
export class Printers {
  constructor(printerPath?: string, barcodeStartSequence?: string, barcodeEndSequence?: string, location?: string, comment?: string,
    model?: string, enabled?: boolean) {

    this.printerPath = printerPath;
    this.barcodeStartSequence = barcodeStartSequence;
    this.barcodeEndSequence = barcodeEndSequence;
    this.location = location;
    this.comment = comment;
    this.model = model;
    this.enabled = enabled;
  }

  public printerId: number;
  public printerPath: string;
  public barcodeEndSequence: string;
  public barcodeStartSequence: string;
  public comment: string;
  public location: string;
  public enabled: boolean;
  public model: string;
}

