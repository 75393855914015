import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';



@Component({
    selector: 'Documents',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
    animations: [fadeInOut]
})
export class DocumentsComponent {

  isEditCategoriesActivated = false;
  isViewDocumentsActivated = true;
  isCollapsed = false;

  fragmentSubscription: any;

  readonly viewDocumentsTab = 'viewdocuments';
  readonly editCategoriesTab = 'editcategories';

  @ViewChild('tab')
  tab: BootstrapTabDirective;


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isCollapsed = !this.menuExpanded;
  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }


  showContent(anchor: string, test?: any) {

    //this.purchaseorder = sessionStorage.getItem(localStorageActions.PurchaseOrder);

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.viewDocumentsTab}Tab`);
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isEditCategoriesActivated = activeTab == this.editCategoriesTab;
    this.isViewDocumentsActivated = activeTab == this.viewDocumentsTab;

    this.router.navigate([], { fragment: activeTab });

  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isViewDocumentsActivated = activeTab == this.viewDocumentsTab;
    this.isEditCategoriesActivated = activeTab == this.editCategoriesTab;

    this.router.navigate([], { fragment: activeTab });
  }
   
  
  get canViewDocuments() {
    return this.accountService.userHasPermission(Permission.viewDocumentsPermission);
  }


  get canManageDocuments() {
    return this.accountService.userHasPermission(Permission.manageDocumentsPermission);
  }

  get canViewMailServer() {
    return this.accountService.userHasPermission(Permission.webmailPermission);
  }
  
}
