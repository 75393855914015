import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
//import { Component } from '@angular/common';
//import { AccountService } from '../../../services/account.service';
import { Utilities } from '../../../services/utilities';
//import { FowkesOnlineService } from '../../../services/fowkes-online.service';
//import { User } from '../../../models/user.model';
//import { UserEdit } from '../../../models/user-edit.model';
//import { Role } from '../../../models/role.model';
//import { Branch } from '../../../models/branch.model';
//import { ArCustomer } from '../../../models/arcustomer.model';
//import { CustomerPayments } from 'src/app/models/customer-payments.model';
import { Moment } from 'moment';
import * as moment from 'moment';
//import { CartHeader } from '../../../models/cart-header.model';
//import { ArCustomerEdit } from '../../../models/arcustomer-edit.model';
//import { SalesPerson } from '../../../models/salesperson.model';
//import { AspnetApplications } from '../../../models/applications.model';
//import { Permission } from '../../../models/permission.model';
import { SysproService } from '../../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceReprint } from '../../../models/invoice-reprint.model';
//import { ArNarration } from 'src/app/models/arnarration.model';
//import { localStorageActions } from 'src/app/models/enums';
//import { ConfigurationService } from 'src/app/services/configuration.service';
import { ngxCsv } from 'ngx-csv';
import { Branch } from '../../../models/branch.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';


@Component({
  selector: 'reports-invoice-reprint',
  templateUrl: './reports-invoice-reprint-component.html',
  styleUrls: ['./reports-invoice-reprint-component.scss']
})
export class ReportsInvoiceReprintComponent
 implements OnInit {

  columns: any[] = [];

  rows: InvoiceReprint[] = [];

  invoiceReprintList: InvoiceReprint[];

  rowsCache: InvoiceReprint[] = [];

  loadingIndicator: boolean;

  selected: { startDate: Moment, endDate: Moment };

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  ranges = {
    'Today': [moment().startOf('day').endOf('day'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  show: boolean;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  @ViewChild('bsDatepicker')
  bsDatepicker: BsDatepickerModule;

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];


  @ViewChild('optionsList')
  private optionsList;

  @ViewChild('optionsListSelector')
  private optionsListSelector;


  @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  selectedBranch: string;
  selectedOption: string;

  private selectUndefinedOptionValue: string = "ALL";

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private fowkesOnline: FowkesOnlineService) { }

  ngOnInit() {

    this.columns = [
      { prop: 'invoice', name: 'Invoice', width: 100, cellClass: "left" },
      { prop: 'displayStatus', name: 'Print', width: 80, cellClass: "left" },
      { prop: 'reprintDate', name: 'Print Date', width: 180, cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'originalOperator', name: 'Original Operator', width: 180, cellClass: "left" },
      { prop: 'reprintOperator', name: 'Reprint Operator', width: 180, cellClass: "left" },
      { prop: 'invoiceTotal', name: 'Total (incl VAT)', width: 100, cellClass: "right", cellTemplate: this.priceTemplate },
      { prop: 'branch', name: 'Branch', width: 80, cellClass: "left" },
      { prop: 'printerName', name: 'Printer', width: 200, cellClass: "left" }
     
    ];

    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }


    this.selectedOption = "Reprint";

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.selectedBranch = this.selectUndefinedOptionValue;
    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();

    this.loadingIndicator = true;


    let startD: Date = new Date(this.selected.startDate.toString());

    let endD: Date = new Date(this.selected.endDate.toString());
    endD.setHours(23, 59, 59, 999);


    this.sysproService.getInvoicePrintAuditTrail(startD.toJSON(), endD.toJSON()).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }

  onDataLoadSuccessful(reprintlist: any[]) {

    this.invoiceReprintList = reprintlist[0];

    this.invoiceReprintList.forEach((listitem, index, reprintlist) => {
      (<any>listitem).index = index + 1;
    });

    this.rowsCache = [...this.invoiceReprintList];

    this.rows = this.invoiceReprintList;
    this.dateFilterChanged();


    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,MessageSeverity.error, error);
  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }
  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }
    this.loadData();

    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.loadData();

    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();

    this.show = false;
  }

  dateFilterChanged() {

    var start = new Date(this.selected.startDate.toString());

    var end = new Date(this.selected.endDate.toString());
    end.setHours(23, 59, 59, 999);

    this.rows = this.rowsCache.filter(m => new Date(m.reprintDate) >= new Date(start) && new Date(m.reprintDate) <= new Date(end));

    if (this.selectedOption != this.selectUndefinedOptionValue) {
      this.rows = this.rows.filter(m => m.displayStatus == this.selectedOption);
    }
    else {
      this.rows = this.rows;
    }

    if (this.selectedBranch  && this.selectedBranch != this.selectUndefinedOptionValue) {
      this.rows = this.rows.filter(m => m.branch == this.selectedBranch);
    }
    else {
      this.rows = this.rows;
    }
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rows.filter(r => Utilities.searchArray(value, false, r.invoice, r.reprintDate, r.originalOperator, r.reprintOperator, r.branch, r.invoiceTotal, r.printerName, r.displayStatus));
    }
    else {
      this.dateFilterChanged();
    }
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Invoice", "Reprint Date", "Original Operator", "Reprint Operator", "Document", "Branch", "Total", "Printer"]
    };

    var filename = "InvoiceReprints";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Invoice: row.invoice, ReprintDate: row.reprintDate, OriginalOperator: row.originalOperator,
        ReprintOperator: row.reprintOperator, Document: row.displayStatus, Branch: row.branch, Total: row.invoiceTotal, Printer: row.printerName
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
