
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, Pipe, PipeTransform } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';


import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { Inventory } from 'src/app/models/inventory.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';


@Component({
  selector: 'syspro-inventory-preview',
  templateUrl: './syspro-inventory-preview.component.html',
  styleUrls: ['./syspro-inventory-preview.component.scss']
})


export class SysproInventoryPreviewComponent implements OnInit {
  rows: Inventory[] = [];
  loadingIndicator: boolean;


  inventoryList: Inventory[];


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  @Input()
  stockCode: string;

  stockCodedetail: Inventory;
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;
    hasImpaCodes: boolean;
    hasAddDataSheet: boolean;
    buyerResponsible: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute) {
  }


  ngOnInit() {

    console.log(this.stockCode);
    this.loadData();
  }




  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    console.log(this.stockCode);
    this.sysproService.getStockCodeDetail(this.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onStockCodeLoadSuccessful(x), error => this.onDataLoadFailed(error));

  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  onStockCodeLoadSuccessful(row: Inventory): void {

    if (row) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail = row[0];
      this.hasDataSheet = false;
      this.hasSpecials = false;
      this.hasImpaCodes = false;

      if (this.stockCodedetail) {
        if (this.stockCodedetail.dataSheetImage) {
          this.hasDataSheet = true;
          this.stockCodedetail.dataSheetImage = encodeURIComponent(this.stockCodedetail.dataSheetImage);
        }
        if (this.stockCodedetail.addSheetImage) {
          this.hasAddDataSheet = true;
          this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
        }
        if (this.stockCodedetail.specialsImage) {
          this.hasSpecials = true;
          this.stockCodedetail.specialsImage = encodeURIComponent(this.stockCodedetail.specialsImage);
        }
        if (this.stockCodedetail.impaCodes && this.stockCodedetail.impaCodes.trim() != "") {
          this.hasImpaCodes = true;
        }
      }
      this.stockCodedetail.stockCodeImage = encodeURIComponent(this.stockCodedetail.stockCodeImage);
    }
    this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

  }


  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());

    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    console.log(error.status);
    if (error.status == 404) { return; }
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }



  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      //this.alternateKey = row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  openSpecials(row: Inventory) {

    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    //if (!!row.specialsImage && row.specialsImage != "") {
    //  window.open(row.filePath + "/SpecialImages/" + row.specialsImage, "_blank");
    //}
  }

  //openSpecials(specialsImg: string) {
  //  if (!!specialsImg && specialsImg != "") {
  //    window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
  //  }
  //}

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

}
