

export class SysproEFTHeader {

  public StrHomeAccount: string;
  public dteActDate: Date;
  public strActDate: string;
  public strGlobRef: string;
  public strBatchDesc: string;
  public decTotal: number;
  public strTotal: string;
  public lblExceeds: string;
  public lblBatchTot: string;


}
