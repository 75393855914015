

export class SysproPaymentDetail {

  public paymentNumber: string;
  public supplier: string;
  public supplierName: string;
  public supplierBank: string;
  public supplierBranch: string;
  public supplierRefNo: string;
  public supplierPayValue: number;
  public message: string;
}
