import { Component, ViewChild, TemplateRef, Input } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Brand } from '../../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CMConsildidatedView } from '../../../models/cms-category-stockcodes.model';
import { CMCategories } from '../../../models/cms-category.model';
import { CMStockCode } from '../../../models/cms-stockcode.model';
import { StockCodeDocuments } from '../../../models/cms-stockcode.documents.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem
} from 'ngx-treeview';

import { CatTemplate } from '../../../models/category-template.model';

@Component({
  selector: 'child-item',
  templateUrl: './child-item.component.html',
  styleUrls: ['./child-item.component.scss'],
  animations: [fadeInOut]
})
export class ChildComponent {

  @Input() items: CatTemplate[];
  @ViewChild('childMenu') public childMenu;


  isSaving: boolean;
  loadingIndicator: boolean;

  @ViewChild('brandModal')
  brandModal: ModalDirective;

  emptyDetails: boolean;

  urlString: string = "https://www.fowkes.co.za/FowkesOnline/FowkesDocuments/ContentManagementDocuments/BrandImages/";

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  rows: CMStockCode[] = [];
  rowsCache: CMStockCode[];
  itemDetail: CMStockCode = new CMStockCode();


  searchTerm: string = "";
  brands: Brand[];
  brandDetail: Brand = new Brand();

  categoryStockCodeList: CMConsildidatedView[] = [];
  CategoryList: CMCategories[] = [];
  rootCategories: CMCategories[];


  treeRows: string[];
  item: any;
  //data: DocumentManagmement;

  seletedItem: TreeviewItem;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: true,
    maxHeight: 500,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild('itemTemplate')
  itemTemplate: TemplateRef<any>;


  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    // this.sysproService.getBrands().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    this.sysproService.getAllCMCategories().subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
   // this.sysproService.GetAllCMStockCodes().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(categories: any[]) {


    //this.CategoryList = [...categories];

    //if (this.CategoryList[0] ) {

    //  //Add all the root categories
    //  var tempList = this.CategoryList.filter(x => x.parentCategoryName === "Root");

    //  tempList.forEach((cat: CMCategories, index: number) => {
    //    let newCategoryRow = new CMConsildidatedView();
    //    newCategoryRow.categoryId = cat.id;
    //    newCategoryRow.categoryName = cat.categoryName;
    //    newCategoryRow.parentCategoryName = cat.parentCategoryName;
    //    newCategoryRow.categoryImage = cat.categoryImage;
    //    newCategoryRow.categorySequence = cat.sequence;
    //    newCategoryRow.categoryStatusIndicator = cat.statusIndicator;
    //    newCategoryRow.categoryLevel = 1;
    //    newCategoryRow.collapsed = true;
    //    newCategoryRow.categoryLastLevel = this.CategoryList.filter(x => x.parentCategoryName.trim() === cat.categoryName).length >= 1 ? false : true;
    //    newCategoryRow.path = cat.categoryName;
    //    this.newMethod(newCategoryRow, cat);
    //  });

    //  this.rootCategories = [...tempList];

    //  //console.log(this.categoryStockCodeList);
    //  //this.populateChildCategories();

    //  if (this.searchTerm.trim() != "") {
    //    this.onSearchChanged(this.searchTerm);
    //  }

    //}
    //else {
    //  this.emptyDetails = true;
    //}
  }
  private newMethod(newCategoryRow: CMConsildidatedView, cat: CMCategories) {
    //newCategoryRow.childCategories = [];
    //newCategoryRow.stockCodeList = [];
    //    if (newCategoryRow.categoryLastLevel) {
    //      this.sysproService.getStockCodesForCategory(cat.id).subscribe(x => {
    //        newCategoryRow.stockCodeList = x;
    //        newCategoryRow.stockCodeList.forEach(s => {
    //          s.path = newCategoryRow.path;
    //        });
    //      }, error => this.onDataLoadFailed(error));

    //      this.categoryStockCodeList.push(newCategoryRow);
    //    }
    //    else {
    //        this.CategoryList.filter(x => x.parentCategoryName === cat.categoryName).forEach(i => {
    //            let childRow = new CMConsildidatedView();
    //            childRow.categoryId = i.id;
    //            childRow.categoryName = i.categoryName;
    //            childRow.parentCategoryName = i.parentCategoryName;
    //            childRow.categoryImage = i.categoryImage;
    //            childRow.categorySequence = i.sequence;
    //            childRow.categoryStatusIndicator = i.statusIndicator;
    //          childRow.categoryLevel = newCategoryRow.categoryLevel++;
    //          childRow.path = newCategoryRow.path + "|" + i.categoryName;
    //          newCategoryRow.collapsed = true;
    //          childRow.categoryLastLevel = this.CategoryList.filter(x => x.parentCategoryName === i.categoryName).length >= 1 ? false : true;
    //          newCategoryRow.childCategories.push(childRow);

    //          this.newMethod(childRow, i);
    //        });

    //      this.categoryStockCodeList.push(newCategoryRow);
    //  }
    }

    //populateChildCategories() {

    //  var tempList = this.categoryStockCodeList;
    //  tempList.forEach((parent: CMConsildidatedView, index: number) => {
    //    this.addChildren(parent);

    //  });

    //}

 // addChildren(parent: CMConsildidatedView): CMConsildidatedView[]{
    //  var childCategories = this.CategoryList.filter(x => x.parentCategoryName === parent.categoryName);
    //if (childCategories.length >= 1) {
    //  var tempList = [];
    //    childCategories.forEach((cat: CMCategories, index: number) => {
    //      console.log(index, cat);
    //      let newCategoryRow = new CMConsildidatedView();
    //      newCategoryRow.categoryId = cat.id;
    //      newCategoryRow.categoryName = cat.categoryName;
    //      newCategoryRow.parentCategoryName = cat.parentCategoryName;
    //      newCategoryRow.categoryImage = cat.categoryImage;
    //      newCategoryRow.categorySequence = cat.sequence;
    //      newCategoryRow.categoryStatusIndicator = cat.statusIndicator;
    //      newCategoryRow.categoryLastLevel = this.CategoryList.filter(x => x.parentCategoryName === cat.categoryName).length >= 1;
    //      newCategoryRow.categoryLevel = parent.categoryLevel ++;
    //      newCategoryRow.stockCodeList = [];
    //      newCategoryRow.childCategories = this.addChildren(newCategoryRow);
    //      tempList.push(newCategoryRow);
    //    });

    //  return tempList;
    //  }
    //  else {
    //    this.sysproService.getStockCodesForCategory(parent.categoryId).subscribe(x => this.onStockCodesForCategoryLoadSuccessful(x, parent), error => this.onDataLoadFailed(error));
    //  }

  //    this.alertService.stopLoadingMessage();
  //    this.loadingIndicator = false;

  //}

  onStockCodesForCategoryLoadSuccessful(stockCodes: CMStockCode[], cat: CMConsildidatedView): void {
    //if (stockCodes[0]) {
    //  let newCategoryRow = new CMConsildidatedView();
    //  newCategoryRow.categoryId = cat.categoryId;
    //  newCategoryRow.categoryName = cat.categoryName;
    //  newCategoryRow.parentCategoryName = cat.parentCategoryName;
    //  newCategoryRow.categoryImage = cat.categoryImage;
    //  newCategoryRow.categorySequence = cat.categorySequence;
    //  newCategoryRow.categoryStatusIndicator = cat.categoryStatusIndicator;
    //  newCategoryRow.categoryLastLevel = this.CategoryList.filter(x => x.parentCategoryName === cat.categoryName).length >= 1;
    //  newCategoryRow.categoryLevel = cat.categoryLevel++;
    //  newCategoryRow.stockCodeList = stockCodes;
    //  this.categoryStockCodeList.push(newCategoryRow);
    //}
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.brandName, r.stockCode, r.stockCodeImage, r.categoryName, r.description));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  deleteRow(row: Brand) {
    //Check that brand doesnt have any stock codes assigned
    this.sysproService.getStockCodesForBrand(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));

  }
  onValidationSuccesful(x: CMStockCode[], row: Brand): void {
    console.log(x);
    if (x && x.length >= 1) {
      this.alertService.showMessage('Warning', `You cannot delete a brand with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
    }
    else {
      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.deleteBrand(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
    }
  }

  enableEditMethod(row: Brand, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: Brand) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.editBrand(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  brandModalHide() {
    this.brandDetail = new Brand();
    this.brandModal.hide();
  }

  newBrand() {

    if (!this.brandModal) {
      setTimeout(() => {
        if (this.brandModal) {
          this.brandModal.show();
        }
      });
    }
    else {
      this.brandModal.show();
    }

  }


  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newformData.append('file', file);
     // this.sysproService.postFileData(formData, "BRAND", row.brandName).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
      //this.myForm.patchValue({
      //  fileSource: file
      //});
    }
  }

  saveBrand() {

    //Validate save
    if (this.brandDetail) {
      if (this.brandDetail.brandName) {
        if (this.rowsCache.filter(x => x.brandName == this.brandDetail.brandName).length >= 1) {
          this.alertService.showMessage('Warning', `This brand name already exists`, MessageSeverity.warn);
        }
        else {
          if (this.newformData.has('file')) {
            this.sysproService.postFileData(this.newformData, "BRAND", this.brandDetail.brandName).subscribe(x => this.newFilePostedSuccess(x), error => this.onPostDataLoadFailed(error));
          }
          else {
            this.alertService.showMessage('Warning', `Remeber to add a file first`, MessageSeverity.warn);}
        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a brand name`, MessageSeverity.warn);
      }
    }
  }

    newFilePostedSuccess(x: any) {

      console.log(x);
      this.brandDetail.brandImage = x.fileName;
      this.brandDetail.createdBy = this.accountService.currentUser.email;
      this.brandDetail.brandImageChanged = new Date();

      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.addBrand(this.brandDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
    }
    onSaveSuccesful(): void {

      this.brandModalHide(); 
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.loadData();
    }

  //get f() {
  //  return this.myForm.controls;
  //}

  onFileChange(event, row: Brand) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.sysproService.postFileData(formData, "BRAND", row.brandName).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
      //this.myForm.patchValue({
      //  fileSource: file
      //});
    }
  }
  filePostedSuccess(result: any, row: Brand) {

    console.log(result);
    row.brandImage = result.fileName;
    row.brandImageChanged = new Date();
    this.saveSegment(row);
  }

  //submit() {
  //  const formData = new FormData();

  //  formData.append('file', this.myForm.get('fileSource').value);

  //  this.sysproService.postFileData(formData).subscribe(x => this.onPostDataSuccessful([x]), error => this.onPostDataLoadFailed(error));
  //}

  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  //onPostDataSuccessful(arg0: any[]) {
  //  throw new Error("Method not implemented.");
  //}
}
