import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Brand } from '../../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CMStockCode } from '../../../models/cms-stockcode.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InfoBoardNotes } from '../../../models/info-board-notes.model';

@Component({
  selector: 'info-settings',
  templateUrl: './settings-info.component.html',
  styleUrls: ['./settings-info.component.scss'],
  animations: [fadeInOut]
})
export class InfoSettingsComponent {


  rows: InfoBoardNotes[] = [];
  rowsCache: InfoBoardNotes[];
  infoNoteDetail: InfoBoardNotes = new InfoBoardNotes();

  @ViewChild('infoNoteModal')
  infoNoteModal: ModalDirective;

  emptyDetails: boolean;

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getAllInfoNotes().subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(infoNotes: InfoBoardNotes[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (infoNotes[0]) {
      var links = infoNotes.filter(x => x.informationBoardName == "NextGenLinks");
      this.rowsCache = [...links];
      const updateRows = this.rowsCache;
     
      this.rows = [...updateRows];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.informationBoardName, r.note));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  deleteRow(row: InfoBoardNotes) {
    //Check that brand doesnt have any stock codes assigned
    //this.sysproService.getStockCodesForBrand(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.deleteInfoNote(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));

  }
  onValidationSuccesful(x: CMStockCode[], row: Brand): void {
    console.log(x);
    if (x && x.length >= 1) {
      this.alertService.showMessage('Warning', `You cannot delete a brand with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
    }
    else {
      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.deleteInfoNote(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
    }
  }

  selectRow(row: InfoBoardNotes) {
    this.infoNoteDetail = new InfoBoardNotes();
    this.infoNoteDetail = row;

    if (!this.infoNoteModal) {
      setTimeout(() => {
        if (this.infoNoteModal) {
          this.infoNoteModal.show();
        }
      });
    }
    else {
      this.infoNoteModal.show();
    }
  }

  enableEditMethod(row: InfoBoardNotes, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: InfoBoardNotes) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateInfoNote(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  infoNoteModalHide() {
    this.infoNoteDetail = new InfoBoardNotes();
    this.newformData = new FormData();
    this.infoNoteModal.hide();
  }

  newInfoNote() {

    this.infoNoteDetail = new InfoBoardNotes();
    this.infoNoteDetail.informationBoardName = "NextGenLinks";
    this.infoNoteDetail.createdBy = this.accountService.currentUser.email;
    this.infoNoteDetail.documentName = "";
    this.infoNoteDetail.status = "R";

    if (!this.infoNoteModal) {
      setTimeout(() => {
        if (this.infoNoteModal) {
          this.infoNoteModal.show();
        }
      });
    }
    else {
      this.infoNoteModal.show();
    }

  }


  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newformData.append('file', file);
     // this.sysproService.postFileData(formData, "BRAND", row.brandName).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
      //this.myForm.patchValue({
      //  fileSource: file
      //});
    }
  }

  saveInfoNote() {
    if (this.infoNoteDetail.id > 0) {
      //Update
      if (this.infoNoteDetail) {

        this.infoNoteDetail.modifiedBy = this.accountService.currentUser.email;
        if (this.newformData.has('file')) {

          this.alertService.startLoadingMessage('Uploading...');
          this.sysproService.postInfoFile(this.newformData, "INFOBOARD", this.infoNoteDetail.heading + "_" + this.infoNoteDetail.id).subscribe(x =>
            this.newFilePostedSuccess(x, this.infoNoteDetail.id),
            error => this.onPostDataLoadFailed(error));
        }
        else {
          this.isSaving = true;
          this.alertService.startLoadingMessage('Saving changes...');
          this.sysproService.updateInfoNote(this.infoNoteDetail, this.infoNoteDetail.id).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
        }
      }
    }
    else {
      //save new
      if (this.rowsCache.filter(x => x.heading == this.infoNoteDetail.heading).length >= 1) {
        this.alertService.showMessage('Warning', `This note name already exists`, MessageSeverity.warn);
      }
      else {
        if (this.newformData.has('file')) {
          this.alertService.startLoadingMessage('Uploading...');
          this.sysproService.postInfoFile(this.newformData, "INFOBOARD", this.infoNoteDetail.heading).subscribe(x => this.newFilePostedSuccess(x, 0),
            error => this.onPostDataLoadFailed(error));
        }
        else {
          this.isSaving = true;
          this.alertService.startLoadingMessage('Saving changes...');
          this.sysproService.addInfoNote(this.infoNoteDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
        }
      }
    }
   
  }
  removeAttachment() {
    this.newformData = new FormData();
    this.infoNoteDetail.documentName = "";
  }

    newFilePostedSuccess(x: any, id: number) {


      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');

      this.infoNoteDetail.documentName = x.fileName;

      if (id > 0) {
        this.infoNoteDetail.modifiedBy = this.accountService.currentUser.email;
        this.sysproService.updateInfoNote(this.infoNoteDetail, id).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
      }
      else {
        this.infoNoteDetail.createdBy = this.accountService.currentUser.email;
        this.sysproService.addInfoNote(this.infoNoteDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
      }
    }
    onSaveSuccesful(): void {

      this.infoNoteModalHide(); 
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.loadData();
    }

  //get f() {
  //  return this.myForm.controls;
  //}
  downloadAttachment(doc: string) {

    this.alertService.startLoadingMessage('downloading...');
    this.sysproService.getInfoFile(doc,0).subscribe(x => this.onFileSuccessful(x, doc), error => this.onDataLoadFailed(error));

  }


  onFileSuccessful(x: Blob, fileName: string): void {
    var test = x;

    this.alertService.stopLoadingMessage();
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }

  onFileChange(event, row: InfoBoardNotes) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.sysproService.postFileData(formData, "INFOBOARD", row.informationBoardName).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
      //this.myForm.patchValue({
      //  fileSource: file
      //});
    }
  }
  filePostedSuccess(result: any, row: InfoBoardNotes) {

    console.log(result);
    row.documentName = result.fileName;
    this.saveSegment(row);
  }

  //submit() {
  //  const formData = new FormData();

  //  formData.append('file', this.myForm.get('fileSource').value);

  //  this.sysproService.postFileData(formData).subscribe(x => this.onPostDataSuccessful([x]), error => this.onPostDataLoadFailed(error));
  //}

  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  //onPostDataSuccessful(arg0: any[]) {
  //  throw new Error("Method not implemented.");
  //}
}
