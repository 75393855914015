
export class ITSDriver  {
  constructor(driverName?: string, driverCode?: string, driverImage?: string,
    filePath?: string, defaultRoute?: string, defaultVehicle?: string, driverTel?: string) {

    this.driverName = driverName;
    this.driverCode = driverCode;
    this.driverImage = driverImage;
    this.filePath = filePath;
    this.defaultRoute = defaultRoute;
    this.defaultVehicle = defaultVehicle;
    this.driverTel = driverTel;
  }

  public id: number;
  public driverName: string;
  public driverCode: string;
  public defaultRoute: string;
  public driverImage: string;
  public filePath: string;
  public defaultVehicle: string;
  public model: string;
  public createdDate: string;
  public createdBy: string;
  public modifiedDate: string;
  public modifiedBy: string;
  public driverTel: string;

}

