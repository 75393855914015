import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { BankImport } from '../../../models/bank-import.model';
import { Branch } from '../../../models/branch.model';
import { User } from '../../../models/user.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';


@Component({
  selector: 'bank-import',
  templateUrl: './bank-import.component.html',
  styleUrls: ['./bank-import.component.scss']
})
export class BankImportComponent implements OnInit {


  @ViewChild('banks')
  private banks;

  @ViewChild('bankListSelector')
  private bankListSelector;
  allBanks: Branch[] = [];

  @ViewChild('fileInput')
  fileInput: ElementRef;

  @ViewChild('periodListSelector')
  private periodListSelector;
  allPeriods: Branch[] = [];


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  columns: any[] = [];
  resultcolumns: any[] = [];

  stagedRows: BankImport[] = [];
  stagedRowsCache: BankImport[] = [];

  resultRows: BankImport[] = [];
  resultRowsCache: BankImport[] = [];

  successcount: number = 0;
  processcount: number = 0;

  successAmount: number = 0;
  skippedAmount: number = 0;

  isPosting: boolean;
  SysproUser: User;

  rows: BankImport[] = [];
  rowsCache: BankImport[];

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('statusTemplate')
  statusTemplate: TemplateRef<any>;

  selectedFileName: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;

  loadingIndicator: boolean = false;

  selected: { startDate: Moment, endDate: Moment };
  show: boolean;

  periods: [{ "Current": "Current", "Date": "June 2021" },
    { "Previous": "Previous", "Date": "May 2021" }  ];

  ranges = {
    //'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  periodRanges = {
    'Current': [moment().startOf('month'), moment().endOf('month')],
    'Previous': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  selectedPeriod: string;
  selectedBank: string;

  newDebtorsFile = new FormData();

  @ViewChild('uploadModal')
  uploadModal: ModalDirective;
  arrayBuffer: any;
    index: any;
    complete: boolean;
    currentFileName: string;
    skipCount: number;
    posintgCount: number;
    failedCount: number;
    gridHeigt: string;
    fileName: string;
    totalAmount: number;
    safecount: number;
    totalCount: number;
  

  constructor(private router: Router, private alertService: AlertService, private fowkesService: FowkesOnlineService, private configurations: ConfigurationService,
    private sysproService: SysproService, private route: ActivatedRoute, private accountService: AccountService) {

  }

  ngOnInit() {

    this.resetForNextBatch();  

    this.selected = {
      startDate: moment().subtract(7, 'day').startOf('day'),
      endDate: moment().subtract(0, 'day').endOf('day')
    }

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.gridHeigt = "height: 300px;";


  }

  resetForNextBatch() {

    //Adding refresh previous file (if in memory)
    if (this.currentFileName && this.currentFileName.length >= 1) {
      this.sysproService.getBankImportByFileName(this.currentFileName).subscribe(x => {}, error => {});
    }


    this.selectedCompany = (this.application == "MaxArcusOnline" ? "M" : "F");
    this.selectedPeriod = "C";
    this.selectedBank = "ST";
    this.complete = false;
    this.stagedRows = [...[]];
    this.stagedRowsCache = [...[]];
    this.resultRows = [...[]];
    this.resultRowsCache = [...[]];
    this.currentFileName = "";
    this.fileName = "";
    this.newDebtorsFile = new FormData();
    this.fileInput.nativeElement.value = "";
    

    this.setDataGrids();

   // this.getFileNames();
  }
  getFileNames() {
    //this.sysproService.getBankImportByCompany(this.selectedCompany).subscribe(
    //  x => this.onStagingFileSuccess(x, this.fileName), error => this.onDataLoadFailed(error));
    }

  onHistoryLoadSuccesfull(x: BankImport[]): void {
    if (x && x.length >= 1) {
      this.resultRowsCache = x;
      this.resultRows = [...this.resultRowsCache];
    }
  }


    setDataGrids() {
      this.columns = [
        { prop: 'company', name: 'Company', width: 50 },
        { prop: 'customerMaster', name: 'Master Acc', width: 80 },
        { prop: 'customer', name: 'Customer', width: 80 },
        { prop: 'customerName', name: 'Customer Name', width: 200 },
        { prop: 'bankReference', name: 'Bank Reference', width: 100, cellClass: "center" },
        { prop: 'paymentDate', name: 'Payment Date', width: 100, cellTemplate: this.dateTemplate },
        { prop: 'paymentValue', name: 'Amount', width: 100 },
        { prop: 'status', name: 'Status', cellTemplate: this.statusTemplate, width: 100 },
        { name: '', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
        { prop: 'message', name: 'Result', width: 200 },
        { prop: 'tender', name: 'Tender', width: 100 },
        { prop: 'customerAccTerms', name: 'Acc Terms', width: 80 },
        { prop: 'customerApplyAutoPayments', name: 'Auto Payments', width: 80 }
      ];

     // this.columns.push({ name: '', width: 300, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

      this.resultcolumns = [
        { prop: 'company', name: 'Company', width: 50 },
        { prop: 'customer', name: 'Customer', width: 100 },
        { prop: 'customerName', name: 'Customer Name', width: 200 },
        { prop: 'bankReference', name: 'Bank Reference', width: 100, cellClass: "center" },
        { prop: 'paymentDate', name: 'Payment Date', width: 100, cellTemplate: this.dateTemplate },
        { prop: 'tender', name: 'Tender', width: 100 },
        { prop: 'paymentValue', name: 'Amount', width: 100 },
        { prop: 'status', name: 'Status', width: 100 },
        { prop: 'message', name: 'Result', width: 200 }
      ];
    }

  ngAfterViewInit() {

    this.setDataGrids();
  }

  openUploadModal() {
    if (!this.uploadModal) {
      setTimeout(() => {
        if (this.uploadModal) {
          this.uploadModal.show();
        }
      });
    }
    else {
      this.uploadModal.show();
    }
  }


  uploadModalHide() {
    this.uploadModal.hide();
  }

  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name.replace(/\s/g, "") + "-" + this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;
        this.newDebtorsFile.append('file', file);
      }
  }

  startImport() {
    if (this.newDebtorsFile.has('file')) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.uploadModalHide();
      this.stagedRows = null;
      this.stagedRowsCache = null;
      this.successcount = 0;
      this.processcount = 0;
      this.successAmount = 0;
      this.skippedAmount = 0;
      this.safecount = 0;
      this.totalCount = 0;
      
      //var newFileName = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;
      this.sysproService.uploadBankFile(this.newDebtorsFile, this.selectedCompany, this.fileName, this.selectedBank, this.selectedPeriod).subscribe(
        x => this.onStagingFileSuccess(x, this.fileName), error => this.onDataLoadFailed(error));
    }
  }

  getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "T" + h + mi + s + "-";
  }

  onStagingFileSuccess(x: any, fileName: string) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.currentFileName = fileName;
    var totalAmount = 0;
    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
        var extract = line.paymentValue;
        totalAmount += (extract*= -1);
      });

      this.stagedRowsCache = [...details];
      this.stagedRows = this.stagedRowsCache;
      this.newDebtorsFile = new FormData();

      this.totalAmount = totalAmount;

      this.totalCount = this.stagedRows.length;
      this.safecount = this.stagedRows.filter(x => x.status == "STAGED").length;

     // this.headerId = this.stagedRows[0].headerId;

      //Load Header Information
    //  this.sysproService.getBankFileHeader()
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.stagedRows = this.stagedRowsCache.filter(r => Utilities.searchArray(value, false, r.status, r.bankReference, r.customer, r.customerName, r.paymentDate, r.paymentValue));
    }
    else {
      this.stagedRows = this.stagedRowsCache;
    }
  }

  postRecord(row: any) {

    if (!this.isPosting) {
      if (this.validatePost(row)) {
        this.isPosting = true;
        this.index = row.index;
        if (!this.SysproUser || this.SysproUser.sysproSessionId) {
          this.alertService.startLoadingMessage('Loggin into Syspro...');
          //Check SYSPRO User
          this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onSingleSessionCreatedSuccesful(x, row), error => this.onSessionFailed(error));

        }
        else {
          this.onSingleSessionCreatedSuccesful(this.SysproUser, row);
        }
      }
    }
  }

  deleteRecord(row: any) {
    //Update local record
    row.status = "SKIPPED";
    row.message = "";

    this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));
  }

  onSingleSessionCreatedSuccesful(x: User, row: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.startLoadingMessage('Posting Payment...');
    this.SysproUser = x;
    if (this.SysproUser.sysproSessionId) {
      let batchId = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod; 
      row.status = "PROCESSING";
      row.batchId = batchId;
      row.userName = this.SysproUser.sysproOperator;
      //Update local record
      this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

      const detail = row;
      if (detail) {
        const postObject = {
          "user": this.SysproUser, "detail": detail
        };
        this.sysproService.postSinglePaymentToSyspro(postObject).subscribe(x => this.onSingleArstpyPOSTSuccesful(x), error => this.onSingleArstpyPOSTFailed(row, error));
      }
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }
  }

  onSingleArstpyPOSTSuccesful(x: BankImport): void {

    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Payments posted to syspro successfully`, MessageSeverity.success);
    this.loadingIndicator = false;
    if (x && x.id >= 1) {
      //Update local record
      let index = this.resultRows.findIndex(r => r.id === x.id)
      let rowToUpdate = this.resultRows[index];
      rowToUpdate = x;
      this.resultRows = [...this.resultRows]; 
      this.sysproService.updateBankImportLine(x, x.id).subscribe(x => { }, error => this.onUpdateFailed(error));
    }

    this.isPosting = false;
    this.complete = true;
  }

  onSingleArstpyPOSTFailed(posteRow: BankImport, error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'The below errors occured whilst posting payments:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //Update local record
    this.sysproService.updateBankImportLine(posteRow, posteRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

    this.isPosting = false;
    this.complete = true;
  }

  postAll() {
    if (!this.isPosting) {
      if (this.validatePost()) {
        this.isPosting = true;
        //Check SYSPRO User
        this.alertService.startLoadingMessage('Loggin into Syspro...');
        this.sysproService.GetSysproSessionId(this.accountService.currentUser, false, this.selectedCompany)
          .pipe(
            finalize(() => this.isPosting = false)  // This will always run after the observable completes, whether in success or error.
          )
          .subscribe(
            x => {
              // Check if the response contains "ERROR"
              if (x.sysproSessionId.includes("ERROR")) {
                this.onSessionFailed(x);  // Pass the error message to the failure handler
              } else {
                this.onsessionCreatedSuccesful(x);  // Proceed with success handling
              }
            },
            error => this.onSessionFailed(error)  // Handle any unexpected errors
          );
      }
    }    
  }


  onsessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.SysproUser = x;
    if (this.SysproUser.sysproSessionId) {

      this.index = 0;
      this.skipCount = 0;
      this.posintgCount = 0;
      this.failedCount = 0;
      const rowsToProcess = this.stagedRows.filter(x => x.status != "ERROR");
      this.processcount = rowsToProcess.length;
      let batchId = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;

      this.alertService.startLoadingMessage('Posting ' + this.processcount + ' Payments...');    

      //Update Local Records
      for (var row of rowsToProcess) {
        row.status = "PROCESSING";
        row.batchId = batchId;
        row.userName = this.SysproUser.sysproOperator;
        //Update local record
        this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));
      }

      const postObject = {
        "user": this.SysproUser, "detail": rowsToProcess
      };
      this.sysproService.postPaymentsToSyspro(postObject).subscribe(x => this.onArstpyPOSTAllSuccesful(x), error => { this.onArstpyPOSTAllFailed(error) });
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }

  }

  postUnprocessed() {
    if (!this.isPosting) {
      if (this.validatePost()) {
        this.isPosting = true;
        //Check SYSPRO User
        this.alertService.startLoadingMessage('Loggin into Syspro...');
        this.sysproService.GetSysproSessionId(this.accountService.currentUser, false, this.selectedCompany)
          .pipe(
            finalize(() => this.isPosting = false)  // This will always run after the observable completes, whether in success or error.
          )
          .subscribe(
            x => this.onSafesessionCreatedSuccesful(x),
            error => this.onSessionFailed(error)
          );
      }
    }
  }


  onSafesessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.SysproUser = x;
    if (this.SysproUser.sysproSessionId) {

      this.index = 0;
      this.skipCount = 0;
      this.posintgCount = 0;
      this.failedCount = 0;
      const rowsToProcess = this.stagedRows.filter(x => x.status == "STAGED");
      this.processcount = rowsToProcess.length;
      let batchId = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;

      this.alertService.startLoadingMessage('Posting ' + this.processcount + ' Payments...');

      //Update Local Records
      for (var row of rowsToProcess) {
        row.status = "PROCESSING";
        row.batchId = batchId;
        row.userName = this.SysproUser.sysproOperator;
        //Update local record
        this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));
      }

      const postObject = {
        "user": this.SysproUser, "detail": rowsToProcess
      };
      this.sysproService.postPaymentsToSyspro(postObject).subscribe(x => this.onArstpyPOSTAllSuccesful(x), error => { this.onArstpyPOSTAllFailed(error) });
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }

  }


  onUpdateSuccesful(x: BankImport): void {

    this.resultRowsCache.push(x);
    this.resultRows = [...this.resultRowsCache];

    const sum = this.resultRows.filter(item => item.status != '')
      .reduce((sum, current) => sum + current.paymentValue, 0);

    this.successAmount = sum;

    //const skipped = this.resultRows.filter(item => item.status === 'SKIPPED')
    //  .reduce((sum, current) => sum + current.paymentValue, 0);


    //this.skippedAmount = skipped;


    let index = this.stagedRows.findIndex(r => r.id === x.id)
    let temp = [...this.stagedRows];
    temp.splice(index, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.stagedRows = [...temp];
  }

    onArstpyPOSTAllFailed(error: any) {
      this.loadingIndicator = false;
      this.alertService.stopLoadingMessage();
  }

  onArstpyPOSTAllSuccesful(x: BankImport): void {

    console.log("RESULT", x);
    //Update Local Records
    const rowsToProcess = this.resultRows.filter(r => r.batchId === x.batchId);
    for (var row of rowsToProcess) {

      //Check for journal number
      if (x.journal && x.journal.length > 1) {

        row.status = "SUCCESS";
        row.journal = x.journal;
        row.trnYear = x.trnYear;
        row.trnMonth = x.trnMonth;
        row.message = "POSTED " + x.journal;

        //Update all records with success, journal details
        this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onFinalUpdateSuccesful(x), error => this.onUpdateFailed(error));
      }
    }

    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
    this.isPosting = false;
    this.complete = true;

  }

  onFinalUpdateSuccesful(x: BankImport): void {
    console.log("NEW ROW", x);
    let index = this.resultRows.findIndex(r => r.id === x.id)
    let rowToUpdate = this.resultRows[index];
    rowToUpdate = x;
    this.resultRows = [...this.resultRows]; 
  }

  //processRow(row: BankImport, count: number) {
  //   this.posintgCount++;
  //   let detail = row;
  //  const postObject = {
  //    "user": this.SysproUser, "detail": detail
  //  };
  //  setTimeout(() => {
   
  //  this.sysproService.postPaymentsToSyspro(postObject).subscribe(x => this.onArstpyPOSTSuccesful(x, count), error => { this.onArstpyPOSTFailed(row, error, count) });
  //  }, 3000); //Time before execution
   
  //}


  //skipRow(row: BankImport, count: number) {
  //  this.successcount++;
  //  this.skipCount++;
  //  console.log("SKIPPED", count, this.successcount, row);
  //  let newRow = new BankImport();
  //  newRow = row;
  //  this.resultRowsCache.push(newRow);
  //  this.resultRows = [...this.resultRowsCache];


  //  let index = this.stagedRows.findIndex(x => x.id === x.id)
  //  let temp = [...this.stagedRows];
  //  temp.splice(index, 1);
  //  temp.forEach((line, index, temp) => {
  //    (<any>line).index = index + 1;
  //  });

  //  this.stagedRows = [...temp];
  //}


  //onNextRow(rowToMove: any): boolean {
  //  let newRow = new BankImport();
  //  newRow = rowToMove;
  //  this.resultRowsCache.push(newRow);
  //  this.resultRows = [...this.resultRowsCache];

  //  let temp = [...this.stagedRowsCache];
  //  temp.splice(this.index - 1, 1);
  //  temp.forEach((line, index, temp) => {
  //    (<any>line).index = index + 1;
  //  });

  //  this.stagedRows = [...temp];

  //  return true;
  //}

  onArstpyPOSTSuccesful(x: BankImport, count: number): void {
    this.successcount++;
    console.log("SUCCESS", count, this.successcount, this.processcount, x);
  
    if (this.successcount <= this.processcount) {
      if (x && x.id >= 1) {
        let newRow = new BankImport();
        newRow = x;
        this.resultRowsCache.push(newRow);
        this.resultRows = [...this.resultRowsCache];
        this.successAmount += newRow.paymentValue;

        let index = this.stagedRows.findIndex(x => x.id === x.id)
        let temp = [...this.stagedRows];
        temp.splice(index, 1);
        temp.forEach((line, index, temp) => {
          (<any>line).index = index + 1;
        });

        this.stagedRows = [...temp];

        //Update local record
        this.sysproService.updateBankImportLine(newRow, newRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

      }
      else {
        console.log(x);
      }
    }
    if (this.successcount == this.processcount) {
      this.wrapUp();
    }
  }
    onUpdateFailed(error: any): void {
      
    }

  validatePost(row?: BankImport) {
    return true;
  }

  onArstpyPOSTFailed(posteRow: BankImport, error: any, count: number): void {
    this.successcount++;
    this.failedCount++;
    console.log("FAILED", count, this.successcount, posteRow);
    let newRow = new BankImport();
    newRow = posteRow;
    newRow.status = "FAILED";
    newRow.message = error;
    this.resultRowsCache.push(newRow);
    this.resultRows = [...this.resultRowsCache];

    let index = this.stagedRows.findIndex(x => x.id === x.id)
    let temp = [...this.stagedRows];
    temp.splice(index, 1);
    temp.splice(count - 1, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.stagedRows = [...temp];
    //Update local record
    this.sysproService.updateBankImportLine(newRow, newRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

    if (this.successcount == this.processcount) {
      this.wrapUp();
    }
    
  }


  private wrapUp() {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Finished processing : ' + this.successcount + ' records of which ' + this.skipCount + ' were skipped, and ' + this.failedCount + ' failed');

    this.isPosting = false;
    this.complete = true;
    this.stagedRows = null;

    this.gridHeigt = "height: 600px;";
  }

  onSessionFailed(error: any): void {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
    // Extract specific error details or use a default message
    const errorMessage = error.message || 'Failed to log into Syspro';

    // Alert the user with the error message
    this.alertService.showStickyMessage('Error', errorMessage, MessageSeverity.error);

    // Additional error handling logic can be added here
    console.error('SYSPRO session error:', error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  RefreshResults() {

    this.sysproService.getBankImportByFileName(this.currentFileName).subscribe(x => this.onHistoryLoadSuccesfull(x), error => this.onDataLoadFailed(error));
  }

  ExportResults() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Company", "MasterAcc", "Customer", "CustomerName", "Amount", "BankReference", "PaymentDate", "Status", "TrnYear", "TrnMonth", "Journal", "Message"]
    };

    var filename = this.currentFileName;
    var selectRows = [...this.resultRows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.paymentDate).toISOString().split('T')[0];
      exportRows.push({
        Company: row.company, MasterAcc: row.customerMaster, Customer: row.customer, CustomerName: row.customerName, Amount: row.paymentValue, BankReference: row.bankReference,
        PaymentDate: date, Status: row.status, TrnYear: row.trnYear, TrnMonth: row.trnMonth, Journal: row.journal, Message: row.message
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  GoToOverview() {
    this.router.navigate(['../../customers'], { fragment: 'debtorsbankoverview' });
  }

}
