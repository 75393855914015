import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { Establishment } from '../../../models/company-establishment.model';
import { Printers } from '../../../models/printers.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { PrinterService } from '../../../services/printer.service';

@Component({
  selector: 'printer-settings',
  templateUrl: './settings.printer.component.html',
  styleUrls: ['./settings.printer.component.scss'],
  animations: [fadeInOut]
})
export class PrinterSettingsComponent {

  isSaving: boolean;
  loadingIndicator: boolean;

  columns: any[] = [];


  printersCache: Printers[] = [];
  printers: Printers[] = [];
  printerDetail: Printers;


  @ViewChild('printerModal')
  printerModal: ModalDirective;

  branchesCache: Establishment[] = [];
  establishmentsCache: Establishment[] = [];
  establishments: Establishment[] = [];
  establishmentDetail: Establishment;

  @ViewChild('establishmentModal')
  establishmentModal: ModalDirective;

  emptyDetails: boolean;

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('companiesList')
  private companiesList;

  @ViewChild('companiesListSelector')
  private companiesListSelector;


  selectedCompany: string;


  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();

  searchTerm: string = "";

  constructor(private http: HttpClient, private alertService: AlertService, private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private printerService: PrinterService) {
  }

  ngOnInit() {

    this.loadGrids();
    this.loadData();

  }

  ngAfterViewInit() {

    this.loadGrids();
  }


  private loadGrids() {
    this.columns = [
      { prop: 'printerPath', name: 'Name', width: 200},
      { prop: 'comment', name: 'Comment', width: 250 },
      { prop: 'model', name: 'Model', width: 200 },
      { prop: 'enabled', name: 'Enabled', width: 80 },
      { name: '', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      { prop: 'barcodeStartSequence', name: 'Barcode Start Sequence', width: 200 },
      { prop: 'barcodeEndSequence', name: 'End Sequence', width: 80 }
    ];

  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.printerService.GetPrinters().subscribe(x => this.onPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));

  }
  onPrintersLoadSuccesful(printers: Printers[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (printers[0]) {
      this.printersCache = [...printers];
      this.printers = this.printersCache;

      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.printers = this.printersCache.filter(r => Utilities.searchArray(value, false, r.printerPath, r.comment, r.barcodeStartSequence, r.barcodeEndSequence, r.location, r.model));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.printers = [...this.printersCache];
    }
  }

  deleteRow(row: Printers) {

    //Check that brand doesnt have any stock codes assigned
   // this.sysproService.getStockCodesForBrand(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));

  }

  editPrinter(row: Printers) {

    this.printerDetail = row;

    if (!this.printerModal) {
      setTimeout(() => {
        if (this.printerModal) {
          this.printerModal.show();
        }
      });
    }
    else {
      this.printerModal.show();
    }

  }

  printerModalHide() {
    this.printerDetail = new Printers();
    this.printerModal.hide();
  }

  newPrinter() {
    this.printerDetail = new Printers();


    if (!this.printerModal) {
      setTimeout(() => {
        if (this.printerModal) {
          this.printerModal.show();
        }
      });
    }
    else {
      this.printerModal.show();
    }

  }


  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  savePrinter() {

    //Validate save
    if (this.printerDetail) {
      if (this.printerDetail.printerPath) {       

        this.printerDetail.barcodeStartSequence = (this.printerDetail.barcodeStartSequence ? this.printerDetail.barcodeStartSequence : "");
        this.printerDetail.barcodeEndSequence = (this.printerDetail.barcodeEndSequence ? this.printerDetail.barcodeEndSequence : "");

        this.printerDetail.comment = (this.printerDetail.comment ? this.printerDetail.comment : "");

        
        if (this.printerDetail.printerId) {
          this.printerService.editPrinter(this.printerDetail, this.printerDetail.printerId).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
        }
        else {

          this.printerService.newPrinter(this.printerDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));

        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a printer name`, MessageSeverity.warn);
      }
    }
  }
    onSaveSuccesful(): void {

      this.printerModal.hide(); 
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.loadData();
    }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

}
