import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { WarehouseApplicationDefault } from '../../../models/warehouse-application-settings.model';
import { WarehouseUserDetails } from '../../../models/warehouse-user.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';

@Component({
  selector: 'ITS-User-settings',
  templateUrl: './settings.ITS-Users.component.html',
  styleUrls: ['./settings.ITS-Users.component.scss'],
  animations: [fadeInOut]
})
export class ITSUserSettingsComponent {

  rows: any[];
  userrows: any[];
  enableUserEdit: boolean = false;
  enableUserEditIndex: number | null = null;
  loadingIndicator: boolean = false;
  isSaving: boolean = false;
  isUserCollapsed: boolean = true;
  users: WarehouseUserDetails[] = [];
  selectedUser: WarehouseUserDetails | null = null;
  usersCache: WarehouseUserDetails[] = [];


  @ViewChild('userModal')
  userModal: ModalDirective;

  constructor(private http: HttpClient, private alertService: AlertService, private fowkesService: FowkesOnlineService) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getITSWarehouseUsers().subscribe(
      (x) => this.onUsersLoadSuccessful(x),
      (error) => this.onDataLoadFailed(error)
    );
  }

  onUsersLoadSuccessful(WarehouseUser: WarehouseUserDetails[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.users = WarehouseUser;
    this.usersCache = [...this.users];

  }


  onSearchChanged(value: string) {
    if (value != "") {
      var filterValues = this.usersCache.filter(r => Utilities.searchArray(value, false, r.firstName, r.lastName, r.userName));
      this.users = filterValues;
    }
  }

  selectUser(user: WarehouseUserDetails): void {
    this.selectedUser = { ...user };


    if (!this.userModal) {
      setTimeout(() => {
        if (this.userModal) {
          this.userModal.show();
        }
      });
    }
    else {
      this.userModal.show();
    }
  }



  saveUser(): void {
    if (this.selectedUser) {
      this.fowkesService.updateITSWarehouseUser(this.selectedUser).subscribe(() => {
        this.fowkesService.getITSWarehouseUsers().subscribe(
          (x) => this.onUsersLoadSuccessful(x),
          (error) => this.onDataLoadFailed(error)
        );
        this.selectedUser = null;
      });
    }
  }

  enableUserEditMethod(row: any, i: number) {
    this.enableUserEdit = true;
    this.enableUserEditIndex = i;
  }

  saveUserSegment(row: any) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.fowkesService.updateITSWarehouseUser(row).subscribe(
      () => this.onUpdateSuccesful(),
      (error) => this.saveFailedHelper(error)
    );
  }

  onUpdateSuccesful() {
    this.isSaving = false;
    this.enableUserEdit = false;
    this.enableUserEditIndex = null;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Updated successfully', MessageSeverity.success);
    this.loadData();
  }

  saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occurred whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  toggleUserSection() {
    this.isUserCollapsed = !this.isUserCollapsed;
  }

}
