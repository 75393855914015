
export class PurchaseOrderList {
  constructor() {
   
  }

  public purchaseOrder: string;
  public descr: string;
  public customer: string;
  public status: string;
  public salesOrder: string;
  public supplier: string;
  public supplierName: string;
  public poNumber: string;
  public printed: boolean;
}

