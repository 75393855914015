import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { Video } from '../../models/video.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'newvideos',
    templateUrl: './products.newvideos.component.html',
    styleUrls: ['./products.newvideos.component.scss'],
    animations: [fadeInOut]
})
export class NewVideosComponent {

  public formResetToggle = true;

  private video: Video = new Video();

  private isEditMode: boolean;

  private isSaving: boolean;

  @ViewChild('userName')
  private userName;

  @ViewChild('jobTitle')
  private jobTitle;

  @ViewChild('sequence')
  private sequence;

  @ViewChild('url')
  private url;

  @ViewChild('ispreview')
  private ispreview;

  @ViewChild('detail')
  private detail;

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private accountService: AccountService, private authService: AuthService) {
  }

  ngOnInit() {
    this.isEditMode = true;
  }


  //ngOnDestroy() {
  //}

  newVideo() {
    //this.isGeneralEditor = true;
    //this.isNewUser = true;

    //this.allRoles = [...allRoles];
    //this.allBranches = [...allBranches];
    //this.allApplications = [...allApplications];
    //this.editingUserName = null;
    //this.user = this.userEdit = new UserEdit();
    //this.userEdit.isEnabled = true;
    //this.edit();

    //console.log(this.userEdit);
    //return this.userEdit;
  }


  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    this.video.createdBy = this.authService.currentUser.email;

    this.sysproService.newVideo(this.video).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));

    //if (this.isEditingSelf) {
    //  //Set local branch storage
    //  this.localStorage.saveSyncedSessionData(this.userEdit.branch, `${TodoDemoComponent.DBKeyTodoDemo}:${this.currentUserId}`);
    //  }
    //}

    //if (this.isNewUser) {
    //  this.userEdit.sysproSessionId = ""; //Requires SysproSessionId
    //  let configString = '{"language":"en","themeId":15,"homeUrl":"/","showDashboardStatistics":false,"showDashboardNotifications":false,"showDashboardTodo":false,"showDashboardBanner":false,';
    //  configString = configString + '"applicationName":"' + this.userEdit.aspnetApplication + '", "branchCode":"' + this.userEdit.branch + '", "loadCache":' + (this.userEdit.branch == "CT" ? 'true' : 'false') + '}';
    //  this.userEdit.configuration = configString;
    //  console.log("NEW USER CONFIG", this.userEdit.configuration);
    //  this.accountService.newUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));
    //} else {
    //  console.log("EXISITING USER CONFIG", this.userEdit.configuration);
    //  this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    //}
  }

  private saveSuccessHelper() {

    //this.savedLineCount++;
    //if (this.saveCount === this.savedLineCount) {
    //  this.cartSaved = true;

    //  this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.isSaving = false;
    this.isEditMode = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', ` Updated successfully`, MessageSeverity.success);

  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
}
