import { ValueHelper } from "./valuehelper.model";

export class BarcodeReference {
  
  constructor() {

  }

  public invoice: string;
  public orderDate: Date;
  public fileNumber: number;
  public barcode: string;
  public prefix: string;
  public fileName: string;
  public branch: string;
  public status: string;
  public barcodeDate: Date;
  public month: string;
  public day: string;
  public barcodeEntryDateString: string;
  public lastSyncedDate: Date;
  public hasBuyouts: boolean;
  public exclude: boolean;
  public noInvoice: boolean;
  public company: string;
  public numberOfFiles: number;
  public documentType: string;
  public interWhSale: string;
  public invTerms: string;
  public orderStatus: string;
  public staffAccount: boolean;
  public fileNumbers: ValueHelper[];
  public bnumber: ValueHelper;
  public customer: string;
  public customerName: string;
  public operator: string;
  public shippingInstructions: string;
  
}
