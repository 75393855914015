
export class WebOrder {
  constructor() {

  }

  id: number;
  user_id: number;
  order_status: string;
  placed_id: string;
  hash: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_contact_number: string;
  is_company_order: boolean;
  vat: string;
  total_price: string;
  recipient_name: string;
  recipient_contact_number: string | null;
  delivery_street_address_1: string | null;
  delivery_street_address_2: string | null;
  delivery_company: string | null;
  delivery_suburb: string | null;
  delivery_city: string | null;
  delivery_postal_code: string | null;
  delivery_province: string | null;
  company_name: string | null;
  vat_number: string | null;
  billing_address: string | null;
  shipping_option: string | null;
  shipping_fee: string | null;
  placed: string; // datetime
  delivered: string; // datetime
  created: string; // datetime
  modified: string; // datetime
  sub_total: number;
  grand_total: number;

  quoteNumber: string;
  salesOrder: string;
  invoice: string;
  internalStatus: string;
  displayStatus: string;


 internalComment: string;
  statusOverride: boolean;
  createDate: Date;

  sequenceNumber: number;

}

