export class ITSParcelTracking {
  id: number;
  driverCode: string;
  vehicleNumber: string;
  cageNumber: string;
  totalParcels: number;
  totalParcelsUndelivered: number;
  trackingInsertDate: Date;
}

export class ITSParcelTrackingDetail {
  id: number;
  trackingHeaderId: number;
  invoiceNumber: string;
  salesOrder: string;
  scannedStatus: string;
  scannedCount: number;
  scannedDate: Date;
  fromCompany: string;
  operator: string;
  undeliveredStatus: string | null;
  undeliveredDate: Date | null; 
  undeliveredCount: number | null;
  undeliveredUser: string | null;
  undeliveredReason: string | null;
  customer: string;
  shipToAddr1: string;
  shipToAddr2: string;
  shipToAddr3: string;
  shipToAddr4: string;
  shipToAddr5: string;
  shipPostalCode: string;
  shippingInstrs: string;
  specialInstrs: string;
}


