
export class ITSLabelPrinting {
  public id: number;
  public invoiceNumber: string;
  public operator: string;
  public salesOrder: string;
  public insertDate: Date;
  public printed: boolean;
  public numberOfLabels: number;
  public printedDate: Date;
  public status: string;
  public despatchParcels: string;
  public printedTo: string;

}

