import { CMStockCode } from "./cms-stockcode.model";

export class CMCategories {
  constructor(categoryName?: string, parentCategoryName?: string, categoryImage?: string, sequence?: number, statusIndicator?: string, keywords?: string,
    title?: string, metaDescription?: string, categoryImageChanged?: Date, parentCategoryId?: number, modifiedDate?: Date, createDate?: Date) {
    this.categoryName = categoryName;
    this.parentCategoryName = parentCategoryName;
    this.categoryImage = categoryImage;
    this.sequence = sequence;
    this.statusIndicator = statusIndicator;
    this.keywords = keywords;
    this.title = title;
    this.metaDescription = metaDescription;
    this.categoryImageChanged = categoryImageChanged;
    this.parentCategoryId = parentCategoryId;
    this.modifiedDate = modifiedDate;
    this.createDate = createDate;
  }

  public json: string;
  public categoryName: string;
  public parentCategoryName: string;
  public parentCategoryId: number;
  public categoryImage: string;
  public sequence: number;
  public statusIndicator: string;
  public keywords: string;
  public title: string;
  public metaDescription: string;
  public id: number;
  public createDate: Date;
  public modifiedDate: Date;
  public createdBy: string;
  public tree: string;
  public stockCodes: CMStockCode[];
  public filePath: string;
  public categoryImageChanged: Date;//null;
}
