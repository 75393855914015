import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { count } from 'console';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { empty } from 'rxjs';
import { groupBy } from 'rxjs/operators';
import { Branch } from '../../../models/branch.model';
import { DebtorsReleaseData } from '../../../models/debtors-release-data.model';
import { DebtorsReleaseOverview, ICompanyTotals, ICustomerTotals, IDataDetails, IOperator } from '../../../models/debtors-release-overview.model';
import { DebtorsReleaseSummary } from '../../../models/debtors-release-summary.model';
import { localStorageActions } from '../../../models/enums';
import { SysproUser } from '../../../models/syspro-user.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'reports-debtors-release',
  templateUrl: './reports-debtors-release-component.html',
  styleUrls: ['./reports-debtors-release-component.scss']
})
export class ReportsDebtorsReleaseComponent implements OnInit {


  private loadingIndicator: boolean;

  columns: any[] = [];
  rows: DebtorsReleaseData[] = [];
  rowsCache: DebtorsReleaseData[] = [];

  rowsSummary: DebtorsReleaseSummary[] = [];
  rowsCacheSummary: DebtorsReleaseSummary[] = [];

  @ViewChild('netTemplate')
  netTemplate: TemplateRef<any>;

  @ViewChild('totalNetMerchandiseCell') totalNetMerchandiseCell: TemplateRef<any>;

  @ViewChild('gpTemplate')
  gpTemplate: TemplateRef<any>;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  operatorModel: IOperator;
  consolidatedDataSet: IOperator[] = [];

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  @ViewChild('accordion') Accordion: MatAccordion;

  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];


  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild('operators')
  private operators;

  @ViewChild('operatorSelector')
  private operatorSelector;
  allOperators: SysproUser[] = [];

  selectedBranch: string;
  selectedOperator: string;
  selectedCompany: string;
  selectedDocumentType: string;
  totalNetMerchandiseSumm: number;
  totalInvoiceCostSumm: string;
  totalInvoiceSumm: string;
  uniqueListOfOperators: DebtorsReleaseSummary[];
  public isCollapsed: boolean = true;
    consolidatedDataSetCache: IOperator[];

  selectedSalesOrder: string;
    reportTotalSales: number;
    reportTotalCount: number;
    

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService) {
  }

  ngAfterViewInit() {
    this.columns = [
      { prop: 'operator', name: 'Operator', width: 100, cellClass: "left" },
      { prop: 'company', name: 'Comp.', width: 100, cellClass: "left" },
      { prop: 'customer', name: 'Customer', width: 100, cellClass: "left" },
      { prop: 'customerName', name: 'Name', width: 200, cellClass: "left" },
      //{ prop: 'branch', name: 'Branch', width: 50, cellClass: "left", summaryFunc: () => null, summaryTemplate: this.totalNetMerchandiseCell },
      { prop: 'invoiceCount', name: 'Invoices', width: 80, cellClass: "center" },
      { prop: 'netSales', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells) }

    ];

  }

  summaryTotal(cells: number[]): string {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += cell), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  ngOnInit() {

    this.selected = {
      startDate: moment().subtract(1, 'week').startOf('week'),
      endDate: moment().subtract(0, 'week').endOf('week')
    }


    this.columns = [
      { prop: 'operator', name: 'Operator', width: 100, cellClass: "left" },
      { prop: 'company', name: 'Comp.', width: 100, cellClass: "left" },
      { prop: 'customer', name: 'Customer', width: 100, cellClass: "right" },
      { prop: 'customerName', name: 'Name', width: 100, cellClass: "right" },
      //{ prop: 'branch', name: 'Branch', width: 50, cellClass: "left", summaryFunc: () => null, summaryTemplate: this.totalNetMerchandiseCell },
      { prop: 'invoiceCount', name: 'Invoices', width: 80, cellClass: "left" },
      { prop: 'netSales', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells) }

    ];

    this.loadData();

  }


  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.allBranches = [];

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.consolidatedDataSet = [];

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    this.sysproService.getDebtorsReleaseSummary(startD.toJSON(), endD.toJSON()).subscribe(x => this.onSummarySuccesful(x), error => this.onDataLoadFailed(error));

    this.sysproService.getDebtorsReleaseData(startD.toJSON(), endD.toJSON()).subscribe(x => this.onDataSuccesful(x), error => this.onDataLoadFailed(error));
  }

  Reload() {
    if (this.selectedCompany && this.selectedCompany != "ALL") {

      this.rows = this.rowsCache.filter(x => x.company == this.selectedCompany);
      this.rowsSummary = this.rowsCacheSummary.filter(x => x.company == this.selectedCompany);      

    }
    else {
      this.rows = this.rowsCache;
      this.rowsSummary = this.rowsCacheSummary;
    }

    if (this.selectedBranch && this.selectedBranch != "ALL") {

      this.rows = this.rows.filter(x => x.branch == this.selectedBranch);
      this.rowsSummary = this.rowsSummary.filter(x => x.branch == this.selectedBranch);

    }
    else {
      this.rows = this.rows;
      this.rowsSummary = this.rowsSummary;
    }

    this.formatData();
  }
  

  onDataSuccesful(x: DebtorsReleaseData[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;


    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });


      this.rowsCache = [...details];

      if (this.selectedCompany && this.selectedCompany != "ALL") {

        this.rows = this.rowsCache.filter(x => x.company == this.selectedCompany);

      }
      else {
        this.rows = this.rowsCache;
      }

      if (this.selectedBranch && this.selectedBranch != "ALL") {

        this.rows = this.rows.filter(x => x.branch == this.selectedBranch);

      }
      else {
        this.rows = this.rows;
      }

      this.formatData();

    }
    else {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }

  private formatData() {

    this.consolidatedDataSet = [];
    var id = 0;
    var index = 0;

    this.reportTotalCount = this.rows.length;
    this.reportTotalSales = 0;
    this.rows.forEach(z => this.reportTotalSales += z.netMerchandise);

    var distinctOprators = Array.from(new Set(this.rows.map((item: any) => item.operator)));


    for (const operator of distinctOprators) {

      let emptyOperator: IOperator = {
        id: 0,
        operator: "",
        invoiceCountOperator: 0,
        username: "",
        invoiceValueOperator: 0,
        companyTotals: []
      };

      var totalOperator = 0;
      id++;
      emptyOperator.id = id;
      emptyOperator.operator = operator;

      var operatorDetailList = this.rows.filter(o => o.operator == operator);

      emptyOperator.invoiceCountOperator = operatorDetailList.length;
      operatorDetailList.forEach(o => totalOperator += o.netMerchandise);
      emptyOperator.invoiceValueOperator = totalOperator;

      var companyIndex = 0;
      // var distinctCompaniesForOperator = Array.from(new Set(operatorDetailList.map((item: any) => item.company)));
      const uniqueCompaniesForOperator = [...new Set(operatorDetailList.map(item => item.company))];

      for (var c = 0; c < uniqueCompaniesForOperator.length; c++) {

        var totalOperatorCompany = 0;
        var company = uniqueCompaniesForOperator[c];
        var companyDetailList = operatorDetailList.filter(o => o.company == company);
        let emptyCompany: ICompanyTotals = {
          company: "",
          companyInvoiceCount: 0,
          companySalesValue: 0,
          customerTotals: [],
          isCollapsed: true
        };

        emptyCompany.company = company;
        emptyCompany.companyInvoiceCount = companyDetailList.length;

        companyDetailList.forEach(a => totalOperatorCompany += a.netMerchandise);
        emptyCompany.companySalesValue = totalOperatorCompany;
        emptyCompany.isCollapsed = true;
        var customerIndex = 0;
        //var distictCustomersForCompany = Array.from(new Set(companyDetailList.map((c: any) => c.customer)));
        const uniqueCustomersForCompany = [...new Set(companyDetailList.map(item => item.customer))];


        for (var cust = 0; cust < uniqueCustomersForCompany.length; cust++) {
          var totalCustomerSales = 0;
          var customer = uniqueCustomersForCompany[cust];
          var customerDetailList = companyDetailList.filter(o => o.customer == customer);
          var firstRowForCustomer = customerDetailList[0];

          let emptyCustomer: ICustomerTotals = {
            customer: "",
            customerAvgSales: 0,
            customercreditLimit: 0,
            customercurrentBalance: 0,
            customercustomerOnHold: "",
            customerInvoiceCount: 0,
            customerName: "",
            masterAccount: "",
            customerOverLimit: 0,
            customerSalesValue: 0,
            isCollapsed: true,
            dataDetails: []
          };

          emptyCustomer.customer = customer;
          emptyCustomer.customerName = firstRowForCustomer.customerName;
          emptyCustomer.customercreditLimit = firstRowForCustomer.creditLimit;
          emptyCustomer.customerOverLimit = firstRowForCustomer.overLimit;
          emptyCustomer.customercurrentBalance = firstRowForCustomer.currentBalance;
          emptyCustomer.customercustomerOnHold = firstRowForCustomer.customerOnHold;
          emptyCustomer.customerAvgSales = firstRowForCustomer.avgSales3M;
          emptyCustomer.masterAccount = firstRowForCustomer.masterAccount;

          emptyCustomer.customerInvoiceCount = customerDetailList.length;
          customerDetailList.forEach(b => totalCustomerSales += b.netMerchandise);
          emptyCustomer.customerSalesValue = totalCustomerSales;

          var invoiceIndex = 0;

          emptyCustomer.dataDetails = [];
          for (var invoice of customerDetailList) {
            emptyCustomer.dataDetails.push({
              branch: invoice.branch,
              invoice: invoice.invoice,
              invoiceSalesValue: invoice.netMerchandise,
              invoiceDate: invoice.createDate
            });

            invoiceIndex++;
          }


          customerIndex++;
          emptyCompany.customerTotals.push(emptyCustomer);
        }
        emptyCompany.customerTotals.sort((a, b) => b.customerSalesValue - a.customerSalesValue);
        companyIndex++;
        emptyOperator.companyTotals.push(emptyCompany);
      }
      this.consolidatedDataSet.push(emptyOperator);
      index++;
    }

    this.consolidatedDataSetCache = this.consolidatedDataSet;
  }

  closeAllPanels() {
    this.Accordion.closeAll();
  }
  openAllPanels() {
    this.Accordion.openAll();
  }

  onSummarySuccesful(x: DebtorsReleaseSummary[]): void {
    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.rowsCacheSummary = [...details];

      if (this.selectedCompany && this.selectedCompany != "ALL") {

        this.rowsSummary = this.rowsCacheSummary.filter(x => x.company == this.selectedCompany);

      }
      else {
        this.rowsSummary = this.rowsCacheSummary;
      }

      if (this.selectedBranch && this.selectedBranch != "ALL") {

        this.rowsSummary = this.rowsSummary.filter(x => x.branch == this.selectedBranch);

      }
      else {
        this.rowsSummary = this.rowsSummary;
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }





  onSearchChanged(value: string) {
    if (value != "") {
       this.consolidatedDataSet = this.consolidatedDataSetCache.filter(r => Utilities.searchArray(value, false, r.operator));    
    }
    else {
      this.consolidatedDataSet = this.consolidatedDataSetCache;
    }
  }


  //onSearchChangedCustomer(value: string) {
  //  if (value != "") {
  //    this.consolidatedDataSet = this.consolidatedDataSetCache.filter(
  //      (x) =>
  //        x.companyTotals.find(
  //          (y) => y.customerTotals.find(
  //            (z) => z.customer.toLowerCase().indexOf(value.toLowerCase()) > -1
  //            )
  //        )
  //    );
  //  }
  //  else {
  //    this.consolidatedDataSet = this.consolidatedDataSetCache;
  //  }
  //}


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onOperatorsLoadSuccessful(operators: SysproUser[]): void {

    this.allOperators = [...this.allOperators, ...operators];
    setTimeout(() => {
      this.operatorSelector.refresh();
    });
  }


  onAppDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve company data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;

    this.loadData();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;

    this.loadData();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;

    this.loadData();
  }

  exportSummary() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Debtor", "Company", "Customer", "CustomerName", "Invoices", "Sales Ex Vat",
        "Customer Credit Limit", "Customer Current Balance", "Customer Avg Sales"]
    };

    var filename = "DebtorsSummary";
    var selectRows = [...this.rowsSummary];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Debtor: row.operator, Company: row.company, Customer: row.customer, FullName: row.customerName,
        Invoices: row.invoiceCount, Sales: row.netSales, CreditLimit: row.creditLimit, Balance: row.currentBalance, AVG: row.avgSales3M
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  exportDetails() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Debtor", "Company", "Customer", "CustomerName", "Branch", "SalesRep", "RepName", "CreditLimit", "Date", "Invoice", "Sales Ex Vat"]
    };

    var filename = "DebtorsOrderDetails";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Debtor: row.operator, Company: row.company, Customer: row.customer, FullName: row.customerName, Branch: row.branch, SalesRep: row.salesRep, RepName: row.salesPersonName, CreditLimit: row.creditLimit,
        Date: row.createDate,
        Invoice: row.invoice, Sales: row.netMerchandise
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  previewSalesOrder(invoice: string, customer: string) {

    this.selectedSalesOrder = invoice;

    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != invoice) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber,  invoice);
    }

    if (!!this.selectedSalesOrder) {
      if (!this.salesOrderModal) {
        setTimeout(() => {
          if (this.salesOrderModal) {
            this.salesOrderModal.show();
          }
        });
      }
      else {
        this.salesOrderModal.show();
      }
    }
  }

  salesOrderModalHide() {
    this.salesOrderModal.hide();
  }


  viewSalesOrder() {

    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  }
}
