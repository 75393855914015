import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
//import { AccountService } from '../../services/account.service';
import { Utilities } from '../../../services/utilities';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { Branch } from '../../../models/branch.model';
import { QuoteSalesReport } from '../../../models/quote-sales-report.model';
import { CreditNoteSalesReport } from '../../../models/creditnote-report.model';

import { AspnetApplications } from '../../../models/applications.model';
//import { Permission } from '../../models/permission.model';
import { SysproService } from '../../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv';
import { SalesReportData } from '../../../models/sales-report.model';
import { SysproUser } from '../../../models/syspro-user.model';
import { SalesReportSummary } from '../../../models/sales-report-summary.model';
import { MySQLDocument } from '../../../models/mysqldocument.model';


@Component({
  selector: 'reports-sales',
  templateUrl: './reports-sales-component.html',
  styleUrls: ['./reports-sales-component.scss']
})
export class ReportsSalesComponent implements OnInit {


  private loadingIndicator: boolean;

  columns: any[] = [];
  rows: SalesReportSummary[] = [];
  rowsCache: SalesReportSummary[] = [];

  @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('netTemplate')
  netTemplate: TemplateRef<any>;

  @ViewChild('totalNetMerchandiseCell') totalNetMerchandiseCell: TemplateRef<any>;

  @ViewChild('costTemplate')
  costTemplate: TemplateRef<any>;

  @ViewChild('profitTemplate')
  profitTemplate: TemplateRef<any>;

  @ViewChild('gpTemplate')
  gpTemplate: TemplateRef<any>;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];


  @ViewChild('operators')
  private operators;

  @ViewChild('operatorSelector')
  private operatorSelector;
  allOperators: SysproUser[] = [];

  selectedBranch: string;
  selectedOperator: string;
  selectedCompany: string;
  selectedDocumentType: string;
    totalNetMerchandiseSumm: number;
    totalInvoiceCostSumm: string;
    totalInvoiceSumm: string;
  

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService) {
  }

  ngAfterViewInit() {
    this.columns = [
      { prop: 'branch', name: 'Branch', width: 50, cellClass: "left", summaryFunc: () => null, summaryTemplate: this.totalNetMerchandiseCell },
      { prop: 'operator', name: 'Operator', width: 80, cellClass: "left" },
      { prop: 'fullName', name: 'Name', width: 170, cellClass: "left" },
      { prop: 'docType', name: 'Type', width: 50, cellClass: "left" },
      { prop: 'totalRecords', name: 'Invoices', width: 80, cellClass: "left" },
      { prop: 'totalNumberOfLines', name: 'Lines', width: 80, cellClass: "right" },
      { prop: 'totalNetMerchandise', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells) },
      { prop: 'totalInvoiceCost', name: 'Total Cost', width: 130, cellClass: "right", cellTemplate: this.costTemplate, summaryFunc: cells => this.summaryTotal(cells) },
      { prop: 'totalInvoiceProfit', name: 'Total Profit', width: 120, cellClass: "right", cellTemplate: this.profitTemplate, summaryFunc: cells => this.summaryTotal(cells) },
      { prop: 'totalInvoiceGP', name: 'GP %', width: 90, cellClass: "right", cellTemplate: this.gpTemplate, summaryFunc: () => null }

    ];
  }

  summaryTotal(cells: number[]): string {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += cell), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


  ngOnInit() {


    this.columns = [
      //{ prop: 'company', name: 'Comp.', width: 50, cellClass: "left" },
      {
        prop: 'branch', name: 'Branch', width: 50, cellClass: "left", summaryFunc: () => null, summaryTemplate: this.totalNetMerchandiseCell},
      { prop: 'operator', name: 'Operator', width: 80, cellClass: "left" },
      { prop: 'fullName', name: 'Name', width: 170, cellClass: "left" },
      { prop: 'docType', name: 'Type', width: 50, cellClass: "left" },
      { prop: 'totalRecords', name: 'Invoices', width: 80, cellClass: "left" },
      { prop: 'totalNumberOfLines', name: 'Lines', width: 80, cellClass: "right" },
    //  { prop: 'totalGrossMerchandise', name: 'Gross Merch', width: 80, cellClass: "right", cellTemplate: this.percentTemplate },
      //{ prop: 'totalDiscountMerchandise', name: 'Discount Merch', width: 80, cellClass: "right", cellTemplate: this.percentTemplate },
      { prop: 'totalNetMerchandise', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells)  },
      //{ prop: 'totalTaxValue', name: 'Tax', width: 60, cellClass: "right", cellTemplate: this.percentTemplate },
      //{ prop: 'totalInvoiceValue', name: 'Total Invoice', width: 100, cellClass: "right", cellTemplate: this.percentTemplate },
      { prop: 'totalInvoiceCost', name: 'Total Cost', width: 130, cellClass: "right", cellTemplate: this.costTemplate, summaryFunc: cells => this.summaryTotal(cells)  },
      { prop: 'totalInvoiceProfit', name: 'Total Profit', width: 120, cellClass: "right", cellTemplate: this.profitTemplate, summaryFunc: cells => this.summaryTotal(cells) },
      { prop: 'totalInvoiceGP', name: 'GP %', width: 90, cellClass: "right", cellTemplate: this.gpTemplate, summaryFunc: () => null}

    ];

    this.selected = {
      startDate: moment().subtract(2, 'year').startOf('year'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

   

    this.loadData();

    //this.loadDocuments();
  }

  loadDocuments() {

    //this.sysproService.getDocuments().subscribe(x => this.onLoadDocumentsSuccessful(x), error => this.onDataLoadFailed(error));

    //this.sysproService.getDocumentsPerMonth("2020", "07").subscribe(x => this.onLoadDocumentsSuccessful(x), error => this.onDataLoadFailed(error));

    //this.sysproService.getDocumentByPrimaryKey("JB055485", "POD").subscribe(x => this.onLoadDocumentsSuccessful(x), error => this.onDataLoadFailed(error));
    
    //this.fowkesOnline.getDocuments().subscribe(x => this.onLoadDocumentsSuccessful(x), error => this.onDataLoadFailed(error));      
  }

  onLoadDocumentsSuccessful(document: MySQLDocument[]): void {

    this.alertService.startLoadingMessage();
  }

    loadData() {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.selected = {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day')
      }

      let startD: Date = new Date(this.selected.startDate.toString());
      let endD: Date = new Date(this.selected.endDate.toString());

      let defaultBranch = new Branch();
      defaultBranch.branch = this.selectUndefinedOptionValue;
      this.allBranches.push(defaultBranch);
      this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

      let defaultOperator = new SysproUser();
      defaultOperator.sysproOperator = this.selectUndefinedOptionValue;
      defaultOperator.fullName = "All Operators";
      this.allOperators.push(defaultOperator);
      this.sysproService.getSysproUserDetailList().subscribe(x => this.onOperatorsLoadSuccessful(x), error => this.onDataLoadFailed(error));      

      this.sysproService.getSalesReportSummary(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

      // this.sysproService.getSalesReportData(startD.toJSON(), endD.toJSON()).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
     
  }

  LoadSummary() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);
    let operator = (this.selectedOperator ? this.selectedOperator : this.selectUndefinedOptionValue);
    let documentType = (this.selectedDocumentType ? this.selectedDocumentType : this.selectUndefinedOptionValue);

    this.sysproService.getSalesReportSummary(startD.toJSON(), endD.toJSON(),
      company.toUpperCase(), branch.toUpperCase(), operator.toUpperCase(), documentType.toUpperCase()).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }

  exportDetails() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);
    let operator = (this.selectedOperator ? this.selectedOperator : this.selectUndefinedOptionValue);
    let documentType = (this.selectedDocumentType ? this.selectedDocumentType : this.selectUndefinedOptionValue);

    this.sysproService.getSalesReportData(startD.toJSON(), endD.toJSON(),
      company.toUpperCase(), branch.toUpperCase(), operator.toUpperCase(), documentType.toUpperCase()).subscribe(x => this.results(x), error => this.onDataLoadFailed(error));

  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.docType, r.company, r.branch,
        r.operator, r.fullName, r.totalRecords, r.totalNumberOfLines, r.totalGrossMerchandise, r.totalDiscountMerchandise, r.totalNetMerchandise, r.totalTaxValue,
        r.totalInvoiceValue,  r.totalInvoiceCost, r.totalInvoiceProfit, r.totalInvoiceGP));
    }
  }
  onSearchSuccesful(x: SalesReportSummary[]): void {
    console.log(x);
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.rowsCache = [...details];
      this.rows = details;

      this.rows.forEach((item) => {
        this.totalNetMerchandiseSumm += item.totalNetMerchandise;
        this.totalInvoiceCostSumm += item.totalInvoiceCost;
        this.totalInvoiceSumm += item.totalInvoiceProfit;
      });
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches,...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onOperatorsLoadSuccessful(operators: SysproUser[]): void {

    this.allOperators = [...this.allOperators, ...operators];
    setTimeout(() => {
      this.operatorSelector.refresh();
    });
  }


  onAppDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve company data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }
   
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
  }

  exportSummary() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["DocType", "Company", "Operator", "FullName", "Branch", "TotalRecords",
        "TotalNumberOfLines", "TotalGrossMerchandise", "TotalDiscountMerchandise",
        "TotalNetMerchandise", "TotalTaxValue", "TotalInvoiceValue", "TotalInvoiceCost", "TotalInvoiceProfit", "TotalInvoiceGP"]
    };

    var filename = "Sales Data";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        DocType: row.docType, Company: row.company, Operator: row.operator, FullName: row.fullName, Branch: row.branch,
        TotalRecords: row.totalRecords, TotalNumberOfLines: row.totalNumberOfLines,
        TotalGrossMerchandise: row.totalGrossMerchandise, TotalDiscountMerchandise: row.totalDiscountMerchandise,
        TotalNetMerchandise: row.totalNetMerchandise,
        TotalTaxValue: row.totalTaxValue, TotalInvoiceValue: row.totalInvoiceValue,
        TotalInvoiceCost: row.totalInvoiceCost, TotalInvoiceProfit: row.totalInvoiceProfit, TotalInvoiceGP: row.totalInvoiceGP
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  results(x: SalesReportData[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      let results = [...details];
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        noDownload: false,
        headers: ["DocType", "Company", "Operator", "Branch", "Invoice", "Customer", "Rep",
          "NumberOfLines", "GrossMerchandise", "DiscountMerchandise",
          "NetMerchandise", "TotalTax", "InvoiceTotal", "InvoiceCost", "InvoiceProfit", "InvoiceGP"]
      };

      var filename = "Sales Data Details";
      var selectRows = [...results];
      var exportRows = [];
      for (var row of selectRows) {

        exportRows.push({
          DocType: row.docType, Company: row.company, Operator: row.operator, Branch: row.branch,
          Invoice: row.invoice, Customer: row.customer, Rep: row.rep,
          NumberOfLines: row.numberOfLines,
          GrossMerchandise: row.grossMerchandise, DiscountMerchandise: row.discountMerchandise,
          NetMerchandise: row.netMerchandise,
          TotalTax: row.totalTax, InvoiceTotal: row.invoiceTotal,
          InvoiceCost: row.invoiceCost, InvoiceProfit: row.invoiceProfit, InvoiceGP: row.invoiceGP
        });
      }

      return new ngxCsv(exportRows, filename, options);
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

    
  }

}
