import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { localStorageActions } from '../../../models/enums';
import { ITSLabelPrinting } from '../../../models/invoice-tracking-label-printing.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';

@Component({
  selector: 'tracking-print-labels',
  templateUrl: './tracking-print-labels.component.html',
  styleUrls: ['./tracking-print-labels.component.scss'],
  animations: [fadeInOut]
})
export class TrackingPrintLabelsComponent {

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('printedTemplate')
  printedTemplate: TemplateRef<any>;

  @ViewChild('labelModal')
  labelModal: ModalDirective;

  @ViewChild('itsModal')
  itsModal: ModalDirective;

  emptyDetails: boolean;

  rows: ITSLabelPrinting[] = [];
  rowsCache: ITSLabelPrinting[];

  labelDetail: ITSLabelPrinting = new ITSLabelPrinting();

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
    src: string;
    selectedInvoice: any;
    currentStatus: any;
    invoiceNumber: any;

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private fowkesService: FowkesOnlineService,  private configurations: ConfigurationService) {
  }

  ngOnInit() {

    this.setData();

    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }

    this.loadData();

  }

  ngAfterViewInit() {

    this.setData();
  }

  private setData() {
    this.columns = [
      { prop: 'insertDate', name: 'Date', width: 140, cellTemplate: this.dateTemplate },
      { prop: 'invoiceNumber', name: 'Invoice', width: 100, cellTemplate: this.invoiceTemplate },
      { prop: 'numberOfLabels', name: '#Labels', width: 80 },
      { prop: 'operator', name: 'Operator', width: 120 },
      { prop: 'printed', name: 'Printed', width: 100, cellTemplate: this.printedTemplate },
      { prop: 'printedTo', name: 'Printer', width: 150 },
      { prop: 'printedDate', name: 'Date Printed', width: 140, cellTemplate: this.dateTemplate },
      { prop: 'despatchParcels', name: '#Parcels', width: 80 }
    ];

    this.columns.push({ name: 'Reprint Label', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }


  toggle() {
    this.show = true;
  }
  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  filterChanged() {

    this.loadData();

  }

  refresh() {

    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }

    this.loadData();
  }

  loadData() {

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());
    // Increase the end date by one day
    endD.setDate(endD.getDate() + 1);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getPrintingByDate(startD.toJSON(), endD.toJSON()).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(labels: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (labels[0]) {

      this.rowsCache = [...labels[0]];
      this.rows = [...this.rowsCache];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }
  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.salesOrder, r.invoiceNumber, r.operator, r.insertDate, r.printed, r.numberOfLabels));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }


  get application(): string {
    return this.configurations.applicationName;
  }



  onPrintAdditionalLabelsClick(row: ITSLabelPrinting, selectedPrinter: string) {
    if (row) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.alertService.showMessage('', `Label printing initiated`, MessageSeverity.success);
      this.fowkesService.printBarcodeLabelDirect(row.invoiceNumber, this.accountService.currentUser.email, row.salesOrder, 1, selectedPrinter).subscribe(
        (response) => {
          this.refresh();
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
        },
        (error) => {
          console.error('Error initiating label printing:', error);
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
        }
      );
    }
  }

  enableEditMethod(row: ITSLabelPrinting, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: ITSLabelPrinting) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
  //  row.createdBy = this.accountService.currentUser.email;
    this.fowkesService.updatePrinting(row.id, row).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  labelModalHide() {
    this.labelDetail = new ITSLabelPrinting();
    this.labelModal.hide();
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  printService() {
    this.fowkesService.RefireLabelPrintingQueue().subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  previewITS(row: ITSLabelPrinting) {

    this.selectedInvoice = row.invoiceNumber;
    this.currentStatus = row.status;
    this.invoiceNumber = row.invoiceNumber;

    
    if (sessionStorage.getItem(localStorageActions.ITSInvoiceNumber) != row.invoiceNumber) {
      sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);
    }

    if (!!this.selectedInvoice) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModalHide() {
    this.selectedInvoice = null;
    if (this.itsModal) {
      this.itsModal.hide();
    }
  }


  gotToITS() {

    this.router.navigate(['../../reports'], { fragment: 'invoicestatus', queryParams: { customer: "FBWEB" } });
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Date", "Invoice", "Operator", "Printed", "Status", "NoOfLabels", "NoOfParcels"]
    };

    var filename = "Barcodes_Printed";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.insertDate).toISOString().split('T')[0];
      let printDate = new Date(row.printedDate).toISOString().split('T')[0];
      exportRows.push({
        Id: row.id, Date: date, Invoice: row.invoiceNumber, Operator: row.operator, Printed: printDate, Status: row.status,
        NoOfLabels: row.numberOfLabels, NoOfParcels: row.despatchParcels
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
