
export class CoreCustomerPrices {
  constructor(id?: number, customerCode?: string, customerId?: number, ruleId?: number, company?: string, stockCode?: string, costPrice?: number
    , sellingPrice?: number, newPrice?: number, supplierCode?: string, category?: string, categoryId?: number, gpValue?: number, gpOverride?: boolean, enabled?: boolean
    , description?: string, ruleName?: string, ruleDescription?: string, value?: number, addValue?: number, addText?: string, uom?: string
    , priority?: number, conversionFactor?: number, status?: string, brandName?: string) {

    this.id = id;
    this.customerCode = customerCode;
    this.customerId = customerId;
    this.ruleId = ruleId;
    this.company = company;
    this.stockCode = stockCode;
    this.costPrice = costPrice;
    this.sellingPrice = sellingPrice;
    this.newPrice = newPrice;
    this.supplierCode = supplierCode;
    this.category = category;
    this.categoryId = categoryId;
    this.gpValue = gpValue;
    this.gpOverride = gpOverride;
    this.gpOverride = gpOverride;
    this.enabled = enabled;
    this.priority = priority;
    this.description = description;
    this.ruleName = ruleName;
    this.ruleDescription = ruleDescription;
    this.value = value;
    this.addValue = addValue;
    this.addText = addText;
    this.uom = uom;
    this.conversionFactor = conversionFactor;
    this.status = status;
    this.brandName = brandName;
  
  }

  public id: number;
  public customerCode: string;
  public customerId: number;
  public ruleId: number;
  public company: string;
  public stockCode: string;
  public description: string;
  public uom: string;
  public costPrice: number;
  public sellingPrice: number;
  public newPrice: number;
  public supplierCode: string;
  public category: string;
  public categoryId: number;
  public gpValue: number;
  public gpOverride: boolean;
  public enabled: boolean;
  public priority: number;
  public ruleName: string;
  public ruleDescription: string;
  public value: number;
  public addValue: number;
  public conversionFactor: number;

  public addText: string;


  public createdBy: string;
  public modifiedBy: string;

  public createdOn: Date;
  public modifiedOn: Date;
  public lastCostChange: Date;

  public brandName: string;
  public status: string;
  public warning: boolean;
}

