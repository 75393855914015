import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { BankImport, BankImportHeader } from '../../../models/bank-import.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';


@Component({
  selector: 'bank-import-management',
  templateUrl: './bank-import-management.component.html',
  styleUrls: ['./bank-import-management.component.scss']
})
export class BankImportManagementComponent implements OnInit {

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  columns: any[] = [];
  detailcolumns: any[] = [];

  rows: BankImportHeader[] = [];
  rowsCache: BankImportHeader[];

  detailRows: BankImport[] = [];
  detailRowsCache: BankImport[] = [];

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('shortdateTemplate')
  shortdateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate')
  statusTemplate: TemplateRef<any>;


  @ViewChild('timeTemplate')
  timeTemplate: TemplateRef<any>;


  @ViewChild('DetailModal')
  DetailModal: ModalDirective;

  selectedFileName: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;
  selectedPeriod: string;
  selectedBank: string;

  loadingIndicator: boolean = false;

  selectedStatuses: string[] = [];
  uniqueStatuses: string[] = [];

  totalCompletedRecords: number = 0;
  totalCompletedPaymentValue: number = 0;


  selected: { startDate: Moment, endDate: Moment };
  show: boolean;


  ranges = {
    'Today': [moment().subtract(0, 'days'), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Term': [moment().subtract(3, 'month').startOf('month'), moment().subtract(0, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()]
  };
      

  constructor(private router: Router, private alertService: AlertService, private fowkesService: FowkesOnlineService, private configurations: ConfigurationService,
    private sysproService: SysproService, private route: ActivatedRoute, private accountService: AccountService) {

  }

  ngOnInit() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.selected = {
      startDate: moment().subtract(0, 'day').startOf('day'),
      endDate: moment().subtract(0, 'day').endOf('day')
    }

    this.setDataGrids();

    this.sysproService.getBankImportOverview().subscribe(x => this.onOverviewLoadSuccesfull(x), error => this.onDataLoadFailed(error));

  }

    onOverviewLoadSuccesfull(x: BankImportHeader[]): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      if (x && x.length >= 1) {
        this.rows = x.map(row => {
          return {
            ...row,
            timeSinceImport: this.calculateTimeSinceImport(row.importDate, row.responseDate, row.importStatus)
          };
        });
        this.rowsCache = this.rows;
      }

      this.uniqueStatuses = [...new Set(this.rows.map(row => row.importStatus))];
      this.selectedStatuses = [...this.uniqueStatuses]; // Initially select all statuses

      this.rowsCache = this.rows;
      this.applyFilters(); // Apply filters based on the initially selected statuses

    }

  applyFilters() {
    this.rows = this.rowsCache.filter(row =>
      this.selectedStatuses.includes(row.importStatus) &&
      moment(row.importDate).isBetween(this.selected.startDate, this.selected.endDate, undefined, '[]')
    );

    // Recalculate totals after filtering
    this.totalCompletedRecords = this.rows.reduce((acc, row) => acc + row.responseCount, 0);
    this.totalCompletedPaymentValue = this.rows.reduce((acc, row) => acc + row.responseValue, 0);

  }

    onStatusFilterChange(status: string) {
      if (this.selectedStatuses.includes(status)) {
        this.selectedStatuses = this.selectedStatuses.filter(s => s !== status);
      } else {
        this.selectedStatuses.push(status);
      }
      this.applyFilters();
    }

    setDataGrids() {
      this.columns = [
        { prop: 'company', name: 'C', width: 30 },
        { prop: 'importDate', name: 'Import Date', width: 150, cellTemplate: this.dateTemplate },
        { prop: 'fileReference', name: 'File', width: 180, cellTemplate: this.selectTemplate },
        { prop: 'importStatus', name: 'Import Status', width: 120 },
        { prop: 'paymentPeriod', name: 'Period', width: 50 },
        { prop: 'numberOfRecords', name: 'Records', width: 50 },
        { prop: 'responseCount', name: 'Completed', width: 50 },
        { prop: 'notes', name: 'Notes', width: 200 },
        { prop: 'timeSinceImport', name: 'Time Tracker', width: 200, cellTemplate: this.timeTemplate },
        { prop: 'responseDate', name: 'Response Date', width: 150, cellTemplate: this.dateTemplate },
        { prop: 'totalPaymentValue', name: 'File Total', width: 100 },
        { prop: 'responseValue', name: 'Completed', width: 100 },
        { prop: 'fileName', name: 'File Name', width: 150 },
        { prop: 'batchId', name: 'Batch ID', width: 100 },
        { prop: 'userName', name: 'User Name', width: 100 },
        //{ name: '', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
      ];

      this.detailcolumns = [
        { prop: 'company', name: 'C', width: 30 },
        { prop: 'customer', name: 'Customer', width: 80 },
        { prop: 'customerName', name: 'Customer Ref', width: 250 },
        { prop: 'bankReference', name: 'Bank Ref', width: 100 },
        { prop: 'paymentDate', name: 'Payment Date', width: 100, cellTemplate: this.shortdateTemplate },
        { prop: 'paymentValue', name: 'Amount', width: 100, cellClass: "right" },
        { prop: 'status', name: 'Status', width: 100, cellClass: "center" },
        { prop: 'message', name: 'Result', width: 150 },
        { prop: 'journal', name: 'Jnl', width: 50 },
        //{ prop: 'userName', name: 'Operator', width: 80 },
        { prop: 'customerAccTerms', name: 'Acc Terms', width: 80 },
        { prop: 'customerApplyAutoPayments', name: 'Auto Payments', width: 80 }
        /* { prop: 'fileName', name: 'FileName', width: 500 }*/
      ];

    }

  ngAfterViewInit() {

    this.setDataGrids();
  }

  viewdetails(row: BankImportHeader) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.selectedFileName = row.fileName;

    this.sysproService.getBankImportByFileName(this.selectedFileName).subscribe(x => this.onHistoryLoadSuccesfull(x), error => this.onDataLoadFailed(error));
  }

  onHistoryLoadSuccesfull(x: BankImport[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {
      this.detailRowsCache = x;
      this.detailRows = [...this.detailRowsCache];

      // Ensure that DetailModal is ready before showing
      setTimeout(() => {
        if (this.DetailModal) {
          this.DetailModal.show();
        } else {
          // Fallback in case DetailModal is still not ready
          setTimeout(() => {
            if (this.DetailModal) {
              this.DetailModal.show();
            }
          }, 500); // Adjust the delay as needed
        }
      });
    }
  }

  DetailModalHide() {

    this.selectedFileName = null;
    this.detailRows = [];
    this.detailRowsCache = [];
  }


  calculateTimeSinceImport(importDate: Date, responseDate: Date, status: string): string {
    const importMoment = moment(importDate);
    let duration: moment.Duration;

    if (status === 'PROCESSING') {
      const now = moment();
      duration = moment.duration(now.diff(importMoment));
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      if (days === 0 && hours === 0 && minutes < 60) {
        return `Processing: ${minutes}m (Still in progress)`;
      } else if (days === 0 && hours < 24) {
        return `Processing: ${hours}h ${minutes}m (Still in progress)`;
      } else {
        return `Processing: ${days}d ${hours}h (Not completed yet, safe to reload)`;
      }
    } else if (status === 'STAGED') {
      return ''; // Don't show anything for STAGED status
    } else {
      duration = moment.duration(moment(responseDate).diff(importMoment));
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      if (days === 0 && hours === 0 && minutes < 60) {
        return 'Completed: in less than an hour';
      } else if (days === 0 && hours < 24) {
        return `Completed: ${hours}h ${minutes}m`;
      } else if (days < 1) {
        return `Completed: Within a day`;
      } else {
        return `Completed: ${days}d ${hours}h ${minutes}m`;
      }
    }
  }



  get application(): string {
    return this.configurations.applicationName;
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.importStatus,
        r.userName,
        r.batchId,
        r.company,
        r.importDate,
        r.paymentPeriod,
        r.fileName,
        r.numberOfRecords,
        r.totalPaymentValue,
        r.hasWarnings,
        r.postedDate,
        r.postedStatus,
        r.postedCount,
        r.postedValue,
        r.responseDate,
        r.responseStatus,
        r.responseCount,
        r.responseValue,
        r.notes));
    }
    else {
      this.rows = this.rowsCache;
    }
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.applyFilters(); // Apply the date range filter to the data
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
    this.applyFilters(); // Apply the date range filter to the data
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.applyFilters(); // Apply the date range filter to the data
  }

  RefreshResults() {

    this.sysproService.getBankImportOverview().subscribe(x => this.onOverviewLoadSuccesfull(x), error => this.onDataLoadFailed(error));
    
  }

  ExportDetails() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Company", "MasterAcc", "Customer", "CustomerName", "Amount", "BankReference", "PaymentDate", "Status", "TrnYear", "TrnMonth", "Journal", "Period", "ApplyAutoPayments", "Message", "Operator", "ImportedFile"]
    };

    var filename = "IMPORTRESULTS";
    var selectRows = [...this.detailRows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.paymentDate).toISOString().split('T')[0];
      let localDate = new Date(row.paymentDate);
      let formattedDate = `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;

      exportRows.push({
        Company: row.company, MasterAcc: row.customerMaster, Customer: row.customer, CustomerName: row.customerName, Amount: row.paymentValue, BankReference: row.bankReference,
        PaymentDate: formattedDate, Status: row.status, TrnYear: row.trnYear, TrnMonth: row.trnMonth, Journal: row.journal,
        Period: row.paymentPeriod, ApplyAutoPayments: row.customerApplyAutoPayments, Message: row.message, Operator: row.userName, ImportedFile: row.fileName
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  ExportResults() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        "Company", "Import Date", "Import Status", "User Name", "Batch ID", "Payment Period",
        "File Name", "Number of Records", "Total Payment Value", 
        "Response Date", "Response Status", "Response Count", "Response Value", "Notes"
      ]
    };

    const filename = "Bank Upload History";
    const selectRows = [...this.rows];
    const exportRows = [];

    for (const row of selectRows) {
      exportRows.push({
        Company: row.company,
        "Import Date": new Date(row.importDate).toISOString().split('T')[0],
        "Import Status": row.importStatus,
        "User Name": row.userName,
        "Batch ID": row.batchId,
        "Payment Period": row.paymentPeriod,
        "File Name": row.fileName,
        "Number of Records": row.numberOfRecords,
        "Total Payment Value": row.totalPaymentValue,       
        "Response Date": row.responseDate ? new Date(row.responseDate).toISOString().split('T')[0] : '',
        "Response Status": row.responseStatus || '',
        "Response Count": row.responseCount || '',
        "Response Value": row.responseValue || '',
        Notes: row.notes
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

}
