export class Brand {
  constructor(brandName?: string, webDisplayName?: string, webDescription?: string, brandImage?: string, brandImageChanged?: Date, webEnabled?: boolean) {
    this.brandName = brandName;
    this.brandImage = brandImage;
    this.brandImageChanged = brandImageChanged;
    this.webDisplayName = webDisplayName;
    this.webDescription = webDescription;
    this.webEnabled = webEnabled;
  }

  public brandName: string;
  public webDisplayName: string;
  public webDescription: string;
  public webEnabled: boolean;
  public brandImage: string;
  public createdDate: string;
  public createdBy: string;
  public id: number;
  public filePath: string;
  public brandImageChanged: Date;//null;
}
