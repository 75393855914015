import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { User } from '../../models/user.model';
import { UserEdit } from '../../models/user-edit.model';
import { Role } from '../../models/role.model';
import { Branch } from '../../models/branch.model';
import { ArCustomer } from '../../models/arcustomer.model';
import { CartHeader } from '../../models/cart-header.model';
import { ArCustomerEdit } from '../../models/arcustomer-edit.model';
import { SalesPerson } from '../../models/salesperson.model';
import { AspnetApplications } from '../../models/applications.model';
import { Permission } from '../../models/permission.model';
import { SysproService } from '../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ArNarration } from 'src/app/models/arnarration.model';
import { localStorageActions } from 'src/app/models/enums';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerSalesConversion } from '../../models/customer-sales-conversion.model';
import { CoreCustomer } from '../../models/core-customer.model';
import { CoreCustomerRule } from '../../models/core-customer-rule.model';


@Component({
  selector: 'syspro-customer-view',
  templateUrl: './syspro-customer-view.component.html',
  styleUrls: ['./syspro-customer-view.component.scss']
})
export class SysproCustomerViewComponent implements OnInit {

  private isEditMode = false;
  private isSaving = false;
  private isNavigating = false;
  private showValidationErrors = false;
  private editingUserName: string;
  uniqueId: string = Utilities.uniqueId();
  private user: User = new User();
  customer: ArCustomer = new ArCustomer();
  private customerEdit: ArCustomerEdit;
  private cartEdit: CartHeader;
  private cartSwitch: CartHeader;
  columns: any[] = [];
  rows: ArNarration[] = [];
  rowsCache: ArNarration[] = [];
  private allSalesPersons: SalesPerson[] = [];

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;


  @ViewChild('f')
  private form;

  // ViewChilds Required because ngIf hides template variables from global scope soldPostalCode
  @ViewChild('customerName')
  private customerName;

  @ViewChild('telex')
  private telex;

  @ViewChild('companyTaxNumber')
  private companyTaxNumber;

  @ViewChild('taxExemptNumber')
  private taxExemptNumber;

  @ViewChild('soldPostalCode')
  private soldPostalCode;

  @ViewChild('shipPostalCode')
  private shipPostalCode;
  
  @ViewChild('email')
  private email;

  @ViewChild('salespersons')
  private salespersons;

  @ViewChild('salespersonSelector')
  private salespersonSelector;

  @ViewChild('sysproOperator')
  private sysproOperator;

  @ViewChild('applications')
  private applications;

  @ViewChild('applicationsSelector')
  private applicationsSelector;


  @ViewChild('notesModal')
  notesModal: ModalDirective;
  
  @ViewChild('debtorsModal')
  debtorsModal: ModalDirective;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  private customerAccountStatus: string = ""
  private sub: any;
  private id: any = "";
    customerNotes: ArNarration[];
    customerIsOnHoldText: string;
    totalInvoiceCount: number;
  cartSaved: boolean;

  cartId: string = "";
  cart: CartHeader = new CartHeader();
    isNavigatingTo: string;
    customerSalesConversion: CustomerSalesConversion;
    conversionMessage: string;
  customerSalesConversionPercentage: string;


  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;


  @ViewChild('applyDiscountCheck')
  private applyDiscountCheck;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();


  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  loadingIndicator: boolean;
    overLimit: number;
    coreCustomer: CoreCustomer;
    currentDiscountId: number;
    rowCustomerRulesCache: CoreCustomerRule[];
    rowCustomerRules: CoreCustomerRule[];
    generalPriceRow: any;
  hasDicounts: boolean;
  applyDiscounts: boolean;


  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService,
    private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
  
    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
    }
    if (this.isViewOnly) {
      this.searchType = "customer";
    }
  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    let action = sessionStorage.getItem(localStorageActions.Customer);
    let cartId = sessionStorage.getItem(localStorageActions.CartId);

    if (action) {
      this.id = action;
      this.searchText = action;

      if (!this.isViewOnly) {
        if (!!cartId) {
          this.cartId = cartId;
        }
      }

      //Header should populate from the syspro customer
      this.sysproService.getCustomerDetail(this.id).subscribe(x => this.onDataLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));

      this.sysproService.getCustomerOrderConversion(this.id).subscribe(x => this.onSalesConversionSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
      this.sysproService.getCustomerNotes(this.id).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));
      this.sysproService.GetAllSalesPersons(this.branchCode).subscribe(x => this.onSalesPersonoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));

    }   
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rows = null;
    this.rowsCache = null;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }


  }


  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }

  loadCustomer() {
    this.id = this.searchResultSelected.customer;
      this.sysproService.getCustomerDetail(this.searchResultSelected.customer).subscribe(x => this.onDataLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
    }

  private onSalesPersonoadSuccessful(salesPersons: any[]) {
    this.allSalesPersons = salesPersons[0];
    console.log(this.allSalesPersons);
  }
  
  private onDataLoadSuccessful(customer: ArCustomer) {
    this.alertService.stopLoadingMessage();
    this.customer = customer;

    if (!this.isViewOnly) {
      if (!!this.cartId) {

        //If this is a new customer, create a new cart from existing details
      
          this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
        
      }
      else {
        if (this.customer.companyTaxNumber.length == 0 || (this.customer.taxStatus == "E" && this.customer.taxExemptNumber.length == 0)) {
          this.edit();
        }
      }
    }

    let overLimit = parseFloat(customer.creditLimit) - parseFloat(customer.currentBalance1);

    if (customer.customerOnHold === "Y") {
      this.customerIsOnHoldText = "Customer is on hold";
      this.customerAccountStatus = (overLimit < 0 ? "On hold and exceeded credit limit" : "Customer on hold");
    }
    else {
      this.customerAccountStatus = (overLimit < 0 ? "Customer exceeded credit limit" : "In Terms");
    }

    // only show if over limit
    if (overLimit < 0) {
      this.overLimit = overLimit;
    }

    this.getTotalInvoices();
    this.hasDicounts = false;
    this.applyDiscounts = false;
    this.coreCustomer = new CoreCustomer();
    this.sysproService.getPricingCustomer(this.id, this.application).subscribe(x => this.onCoreCustomerLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.alertService.showMessage('Success', `Loaded Syspro Customer`, MessageSeverity.success);   

  }

  onCoreCustomerLoadSuccessful(x: CoreCustomer[]): void {
    if (x && x.length == 1) {
      if (x[0].customerCode != "") {
        this.coreCustomer = x[0];
        this.applyDiscounts =  this.coreCustomer.enabled;
        this.sysproService.getAllPricingCustomerRules(this.coreCustomer.id).subscribe(x => this.onPricingRulesLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      }
    }
  }

  toggleDiscount() {
    this.coreCustomer.enabled = !this.coreCustomer.enabled;
    this.applyDiscounts = this.coreCustomer.enabled;
    this.isSaving = true;
    if (this.coreCustomer && this.coreCustomer.id > 0) {
      //Edit
      this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
      this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
        this.alertService.showStickyMessage('Success', 'Customer Updated',
          MessageSeverity.info);
       
        this.isSaving = false;
      }, error => this.onDataLoadFailed(error));
    }
  }


  onPricingRulesLoadSuccessful(x: CoreCustomerRule[][]): void {
    this.currentDiscountId = 0;
    if (x && x.length >= 1) {
      var ruleList = x[0];
      this.hasDicounts = true;
      this.rowCustomerRulesCache = [...ruleList];
      this.rowCustomerRules = this.rowCustomerRulesCache;     

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

    }
  }

  onSalesConversionSuccessful(x: CustomerSalesConversion): void {
    if (x && x[0]) {
      this.customerSalesConversion = x[0];
      let conversionPercentage = 0;
      if (this.customerSalesConversion.numberOfSalesOrders > 0) {
        if (this.customerSalesConversion.numberOfQuotes > 0) {
          conversionPercentage = (this.customerSalesConversion.valueOfOrders / ((this.customerSalesConversion.valueOfQuotes + this.customerSalesConversion.valueOfOrders)
            - this.customerSalesConversion.convertedQuotes)) * 100;
        }
        else {
          conversionPercentage = 100;
        }
        this.customerSalesConversion.percentage = conversionPercentage;
      }
    }
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    if (this.cart.customer != this.id) {
      this.switchCustomer();
    }
    else {
      Object.assign(this.customer, this.cart);
    }
   
    if (this.cart.companyTaxNumber.length == 0 || (this.cart.taxStatus == "E" && this.cart.taxExemptNumber.length == 0)) {
      this.edit();
    }
    this.alertService.showMessage('Success', `Loaded Cart Header`, MessageSeverity.success);
  }

  private getTotalInvoices() {
    this.totalInvoiceCount = parseFloat(this.customer.numCurrentInv) + parseFloat(this.customer.num30daysInv) + parseFloat(this.customer.num60daysInv)
      + parseFloat(this.customer.num90daysInv) + parseFloat(this.customer.num120daysInv);
  }

  private onNotesLoadSuccessful(notes: any[]) {
    this.alertService.stopLoadingMessage();
    this.customerNotes = notes;
    if (notes[0].length > 0) {
      this.notesModal.show();
    }
  }

  goAddItems() {
    this.isNavigating = true;
    this.isNavigatingTo = "inventory";
    this.showValidationErrors = false;
    this.setCartHeader();
    this.save();
  }

  goAddNSItems() {
    this.isNavigating = true;
    this.isNavigatingTo = "nonstock";
    this.showValidationErrors = false;
    this.setCartHeader();
    this.save();
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }


  onDataLoadFailed(error: any) {

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  private getSalesPersonByName(name: string) {
    return this.allSalesPersons.find((r) => r.salesperson == name);
  }
  

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  
  private edit() {

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.setCartHeader();
  }

  private switchCustomer() {

    this.cartEdit = new CartHeader();
    Object.assign(this.cartEdit, this.customer);
    this.cartEdit.userid = this.accountService.currentUser.id;
    this.cartEdit.cartNumber = this.cartId;
    this.cartEdit.branch_code = this.configurations.branchCode;
    this.cartEdit.customerPoNumber = (this.cart.customerPoNumber ? this.cart.customerPoNumber : "");
    this.cartEdit.shippingInstrs = (this.cart.shippingInstrs ? this.cart.shippingInstrs : "");
    this.cartEdit.specialInstrs = (this.cart.specialInstrs ? this.cart.specialInstrs : "");
    this.cartEdit.ordertype = "";
    this.cartEdit.orderdiscountpercentage = 0;
    this.cartEdit.quotedFrom = (this.cart.quotedFrom ? this.cart.quotedFrom : "");
    this.cartEdit.hasNettPrices = this.cart.hasNettPrices;
    this.cartEdit.userid = this.accountService.currentUser.id;
    this.cartEdit.specialPricesEnabled = this.applyDiscounts;
   
    this.save();
   // this.fowkesService.newCart(this.cart).subscribe(_response => this.saveSuccessHelper(this.cartEdit.cartNumber), error => this.saveFailedHelper(error));

  }
  
  private setCartHeader() {

    this.cartEdit = new CartHeader();
    Object.assign(this.cartEdit, this.customer);
    this.cartEdit.userid = this.accountService.currentUser.id;
    this.cartEdit.specialPricesEnabled = this.applyDiscounts;
    if (!!this.cartId) {
      if (this.cart) {
        Object.assign(this.cartEdit, this.cart);
      }
    }
    else {
      let uniqueId = this.id + Math.random().toString(36).substring(2) + Date.now().toString(36);
      this.cartEdit.cartNumber = uniqueId;
      this.cartEdit.branch_code = this.configurations.branchCode;
      this.cartEdit.customerPoNumber = "";
      this.cartEdit.shippingInstrs = "";
      this.cartEdit.specialInstrs = "";
      this.cartEdit.ordertype = "";
      this.cartEdit.orderdiscountpercentage = 0;
      this.cartEdit.quotedFrom = "";
      this.cartEdit.hasNettPrices = false;
      this.cartEdit.specialPricesEnabled = this.applyDiscounts;
      console.log("CartNumber2", this.cartEdit.cartNumber);
    }

    console.log("THIS IS WHAT I HAVE AFTER setCartHeader", this.cartEdit);
  }

  setLocalCartId(cartId: string) {
    if (sessionStorage.getItem(localStorageActions.CartId) != cartId) {
      sessionStorage.setItem(localStorageActions.CartId, cartId);
      this.cartId = cartId;
    }
  }

  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    console.log("CartNumberSave", this.cartEdit.cartNumber);

    this.cartEdit.specialPricesEnabled = this.applyDiscounts;
    //if (this.coreCustomer && this.coreCustomer.id > 0 && this.applyDiscounts == false) {
    //  //Edit
    //  this.coreCustomer.enabled = false;
    //  this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
    //  this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => { });
    //}

    this.fowkesService.newCart(this.cartEdit).subscribe(_response => this.saveSuccessHelper(this.cartEdit.cartNumber), error => this.saveFailedHelper(error));
    
  }


  private saveSuccessHelper(cartId: string) {

    this.setLocalCartId(cartId);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;
    this.isEditMode = false;

    this.alertService.showMessage('Success', `Updated successfully ` + cartId, MessageSeverity.success);

    if (!this.isNavigating) {
      this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));     
    }
    else {
      if (this.isNavigatingTo == "nonstock") {
        this.router.navigate(['../orders'], { fragment: 'nonstock', queryParams: { customer: this.customer.customer, cartId: cartId } });
      }
      if (this.isNavigatingTo == "inventory") {
        this.router.navigate(['../orders'], { fragment: 'inventory', queryParams: { customer: this.customer.customer, cartId: cartId } });
      }
    }
  }
  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }
   
   
  private cancel() {
    //if (this.isGeneralEditor) {
    //  //this.userEdit = this.user = new UserEdit();
    //  this.customerEdit = this.customer = new ArCustomerEdit();
    //} else {
    //  //this.userEdit = new UserEdit();
    //  this.customerEdit = new ArCustomerEdit();
    // // Object.assign(this.cartEdit, this.customer);
    //}
    this.setCartHeader();
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
   // this.userEdit = this.user = new UserEdit();
    this.customerEdit = this.customer = new ArCustomerEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  copySoldToShip() {
    this.cartEdit.shipToAddr1 = this.cartEdit.soldToAddr1;
    this.cartEdit.shipToAddr2 = this.cartEdit.soldToAddr2;
    this.cartEdit.shipToAddr3 = this.cartEdit.soldToAddr3;
    this.cartEdit.shipToAddr4 = this.cartEdit.soldToAddr4;
    this.cartEdit.shipToAddr5 = this.cartEdit.soldToAddr5;
    this.cartEdit.shipPostalCode = this.cartEdit.soldPostalCode;
  }

  copyShipToSold() {
    this.cartEdit.soldToAddr1 = this.cartEdit.shipToAddr1;
    this.cartEdit.soldToAddr2 = this.cartEdit.shipToAddr2;
    this.cartEdit.soldToAddr3 = this.cartEdit.shipToAddr3;
    this.cartEdit.soldToAddr4 = this.cartEdit.shipToAddr4;
    this.cartEdit.soldToAddr5 = this.cartEdit.shipToAddr5;
    this.cartEdit.soldPostalCode = this.cartEdit.shipPostalCode;
  }
  
  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  search() {
 //   if (this.cartId) {

      this.router.navigate(['../../orders'], { fragment: 'customers', queryParams: { customer: this.customer, cartId: this.cartId } });
    //}
    //else {
    //  this.router.navigate(['../../customers'], { fragment: 'customers', queryParams: { customer: this.customer } });
    //}
  }

  //editCustomer(customer: ArCustomer, allSalesPersons: SalesPerson[]) {
  //  if (customer) {
  //    this.isGeneralEditor = true;

  //    this.setSalesPerson(customer, allSalesPersons);
  //    this.customer = new ArCustomer();
  //    this.customerEdit = new ArCustomerEdit();
  //    Object.assign(this.customer, customer);
  //    Object.assign(this.customerEdit, customer);
  //    this.edit();

  //    console.log(this.customerEdit);
  //    console.log(this.customer);
  //    return this.customerEdit;
  //  } 
  //}


  //displayCustomer(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

  //  this.customer = new ArCustomer();
  //  Object.assign(this.customer, customer);
  //  this.setSalesPerson(customer, allSalesPersons);
  //  this.isEditMode = false;
  //}


  private setSalesPerson(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

    this.allSalesPersons = allSalesPersons ? [...allSalesPersons] : [];

    if (customer.salesperson) {
      if (!this.allSalesPersons.some(r => r.salesperson == customer.salesperson)) {
        this.allSalesPersons.unshift(new SalesPerson(customer.salesperson));
      }
    }

    if (allSalesPersons == null || this.allSalesPersons.length != allSalesPersons.length) {
      setTimeout(() => {
        if (this.salespersonSelector) {
          this.salespersonSelector.refresh();
        }
      });
    }
  }


  get canViewAllSalesPersons(){
    return true;
  }

  get canAssignSalesPersons() {
    return true;
  }

  get canViewAllBranches() {
    return true;
  }
  get canAssignBranches() {
    return true;
  }

  get canAssignApplications() {
    return true;
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }

  get canCreateCart() {
    return this.accountService.userHasPermission(Permission.placeOrderPermission);
  }


  get canMaintainPrices() {
    return this.accountService.userHasPermission(Permission.cartPricingPermission);
  }
}
