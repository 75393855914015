export class Video {
  constructor(title?: string, description?: string, sequence?: number, url?: string, isPreview?: boolean, showMax?: boolean, showFowkes?: boolean, enabled?: boolean) {
    this.title = title;
    this.description = description;
    this.sequence = sequence;
    this.url = url;
    this.isPreview = isPreview;
    this.showMax = showMax;
    this.showFowkes = showFowkes;
    this.enabled = enabled;
  }

  public title: string;

  //public oldtitle: string;

  public description: string;

  public sequence: number;

  public url: string;

  public isPreview?: boolean;

  public showMax?: boolean;

  public showFowkes?: boolean;

  public enabled?: boolean;

  public detail: string;

  public createdDate: string;

  public createdBy: string;
  public id: number;
  public safeURL: any;
}
