import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { localStorageActions } from 'src/app/models/enums';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { Branch } from '../../../models/branch.model';
import { InvoiceDocuments } from '../../../models/invoice-documents.model';
import { InvoiceHeader } from '../../../models/invoice-header.model';
import { SalesOrderHeader } from '../../../models/salesorder-header.model';
import { ValueHelper } from '../../../models/valuehelper.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';





@Component({
  selector: 'pod-search',
  templateUrl: './pod-search.component.html',
  styleUrls: ['./pod-search.component.scss']
})
export class PodSearchComponent implements OnInit, AfterViewInit {


  loadingIndicator: boolean = false;

  rows: InvoiceDocuments[] = [];

  rowsCache: InvoiceDocuments[] = [];

  columns: any[] = [];

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('refreshTemplate')
  refreshTemplate: TemplateRef<any>;

  @ViewChild('barcodeListList')
  barcodeListList: TemplateRef<any>;

  @ViewChild('barcodeListListSelector')
  private barcodeListListSelector;

  @ViewChild('barcodeTemplate')
  barcodeTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
  
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedSearchOptionValue: string = "Please Select";

  searchType: string;
  searchText: string;
  searchResultSelected: InvoiceDocuments;
  searchResults: InvoiceDocuments[];
  doclist: BarcodeReference[] = [];
  selectedDoc: BarcodeReference;


  invoiceHeader: InvoiceHeader = new InvoiceHeader();

  constructor(private router: Router, private alertService: AlertService, private sysproService: SysproService, private route: ActivatedRoute, private accountService: AccountService, private fowkesOnline: FowkesOnlineService) {

  }

  ngOnInit() {

    this.searchType = "invoice";

    this.columns = [
      { prop: 'documentType', name: 'T', width: '50', cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: '70', cellClass: "left" },
      { prop: 'salesOrderNumber', name: 'Sales Order', width: '120', cellClass: "left", cellTemplate: this.barcodeTemplate },
      { prop: 'orderStatus', name: 'S', width: '50', cellClass: "left" },
      { prop: 'invoiceNumber', name: 'Invoice', width: '120', cellClass: "left"},
      //{ prop: 'gtrReferenceNumber', name: 'Transfer', width: '120', cellClass: "left" },
      { prop: 'createDate', name: 'Order Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'operator', name: 'Operator', width: '70', cellClass: "left" },
      { prop: 'barcode', name: 'Barcode', width: '120', cellClass: "left" },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left" },
     // { prop: 'fileNumber', name: 'File No', width: '70', cellClass: "left" },
     // { prop: 'prefix', name: 'Prefix', width: '70', cellClass: "left" },
      { prop: 'fileName', name: '', width: '200', cellClass: "left", cellTemplate: this.selectTemplate },
      { prop: '', name: '', width: '25', cellClass: "left", cellTemplate: this.refreshTemplate }
    ];

  }

  ngAfterViewInit() {

    this.columns = [
      { prop: 'documentType', name: 'T', width: '50', cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: '70', cellClass: "left" },
      { prop: 'salesOrderNumber', name: 'Sales Order', width: '120', cellClass: "left", cellTemplate: this.barcodeTemplate },
      { prop: 'orderStatus', name: 'S', width: '50', cellClass: "left" },
      { prop: 'invoiceNumber', name: 'Invoice', width: '120', cellClass: "left" },
      //{ prop: 'gtrReferenceNumber', name: 'Transfer', width: '120', cellClass: "left" },
      { prop: 'createDate', name: 'Order Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'operator', name: 'Operator', width: '70', cellClass: "left" },
      { prop: 'barcode', name: 'Barcode', width: '120', cellClass: "left" },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left" },
    //  { prop: 'fileNumber', name: 'File No', width: '70', cellClass: "left" },
     // { prop: 'prefix', name: 'Prefix', width: '70', cellClass: "left" },
      { prop: 'fileName', name: '', width: '200', cellClass: "left", cellTemplate: this.selectTemplate },
      { prop: '', name: '', width: '25', cellClass: "left", cellTemplate: this.refreshTemplate }
    ];

  }


  specificSearch(stop?: boolean) {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.sysproService.getInvoiceDocumentsSearchByCriteria(this.searchType, this.searchText.trim()).subscribe(
        x => this.onSpecificSearchSuccesful(x, stop), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (this.searchType == "invoice" || this.searchType == "salesorder") {
      this.sysproService.getBarcodeForInvoiceSync(this.searchText.trim(), this.searchText.trim().substr(0, 2)).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    }
  

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: InvoiceDocuments[], stop?: boolean): void {

    if (x && x.length >= 1) {

      this.searchResults = x;
      if (this.searchResults && this.searchResults.length >= 1) {

        this.rowsCache = this.searchResults;

        this.rowsCache.forEach((barcode, index, list) => {
          (<any>barcode).index = index + 1;
          if (barcode.barcode.length > 0) {
            //this.doclist = [];
            let defaultDoc = new BarcodeReference();
            defaultDoc.fileNumber = 0;
            defaultDoc.fileName = this.selectUndefinedSearchOptionValue;
            //this.doclist.push(defaultDoc);

            this.sysproService.getPODByInvoice(barcode.invoiceNumber).subscribe(docs => {
              barcode.barcodeReferences = [...docs];
              barcode.barcodeReferences.push(defaultDoc);
              barcode.fileName = this.selectUndefinedSearchOptionValue;

            }, error => this.onDataLoadFailed(error));

          }
        });

        this.rows = [...this.rowsCache];
        console.log(this.rows);
      }

    }
    else {
      if (!stop) {
        if (this.searchType == "invoice" || this.searchType == "salesorder") {
          this.sysproService.getBarcodeForInvoiceSync(this.searchText.trim(), this.searchText.trim().substr(0, 2)).subscribe(x => this.specificSearch(true), error => this.onDataLoadFailed(error));
        }
      }
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }


  syncInvoice(row: InvoiceDocuments) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    if ((row.invoiceNumber && row.invoiceNumber.length > 1 && row.documentType) && (row.documentType == "C" || row.documentType == "D")) {
      this.sysproService.getBarcodeForInvoiceSync(row.invoiceNumber, row.branch).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    }
    else {
      this.sysproService.getBarcodeForInvoiceSync(row.salesOrderNumber, row.branch).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    }
    
    //if (row.gtrReferenceNumber && row.gtrReferenceNumber.length > 1) {
    //  this.sysproService.getBarcodeForInvoiceSync(row.salesOrderNumber, row.branch).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    //}
    //else {
    //  if (row.invoiceNumber && row.invoiceNumber.length > 1 && row.invoiceNumber != row.salesOrderNumber) {
    //    this.sysproService.getBarcodeForInvoiceSync(row.invoiceNumber, row.branch).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    //  }
    //  else {
    //    this.sysproService.getBarcodeForInvoiceSync(row.salesOrderNumber, row.branch).subscribe(x => this.specificSearch(), error => this.onDataLoadFailed(error));
    //  }
    //}
   
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  viewPOD(row: InvoiceDocuments) {


          
    if (row.fileName != "" && row.fileName != this.selectUndefinedSearchOptionValue) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getFileStream(row.fileName, 0).subscribe(x => this.onFileSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));
    }
    
  }

  viewPODDetails(row: InvoiceDocuments) {

    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, row.invoiceNumber);

    sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrderNumber);

    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: row.invoiceNumber } });
  }

  onFileSuccessful(x: Blob, fileName: string): void {


    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.title = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  //toggle() {
  //  this.show = true;
  //}

  //choosedDateTime(e) {

  //  this.selected = {
  //    startDate: e.startDate,
  //    endDate: e.endDate
  //  }

  //  this.show = false;
  //  this.Loaddata();
  //}

  //rangeClicked(e) {

  //  this.picker.renderRanges();

  //  this.selected = {
  //    startDate: this.picker.startDate,
  //    endDate: this.picker.endDate
  //  }

  //  this.show = false;
  //  this.Loaddata();
  //}

  //searchDates(e) {

  //  this.selected = {
  //    startDate: e.startDate,
  //    endDate: e.endDate
  //  }

  //  this.show = false;
  //  this.Loaddata();
  //}

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.invoiceNumber, r.orderStatus, r.fileName, r.prefix, r.branch, r.status));
    }
    else {
      this.rows = this.rowsCache;
    }
  }


  //exportDetails() {
  //  var options = {
  //    fieldSeparator: ',',
  //    quoteStrings: '"',
  //    decimalseparator: '.',
  //    showLabels: true,
  //    showTitle: false,
  //    useBom: true,
  //    noDownload: false,
  //    headers: ["Invoice", "OrderDate",  "Status",  "LastSyncDate", "NumberOfFiles"]
  //  };

  //  var filename = "Documents";
  //  var selectRows = [...this.rows];
  //  var exportRows = [];
  //  for (var row of selectRows) {

  //    //exportRows.push({
  //    //  Invoice: row.invoiceNumber, OrderDate: row.orderDate, Status: row.status, LastSyncDate: row.lastSyncedDate, NumberOfFiles: row.numberOfFiles
  //    //});
  //  }

  //  return new ngxCsv(exportRows, filename, options);
  //}


  //filterTransfers() {

  //  this.showAll = false;

  //  if (!this.exclTrans) {

  //    this.excludedTransfers = this.rows.filter(x => x.documentType == 'O');
  //    this.rows = this.rows.filter(x => x.documentType != 'O');
  //  }
  //  else {

  //    if (this.excludedTransfers && this.excludedTransfers.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedTransfers);
  //      //this.excludedTransfers = [];
  //    }
  //  }
  //}

  //filterCanceled() {
  //  //todo OrderStatus or Status / ?
  //}

  //filterCRN() {


  //  this.showAll = false;

  //  if (!this.exclCreditNotes) {

  //    this.excludedCreditnotes = this.rows.filter(x => x.documentType == 'C');
  //    this.rows = this.rows.filter(x => x.documentType != 'C');
  //  }
  //  else {
  //    if (this.excludedCreditnotes && this.excludedCreditnotes.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedCreditnotes);
  //    }
  //  }
  //}

  //filterCash() {
  //  this.showAll = false;

  //  if (!this.exclCashSales) {
  //    this.excludedCashSales = this.rows.filter(x => x.invTerms == '03');

  //    this.rows = this.rows.filter(x => x.invTerms != '03');
  //  }
  //  else {

  //    if (this.excludedCashSales && this.excludedCashSales.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedCashSales);
  //    }
  //  }
  //}

  //filterAll() {
  //  if (!this.showAll) {

  //    if (!this.exclTrans) {
  //      this.filterTransfers();
  //    }

  //    if (!this.exclCreditNotes) {
  //      this.filterCRN();
  //    }

  //    if (!this.exclCashSales) {
  //      this.filterCash();
  //    }

  //    this.exclTrans = true;

  //    this.exclCreditNotes = true;

  //    this.exclCashSales = true;

  //  }
  //  else {
  //    if (this.exclTrans) {
  //      this.filterTransfers();
  //    }

  //    if (this.exclCreditNotes) {
  //      this.filterCRN();
  //    }

  //    if (this.exclCashSales) {
  //      this.filterCash();
  //    }

  //    this.exclTrans = false;

  //    this.exclCreditNotes = false;

  //    this.exclCashSales = false;
  //  }
  //}
}
