import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    animations: [fadeInOut]
})
export class ProductsComponent {

  isListActivated = true;
  isCMCategoriesActivated = false;
  isMetaTagsActivated = false;
  isCMImagesActivated = false;
  isStockCodesActivated = false;
  isDataSheetsActivated = false;
  isStockNewActivated = false;
  isCMSImagesActivated = false;
  isVideosActivated = false;
  isBrandsActivated = false;
  isCatalogueActivated = false;
  isCatagoryCatalogueActivated = false;
  isCalculatorActivated = false;

  isCollapsed = false;

  fragmentSubscription: any;

  readonly inventoryListTab = 'inventory';
  readonly categoriesTab = 'cmcategories';
  readonly metaTagsTab = 'metatags';
  readonly imagesTab = 'cmimages';
  readonly stockcodesTab = 'stockcodes';
  readonly datasheetsTab = 'datasheets';
  readonly stocknewTab = 'stocknew';
  readonly stockimagesTab = 'cmsimages';
  readonly videosTab = 'videos';
  readonly brandsTab = 'brands';
  readonly catalogueTab = 'catalogue';
  readonly catagoryCatalogueTab = 'categorycatalogue';
  readonly calculatorTab = 'calculator';


  @ViewChild('tab')
  tab: BootstrapTabDirective;

  sub: any;
  id: any = "";


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {

    //this.sub = this.route.queryParams.subscribe(params => {
    //  this.id = params['customer'];
    //});


    this.isCollapsed = !this.configurations.menuExpanded;
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
  }

  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
  showContent(anchor: string, test: any) {

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.inventoryListTab}Tab`);

  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.inventoryListTab;
    this.isCMCategoriesActivated = activeTab == this.categoriesTab;
    this.isMetaTagsActivated = activeTab == this.metaTagsTab;
    this.isCMImagesActivated = activeTab == this.imagesTab;
    this.isStockCodesActivated = activeTab == this.stockcodesTab;
    this.isDataSheetsActivated = activeTab == this.datasheetsTab;
    this.isStockNewActivated = activeTab == this.stocknewTab;
    this.isCMSImagesActivated = activeTab == this.stockimagesTab;
    this.isVideosActivated = activeTab == this.videosTab;
    this.isBrandsActivated = activeTab == this.brandsTab;
    this.isCatalogueActivated = activeTab == this.catalogueTab;
    this.isCatagoryCatalogueActivated = activeTab == this.catagoryCatalogueTab;
    this.isCalculatorActivated = activeTab == this.calculatorTab;


    this.router.navigate([], { fragment: activeTab, queryParams: { customer: this.id } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.inventoryListTab;
    this.isCMCategoriesActivated = activeTab == this.categoriesTab;
    this.isMetaTagsActivated = activeTab == this.metaTagsTab;
    this.isCMImagesActivated = activeTab == this.imagesTab;
    this.isStockCodesActivated = activeTab == this.stockcodesTab;
    this.isDataSheetsActivated = activeTab == this.datasheetsTab;
    this.isStockNewActivated = activeTab == this.stocknewTab;
    this.isCMSImagesActivated = activeTab == this.stockimagesTab;
    this.isVideosActivated = activeTab == this.videosTab;
    this.isBrandsActivated = activeTab == this.brandsTab;
    this.isCatalogueActivated = activeTab == this.catalogueTab;
    this.isCatagoryCatalogueActivated = activeTab == this.catagoryCatalogueTab;
    this.isCalculatorActivated = activeTab == this.calculatorTab;

    this.router.navigate([], { fragment: activeTab });
  }

  get canViewCustomers() {
    return true;
  }

  get canManageInventory() {
    return  this.accountService.userHasPermission(Permission.manageInventoryPermission);
  }


  get canViewCatalogue() {
    return this.accountService.userHasPermission(Permission.viewCataloguePermission);
  }

}
