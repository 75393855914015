import { Component, OnInit, ViewChild, Input, TemplateRef, OnChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceTrackingStatus } from 'src/app/models/invoice-tracking-status.model';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { localStorageActions } from '../../../models/enums';
import { User } from '../../../models/user.model';
import { ConfigurationService } from '../../../services/configuration.service';
import Quagga from 'quagga';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ITSDriversLog, ITSParcelTrackingDetailLog } from '../../../models/invoice-tracking-drivers-log.model';
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'reports-invoice-status',
  templateUrl: './reports-invoice-status-component.html',
  styleUrls: ['./reports-invoice-status-component.scss']
})
export class ReportsInvoiceStatusComponent implements OnInit
{

  @ViewChild('purgeModal')
  purgeModal: ModalDirective;


  @ViewChild('printLabelModal')
  printLabelModal: ModalDirective;

  private isEditMode = false;
    loadingIndicator: boolean;
  result: InvoiceTrackingStatus;
  ITSObject: InvoiceTrackingStatus = new InvoiceTrackingStatus();
  

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
  invoiceTracking: InvoiceTrackingStatus;
    hasDuplicates: boolean;
    noResults: boolean;
  user: User;

  @Input()
  isViewOnly: boolean;
  enabled: boolean;


  @Input()
  selectedInvoice: string;
  numberOfLabelsToPrint: number;

  rowsDriverLogHeader: ITSDriversLog[];
  additionalDeliveryDetails: ITSParcelTrackingDetailLog[];

  

  constructor(private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private configurations: ConfigurationService,
    private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService) {

  }

  ngOnInit() {
    this.isViewOnly = this.isViewOnly == true ? this.isViewOnly : false;
    this.loadData();
    this.searchType = "itsinvoice";

    let invoiceNumber = sessionStorage.getItem(localStorageActions.ITSInvoiceNumber);
    if (invoiceNumber && invoiceNumber != "") {
      this.searchText = invoiceNumber;
      this.specificSearch();
    }


  }

  ngOnChanges() {

    this.isViewOnly = this.isViewOnly == true ? this.isViewOnly : false;

    if (this.selectedInvoice) {
      this.loadData();
      this.searchType = "itsinvoice";

      let invoiceNumber = sessionStorage.getItem(localStorageActions.ITSInvoiceNumber);
      if (invoiceNumber && invoiceNumber != "") {
        this.searchText = invoiceNumber;
        this.specificSearch();
      }   
    }   

  }

  loadData() {


    this.user = this.accountService.currentUser;
    this.enabled = true;
    }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.result = null;
      this.noResults = true;
      this.sysproService.getInvoiceTrackingStatus(this.searchText, this.searchText).
        subscribe(x => this.onDataLoadSuccessful(x), error => this.onStatusLoadFailed(error));
      //this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
      //  x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
  onStatusLoadFailed(error: any): void {

    this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
      x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: SalesOrderList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x.filter(i => i.invoiceNumber != '');

    if (x.length === 1) {
      this.searchResultSelected = x[0];
      this.viewInvoiceSelected();
    }

  }
    viewInvoiceSelected() {
      this.alertService.startLoadingMessage();

      this.loadingIndicator = true;
      this.noResults = true;
      this.sysproService.getInvoiceTrackingStatus(this.searchResultSelected.salesOrderNumber, this.searchResultSelected.invoiceNumber).
        subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(result: InvoiceTrackingStatus, success?: boolean) {
    this.ITSObject = new InvoiceTrackingStatus();

    if (result) {
      if (result[1]) {
        this.hasDuplicates = true;
      }
      else {
        this.hasDuplicates = false;
      }
      if (result[0]) {
        this.noResults = false;
        this.result = new InvoiceTrackingStatus();
        this.result = result[0];
        this.ITSObject = this.result;
        if ((this.application == "MaxArcusOnline" && this.ITSObject.fromCompany == "Max Arcus") || (this.application == "FowkesOnline" && this.ITSObject.fromCompany == "Fowkes Bros")) {
          this.enabled = true;
        }
        else {
          this.enabled = false;
        }

        this.additionalDeliveryDetails = [];
        this.rowsDriverLogHeader = [];

        if (this.ITSObject.dispatch == 'A' || this.ITSObject.delivery == 'A') {
          //get the addtional delivery information
          this.getDeliveryDetails();
          //this.fowkesService.getITSDeliveryDetails(this.ITSObject.invoiceNumber).
          //  subscribe(x => this.onDeliveryDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
        }
      }
    }
    else {

      this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
        MessageSeverity.error);
    }
      

    if (success) {

      this.alertService.showStickyMessage('Success', `Record Has been updated: `, MessageSeverity.success);

    }

    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

  }

  getDeliveryDetails() {
    // get the additional delivery information
    this.fowkesService.getITSDeliveryDetails(this.ITSObject.invoiceNumber).pipe(
      // Check if data exists and filter out invalid data if necessary
      map(data => data.filter(row => row && row.trackingHeaderId)),
      // Use mergeMap to handle the array of data and inner Observable
      mergeMap(data => {
        // Use forkJoin to handle multiple parallel requests
        const requests = data.map(row =>
          this.fowkesService.getITSDeliveryDetailsAdditional(row.trackingHeaderId)
        );
        // Return combined result: original data and the results of the requests
        return forkJoin(requests).pipe(
          map(headers => ({ data, headers }))
        );
      })
    ).subscribe(({ data, headers }) => {
      this.additionalDeliveryDetails = [...data];
      this.rowsDriverLogHeader = headers.filter(header => header);
      console.log(this.rowsDriverLogHeader);

    }, error => this.onDataLoadFailed(error));

  }

    onDeliveryDataLoadSuccessful(x: ITSParcelTrackingDetailLog[]): void {

      

      if (x && x[0]) {

       x.forEach(row => {
         //Get the log headers for each detail line
         this.fowkesService.getITSDeliveryDetailsAdditional(row.trackingHeaderId).
           subscribe(result => {
             if (result) {
               this.rowsDriverLogHeader.push(result);

             }
           }, error => this.onDataLoadFailed(error));

        });
        


        this.additionalDeliveryDetails = [...x];


      }
    }


  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  purgeITS() {

    if (this.ITSObject && this.ITSObject.purgeUser && this.ITSObject.purgeUser != "" && this.ITSObject.purgeReason && this.ITSObject.purgeReason != "") {
      this.purgeModal.hide();
      this.alertService.startLoadingMessage();

      this.loadingIndicator = true;

      this.sysproService.postInvoiceTrackingPurge(this.ITSObject.salesOrder, this.ITSObject.invoiceNumber, this.ITSObject.purgeUser,
        this.ITSObject.purgeReason.replace(/\//g, "--").replace(/\+/gi, "___"),
        this.user.fullName).
        subscribe(x => this.onDataLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty Fields', 'Please enure you have added a purge user and reason for purge',
        MessageSeverity.warn);
    }
    

  }

  printLabelsShow() {
    this.numberOfLabelsToPrint = 1;
    if (!this.printLabelModal) {
      setTimeout(() => {
        if (this.printLabelModal) {
          this.printLabelModal.show();
        }
      });
    }
    else {
      this.printLabelModal.show();
    }
  }

 

  printLabels(selectedPrinter: string) {


    if (this.ITSObject && this.numberOfLabelsToPrint >= 1) {
      this.printLabelModal.hide();
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.alertService.showMessage('', `Label printing initiated`, MessageSeverity.success);

      this.fowkesService.printBarcodeLabelDirect(this.ITSObject.invoiceNumber, 
        this.user.fullName,  this.ITSObject.invoiceNumber,
        this.numberOfLabelsToPrint, selectedPrinter).
        subscribe(x => this.onDataLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty Fields', 'Please enure you have the number of labels to print',
        MessageSeverity.warn);
    }

  }

  increaseLabels() {
    this.numberOfLabelsToPrint++;
  }

  decreaseLabels() {
    if (this.numberOfLabelsToPrint > 1) { 
      this.numberOfLabelsToPrint--;
    }
  }

  removeDuplicate() {
    this.purgeModal.hide();
    this.alertService.startLoadingMessage();

    this.loadingIndicator = true;

    this.sysproService.deleteInvoiceTrackingDuplicate(this.ITSObject.salesOrder, this.ITSObject.invoiceNumber).
      subscribe(x => this.onDataLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
  }


  viewSalesInvoice() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode } });
  }


  viewCreditNote() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.creditNoteNumber);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.removeItem(localStorageActions.InvoiceNumber);
   // sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode, credit: "C" } });
  }

  viewTransfer() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: 'TRANSFER' } });
  }


  viewDocuments() {
    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, this.ITSObject.invoiceNumber);
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: this.ITSObject.salesOrder } });
  }

  viewITS() {
    this.router.navigate(['/reports'], { fragment: 'itslist' });
  }


  controlReport() {
    this.router.navigate(['/reports'], { fragment: 'control' });
  }


}
