import { Component, OnInit, ViewChild, Input, TemplateRef, OnChanges, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { InvoiceTrackingStatus } from 'src/app/models/invoice-tracking-status.model';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { localStorageActions } from '../../../models/enums';
import { User } from '../../../models/user.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { InvoiceBarcode } from '../../../models/invoice-barcode.model';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { ITSParcelTracking, ITSParcelTrackingDetail } from '../../../models/invoice-tracking-parcels.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ITSDriversLog, ITSParcelTrackingDetailLog } from '../../../models/invoice-tracking-drivers-log.model';
import { ngxCsv } from 'ngx-csv';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'tracking-invoice-status',
  templateUrl: './tracking-invoice-status-component.html',
  styleUrls: ['./tracking-invoice-status-component.scss']
})
export class TrackingInvoiceStatusComponent implements OnInit
{

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];
  rowsHeader: ITSParcelTracking[] = [];
  rowsHeaderCache: ITSParcelTracking[] = [];

  rowsDetail: ITSParcelTrackingDetail[] = [];
  rowsDetailCache: ITSParcelTrackingDetail[] = [];

  trackingHeader: ITSParcelTracking;

  rowsDriverLogHeader: ITSDriversLog[] = [];
  rowsDriverLogHeaderCache: ITSDriversLog[] = [];

  rowsParcelDetail: ITSParcelTrackingDetailLog[] = [];
  rowsParcelDetailCache: ITSParcelTrackingDetailLog[] = [];


  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild('itsModal')
  itsModal: ModalDirective;

  @ViewChild('reportDetailModal')
  reportDetailModal: ModalDirective;

  @ViewChild('returnedModal')
  returnedModal: ModalDirective;

  @ViewChild('notesModal')
  notesModal: ModalDirective;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate')
  nameTemplate: TemplateRef<any>;

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;


  @ViewChild('previewTemplate')
  previewTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;


  @ViewChild('changedSelector')
  private changedSelector;

  private isEditMode = false;
    loadingIndicator: boolean;
  result: InvoiceTrackingStatus;
  ITSObject: InvoiceTrackingStatus = new InvoiceTrackingStatus();
  

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
  invoiceTracking: InvoiceTrackingStatus;
    hasDuplicates: boolean;
    noResults: boolean;
  user: User;

  @Input()
  isViewOnly: boolean;
  enabled: boolean;

  driverSearch: string;
  driverCageSearch: string;
  vehicleSearch: string;
  barcodeSearch: string;
  addedPackage: string;

  @Input()
  selectedInvoice: string;

  @ViewChild('input1') input1!: ElementRef;
  @ViewChild('input2') input2!: ElementRef;
  @ViewChild('input3') input3!: ElementRef;
  @ViewChild('input4') input4!: ElementRef;
  inputList: string[] = [];

  barcodeList: InvoiceBarcode[];
  inputCount: { [key: string]: number } = {};
  driversLogHeader: ITSDriversLog;
  cardType: string;
  src: string;

  totalParcels: number;
  totalInvoices: number;
  totalDespatched: number;
  totalReturned: number;
  loaded: boolean;

  returnedParcels: number = 0;
  returnedReason: string = '';
  currentReturnedRow: ITSParcelTrackingDetail;

  despatchedParcels: number = 0;
  changedReason: string = '';
  currentUpdatingRow: ITSParcelTrackingDetailLog;

  updatingNotes: string = '';

  selectedCustomer: string;
    comment: string;
    sortBy: string;


  constructor(private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private configurations: ConfigurationService,
    private alertService: AlertService, private accountService: AccountService, private elementRef: ElementRef, private fowkesService: FowkesOnlineService,) {

  }

  private setData() {
   
    this.columns = [
      { prop: 'id', name: '##', width: 50, cellTemplate: this.indexTemplate },
      { prop: 'trackingInsertDate', name: 'Date', width: 110, cellTemplate: this.dateTemplate },
      { prop: 'driverName', name: 'Driver Name', width: 250, cellTemplate: this.nameTemplate}, 
      { prop: 'vehicleNumber', name: 'Vehicle Registration', width: 100, cellClass: "center" },
      { prop: 'vehicleDescription', name: 'Vehicle Description', width: 180 }, 
      { prop: 'cageNumber', name: 'Cage', width: 100 },
      { prop: 'cageDescription', name: 'Cage Description', width: 150 },
      { prop: 'totalParcels', name: '#Parcels', width: 50 },
      { prop: 'totalParcelsUndelivered', name: 'Returned', width: 50 }, 
    ];


    //this.columns.push({ name: 'View', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });


  }

  refresh() {
    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.setData();
    this.reset();
  }

  ngOnInit() {

    this.sortBy = "ID";
    this.user = this.accountService.currentUser;

    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }
  
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.setData();
    this.reset();
  }

  reset() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sortBy = "ID";

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.totalInvoices = 0;
    this.totalParcels = 0;
    this.totalDespatched = 0;
    this.totalReturned = 0;

    this.loaded = false;

    this.fowkesService.getITSDriversLog(startD.toJSON(), endD.toJSON()).subscribe(x => this.onLogSearchSuccesful([x], startD, endD), error => this.onDataLoadFailed(error));
        
  }



  onLogSearchSuccesful(drivers: any[], startD: Date, endD: Date): void {


    if (drivers[0]) {
      this.rowsDriverLogHeaderCache = drivers[0];
      this.rowsDriverLogHeader = [...this.rowsDriverLogHeaderCache];


      if (this.rowsDriverLogHeader && this.rowsDriverLogHeader.length >= 1) {
        this.alertService.showMessage('', `Getting Details...`, MessageSeverity.info);
        // Use forkJoin to make individual calls for each header
        const detailObservables = this.rowsDriverLogHeaderCache.map(header =>
          this.fowkesService.getITSParcelTrackingDetailListForHeaderId(header.id)
        );

        forkJoin(detailObservables).subscribe(
          detailsList => {
            detailsList.forEach((details, index) => {
              this.rowsDriverLogHeaderCache[index].details = details;
            });

            this.rowsDriverLogHeader = [...this.rowsDriverLogHeaderCache];
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;

          },
          error => this.onDataLoadFailed(error)
        );
      }
     

      //this.fowkesService.getITSParcelTrackingDetail(startD.toJSON(), endD.toJSON()).subscribe(x => this.onLogDetailSearchSuccesful([x]), error => this.onDataLoadFailed(error));   

    }
    else {

      this.rowsDriverLogHeader = null;
      this.rowsDriverLogHeaderCache = null;
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('', `No logs found for selected date range`, MessageSeverity.info);
    }
  }

  onLogDetailSearchSuccesful(details: any[]): void {
    if (details[0] && details[0].length > 0) {
     
      this.rowsDriverLogHeaderCache.forEach(headerRow => {
        // Filter the details based on the trackingHeaderId of the current header row
        const matchedDetails = details[0].filter(detail => detail.trackingHeaderId === headerRow.id);

        // Assign the matched details to the header row (you can further process the data if needed)
        headerRow.details = matchedDetails;

      });

      this.rowsDriverLogHeader = [...this.rowsDriverLogHeaderCache];

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }
    else {

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('', `No details found for selected date range`, MessageSeverity.info);
    }
  }

 
  toggle() {
    this.show = true;
  }
  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  filterChanged() {

    this.reset();

  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rowsDriverLogHeader = this.rowsDriverLogHeaderCache.filter(r => Utilities.searchArray(value, false, r.id, r.driverCode, r.vehicleNumber, r.cageNumber, r.driverName, r.cageDescription, r.vehicleDescription));
    }
  }

  ngAfterViewInit(): void {

    this.setData();
  }

  viewDetails(row: ITSDriversLog) {

    this.sortBy = "ID";
    this.driversLogHeader = new ITSDriversLog();
    this.driversLogHeader = row;

    this.rowsParcelDetail = row.details;
    if (!this.reportDetailModal) {
      setTimeout(() => {
        if (this.reportDetailModal) {
          this.reportDetailModal.show();
        }
      });
    }
    else {
      this.reportDetailModal.show();
    }

    this.totalInvoices = this.rowsParcelDetail.length;
    this.totalParcels = this.rowsParcelDetail.reduce((total, row) => total + row.scannedCount, 0);
    this.totalDespatched = this.rowsParcelDetail.reduce((total, row) => total + row.itsParcelCount, 0);
    this.totalReturned = this.rowsParcelDetail.reduce((total, row) => total + row.undeliveredCount, 0);

  }

  onScannedCountChanged(row: any, newValue: any) {
    // Parse the new value as an integer
    newValue = parseInt(newValue, 10);

    if (isNaN(newValue)) {
      return; // If parsing fails, don't proceed
    }

    // Update the scannedCount property of the row with the new value
    row.scannedCount = newValue;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    // Call the updateparcels service endpoint to save the changes to the server
    this.fowkesService.updateparcels(row.id, newValue).subscribe(
      (response) => {
        console.log('Scanned count updated:', response);
        this.alertService.showMessage('', `Scanned count updated`, MessageSeverity.success);

        // Recalculate the total parcels after updating the scanned count
        this.totalParcels = this.rowsParcelDetail.reduce((total, row) => total + row.scannedCount, 0);

        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      },
      (error) => {
        console.error('Error updating scanned count:', error);

        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }
    );
  }


  onPrintAdditionalLabelsClick(row: any) {
    if (row) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      //Replacing with direct print
      this.alertService.showMessage('', `Label printing initiated`, MessageSeverity.success);

      this.fowkesService.printBarcodeLabelDirect(row.invoiceNumber, this.accountService.currentUser.email, row.salesOrder,
        1, "DESPATCH").subscribe(x => this.alertService.showMessage('', `Label printed`, MessageSeverity.success), error => this.onDataLoadFailed(error));
        

      //this.fowkesService.initiatelabelprinting(row.id, 1).subscribe(
      //  (response) => {
      //    this.alertService.showMessage('', `Label printing initiated`, MessageSeverity.success);
      //  //  console.log('Label printing initiated:', response);
      //  //  this.fowkesService.printAdditionalLabels(row.invoiceNumber, this.accountService.currentUser.email, row.salesOrder, ).subscribe(x => this.onLabelSuccessful(x, false, row.invoiceNumber), error => this.onDataLoadFailed(error));
      //  },
      //  (error) => {
      //    console.error('Error initiating label printing:', error);
      //  }
      //);
    }
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  openReturnedModal(row: ITSParcelTrackingDetail) {
    // Set the current row
    this.currentReturnedRow = new ITSParcelTrackingDetail();
    this.currentReturnedRow = row;
    this.returnedParcels = row.scannedCount;

    if (!this.returnedModal) {
      setTimeout(() => {
        if (this.returnedModal) {
          this.returnedModal.show();
        }
      });
    }
    else {
      this.returnedModal.show();
    }
  }

  markAsReturned() {
    // Update the returned parcel count and reason
    this.currentReturnedRow.undeliveredCount = this.returnedParcels;
    this.currentReturnedRow.undeliveredReason = this.returnedReason;

    // Set the undelivered status, date, and user
    this.currentReturnedRow.undeliveredStatus = 'Returned';
    this.currentReturnedRow.undeliveredDate = new Date();
    this.currentReturnedRow.undeliveredUser = this.accountService.currentUser.email;

    // Update the row in the database
    this.fowkesService.updateparcelsUndelivered(this.currentReturnedRow.id, this.currentReturnedRow.undeliveredCount, this.currentReturnedRow.undeliveredReason, this.currentReturnedRow.undeliveredUser).subscribe(
      (response) => {
        console.log('Undelivered flag updated:', response);
        this.alertService.showMessage('', `Parcel marked as returned`, MessageSeverity.success);
      },
      (error) => {
        console.error('Error updating undelivered flag:', error);
      }
    );

    this.closeReturned();
  }

  closeReturned() {
    // Close the modal
    this.returnedModal.hide();
  }



  onAddNotes(row: ITSParcelTrackingDetailLog) {
    // Set the current row
    this.currentUpdatingRow = new ITSParcelTrackingDetailLog();
    this.currentUpdatingRow = row;
    this.despatchedParcels = row.itsParcelCount;
    this.comment = "";
    this.changedReason = this.selectUndefinedOptionValue;

    if (!this.notesModal) {
      setTimeout(() => {
        if (this.notesModal) {
          this.notesModal.show();
        }
      });
    }
    else {
      this.notesModal.show();
    }
  }

  setComment() {

    const today = new Date();
    const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    this.comment = `${formattedDate} - ${this.accountService.currentUser.email}: Changed parcel count to ${this.despatchedParcels} due to reason: ${this.changedReason}.`;
  }

  updateDespatched() {

    if (this.changedReason == this.selectUndefinedOptionValue) {
      this.alertService.showMessage('Warning', `Please select a reason before saving`, MessageSeverity.warn);
    }
    else {
      // Update the despatched parcel count and reason
      this.currentUpdatingRow.itsParcelCount = this.despatchedParcels;
      this.currentUpdatingRow.itsUpdatedBy = this.accountService.currentUser.email;
      this.currentUpdatingRow.itsDespatchNotes = this.comment + " " + this.currentUpdatingRow.itsDespatchNotes;

      // Update the row in the database
      this.fowkesService.updateDespatchParcels(this.currentUpdatingRow.id, this.currentUpdatingRow).subscribe(
        (response) => {

          this.alertService.showMessage('', `Record Updated`, MessageSeverity.success);
        },
        (error) => {
          console.error('Error updating', error);
        }
      );

      this.closeNotes();
    }
    
  }

  closeNotes() {
    // Close the modal
    this.notesModal.hide();
  }


  //onUndeliveredChanged(row: ITSParcelTrackingDetail) {
  //  // Toggle the undelivered flag
  //  row.undeliveredStatus = "Returned";

  //  // If the item is marked as undelivered, set the undelivered count and user
  //  if (row.undeliveredStatus == "Returned") {
  //    row.undeliveredCount = row.scannedCount;
  //    row.undeliveredUser = 'current_user'; // You can set the appropriate user here
  //    row.undeliveredStatus = 'Undelivered'; // Set the status
  //    row.undeliveredDate = new Date(); // Set the undelivered date
  //  } else {
  //    // If the item is marked as delivered again, clear undelivered fields
  //    row.undeliveredCount = null;
  //    row.undeliveredUser = null;
  //    row.undeliveredStatus = null;
  //    row.undeliveredDate = null;
  //  }

  //  // Update the row in the database
  //  this.fowkesService.updateUndeliveredFlag(row.id, row.undelivered).subscribe(
  //    (response) => {
  //      console.log('Undelivered flag updated:', response);
  //      this.alertService.showMessage('', `Undelivered flag updated`, MessageSeverity.success);
  //    },
  //    (error) => {
  //      console.error('Error updating undelivered flag:', error);
  //    }
  //  );
  //}

  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  viewSalesInvoice() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode } });
  }


  viewCreditNote() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.creditNoteNumber);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.removeItem(localStorageActions.InvoiceNumber);
   // sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode, credit: "C" } });
  }

  viewTransfer() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: 'TRANSFER' } });
  }


  viewDocuments() {
    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, this.ITSObject.invoiceNumber);
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: this.ITSObject.salesOrder } });
  }

  viewITS() {
    this.router.navigate(['/reports'], { fragment: 'invoicestatus' });
  }


  controlReport() {
    this.router.navigate(['/reports'], { fragment: 'control' });
  }



  reportDetailModalHide() {
    this.driversLogHeader = null;
    if (this.reportDetailModal) {
      this.reportDetailModal.hide();
    }
    this.filterChanged();
  }


  changeSort(sortBy: string) {
    if (sortBy) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sortBy = sortBy;

      if (sortBy === 'PostalCode') {

        this.rowsParcelDetail.sort((a, b) => a.shipPostalCode.localeCompare(b.shipPostalCode));
      } else if (sortBy === 'ID') {

        this.rowsParcelDetail.sort((a, b) => a.id - b.id);
      }

      // After sorting is done, stop the loading message and indicator
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }
  }



  printDriversLog(row: ITSDriversLog) {
    if (row) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.cardType = "DriversLog";
      this.fowkesService.getPrintLogBookPDF(this.cardType, row.id.toString(), this.sortBy).subscribe(x => this.onFileSuccessful(x, false, row.vehicleNumber), error => this.onDataLoadFailed(error));

    }
  }


  onLabelSuccessful(x: Blob, preview: boolean, invoice: string): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
   
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = "Labels_" + invoice + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  onFileSuccessful(x: Blob, preview: boolean, id: string): void {

    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = this.cardType + "_" + id + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  printService() {
   // this.fowkesService.RefireLogPrintingQueue().subscribe(x => this.refresh(), error => this.onDataLoadFailed(error));
  }


  previewITS(row: ITSParcelTrackingDetail) {

    this.selectedInvoice = row.invoiceNumber;
    this.selectedCustomer = row.customer;

    if (sessionStorage.getItem(localStorageActions.ITSInvoiceNumber) != row.invoiceNumber) {
      sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);
    }

    if (sessionStorage.getItem(localStorageActions.Customer) != row.customer) {
      sessionStorage.setItem(localStorageActions.Customer, row.customer);
    }

    if (!!this.selectedInvoice) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModalHide() {
    this.selectedInvoice = null;
    if (this.itsModal) {
      this.itsModal.hide();
    }
  }

  gotToITS() {

    this.router.navigate(['../../reports'], { fragment: 'invoicestatus', queryParams: { customer: this.selectedCustomer } });
  }




  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Date", "Driver", "DriverName", "Vehicle", "VehicleDescription", "Cage", "CageDescription", "NoParcels", "Returned"]
    };

    var filename = "DriversLog";
    var selectRows = [...this.rowsDriverLogHeader];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.trackingInsertDate).toISOString().split('T')[0];
      exportRows.push({
        Id: row.id, Date: date, Driver: row.driverCode, DriverName: row.driverName, Vehicle: row.vehicleNumber, VehicleDescription: row.vehicleDescription,
        Cage: row.cageNumber, CageDescription: row.cageDescription, NoParcels: row.totalParcels, Returned: row.totalParcelsUndelivered
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  resultDetails() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Date", "Driver", "DriverName", "Vehicle", "VehicleDescription", "Cage", "CageDescription", "NoParcels", "Returned",
        "Detail_InvoiceNumber", "Detail_Customer", "Detail_Customer_Name", "Parcel_Count", "Parcels_Scanned",  "Detail_ScannedDate"]  // ... Invoice	Customer	Customer Name	#Parcels	#Scanned	Telephone	Address	#Returned
    };

    var filename = "DriversLogDetails";
    var selectRows = [...this.rowsDriverLogHeader];
    var exportRows = [];
    for (let row of selectRows) {
      const date = new Date(row.trackingInsertDate).toISOString().split('T')[0];
      const headerData = {
        Id: row.id, Date: date, Driver: row.driverCode, DriverName: row.driverName, Vehicle: row.vehicleNumber, VehicleDescription: row.vehicleDescription,
        Cage: row.cageNumber, CageDescription: row.cageDescription, NoParcels: row.totalParcels, Returned: row.totalParcelsUndelivered
      };

      if (row.details && row.details.length) {
        for (let detail of row.details) {
          exportRows.push({
            ...headerData,
            Detail_InvoiceNumber: detail.invoiceNumber,
            Detail_Customer: detail.customer,
            Detail_Customer_Name: detail.customerName,
            Parcel_Count: detail.itsParcelCount,
            Parcels_Scanned: detail.scannedCount,
            Detail_ScannedDate: detail.scannedDate,
            // ... other detail fields here
          });
        }
      } else {
        exportRows.push(headerData);
      }
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
