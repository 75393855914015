import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { User } from '../../models/user.model';
import { UserEdit } from '../../models/user-edit.model';
import { Role } from '../../models/role.model';
import { Branch } from '../../models/branch.model';
import { AspnetApplications } from '../../models/applications.model';
import { Permission } from '../../models/permission.model';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  private isEditMode = false;
  private isNewUser = false;
  private isSaving = false;
  private isChangePassword = false;
  private isEditingSelf = false;
  private showValidationErrors = false;
  private editingUserName: string;
  private uniqueId: string = Utilities.uniqueId();
  private user: User = new User();
  private userEdit: UserEdit;
  private allRoles: Role[] = [];
  private allBranches: Branch[] = [];
  private allApplications: AspnetApplications[] = [];

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;

   
  @ViewChild('f')
  private form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild('userName')
  private userName;

  @ViewChild('userPassword')
  private userPassword;

  @ViewChild('email')
  private email;

  @ViewChild('currentPassword')
  private currentPassword;

  @ViewChild('newPassword')
  private newPassword;

  @ViewChild('confirmPassword')
  private confirmPassword;

  @ViewChild('roles')
  private roles;

  @ViewChild('role')
  private role;

  @ViewChild('rolesSelector')
  private rolesSelector;
  
  @ViewChild('branches')
  private branches;

  @ViewChild('branchSelector')
  private branchSelector;

  @ViewChild('sysproOperator')
  private sysproOperator;

  @ViewChild('sysproPassword')
  private sysproPassword;

  @ViewChild('sysproBuyer')
  private sysproBuyer;

  @ViewChild('sysproRep')
  private sysproRep;


  @ViewChild('isSupervisor')
  private isSupervisor;


  @ViewChild('applications')
  private applications;

  @ViewChild('applicationsSelector')
  private applicationsSelector;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesOnline: FowkesOnlineService,
    private configurations: ConfigurationService) {
  }

  ngOnInit() {
    console.log('General Editor', this.isGeneralEditor);
    console.log('View Only', this.isViewOnly);
    console.log('Editing Self', this.isEditingSelf);
    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
    }
  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
    this.fowkesOnline.getApplications().subscribe(x => this.onAppLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onBranchLoadSuccessful(branches: Branch[]) {
    this.alertService.stopLoadingMessage();
    this.allBranches = branches;
    console.log("Branches", this.allBranches);
  }
  
  private onAppLoadSuccessful(applications: AspnetApplications[]) {
    this.alertService.stopLoadingMessage();
    this.allApplications = applications;
    console.log("allApplications", this.allApplications);
  }


  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;

    console.log("ROLES", this.allRoles);
    console.log("USER", this.user);
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }



  private getRoleByName(name: string) {
    return this.allRoles.find((r) => r.name == name);
  }

  private getBranchByName(name: string) {
    return this.allBranches.find((r) => r.branch == name);
  }


  private getApplicationByName(name: string) {
    return this.allApplications.find((r) => r.applicationName == name);
  }

  
  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }


  public deletePasswordFromUser(user: UserEdit | User) {
    const userEdit = <UserEdit>user;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }


  private edit() {
    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
    } else {
      if (!this.userEdit) {
        this.userEdit = new UserEdit();
      }

      this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id == this.accountService.currentUser.id : false;
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;
  }


  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    //if (this.isEditingSelf) {
    //  //Set local branch storage
    //  this.localStorage.saveSyncedSessionData(this.userEdit.branch, `${TodoDemoComponent.DBKeyTodoDemo}:${this.currentUserId}`);
    //  }
    //}

    if (this.isNewUser) {
      this.userEdit.sysproSessionId = ""; //Requires SysproSessionId
      let configString = '{"language":"en","themeId":15,"homeUrl":"/","showDashboardStatistics":false,"showDashboardNotifications":false,"showDashboardTodo":false,"showDashboardBanner":false,';
      configString = configString + '"applicationName":"' + this.userEdit.aspnetApplication + '", "branchCode":"' + this.userEdit.branch + '", "loadCache":' + (this.userEdit.branch == "CT" ? 'true' : 'false') + '}';
      this.userEdit.configuration = configString;
      console.log("NEW USER CONFIG", this.userEdit.configuration);
      this.accountService.newUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));
    } else {
      console.log("EXISITING USER CONFIG", this.userEdit.configuration);
      this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    }
  }


  private saveSuccessHelper(user?: User) {
    this.testIsRoleUserCountChanged(this.user, this.userEdit);

    if (user) {
      Object.assign(this.userEdit, user);
    }

    //Update the user's default preferences:
    //this.accountService.getUserPreferences().subscribe(results => {
    //  this.configurations.import(results);
    //  this.configurations.branchCode = this.userEdit.branch;
    //  this.configurations.themeId = 15;
    //  this.accountService.updateUserPreferences(this.configurations.export())
    //    .subscribe(response => {
    //      this.alertService.stopLoadingMessage();
    //      this.alertService.showMessage('User Preferences', 'USer default preferences updated successfully', MessageSeverity.success);

    //    },
    //      error => {
    //        this.alertService.stopLoadingMessage();
    //        this.alertService.showStickyMessage('Save Error', `An error occured whilst saving configuration defaults.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
    //          MessageSeverity.error, error);
    //      });
    //},
    //  error => {
    //    this.alertService.stopLoadingMessage();
    //    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user preferences from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
    //      MessageSeverity.error, error);
    //  });

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    this.deletePasswordFromUser(this.userEdit);
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();


    if (this.isGeneralEditor) {
      if (this.isNewUser) {
        this.alertService.showMessage('Success', `User \"${this.user.userName}\" was created successfully`, MessageSeverity.success);
      } else if (!this.isEditingSelf) {
        this.alertService.showMessage('Success', `Changes to user \"${this.user.userName}\" was saved successfully`, MessageSeverity.success);
           }
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage('Success', 'Changes to your User Profile was saved successfully', MessageSeverity.success);
      this.refreshLoggedInUser();
    }

    this.isEditMode = false;


    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }


  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }



  private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {

    const rolesAdded = this.isNewUser ? editedUser.roles : editedUser.roles.filter(role => currentUser.roles.indexOf(role) == -1);
    const rolesRemoved = this.isNewUser ? [] : currentUser.roles.filter(role => editedUser.roles.indexOf(role) == -1);

    const modifiedRoles = rolesAdded.concat(rolesRemoved);

    if (modifiedRoles.length) {
      setTimeout(() => this.accountService.onRolesUserCountChanged(modifiedRoles));
    }
  }



  private cancel() {
    if (this.isGeneralEditor) {
      this.userEdit = this.user = new UserEdit();
    } else {
      this.userEdit = new UserEdit();
    }

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }



  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe(user => {
        this.loadCurrentUserData();
      },
        error => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage('Refresh failed', 'An error occured whilst refreshing logged in user information from the server', MessageSeverity.error, error);
        });
  }


  private changePassword() {
    this.isChangePassword = true;
  }


  private unlockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Unblocking user...');


    this.accountService.unblockUser(this.userEdit.id)
      .subscribe(() => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Success', 'User has been successfully unblocked', MessageSeverity.success);
      },
        error => {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Unblock Error', 'The below errors occured whilst unblocking the user:', MessageSeverity.error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        });
  }


  resetForm(replace = false) {
    this.isChangePassword = false;

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }


  newUser(allRoles: Role[], allBranches: Branch[], allApplications: AspnetApplications[]) {
    this.isGeneralEditor = true;
    this.isNewUser = true;

    this.allRoles = [...allRoles];
    this.allBranches = [...allBranches];
    this.allApplications = [...allApplications];
    this.editingUserName = null;
    this.user = this.userEdit = new UserEdit();
    this.userEdit.isEnabled = true;
    this.edit();

    console.log(this.userEdit);
    return this.userEdit;
  }

  editUser(user: User, allRoles: Role[], allBranches: Branch[], allApplications: AspnetApplications[]) {
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;

      this.setRoles(user, allRoles);
      this.setBranch(user, allBranches);
      this.setApplication(user, allApplications);
      this.editingUserName = user.userName;
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      this.edit();

      console.log(this.userEdit);
      console.log(this.user);
      return this.userEdit;
    } else {
      return this.newUser(allRoles, allBranches, allApplications);
    }
  }


  displayUser(user: User, allRoles?: Role[], allBranches?: Branch[], allApplications?: AspnetApplications[]) {

    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setRoles(user, allRoles);
    this.setBranch(user, allBranches);
    this.setApplication(user, allApplications);

    this.isEditMode = false;
  }



  private setRoles(user: User, allRoles?: Role[]) {

    this.allRoles = allRoles ? [...allRoles] : [];

    if (user.roles) {
      for (const ur of user.roles) {
        if (!this.allRoles.some(r => r.name == ur)) {
          this.allRoles.unshift(new Role(ur));
        }
      }
    }

    if (allRoles == null || this.allRoles.length != allRoles.length) {
      setTimeout(() => {
        if (this.rolesSelector) {
          this.rolesSelector.refresh();
        }
      });
    }
  }

  private setBranch(user: User, allBranches?: Branch[]) {

    this.allBranches = allBranches ? [...allBranches] : [];

    if (user.branch) {
      if (!this.allBranches.some(r => r.branch == user.branch)) {
        this.allBranches.unshift(new Branch(user.branch));
      }
    }

    if (allBranches == null || this.allBranches.length != allBranches.length) {
      setTimeout(() => {
        if (this.branchSelector) {
          this.branchSelector.refresh();
        }
      });
    }
  }

  private setApplication(user: User, allApplications?: AspnetApplications[]) {

    this.allApplications = allApplications ? [...allApplications] : [];

    if (user.aspnetApplication) {
      if (!this.allApplications.some(r => r.applicationName == user.aspnetApplication)) {
        this.allApplications.unshift(new AspnetApplications(user.aspnetApplication));
      }
    }

    if (allApplications == null || this.allApplications.length != allApplications.length) {
      setTimeout(() => {
        if (this.applicationsSelector) {
          this.applicationsSelector.refresh();
        }
      });
    }
  }

  get canViewAllBranches() {
    return true;
  }
  get canAssignBranches() {
    return true;
  }

  get canAssignApplications() {
    return true;
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }
}
