import { Component, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

import { Moment } from 'moment';
import * as moment from 'moment';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { SysproService } from '../../../services/syspro.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Utilities } from '../../../services/utilities';
import { localStorageActions } from '../../../models/enums';


@Component({
  selector: 'pod-reconcile',
  templateUrl: './pod-reconcile.component.html',
  styleUrls: ['./pod-reconcile.component.scss'],
  animations: [fadeInOut]
})
export class PodReconcileComponent {

  show: boolean = false;

  private selectUndefinedOptionValue: string = "ALL";

  loadingIndicator: boolean = false;

  selected: { startDate: Moment, endDate: Moment };

  ranges = {
    //'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  rows: BarcodeReference[] = [];

  rowsCache: BarcodeReference[] = [];

  columns: any[] = [];

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('barcodeTemplate')
  barcodeTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
    referenceList: BarcodeReference[];

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService, private sysproService: SysproService, private alertService: AlertService) {
  }

  ngOnInit() {

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }


    this.columns = [
      { prop: 'invoice', name: 'Reference', width: '120', cellClass: "left"},
      { prop: 'barcodeDate', name: 'File Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      //{ prop: 'orderStatus', name: 'Order Status', width: '90', cellClass: "left", sortable: false },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left" },
      { prop: 'month', name: 'File Month', width: '100', cellClass: "left" },
      { prop: 'day', name: 'File Day', width: '100', cellClass: "left" },
      { prop: 'fileNumber', name: 'Number', width: '100', cellClass: "left" },
      { prop: 'prefix', name: 'Prefix', width: '100', cellClass: "left" },
      { prop: 'fileName', name: 'File Name', width: '200', cellClass: "left", cellTemplate: this.selectTemplate },
      //{ prop: 'lastSyncDate', name: 'Synced On', width: '100', cellClass: "left", cellTemplate: this.dateTemplate, sortable: false },
      //{ prop: 'hasBuyouts', name: 'Buyouts?', width: '70', cellClass: "left" },
      //{ prop: 'documentType', name: 'Type', width: '70', cellClass: "left", sortable: false },
      //{ prop: 'invTerms', name: 'Terms', width: '70', cellClass: "left", sortable: false },
      //{ prop: 'numberOfFiles', name: 'Files', width: '70', cellClass: "left", sortable: false }
    ];

       this.columns.push({ name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    this.Loaddata();
  }

  ngAfterViewInit() {


    this.columns = [
      { prop: 'invoice', name: 'Reference', width: '120', cellClass: "left" },
      { prop: 'barcodeDate', name: 'File Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      //{ prop: 'orderStatus', name: 'Order Status', width: '90', cellClass: "left", sortable: false },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left" },
      { prop: 'month', name: 'File Month', width: '100', cellClass: "left" },
      { prop: 'day', name: 'File Day', width: '100', cellClass: "left" },
      { prop: 'fileNumber', name: 'Number', width: '100', cellClass: "left" },
      { prop: 'prefix', name: 'Prefix', width: '100', cellClass: "left" },
      { prop: 'fileName', name: 'File Name', width: '200', cellClass: "left", cellTemplate: this.selectTemplate },
      //{ prop: 'lastSyncDate', name: 'Synced On', width: '100', cellClass: "left", cellTemplate: this.dateTemplate, sortable: false },
      //{ prop: 'hasBuyouts', name: 'Buyouts?', width: '70', cellClass: "left" },
      //{ prop: 'documentType', name: 'Type', width: '70', cellClass: "left", sortable: false },
      //{ prop: 'invTerms', name: 'Terms', width: '70', cellClass: "left", sortable: false },
      //{ prop: 'numberOfFiles', name: 'Files', width: '70', cellClass: "left", sortable: false }
    ];

    this.columns.push({ name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }

  Loaddata() {


    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());


    this.sysproService.getBarcodesUnallocated(startD.toJSON(), endD.toJSON(), this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue)
      .subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
  }
  onSearchSuccesful(x: BarcodeReference[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {

      this.referenceList = x;

      this.referenceList.forEach((barcode, index, list) => {
        (<any>barcode).index = index + 1;
      });

      this.rowsCache = [...this.referenceList];
      if (this.referenceList.length >= 200) {
        this.rows = this.referenceList.slice(0, 200);
      }
      else {
        this.rows = this.referenceList;
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  viewFile(row: BarcodeReference) {

    this.sysproService.getFileStream(row.fileName, row.fileNumber).subscribe(x => this.onFileSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));

  }

  changeInvoice(row: BarcodeReference) {

    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, row.invoice);

    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: row.invoice } });
  }

  onFileSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }

  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.Loaddata();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
    this.Loaddata();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.Loaddata();
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.invoice, r.orderDate, r.orderStatus, r.fileName, r.prefix, r.branch, r.month, r.status,
        r.barcodeEntryDateString));
    }
    else {
      this.rows = this.rowsCache;
    }
  }


}
