
export class CoreCustomerRule {
  constructor() {

    
  
  }

  public id: number;
  public customerId: number;
  public customerCode: string;
  public ruleName: string;
  public ruleDescription: string;
  public code: string;


  public value: number;
  public addValue: number;

  public addText: string;
  public addCheck: boolean;
  public addFlag: string;
  public priority: number;
  public categoryId: number;


  public createdBy: string;
  public modifiedBy: string;

  public createdOn: Date;
  public modifiedOn: Date;
}

