
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../../models/permission.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { CustomerMovements } from 'src/app/models/customer-movements.model';
import { CustomerPayments } from 'src/app/models/customer-payments.model';
import { localStorageActions } from 'src/app/models/enums';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigurationService } from '../../../services/configuration.service';
import { BankImport } from '../../../models/bank-import.model';


@Component({
  selector: 'bank-import-report',
  templateUrl: './bank-import-report.component.html',
  styleUrls: ['./bank-import-report.component.scss']
})
export class BankImportReportComponent implements OnInit, AfterViewInit {
  columns: any[] = [];

  resultcolumns: any[] = [];
  rows: CustomerPayments[] = [];
  rowsCache: CustomerPayments[] = [];
  customerPaymentsList: CustomerPayments[];
    loadingIndicator: boolean;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();

  @Input()
  isViewOnly: boolean;
  
  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  isSearch: boolean; 
  id: string;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  searchType: string = "";
  searchText: string = "";
  searchYear: string = "";
  searchMonth: string = "";

  selectedFileName: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;
  selectedYear: string;
  selectedMonth: string;

  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild(DaterangepickerComponent) pickerPayment: DaterangepickerComponent;
  @ViewChild(DaterangepickerComponent) pickerFile: DaterangepickerComponent;

  selectedPaymentDate: { startDate: Moment, endDate: Moment };
  selectedFileDate: { startDate: Moment, endDate: Moment };


  showPaymentDate: boolean;
  showFileDate: boolean;

  ranges = {    
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 2 Years': [moment().subtract(24, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Years': [moment().subtract(36, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  locale: LocaleConfig = {
    applyLabel: 'Done'
  };
  startPaymentDate: Date;
  endPaymentDate: Date;
  startFileDate: Date;
  endFileDate: Date;



  resultRows: BankImport[] = [];
  resultRowsCache: BankImport[] = [];

  inlineDate: any;
  inlineDateTime: any;

    chkDb: boolean;
    chkCr: boolean;
    selectedReference: string;
    selectedFile: string;
    selectedBatch: string;
    startDate: string;
    endDateDate: string;
  selectedUserName: string;

  successAmount: number = 0;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {

    this.setDataGrids();

    this.selectedCompany = (this.application == "MaxArcusOnline" ? "M" : "F");

    this.searchType = "fileDate";

    this.selectedPaymentDate = {
      startDate: moment().subtract(0, 'month').startOf('month'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.selectedFileDate = {
      startDate: moment(),
      endDate: moment()
    }

    this.loadData();

    //this.getFileNames();
  }

  getFileNames() {
    this.sysproService.getBankImportByCompany(this.selectedCompany).subscribe(
      x => this.onResultsSuccess(x), error => this.onDataLoadFailed(error));
  }

    onResultsSuccess(x: BankImport[]): void {
       //Gets a list of all the records for that company

    }

  specificSearch() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.successAmount = 0;
   //Get lastest file results;
    
    this.selectedCustomer = (this.searchType == "customer" ? this.searchText : "ALL");
    this.selectedYear = (this.searchType == "period" ? this.searchYear : "ALL");
    this.selectedMonth = (this.searchType == "period" ? this.searchMonth : "ALL");
    this.selectedReference = (this.searchType == "reference" ? this.searchText : "ALL");
    this.selectedFile = (this.searchType == "fileName" ? this.searchText : "ALL");
    this.selectedUserName = (this.searchType == "userName" ? this.searchText : "ALL");
    this.selectedBatch = (this.searchType == "latestBatch" ? this.searchText : "ALL");

    if (this.searchType == "fileDate") {
      var start = new Date(this.selectedFileDate.startDate.toString());
      var end = new Date(this.selectedFileDate.endDate.toString());

      this.sysproService.getBankImportLatest(this.selectedCompany, this.selectedReference, this.selectedYear, this.selectedMonth,
        this.selectedCustomer, start.toJSON(), end.toJSON(), this.searchType, this.selectedFile, this.selectedUserName).subscribe(x => this.onHistoryLoadSuccesfull(x), error => this.onDataLoadFailed(error));
    }
    else {
      var start = new Date(this.selectedPaymentDate.startDate.toString());
      var end = new Date(this.selectedPaymentDate.endDate.toString());

      this.sysproService.getBankImportLatest(this.selectedCompany, this.selectedReference, this.selectedYear, this.selectedMonth,
        this.selectedCustomer, start.toJSON(), end.toJSON(), this.searchType, this.selectedFile, this.selectedUserName).subscribe(x => this.onHistoryLoadSuccesfull(x), error => this.onDataLoadFailed(error));
    }   

  }


  onHistoryLoadSuccesfull(x: BankImport[]): void {


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
        this.successAmount += line.paymentValue;
      });

      this.resultRowsCache = [...details];
     // this.resultRowsCache = x;
      this.resultRows = [...this.resultRowsCache];
    }
  }

  setDataGrids() {
    this.resultcolumns = [
      { prop: 'company', name: 'C', width: 30 },
      { prop: 'customer', name: 'Customer', width: 80 },
      { prop: 'customerName', name: 'Customer Ref', width: 200 },
      { prop: 'bankReference', name: 'Bank Reference', width: 100},
      { prop: 'paymentDate', name: 'Payment Date', width: 100, cellTemplate: this.dateTemplate },
      { prop: 'paymentValue', name: 'Amount', width: 100, cellClass: "right"  },
      { prop: 'status', name: 'Status', width: 80, cellClass: "center" },
      { prop: 'message', name: 'Result', width: 150 },
      { prop: 'journal', name: 'Jnl', width: 50 },
      { prop: 'userName', name: 'Operator', width: 80 },
      { prop: 'customerAccTerms', name: 'Acc Terms', width: 80 },
      { prop: 'customerApplyAutoPayments', name: 'Auto Payments', width: 80 },
      { prop: 'paymentPeriod', name: 'P', width: 50 },
      { prop: 'fileName', name: 'FileName', width: 500 }
    ];
  }


  ngAfterViewInit() {
    this.setDataGrids();
  } 


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  dateFilterChanged() {
    
  }

  togglePayment() {
    this.showPaymentDate = true;
  }
  searchPaymentDates(e) {

    this.selectedPaymentDate = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.showPaymentDate = false;
  }

  choosedPaymentDateTime(e) {

    this.selectedPaymentDate = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.showPaymentDate = false;
  }

  rangeClickedPayment(e) {

    this.pickerPayment.renderRanges();
    this.selectedPaymentDate = {
      startDate: this.pickerPayment.startDate,
      endDate: this.pickerPayment.endDate
    }
    this.showPaymentDate = false;
  }

  toggleFile() {
    this.showFileDate = true;
  }
  searchFileDates(e) {

    this.selectedFileDate = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.showFileDate = false;
  }


  choosedFileDateTime(e) {

    this.selectedFileDate = {
      startDate: e.startDate,
      endDate: e.endDate
    }
    this.showFileDate = false;
  }


  rangeClickedFile(e) {

    this.pickerFile.renderRanges();
    this.selectedFileDate = {
      startDate: this.pickerFile.startDate,
      endDate: this.pickerFile.endDate
    }
    this.showFileDate = false;
  }

  showPaymentsOnly() {
    this.chkDb = !this.chkDb;
    this.chkCr = false;
    this.dateFilterChanged();
  }

  showCreditsOnly() {
    this.chkDb = false;
    this.chkCr = !this.chkCr;
    this.dateFilterChanged();
  }




  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }

  }
    
  onDataLoadSuccessful(customers: any[]) {

    this.customerPaymentsList = customers[0];

    if (this.customerPaymentsList && this.customerPaymentsList.length >= 1) {

      this.customerPaymentsList.forEach((customer, index, customers) => {
        (<any>customer).index = index + 1;
      });

      this.rowsCache = [...this.customerPaymentsList];

      this.dateFilterChanged();

    }
    else {
      this.alertService.showMessage('No results', 'No payment details for the search criteria',
        MessageSeverity.warn);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  private loadCustomer() {
    this.id = this.searchResultSelected.customer;
    this.currentCustomer = this.id;
    sessionStorage.removeItem(localStorageActions.Customer);
    sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);   
    this.customerHeading = this.searchResultSelected;
    this.loadDetails();
    this.router.navigate(['../../customers'], { fragment: 'payments', queryParams: { customer: this.currentCustomer } });
  }

    loadDetails() {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      //var start = new Date(this.selected.startDate.toString());
      //var end = new Date(this.selected.endDate.toString());


      //this.customerPaymentsList = null;
      //this.rowsCache = null;
      //this.rows = null;

      //this.sysproService.getCustomerPayments(start.toJSON(), end.toJSON(),this.id).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    }


  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }


  onCustomerLoadSuccessful(x: ArCustomer[]): void {
    this.customerSearch = x;
    this.customerHeading = this.customerSearch.find(x => x.customer == this.currentCustomer);
    setTimeout(() => {
      if (this.customerSelector) {
        this.customerSelector.refresh();
      }
    });
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Customer", "Invoice", "Date", "Transaction", "Customer PO", "Payment Ref",
         "Operator",
        "Total (Incl VAT)"]
    };

    var filename = this.id + "-Payments";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Customer: row.customer, Invoice: row.invoice, Date: (moment(row.filterDate)).format('DD-MMM-YYYY'), Transaction: row.transaction,
        CustomerPO: row.customerPoNumber, PaymentRef: row.reference,
        Operator: row.operator,
        Total: row.amount
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  onSearchChanged(value: string) {
    if (value != "") {
      this.resultRows = this.resultRowsCache.filter(r => Utilities.searchArray(value, false, r.status, r.customer, r.paymentDate, r.paymentValue, r.bankReference,
        r.customerName, r.userName, r.fileName));
    }

    else {
      this.resultRows = this.resultRowsCache;
    }
  }


  ExportResults() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Company", "MasterAcc", "Customer", "CustomerName", "Amount", "BankReference", "PaymentDate", "Status", "TrnYear", "TrnMonth", "Journal", "Period", "ApplyAutoPayments", "Message", "Operator", "ImportedFile"]
    };

    var filename = "IMPORTRESULTS";
    var selectRows = [...this.resultRows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.paymentDate).toISOString().split('T')[0];
      let localDate = new Date(row.paymentDate);
      let formattedDate = `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;

      exportRows.push({
        Company: row.company, MasterAcc: row.customerMaster, Customer: row.customer, CustomerName: row.customerName, Amount: row.paymentValue, BankReference: row.bankReference,
        PaymentDate: formattedDate, Status: row.status, TrnYear: row.trnYear, TrnMonth: row.trnMonth, Journal: row.journal,
        Period: row.paymentPeriod, ApplyAutoPayments: row.customerApplyAutoPayments ,Message: row.message, Operator: row.userName, ImportedFile: row.fileName
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
