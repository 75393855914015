
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { PurchaseOrderList } from 'src/app/models/purchaseorder-list.model';


@Component({
  selector: 'syspro-purchaseorder-management',
  templateUrl: './syspro-purchaseorder-management.component.html',
  styleUrls: ['./syspro-purchaseorder-management.component.scss']
})
export class SysproPurchaseOrderManagementComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  columns: any[] = [];
  rows: PurchaseOrderList[] = [];
  rowsCache: PurchaseOrderList[] = [];
    cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;
  
    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('customerTemplate')
  customerTemplate: TemplateRef<any>;

  @ViewChild('purchaseOrderTemplate')
  purchaseOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;
  
  @ViewChild('poTemplate')
  poTemplate: TemplateRef<any>;
  
  @ViewChild('purchaseOrderList')
  purchaseOrderList: TemplateRef<any>;

  @ViewChild('purchaseOrderListSelector')
  private purchaseOrderListSelector;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    //@ViewChild('customerEditor')
    //customerEditor: SysproCustomerViewComponent;
    //inventoryList: Inventory[];


  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    salesOrders: SalesOrderList[];
    salesOrderNumber: string;
  purchaseOrders: PurchaseOrderList[];

  searchType: string = "";
  searchText: string = "";
  searchResults: PurchaseOrderList[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {    

    let action = sessionStorage.getItem(localStorageActions.Customer);
   // let quoteNumber = sessionStorage.getItem(localStorageActions.QuoteNumber);
    let salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);

  //  this.quoteNumber = quoteNumber;
    this.salesOrderNumber = salesOrderNumber;
    this.id = action;

      this.columns = [
        { prop: 'purchaseOrder', name: 'Purchase Order', width: 180, cellTemplate: this.purchaseOrderTemplate},
        //{ prop: 'descr', name: 'Description', width: 570},
        //{ prop: 'supplier', name: 'Supplier', width: 70 },
        { prop: 'supplierName', name: 'Supplier Name', width: 400 },
        { prop: 'statusDescr', name: 'Status', width: 100 },
        { prop: 'customer', name: 'Customer', width: 100 },
        { prop: 'salesOrder', name: 'Sales Order', width: 100 }
      ];

      //this.columns.push({ name: 'Purchase Orders', width: 200, cellTemplate: this.selectTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

      this.loadData();
  }


  get loadCache(): boolean {
    return this.configurations.loadCache;
  }


  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.customer, r.salesOrder, r.poNumber,
        r.status, r.supplier, r.supplierName, r.descr, r.salesOrder));
    }
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getPurchaseOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: PurchaseOrderList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

  }

  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }



  setLocalCustomer(customer: string, salesOrderNumber: string, invoiceNumber: string, purchaseorder?: string) {

    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != salesOrderNumber) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != invoiceNumber) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, invoiceNumber);
    }
    if (purchaseorder) {
      if (sessionStorage.getItem(localStorageActions.PurchaseOrder) != purchaseorder) {
        sessionStorage.setItem(localStorageActions.PurchaseOrder, purchaseorder);
      }
    }
  }


    ngAfterViewInit() {
      this.columns = [
        { prop: 'purchaseOrder', name: 'Purchase Order', width: 180, cellTemplate: this.purchaseOrderTemplate },
        //{ prop: 'descr', name: 'Description', width: 570},
        //{ prop: 'supplier', name: 'Supplier', width: 70 },
        { prop: 'supplierName', name: 'Supplier Name', width: 400 },
        { prop: 'statusDescr', name: 'Status', width: 100 },
        { prop: 'customer', name: 'Customer', width: 100 },
        { prop: 'salesOrder', name: 'Sales Order', width: 100 }
      ];

    }
  

  loadData() {
    this.searchType = "purchaseorder";

    if (this.loadCache) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getPurchaseOrderSearchByCriteria("All", "TOP").subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
   
  }
    onSearchSuccesful(x: PurchaseOrderList[]): void {
      console.log(x);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.purchaseOrders = x;
      x.forEach((salesOrderNumber, index, x) => {
        (<any>salesOrderNumber).index = index + 1;
      });
      
      this.rowsCache = [...x];
      this.rows = this.rowsCache.slice(0, 50);
  }
  

  get branchCode(): string {
    return this.configurations.branchCode;
  }
    
    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  
  viewPurchaseOrder(row: PurchaseOrderList) {
    console.log(row);
    this.setLocalCustomer(row.customer, row.salesOrder, "", row.poNumber);
    this.router.navigate(['../../purchaseorders'], { fragment: 'purchaseorder', queryParams: { customer: row.customer } });
  }


}
