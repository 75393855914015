
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Notification } from '../../models/notification.model';
import { QuoteSalesReportData } from '../../models/quote-sales-report-data.model';
import { Role } from '../../models/role.model';
import { SalesRepSummary } from '../../models/sales-rep-summary.model';
import { SalesReportData } from '../../models/sales-report.model';
import { SalesStats } from '../../models/sales-stats.model';
import { SysproUser } from '../../models/syspro-user.model';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { NotificationService } from '../../services/notification.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';




@Component({
  selector: 'syspro-rep-viewer',
  templateUrl: './syspro-rep-viewer.component.html',
  styleUrls: ['./syspro-rep-viewer.component.scss']
})
export class SysproRepViewerComponent implements OnInit, OnDestroy {

    columnsSales: any[] = [];
  rowOperatorToday: SalesRepSummary;
  rowOperatorMonth: SalesRepSummary;
  rowOperatorLastMonth: SalesRepSummary;

  rowsRepToday: SalesRepSummary;
  rowsRepMonth: SalesRepSummary;
  rowsRepLastMonth: SalesRepSummary;

  Operator: SysproUser;
  operatorCode: string;
  repCode: string;

  numberOfSalesOrders: number = 0;
  numberOfQuotes: number = 0;
  valueOfOrders: number = 0;
  valueOfQuotes: number = 0;
  convertedQuotes: number = 0;
  conversionPercentage: number = 0;


  CompnumberOfSalesOrders: number = 0;
  CompnumberOfQuotes: number = 0;
  CompvalueOfOrders: number = 0;
  CompvalueOfQuotes: number = 0;
  CompconvertedQuotes: number = 0;
  CompconversionPercentage: number = 0;

  selected: { startDate: Moment, endDate: Moment };

  private selectUndefinedOptionValue: string = "ALL";

  columns: any[] = [];
  rows: Notification[] = [];

  loadingIndicator1: boolean;
  loadingIndicator2: boolean;
  loadingIndicator3: boolean;
  loadingIndicator4: boolean;
  loadingIndicator5: boolean;
  loadingIndicator6: boolean;

    dataLoadingConsecutiveFailurs = 0;
    dataLoadingSubscription: any;


    @Input()
    isViewOnly: boolean;

    @Input()
    verticalScrollbar = false;


    @ViewChild('statusHeaderTemplate')
    statusHeaderTemplate: TemplateRef<any>;

    @ViewChild('statusTemplate')
    statusTemplate: TemplateRef<any>;

    @ViewChild('dateTemplate')
    dateTemplate: TemplateRef<any>;

    @ViewChild('contentHeaderTemplate')
    contentHeaderTemplate: TemplateRef<any>;

    @ViewChild('contenBodytTemplate')
    contenBodytTemplate: TemplateRef<any>;

    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
    allRoles: Role[];
    loadingIndicatorMyConversion: boolean;
    loadingIndicatorCompConversion: boolean;


  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private notificationService: NotificationService,
    private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService, private configurations: ConfigurationService) {
    }


  ngOnInit() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator1 = true;
    this.loadingIndicator2 = true;
    this.loadingIndicator3 = true;
    this.loadingIndicator4 = true;
    this.loadingIndicator5 = true;
    this.loadingIndicator6 = true;

    this.sysproService.getSysproUserDetailList().subscribe(x => this.onOperatorsLoadSuccessful(x), error => this.onDataLoadFailed(error));          
  }

  onOperatorsLoadSuccessful(x: SysproUser[]): void {

    this.Operator = x.filter(u => u.nextGenId.toUpperCase() == this.accountService.currentUser.id.toUpperCase())[0];

   // this.runFileSyncbackround();

    if (!!this.Operator) {
      this.operatorCode = this.Operator.sysproOperator;
      this.repCode = this.Operator.sysproRep;

      this.loadDataForConversioncalculation();
     // this.loadDataForCompanyConversioncalculation();

      this.LoadTodaysSales();
      this.LoadThisMonthSales();
      this.LoadLastMonthSales();
    }
  }

  runFileSyncbackround() {
    let currentDate: Date = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    this.sysproService.syncFileReferencesByDate(year + '-' + month, day).subscribe(x => this.loadFileReferences(x), error => this.onReferenceLoadFail(error));
    }
    onReferenceLoadFail(error: any): void {
      console.log(error);
    }
  loadFileReferences(x: any): void {
    console.log(x);
    }

  loadDataForCompanyConversioncalculation() {
    if (!!this.operatorCode) {

      let startD: Date = new Date(moment().startOf('year').toString());
      let endD: Date = new Date(moment().endOf('month').toString());

      this.alertService.startLoadingMessage();
      this.loadingIndicatorCompConversion = true;
      this.sysproService.getSalesStats(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onCompanyStatsLoadedSuccesful(x, true), error => this.onDataLoadFailed(error));
    }

  }

  onCompanyStatsLoadedSuccesful(x: SalesStats, arg1: boolean): void {

    if (x && x[0] && x[0].value) {
      this.CompconversionPercentage = x[0].value;
      this.loadingIndicatorCompConversion = false;
    }
    else {

      let startD: Date = new Date(moment().startOf('year').toString());
      let endD: Date = new Date(moment().endOf('month').toString());

      this.sysproService.getSalesReportData(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue
      ).subscribe(x => this.onSearchForCompanySalesDataSuccesful(x, true), error => this.onDataLoadFailed(error));
    }
  }


  onSearchForCompanySalesDataSuccesful(x: SalesReportData[], arg1: boolean): void {
    let startD: Date = new Date(moment().startOf('year').toString());
    let endD: Date = new Date(moment().endOf('month').toString());
    if (x && x[0]) {
      this.CompnumberOfSalesOrders = x.length;
      this.CompvalueOfOrders = x.map(a => a.invoiceTotal).reduce(function (a, b) {
        return a + b;
      });
      this.sysproService.getQuoteSalesData(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForCompanyQuoteDataSuccesful(x, true), error => this.onDataLoadFailed(error));
    }
    else {

      this.loadingIndicatorCompConversion = false;
    }
  }

  onSearchForCompanyQuoteDataSuccesful(x: QuoteSalesReportData[], arg1: boolean): void {
    //(Total sales (with no quotes) + (converted quotes)) / total quote value
    //Orders / (total quotes + sales orders) - converted quotes
    if (x && x[0]) {
      this.CompnumberOfQuotes = x.length;
      this.CompvalueOfQuotes = x.map(a => a.invoiceTotal).reduce(function (a, b) {
        return a + b;
      });
      this.CompconvertedQuotes = x.filter(x => x.quoteStatus == "S").length;
      this.CompconversionPercentage = (this.CompvalueOfOrders / ((this.CompvalueOfQuotes + this.CompvalueOfOrders)
        - this.CompconvertedQuotes)) * 100;

    }
    else {

      this.CompconversionPercentage = 100;
    }

    //this.sysproService.updateSalesStats("ALL", "Company", this.CompconversionPercentage)
    //  .subscribe(x => this.onStatsUpdateSuccesful(x, true), error => this.onDataLoadFailed(error));

    this.loadingIndicatorCompConversion = false;

  }

  loadDataForConversioncalculation() {
    let startD: Date = new Date(moment().startOf('year').toString());
    let endD: Date = new Date(moment().endOf('month').toString());

    if (!!this.operatorCode) {

      this.alertService.startLoadingMessage();
      this.loadingIndicatorMyConversion = true;
      this.sysproService.getSalesStats(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.operatorCode).subscribe(x => this.onOperatorStatsLoadedSuccesful(x, true), error => this.onDataLoadFailed(error));
    
    }
  }
    onOperatorStatsLoadedSuccesful(x: SalesStats, arg1: boolean): void {
      if (x && x[0] && x[0].value) {
        this.conversionPercentage = x[0].value;
        this.loadingIndicatorMyConversion = false;
      }
      else {
        let startD: Date = new Date(moment().startOf('year').toString());
        let endD: Date = new Date(moment().endOf('month').toString());

        this.sysproService.getSalesReportData(startD.toJSON(), endD.toJSON(),
          this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.operatorCode, this.selectUndefinedOptionValue
        ).subscribe(x => this.onSearchForOperatorSalesDataSuccesful(x, true), error => this.onDataLoadFailed(error));

      }
    }

  onSearchForOperatorSalesDataSuccesful(x: SalesReportData[], arg1: boolean): void {
  let startD: Date = new Date(moment().startOf('year').toString());
    let endD: Date = new Date(moment().endOf('month').toString());
    if (x && x[0]) {
      this.numberOfSalesOrders = x.length;
      this.valueOfOrders = x.map(a => a.invoiceTotal).reduce(function (a, b) {
        return a + b;
      });

      this.sysproService.getQuoteSalesData(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.operatorCode).subscribe(x => this.onSearchForOperatorQuoteDataSuccesful(x, true), error => this.onDataLoadFailed(error));
    }
    else {
      this.loadingIndicatorMyConversion = false;
    }
  }

  onSearchForOperatorQuoteDataSuccesful(x: QuoteSalesReportData[], arg1: boolean): void {
    if (x && x[0]) {
      this.numberOfQuotes = x.length;
      this.valueOfQuotes = x.map(a => a.invoiceTotal).reduce(function (a, b) {
        return a + b;
      });
      this.convertedQuotes = x.filter(x => x.quoteStatus == "S").length;
      this.conversionPercentage = (this.valueOfOrders / ((this.valueOfQuotes + this.valueOfOrders)
        - this.convertedQuotes)) * 100;

      if (this.conversionPercentage <= 0) {
        this.conversionPercentage = 100;
      }
    }
    else {

      this.conversionPercentage = 100;
    }

    //this.sysproService.updateSalesStats(this.operatorCode, "Operator", this.conversionPercentage)
    //  .subscribe(x => this.onStatsUpdateSuccesful(x, true), error => this.onDataLoadFailed(error));

    this.loadingIndicatorMyConversion = false;
  }
    onStatsUpdateSuccesful(x: SalesStats, arg1: boolean): void {
        
    }

  private LoadTodaysSales() {
        this.selected = {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day')
        };

        let startD: Date = new Date(this.selected.startDate.toString());
        let endD: Date = new Date(this.selected.endDate.toString());

    if (!!this.operatorCode) {
      this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.operatorCode, this.selectUndefinedOptionValue,
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForOperatorSuccesful(x, true), error => this.onDataLoadFailed(error));

    }
    if (!!this.repCode) {
      this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue,
        this.repCode, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForSalesRepSuccesful(x, true), error => this.onDataLoadFailed(error));
    }
  }


  private LoadThisMonthSales() {


    let startD: Date = new Date(moment().startOf('month').toString());
    let endD: Date = new Date(moment().endOf('month').toString());

    if (!!this.operatorCode) {
      this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.operatorCode, this.selectUndefinedOptionValue,
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForOperatorSuccesful(x, false), error => this.onDataLoadFailed(error));
    }

    if (!!this.repCode) {
      this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
        this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue,
        this.repCode, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForSalesRepSuccesful(x, false), error => this.onDataLoadFailed(error));
    }
  }


  private LoadLastMonthSales() {


    let startD: Date = new Date(moment().subtract(1, 'month').startOf('month').toString());
    let endD: Date = new Date(moment().subtract(1, 'month').endOf('month').toString());


    this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
      this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.operatorCode, this.selectUndefinedOptionValue,
      this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForOperatorSuccesful(x, false, true), error => this.onDataLoadFailed(error));

    this.sysproService.getSalesRepSummary(startD.toJSON(), endD.toJSON(),
      this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue,
      this.repCode, this.selectUndefinedOptionValue).subscribe(x => this.onSearchForSalesRepSuccesful(x, false, true), error => this.onDataLoadFailed(error));
  }


  onSearchForSalesRepSuccesful(x: SalesRepSummary[], today?: boolean, lastMonth?: boolean): void {

    this.alertService.stopLoadingMessage();
    if (!!today) {
      if (x && x[0]) {
        this.rowsRepToday = x[0];
      }
      this.loadingIndicator1 = false;
    }
    else {
      if (!!lastMonth) {
        if (x && x[0]) {
          this.rowsRepLastMonth = x[0];
        }
        this.loadingIndicator3 = false;
      }
      else {
        if (x && x[0]) {
          this.rowsRepMonth = x[0];
        }
        this.loadingIndicator2 = false;
      }
    }
   
  }


  onSearchForOperatorSuccesful(x: SalesRepSummary[], today?: boolean, lastMonth?: boolean): void {

    if (!!today) {
      if (x && x[0]) {
        this.rowOperatorToday = x[0];
      }
      this.loadingIndicator4 = false;
    } else {
      if (!!lastMonth) {
        if (x && x[0]) {
          this.rowOperatorLastMonth = x[0];
        }
        this.loadingIndicator6 = false;
      }
      else {
        if (x && x[0]) {
          this.rowOperatorMonth = x[0];
        }
        this.loadingIndicator5 = false;
      }
    }
    this.alertService.stopLoadingMessage();
  
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator1 = false;
    this.loadingIndicator2 = false;
    this.loadingIndicator3 = false;
    this.loadingIndicator4 = false;
    this.loadingIndicator5 = false;
    this.loadingIndicator6 = false;
    this.loadingIndicatorMyConversion = false;

  }


  ngAfterViewInit() {
   
  }


    ngOnDestroy() {
        if (this.dataLoadingSubscription) {
            this.dataLoadingSubscription.unsubscribe();
        }
    }


  get branchCode(): string {
    return this.configurations.branchCode;
  }



    private processResults(notifications: Notification[]) {

        if (this.isViewOnly) {
            notifications.sort((a, b) => {
                return b.date.valueOf() - a.date.valueOf();
            });
        }

        return notifications;
    }



    getPrintedDate(value: Date) {
        if (value) {
            return Utilities.printTimeOnly(value) + ' on ' + Utilities.printDateOnly(value);
        }
    }



    get canManageNotifications() {
      return true;// this.accountService.userHasPermission(Permission.manageRolesPermission); // Todo: Consider creating separate permission for notifications
  }

  sendTestSMS() {
    var testMessage = "Ello! This is a test message :)";
    this.sysproService.sendSMS(testMessage).subscribe(x => this.onsSuccess(x), error => this.onDataLoadFailed(error));
  }
    onsSuccess(x: any): void {

      var result = x;

    }

}
