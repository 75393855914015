
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { Email } from '../models/email.model';
import { CoreAudit } from '../models/core-audit.model';
import { Printers } from '../models/printers.model';


@Injectable()
export class PrinterEndpoint extends EndpointFactory {

  private readonly _printerUrl: string = '/api/Printer/';


  get printerUrl() { return this.configurations.baseUrl + this._printerUrl; }
  
  
  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  //GetPrinters
  getPrintersEndpoint<T>(): Observable<T> {
    const endpointUrl = this.printerUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPrintersEndpoint());
      }));
  }

  //GetUserPrinterMappings
  getUserPrinterMappingsEndpoint<T>(userId?: string): Observable<T> {
    const endpointUrl = `${this.printerUrl}${userId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserPrinterMappingsEndpoint(userId));
      }));
  }

  //PrintInvoice/{salesOrder}
  postPrintInvoiceEndpoint<T>(salesOrder: string, original: boolean, userDetail: any): Observable<T> {
    const endpointUrl = `${this.printerUrl}PrintInvoice/${salesOrder}/${original}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(userDetail), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postPrintInvoiceEndpoint(salesOrder, original, userDetail));
      }));
  }

  //PrintEFTTextFile/{paymentRunNumber}
  postEFTToFileEndpoint<T>(paymentRunNumber: string, postObject: any): Observable<T> {
    const endpointUrl = `${this.printerUrl}PrintEFTTextFile/${paymentRunNumber}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postEFTToFileEndpoint(paymentRunNumber, postObject));
      }));
  }


  getFileStreamEndpoint<T>(fileName?: string): Observable<T> {
    const endpointUrl = `${this.printerUrl}GetFileStream/${fileName}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamEndpoint(fileName));
      }));
  }

  updateSysproPayRunEndpoint<T>(paymentRunNumber: string): Observable<T> {
    const endpointUrl = `${this.printerUrl}UpdateSysproPayRun/${paymentRunNumber}`;
    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateSysproPayRunEndpoint(paymentRunNumber));
      }));
  }


  editPrinterEndpoint<T>(printer: Printers, id: number) {
    const endpointUrl = `${this.printerUrl}EditPrinter/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(printer), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.editPrinterEndpoint(printer, id));
      }));
  }

  newPrinterEndpoint<T>(printer: Printers) {
    const endpointUrl = `${this.printerUrl}NewPrinter`;

    return this.http.put<T>(endpointUrl, JSON.stringify(printer), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.newPrinterEndpoint(printer));
      }));
  }

  deletePrinterEndpoint<T>(id: number) {
    const endpointUrl = `${this.printerUrl}DeletePrinter/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deletePrinterEndpoint(id));
      }));
  }

}
