

export class Establishment {

  constructor(branchCode?: string, companyCode?: string, company?: string, establishment?: string, establishmentType?: string) {

    this.branchCode = branchCode;
    this.companyCode = companyCode;
    this.company = company;
    this.establishment = establishment;
    this.establishmentType = establishmentType;
  }

  public establishmentId: number;
  public branchCode: string;
  public companyCode: string;
  public company: string;
  public establishment: string;
  public establishmentType: string;
  public comments: string;
  public country: string;
  public city: string;
  public tel: string;
  public fax: string;
  public email: string;
  public companyRegistration: string;
  public taxNumber: string;
  public addressLine1: string;
  public addressLine2: string;
  public addressLine3: string;
  public addressLine4: string;
  public addressLine5: string;
  public areaCode: string;
  public postalAddress1: string;
  public postalAddress2: string;
  public postalAddress3: string;
  public postalAddress4: string;
  public postalAddress5: string;
  public postalCode: string;
  public countryDiallingCode: string;
  public cityDiallingCode: string;
  public hoursWeekdays: string;
  public hoursSaturdays: string;
  public hoursSundays: string;
  public enabled: boolean;
  public googleBusinessProfile: string;
  public googleHyperlink: string;

}
