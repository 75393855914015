import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { MySQLDocument } from '../../../models/mysqldocument.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { Branch } from '../../../models/branch.model';
import { InvoiceBarcode } from '../../../models/invoice-barcode.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { BarcodeReference } from '../../../models/barcode-reference.model';



@Component({
  selector: 'pod-sync',
  templateUrl: './pod-sync.component.html',
  styleUrls: ['./pod-sync.component.scss']
})
export class PodSyncComponent implements OnInit {

  @ViewChild('searchResultSelector')
  private searchResultSelector;

  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  searchResults: InvoiceBarcode[];
  searchType: string;
  searchText: string;
  searchResultSelected: InvoiceBarcode;


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  checkCreditNotes: boolean;
  columns: any[] = [];
  rows: InvoiceBarcode[] = [];
  rowsCache: InvoiceBarcode[];

  columnsBarcodeReference: any[] = [];
  BarcodeReferenceList: BarcodeReference[] = [];
  BarcodesUpdated: BarcodeReference[];

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  selectedBranch: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;

  loadingIndicator: boolean = false;

  selected: { startDate: Moment, endDate: Moment };
  show: boolean;

  ranges = {
    //'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  constructor(private router: Router, private alertService: AlertService, private fowkesService: FowkesOnlineService, private sysproService: SysproService,  private route: ActivatedRoute, private accountService: AccountService) {

  }

  ngOnInit() {

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesService.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    this.selected = {
      startDate: moment().subtract(7, 'day').startOf('day'),
      endDate: moment().subtract(0, 'day').endOf('day')
    }

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());
    //Sync Search Batches:

    //Syspro Invoices not yet having documents
    this.columns = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'docType', name: 'Type', width: 50 },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'customer', name: 'Customer', width: 90, cellClass: "center" },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      //{ prop: 'invoiceTotal', name: 'Total', width: 90, cellClass: "right", cellTemplate: this.totalTemplate  },
      { prop: 'invoiceDate', name: 'Date', width: 100, cellTemplate: this.dateTemplate }
     // { prop: 'OrderStatus', name: 'Order Status', width: 100 }
    ];

    this.columns.push({ name: '', width: 300, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    //this.sysproService.getInvoiceBarcodeSyncReport(startD.toJSON(), endD.toJSON(),
    //  this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue, this.selectUndefinedOptionValue).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

    this.columnsBarcodeReference = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'prefix', name: 'Prefix', width: 50, cellClass: "center" },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'status', name: 'Status', width: 120 },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      { prop: 'fileNumber', name: 'Count', width: 90 },
      { prop: 'barcode', name: 'Barcode', width: 90 },
      { prop: 'fileName', name: 'File Name', width: 200 },
      { prop: 'barcodeDate', name: 'DocumentDate', width: 90, cellTemplate: this.dateTemplate },
      { prop: 'exclude', name: 'Excluded', width: 90 }
    ];

  }

  ngAfterViewInit() {

    this.columns = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'docType', name: 'Type', width: 50 },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'customer', name: 'Customer', width: 90, cellClass: "center" },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      //{ prop: 'invoiceTotal', name: 'Total', width: 90, cellClass: "right", cellTemplate: this.totalTemplate },
      { prop: 'invoiceDate', name: 'Date', width: 100, cellTemplate: this.dateTemplate }
     // { prop: 'OrderStatus', name: 'Order Status', width: 100 }
    ];

    this.columns.push({ name: '', width: 300, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    this.columnsBarcodeReference = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'prefix', name: 'Prefix', width: 50, cellClass: "center" },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'status', name: 'Status', width: 120 },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      { prop: 'fileNumber', name: 'Count', width: 90 },
      { prop: 'barcode', name: 'Barcode', width: 90 },
      { prop: 'fileName', name: 'File Name', width: 200 },
      { prop: 'barcodeDate', name: 'DocumentDate', width: 90, cellTemplate: this.dateTemplate },
      { prop: 'exclude', name: 'Excluded', width: 90 }
    ];

  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.company, r.customer, r.branch, r.docType, r.invoice));
    }
    else {
      this.rows = this.rowsCache;
    }
  }

  specificSearch() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.searchResults = null;

    //This loads the Local invoices not yet having barcodes in the reference table
    this.loadInvoicesForSync();
  }


  private loadInvoicesForSync() {


    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);
    let customer = this.selectUndefinedOptionValue;
    let documentType = this.selectUndefinedOptionValue;
    if (this.selectedDocumentType) {
      if (this.selectedDocumentType == "Canceled") {
        customer = "Canceled";
      }
      else {
        if (this.selectedDocumentType == "IncludeMissing") {
          customer = "IncludeMissing";
        }
        else {
          documentType = this.selectedDocumentType;
        }
      }
    }

    this.sysproService.getInvoiceBarcodeSyncReport(startD.toJSON(), endD.toJSON(),
      company, branch, customer, documentType).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
  }


    onSearchSuccesful(x: InvoiceBarcode[]): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      if (x && x[0] && x.length > 0) {
        const details = x;
        details.forEach((line, index, details) => {
          (<any>line).index = index + 1;
        });

        this.rowsCache = [...details];
        this.rows = details;

      }
      else {
        this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
          MessageSeverity.warn);
      }
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  syncInvoice(row: any) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let temp = [...this.rows];
    temp.splice(row.index - 1, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.rowsCache = [...temp];
    this.rows = temp;

    this.sysproService.getBarcodeForInvoiceSync(row.invoice, row.branch).subscribe(x => this.onBarcodeSearchSuccesful(x), error => this.onDataLoadFailed(error));
  }

  excludeInvoice(row: any) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let temp = [...this.rows];
    temp.splice(row.index - 1, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.rowsCache = [...temp];
    this.rows = temp;

    this.sysproService.updateBarcodeExcluded(row.invoice).subscribe(x => this.onBarcodeSearchSuccesful(x), error => this.onDataLoadFailed(error));
  }

  syncToNextGen() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    for (var invoice of this.rows) {
      this.sysproService.getBarcodeForInvoiceSync(invoice.invoice, invoice.branch).subscribe(x => this.onBarcodeSearchSuccesful(x, 1), error => this.onDataLoadFailed(error));
    }
  }

  excludeItems() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    for (var invoice of this.rows) {
      this.sysproService.updateBarcodeExcluded(invoice.invoice).subscribe(x => this.onBarcodeSearchSuccesful(x, 1), error => this.onDataLoadFailed(error));
     
    }
  }

  onBarcodeSearchSuccesful(x: BarcodeReference[], rowIndex?: number): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {
      let details = x;
      details.forEach((line, index, details) => {
        
        (<any>line).index = index + 1;
        let newLine = new BarcodeReference();
        newLine.invoice = line.invoice;
        newLine.branch = line.branch;
        newLine.fileNumber = line.fileNumber;
        newLine.fileName = line.fileName;
        newLine.status = line.status;
        newLine.barcode = line.barcode;
        newLine.barcodeDate = line.barcodeDate;
        newLine.exclude = line.exclude;
        newLine.prefix = line.prefix;
        newLine.company = line.company;
        this.BarcodeReferenceList.push(newLine);
        if (rowIndex) {
          let rowNum = this.rowsCache.findIndex(x => x.invoice == line.invoice);
          let temp = [...this.rows];
          temp.splice(rowNum -1, 1);
          temp.forEach((line, index, temp) => {
            (<any>line).index = index + 1;
          });

          this.rowsCache = [...temp];
        }
      });

      //this.BarcodeReferenceList = details;
      this.BarcodesUpdated = [...this.BarcodeReferenceList];
      if (rowIndex) {
        this.rows = this.rowsCache;
      }
      
    }
  }

  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }



  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

}
