

export class SysproEFTDetail {

  public strSupplierBranch: string;
  public strSupplierBank: string;
  public strSupplier: string;
  public strSuppName: string;
  public strSupplierRefNo: string;
  public decSuppPayValue: number;
  public decLineSubTot: number;
  public strStatus: string;

}
