
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Inventory } from 'src/app/models/inventory.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';



@Component({
  selector: 'products-calculator',
  templateUrl: './products-calculator.component.html',
  styleUrls: ['./products-calculator.component.scss']
})


export class ProductsCalculatorComponent implements OnInit {
 
  loadingIndicator: boolean;

  @ViewChild('calcModal')
  calcModal: ModalDirective;

  totalArea: number;
  csmQuantity: number;
  resinQuantity: number;
  poolCoatQuantity: number;
  catalystQuantity: number;

  poolWidth: number;
  poolLength: number;
  poolDepthShallow: number;
  poolDepthDeep: number;
  avgDepth: number;

  floorArea: number;
  wallArea: number;
  surfaceArea: number;

  calculated: boolean;
  calWastage: number;

  poolDiameter: number;
  totalCircularArea: number;
  poolDepthShallowC: number;
  poolDepthDeepC: number;
  avgDepthC: number;
  calWastageC: number;
  poolCircumference: number;
  img1 = require('../../assets/images/Calc1.png');
  img2 = require('../../assets/images/Calc2.png');
  img3 = require('../../assets/images/Calc3.png');
  img4 = require('../../assets/images/Calc4.png');
  // pdfFile1Url = '../../assets/images/Application_Guidance_Swimming_Pools.pdf';
  //pdfFile2Url = require('../../assets/images/Required_Materials_Swimming_Pools.pdf');
  //pdfFile3Url = require('../../assets/images/Square_Meter_Area_Swimming_Pools.pdf');

  selectedPoolShape: string = '';
  resinLiters: number;
  specificGravity: number;
  totalCsm300gsmKg: number;
  totalCsm450gsmKg: number;
    surfaceTissueTotalKg: number;
    surfaceTissuePerSqm: number;
    resinQuantity450: number;
    resinQuantity300: number;
    catalystQuantity450: number;
    catalystQuantity300: number;
    additionalResinForSurfaceTissue: number;


  constructor(private alertService: AlertService, private fowkesService: FowkesOnlineService,
    private sysproService: SysproService,  private configurations: ConfigurationService,) {
  }


  ngOnInit() {

    this.calculated = false;
    this.calWastage = 10;
    this.calWastageC = 10;

    this.poolDepthDeep = 1.8;
    this.poolDepthShallow = 1.2;

    this.poolDepthDeepC = 1.8;
    this.poolDepthShallowC = 1.2;

    this.specificGravity = 1000;
    this.surfaceTissuePerSqm = 300;

    this.calculateAverageDepth();
    this.calculateAverageDepthC();

  }

  selectPoolShape(shape: string) {
    this.selectedPoolShape = shape;
  }

  calculateArea() {

    // Validation check for width and length
    if (!this.poolWidth || this.poolWidth <= 0 || !this.poolLength || this.poolLength <= 0) {
      this.alertService.showMessage('Warning', `Please enter valid width and length values.`, MessageSeverity.warn);
      return;
    }

    this.avgDepth = (this.poolDepthShallow + this.poolDepthDeep) / 2;

    //Floor Area
    this.floorArea = this.poolLength * this.poolWidth;

    //Wall area
    this.wallArea = 2 * (this.poolLength * this.avgDepth) + 2 * (this.poolWidth * this.avgDepth);

    //Surface Area
    this.surfaceArea = this.totalArea = this.floorArea + this.wallArea;

     // Area calculation
    //this.totalArea = this.poolLength * this.poolWidth * this.avgDepth;
    this.totalArea = this.surfaceArea;

    //Add wastage
    this.totalArea *= (1 + (this.calWastage / 100));


    //CSM (Chop Strand Mat) 450gsm: Since 450gsm(grams per square meter) is the density, you calculate the CSM quantity by multiplying the total area by 450gsm.Convert the result to kilograms.
    this.totalCsm450gsmKg = this.totalArea * 450 / 1000; // Convert grams to kilograms

    //Add additional layer to 300
    this.totalCsm300gsmKg = this.totalArea * 300 / 1000; // Convert grams to kilograms

    //Resin (2.5:1 Ratio):
    this.resinQuantity450 = this.totalCsm450gsmKg * 2.5;
    //3 for 300
    this.resinQuantity300 = this.totalCsm300gsmKg * 2.5;

    //PoolCoat (600gsm):
    this.poolCoatQuantity = this.totalArea * 600 / 1000; // Convert grams to kilograms

    //Catalyst (2%): The catalyst quantity is 2% of the combined quantity of Resin and PoolCoat. 
    this.catalystQuantity450 = (this.resinQuantity450 + this.poolCoatQuantity) * 0.02;
    this.catalystQuantity300 = (this.resinQuantity300 + this.poolCoatQuantity) * 0.02;

    // Example for the rectangular pool calculation
    this.resinLiters = this.totalArea * this.specificGravity; // Add this line to calculate liters

    this.surfaceTissueTotalKg = (this.surfaceTissuePerSqm * this.totalArea) / 1000; // Convert to kg

    // Calculate additional resin for surface tissue with 10% wastage
    this.additionalResinForSurfaceTissue = this.totalArea * 300 / 1000; // Convert grams to kilograms

    //0.3 times surface area additionalResin for top coat


    this.calculated = true;

    if (!this.calcModal) {
      setTimeout(() => {
        if (this.calcModal) {
          this.calcModal.show();
        }
      });
    }
    else {
      this.calcModal.show();
    }
  }

  calculateCircularArea() {

  
    let radius;

    if (this.poolDiameter && this.poolDiameter > 0) {
      radius = this.poolDiameter / 2;
    } else if (this.poolCircumference && this.poolCircumference > 0) {
      radius = this.poolCircumference / (2 * Math.PI);
    } else {
      // Handle case where neither diameter nor circumference is provided
      this.alertService.showMessage('Warning', `Make sure to enter the diameter`, MessageSeverity.warn);
      return;
    }

    // Calculate average depth first
    this.avgDepthC = (this.poolDepthShallowC + this.poolDepthDeepC) / 2;

    // Calculate floor and wall areas
    let floorArea = Math.PI * radius * radius; // Area = πr²
    let wallArea = 2 * Math.PI * radius * this.avgDepthC;

    // Total surface area
    this.totalArea = floorArea + wallArea;

    // Add wastage
    this.totalArea *= (1 + (this.calWastageC / 100));

    // Material calculations
    this.csmQuantity = this.totalArea * 450 / 1000; // Convert grams to kilograms
    this.resinQuantity = this.csmQuantity * 2.5;
    this.poolCoatQuantity = this.totalArea * 600 / 1000; // Convert grams to kilograms
    this.catalystQuantity = (this.resinQuantity + this.poolCoatQuantity) * 0.02;

    //CSM (Chop Strand Mat) 450gsm: Since 450gsm(grams per square meter) is the density, you calculate the CSM quantity by multiplying the total area by 450gsm.Convert the result to kilograms.
    this.totalCsm450gsmKg = this.totalArea * 450 / 1000; // Convert grams to kilograms

    //Add additional layer to 300
    this.totalCsm300gsmKg = this.totalArea * 300 / 1000; // Convert grams to kilograms

    //Resin (2.5:1 Ratio):
    this.resinQuantity450 = this.totalCsm450gsmKg * 2.5;
    //3 for 300
    this.resinQuantity300 = this.totalCsm300gsmKg * 2.5;

    //Catalyst (2%): The catalyst quantity is 2% of the combined quantity of Resin and PoolCoat. 
    this.catalystQuantity450 = (this.resinQuantity450 + this.poolCoatQuantity) * 0.02;
    this.catalystQuantity300 = (this.resinQuantity300 + this.poolCoatQuantity) * 0.02;


    this.resinLiters = this.totalArea * this.specificGravity; // Add this line to calculate liters
    this.surfaceTissueTotalKg = (this.surfaceTissuePerSqm * this.totalArea) / 1000; // Convert to kg
    // Calculate additional resin for surface tissue with 10% wastage
    this.additionalResinForSurfaceTissue = this.totalArea * 300 / 1000; // Convert grams to kilograms

    this.calculated = true;
    if (!this.calcModal) {
      setTimeout(() => {
        if (this.calcModal) {
          this.calcModal.show();
        }
      });
    }
    else {
      this.calcModal.show();
    }
  }

  calculateAverageDepth() {
    if (this.poolDepthShallow && this.poolDepthDeep) {
      this.avgDepth = (this.poolDepthShallow + this.poolDepthDeep) / 2;
    }
  }


  calculateAverageDepthC() {
    if (this.poolDepthShallowC && this.poolDepthDeepC) {
      this.avgDepthC = (this.poolDepthShallowC + this.poolDepthDeepC) / 2;
    }
  }

  closeCalc() {
    // Close the modal
    this.calcModal.hide();
    this.calculated = false;
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  openWebsiteLink(searchString: string) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(searchString), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(searchString), "_blank");
    }
  }

  openPDF(doc: number) {
    let fileName = '';

    switch (doc) {
      case 1:
        fileName = 'Application Guidance (Swimming pools).pdf';
        break;
      case 2:
        fileName = 'Required Materials (Swimming Pools).pdf';
        break;
      case 3:
        fileName = 'Square Meter Area (Swimming Pools).pdf';
        break;
      default:
        console.error('Document not found');
        return;
    }

    window.open(`/MediaServer/ContentManagementDocuments/PublicDocuments/${fileName}`, "_blank");
  }



}
