
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, OnChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthService } from '../../services/auth.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { PrinterService } from '../../services/printer.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteHeader } from 'src/app/models/quote-header.model';
import { Email } from 'src/app/models/email.model';
import { User } from 'src/app/models/user.model';
import { SalesOrderHeader } from 'src/app/models/salesorder-header.model';
import { SalesOrderDetail } from 'src/app/models/salesorder-detail.model';
import { SalesOrderStatus } from 'src/app/models/salesorder-status.model';
import { SalesOrderCancelReasonCode } from 'src/app/models/salesorder-cancelreasons.model';
import { PurchaseOrderList } from 'src/app/models/purchaseorder-list.model';
import { validation } from '../../services/validation.service';
import { InvoiceHeader } from '../../models/invoice-header.model';
import { PurchaseOrderHeader } from '../../models/purchaseorder-header.model';
import { UserLogin } from '../../models/user-login.model';
import { CoreAudit } from '../../models/core-audit.model';
import { Printers } from '../../models/printers.model';
import { PrinterUserMappings } from '../../models/printer-user-mappings.model';
import { WHAvailQTY } from '../../models/inventory-wh.model';
import { SalesOrderList } from '../../models/salesorder-list.model';
import { InvoiceTrackingLookup } from '../../models/invoice-tracking-lookup.model';
import { PurchaseOrderDetail } from '../../models/purchaseorder-detail.model';


@Component({
  selector: 'syspro-salesorder-view',
  templateUrl: './syspro-salesorder-view.component.html',
  styleUrls: ['./syspro-salesorder-view.component.scss']
})
export class SysproSalesOrderViewComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  userLogin = new UserLogin();
  auditObj = new CoreAudit();
  emailObject: Email = new Email();

  @ViewChild('loginModal')
  loginModal: ModalDirective;

  uniqueId: string = Utilities.uniqueId();

  loadingIndicator: boolean;

  rows: SalesOrderDetail[] = [];
  rowsCache: SalesOrderDetail[] = [];
  Printers: Printers[] = [];
  printerMappings: PrinterUserMappings[] = [];
  inventoryList: Inventory[];
 


  @ViewChild('statusModal')
  statusModal: ModalDirective;

  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;

  @ViewChild('printModal')
  printModal: ModalDirective;

  @ViewChild('commentsModal')
  commentsModal: ModalDirective;

  @ViewChild('commentsSelector')
  private commentsSelector;
  comments: string[];
  newComment: string;
  commentLine: SalesOrderDetail = new SalesOrderDetail();

  @ViewChild('authModal')
  authModal: ModalDirective;

  authorized: boolean;
  stringToCompare: string;


  @Input()
  selectedSalesOrder: string;

  @Input()
  isViewOnly: boolean;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    quoteId: string;
  quote: QuoteHeader;
  salesOrderNumber: string;
 // salesOrder: SalesOrderHeader;
  salesOrder: SalesOrderHeader = new SalesOrderHeader();
    pdfName: string;
    isUpdating: any;
  quoteHeader: QuoteHeader = new QuoteHeader();
    user: any;
    allRoles: any[];
    src: string;
    OrderStatuses: SalesOrderStatus;

  CancelCodes: SalesOrderCancelReasonCode;
  CancelReason: string;

  @ViewChild('cancelreasons')
  private cancelreasons;

  @ViewChild('cancelreasonsSelector')
  private cancelreasonsSelector;

  @ViewChild('statusCodeSelector')
  private statusCodeSelector;

  @ViewChild('printerSelector')
  private printerSelector;

  purchaseOrders: PurchaseOrderList[] = [];
  purchaseOrderDetails: PurchaseOrderDetail[] = [];
  poNumber: any;
    OrderStatus: string;
    showCancelReasons: boolean;
    currentStatus: string;
    sessionId: string;
    canEdit: any;
    hasBuyouts: boolean;
    placePurchaseOrder: boolean;
    SysproUser: User;
    poCount: number;
    poPostedCount: any;
  supervisor: User;
  selectedPrinter: string;
    lineToCancel: number;
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  stockCodedetail: Inventory;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;

  hasDataSheets: boolean;
  attachDataSheets: boolean;
  attachSpecials: boolean;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
  waiting: boolean;
  hasAddDataSheet: boolean;
    lookup: InvoiceTrackingLookup;
    buyerResponsible: string;


  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute, private authService: AuthService, private printerService: PrinterService) {
    }


  ngOnInit() {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    let salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
    this.id = (action ? action.trim() : "");

    this.searchType = "salesorder";
    this.SysproUser = new User();

    if (salesOrderNumber && salesOrderNumber != "") {

      this.salesOrderNumber = salesOrderNumber;
      this.auditObj.referenceType = "SALES ORDER";
      this.auditObj.referenceNumber = this.salesOrderNumber;
      this.auditObj.branch = this.branchCode;
      this.auditObj.applicationUser = this.authService.currentUser.email;
      this.auditObj.actionedBy = this.authService.currentUser.email;
      this.auditObj.groupKey = this.accountService.currentUser.id;
      this.postAudit();
      this.loadData();

    }

    this.printerService.GetPrinters().subscribe(x => this.onPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));
  }


  ngOnChanges() {

    this.isViewOnly = this.isViewOnly == true ? this.isViewOnly : false;

    if (this.selectedSalesOrder) {

      this.salesOrderNumber = this.selectedSalesOrder;
        this.auditObj.referenceType = "SALES ORDER";
        this.auditObj.referenceNumber = this.salesOrderNumber;
        this.auditObj.branch = this.branchCode;
        this.auditObj.applicationUser = this.authService.currentUser.email;
        this.auditObj.actionedBy = this.authService.currentUser.email;
        this.auditObj.groupKey = this.accountService.currentUser.id;
        this.postAudit();
        this.loadData();

      }

  }

  ngAfterViewInit() {

  }
  

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getSalesOrderHeader(this.salesOrderNumber).subscribe(x => this.onSalesOrderHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getSalesOrderCancelReasonCodes().subscribe(x => this.onCancelCodesLoaded(x), error => this.onDataLoadFailed(error));
    this.sysproService.getSalesOrderStatuses().subscribe(x => this.onStatusesLoaded(x), error => this.onDataLoadFailed(error));

    this.sysproService.GetSysproComments().subscribe(x => this.onCommentsLoadSuccessful(x), error => this.onDataLoadFailed(error));

    //if (this.sessionId == "" || !this.sessionId) {
    //  this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onsessionCreatedSuccesful(x), error => this.onSessionFailed(error));
    //}
    

  }
  onCommentsLoadSuccessful(x: string[]): void {
    this.comments = x;
    setTimeout(() => {
      if (this.commentsSelector) {
        this.commentsSelector.refresh();
      }
    });
    this.newComment = "";
    console.log("COMMENTS", this.comments);
  }

  openCommentsModal() {
    this.commentsModal.show();
    this.commentLine = new SalesOrderDetail();
    this.commentLine.commentType = "I";
    console.log("COMMENT LINE", this.commentLine);
  }

  addTopComment() {
    const lineNumber = 0;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___");
    this.sysproService.InsertSalesOrderComments(this.salesOrder.salesOrderNumber, lineNumber, comment, 0,
      "TOP", true)
      .subscribe(x => this.loadData(), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }

  addComment() {
    const lineNumber = this.rows.length + 1;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___");
    let isPreviousLineAComment = (this.rows[this.rows.length - 1].lineType == "6")? "Y" : "N";
    this.sysproService.InsertSalesOrderComments(this.salesOrder.salesOrderNumber, lineNumber, comment, 0,
      isPreviousLineAComment, true)
      .subscribe(x => this.loadData(), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }


  get defaultPrinter(): string {
    return this.configurations.defaultPrinter;
  }

  onPrintersLoadSuccesful(x: Printers[]): void {
    this.Printers = x;

    setTimeout(() => {
      if (this.printerSelector) {
        this.printerSelector.refresh();
      }
    });

    if (this.defaultPrinter) {
      this.selectedPrinter = this.defaultPrinter;
    }
    else {
      this.printerService.GetUserPrinterMappings(this.accountService.currentUser.id).subscribe(x => this.onUserPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));
    }
    console.log("Printers", x);
  }
  onUserPrintersLoadSuccesful(x: PrinterUserMappings[]): void {
   
    this.printerMappings = x;

    if (this.printerMappings.length > 0) {
      const printerForInvoice = this.printerMappings.filter(p => p.reportName === "Invoice");
      this.selectedPrinter = (printerForInvoice.length ? printerForInvoice[0].printerName : this.selectedPrinter);
    }

    console.log("Printer Mappings", x);
  }

    onStatusesLoaded(x: SalesOrderStatus): void {
      this.OrderStatuses = x;
      setTimeout(() => {
        if (this.statusCodeSelector) {
          this.statusCodeSelector.refresh();
        }
      });
      console.log("STATUSES", this.OrderStatuses);
    }
    onCancelCodesLoaded(x: SalesOrderCancelReasonCode): void {
      this.CancelCodes = x;
      setTimeout(() => {
        if (this.cancelreasonsSelector) {
          this.cancelreasonsSelector.refresh();
        }
      });
      console.log("CANCEL CODES", this.CancelCodes);
    }

    get branchCode(): string {
    return this.configurations.branchCode;
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText.trim()).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
    onSearchFailed(error: any): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
        MessageSeverity.error);
    }


  onSpecificSearchSuccesful(x: SalesOrderList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

    if (x.length === 1) {
      this.searchResultSelected = x[0];
      this.viewSalesOrderSelected();
    }

  }

  viewSalesOrderSelected() {
    if (this.searchResultSelected) {
      sessionStorage.removeItem(localStorageActions.Customer);
      sessionStorage.setItem(localStorageActions.Customer, this.searchResultSelected.customer);
      sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.searchResultSelected.salesOrderNumber);
      if (this.searchResultSelected.invoiceNumber) {
        sessionStorage.setItem(localStorageActions.InvoiceNumber, this.searchResultSelected.invoiceNumber);
      }
      this.id = this.searchResultSelected.customer;
      this.salesOrderNumber = this.searchResultSelected.salesOrderNumber;
      this.auditObj.referenceType = "SALES ORDER";
      this.auditObj.referenceNumber = this.salesOrderNumber;
      this.auditObj.branch = this.branchCode;
      this.auditObj.applicationUser = this.authService.currentUser.email;
      this.auditObj.actionedBy = this.authService.currentUser.email;
      this.loadData();
      this.router.navigate(['../../salesorders'], { fragment: 'view' });

    }
  }
    
  onSalesOrderHeaderLoadSuccessful(order: SalesOrderHeader, updateAudit?: boolean) {
   
    let res = order;
    if (res && res[0] && res[0].orderStatus) {
      this.salesOrder = res[0];
      this.currentStatus = this.salesOrder.orderStatus;
      this.canEdit = validation.validateCanEditOrder(this.currentStatus);

      this.sysproService.getSalesOrderDetail(this.salesOrderNumber).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));

      this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", this.salesOrder.salesOrderNumber).
        subscribe(x => this.POSearchSuccesful(x), error => this.onPOLoadFailed(error));

      if (updateAudit) {
        this.postAudit();
      }
    }
    else {
      this.alertService.showMessage('Load Error', `This sales order could not be found`,
        MessageSeverity.error);
    }
    

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  onPOLoadFailed(error: any): void {

  }
  POSearchSuccesful(x: PurchaseOrderList[]): void {
    this.purchaseOrders = [];
    
    if (x.length >= 1) {
    
      let defaultPO = new PurchaseOrderList();
      defaultPO.purchaseOrder = this.selectUndefinedOptionValue;
      defaultPO.poNumber = this.selectUndefinedOptionValue;
      defaultPO.descr = this.selectUndefinedOptionValue;
      this.purchaseOrders.push(defaultPO);
      this.purchaseOrders = [...this.purchaseOrders, ...x];
      this.hasBuyouts = true;
      this.placePurchaseOrder = true;
    }
  }

  onSalesOrderUpdatedSuccessful(x: any): void {
    if (x.message) {
      this.alertService.showStickyMessage('Failed', 'Updating Sales Order Failed ', MessageSeverity.error);
      this.alertService.showStickyMessage(x.message, null, MessageSeverity.error);
    }
    else {
      this.onCartDetailLoadSuccessful(x);
    }
  }

  onCartDetailLoadSuccessful(salesOrderDetail: SalesOrderDetail[]) {

    if (salesOrderDetail && salesOrderDetail.length >= 1) {

      this.rowsCache = [...salesOrderDetail];
      const updateRows = this.rowsCache;
      const vat = this.salesOrder.vatPercentage;
      this.TotalLines = updateRows.filter(x => x.lineType !== "6").length;
      this.TotalItems = 0;
      this.TotalExcl = 0;
      this.TotalDiscount = 0;
      this.TotalExclAfterDiscount = 0;
      this.TotalVat = 0;
      this.TotalIncl = 0;

      for (var product of this.rowsCache) {
        var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.sellingPrice, product.priceConvFac, product.lineTotal);
        var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.sellingPrice, product.discountPercentage1, product.priceConvFac, 0);
        product.nettPrice = Calculations.getGridNettLinePrice(product.orderQty, product.sellingPrice, product.priceConvFac, product.discountPercentage1, 0);

        this.TotalItems += product.orderQty;
        this.TotalExcl += lineTotalExclVat;
        this.TotalDiscount += lineDiscount;

        var lineVat = 0;
        if (vat && product.vatExempt != true) {
          lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);
        }
        //var lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);

        this.TotalVat += lineVat;

        this.calcTotal(product);
      }

       this.TotalExclAfterDiscount = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero(this.TotalDiscount, 2));
    //  this.TotalExclAfterDiscount = (Calculations.RoundAwayFromZero(this.TotalExcl - this.TotalDiscount, 2));
      this.TotalIncl = Calculations.RoundAwayFromZero((this.TotalExclAfterDiscount + this.TotalVat), 2);

      this.rows = this.rowsCache;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  
  viewCustomer() {
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }


  viewPurchaseOrder() {
    if (this.poNumber != "" && this.poNumber != "-1" && this.poNumber != this.selectUndefinedOptionValue) {
      sessionStorage.removeItem(localStorageActions.PurchaseOrder);
      sessionStorage.setItem(localStorageActions.PurchaseOrder, this.poNumber);
      this.router.navigate(['../../purchaseorders'], { fragment: 'purchaseorder', queryParams: { customer: this.id } });
    }
  }

  changeStatus(cancel: boolean) {

    let error = "";
    this.showCancelReasons = cancel;

    error = validation.validateOrderStatus(this.currentStatus, this.OrderStatus, error);
    if (error.length > 1) {
      this.alertService.showMessage('Error', error, MessageSeverity.error);
    }
    else {
      this.statusModal.show();

      if (this.showCancelReasons) {
        this.OrderStatus = "'\'";


      }
      else {
        this.OrderStatus = this.salesOrder.orderStatus;
      }
    }
  }

  saveStatus() {

    if (this.OrderStatus === "9") {

      this.auditObj.actionType = "CLOSE ORDER";
      this.auditObj.actionInput = this.salesOrder.orderStatus + " - " + this.OrderStatus;
      this.alertService.showDialog('You have selected status 9 - Complete. Do you want to close this order? You will not be able to invoice once in status 9.',
        DialogType.confirm, () => this.continueUpdate(), this.cancelUpdate(), "Yes, Close Order", "No, Cancel Update");
    }
    else {

      this.continueUpdate();
    }
  }

  private continueUpdate() {
    this.alertService.startLoadingMessage('Loggin into Syspro...');
    this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
      this.sessionId = x.sysproSessionId;
      this.SysproUser = x;
      this.auditObj.sysproOperator = this.SysproUser.sysproOperator;
      this.alertService.startLoadingMessage('Creating Invoice...');
      if (this.OrderStatus === "'\'" && this.CancelReason !== "") {
        this.auditObj.actionType = "CANCEL";
        this.auditObj.actionInput = this.salesOrder.orderStatus + ": " + this.CancelReason;
        this.auditObj.actionOutput = "Cancelled Sales Order";
        //Cancle order
        this.sysproService.UpdateSalesOrderStatus(this.salesOrder.salesOrderNumber, this.OrderStatus, this.CancelReason, this.sessionId).subscribe(x => this.onSalesOrderHeaderLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
      }
      else {

        if (this.canReleaseSalesOrders)
        {
          if (this.OrderStatus === "8") {

            this.auditObj.actionType = "RELEASE ORDER";
            this.auditObj.actionInput = this.salesOrder.orderStatus + " - " + this.OrderStatus;
            this.authModal.show();
          }
          else {
            this.auditObj.actionType = "CHANGE STATUS";
            this.auditObj.actionInput = this.salesOrder.orderStatus + " - " + this.OrderStatus;
            this.sysproService.UpdateSalesOrderStatus(this.salesOrder.salesOrderNumber, this.OrderStatus, "none", this.sessionId).subscribe(x => this.onSalesOrderHeaderLoadSuccessful(x, true), error => this.onDataLoadFailed(error));

          }
        }
        else {
          this.authorized = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showMessage('Warning', 'Only Finance and Supervisors are Authorized to release Sales Orders', MessageSeverity.error);
          // this.loginModal.show();
        }
      }
      this.statusModal.hide();
    }, error => this.onSessionFailed(error));
  }

  cancelUpdate(): any {
    this.statusModal.hide();
  }
  

  verifyAuth() {
    this.alertService.startLoadingMessage("Verfiying...");
    this.loadingIndicator = true;
    this.fowkesService.VerifyPassword(this.branchCode, "RELEASE", encodeURIComponent(this.stringToCompare)).subscribe(x => this.onVerificationSuccess(), error => this.onVerificationFailed(error));

  }

  onVerificationSuccess(): void {
    this.authorized = true;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
    this.authModal.hide();
    this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
      this.sessionId = x.sysproSessionId;
      this.SysproUser = x;
      this.sysproService.UpdateSalesOrderStatus(this.salesOrder.salesOrderNumber, this.OrderStatus, "none", this.sessionId).subscribe(x => this.onSalesOrderHeaderLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
    }, error => this.onSessionFailed(error));
  }

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }

  validateCancel(): boolean {
    let error = "";

    if (error == "") {
     // error = validation.validateCustomerPO(this.cart.customerPoNumber, error);

      //for (var product of this.rows) {
      //  if (product.lineType == "1") {
      //    error = validation.validateQtyForOrder(product.stockCode, product.availQty, product.orderQty, error);
      //  }
      //}
    }

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  goAddItems() {
    this.router.navigate(['../../quotes'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }
  
  goAddNSItems() {
    this.router.navigate(['../../quotes'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goInvoice() {

    this.isNavigating = true;
    if (this.validatePost()) {
     
      this.printModal.show();     
    }
    
  }


  onsessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);

    this.sessionId = x.sysproSessionId;
    this.SysproUser = x;
    this.auditObj.sysproOperator = this.SysproUser.sysproOperator;
    this.waiting = false;
  }


  onSessionFailed(error: any): void {

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'Could not log you into SYSPRO', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }


  print() {

    if (this.selectedPrinter) {
      if (!this.isSaving) {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Loggin into Syspro...');
        this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onFirstsessionCreatedSuccesful(x), error => this.onSessionFailed(error));
        //this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => {

        //  this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
        //  this.sessionId = x.sysproSessionId;
        //  this.SysproUser = x;
        //  //this.SysproUser.defaultPrinter = this.selectedPrinter;
        //  this.auditObj.sysproOperator = this.SysproUser.sysproOperator;
        //  this.alertService.startLoadingMessage('Creating Invoice...');
        //  this.auditObj.actionType = "INVOICE";
        //  this.auditObj.actionInput = this.auditObj.applicationUser + " - " + this.salesOrder.salesOrderNumber;

        //  this.sysproService.PostInvoiceToSyspro(this.salesOrder.salesOrderNumber, x.sysproSessionId).subscribe(x => this.onSorticPOSTSuccesful(x), error => this.onSorticPOSTFailed(error));
        //  this.printModal.hide();
        //}, error => this.onSessionFailed(error));
      }
      
    }
    else {
      this.alertService.showStickyMessage('Error', 'Please select a printer first.', MessageSeverity.error, "");
     
    }
    
  }

  onFirstsessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
    this.sessionId = x.sysproSessionId;
    this.SysproUser = x;
    //this.SysproUser.defaultPrinter = this.selectedPrinter;
    this.auditObj.sysproOperator = this.SysproUser.sysproOperator;
    this.alertService.startLoadingMessage('Creating Invoice...');
    this.auditObj.actionType = "INVOICE";
    this.auditObj.actionInput = this.auditObj.applicationUser + " - " + this.salesOrder.salesOrderNumber;
    if (this.SysproUser.sysproSessionId) {
      this.auditObj.actionOutput = this.SysproUser.sysproSessionId;
      this.postAudit();

      //Check if ITS needs to be updated
      if (this.salesOrder.branch_code == "CT") {
        this.lookup = new InvoiceTrackingLookup();
        this.lookup.salesOrder = this.salesOrder.salesOrderNumber;
        this.lookup.invoice = this.salesOrder.salesOrderNumber;
        this.lookup.creditNote = "";
        this.lookup.purchaseOrder = (this.purchaseOrders && this.purchaseOrders.length >= 2 ? this.purchaseOrders[1].poNumber : "");
        this.lookup.poCount = (this.purchaseOrders && this.purchaseOrders.length >= 2 ? this.purchaseOrders.length - 1 : 0);
        this.lookup.pods = false;
        this.lookup.operatorCode = x.sysproOperator;
        this.lookup.customerCode = this.salesOrder.customer;
        this.lookup.customerName = this.salesOrder.customerName;
        this.lookup.shippingInstructions = this.salesOrder.shippingInstrs;
        this.lookup.company = (this.application == "MaxArcusOnline" ? "Max Arcus" : "Fowkes Bros");
        this.lookup.branchCode = this.salesOrder.branch_code;

        this.sysproService.addInvoiceTrackingLookup(this.lookup).subscribe(x => { }, error => { });
      }

      //Check if payment reference comment should be added
      if (this.salesOrder.terms == "04") {

        //Make sure comment has not already been added
        let hasBeenAdded = this.rows.filter(x => x.comment == "PLEASE USE YOUR INVOICE NUMBER AS REFERENCE");
        if (hasBeenAdded && hasBeenAdded.length >= 1) {

        }
        else {

          const lineNumber = this.rows.length + 1;

          let isPreviousLineAComment = (this.rows[this.rows.length - 1].lineType == "6") ? "Y" : "N";
          this.sysproService.InsertSalesOrderPaymentComments(this.salesOrder.salesOrderNumber, lineNumber, "auto", 0,
            isPreviousLineAComment, true)
            .subscribe(x => { }, error => { });
        }       
      }

      this.sysproService.PostInvoiceToSyspro(this.salesOrder.salesOrderNumber, x.sysproSessionId).subscribe(x => this.onSorticPOSTSuccesful(x), error => this.onSorticPOSTFailed(error));
      this.printModal.hide();
    }
  }

  validatePost(): boolean {
    let error = "";
    error = validation.validateOrderStatus(this.salesOrder.orderStatus, "9", error);
   // error = validation.validateCustomerOnHoldOrOverCredit(this.customer.customerOnHold, this.customer.creditLimit, this.customer.currentBalance1, error);
    if (error == "") {
     //error = validation.validateCustomerPO(this.cart.customerPoNumber, error);
      //error = validation.validateSalesPerson(this.cart.salesperson, error);
      //error = validation.validateShippingInstructions(this.cart.shippingInstrs, error);
      if (this.placePurchaseOrder) {

        for (var po of this.purchaseOrders) {
          if (po.status != "*" && !po.printed && po.poNumber != this.selectUndefinedOptionValue) {
            //make sure po has been printed
            error = validation.validatePurchaseOrderPrinted(po.purchaseOrder, error);
            if (error.length > 1) {
              let poError = "Remember to send Supplier PO for: " + error;
              this.isSaving = false;
              this.alertService.stopLoadingMessage();
              this.alertService.showMessage('Error', poError, MessageSeverity.error);
              return false;
            }

            console.log("purchaseOrder", po);
          }
        }
      }
    }

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }


  onSorticPOSTFailed(error: any): void {
    this.isSaving = false;

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'The below errors occured whilst placing Invoice:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //Check if invoice actually created:

    this.sysproService.getInvoiceHeader(this.salesOrderNumber, this.salesOrderNumber).subscribe(x => this.onInvoiceHeaderLoadSuccessful(x), error => this.onInvoiceLoadFailed(error));

  }
    onInvoiceLoadFailed(error: any): void {

    
    }

  onInvoiceHeaderLoadSuccessful(x: InvoiceHeader): void {
    this.alertService.showStickyMessage('Note', 'The invoice has been created in SYSPRO, do not post again the system will try to continue with the process', MessageSeverity.info);

    this.onSorticPOSTSuccesful(x);
  }

  onSorticPOSTSuccesful(x: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    let invoiceHeader = x[0];
    if (invoiceHeader)
    {
      this.alertService.showMessage('Success', `Created Invoice`, MessageSeverity.success);

      this.auditObj.actionOutput = "Created Invoice: " + invoiceHeader.invoiceNumber;
      this.postAudit();

      this.auditObj.referenceType = "INVOICE";
      this.auditObj.referenceNumber = invoiceHeader.invoiceNumber;

      sessionStorage.removeItem(localStorageActions.InvoiceNumber);
      sessionStorage.setItem(localStorageActions.InvoiceNumber, invoiceHeader.invoiceNumber);
      let user = this.accountService.currentUser;
      user.defaultPrinter = this.selectedPrinter;
      console.log("PRINT" + invoiceHeader.invoiceNumber, user);


      this.printerService.PrintInvoice(invoiceHeader.invoiceNumber, true, user).subscribe(x => this.onPrintSuccesful(x), error => this.onSorticPOSTFailed(error));


      if (!this.placePurchaseOrder) {

        this.isNavigating = true;
        this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.id, invoice: invoiceHeader.invoiceNumber } });

      }
      else {
        //For each related purchase order post GRN
        this.sysproService.GetSysproSessionId(this.accountService.currentUser, true).subscribe(x => {
         
          this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
          this.sessionId = x.sysproSessionId;
          this.SysproUser = x;
          this.poCount = 0;
          this.poPostedCount = 0;
          this.alertService.startLoadingMessage('Posting GRNs...');
          this.auditObj.referenceType = "GRN";
          this.auditObj.referenceNumber = invoiceHeader.invoiceNumber;
          for (const po of this.purchaseOrders.filter(x => x.poNumber != this.selectUndefinedOptionValue)) {
            this.auditObj.actionInput = "PO: " + po.poNumber + "Status: " + po.status;
            if (po.status != "*" && po.status != "9") {
              this.poCount++;
              console.log("PO", po);
              const postObject = {
                "user": this.SysproUser, "header": invoiceHeader, "poResult": po
              };

              console.log("postObject", postObject);
              this.sysproService.PostGRNToSyspro(postObject).subscribe(x => this.onPortorPOSTSuccesful(x), error => this.onSorticPOSTFailed(error));

              this.postAudit();
            }
          }
        }, error => this.onSessionFailed(error));
      }
    }
    else {
      console.log("POST RESPONSE", x);
      this.isSaving = false;
      this.alertService.showStickyMessage('Failed', 'Creating Invoice Failed ', MessageSeverity.error);
      this.alertService.showStickyMessage(x.message, null, MessageSeverity.error);
      this.loadData();
    }
  }

  onPrintSuccesful(x: CoreAudit): void {
    console.log("PRINTED", x);

    this.isSaving = false;
    this.alertService.showMessage('Success', `Printed Invoice`, MessageSeverity.success);
  }

  onPortorPOSTSuccesful(x: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.poPostedCount ++;
    console.log("GRN", x);
    var header = x[0];
    if (header) {
      if (this.poCount == this.poPostedCount) {
        this.alertService.showMessage('Success', `Created GRN`, MessageSeverity.success);
        this.isNavigating = true;
        this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.id, invoice: x.salesOrder } });
      }
    }
    else {

      this.isSaving = false;
      this.alertService.showStickyMessage('Failed', 'Creating GRN Failed ', MessageSeverity.error);
      this.alertService.showStickyMessage(x.message, null, MessageSeverity.error);
    }
    
  }

  onStockLoadSuccessful(inventory: any[]) {

    this.inventoryList = inventory[0];
  }

  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.stockDescription;
      this.stockCode = this.stockCodedetail.stockCode;

      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.addSheetImage = row.addSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  onStockCodeLoadSuccessful(row: Inventory): void {

    this.stockCodedetail = new Inventory();
    this.stockCodedetail = row[0];
    this.hasDataSheet = false;
    this.hasSpecials = false;

    if (this.stockCodedetail.dataSheetImage) {
      this.hasDataSheet = true;
    }
    if (this.stockCodedetail.addSheetImage) {
      this.hasAddDataSheet = true;
      this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
    }
    if (this.stockCodedetail.specialsImage) {
      this.hasSpecials = true;
    }
    if (!!this.stockCodedetail) {
      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }
    }
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }

  openSpecials(specialsImg: string) {
    if (!!specialsImg && specialsImg != "") {
      window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
    }
  }

  showWHDetail(row: any, event) {
    if (row) {
      if (row.lineType != "7") {
        this.stockDescription = row.stockCode + ": " + row.stockDescription;
        this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      }
    }
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }


  onEmailLoadSuccessful() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasSpecials = false;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___"), this.branchCode, this.application).subscribe(x => this.loadInventoryEmailModal(description, stockCode));
  }

  private loadInventoryEmailModal(description: string, stockCode: string) {

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);
    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.onSpecial && this.stockCodedetail.specialsImage.length >= 1);

    this.emailModal.show();
    this.emailObject.RecepientName = this.salesOrder.contact;
    this.emailObject.RecepientEmail = this.salesOrder.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  viewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailPDF() {

    console.log(this.emailObject);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.emailObject.AddDoc1 = (this.attachDataSheets ? this.emailObject.AddDoc1 : "");
    this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");

    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var stockCode = this.emailObject.Attachment.replace('/', '_').replace('+', '_');

    this.src = url;
    // window.open(this.src).print();
    // const blobUrl = window.URL.createObjectURL((test));
    //const iframeDoc = document.getElementsByTagName("iframe")[0].contentWindow;
    // iframeDoc.src = url;



    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = stockCode + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  //private saveSuccessHelper(res?: any) {

  //  this.cartSaved = true;

  //  this.alertService.stopLoadingMessage();

  //  if (res) {
  //    console.log(res);
  //  }

  //  if (this.isNavigating) {
  //    this.router.navigate(['../orders'], { fragment: 'cart', queryParams: { customer: this.id, cartId: this.cartId } });
  //  }

  //  if (this.isUpdating) {

  //    this.isUpdating = false;
  //    this.alertService.showMessage('Success', this.quoteId + ` Updated successfully`, MessageSeverity.success);
  //  }
  //  else {

  //    this.isSaving = false;
  //    this.alertService.showMessage('Success', this.cartId + ` Created successfully`, MessageSeverity.success);
  //  }

  //}

  //private saveFailedHelper(error: any) {
  //  this.isSaving = false;
  //  this.alertService.stopLoadingMessage();
  //  this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
  //  this.alertService.showStickyMessage(error, null, MessageSeverity.error);

  //  //if (this.changesFailedCallback) {
  //  //  this.changesFailedCallback();
  //  //}
  //}

  //private save(location?) {
  //  this.isSaving = true;
  //  this.alertService.startLoadingMessage('Saving changes...');

    
  //  for (var product of this.rowsCache) {
  //  }


  //}

  //viewInventory() {

  //}

  calcOrderQty(row: any, event) {
    if (row.lineType != "6") {
      var val = event.target.value;
      row.orderQty = val;

      let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
      row.lineTotal = total;
    }
  }


  calcTotal(row: any) {
    if (row.lineType != "6") {
      let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
      row.lineTotal = total;
        //Calculations.precise_round(total, 2);
    }
  }

  setDelete(row: SalesOrderDetail) {

    this.CancelReason = "07";
    this.lineToCancel = row.salesOrderLineNumber;
    this.auditObj.actionType = "DELETE LINE";
    this.auditObj.actionInput = this.CancelReason + " - " + this.lineToCancel.toString();

    var headers = null;

    //Check if this is a NS and has a po number attached
    if (row.lineType == "7") {
      //Get current POs
      headers = this.purchaseOrders.filter(p => Utilities.searchArray(row.boSupplierInvoice,
        false, p.purchaseOrder));

    }

    if (headers && headers.length >= 1 && headers[0].status != "*") {
      this.alertService.showStickyMessage('Error', "You cannot delete this item because there is an active purchase order. Please ask the finance team to cancel PO "
        + headers[0].purchaseOrder + " first in order to remove this item.", MessageSeverity.error);
    }
    else {
     
      let detail = this.rows.find(x => x.salesOrderLineNumber == this.lineToCancel);
      detail.boLineInfo = this.CancelReason;

      this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
        this.sessionId = x.sysproSessionId;
        this.SysproUser = x;
        this.auditObj.actionedBy = x.userName;
        this.auditObj.sysproOperator = x.sysproOperator;
        const postObject = {
          "user": this.SysproUser, "header": this.salesOrder, "detail": detail
        };
        this.postAudit();
        this.sysproService.UpdateSalesOrderSyspro(postObject).subscribe(x => this.onSalesOrderUpdatedSuccessful(x), error => this.onDataLoadFailed(error));

      }, error => this.onSessionFailed(error));
    }

    
  }


  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }


  get canReleaseSalesOrders() {
    return this.accountService.userHasPermission(Permission.releaseSalesOrdersPermission);
  }


  login() {
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage('', 'Attempting Supervisor login...');

    this.auditObj.actionType = "LOGIN SUPERVISOR";
    this.auditObj.actionInput = this.auditObj.applicationUser + " - " + this.userLogin.userName;
    this.auditObj.actionOutput = "LOGGED IN Supervisor";

    this.authService.loginSupervisor(this.userLogin.userName, this.userLogin.password)
      .subscribe(
        user => {
          setTimeout(() => {
            if (user.jobTitle === "Supervisor") {

              this.alertService.stopLoadingMessage();
              this.alertService.showMessage('Supervisor Login', `Granted ${user.userName} override`, MessageSeverity.success);
              this.loginModal.hide();
              this.supervisor = user;
              this.auditObj.actionedBy = this.supervisor.userName;
              this.auditObj.actionType = "RELEASE ORDER";
              this.auditObj.actionInput = this.salesOrder.orderStatus + " - " + this.OrderStatus;
              this.authModal.show();
             //this.sysproService.UpdateSalesOrderStatus(this.salesOrder.salesOrderNumber, this.OrderStatus, "none", this.sessionId).subscribe(x => this.onSalesOrderHeaderLoadSuccessful(x, true), error => this.onDataLoadFailed(error));

              console.log("LOGGED IN Supervisor", user);
            }
            else {
              this.auditObj.actionOutput = "Error: Supervisor not authenticated" + Error;
              this.alertService.stopLoadingMessage();
              this.alertService.showStickyMessage('Unable to login', 'Supervisor not authenticated', MessageSeverity.error, Error);
            }
          }, 500);
        },
        error => {

          this.auditObj.actionOutput = "Error: Unable to login" + Utilities.getResponseBody(error);
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Unable to login', 'An error occured whilst logging in, please try again later.\nError: ' + Utilities.getResponseBody(error), MessageSeverity.error, error);

        });

    this.postAudit();
  }

  private postAudit() {
        this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
    }
}
