
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class ReportEndpoint extends EndpointFactory {

  private readonly _branchesUrl: string = '/api/Branch/GetAllBranches';
  private readonly _customersUrl: string = '/api/Customers/GetCustomerList';
  private readonly _reportsUrl: string = '/api/reports/';

  get reportsUrl() { return this.configurations.baseUrl + this._reportsUrl; }

  
  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getFileStreamEndpoint<T>(quoteNumber?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetFileStream/${quoteNumber}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamEndpoint(quoteNumber));
      }));
  }

  getQuotePDFEndpoint<T>(quoteNumber?: string, application?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetQuotePDF/${quoteNumber}/${application}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuotePDFEndpoint(quoteNumber, application));
      }));
  }

  getInventoryPDFEndpoint<T>(stockCode?: string, branchCode?: string, application?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetInventoryPDF/${encodeURIComponent(stockCode)}/${branchCode}/${application}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInventoryPDFEndpoint(stockCode, branchCode, application));
      }));
  }


  getStatementPDFEndpoint<T>(customer?: string, application?: string, fromDate?: string, toDate?: string, uniqueCode?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetStatementPDF/${customer}/${application}/${fromDate}/${toDate}/${uniqueCode}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStatementPDFEndpoint(customer, application, fromDate, toDate, uniqueCode));
      }));
  }

  getCataloguePDFEndpoint<T>(branchCode?: string, application?: string, reportTitle?: string, reportType?: string, filterValue?: string, includeIndex?: boolean, extraFilter?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}getCataloguePDF/${branchCode}/${application}/${encodeURIComponent(reportTitle)}/${reportType}/${encodeURIComponent(filterValue)}/${includeIndex}/${encodeURIComponent(extraFilter)}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCataloguePDFEndpoint(branchCode, application, reportTitle, reportType, filterValue, includeIndex, extraFilter));
      }));
  }

  getFileStreamCatalogueEndpoint<T>(reportName?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetFileStreamCatalogue/${encodeURIComponent(reportName)}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamCatalogueEndpoint(reportName));
      }));
  }

  getPurchaseOrderPDFEndpoint<T>(PoNumber: string, internalRef: boolean, application?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetPurchaseOrderPDF/${PoNumber}/${internalRef}/${application}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseOrderPDFEndpoint(PoNumber, internalRef, application));
      }));
  }


  getFileStreamPurchaseOrderEndpoint<T>(PoNumber?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetFileStreamPurchaseOrder/${PoNumber}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamPurchaseOrderEndpoint(PoNumber));
      }));
  }

  getFileStreamInventoryEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetFileStreamInventory/${encodeURIComponent(stockCode)}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamInventoryEndpoint(stockCode));
      }));
  }


  getFileStreamStatementEndpoint<T>(OTP?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetFileStreamStatement/${encodeURIComponent(OTP)}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStreamStatementEndpoint(OTP));
      }));
  }


  getStatementInvoicePDFEndpoint<T>(customer?: string, application?: string, salesOrder?: string, invoice?: string, uniqueCode?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetStatementInvoice/${customer}/${application}/${salesOrder}/${invoice}/${uniqueCode}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStatementInvoicePDFEndpoint(customer, application, salesOrder, invoice, uniqueCode));
      }));
  }

  getStatementCreditPDFEndpoint<T>(customer?: string, application?: string, salesOrder?: string, invoice?: string, uniqueCode?: string): Observable<T> {
    const endpointUrl = `${this.reportsUrl}GetStatementCredit/${customer}/${application}/${salesOrder}/${invoice}/${uniqueCode}`;

    return this.http.post<T>(endpointUrl, { options: { headers: this.getRequestHeaders() }, responseType: 'blob' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStatementInvoicePDFEndpoint(customer, application, salesOrder, invoice, uniqueCode));
      }));
  }
  
}
