
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { CoreCustomerPrices } from '../../../models/core-customer-prices.model';
import { CoreCustomerRule } from '../../../models/core-customer-rule.model';
import { CoreCustomer } from '../../../models/core-customer.model';
import { Inventory } from '../../../models/inventory.model';
import { Permission } from '../../../models/permission.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities'; 
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { CMCategories } from '../../../models/cms-category.model';
import { WHAvailQTY } from '../../../models/inventory-wh.model';
import { SimpleTree } from '../../../models/simple-tree.model';
import { Supplier } from '../../../models/supplier.model';
import { Calculations } from '../../../services/calculations.service';
import { forEach } from '@angular/router/src/utils/collection';




@Component({
  selector: 'core-customer-admin',
  templateUrl: './core-customer-admin.component.html',
  styleUrls: ['./core-customer-admin.component.scss']
})
export class CoreCustomerAdminComponent implements OnInit, AfterViewInit {


  columns: any[] = [];

  customerRows: ArCustomer[] = [];
  customerRowsCache: ArCustomer[] = [];


  loadingIndicator: boolean;

  //Customer Search
  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  searchCustomerType: string = "";
  searchTextCustomer: string = "";

  @ViewChild('searchResultSelector')
  private searchResultSelector;

  @ViewChild('customerActionsTemplate')
  customerActionsTemplate: TemplateRef<any>;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;


  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();

  id: string;


  coreCustomer: CoreCustomer = new CoreCustomer();  
  coreCustomers: CoreCustomer[];
 
    searchOnValue: string;



  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
  
    this.searchCustomerType = "customer";

    this.setGrid();

    this.loadData();
  }

  setGrid() {    
    this.columns = [
      { prop: 'customer', name: 'Customer', width: 90, cellClass: "center" },
      { prop: 'customerName', name: 'Name', width: 220, cellClass: "left" },
      { prop: 'termsCode', name: 'Terms Code', width: 110, cellClass: "left" },
      { prop: 'termsDescription', name: 'Terms Description', width: 110, cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: 50, cellClass: "center" },
      { prop: 'salesperson', name: 'Sales Person', width: 50, cellClass: "left" },
      { prop: 'salespersonName', name: 'Name', width: 150, cellClass: "left" },
      { prop: 'customerOnHold', name: 'On Hold', width: 50, cellClass: "center" },
      { prop: 'creditStatus', name: 'Update', cellTemplate: this.actionsTemplate , width: 100, cellClass: "center"}
    ];
  }


  ngAfterViewInit() {
    this.setGrid();
  }


  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getCustomers("NOPRICING").subscribe(x => this.onAllCustomersLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

  specificCustomerSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onAllCustomersLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  allCustomerSearch() {
    if (this.searchCustomerType && this.searchTextCustomer != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getCustomers("NOPRICING").subscribe(
        x => this.onAllSearchCustomersLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onAllSearchCustomersLoadSuccessful(customers: ArCustomer[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (customers && customers.length >= 1) {

      const details = customers;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.customerRowsCache = [...details];
      // this.resultRowsCache = x;
      this.customerRows = [...this.customerRowsCache];
    }
  

    this.customerRows = this.customerRowsCache.filter(r => r.customer.toLowerCase() == this.searchTextCustomer.toLowerCase());

    if (this.searchCustomerType == "customerName") {
      this.customerRows = this.customerRowsCache.filter(r => Utilities.searchArray(this.searchTextCustomer, false, r.customerName));
    }
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.searchOnValue = value;
      this.customerRows = this.customerRowsCache.filter(r => Utilities.searchArray(value, false, r.customer, r.customerName));

    }
    else {
      this.searchOnValue = "";
      this.customerRows = this.customerRowsCache;
    }
  }

  onAllCustomersLoadSuccessful(customers: ArCustomer[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    var x = customers.filter(c => c.creditStatus != 'NOT FOUND');

    if (x && x.length >= 1) {

      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.customerRowsCache = [...details];
      this.customerRows = [...this.customerRowsCache];

    }
           
  }


  clearFilters() {
    this.searchOnValue = "";
    this.customerRows = this.customerRowsCache;

  }

  get application(): string {
    return this.configurations.applicationName;
  }

  resetAll() {
    for(var customer of this.customerRows) {
      this.resetPricing(customer);
    }
  }

  resetPricing(row: ArCustomer) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    //var customerId = Number(row.creditStatus); 
    this.sysproService.resetCustomerPricing(this.application, row.customer).subscribe(x => this.onUpdateSuccessfull(x), error => this.onDataLoadFailed(error));
    //this.sysproService.getPricingCustomer(row.customer, this.application).subscribe(x => this.onCoreCustomerLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }
    onUpdateSuccessfull(x: CoreCustomer): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;


      this.alertService.showStickyMessage('Success', `Updated Customer ` + x.customerCode,
        MessageSeverity.success);
    }

  onCoreCustomerLoadSuccessful(x: CoreCustomer): void {

    this.coreCustomer = x[0];
    this.sysproService.getAllPricingCustomerPrices(this.coreCustomer.id).subscribe(x => this.onPricesLoadSuccessful(x, true), error => this.onDataLoadFailed(error));
    //if (x && x.length == 1) {
    //  if (x[0].customerCode != "") {

       
    //  }
    //}
  }
    onPricesLoadSuccessful(x: CoreCustomerPrices[], arg1: boolean): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;


      this.alertService.showStickyMessage('Success', `Updated Customer ` + x[0].customerCode,
        MessageSeverity.success);
    }


  onDefaultsPostedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to update defaults.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

  }

  onDefaultsPostedSuccesful(x: CoreCustomer): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;


    this.alertService.showStickyMessage('Success', `Added Defaults.`,
      MessageSeverity.success);


    this.sysproService.getCustomers("NOPRICING").subscribe(x => this.onAllCustomersLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

 
  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }



  get branchCode(): string {
    return this.configurations.branchCode;
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }




}
