
export class CartHeader {
  constructor(cartNumber?: string, userid?: string, customer?: string, branchcode?: string, customerName?: string,
    telephone?: string, addTelephone?: string, fax?: string, email?: string, companyTaxNumber?: string, taxStatus?: string, taxExemptNumber?: string,
    contact?: string, soldToAddr1?: string, soldToAddr2?: string, soldToAddr3?: string, soldToAddr4?: string, soldToAddr5?: string, soldPostalCode?: string,
    shipToAddr1?: string, shipToAddr2?: string, shipToAddr3?: string, shipToAddr4?: string, shipToAddr5?: string, shipPostalCode?: string,
    area?: string, salesperson?: string, shipDate?: Date, orderDate?: Date, createDate?: Date, customerPoNumber?: string, shippingInstrs?: string,
    specialInstrs?: string, buyersName?: string, buyersEmail?: string, hasNettPrices?: boolean, quotedFrom?: string, specialPricesEnabled?: boolean) {

    this.cartNumber = cartNumber;
    this.userid = userid;
    this.customer = customer;
    this.branch_code = branchcode;
    this.customerName = customerName;
    this.telephone = telephone;
    this.addTelephone = addTelephone;
    this.email = email;
    this.fax = fax;
    this.companyTaxNumber = companyTaxNumber;
    this.area = area;
    this.salesperson = salesperson;
    this.contact = contact;
    this.taxExemptNumber = taxExemptNumber;
    this.taxStatus = taxStatus;
    this.soldToAddr1 = soldToAddr1;
    this.soldToAddr2 = soldToAddr2;
    this.soldToAddr3 = soldToAddr3;
    this.soldToAddr4 = soldToAddr4;
    this.soldToAddr5 = soldToAddr5;
    this.soldPostalCode = soldPostalCode;
    this.shipToAddr1 = shipToAddr1;
    this.shipToAddr2 = shipToAddr2;
    this.shipToAddr3 = shipToAddr3;
    this.shipToAddr4 = shipToAddr4;
    this.shipToAddr5 = shipToAddr5;
    this.shipPostalCode = shipPostalCode;
    this.shipDate = shipDate;
    this.orderdate = orderDate;
    this.createdate = createDate;
    this.alternateKey = "";
    this.customerPoNumber = customerPoNumber;
    this.shippingInstrs = shippingInstrs;
    this.specialInstrs = specialInstrs;
    this.buyersName = buyersName;
    this.buyersEmail = buyersEmail;
    this.hasNettPrices = hasNettPrices;
    this.quotedFrom = quotedFrom;
    this.specialPricesEnabled = specialPricesEnabled;
  }

  public cartNumber: string;
  public userid: string;
  public branch_code: string;
  public customer: string;
  public customerName: string;
  public telephone: string;
  public addTelephone: string;
  public fax: string;
  public email: string;
  public companyTaxNumber: string;
  public taxStatus: string;
  public taxExemptNumber: string;
  public soldToAddr1: string;
  public soldToAddr2: string;
  public soldToAddr3: string;
  public soldToAddr4: string;
  public soldToAddr5: string;
  public soldPostalCode: string;
  public shipToAddr1: string;
  public shipToAddr2: string;
  public shipToAddr3: string;
  public shipToAddr4: string;
  public shipToAddr5: string;
  public shipPostalCode: string;
  public shippingInstrs: string;
  public specialInstrs: string;
  public area: string;
  public salesperson: string;
  public contact: string;
  public telex: string;
  public customerPoNumber: string;
  public alternateKey: string;
  public shipDate: Date;
  public orderdate: Date;
  public ordertype: string;
  public orderdiscountpercentage: number;
  public createdate: Date;
  public hasNettPrices: boolean;
  public buyersName: string;
  public buyersEmail: string;
  public quotedFrom: string;
  public specialPricesEnabled: boolean;
}

