import { Component, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Brand } from '../../../models/brand.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { Establishment } from '../../../models/company-establishment.model';
import { Branch } from '../../../models/branch.model';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'branch-settings',
  templateUrl: './settings.branch.component.html',
  styleUrls: ['./settings.branch.component.scss'],
  animations: [fadeInOut]
})
export class BranchSettingsComponent {

  isSaving: boolean;
  loadingIndicator: boolean;

  columns: any[] = [];
  branchesCache: Establishment[] = [];
  establishmentsCache: Establishment[] = [];
  establishments: Establishment[] = [];
  establishmentDetail: Establishment;

  @ViewChild('establishmentModal')
  establishmentModal: ModalDirective;

  emptyDetails: boolean;

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('companiesList')
  private companiesList;

  @ViewChild('companiesListSelector')
  private companiesListSelector;


  @ViewChild('establishmentTypeList')
  private establishmentTypeList;

  @ViewChild('establishmentTypeListSelector')
  private establishmentTypeListSelector;


  @ViewChild('citiesList')
  private citiesList;

  @ViewChild('citiesListSelector')
  private citiesListSelector;

  selectedCompany: string;


  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();

  searchTerm: string = "";

  constructor(private http: HttpClient, private alertService: AlertService, private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadGrids();
    this.loadData();

  }

  ngAfterViewInit() {

    this.loadGrids();
  }


  private loadGrids() {
    this.columns = [
      { prop: 'company', name: 'Company', width: 150},
      { prop: 'establishment', name: 'Establishment', width: 160 },
      { prop: 'establishmentType', name: 'Type', width: 80 },
      { prop: 'companyRegistration', name: 'Registration Number', width: 150 },
      { prop: 'taxNumber', name: 'Tax Number', width: 100 }, 
      { prop: 'cityDiallingCode', name: 'Code', width: 80 },
      { prop: 'tel', name: 'Tel Number', width: 100 },
      { prop: 'fax', name: 'Fax Number', width: 100 }
    ];

    this.columns.push({ name: '', width: 200, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getAllEstablishmentDetails().subscribe(x => this.onEstablishmentsLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onEstablishmentsLoadSuccessful(branches: any[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (branches[0]) {
      this.branchesCache = [...branches[0]];
      this.establishmentsCache = this.branchesCache.filter(x => x.establishmentType != "Other");

      this.establishmentsCache.forEach((branch, index, establishments) => {
        (<any>branch).index = index + 1;
        this.sysproService.getEstablishmentDetails(branch.companyCode, branch.branchCode).subscribe(
          x => {
            var addDetails = x;
            branch.cityDiallingCode = "(0"+ addDetails.cityDiallingCode + ")";

          }, error => this.onDataLoadFailed(error));
      });

      this.establishments = [...this.establishmentsCache];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }

  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.establishments = this.establishmentsCache.filter(r => Utilities.searchArray(value, false, r.companyCode, r.company, r.establishment, r.branchCode, r.tel, r.fax));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.establishments = [...this.establishmentsCache];
    }
  }

  deleteRow(row: Establishment) {

    //Check that brand doesnt have any stock codes assigned
   // this.sysproService.getStockCodesForBrand(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));

  }
  //onValidationSuccesful(x: CMStockCode[], row: Brand): void {
  //  console.log(x);
  //  if (x && x.length >= 1) {
  //    this.alertService.showMessage('Warning', `You cannot delete a brand with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
  //  }
  //  else {
  //    this.isSaving = true;
  //    this.alertService.startLoadingMessage('Saving changes...');
  //    this.sysproService.deleteBrand(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  //  }
  //}

  editEstablishment(row: Establishment) {

    this.establishmentDetail = row;

    if (!this.establishmentModal) {
      setTimeout(() => {
        if (this.establishmentModal) {
          this.establishmentModal.show();
        }
      });
    }
    else {
      this.establishmentModal.show();
    }

  }


  establishmentModalHide() {
    this.establishmentDetail = new Establishment();
    this.establishmentModal.hide();
  }

  newEstablishment() {
    this.establishmentDetail = new Establishment();

    let defaults = this.establishments[0];
    this.establishmentDetail.companyRegistration = defaults.companyRegistration;
    this.establishmentDetail.email = defaults.email;
    this.establishmentDetail.country = defaults.country;
    this.establishmentDetail.enabled = true;
    this.establishmentDetail.hoursWeekdays = "Mon - Thur: ";
    this.establishmentDetail.hoursSaturdays = "Friday: ";
    this.establishmentDetail.hoursSundays = "Saturday: ";
    this.establishmentDetail.company = "";
    this.establishmentDetail.city = "";
    //this.establishmentDetail.cityDiallingCode = defaults.cityDiallingCode;

    if (!this.establishmentModal) {
      setTimeout(() => {
        if (this.establishmentModal) {
          this.establishmentModal.show();
        }
      });
    }
    else {
      this.establishmentModal.show();
    }

  }

  saveSegment(row: Establishment) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.fowkesService.editEstablishment(row, row.establishmentId).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  saveEstablishment() {

    //Validate save
    if (this.establishmentDetail) {
      if (this.establishmentDetail.establishment) {       
        
        if (!this.establishmentDetail.branchCode) {
          this.alertService.showMessage('Warning', `Make sure to enter a branch code`, MessageSeverity.warn);
        }
        else {

          this.establishmentDetail.addressLine2 = (this.establishmentDetail.addressLine2 ? this.establishmentDetail.addressLine2 : "");
          this.establishmentDetail.addressLine3 = (this.establishmentDetail.addressLine3 ? this.establishmentDetail.addressLine3 : "");
          this.establishmentDetail.addressLine4 = (this.establishmentDetail.addressLine4 ? this.establishmentDetail.addressLine4 : "");
          this.establishmentDetail.addressLine5 = (this.establishmentDetail.addressLine5 ? this.establishmentDetail.addressLine5 : "");

          this.establishmentDetail.postalAddress2 = (this.establishmentDetail.postalAddress2 ? this.establishmentDetail.postalAddress2 : "");
          this.establishmentDetail.postalAddress3 = (this.establishmentDetail.postalAddress3 ? this.establishmentDetail.postalAddress3 : "");
          this.establishmentDetail.postalAddress4 = (this.establishmentDetail.postalAddress4 ? this.establishmentDetail.postalAddress4 : "");
          this.establishmentDetail.postalAddress5 = (this.establishmentDetail.postalAddress5 ? this.establishmentDetail.postalAddress5 : "");


          this.establishmentDetail.comments = (this.establishmentDetail.comments ? this.establishmentDetail.comments : "");


          this.establishmentDetail.googleBusinessProfile = (this.establishmentDetail.googleBusinessProfile ? this.establishmentDetail.googleBusinessProfile : "");
          this.establishmentDetail.googleHyperlink = (this.establishmentDetail.googleHyperlink ? this.establishmentDetail.googleHyperlink : "");

          this.establishmentDetail.companyCode = (this.establishmentDetail.company == "Fowkes Bros (PTY) Ltd" ? "FB" : "MA");
          this.establishmentDetail.establishmentType = "Branch";

          if (this.establishmentDetail.establishmentId) {
            this.fowkesService.editEstablishment(this.establishmentDetail, this.establishmentDetail.establishmentId).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
          }
          else {
            if (this.establishmentsCache.filter(x => x.company == this.establishmentDetail.company && x.establishment == this.establishmentDetail.establishment).length >= 1) {
              this.alertService.showMessage('Warning', `This company / establishment already exists`, MessageSeverity.warn);
            }
            else {

              this.fowkesService.newEstablishment(this.establishmentDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
            }

          }
        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter an establishment name`, MessageSeverity.warn);
      }
    }
  }
    onSaveSuccesful(): void {

      this.establishmentModal.hide(); 
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.loadData();
    }


  ExportResults() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Company", "Establishment", "Comments", "BranchCode", "Tel", "Fax", "Email", "CompanyRegistration", "TaxNumber", "AddressLine1", "AddressLine2", "AddressLine3", "AddressLine4", "AddressLine5"
        , "AreaCode", "PostalAddress1", "PostalAddress2", "PostalAddress3", "PostalAddress4", "PostalAddress5", "PostalCode"
        , "Enabled", "Country", "City", "Hours1", "Hours2", "Hours3"]
    };

    var filename = "Establishments";
    var selectRows = [...this.establishments];

    var exportRows = [];
    for (var row of selectRows) {
      //let date = new Date(selectedHeader.).toISOString().split('T')[0];
      exportRows.push({
        Company: row.company, Establishment: row.establishment, Comments: row.comments, BranchCode: row.branchCode, Tel: row.tel, Fax: row.fax,
        Email: row.email, CompanyRegistration: row.companyRegistration, TaxNumber: row.taxNumber, AddressLine1: row.addressLine1, AddressLine2: row.addressLine2
        , AddressLine3: row.addressLine3, AddressLine4: row.addressLine4, AddressLine5: row.addressLine5, AreaCode: row.areaCode, PostalAddress1: row.postalAddress1
        , PostalAddress2: row.postalAddress2, PostalAddress3: row.postalAddress3, PostalAddress4: row.postalAddress4, PostalCode: row.postalCode
        , Enabled: row.enabled, Country: row.country, City: row.city, Hours1: row.hoursWeekdays, Hours2: row.hoursSaturdays, Hours3: row.hoursSundays
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

}
