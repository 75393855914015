
export class ArNarration {
  constructor(customer?: string, invoice?: string, invoiceType?: string, noteType?: string, line?: number,
    notation?: string, existingNote?: string) {

    this.customer = customer;
    this.invoice = invoice;
    this.invoiceType = invoiceType;
    this.noteType = noteType;
    this.notation = notation;
    this.line = line;
    this.existingNote = existingNote;
    
  }
  /*[Key]*/
  public customer: string;
  public invoice: string;
  public invoiceType: string;
  public noteType: string;
  public line: number;
  public notation: string;
  public timestamp: number[];
  public existingNote: string;
}

