
export class TrackingAreas   {
  id: number;
  province: string | null;
  city: string | null;
  suburb: string | null;
  streetCode: string | null;
  poBoxCode: string | null;
  area: string | null;
  driversCageId: number | null;

}

