
export class DocumentManagmement {
  constructor( ) {

   
  }

  public id: number;
  public documentName: string;
  public description: string;
  public categoryName: string;
  public parentCategoryName: string;
  public value: string;
  public isLink: boolean;
  public enabled: boolean;
  public responsibility: string;
  public createdBy: string;
  public filePath: string;
}

