export class CustomerOTPReference {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(otpReference?: string, documentNumber?: string, dateFrom?: Date, dateTo?: Date, createDate?: Date, application?: string,
    customerCode?: string, createdBy?: string, sentTo?: string, status?: string) {

    this.otpReference = otpReference;
    this.documentNumber = documentNumber;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.createDate = createDate;
    this.application = application;
    this.customerCode = customerCode;
    this.createdBy = createdBy;
    this.sentTo = sentTo;
    this.status = status;
  }


  public otpReference: string;
  public documentNumber: string;
  public dateFrom: Date;
  public dateTo: Date;
  public createDate: Date;
  public application: string;
  public customerCode: string
  public createdBy: string;
  public sentTo: string;
  public status: string;
}
