import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { Brand } from '../../models/brand.model';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'brands',
  templateUrl: './products.brands.component.html',
  styleUrls: ['./products.brands.component.scss'],
  animations: [fadeInOut]
})
export class BrandsComponent {

  @ViewChild('brandModal')
  brandModal: ModalDirective;

  emptyDetails: boolean;

  rows: Brand[] = [];
  //urlString: string = "https://www.fowkes.co.za/FowkesOnline/FowkesDocuments/ContentManagementDocuments/BrandImages/";

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  rowsCache: Brand[];
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
  brandDetail: Brand = new Brand();

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getBrands().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }



  onDataLoadSuccessful(brands: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (brands[0]) {

      this.rowsCache = [...brands[0]];
      const updateRows = this.rowsCache.sort((obj1, obj2) => {
        if (obj1.brandImage > obj2.brandImage) {
          return 1;
        }

        if (obj1.brandImage < obj2.brandImage) {
          return -1;
        }

        return 0;
      });

      this.rows = [...updateRows];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }


  results() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Brand", "Display Name", "Description", "Image"]
    };

    const filename = "Brands";
    const exportRows = this.rows.map(row => ({
      Id: row.id,
      Brand: row.brandName,
      "Display Name": row.webDisplayName,
      Description: row.webDescription,
      Image: row.brandImage
    }));

    return new ngxCsv(exportRows, filename, options);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.brandName, r.brandImage));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  deleteRow(row: Brand) {
    //Check that brand doesnt have any stock codes assigned
    this.sysproService.getStockCodesForBrand(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));

  }
  onValidationSuccesful(x: CMStockCode[], row: Brand): void {
    console.log(x);
    if (x && x.length >= 1) {
      this.alertService.showMessage('Warning', `You cannot delete a brand with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
    }
    else {
      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.deleteBrand(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
    }
  }

  enableEditMethod(row: Brand, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;

    this.editBrand(row);
    console.log(i, row);
  }

  saveSegment(row: Brand) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    row.createdBy = this.accountService.currentUser.email;
    this.sysproService.editBrand(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  brandModalHide() {
    this.brandDetail = new Brand();
    this.brandModal.hide();
  }

  editBrand(row: Brand) {

    this.brandDetail = row;

    if (!this.brandModal) {
      setTimeout(() => {
        if (this.brandModal) {
          this.brandModal.show();
        }
      });
    }
    else {
      this.brandModal.show();
    }

    

  }


  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newformData.append('file', file);
   
    }
  }

  saveBrand() {

    //Validate save
    if (this.brandDetail) {
      if (this.brandDetail.brandName) {
       
        this.sysproService.editBrand(this.brandDetail, this.brandDetail.id).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a brand name`, MessageSeverity.warn);
      }
    }
  }

    newFilePostedSuccess(x: any) {

      console.log(x);
      this.brandDetail.brandImage = x.fileName;
      this.brandDetail.createdBy = this.accountService.currentUser.email;
      this.brandDetail.brandImageChanged = new Date();

      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.addBrand(this.brandDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
    }
    onSaveSuccesful(): void {

      this.brandModalHide(); 
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.loadData();
    }


  onFileChange(event, row: Brand) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.sysproService.postFileData(formData, "BRAND", row.brandName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
  
    }
  }
  filePostedSuccess(result: any, row: Brand) {

    console.log(result);
    row.brandImage = result.fileName;
    row.brandImageChanged = new Date();

    this.saveSegment(row);
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

}
