import { Component, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Brand } from '../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CMCategories } from '../../models/cms-category.model';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Inventory } from '../../models/inventory.model';
import { localStorageActions } from '../../models/enums';

@Component({
  selector: 'manage-datasheets',
  templateUrl: './products-manage-datasheets.component.html',
  styleUrls: ['./products-manage-datasheets.component.scss'],
  animations: [fadeInOut]
})
export class ManageDataSheetsComponent {

  @ViewChild('brandModal')
  brandModal: ModalDirective;

  emptyDetails: boolean;

  allcategories: CMCategories[] = [];
  categoryrows: CMCategories[] = [];
  categoryrowscache: CMCategories[] = [];
  //categoryurlString: string = "https://www.fowkes.co.za/FowkesOnline/FowkesDocuments/ContentManagementDocuments/CategoryImages/";

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;
  stockCodedetail: any;

  allstockCodes: CMStockCode[] = [];
  stockCoderows: CMStockCode[] = [];
  stockCoderowscache: CMStockCode[] = [];
  //stockCodeurlString: string = "https://www.fowkes.co.za/FowkesOnline/FowkesDocuments/ContentManagementDocuments/StockCodeImages/";

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  rowsCache: Brand[];
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
  brandDetail: Brand = new Brand();
  missingImageCount: number;
  columns: any[] = [];

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
  chkDt1: boolean;
  chkDt2: boolean;

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    //this.chkDt1 = true;
    //this.chkDt2 = true;
    this.SetDataGrid();
    this.loadData();

  }

  private SetDataGrid() {
    
    this.columns = [
      { prop: 'stockCode', name: 'StockCode', width: 120, cellClass: "left" },
      { prop: 'description', name: 'Description', width: 370, cellClass: "left" },
      { prop: 'categoryTree', name: 'Tree', width: 370, cellClass: "left" }
    ];

    this.columns.push({ name: 'CMS', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    //}
  }

  ngAfterViewInit() {

    this.SetDataGrid();

  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    //Scrape stock codes and Load Categories
    this.sysproService.getAllCMStockCodesMissingDatasheets().subscribe(x => this.onDataLoadSuccessful([x]), error =>
      this.noMissingImage());

  }

  noMissingImage() {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `No missing datasheets found!`, MessageSeverity.success);
  }

  onDataLoadSuccessful(stockCodes: any[]) {
        

    if (stockCodes[0]) {

      this.stockCoderowscache = [...stockCodes[0]];


      this.missingImageCount = this.stockCoderowscache.length;
      this.alertService.showMessage('Processing', this.missingImageCount + ` missing datasheets found! preparing data`, MessageSeverity.info);

      this.stockCoderows = this.stockCoderowscache.slice(0, 100);

      this.sysproService.getAllCMCategories().subscribe(x => this.onCategoriesLoadSuccessful(x), error => this.onDataLoadFailed(error));

      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }
  }
  onCategoriesLoadSuccessful(x: any) {
    this.allcategories = x;

    let updateRows = this.stockCoderowscache;
    updateRows.forEach((stockCode, index, inventory) => {
      (<any>stockCode).index = index + 1;
      var parentTree = "";
      stockCode.categoryTree = this.buildParentTree(stockCode.categoryName, parentTree);
    
      //this.sysproService.getStockCodeAdditionalDetail(stockCode.stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(
      //  x => {
      //    var addDetails = x[0];
      //    if (addDetails) {
      //      stockCode.qtyCT = addDetails.qtyCT;
      //      stockCode.qtyCombined = addDetails.qtyCombined;
      //      stockCode.binLocCT = addDetails.binLocCT;
      //      stockCode.createDate = addDetails.createDate;
      //    }

      //  }, error => this.onDataLoadFailed(error));
    });

    // updateRows.sort((a, b) => (a.categoryTree > b.categoryTree) ? 1 : -1)
    this.stockCoderows = [...updateRows];
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  buildParentTree(parentCategoryName: string, treePath: string): string {
    var path = "";
    if (parentCategoryName && parentCategoryName != "Root") {
      let parents = this.allcategories.filter(x => x.categoryName == parentCategoryName);
      if (parents && parents.length >= 1) {
        let parent = parents[0];
        if (parent && parent.parentCategoryName != "") {
          path = parentCategoryName + " > " + treePath;
          treePath = this.buildParentTree(parent.parentCategoryName, path);
        }
        else {
          return treePath;
        }
      }
      else {
        return treePath;
      }
    }
    return treePath;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
 
    if (value.trim() != "") {      
      this.stockCoderows = this.stockCoderowscache.filter(r => Utilities.searchArray(value, false, r.stockCode, r.description, r.brandName, r.categoryTree));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.stockCoderows = [...this.stockCoderowscache];
    }
    //if (this.chkDt1) {
    //  this.stockCoderows = this.stockCoderows.filter(m => m.dataSheetImage == "");
    //}
    
    //if (this.chkDt2) {
    //  this.stockCoderows = this.stockCoderows.filter(m => m.addSheetImage == "");
    //}
 
  }

 

  filterDatasheet1() {
    this.chkDt1 = !this.chkDt1;
    this.onSearchChanged(this.searchTerm );
  }

  filterDatasheet2() {
    this.chkDt2 = !this.chkDt2;
    this.onSearchChanged(this.searchTerm );
  }

  deleteStockCodes(row: CMStockCode) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.deleteCMStockCode(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));

  }

  enableStockCodes(row: CMStockCode) {
    row.status = "E";
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateCMStockCode(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  disableStockCodes(row: CMStockCode) {
    row.status = "D";
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateCMStockCode(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  saveSegment(row: CMStockCode) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
   

    this.sysproService.updateCMStockCode(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }



  selectItem(row: CMStockCode) {
    if (row) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      var stockCode = this.stockCodedetail.stockCode;
      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;

      if (sessionStorage.getItem(localStorageActions.StockCode) != stockCode) {
        sessionStorage.setItem(localStorageActions.StockCode, stockCode);
      }

      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }

    }


  }

  stockCodeModallHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
   
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    //this.loadData();
  }



  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newformData.append('file', file);    
    }
  }


  onSaveSuccesful(): void {

    //this.brandModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  onFileChange(event, row: CMStockCode) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.sysproService.postFileData(formData, "DATASHEET", row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
    
    }
  }

  filePostedSuccess(result: any, row: CMStockCode) {

    console.log(result);
    row.dataSheetImage = result.fileName;
    row.dataSheetImageChanged = new Date();
    //Update Syspro with image path
    //this.sysproService.updateStockCodeImageSyspro(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___"), row.stockCodeImage).subscribe(x =>
    //  (this.alertService.showMessage('Success', `Syspro Image Updated`, MessageSeverity.success)), error => this.onPostDataLoadFailed(error));

    this.saveSegment(row);
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  exportStockCodes() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "Brand", "CategoryPath", "Datasheet1", "DataSheet2"]
    };

    var filename = "Missing Datasheets";
    var selectRows = [...this.stockCoderowscache];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        StockCode: row.stockCode, Description: row.description, Brand: row.brandName, CategoryPath: row.categoryTree, Datasheet1: row.dataSheetImage, DataSheet2: row.addSheetImage
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
