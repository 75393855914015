
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CartHeader } from 'src/app/models/cart-header.model';
import { Supplier } from 'src/app/models/supplier.model';
import { Calculations } from 'src/app/services/calculations.service';
import { validation } from 'src/app/services/validation.service';


@Component({
  selector: 'syspro-inventory-nonstock',
  templateUrl: './syspro-inventory-nonstock.component.html',
  styleUrls: ['./syspro-inventory-nonstock.component.scss']
})
export class SysproInventoryNonStockComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
    rows: CartDetail[] = [];
    rowsCache: CartDetail[] = [];
    cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
  loadingIndicator: boolean;

  suppliersCache: Supplier[] = [];

    
  newRow: CartDetail = new CartDetail();


    @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;
   
  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;


    @ViewChild('orderQtyTemplate')
  orderQtyTemplate: TemplateRef<any>;


  @ViewChild('uomTemplate')
  uomTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
  discountTemplate: TemplateRef<any>;

  

    @ViewChild('priceTemplate')
    priceTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;


  @ViewChild('supplierTemplate')
  supplierTemplate: TemplateRef<any>;
  

  @ViewChild('supplierNameTemplate')
  supplierNameTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
//  inventoryList: Inventory[];


  @ViewChild('supplierModal')
  supplierModal: ModalDirective;


  @ViewChild('currentSupplier')
  currentSupplier: Supplier;


  @ViewChild('suppliers')
  private suppliers;

  @ViewChild('supplierSelector')
  private supplierSelector;

  @ViewChild('authModal')
  authModal: ModalDirective;

  authorized: boolean;
  stringToCompare: string;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;


    cartId: string;
    cart: CartHeader;
  isSaving: boolean;
    cartlinenumber: any;
    isNavigating: boolean;
  currentRow: number = 1;
  //currentSupplier: any;
    lineDetail: CartDetail;
    rowNumber: number;
    id: string;
    hasDetailRows: boolean;
  selectedRow: any;

  @ViewChild('boSupplierNameRow')
  private boSupplierNameRow;

  rememberSupplier: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    this.id = action;
    let cartId = sessionStorage.getItem(localStorageActions.CartId);
    if (cartId) {

      this.cartId = cartId;
      this.authorized = false;

      this.loadData();
    }

    else {
      this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
    }
  }


    ngAfterViewInit() {

    }


  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    console.log("LOADING SUPPLIERS");
    this.sysproService.getAllSuppliers().subscribe(x => this.onSupplierLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
   
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    this.alertService.showMessage('Loaded', `Cart Header Loaded`, MessageSeverity.success);
  }

  onCartDetailLoadSuccessful(cartDetail: CartDetail[]) {

    this.loadNewNSRow();

    if (cartDetail[0]) {
      console.log("LOADING THE CART DETAIL");
      this.cartRows = cartDetail;
      this.cartlinenumber = cartDetail.length;
      this.newRow.cartLineNumber = this.GenerateUniqueCartLineNumber() + 1;

      this.rows = this.cartRows.filter(x => x.lineType == "7");
      this.hasDetailRows = true;
      for (var product of this.rows) {
        this.calcTotal(product);
      }
    }
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }

  loadNewNSRow() {


    console.log("Creating New Row");
    const cartRow = new CartDetail();
    cartRow.cartNumber = this.cartId;
    cartRow.lineType = "7";
    cartRow.stockCode = "##_";
    cartRow.cartLineNumber = this.GenerateUniqueCartLineNumber() + 1;
    cartRow.stockDescription = "";
    cartRow.warehouse = "**";
    cartRow.orderQty = 0;
    cartRow.unitCost = 0.00;
    cartRow.orderUom = "EA";
    cartRow.sellingPrice = 0.00;
    cartRow.discountPercentage1 = 0.00;
    cartRow.discountValue = 0;
    cartRow.priceConvFac = 1;
    cartRow.unitmass = 0;
    cartRow.unitvolume = 0;
    cartRow.comment = "";
    cartRow.commentFromLineNumber = 0;
    cartRow.commentType = "";
    cartRow.boSupplierName = (this.currentSupplier && this.currentSupplier.supplier != "") ? this.currentSupplier.supplierName : "Select Supplier";
    cartRow.boSupplierInvoice = "";
    cartRow.boLineInfo = "";
    cartRow.boSupplierCode = (this.currentSupplier && this.currentSupplier.supplier != "") ? this.currentSupplier.supplier : "";
    cartRow.vatExempt = false;
    Object.assign(this.newRow, cartRow);



  }

  formatUnitCost(row: CartDetail): any {
    row.unitCost = Calculations.setUnitCostDecimals(row.unitCost);
  }

  calcTotal(row: CartDetail): any {
    let total = 0;
    total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
  }

  private saveSuccessHelper(cart?: CartDetail) {
    
    if (cart) {
    }

    if (!this.isNavigating) {
      this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
  }

  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  saveNewRow() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    console.log("SAVING THESE VALUES", this.newRow);
    if (this.newRow.boSupplierCode.length > 1) {
      if (this.newRow.stockCode.startsWith("##_")) {

        this.newRow.stockCode = this.newRow.stockCode.toUpperCase();
      }
      else {
        this.newRow.stockCode = "##_" + this.newRow.stockCode.toUpperCase();
      }
      this.newRow.stockDescription = this.newRow.stockDescription.toUpperCase();
      this.newRow.orderUom = this.newRow.orderUom.toUpperCase();

      //Check if stockcode already exist on order
      if (this.rows.find(x => x.stockCode == this.newRow.stockCode)) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Save Warning', 'This stock code has already been added to the cart', MessageSeverity.warn);

      }
      else {

        if (this.ValidateSave(this.newRow)) {
          this.newRow.nettDiscount = 0;
          this.newRow.nettPrice = Calculations.getGridNettLinePrice(this.newRow.orderQty, this.newRow.sellingPrice, this.newRow.priceConvFac, this.newRow.discountPercentage1, 0);
          this.newRow.originalPrice = this.newRow.sellingPrice;
          this.newRow.originalDiscount = this.newRow.discountPercentage1;
          this.fowkesService.cartDetail(this.newRow).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));

        }
      }

    }
    else {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Save Warning', 'Make sure to select the supplier before saving', MessageSeverity.warn);

    }
  }

  ValidateSave(row: CartDetail): boolean {
    let error = "";
    if (!this.authorized) {
      error = validation.minPrice(row.unitCost, row.sellingPrice, row.discountPercentage1, error);
      if (error.length > 1) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
        this.authorized = false;
        this.authModal.show();
        return false;
      }
      else {
        this.authorized = true;
      }
    }
    if (this.authorized) {
      error = validation.stockCode(row.stockCode, error);
      error = validation.description(row.stockDescription, error);
      error = validation.qty(row.orderQty, error);
      error = validation.uom(row.orderUom, error);
      error = validation.NSprice(row.sellingPrice, error);
      error = validation.discount(row.discountPercentage1, error);
      error = validation.NScost(row.unitCost, error);
      error = validation.supplier(row.boSupplierCode, row.boSupplierName, row.boLineInfo, error);

      if (error.length > 1) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
        return false;
      }
      else {
        return true;
      }
    }
  }

  verifyAuth() {
    this.alertService.startLoadingMessage("Verfiying...");
    this.loadingIndicator = true;
    this.fowkesService.VerifyPassword(this.branchCode, "PRICE", encodeURIComponent(this.stringToCompare)).subscribe(x => this.onVerificationSuccess(), error => this.onVerificationFailed(error));

  }
  onVerificationSuccess(): void {
    this.authorized = true;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
    this.authModal.hide();
    if (this.newRow.stockDescription && this.newRow.stockDescription != "") {
      this.saveNewRow();
    }
    else {
      this.save();
    }
  }

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }


  GenerateUniqueCartLineNumber(): any {
    if (this.cartRows.length > 0) {
      const maxId = this.cartRows.reduce(
        (max, character) => (character.cartLineNumber > max ? character.cartLineNumber : max),
        this.cartRows[0].cartLineNumber
      );
      return maxId;
    }
    else {
      return 0;
    }
  }


  deleteRow(row: CartDetail) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.stockCode + ' - ' + row.stockDescription +  '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: CartDetail) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.fowkesService.cartDetailDelete(row.cartNumber, row.cartLineNumber).subscribe(results => {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.stockCode + ` Successfully deleted`, MessageSeverity.success);

      this.rowsCache = this.rowsCache.filter(item => item !== row);
      this.rows = this.rows.filter(item => item !== row);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });

  }
      
  onSupplierLoadSuccessful(suppliers: Supplier[]) {
    this.suppliersCache = suppliers;
    this.setSupplier(suppliers);
    console.log("SUPPLIERS LOADED");
  }

  setSupplier(suppliers: Supplier[]) {

    if (this.newRow.boSupplierCode) {
      if (!this.suppliers.some(r => r.supplier == this.newRow.boSupplierCode)) {
       // this.suppliers.unshift(new Supplier(this.newRow.boSupplierCode));
      }
    }

    if (suppliers == null || this.suppliers.length != suppliers.length) {
      setTimeout(() => {
        if (this.supplierSelector) {
          this.supplierSelector.refresh();
        }
      });
    }
  }
  
    onDataLoadFailed(error: any, source?: string) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      if (error.status == 404 || error.status == 500) {
        this.onCartDetailLoadSuccessful([new CartDetail()]);
      }
      this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  viewCustomer() {
    if (!this.checkFornewRow("view")) {
      this.isNavigating = true;
      this.save();
      this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });

    }    
  }

  checkFornewRow(navigate?: string) {
    if (this.newRow.stockDescription != "" || this.newRow.orderQty > 0) {
      this.alertService.showDialog('There are values for a new non stock line, did you mean to save new line first?', DialogType.confirm,
        () => this.SaveNewHelper(navigate),
        () => this.ClearNewHelper(navigate),
        "Save New Line",
        "Clear New Line");
      return true;
    }
    else return false;
  }

  SaveNewHelper(navigate?: string): any {

    this.saveNewRow();
    //this.save();
    //if (navigate.length > 1) {
    //  this.router.navigate(['../../orders'], { fragment: navigate, queryParams: { customer: this.id, cartId: this.cartId } });
    //}    
  }

  ClearNewHelper(navigate?: string): any {
    this.loadNewNSRow();
    this.isNavigating = true;
    this.save();
    if (navigate.length > 1) {
      this.router.navigate(['../../orders'], { fragment: navigate, queryParams: { customer: this.id, cartId: this.cartId } });
    }
  }

  goAddItems() {
    if (!this.checkFornewRow("inventory")) {
      this.isNavigating = true;
      this.save();
      this.router.navigate(['../../orders'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });

    }    
  }

  goSaveItems() {
    if (!this.checkFornewRow("cart")) {
      this.isNavigating = true;
      this.save();
      this.router.navigate(['../../orders'], { fragment: 'cart', queryParams: { customer: this.id, cartId: this.cartId } });

    }    
  }

  private save(location?) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    //Populate cart rows with selected input
    const saveRows = this.rows.filter(x => x.lineType !== "6");
    if (saveRows.length >= 1) {

      // Validate Duplicate Stock Codes
      var seen = {};
      var hasDuplicates = saveRows.some(function (currentObject) {
        return seen.hasOwnProperty(currentObject.stockCode)
          || (seen[currentObject.stockCode] = false);
      });

      if (hasDuplicates) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Save Error', 'Please make sure all stockcodes are unique', MessageSeverity.warn);
      }
      else {
        for (let product of saveRows) {
          if (this.ValidateSave(product)) {
            //All validation passed, save NS item
            if (this.cart.hasNettPrices) {
              product.sellingPrice = product.nettPrice;
              product.discountPercentage1 = product.nettDiscount;
            }
            else {
              product.originalPrice = product.sellingPrice;
              product.originalDiscount = product.discountPercentage1;
            }
            product.specialPrice = product.sellingPrice;
            product.unitCost = Calculations.setUnitCostDecimals(product.unitCost);
            this.fowkesService.cartDetail(product, this.cartId).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
          }
        }
      }      
    }
    //else {
    //  this.alertService.showMessage('Warning', 'There are no items to save, did you mean to save the new line?', MessageSeverity.warn);
    //  this.isSaving = false;
    //  this.alertService.stopLoadingMessage();
    //}
  }

  showSupplierDetail(row: number) {
    this.currentRow = row;
    this.supplierModal.show();
  }

  inlineSupplierSelect(row: CartDetail) {
    this.selectedRow = row;
    this.supplierModal.show();
  }

  setSupplierName(supplier: any) {
    var supplierDetail = this.getSupplierByID(supplier);
     var canSave = false;
    //if (this.newRow.boSupplierCode == 'COD001' || this.newRow.boSupplierCode == 'COD002') {
    //  if (!this.newRow.boLineInfo || this.newRow.boLineInfo.length <= 1) {
    //    this.alertService.showMessage('Error', "Please enter a name for the cash supplier. ", MessageSeverity.error);
    //  }
    //  else {
    //    canSave = true;
    //  }
    //}
    //else {
    //  canSave = true;
    //}

    if (this.selectedRow && this.selectedRow.stockCode) {
      this.selectedRow.boSupplierName = supplierDetail.supplierName;
      var updateRow = this.rows.find(x => x.cartLineNumber == this.selectedRow.cartLineNumber);
      updateRow.boSupplierCode = supplierDetail.supplier;
      updateRow.boSupplierName = supplierDetail.supplierName;
      updateRow.boLineInfo = this.newRow.boLineInfo;
      var error = "";
      error = error = validation.supplier(supplierDetail.supplier, supplierDetail.supplierName, this.newRow.boLineInfo, error);

      if (error.length > 1) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Error', error + 'For item ' + this.selectedRow.stockCode, MessageSeverity.error);
        canSave = false;
      }
      else {
        canSave = true;
      }
      
    }
    else {
      if (this.newRow) {
        this.newRow.boSupplierName = supplierDetail.supplierName;
        var error = "";
        error = validation.supplier(supplierDetail.supplier, supplierDetail.supplierName, this.newRow.boLineInfo, error);

        if (error.length > 1) {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showMessage('Error', error, MessageSeverity.error);
          canSave = false;
        }
        else {
          canSave = true;
        }
      }
    }

   if (canSave) {

      this.currentSupplier = supplierDetail;

      this.selectedRow = {};
      this.currentRow = null;
      this.supplierModal.hide();
   }
   
    ////var extra = supplier.supAddr1 ? supplier.supAddr1 : "";
    //if (this.currentRow == 0) {
    //  this.newRow.boSupplierCode = supplier;
    //  this.newRow.boLineInfo = extra;
    //}
    //else {

    //  let row = this.rows.filter(x => x.cartLineNumber == this.currentRow)[0];
    //  row.boSupplierCode = supplier;
    //  row.boSupplierName = supplierDetail.supplierName;
    //  row.boLineInfo = extra;
    //}

  }

  private getSupplierByID(name: string) {
    return this.suppliersCache.find((r) => r.supplier == name);
  }


  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
   
  }

  calcDisc(row: any, event) {
    var val = event.target.value;
    row.discountPercentage1 = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
  }


  calcTotalNewRow() {
    let row = this.newRow;
    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    this.newRow.lineTotal = total;    
  }


  formatNewRowUnitCost(): any {
    let row = this.newRow;
    row.unitCost = Calculations.setUnitCostDecimals(row.unitCost);
  }


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = Calculations.precise_round(total, 2);
    
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }


  onSupplierSearchChanged(value: string) {
    if (value != "") {
      this.suppliers = this.suppliersCache.filter(r => Utilities.searchArray(value, false, r.supplier, r.supplierName));
    }
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
