

export class Inbox {
  id: number;
  integrationId: string;
  integrationName: string;
  channel: string;
  messageId: string;
  relatedMessageId: string;
  relatedClientMessageId: string;
  senderNumber: string;
  receiverNumber: string;
  timestamp: Date;
  profileName: string;
  charset: string;
  content: string;
  udh: string;
  network: string;
  encryptionKey: string;
}

export class Message {
  id: number;
  senderName: string;
  senderNumber: string;
  content: string;
  timestamp: Date;
  isIncoming: boolean;
  isExpanded: boolean = false; // Tracks whether the message details are visible
}


export class Outbox {
  id: number;
  toPhoneNumber: string;
  channel: string;
  content: string;
  templateName: string;
  headerType: string;
  headerMediaFileId: string;
  bodyParameters: string;
  buttonTitle1: string;
  buttonPostbackData1: string;
  buttonTitle2: string;
  buttonPostbackData2: string;
  createdAt: Date;
  sentAt: Date | null;
  isSent: boolean;
  messageId: string;
  status: string;
  statusCode: number;
  statusTimestamp: Date;
  nextGenUser: string;
}

export class OptIns {
  public id: number;
  public phoneNumber: string;
  public optedInDate: Date;
  public optedIn: boolean;
  public channel: string;
  public otp: string;
  public profileName: string;
  public nextGenUser: string;
  public inviteSendDate: Date;
  public optedOutDate: Date;
}

export class InboxMessageDetails {
  inboxId: number;
  integrationName: string;
  senderNumber: string;
  timestamp: Date;
  content: string;
  messageType: string | null;  // Nullable if there might be messages without a specific type
  mediaUrl: string | null;     // Nullable for messages that do not include media
  mediaId: string | null;     // Nullable for messages that do not include media
  profileName: string;
}


