import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { User } from '../../models/user.model';
import { ArCustomer } from '../../models/arcustomer.model';
import { ArNarration } from '../../models/arnarration.model';
import { Permission } from '../../models/permission.model';
import { SysproService } from '../../services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { forEach } from '@angular/router/src/utils/collection';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { filter } from 'rxjs/operators';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { Branch } from '../../models/branch.model';
import { ConfigurationSettings } from '../../models/configuration-settings.model';
import { TaxCodes } from '../../models/tax-code.model';
import * as moment from 'moment';


@Component({
  selector: 'settings-configuration',
  templateUrl: './settings-configuration.component.html',
  styleUrls: ['./settings-configuration.component.scss']
})
export class SettingsConfigurationComponent implements OnInit {


  @ViewChild('table') table: DatatableComponent;
  rows: ArNarration[] = [];
  rowsCache: ArNarration[] = [];
  loadingIndicator: boolean;

  scrollbarV = false;
  bodyHeight = 'auto';

  editing = {};

  private isEditMode = false;
  private isSaving = false;
  private showValidationErrors = false;
  private customerNotes: ArNarration[] = [];
  
  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;


  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;
  
  @ViewChild('vatCodeSelector')
  private vatCodeSelector;


  @ViewChild('effectiveDate')
  private effectiveDate;

  startDate = new Date().toISOString().slice(0, 16);

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();
  
  @ViewChild('f')
  private form;
    columns: ({ prop: string; name: string; width: number; canAutoResize: boolean; } | { prop: string; name: string; width: number; canAutoResize?: undefined; })[];
    id: string;
  noteLines: ArNarration[] = [];
  notelinenumber: any;

  newRow: ArNarration = new ArNarration();
    hasDetailRows: boolean;
    lastRow: number;
    rowsSaved: any;
  rowsToSave: ArNarration[] = [];
    allBranches: any[];
  configSettings: ConfigurationSettings[];
  taxCodes: TaxCodes[];
  taxCode: string;
  newTaxValue: string;
    vatHistory: ConfigurationSettings[];
 
  constructor(private alertService: AlertService, private accountService: AccountService, private sysproService: SysproService,
    private router: Router, private fowkesOnline: FowkesOnlineService) {
  }

  ngOnInit() {
    this.alertService.startLoadingMessage();

    this.loadingIndicator = true;

    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
    this.sysproService.getConfigurationSettings().subscribe(x => this.onConfigLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
    
    this.startDate = moment().format('YYYY-MM-DD');
  }
    onCurrentUserDataLoadFailed(error: any): void {
        throw new Error("Method not implemented.");
    }
  onConfigLoadSuccessful(x: ConfigurationSettings[]): void {
   
    this.configSettings = x;
    this.vatHistory = x.filter(r => r.configuration == "VAT");
    this.sysproService.getSysproTaxCodes().subscribe(x => this.onTaxCodesLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));

  }

  onTaxCodesLoadSuccessful(x: TaxCodes[]): void {
    this.alertService.stopLoadingMessage();
    this.taxCodes = x;
    this.taxCode = this.configSettings.filter(r => r.configuration == "TAXEXEMPT")[0].value;

    setTimeout(() => {
      if (this.vatCodeSelector) {
        this.vatCodeSelector.refresh();
      }
    });

    }


  private onBranchLoadSuccessful(branches: Branch[]) {
    this.alertService.stopLoadingMessage();
    this.allBranches = branches;
    console.log("Branches", this.allBranches);
  }


  deleteRow(row: ConfigurationSettings) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    console.log(row);
    this.sysproService.deleteConfigurationSettings(row.id, row.configuration).subscribe(x => this.onConfigLoadSuccessful(x), error => this.saveFailedHelper(error));

  }

  updateRow(row: Branch) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    console.log(row);
    this.sysproService.updateBranchSettings(row.branch, row.priceOverridePassword, row.invoiceReprintPassword, row.salesOrderPassword,
    row.priceCode, row.ledgerCode).subscribe(x => this.onBranchLoadSuccessful(x), error => this.saveFailedHelper(error));

  }

  //setSave(row: ArNarration) {
  //  console.log(row);
  //  this.rowsToSave = null;
  //  this.isSaving = true;
  //  this.alertService.startLoadingMessage('Saving changes...');

  //  this.sysproService.insertCustomerNotes(this.currentCustomer, row.line, row.notation).subscribe(_response => this.saveLinesSuccessHelper(), error => this.saveFailedHelper(error));

  //}




  save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.loadingIndicator = true;
    let stringDate: Date = new Date(this.startDate.toString());
    if (this.newTaxValue != "") {
      this.sysproService.updateConfigurationSettings("VAT", this.newTaxValue, this.accountService.currentUser.userName, stringDate.toJSON()).subscribe(x => this.onConfigLoadSuccessful(x), error => this.saveFailedHelper(error));
    }
    this.sysproService.updateConfigurationSettings("TAXEXEMPT", this.taxCode, this.accountService.currentUser.userName, null).subscribe(x => this.onConfigLoadSuccessful(x), error => this.saveFailedHelper(error));

  
  }

  


  private saveSuccessHelper() {    

    this.isEditMode = false;


    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }
  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }
   
   
  private cancel() {
    
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
   
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }


  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }
}
