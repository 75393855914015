import { Component, ViewChild, Input, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { isNil, remove, reverse } from 'lodash';
import {
  TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem } from 'ngx-treeview';
import { DocumentManagmement } from '../../../models/document-management.model';
import { SimpleTree } from '../../../models/simple-tree.model';
import { CMCategories } from '../../../models/cms-category.model';
import { DocumentCategory } from '../../../models/document-category.model';

@Component({
  selector: 'document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  animations: [fadeInOut]
})
export class ViewDocumentsComponent {

  items: TreeviewItem[];
  rows: string[];
  item: any;
  data: DocumentManagmement;
  categoryData: DocumentCategory[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild('itemTemplate')
  itemTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate')
  headerTemplate: TemplateRef<any>;

  loadingIndicator: boolean;
  enableEdit = false;
  enableEditIndex = null;


  @ViewChild('categoryModal')
  categoryModal: ModalDirective;

  categoryDetail: DocumentCategory = new DocumentCategory();
  rowsCache: TreeviewItem[];

  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private accountService: AccountService) {
  }


  ngOnInit() {

    this.loadData();

  }

  ngOnDestroy() {

  }

  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getDocumentCatergories(-1).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }

  onDataLoadSuccessful(treeview: any[]) {


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
       
    let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

    this.items = this.getItems([treeviewItems]);
    this.rowsCache = [...this.items];
        
    console.log(this.items);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  selectItem(item: TreeviewItem) {
    if (item.value.document && item.value.document.DocumentName) {
      var test = item.value.document.FilePath + "/" + decodeURI(item.value.document.DocumentName);
      if (item.value.document.DocumentName.indexOf("xls") >= 0 || item.value.document.DocumentName.indexOf(".doc") >= 0) {
        window.open("/MediaServer/DocumentRepositoryDocuments/" + item.value.document.DocumentName, "_blank");
      }
      else {
        window.open(item.value.document.FilePath + "/" + item.value.document.DocumentName, "_blank");
      }
    }
  }

  treeBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
        this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
      }
    }
  
    //if (e.item && e.item.locked)
    //  e.cancel = true;
  }                   

  enableEditMethod(row: TreeviewItem) {
    this.enableEdit = true;
    console.log(row.value, row);
    this.enableEditIndex = row.value.index;

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
  }


  addNewFolder(row: TreeviewItem) {
   // this.enableEdit = true;
    console.log(row.value, row);
    this.enableEditIndex = row.value.index;

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);

    this.categoryDetail.parentCategoryName = row.text;
    this.categoryDetail.enabled = true;
    this.categoryDetail.createdBy = this.accountService.currentUser.email;
    this.categoryDetail.responsibility = "";

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }

  categoryModalHide() {
    this.categoryDetail = new DocumentCategory();
    this.categoryModal.hide();
  }


  saveNewDocumentFolder() {

    //Validate save
    if (this.categoryDetail) {
      if (this.categoryDetail.categoryName) {
        this.sysproService.addDocumentCategory(this.categoryDetail).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a document folder name`, MessageSeverity.warn);
      }
    }
  }

  onNewFolderAdded(x: DocumentCategory): void {
    this.loadData();
    // Open to correct level.

    this.categoryModalHide();
  }


  onNewFolderAddedFailed(error: any): void {
    throw new Error("Method not implemented.");
  }

  removeFolder(row: TreeviewItem) {
    // this.enableEdit = true;
    console.log(row.value, row);
    this.enableEditIndex = row.value.index;

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
    if (row.value.category) {
      this.sysproService.deleteDocumentCategory(row.value.category.Id).subscribe(x => { this.removeItem(row) }, error => this.onNewFolderAddedFailed(error));
    }

  }

  onSelectedCheckedChange(row: TreeviewItem) {
    let test = "";
  }

  onSelectedChange(downlineItems: TreeviewItem): void {

    console.log(downlineItems);
  //  this.sysproService.getSimpleDocumentTree(downlineItems[0].item.text).subscribe(result => this.breadcrumbs = [...result]);
    //this.rows = [];
    //downlineItems.forEach(downlineItem => {
    //  const item = downlineItem.item;
    //  const value = item.value.split("|__")[0];
    //  const texts = [item.text];
    //  let parent = downlineItem.parent;
    //  while (!isNil(parent)) {
    //    texts.push(parent.item.text);
    //    parent = parent.parent;
    //  }
    //  const reverseTexts = reverse(texts);
    //  const row = `${reverseTexts.join(' -> ')} : ${value}`;
    //  this.rows.push(row);
    //});
  }

  expandItem(item: TreeviewItem): void {
   
    for (const tmpItem of this.items) {
      if (tmpItem === item) {
        item.collapsed = false;
        let parent = TreeviewHelper.findParent(tmpItem, item);
        parent.collapsed = false;
      }
    }

    this.treeviewComponent.raiseSelectedChange();
  }

  removeItem(item: TreeviewItem): void {
    for (const tmpItem of this.items) {
      if (tmpItem === item) {
        remove(this.items, item);
      } else {
        if (TreeviewHelper.removeItem(tmpItem, item)) {
          break;
        }
      }
    }

    this.treeviewComponent.raiseSelectedChange();
  }

  onFilterChange(value: string): void {
    //console.log('filter:', value);
  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }


  get canManageDocuments() {
    return this.accountService.userHasPermission(Permission.manageDocumentsPermission);
  }
}
