

export class WHAvailQTY {

  constructor() { }

  public warehouse: string;
  public warehouseDescription: string;
  public qty: number;
  public unitCost: number;
  public warehouseQty: string;
  public sellingPrice: number;
  public qtyOnOrder: number;
  public qtyInTransit: number;
  public uom: string;
  public orderDueDate: string;
  public buyerResponsible: string;
  public dateLastCostChange: string;
}
