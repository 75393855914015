

export class CoreEmailTemplate {
  id: number; // Auto-incrementing unique identifier
  name: string;       // Friendly name for the template
  type: string;       // Type of template (e.g., Customer, Supplier)
  subject: string;    // Subject line of the email
  body: string;       // The body of the email with placeholders
  isActive: boolean;  // Indicates if the template is active
  createdDate: Date;  // Date the template was created
  modifiedDate?: Date; // Last modified date
  createdBy?: string;  // User who created the template
  modifiedBy?: string; // User who last modified the template
}
