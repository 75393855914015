
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { ReportService } from '../../../services/report.service';
import { Utilities } from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../../models/permission.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteList } from 'src/app/models/quote-list.model';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { PurchaseOrderList } from 'src/app/models/purchaseorder-list.model';
import { User } from '../../../models/user.model';
import { InvoiceTrackingStatus } from '../../../models/invoice-tracking-status.model';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv';
import { CatalogueActionReport } from '../../../models/catalogue-action-report.model';


@Component({
  selector: 'reports-product-catalogue-usage',
  templateUrl: './reports-product-catalogue-usage.component.html',
  styleUrls: ['./reports-product-catalogue-usage.component.scss']
})
export class ReportsProductCatalogueComponent implements OnInit, AfterViewInit {


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];

  loadingIndicator: boolean;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;


  @ViewChild('dateOnlyTemplate')
  dateOnlyTemplate: TemplateRef<any>;

  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "ALL";


  @ViewChild('#companiesListSelector')
  private companiesListSelector;

  rows: CatalogueActionReport[] = [];
  rowsCache: CatalogueActionReport[] = [];

  gridHeight: number = 0;
  selectedItems: any;
  isNavigating: boolean;
  countRecords: number;
  percentageCount: number;
  chkPurged: boolean;
  chkTBC: boolean;
  chkIncomplete: boolean;
  chkShowAll: boolean;
  selectedCompany: string;



  @ViewChild('itsModal')
  itsModal: ModalDirective;
  itsDetail: InvoiceTrackingStatus;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute) {
  }


  ngOnInit() {


    this.setGrid();

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day')
    }

    this.selectedCompany = "ALL";// (this.application == "MaxArcusOnline" ? "Max Arcus" : "Fowkes Bros");


    this.chkTBC = true;
    this.chkPurged = true;
    this.chkIncomplete = false;
    this.chkShowAll = true;

    this.loadData();

  }



  ngAfterViewInit() {
    this.setGrid();
  }

  setGrid() {
    this.columns = [
      { prop: 'createdDate', name: 'Create Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'actionedBy', name: 'Operator', width: 200 },
      { prop: 'branch', name: 'Branch', width: 80 },
      { prop: 'referenceNumber', name: 'Type', width: 200 },
      { prop: 'actionType', name: 'Action', width: 80 },
      { prop: 'actionInput', name: 'Detail', width: 300 }
    ];
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.actionedBy, r.branch, r.actionType,
        r.actionInput, r.referenceType, r.referenceNumber));
    }
  }

  specificSearch() {
    this.loadData();
  }


  //  onSpecificSearchSuccesful(x: SalesOrderList[]): void {

  //    this.alertService.stopLoadingMessage();
  //    this.loadingIndicator = false;
  //    this.searchResults = x.filter(i => i.invoiceNumber != '');

  //}


  viewInvoiceSelected() {
    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, this.searchResultSelected.invoiceNumber);
    this.router.navigate(['/reports'], { fragment: 'invoicestatus' });

  }


  viewInvoice(row: InvoiceTrackingStatus) {


    //if ((this.application == "MaxArcusOnline" && row.fromCompany == "Max Arcus") || (this.application == "FowkesOnline" && row.fromCompany == "Fowkes Bros")) {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);

    this.itsDetail = row;

    if (!this.itsModal) {
      setTimeout(() => {
        if (this.itsModal) {
          this.itsModal.show();
        }
      });
    }
    else {
      this.itsModal.show();
    }
    //}

  }




  itsModallHide() {
    this.itsDetail = null;
    this.itsModal.hide();
  }


  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);

    this.sysproService.getCatalogueUseReportData(startD.toJSON(), endD.toJSON()).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }

  onSearchSuccesful(x: CatalogueActionReport[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.countRecords = 0;
    this.percentageCount = 0;

    if (x && x.length > 0) {
      this.rowsCache = x;

     // this.countRecords = this.invoiceTrackingPODsReceived.length;
     //this.percentageCount = (this.countRecords / this.rowsCache.length) * 100;

      this.rows = this.rowsCache;

    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }


  showAll() {
    this.chkShowAll = !this.chkShowAll;
    this.chkIncomplete = false;
    this.filterChanged();
  }

  showOnlyIncomplete() {
    this.chkIncomplete = !this.chkIncomplete;
    this.chkShowAll = false;
    this.filterChanged();
  }

  showPurged() {
    this.chkPurged = !this.chkPurged;
    this.filterChanged();
  }

  showTBC() {
    this.chkTBC = !this.chkTBC;
    this.filterChanged();
  }

  filterChanged() {

    //if (this.chkIncomplete) {
    //  this.rows = this.rowsCache.filter(x => x.dispatch != "A" && x.collections != "A");
    //}

    //if (this.chkShowAll) {
    //  this.rows = this.rowsCache;
    //}


  }
  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;

  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.loadData();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["CreateDate", "Operator", "Branch", "Type", "Action", "Detail"]
    };

    var filename = "CatalogueUsage";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.createdDate).toISOString().split('T')[0];

      exportRows.push({
        CreateDate: date, Operator: row.actionedBy,
        Branch: row.branch,
        Type: row.referenceNumber,
        Action: row.actionType,
        Detail: row.actionInput
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  isValidDate(d: any): boolean {

    var isValid = (d != null && d != "" && d != "1900-01-01T00:00:00");
    return isValid;
   // return d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime()) && d > new Date(1900, 1, 2);
  }


}
