
export class InvoiceTrackingLookup {
  constructor() {

  }
  public invoice: string;
  public salesOrder: string;
  public branchCode: string;
  public purchaseOrder: string;
  public creditNote: string;
  public shippingInstructions: string;
  public company: string;
  public customerCode: string;
  public customerName: string;
  public operatorCode: string;
  public pods: boolean;
  public insertDate: Date;
  public poCount: number;
}

