

export class SalesPerson {

  constructor(branch?: string, salesperson?: string, name?: string) {

    this.branch = branch;
    this.salesperson = salesperson;
    this.name = name;
  }


  public branch: string;
  public salesperson: string;
  public name: string;
  public salesbudget1: number;
  public salesbudget2: number;
  public salesbudget3: number;
  public salesbudget4: number;
  public salesbudget5: number;
  public salesbudget6: number;
  public salesbudget7: number;
  public salesbudget8: number;
  public salesbudget9: number;
  public salesbudget10: number;
  public salesbudget11: number;
  public salesbudget12: number;
  public salesbudget13: number;
  public salesactual1: number;
  public salesactual2: number;
  public salesactual3: number;
  public salesactual4: number;
  public salesactual5: number;
  public salesactual6: number;
  public salesactual7: number;
  public salesactual8: number;
  public salesactual9: number;
  public salesactual10: number;
  public salesactual11: number;
  public salesactual12: number;
  public salesactual13: number;
  public commissionpct: number;
  public timestamp: number[];
  public salespersoncoll: string;
}
