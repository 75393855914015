
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { Utilities } from '../../services/utilities';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { UserEdit } from '../../models/user-edit.model';
import { UserInfoComponent } from './user-info.component';
import { Branch } from '../../models/branch.model';
import { AspnetApplications } from '../../models/applications.model';


@Component({
    selector: 'users-management',
    templateUrl: './users-management.component.html',
    styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
    rows: User[] = [];
    rowsCache: User[] = [];
    editedUser: UserEdit;
    sourceUser: UserEdit;
    editingUserName: { name: string };
    loadingIndicator: boolean;

    allRoles: Role[] = [];
  allBranches: Branch[] = [];
  allApplications: AspnetApplications[] = [];

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;

    @ViewChild('userNameTemplate')
    userNameTemplate: TemplateRef<any>;

    @ViewChild('rolesTemplate')
    rolesTemplate: TemplateRef<any>;

    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('userEditor')
    userEditor: UserInfoComponent;


    constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private fowkesService: FowkesOnlineService) {
    }


  ngOnInit() {

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: 'fullName', name: gT('users.management.FullName'), width: 120 },
      { prop: 'userName', name: gT('users.management.UserName'), width: 120, cellTemplate: this.userNameTemplate },
      //{ prop: 'jobTitle', name: gT('users.management.Title'), width: 100 },
      { prop: 'roles', name: 'Role', width: 150, cellTemplate: this.rolesTemplate },
      { prop: 'branch', name: gT('Branch'), width: 50 },
     // { prop: 'email', name: gT('users.management.Email'), width: 140 },
      { prop: 'sysproOperator', name: gT('Operator'), width: 60 },
      { prop: 'sysproBuyer', name: 'Buyer', width: 60 },
      { prop: 'sysproRep', name: 'Rep Code', width: 60 },
     // { prop: 'phoneNumber', name: gT('users.management.PhoneNumber'), width: 100 }
    ];

    if (this.canManageUsers) {
      this.columns.push({ name: '', width: 140, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }

    this.loadData();
  }


    ngAfterViewInit() {

        this.userEditor.changesSavedCallback = () => {
            this.addNewUserToList();
            this.editorModal.hide();
        };

        this.userEditor.changesCancelledCallback = () => {
            this.editedUser = null;
            this.sourceUser = null;
            this.editorModal.hide();
        };
    }


    addNewUserToList() {
        if (this.sourceUser) {
            Object.assign(this.sourceUser, this.editedUser);

            let sourceIndex = this.rowsCache.indexOf(this.sourceUser, 0);
            if (sourceIndex > -1) {
                Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
            }

            sourceIndex = this.rows.indexOf(this.sourceUser, 0);
            if (sourceIndex > -1) {
                Utilities.moveArrayItem(this.rows, sourceIndex, 0);
            }

            this.editedUser = null;
            this.sourceUser = null;
        } else {
            const user = new User();
            Object.assign(user, this.editedUser);
            this.editedUser = null;

            let maxIndex = 0;
            for (const u of this.rowsCache) {
                if ((<any>u).index > maxIndex) {
                    maxIndex = (<any>u).index;
                }
            }

            (<any>user).index = maxIndex + 1;

            this.rowsCache.splice(0, 0, user);
            this.rows.splice(0, 0, user);
            this.rows = [...this.rows];
        }
    }


    loadData() {
        this.alertService.startLoadingMessage();
      this.loadingIndicator = true;


      this.fowkesService.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onDataLoadFailed(error));
      this.fowkesService.getApplications().subscribe(x => this.onAppLoadSuccessful(x), error => this.onDataLoadFailed(error));

        if (this.canViewRoles) {
            this.accountService.getUsersAndRoles().subscribe(results => this.onDataLoadSuccessful(results[0], results[1]), error => this.onDataLoadFailed(error));
        } else {
            this.accountService.getUsers().subscribe(users => this.onDataLoadSuccessful(users, this.accountService.currentUser.roles.map(x => new Role(x))), error => this.onDataLoadFailed(error));
      }
    }

  private onBranchLoadSuccessful(branches: Branch[]) {
    this.alertService.stopLoadingMessage();
    this.allBranches = branches;
    console.log("Branches", this.allBranches);
  }

  private onAppLoadSuccessful(applications: AspnetApplications[]) {
    this.alertService.stopLoadingMessage();
    this.allApplications = applications;
    console.log("allApplications", this.allApplications);
  }


    onDataLoadSuccessful(users: User[], roles: Role[]) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        users.forEach((user, index, users) => {
            (<any>user).index = index + 1;
        });

        this.rowsCache = [...users];
        this.rows = users;

      this.allRoles = roles;
    }


    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    onSearchChanged(value: string) {
        this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.userName, r.fullName, r.email, r.phoneNumber, r.sysproBuyer, r.jobTitle, r.roles, r.branch, r.sysproOperator));
    }

    onEditorModalHidden() {
        this.editingUserName = null;
        this.userEditor.resetForm(true);
    }


    newUser() {
        this.editingUserName = null;
        this.sourceUser = null;
        this.editedUser = this.userEditor.newUser(this.allRoles, this.allBranches, this.allApplications);
        this.editorModal.show();
    }


    editUser(row: UserEdit) {
        this.editingUserName = { name: row.userName };
        this.sourceUser = row;
      this.editedUser = this.userEditor.editUser(row, this.allRoles, this.allBranches, this.allApplications);
        this.editorModal.show();
    }


    deleteUser(row: UserEdit) {
        this.alertService.showDialog('Are you sure you want to delete \"' + row.userName + '\"?', DialogType.confirm, () => this.deleteUserHelper(row));
    }


    deleteUserHelper(row: UserEdit) {

        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;

        this.accountService.deleteUser(row)
            .subscribe(results => {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

                this.rowsCache = this.rowsCache.filter(item => item !== row);
                this.rows = this.rows.filter(item => item !== row);
            },
            error => {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

                this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
                    MessageSeverity.error, error);
            });
    }



    get canAssignRoles() {
        return this.accountService.userHasPermission(Permission.assignRolesPermission);
    }

    get canViewRoles() {
        return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

    get canManageUsers() {
        return this.accountService.userHasPermission(Permission.manageUsersPermission);
    }
}
