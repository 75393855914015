

export class Supplier {

  public supplier: string;
  public supplierName: string;
  public supAddr1: string;
  public supAddr2: string;
  public supAddr3: string;
  public supAddr4: string;
  public supAddr5: string
  public telephone: string;
  public email: string;
  public contact: string;

  public specialDiscount: number;
  public specialCostAdd: number;
  public specialPrice: number;
}
