
export class InvoiceTrackingStatus {
  constructor() {

  }
  public id: number;
  public invoiceNumber: string;
  public salesOrder: string;
  public branch: string;
  public shippingInstructions: string;
  public customerCode: string;
  public customerName: string;
  public currentStatus: string;
  public printed: string;
  public printedDateTime: Date;
  public printedUser: string;
  public picking: string;
  public pickingDateTime: Date;
  public pickingUser: string;
  public onHold: string;
  public onHoldDateTime: Date;
  public onHoldUser: string;
  public onHoldUpdate: string;
  public picked: string;
  public pickedDateTime: Date;
  public pickedUser: string;
  public checked: string;
  public checkedDateTime: Date;
  public checkedUser: string;
  public dispatch: string;
  public dispatchDateTime: Date;
  public dispatchUser: string;
  public collections: string;
  public collectionsDateTime: Date;
  public collectionsUser: string;
  public delivery: string;
  public deliveryDateTime: Date;
  public deliveryUser: string;
  public returned: string;
  public returnedDateTime: Date;
  public returnedUser: string;
  public completed: string;
  public completedDateTime: Date;
  public completedUser: string;
  public purge: string;
  public purgeOperator: string;
  public purgeUser: string;
  public purgeReason: string;
  public purgeDateTime: Date;
  public transfer: string;
  public transferInvoiceNumber: string;
  public lastOperator: string;
  public insertDate: Date;
  public poCount: number;
  public creditInvoice: string;
  public podReceived: string;
  public podReceivedDate: Date;
  public invoiceCredited: string;
  public invoiceCreditedDate: Date;
  public creditNoteNumber: string;
  public fromCompany: string;
  public operatorCode?: string;
  public pendingStatus: string;
}

