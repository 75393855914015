
import { Injectable } from '@angular/core';
import { FowkesOnlineService } from './fowkes-online.service';

@Injectable()
export class Calculations {

  //TODO: Add to admin config, set to 10% for now
  static minimumPriceMargin: number = 10;

  

  public static precise_round(num, decimals) {
  var t = Math.pow(10, decimals);
  return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);

  }

  public static RoundAwayFromZero(startValue: number, digits: number) {
    let decimalValue = 0;
    digits = digits || 0;
    startValue *= Math.pow(10, (digits + 1));
    decimalValue = Math.floor(startValue) - (Math.floor(startValue / 10) * 10);
    startValue = Math.floor(startValue / 10);
    if (decimalValue >= 5) {
      startValue += 1;
    }
    startValue /= Math.pow(10, (digits));
    return startValue;
  }


  public static setUnitCostDecimals(lineUnitCost: number): any {
    return this.RoundAwayFromZero(lineUnitCost, 3);
  }


  public static setSpecialPriceDecimals(specialPrice: number): any {
    return this.RoundAwayFromZero(specialPrice, 2);
  }


  public static getSpecialPriceTotal(unitCost: number, lineSellingPrice: number, addPercentage: number, discPercentage: number, linePriceConvFac: number,
    lineTotal: number): any {

    if (discPercentage > 0) {
      lineTotal = this.RoundAwayFromZero((lineSellingPrice / linePriceConvFac) * ((100 - discPercentage) / 100), 3);
    }
    else {
      lineTotal = this.RoundAwayFromZero((unitCost) * ((100 + addPercentage) / 100), 3);
    }

    return this.RoundAwayFromZero(lineTotal, 3);// this.RoundAwayFromZero(this.RoundAwayFromZero(lineTotal, 3), 2);
  }

  public static getSpecialPriceCostPlus(unitCost: number, specialPrice: number, linePriceConvFac: number,  addPercentage: number): any {

    addPercentage = this.RoundAwayFromZero((((specialPrice / linePriceConvFac) - unitCost) / unitCost) * 100, 3);
    
    return this.RoundAwayFromZero(addPercentage, 2);// this.RoundAwayFromZero(this.RoundAwayFromZero(lineTotal, 3), 2);
  }

  public static getGridLineTotal(lineOrderQty: number, lineSellingPrice: number, linePriceConvFac: number, lineDiscountPercentage: number,
    lineTotal: number): any {

    lineTotal = this.RoundAwayFromZero((lineOrderQty * (lineSellingPrice / linePriceConvFac)) * ((100 - lineDiscountPercentage) / 100), 3);

    return this.RoundAwayFromZero(lineTotal, 2);// this.RoundAwayFromZero(this.RoundAwayFromZero(lineTotal, 3), 2);
  }


  public static getGridLineTotalCostPlus(lineOrderQty: number, lineCostPrice: number, linePriceConvFac: number, linePlusPercentage: number,
    lineTotal: number): any {

    lineTotal = this.RoundAwayFromZero((lineOrderQty * (lineCostPrice * linePriceConvFac)) * ((100 + linePlusPercentage) / 100), 3);

    return this.RoundAwayFromZero(lineTotal, 2);// this.RoundAwayFromZero(this.RoundAwayFromZero(lineTotal, 3), 2);
  }

  public static getGridNettLinePrice(lineOrderQty: number, lineSellingPrice: number, linePriceConvFac: number, lineDiscountPercentage: number,
    lineTotal: number): any {

    var baseTotal = this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2);
    //(lineOrderQty * (lineSellingPrice / linePriceConvFac));
    var discount = this.RoundAwayFromZero(this.RoundAwayFromZero(
      ((this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2) / 100)
        * lineDiscountPercentage), 3), 2);
      // ((lineOrderQty * (lineSellingPrice / linePriceConvFac)) / 100) * lineDiscountPercentage;

    lineTotal = ((baseTotal - discount) / lineOrderQty) * linePriceConvFac;

    return this.RoundAwayFromZero(lineTotal, 3);
  }
 
  //Calculate the line total for specified row
  public static calculateBaseLineTotal(lineOrderQty: number, lineSellingPrice: number, linePriceConvFac: number,
    lineTotal: number): any {

    lineTotal = this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2);
      //(lineOrderQty * (lineSellingPrice / linePriceConvFac));

    return lineTotal;
  }


  //Calculate the line discount total for specified row
  public static calculateLineDiscountTotal(lineOrderQty: number, lineSellingPrice: number, lineDiscountPercentage: number, linePriceConvFac: number, lineDiscountTotal: number): any {

    lineDiscountTotal = this.RoundAwayFromZero(this.RoundAwayFromZero(
      ((this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2) / 100)
        * lineDiscountPercentage), 3), 2);

    return lineDiscountTotal;
  }


  //Calculate the line totals like syspro
  public static calculateLineSysproTotal(lineOrderQty: number, lineSellingPrice: number, lineDiscountPercentage: number, linePriceConvFac: number, lineSysproTotal: number): any {

    lineSysproTotal =
      this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2)
      - this.RoundAwayFromZero(this.RoundAwayFromZero(
        ((this.RoundAwayFromZero(this.RoundAwayFromZero(((lineOrderQty * lineSellingPrice) / linePriceConvFac), 3), 2) / 100)
          * lineDiscountPercentage), 3), 2);


    return lineSysproTotal;
  }

  public static calculateGPLineTotal(lineUnitCost: number, lineSellingPrice: number,linePriceConvFac: number,
    lineTotal: number): any {

    lineTotal = (((lineSellingPrice - lineUnitCost) / lineSellingPrice) * 100);

    return lineTotal;
  }

  public static calculateNettLinePrice(lineOrderQty: number, lineSellingPrice: number, linePriceConvFac: number,
    lineTotal: number, lineDiscountTotal: number): any {
    if(lineOrderQty > 0)
    lineTotal = ((lineTotal - lineDiscountTotal) / lineOrderQty) * linePriceConvFac;
      //this.RoundAwayFromZero(((lineTotal - lineDiscountTotal) / lineOrderQty) * linePriceConvFac, 2);

    return lineTotal;
  }


  //Calculate the line discount for special price
  public static calculateSpecialDiscountTotal(lineSellingPrice: number, lineSpecialPrice: number, lineDiscountTotal: number): any {

    lineDiscountTotal = ((lineSellingPrice - lineSpecialPrice) / lineSellingPrice) * 100 ;

    return lineDiscountTotal;
  }



  //Calculate the line vat for specified row
  public static calculateLineVat(lineTotalExlVat: number, lineDiscount: number, vatValue: number, vatPercentage?: number): any {

    let calcVat = 0;
    if (vatPercentage >= 0) {
      calcVat = vatPercentage;
    }
    else {
      calcVat = Calculations.getVatPercentage();
    }

    var lineTotalRounded = this.RoundAwayFromZero(lineTotalExlVat - lineDiscount, 2);
    vatValue = this.RoundAwayFromZero(lineTotalRounded / 100 * calcVat, 2);
    return vatValue;
  }

  public static validateMinimumPriceMargin(lineSellingPrice: number, lineDiscountPercentage: number, linePriceConvFac: number, lineCost: number): any {
   
    var newSellingPrice = (lineSellingPrice / linePriceConvFac) * ((100 - lineDiscountPercentage) / 100);
    //Check that the newSellingPrice - discount does not exceed unitCost + minimumPriceMargin in web.config
    if (newSellingPrice < (lineCost + ((lineCost / 100) * this.minimumPriceMargin))) {
      return false;
    }
    else {
      return true;
    }
  }


  public static getVatPercentage(invoiceDate?: Date): any {

    return 15;
  }

  public static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {
    const separatorIndex = text.indexOf(separator);

    if (separatorIndex == -1) {
      return { firstPart: text, secondPart: null };
    }

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static safeStringify(object) {

    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    } catch (error) {

    }

    const simpleObject = {};

    for (const prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof (object[prop]) == 'object') {
        continue;
      }
      if (typeof (object[prop]) == 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }

    result = '[***Sanitized Object***]: ' + JSON.stringify(simpleObject);

    return result;
  }

  public static JsonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }
      return value;
    }
  }

  public static TestIsObjectEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  public static TestIsUndefined(value: any) {
    return typeof value === 'undefined';
    // return value === undefined;
  }

  public static TestIsString(value: any) {
    return typeof value === 'string' || value instanceof String;
  }

  public static capitalizeFirstLetter(text: string) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return text;
    }
  }

  public static toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (subString) => {
      return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
    });
  }

  public static toLowerCase(items: string);
  public static toLowerCase(items: string[]);
  public static toLowerCase(items: any): string | string[] {

    if (items instanceof Array) {
      const loweredRoles: string[] = [];

      for (let i = 0; i < items.length; i++) {
        loweredRoles[i] = items[i].toLowerCase();
      }

      return loweredRoles;
    } else if (typeof items === 'string' || items instanceof String) {
      return items.toLowerCase();
    }
  }

  public static uniqueId() {
    return this.randomNumber(1000000, 9000000).toString();
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static baseUrl() {
    let base = '';

    if (window.location.origin) {
      base = window.location.origin;
    } else {
      base = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return base.replace(/\/$/, '');
  }

  public static printDateOnly(date: Date) {

    date = new Date(date);

    const dayNames = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    const monthNames = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');

    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    let sup = '';
    const month = date.getMonth();
    const year = date.getFullYear();

    if (dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31) {
      sup = 'st';
    } else if (dayOfMonth == 2 || dayOfMonth == 22) {
      sup = 'nd';
    } else if (dayOfMonth == 3 || dayOfMonth == 23) {
      sup = 'rd';
    } else {
      sup = 'th';
    }

    const dateString = dayNames[dayOfWeek] + ', ' + dayOfMonth + sup + ' ' + monthNames[month] + ' ' + year;

    return dateString;
  }

  public static printTimeOnly(date: Date) {

    date = new Date(date);

    let period = '';
    let minute = date.getMinutes().toString();
    let hour = date.getHours();

    period = hour < 12 ? 'AM' : 'PM';

    if (hour == 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }

    if (minute.length == 1) {
      minute = '0' + minute;
    }

    const timeString = hour + ':' + minute + ' ' + period;


    return timeString;
  }

  public static printDate(date: Date, separator = 'at') {
    return `${Calculations.printDateOnly(date)} ${separator} ${Calculations.printTimeOnly(date)}`;
  }

  public static printFriendlyDate(date: Date, separator = '-') {
    const today = new Date(); today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1);
    const test = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (test.toDateString() == today.toDateString()) {
      return `Today ${separator} ${Calculations.printTimeOnly(date)}`;
    }
    if (test.toDateString() == yesterday.toDateString()) {
      return `Yesterday ${separator} ${Calculations.printTimeOnly(date)}`;
    } else {
      return Calculations.printDate(date, separator);
    }
  }

  public static printShortDate(date: Date, separator = '/', dateTimeSeparator = '-') {

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    if (day.length == 1) {
      day = '0' + day;
    }

    if (month.length == 1) {
      month = '0' + month;
    }

    return `${month}${separator}${day}${separator}${year} ${dateTimeSeparator} ${Calculations.printTimeOnly(date)}`;
  }

  public static parseDate(date) {

    if (date) {

      if (date instanceof Date) {
        return date;
      }

      if (typeof date === 'string' || date instanceof String) {
        if (date.search(/[a-su-z+]/i) == -1) {
          date = date + 'Z';
        }

        return new Date(date);
      }

      if (typeof date === 'number' || date instanceof Number) {
        return new Date(<any>date);
      }
    }
  }

  public static printDuration(start: Date, end: Date) {

    start = new Date(start);
    end = new Date(end);

    // get total seconds between the times
    let delta = Math.abs(start.valueOf() - end.valueOf()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;  // in theory the modulus is not required


    let printedDays = '';

    if (days) {
      printedDays = `${days} days`;
    }

    if (hours) {
      printedDays += printedDays ? `, ${hours} hours` : `${hours} hours`;
    }

    if (minutes) {
      printedDays += printedDays ? `, ${minutes} minutes` : `${minutes} minutes`;
    }

    if (seconds) {
      printedDays += printedDays ? ` and ${seconds} seconds` : `${seconds} seconds`;
    }


    if (!printedDays) {
      printedDays = '0';
    }

    return printedDays;
  }

  public static getAge(birthDate, otherDate) {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
      otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }

    return years;
  }

  public static searchArray(searchTerm: string, caseSensitive: boolean, ...values: any[]) {
    if (!searchTerm) {
      return true;
    }

    let filter = searchTerm.trim();
    let data = values.join();

    if (!caseSensitive) {
      filter = filter.toLowerCase();
      data = data.toLowerCase();
    }

    return data.indexOf(filter) != -1;
  }

  public static moveArrayItem(array: any[], oldIndex, newIndex) {

    while (oldIndex < 0) {
      oldIndex += this.length;
    }

    while (newIndex < 0) {
      newIndex += this.length;
    }

    if (newIndex >= this.length) {
      let k = newIndex - this.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  public static expandCamelCase(text: string) {

    if (!text) {
      return text;
    }

    return text.replace(/([A-Z][a-z]+)/g, ' $1')
      .replace(/([A-Z][A-Z]+)/g, ' $1')
      .replace(/([^A-Za-z ]+)/g, ' $1');
  }

  public static testIsAbsoluteUrl(url: string) {

    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
  }

  public static convertToAbsoluteUrl(url: string) {

    return Calculations.testIsAbsoluteUrl(url) ? url : '//' + url;
  }

  public static removeNulls(obj) {
    const isArray = obj instanceof Array;

    for (const k in obj) {
      if (obj[k] === null) {
        isArray ? obj.splice(k, 1) : delete obj[k];
      } else if (typeof obj[k] == 'object') {
        Calculations.removeNulls(obj[k]);
      }

      if (isArray && obj.length == k) {
        Calculations.removeNulls(obj);
      }
    }

    return obj;
  }

  public static debounce(func: (...args) => any, wait: number, immediate?: boolean) {
    let timeout;

    return function () {
      const context = this;
      const args_ = arguments;

      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args_);
        }
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args_);
      }
    };
  }
}
