import { Component, ViewChild, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [fadeInOut]
})
export class OrdersComponent {

  isListActivated = true;
  isViewActivated = false;
  isInventoryListActivated = false;
  isInventoryNSActivated = false;
  isCartViewActivated = false;

  isCollapsed = false;
  fragmentSubscription: any;

  readonly customersListTab = 'customers';
  readonly customerViewTab = 'view';
  readonly inventoryListTab = 'inventory';
  readonly inventoryNSTab = 'nonstock';
  readonly cartViewTab = 'cart';

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  isSearch: boolean;

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  id: any = "";
    cartId: string;
    noCart: boolean;


  constructor(private router: Router, private route: ActivatedRoute,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {
       
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let cartId = sessionStorage.getItem(localStorageActions.CartId);
    this.cartId = cartId;
    this.noCart = true;

    this.isCollapsed = !this.configurations.menuExpanded;

    if (action) {
      this.id = action;
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
      if (cartId) {
        this.noCart = false;
      }
    } else {
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    }
  }

  ngAfterViewInit() {
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let cartId = sessionStorage.getItem(localStorageActions.CartId);
    this.cartId = cartId;
    this.noCart = true;
    if (action) {
      this.id = action;
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
      if (cartId) {
        this.noCart = false;
      }
    } else {

      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
      //this.router.navigate(['orders']);
    }
  }
  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string, test?: any) {

    this.id = sessionStorage.getItem(localStorageActions.Customer);
    this.cartId = sessionStorage.getItem(localStorageActions.CartId);
    if (this.cartId) {
      this.noCart = false;
    }
   
    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.customersListTab}Tab`);

  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.customersListTab;
    this.isViewActivated = activeTab == this.customerViewTab;
    this.isInventoryListActivated = activeTab == this.inventoryListTab;
    this.isInventoryNSActivated = activeTab == this.inventoryNSTab;
    this.isCartViewActivated = activeTab == this.cartViewTab;

    this.router.navigate([], { fragment: activeTab, queryParams: { customer: this.id, cartId: this.cartId } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.customersListTab;
    this.isViewActivated = activeTab == this.customerViewTab;
    this.isInventoryListActivated = activeTab == this.inventoryListTab;
    this.isInventoryNSActivated = activeTab == this.inventoryNSTab;
    this.isCartViewActivated = activeTab == this.cartViewTab;

    this.router.navigate([], { fragment: activeTab });
  }

  get canViewCustomers() {
    return true;
  }

  get canViewMovements() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerMovementsPermission);
  }

  get canViewPayments() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerPaymentsPermission);
  }
}
