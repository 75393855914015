import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { CMCategoryList } from '../../models/cms-category-list.model';
import { CMCategories } from '../../models/cms-category.model';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { Email } from '../../models/email.model';
import { SimpleTree } from '../../models/simple-tree.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'products-category-catalogue',
  templateUrl: './products-category-catalogue.component.html',
  styleUrls: ['./products-category-catalogue.component.scss'],
  animations: [fadeInOut]
})
export class ProductsCatagoryCatalogueComponent {


  @ViewChild('imageTemplate')
  imageTemplate: TemplateRef<any>;

  loadingIndicator: boolean;
  enableEdit = false;
  enableEditIndex = null;

  searchType: string = "";
  reportTitle: string = "";
  reportType: string = "";
  searchText: string = "";

  columnsCat: any[] = [];
  rowsCat: CMCategoryList[] = [];
  rowsCatcache: CMCategoryList[] = [];



  emailObject: Email = new Email();


  src: string;


  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute,
    private sysproService: SysproService, private accountService: AccountService, private reportService: ReportService,
    private configurations: ConfigurationService, private authService: AuthService) {
  }


  ngOnInit() {
    this.searchType = "catLowList";
    this.SetDataGrid();
    this.loadData();

  }

  ngOnDestroy() { }


  ngAfterViewInit() {
    this.SetDataGrid();
  }

  SetDataGrid() {
   
    this.columnsCat = [
      { name: '', width: 60, cellTemplate: this.imageTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      { prop: 'categoryName', name: 'Category', width: 280, cellClass: "left" },
      { prop: 'numberOfStockCodes', name: 'Items', width: 80, cellClass: "right" },
      { prop: 'categoryGroup', name: 'Group', width: 250, cellClass: "left" },
      { prop: 'categoryTree', name: 'Tree', width: 400, cellClass: "left" }
    ]
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportType = "report";
    this.reportTitle = "Category List";
    this.searchText = "catLowList";
    this.loadList();

  }



  loadList() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    //Scrape and Load Categories
    this.sysproService.getCategoryCatalogueList("CT", "catLowList", "ALL", "ALL").subscribe(x => this.onCategoryListLoadSuccessful(x), error =>
      this.onDataLoadFailed(error));
  }

  onCategoryListLoadSuccessful(x: CMCategoryList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x[0]) {
      this.rowsCatcache = [...x];
      this.rowsCat = this.rowsCatcache;
    }
  }
     
  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onSearchChanged(value: string) {
    if (value != "") {
      this.rowsCat = this.rowsCatcache.filter(r => Utilities.searchArray(value, false, r.categoryName, r.categoryGroup, r.categoryTree,
        r.metaDescription));
      this.searchText = value;
    }
    else {
      this.rowsCat = this.rowsCatcache;
      this.searchText = "";
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }



  createCatalogue() {

    this.alertService.startLoadingMessage("Generating Report...");
    this.loadingIndicator = true;

    var filter = this.searchText != "" ? this.searchText : "ALL";
    this.reportService.getCataloguePDF(this.branchCode, this.application, this.reportTitle,
      this.reportType, "catLowList", true, filter).subscribe(x => this.previewCatalogue(), error => this.onDataLoadFailed(error));
  }

  previewCatalogue() {


    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.accountService.currentUser.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = this.reportTitle + " Catalogue";
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = this.reportTitle;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Catalogue";

    this.reportService.getFileStreamCatalogue(this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.viewPDF(), error => this.onDataLoadFailed(error));


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  viewPDF() {
    var attachement = this.emailObject.Attachment;


    this.reportService.getFileStreamCatalogue(this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));

  }

  onFileSuccessful(x: Blob, preview: boolean): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var attachement = this.emailObject.Attachment.replace('/', '_').replace('+', '_').replace('%', '_');

    this.src = url;

    if (!preview) {
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = attachement + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["CategoryGroup", "CategoryName", "CategoryTree", "NumberOfStockCodes"]
    };

    var filename = "Category Catalogue";
    var selectRows = [...this.rowsCat];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        CategoryGroup: row.categoryGroup, Category: row.categoryName, CategoryTree: row.categoryTree, NumberOfStockCodes: row.numberOfStockCodes
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
