import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { ArCustomer } from '../../models/arcustomer.model';
import { CartHeader } from '../../models/cart-header.model';
import { ArCustomerEdit } from '../../models/arcustomer-edit.model';
import { SalesPerson } from '../../models/salesperson.model';
import { Permission } from '../../models/permission.model';
import { SysproService } from '../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ArNarration } from 'src/app/models/arnarration.model';
import { localStorageActions } from 'src/app/models/enums';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { QuoteHeader } from 'src/app/models/quote-header.model';
import { Observable, of, timer } from 'rxjs';
import { delay, flatMap } from 'rxjs/operators';
import { ShippingInstr } from 'src/app/models/shipping-instructions.model';


@Component({
  selector: 'syspro-customer-preview',
  templateUrl: './syspro-customer-preview.component.html',
  styleUrls: ['./syspro-customer-preview.component.scss']
})
export class SysproCustomerPreviewComponent implements OnInit {


  uniqueId: string = Utilities.uniqueId();
  private isEditMode = false;
  private isSaving = false;
  private isNavigating = false;
  private showValidationErrors = false;
  private editingUserName: string;
  private user: User = new User();
  customer: ArCustomer = new ArCustomer();
  private customerEdit: ArCustomerEdit;
  columns: any[] = [];
  rows: ArNarration[] = [];
  rowsCache: ArNarration[] = [];
  private allSalesPersons: SalesPerson[] = [];

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;

  @Input()
  isCart: boolean;
  @Input()
  isQuote: boolean;
  @Input()
  isCustomer: boolean;
  @Input()
  isSalesOrder: boolean;


  @ViewChild('f')
  private form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild('customerName')
  private customerName;
  
  @ViewChild('email')
  private email;

  @ViewChild('salespersons')
  private salespersons;

  @ViewChild('salespersonSelector')
  private salespersonSelector;

  @ViewChild('sysproOperator')
  private sysproOperator;

  @ViewChild('shippingInstr')
  private shippingInstr;

  @ViewChild('shippingInstrSelector')
  private shippingInstrSelector;

  
  @ViewChild('debtorsModal')
  debtorsModal: ModalDirective;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  private customerAccountStatus: string = ""
  private sub: any;
  private id: any = "";
    customerNotes: ArNarration[];
    customerIsOnHoldText: string;
    totalInvoiceCount: number;
    cartSaved: boolean;
    cartId: string;
  quoteId: string;


  cart: CartHeader = new CartHeader();
  private cartEdit: CartHeader;
  quote: QuoteHeader = new QuoteHeader();
  shippingInstructionsList: ShippingInstr;
    customerId: string;
    salesOrderNumber: string;
    overLimit: number;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService,
    private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {

    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
    }

  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    let action = sessionStorage.getItem(localStorageActions.Customer);
    if (action) {
      this.id = action;
      this.sysproService.getCustomerDetail(this.id).subscribe(x => this.onDataLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));

      //this.sysproService.getCustomerNotes(this.id).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      //this.router.navigate(['../../customers']);
    }

    if (!!this.isCart) {
      let cartId = sessionStorage.getItem(localStorageActions.CartId);
      if (!!cartId) {
        this.cartId = cartId;
        this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));
      }
    }

    if (!!this.isQuote) {
      let quoteId = sessionStorage.getItem(localStorageActions.QuoteNumber);
      if (!!quoteId) {
        this.quoteId = quoteId;
        console.log("I have loaded with this quoteId", this.quoteId);        
      }
    }

    if (!!this.isCustomer) {
      let customerId = sessionStorage.getItem(localStorageActions.Customer);
      if (!!customerId) {
        this.customerId = customerId;
        console.log("I have loaded with this customerId", this.customerId);
      }
    }

    if (!!this.isSalesOrder) {
      let salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
      if (!!salesOrderNumber) {
        this.salesOrderNumber = salesOrderNumber;
        console.log("I have loaded with this salesOrderNumber", this.salesOrderNumber);
      }
    }
  }

  onCartHeaderLoadSuccessful(x: CartHeader): void {
    this.cart = x;
    Object.assign(this.customer, this.cart);
  }


  onQuoteHeaderLoadFailed(error: any): void {
    throw new Error("Method not implemented.");
  }
  onQuoteHeaderLoadSuccessful(x: QuoteHeader): void {
    this.quote = x[0];
    console.log("THIS IS THE QUOTE", this.quote);
  }



  onCartHeaderLoadFailed(error: any): void {
    throw new Error("Method not implemented.");
  }


  private edit() {
    this.isEditMode = true;
    this.showValidationErrors = true;
    this.setCartHeader();
  }

  private setCartHeader() {
    this.cartEdit = new CartHeader();
    Object.assign(this.cartEdit, this.cart);
    this.cartEdit.userid = this.accountService.currentUser.id;
    console.log("THIS IS MY EDITING CART POPULATED WITH CUSTOMER", this.cartEdit);
    if (!!this.cartId) {
      if (this.cart) {
        Object.assign(this.cartEdit, this.cart);
        console.log("THIS IS MY EDITING CART POPULATED WITH EXISTING CART", this.cartEdit);
        console.log("THIS IS THE CART THAT WAS ADDED", this.cart);
      }
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  private onSalesPersonoadSuccessful(salesPersons: any[]) {
    this.alertService.stopLoadingMessage();
    this.allSalesPersons = salesPersons[0];
    console.log(this.allSalesPersons);
  }

  private onDataLoadSuccessful(customer: ArCustomer) {
    this.alertService.stopLoadingMessage();
    this.customer = customer;

    let overLimit = parseFloat(customer.creditLimit) - parseFloat(customer.currentBalance1);

    if (customer.customerOnHold === "Y") {
      this.customerIsOnHoldText = "Customer is on hold";
      this.customerAccountStatus = (overLimit < 0 ? "On hold and exceeded credit limit" : "Customer on hold");
    }
    else {
      this.customerAccountStatus = (overLimit < 0 ? "Customer exceeded credit limit" : "In Terms");
    }

    if (overLimit < 0) {
      this.overLimit = overLimit;
    }

    this.getTotalInvoices();

    if (!!this.cartId) {
      this.GetCurrentCartDelayed().then(x =>
        this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onCartHeaderLoadFailed(error)));
    }
    if (!!this.quoteId) {
      this.GetCurrentCartDelayed().then(x =>
        this.sysproService.getQuoteHeader(this.quoteId).subscribe(x => this.onQuoteHeaderLoadSuccessful(x), error => this.onQuoteHeaderLoadFailed(error)));
    }
  }

  private getTotalInvoices() {
    this.totalInvoiceCount = parseFloat(this.customer.numCurrentInv) + parseFloat(this.customer.num30daysInv) + parseFloat(this.customer.num60daysInv)
      + parseFloat(this.customer.num90daysInv) + parseFloat(this.customer.num120daysInv);
  }

  public GetCurrentCartDelayed() {
    return new Promise(resolve =>
      setTimeout(resolve, 2000)
    );
  }


  goAddItems() {
    this.isNavigating = true;
    this.setCartHeader();
    //this.save();
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }

  copySoldToShip() {
    this.cartEdit.shipToAddr1 = this.cartEdit.soldToAddr1;
    this.cartEdit.shipToAddr2 = this.cartEdit.soldToAddr2;
    this.cartEdit.shipToAddr3 = this.cartEdit.soldToAddr3;
    this.cartEdit.shipToAddr4 = this.cartEdit.soldToAddr4;
    this.cartEdit.shipToAddr5 = this.cartEdit.soldToAddr5;
    this.cartEdit.shipPostalCode = this.cartEdit.soldPostalCode;
  }
  copyShipToSold() {
    this.cartEdit.soldToAddr1 = this.cartEdit.shipToAddr1;
    this.cartEdit.soldToAddr2 = this.cartEdit.shipToAddr2;
    this.cartEdit.soldToAddr3 = this.cartEdit.shipToAddr3;
    this.cartEdit.soldToAddr4 = this.cartEdit.shipToAddr4;
    this.cartEdit.soldToAddr5 = this.cartEdit.shipToAddr5;
    this.cartEdit.soldPostalCode = this.cartEdit.shipPostalCode;
  }

  private getSalesPersonByName(name: string) {
    return this.allSalesPersons.find((r) => r.salesperson == name);
  }
  

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  
  setLocalCartId(cartId: string) {
    if (sessionStorage.getItem(localStorageActions.CartId) != cartId) {
      sessionStorage.setItem(localStorageActions.CartId, cartId);
    }
  }

  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    console.log("CartNumberSave", this.cartEdit.cartNumber);
    this.fowkesService.newCart(this.cartEdit).subscribe(_response => this.saveSuccessHelper(this.cartEdit.cartNumber), error => this.saveFailedHelper(error));
  }

  search() {
     //this.router.navigate(['../../customers'], { fragment: 'customers', queryParams: { customer: this.customer } });
  }


  private saveSuccessHelper(cartId: string) {

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;
    this.isEditMode = false;

    this.alertService.showMessage('Success', `Updated successfully ` + cartId, MessageSeverity.success);
    this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onCartHeaderLoadFailed(error));

  }
  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }
   
   
  private cancel() {
    if (this.isGeneralEditor) {
      //this.userEdit = this.user = new UserEdit();
      this.customerEdit = this.customer = new ArCustomerEdit();
    } else {
      //this.userEdit = new UserEdit();
      this.customerEdit = new ArCustomerEdit();
    }

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
   // this.userEdit = this.user = new UserEdit();
    this.customerEdit = this.customer = new ArCustomerEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }
  
  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }



  editCustomer(customer: ArCustomer, allSalesPersons: SalesPerson[]) {
    if (customer) {
      this.isGeneralEditor = true;

      this.setSalesPerson(customer, allSalesPersons);
      this.customer = new ArCustomer();
      this.customerEdit = new ArCustomerEdit();
      Object.assign(this.customer, customer);
      Object.assign(this.customerEdit, customer);
      this.edit();

      console.log(this.customerEdit);
      console.log(this.customer);
      return this.customerEdit;
    } 
  }


  displayCustomer(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

    this.customer = new ArCustomer();
    Object.assign(this.customer, customer);
    this.setSalesPerson(customer, allSalesPersons);
    this.isEditMode = false;
  }


  private setSalesPerson(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

    this.allSalesPersons = allSalesPersons ? [...allSalesPersons] : [];

    if (customer.salesperson) {
      if (!this.allSalesPersons.some(r => r.salesperson == customer.salesperson)) {
        this.allSalesPersons.unshift(new SalesPerson(customer.salesperson));
      }
    }

    if (allSalesPersons == null || this.allSalesPersons.length != allSalesPersons.length) {
      setTimeout(() => {
        if (this.salespersonSelector) {
          this.salespersonSelector.refresh();
        }
      });
    }
  }


  get canViewAllSalesPersons(){
    return true;
  }

  get canAssignSalesPersons() {
    return true;
  }

  get canViewAllBranches() {
    return true;
  }
  get canAssignBranches() {
    return true;
  }

  get canAssignApplications() {
    return true;
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }
}
