import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppTranslationService } from './app-translation.service';
import { ThemeManager } from './theme-manager';
import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-Keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';

interface UserConfiguration {
  loadCache: boolean;
  wappEnabled: boolean;
  menuExpanded: boolean;
  language: string;
  homeUrl: string;
  themeId: number;
  showDashboardStatistics: boolean;
  showDashboardNotifications: boolean;
  showDashboardTodo: boolean;
  showDashboardBanner: boolean;
  applicationName: string;
  branchCode: string;
  defaultPrinter: string;
}

@Injectable()
export class ConfigurationService {

  constructor(
    private localStorage: LocalStoreManager,
    private translationService: AppTranslationService,
    private themeManager: ThemeManager) {

    this.loadLocalChanges();
  }

  set language(value: string) {
    this._language = value;
    this.saveToLocalStore(value, DBkeys.LANGUAGE);
    this.translationService.changeLanguage(value);
  }
  get language() {
    return this._language || ConfigurationService.defaultLanguage;
  }


  set themeId(value: number) {
    value = +value;
    this._themeId = value;
    this.saveToLocalStore(value, DBkeys.THEME_ID);
    this.themeManager.installTheme(this.themeManager.getThemeByID(value));
  }
  get themeId() {
    return this._themeId || ConfigurationService.defaultThemeId;
  }


  set homeUrl(value: string) {
    this._homeUrl = value;
    this.saveToLocalStore(value, DBkeys.HOME_URL);
  }
  get homeUrl() {
    return this._homeUrl || ConfigurationService.defaultHomeUrl;
  }

  set loadCache(value: boolean) {
    this._loadCache = value;
    this.saveToLocalStore(value, DBkeys.LOAD_CACHE);
  }
  get loadCache() {
    return this._loadCache != null ? this._loadCache :  ConfigurationService.defaultLoadCache;
  }

  set wappEnabled(value: boolean) {
    this._wappEnabled = value;
    this.saveToLocalStore(value, DBkeys.WAPP_ENABLED);
  }
  get wappEnabled() {
    return this._wappEnabled != null ? this._wappEnabled : ConfigurationService.defaultWappEnabled;
  }


  set menuExpanded(value: boolean) {
    this._menuExpanded = value;
    this.saveToLocalStore(value, DBkeys.MENU_EXPANDED);
  }
  get menuExpanded() {
    return this._menuExpanded != null ? this._menuExpanded : ConfigurationService.defaultMenuExpanded;
  }


  set defaultPrinter(value: string) {
    this._defaultPrinter = value;
    this.saveToLocalStore(value, DBkeys.DEFAULT_PRINTER);
  }
  get defaultPrinter() {
    return this._defaultPrinter || ConfigurationService.defaultPrinter;
  }

  set showDashboardStatistics(value: boolean) {
    this._showDashboardStatistics = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_STATISTICS);
  }
  get showDashboardStatistics() {
    return this._showDashboardStatistics != null ? this._showDashboardStatistics : ConfigurationService.defaultShowDashboardStatistics;
  }


  set showDashboardNotifications(value: boolean) {
    this._showDashboardNotifications = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
  }
  get showDashboardNotifications() {
    return this._showDashboardNotifications != null ? this._showDashboardNotifications : ConfigurationService.defaultShowDashboardNotifications;
  }


  set showDashboardTodo(value: boolean) {
    this._showDashboardTodo = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_TODO);
  }
  get showDashboardTodo() {
    return this._showDashboardTodo != null ? this._showDashboardTodo : ConfigurationService.defaultShowDashboardTodo;
  }


  set showDashboardBanner(value: boolean) {
    this._showDashboardBanner = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_BANNER);
  }
  get showDashboardBanner() {
    return this._showDashboardBanner != null ? this._showDashboardBanner : ConfigurationService.defaultShowDashboardBanner;
  }


  set applicationName(value: string) {
    this._applicationName = value;
    this.saveToLocalStore(value, DBkeys.APPLICATION_NAME);
  }
  get applicationName() {
    return this._applicationName || ConfigurationService.defaultApplicationName;
  }


  set branchCode(value: string) {
    this._branchCode = value;
    this.saveToLocalStore(value, DBkeys.BRANCH_CODE);
  }
  get branchCode() {
    return this._branchCode || ConfigurationService.defaultBranchCode;
  }

  public static readonly appVersion: string = '2.7.2';

  // ***Specify default configurations here***
  public static readonly defaultLanguage: string = 'en';
  public static readonly defaultHomeUrl: string = '/';
  public static readonly defaultThemeId: number = 15;
  public static readonly defaultShowDashboardStatistics: boolean = false;
  public static readonly defaultShowDashboardNotifications: boolean = false;
  public static readonly defaultShowDashboardTodo: boolean = false;
  public static readonly defaultShowDashboardBanner: boolean = false;
  public static readonly defaultApplicationName: string = 'FowkesOnline';
  public static readonly defaultBranchCode: string = 'CT';
  public static readonly defaultLoadCache: boolean = true;
  public static readonly defaultWappEnabled: boolean = false;
  public static readonly defaultMenuExpanded: boolean = true;
  public static readonly defaultPrinter: string = '';

  public baseUrl = environment.baseUrl || Utilities.baseUrl();
  public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
  public loginUrl = environment.loginUrl;
  public fallbackBaseUrl = 'https://wwww.fowkes.co.za';
  // ***End of defaults***

  private _language: string = null;
  private _homeUrl: string = null;
  private _themeId: number = null;
  private _showDashboardStatistics: boolean = null;
  private _showDashboardNotifications: boolean = null;
  private _showDashboardTodo: boolean = null;
  private _showDashboardBanner: boolean = null;
  private _applicationName: string = null;
  private _branchCode: string = null;
  private _loadCache: boolean = null;
  private _wappEnabled: boolean = null;
  private _menuExpanded: boolean = null;
  private _defaultPrinter: string = null;

  private onConfigurationImported: Subject<boolean> = new Subject<boolean>();
  configurationImported$ = this.onConfigurationImported.asObservable();



  private loadLocalChanges() {

    if (this.localStorage.exists(DBkeys.LANGUAGE)) {
      this._language = this.localStorage.getDataObject<string>(DBkeys.LANGUAGE);
      this.translationService.changeLanguage(this._language);
    } else {
      this.resetLanguage();
    }


    if (this.localStorage.exists(DBkeys.THEME_ID)) {
      this._themeId = this.localStorage.getDataObject<number>(DBkeys.THEME_ID);
      this.themeManager.installTheme(this.themeManager.getThemeByID(this._themeId));
    } else {
      this.resetTheme();
    }


    if (this.localStorage.exists(DBkeys.HOME_URL)) {
      this._homeUrl = this.localStorage.getDataObject<string>(DBkeys.HOME_URL);
    }

    if (this.localStorage.exists(DBkeys.LOAD_CACHE)) {
      this._loadCache = this.localStorage.getDataObject<boolean>(DBkeys.LOAD_CACHE);
    }


    if (this.localStorage.exists(DBkeys.WAPP_ENABLED)) {
      this._wappEnabled = this.localStorage.getDataObject<boolean>(DBkeys.WAPP_ENABLED);
    }

    if (this.localStorage.exists(DBkeys.DEFAULT_PRINTER)) {
      this._defaultPrinter = this.localStorage.getDataObject<string>(DBkeys.DEFAULT_PRINTER);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_STATISTICS)) {
      this._showDashboardStatistics = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_STATISTICS);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS)) {
      this._showDashboardNotifications = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_TODO)) {
      this._showDashboardTodo = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_TODO);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_BANNER)) {
      this._showDashboardBanner = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_BANNER);
    }

    if (this.localStorage.exists(DBkeys.APPLICATION_NAME)) {
      this._applicationName = this.localStorage.getDataObject<string>(DBkeys.APPLICATION_NAME);
    }

    if (this.localStorage.exists(DBkeys.BRANCH_CODE)) {
      this._branchCode = this.localStorage.getDataObject<string>(DBkeys.BRANCH_CODE);
    }

  }


  private saveToLocalStore(data: any, key: string) {
    setTimeout(() => this.localStorage.savePermanentData(data, key));
  }


  public import(jsonValue: string) {

    this.clearLocalChanges();

    if (jsonValue) {
      const importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

      if (importValue.language != null) {
        this.language = importValue.language;
      }

      //if (importValue.themeId != null) {
      //  this.themeId = +importValue.themeId;
      //}

      if (importValue.themeId != null) {
        this.themeId = +importValue.themeId;
      }

      if (importValue.homeUrl != null) {
        this.homeUrl = importValue.homeUrl;
      }

      if (importValue.defaultPrinter != null) {
        this.defaultPrinter = importValue.defaultPrinter;
      }

      if (importValue.showDashboardStatistics != null) {
        this.showDashboardStatistics = importValue.showDashboardStatistics;
      }

      if (importValue.showDashboardNotifications != null) {
        this.showDashboardNotifications = importValue.showDashboardNotifications;
      }

      if (importValue.showDashboardTodo != null) {
        this.showDashboardTodo = importValue.showDashboardTodo;
      }

      if (importValue.showDashboardBanner != null) {
        this.showDashboardBanner = importValue.showDashboardBanner;
      }

      if (importValue.applicationName != null) {
        this.applicationName = importValue.applicationName;
      }
      
      if (importValue.branchCode != null) {
        this.branchCode = importValue.branchCode;
      }
      if (importValue.loadCache != null) {
        this.loadCache = importValue.loadCache;
      }

      if (importValue.wappEnabled != null) {
        this._wappEnabled = importValue.wappEnabled;
      }

      if (importValue.menuExpanded != null) {
        this.menuExpanded = importValue.menuExpanded;
      }
    }

    this.onConfigurationImported.next();
  }


  public export(changesOnly = true): string {

    const exportValue: UserConfiguration = {
      language: changesOnly ? this._language : this.language,
      themeId: changesOnly ? this._themeId : this.themeId,
      homeUrl: changesOnly ? this._homeUrl : this.homeUrl,
      showDashboardStatistics: changesOnly ? this._showDashboardStatistics : this.showDashboardStatistics,
      showDashboardNotifications: changesOnly ? this._showDashboardNotifications : this.showDashboardNotifications,
      showDashboardTodo: changesOnly ? this._showDashboardTodo : this.showDashboardTodo,
      showDashboardBanner: changesOnly ? this._showDashboardBanner : this.showDashboardBanner,
      applicationName: changesOnly ? this._applicationName : this.applicationName,
      branchCode: changesOnly ? this._branchCode : this.branchCode,
      loadCache: changesOnly ? this._loadCache : this.loadCache,
      wappEnabled: changesOnly ? this._wappEnabled : this.wappEnabled,
      menuExpanded: changesOnly ? this._menuExpanded : this.menuExpanded,
      defaultPrinter: changesOnly ? this._defaultPrinter : this.defaultPrinter
    };

    return JSON.stringify(exportValue);
  }


  public clearLocalChanges() {
    this._language = null;
    this._themeId = null;
    this._homeUrl = null;
    this._showDashboardStatistics = null;
    this._showDashboardNotifications = null;
    this._showDashboardTodo = null;
    this._showDashboardBanner = null;
    this._applicationName = null;
    this._branchCode = null;
    this._loadCache = null;
    this._wappEnabled = null;
    this._menuExpanded = null;
    this._defaultPrinter = null;

    this.localStorage.deleteData(DBkeys.LANGUAGE);
    this.localStorage.deleteData(DBkeys.THEME_ID);
    this.localStorage.deleteData(DBkeys.HOME_URL);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_STATISTICS);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_TODO);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_BANNER);
    this.localStorage.deleteData(DBkeys.APPLICATION_NAME);
    this.localStorage.deleteData(DBkeys.BRANCH_CODE);
    this.localStorage.deleteData(DBkeys.LOAD_CACHE);
    this.localStorage.deleteData(DBkeys.WAPP_ENABLED);
    this.localStorage.deleteData(DBkeys.MENU_EXPANDED);
    this.localStorage.deleteData(DBkeys.DEFAULT_PRINTER);

    this.resetLanguage();
    this.resetTheme();
  }


  private resetLanguage() {
    const language = this.translationService.useBrowserLanguage();

    if (language) {
      this._language = language;
    } else {
      this._language = this.translationService.useDefaultLangage();
    }
  }

  private resetTheme() {
    this.themeManager.installTheme();
    this._themeId = null;
  }
}
