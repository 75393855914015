import * as moment from "moment";
import { ValueHelper } from "./valuehelper.model";

export class BankImport {
  
  constructor() {

  }

  public id: number;
  public company: string;
  public customer: string;
  public customerName: string;
  public customerMaster: string;
  public paymentValue: number;
  public bankReference: string;
  public sysproAllocation: string;
  public journalNotation: string;
  public paymentDate: Date;
  public paymentNarration: string;
  public bank: string;
  public tender: string;
  public paymentType: string;
  public paymentPeriod: string;
  public periodEndDate: Date;
  public insertDate: Date;
  public status: string;
  public message: string;
  public fileName: string;
  public trnYear: string;
  public trnMonth: string;
  public journal: string;
  public customerAccTerms: string;
  public customerApplyAutoPayments: string;
  public batchId: string;
  public processCustomer: string;
  public userName: string;
}
export class BankImportHeader {
  id: number; // Int64 in C#
  company: string; // varchar(50)
  importDate: Date; // datetime
  importStatus: string; // varchar(50)
  userName: string; // varchar(50)
  batchId: string; // varchar(50)
  paymentPeriod: string; // varchar(50)
  fileName: string; // varchar(150)
  fileReference: string; // varchar(150)
  numberOfRecords: number; // int
  totalPaymentValue: number; // decimal(18, 2)
  hasWarnings: boolean; // bit
  postedDate?: Date; // datetime (nullable)
  postedStatus?: string; // varchar(50) (nullable)
  postedCount?: number; // int (nullable)
  postedValue?: number; // decimal(18, 2) (nullable)
  responseDate?: Date; // datetime (nullable)
  responseStatus?: string; // varchar(50) (nullable)
  responseCount?: number; // int (nullable)
  responseValue?: number; // decimal(18, 2) (nullable)
  notes: string; // varchar(MAX)
  timeSinceImport: string;

  constructor() {
    this.id = 0;
    this.company = '';
    this.importDate = new Date();
    this.importStatus = '';
    this.userName = '';
    this.batchId = '';
    this.paymentPeriod = '';
    this.fileName = '';
    this.numberOfRecords = 0;
    this.totalPaymentValue = 0;
    this.hasWarnings = false;
    this.notes = '';
  }
}

