import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { ReportEndpoint } from './report-endpoint.service';
import { ArCustomer } from '../models/arcustomer.model';
import { Stream } from 'stream';

@Injectable()
export class ReportService {

  constructor(
    private authService: AuthService,
    private reportEndpoint:ReportEndpoint) {

  }

  get currentUser() {
    return this.authService.currentUser;
  }


  getFileStream(quoteNumber?: string) {
    return this.reportEndpoint.getFileStreamEndpoint<Blob>(quoteNumber);
  }

  getFileStreamInventory(stockCode?: string) {
    return this.reportEndpoint.getFileStreamInventoryEndpoint<Blob>(stockCode);
  }


  getFileStreamStatement(OTP?: string) {
    return this.reportEndpoint.getFileStreamStatementEndpoint<Blob>(OTP);
  } 


  getQuotePDF(quoteNumber?: string, application?: string) {
    return this.reportEndpoint.getQuotePDFEndpoint<Blob>(quoteNumber, application);
  }


  getInventoryPDF(stockCode?: string, branchCode?: string, application?: string) {
    return this.reportEndpoint.getInventoryPDFEndpoint<Blob>(stockCode, branchCode, application);
  }



  getStatementPDF(customer?: string, application?: string, fromDate?: string, toDate?: string, uniqueCode?: string) {
    return this.reportEndpoint.getStatementPDFEndpoint<Blob>(customer, application, fromDate, toDate, uniqueCode);
  }



  getStatementInvoicePDF(customer?: string, application?: string, salesOrder?: string, invoice?: string, uniqueCode?: string) {
    return this.reportEndpoint.getStatementInvoicePDFEndpoint<Blob>(customer, application, salesOrder, invoice, uniqueCode);
  }


  getStatementCreditPDF(customer?: string, application?: string, salesOrder?: string, invoice?: string, uniqueCode?: string) {
    return this.reportEndpoint.getStatementCreditPDFEndpoint<Blob>(customer, application, salesOrder, invoice, uniqueCode);
  }


  getCataloguePDF(branchCode?: string, application?: string, reportTitle?: string, reportType?: string, filterValue?: string, includeIndex?: boolean, extraFilter?: string) {
    return this.reportEndpoint.getCataloguePDFEndpoint<Blob>(branchCode, application, reportTitle, reportType, filterValue, includeIndex, extraFilter);
  }

  getFileStreamCatalogue(reportName?: string) {
    return this.reportEndpoint.getFileStreamCatalogueEndpoint<Blob>(reportName);
  } 


  getPurchaseOrderPDF(PoNumber: string, internalRef: boolean, application?: string) {
    return this.reportEndpoint.getPurchaseOrderPDFEndpoint<Blob>(PoNumber, internalRef, application);
  }


  getFileStreamPurchaseOrder(PoNumber?: string) {
    return this.reportEndpoint.getFileStreamPurchaseOrderEndpoint<Blob>(PoNumber);
  } 

}
