import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { User } from '../../models/user.model';
import { ArCustomer } from '../../models/arcustomer.model';
import { ArNarration } from '../../models/arnarration.model';
import { Permission } from '../../models/permission.model';
import { SysproService } from '../../services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { forEach } from '@angular/router/src/utils/collection';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { filter } from 'rxjs/operators';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'syspro-customer-notes',
  templateUrl: './syspro-customer-notes.component.html',
  styleUrls: ['./syspro-customer-notes.component.scss']
})
export class SysproCustomerNotesComponent implements OnInit {


  @ViewChild('table') table: DatatableComponent;
  rows: ArNarration[] = [];
  rowsCache: ArNarration[] = [];
  loadingIndicator: boolean;

  scrollbarV = false;
  bodyHeight = 'auto';

  editing = {};

  private isEditMode = false;
  private isSaving = false;
  private showValidationErrors = false;
  private customerNotes: ArNarration[] = [];
  
  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;


  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;
  
  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();


  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";
  
  @ViewChild('f')
  private form;
    columns: ({ prop: string; name: string; width: number; canAutoResize: boolean; } | { prop: string; name: string; width: number; canAutoResize?: undefined; })[];
    id: string;
  noteLines: ArNarration[] = [];
  notelinenumber: any;

  newRow: ArNarration = new ArNarration();
    hasDetailRows: boolean;
    lastRow: number;
    rowsSaved: any;
  rowsToSave: ArNarration[] = [];
 
  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService, private sysproService: SysproService, private router: Router) {
  }

  ngOnInit() {


    this.loadCurrentUserData();


  }

  private loadCurrentUserData() {

    this.columns = [
      { prop: 'line', name: 'Line', width: 50, canAutoResize: false },
      { prop: 'notation', name: 'Notation', width: 250, canAutoResize: true }
    ];

    let action = sessionStorage.getItem(localStorageActions.Customer);

    this.searchType = "customer";
    if (action && action.length >= 4) {
      this.id = action;
      this.currentCustomer = this.id;
      this.searchText = this.id;
      if (!this.isViewOnly) {

        this.alertService.startLoadingMessage();

        this.sysproService.getCustomerDetail(this.id).subscribe(
          x => this.onSpecificSearchSuccesful([x]), error => this.onCurrentUserDataLoadFailed(error));
      }
      else {
        this.sysproService.getCustomerNotes(this.id).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));
      }


    }

  }

    onCustomerLoadSuccessful(x: ArCustomer[]): void {
      this.customerSearch = x;
      this.customerHeading = this.customerSearch.find(x => x.customer == this.currentCustomer);
      setTimeout(() => {
        if (this.customerSelector) {
          this.customerSelector.refresh();
        }
      });
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rows = null;
    this.rowsCache = null;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }
    

  }

  private loadCustomer() {
    this.id = this.searchResultSelected.customer;
    this.currentCustomer = this.id;
    sessionStorage.removeItem(localStorageActions.Customer);
    sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.customerHeading = this.searchResultSelected;
    this.sysproService.getCustomerNotes(this.currentCustomer).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: this.currentCustomer } });
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }
  
  private onNotesLoadSuccessful(notes: any[]) {
   
    if (notes[0]) {
      console.log("LOADING THE NOTES");
      this.noteLines = notes[0];
      this.notelinenumber = notes[0].length + 1;
      this.newRow.line = this.notelinenumber + 1;

      this.rows = this.noteLines;
      this.hasDetailRows = true;

      if (!this.isViewOnly) {
        for (var i = 0; i < 5; i++) {
          let newLine = new ArNarration();
          newLine.line = this.notelinenumber + i;
          newLine.customer = this.currentCustomer;
          newLine.notation = "";
          newLine.existingNote = "F";
          this.rows.push(newLine);
        }
      }

      this.rowsCache = [...this.rows];

      console.log("THESE ARE THE NOTES", this.rows);
    }
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    }


  setDelete(row: ArNarration) {
    console.log(row);
    this.rowsToSave = null;
    row.notation = '                                                            ';
   // this.sysproService.deleteCustomerNotes(this.id, row.line).subscribe(_response => this.clearNotesSuccessHelper(), error => this.saveFailedHelper(error));

  }


  setSave(row: ArNarration) {
    console.log(row);
    this.rowsToSave = null;
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    if (row.notation.trim() == "") {
      row.notation = '                                                            ';
    }
    this.sysproService.insertCustomerNotes(this.currentCustomer, row.line, row.notation.replace(/\%/g, 'zzzz').replace(/\//g, '--').replace(/\+/g, '___')).subscribe(_response => this.saveLinesSuccessHelper(), error => this.saveFailedHelper(error));

  }

  reload() {
    this.alertService.startLoadingMessage("...Reloading");
    this.loadingIndicator = true;
    this.sysproService.getCustomerNotes(this.currentCustomer).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));

  }

  addMoreLines() {
    this.alertService.startLoadingMessage("...Adding lines");
    this.loadingIndicator = true;
    this.notelinenumber = this.rows.length + 1;
    for (var i = 0; i < 5; i++) {
      let newLine = new ArNarration();
      newLine.line = this.notelinenumber + i;
      newLine.customer = this.currentCustomer;
      newLine.notation = "";
      newLine.existingNote = "F";
      this.rows.push(newLine);
    }
    this.rowsCache = [...this.rows];
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }



  save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    // Find last row with a note in
    this.rowsToSave = this.rows;
    this.lastRow = this.rowsToSave.length;
    this.clearNotesSuccessHelper();
    //First Delete all rows..
   // this.sysproService.deleteCustomerNotes(this.currentCustomer, 0).subscribe(_response => this.clearNotesSuccessHelper(), error => this.saveFailedHelper(error));
   
  }

  clearNotesSuccessHelper(): void {
    let index = 0;
    //Update header.. could possibly just skip
    if (this.rowsToSave) {
      this.rowsSaved = 0;
      //Insert all rows up to last row
      for (var line of this.rowsToSave) {
        index++;
        if (index <= this.lastRow) {
          if (line.notation.trim() == "") {
            line.notation = '                                                            ';
          }
          this.sysproService.insertCustomerNotes(this.currentCustomer, index, line.notation.replace(/\%/g, 'zzzz').replace(/\//g, '--').replace(/\+/g, '___')).subscribe(_response => this.saveLinesSuccessHelper(), error => this.saveFailedHelper(error));
        }
      }
    }
    else {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Deleted successfully`, MessageSeverity.success);

      this.reload();
    }
  }
  saveLinesSuccessHelper(): void {
    this.rowsSaved++;

    if (this.rowsSaved == this.lastRow) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
      this.reload();
    }
    if (!this.rowsToSave) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();

      this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    }

  }



  GenerateUniqueNoteLineNumber() {
    if (this.rowsCache.length > 0) {
      const maxId = this.rowsCache.reduce(
        (max, character) => (character.line > max ? character.line : max),
        this.rowsCache[0].line
      );
      return maxId;
    }
    else {
      return 1;
    }
  }


  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  updateValue(event, cell, rowIndex) {
    console.log(event);
    console.log('inline editing rowIndex', rowIndex)

    var val = event.target.value;

    this.rows[rowIndex][cell] = val;
    this.rows = [...this.rows];
    console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  split(data, rowIndex) {
    rowIndex += 1;
    // Copy the data
    let rowData = { ...data };

    // With updation of values 
    rowData.notation = '';
    this.rows.splice(rowIndex, 0, rowData);
    this.rows = [...this.rows]
  }


  remove(data, rowIndex) {
    // Copy the data
    this.rows.splice(rowIndex, 1);
    this.rows = [...this.rows]
  }


  setStyles() {
    return {
      'height': (this.isViewOnly ? "350px" : "")
        //this.bodyHeight
    };
  }

  private edit() {  

    
  }
  


  private saveSuccessHelper() {    

    this.isEditMode = false;


    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }
  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }
   
   
  private cancel() {
    
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
   
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }


  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }
}
