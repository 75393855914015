import { Component, ViewChild, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'purchaseorders',
  templateUrl: './purchaseorders.component.html',
  styleUrls: ['./purchaseorders.component.scss'],
  animations: [fadeInOut]
})
export class PurchaseOrdersComponent {

  isListActivated = true;
  isViewActivated = false;
  isInvoiceViewActivated = false;
  isPurchaseOrderViewActivated = false;
  isCartViewActivated = false;

  isCollapsed = false;


  fragmentSubscription: any;

  readonly purchaseordersListTab = 'purchaseorders';
  readonly salesordersViewTab = 'view';
  readonly invoiceViewTab = 'invoice';
  readonly purchaseorderTab = 'purchaseorder';
  readonly cartViewTab = 'cart';

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  isSearch: boolean;

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  id: any = "";
    cartId: string;
  salesorderId: string;
  invoiceNumber: string;
  purchaseorder: string;

  constructor(private router: Router, private route: ActivatedRoute, private configurations: ConfigurationService) {
  }


  ngOnInit() {
       
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let salesorderId = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
    let invoiceNumber = sessionStorage.getItem(localStorageActions.InvoiceNumber);
    let purchaseorder = sessionStorage.getItem(localStorageActions.PurchaseOrder);
    this.salesorderId = salesorderId;
    this.invoiceNumber = invoiceNumber;
    this.purchaseorder = purchaseorder;

    this.isCollapsed = !this.configurations.menuExpanded;
    if (action) {
      this.id = action;
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
    } else {

      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
     //this.router.navigate(['purchaseorders']);
    }

  }

  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  showContent(anchor: string, test?: any) {

    this.id = sessionStorage.getItem(localStorageActions.Customer);
    this.salesorderId = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
    this.invoiceNumber = sessionStorage.getItem(localStorageActions.InvoiceNumber);
    this.purchaseorder = sessionStorage.getItem(localStorageActions.PurchaseOrder);
   
    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.purchaseordersListTab}Tab`);

  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.purchaseordersListTab;
    this.isViewActivated = activeTab == this.salesordersViewTab;
    this.isInvoiceViewActivated = activeTab == this.invoiceViewTab;
    this.isPurchaseOrderViewActivated = activeTab == this.purchaseorderTab;
    this.isCartViewActivated = activeTab == this.cartViewTab;

    this.router.navigate([], { fragment: activeTab, queryParams: { customer: this.id, salesorderId: this.salesorderId } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.purchaseordersListTab;
    this.isViewActivated = activeTab == this.salesordersViewTab;
    this.isInvoiceViewActivated = activeTab == this.invoiceViewTab;
    this.isPurchaseOrderViewActivated = activeTab == this.purchaseorderTab;
    this.isCartViewActivated = activeTab == this.cartViewTab;

    this.router.navigate([], { fragment: activeTab });
  }

  get canViewCustomers() {
    return true;
  }

  get canViewMovements() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerMovementsPermission);
  }

  get canViewPayments() {
    return true;//TODO: this.accountService.userHasPermission(Permission.viewCustomerPaymentsPermission);
  }
}
