
export class CoreCustomer {
  constructor(customerCode?: string, company?: string, enabled?: boolean) {

    
    this.customerCode = customerCode;
    this.company = company;
    this.enabled = enabled;
  
  }

  public id: number;
  public customerCode: string;
  public company: string
  public enabled: boolean;


  public createdBy: string;
  public modifiedBy: string;

  public createdOn: Date;
  public modifiedOn: Date;
  public staffAccount: boolean;
  
}

