
export class POExtended {
  constructor(poNumber?: string, internalReference?: string, salesTotal?: number, vatTotal?: number, reportTotal?: number) {

    this.PoNumber = poNumber;
    this.InternalReference = internalReference;
    this.SalesTotal = salesTotal;
    this.VatTotal = vatTotal;
    this.ReportTotal = reportTotal;
  }

  public PoNumber: string
  public InternalReference: string
  public SalesTotal: number
  public VatTotal: number
  public ReportTotal: number

}

