import { Component, ViewChild, Input, TemplateRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
import { isNil, remove, reverse } from 'lodash';
import {
  TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem } from 'ngx-treeview';
import { DocumentManagmement } from '../../../models/document-management.model';
import { SimpleTree } from '../../../models/simple-tree.model';
import { CMCategories } from '../../../models/cms-category.model';
import { DocumentCategory } from '../../../models/document-category.model';

@Component({
  selector: 'Edit-Categories',
  templateUrl: './document-editcategories.component.html',
  styleUrls: ['./document-editcategories.component.scss'],
  animations: [fadeInOut]
})
export class EditCategoriesComponent {

  items: TreeviewItem[];
  rows: string[];
  item: any;
  data: DocumentManagmement;
  categoryData: DocumentCategory[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild('itemTemplate')
  itemTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate')
  headerTemplate: TemplateRef<any>;

  loadingIndicator: boolean;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('categoryListSelector')
  private categoryListSelector;
  private selectUndefinedOptionValue: string = "Please Select";
  categorySelected: string = "";
  categoryList: DocumentCategory[] = [];
  categoryListChangeParent: DocumentCategory[] = [];
  changeParent: boolean = false;


  @ViewChild('fileListSelector')
  private fileListSelector;
  fileCategorySelected: string = "";
  fileListChangeParent: DocumentCategory[] = [];
  changeFileParent: boolean = false;

  @ViewChild('categoryModal')
  categoryModal: ModalDirective;

  @ViewChild('attachmentModal')
  attachmentModal: ModalDirective;

  @ViewChild('myFileInput')
  myFileInput: ElementRef;

  categoryDetail: DocumentCategory = new DocumentCategory();
  rowsCache: TreeviewItem[];
  headerTemplateContext: { config: TreeviewConfig; item: any};
  modalHeader: string;
    isSaving: boolean;
    compareFolderName: any;
  documentDetail: DocumentManagmement = new DocumentManagmement();

  newformData = new FormData();
  compareFileName: string;
  repoDocsList: DocumentManagmement[] = [];


  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private accountService: AccountService) {
  }


  ngOnInit() {

    this.loadData();

  }

  ngOnDestroy() {

  }

  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getDocumentCatergories(this.expandIndex).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    this.sysproService.GetAllDocumentCategoriesList().subscribe(x => this.onCategoriesLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getAllRepoDocs().subscribe(x => this.onDocsLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }
   
  onDataLoadSuccessful(treeview: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
       
    let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

    this.items = this.getItems([treeviewItems]);
    this.rowsCache = [...this.items];

    console.log("CACHE", this.rowsCache);
  }

  onCategoriesLoadSuccessful(results: DocumentCategory[]) {

    this.categoryList = results;
    this.categoryListChangeParent = this.categoryList.filter(x => x.enabled);
    this.categorySelected = "";
    console.log("LIST", this.categoryList, this.categorySelected );
  }

  onDocsLoadSuccessful(results: DocumentManagmement[]) {

    this.repoDocsList = results;
    this.categorySelected = "";

    let mappedList = this.repoDocsList.map(docCat => { return docCat.categoryName });

    this.categoryListChangeParent = this.categoryList.filter(x => !mappedList.includes(x.categoryName));
    this.fileListChangeParent = this.categoryList.filter(x => mappedList.includes(x.categoryName));
    console.log("DOCLIST", this.repoDocsList, this.categorySelected);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  selectItem(item: TreeviewItem) {
    if (item.value.document && item.value.document.DocumentName) {
      if (item.value.document.DocumentName.indexOf("xls") >= 0 || item.value.document.DocumentName.indexOf(".doc") >= 0) {
        window.open("/MediaServer/DocumentRepositoryDocuments/" + item.value.document.DocumentName, "_blank");
      }
      else {
        window.open(item.value.document.FilePath + "/" + item.value.document.DocumentName, "_blank");
      }
    }
  }

  treeBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
        this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
      }
    }
  }

  changeCategoryParent() {
    this.changeParent = !this.changeParent;
  }

  openChangeFileParent() {
    this.changeFileParent = !this.changeFileParent;
  }

  enableEditMethod(row: TreeviewItem) {
    this.enableEdit = true;
    console.log(row.value, row);
    this.modalHeader = "Edit Folder";
    this.enableEditIndex = row.value.index;

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);

    this.categoryDetail.displayName = "Edit Folder Name 	:"

    this.categoryDetail.parentCategoryName = row.value.category.ParentCategoryName;

    this.categoryDetail.categoryName = row.value.category.CategoryName;

    this.categoryDetail.responsibility = row.value.category.Responsibility;

    this.categoryDetail.enabled = row.value.category.Enabled;

    this.categoryDetail.id = row.value.category.Id;

    this.compareFolderName = this.categoryDetail.categoryName;
    this.categorySelected = this.categoryDetail.parentCategoryName;

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }

  addNewFolder(row: TreeviewItem) {
    this.modalHeader = "Add new Folder";
    this.enableEditIndex = row.value.index;

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);

    this.categoryDetail.displayName = "New Folder Name 	:"
    this.categoryDetail.parentCategoryName = row.text;
    this.categoryDetail.enabled = true;
    this.categoryDetail.createdBy = this.accountService.currentUser.email;
    this.categoryDetail.responsibility = "";

    this.categorySelected = row.text;

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }

  categoryModalHide() {
    this.categoryDetail = new DocumentCategory();
    this.changeParent = false;
    this.categoryModal.hide();
  }

  saveNewDocumentFolder() {

    //Validate save
    if (this.categoryDetail) {
      if (this.categoryDetail.categoryName) {
        if (this.changeParent && this.categorySelected != "") {
          this.categoryDetail.parentCategoryName = this.categorySelected;
        }
        if (this.categoryDetail.id > 0) {
          if (this.compareFolderName && this.compareFolderName.toUpperCase() != this.categoryDetail.categoryName.toUpperCase()) {
            if (this.validateCategorySave()) {
              if (this.categoryDetail.parentCategoryName == "Categories") {
                this.categoryDetail.parentCategoryName = "Root";
              }
              this.sysproService.editDocumentCategory(this.categoryDetail, this.categoryDetail.id).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));

            }
          }
          else {
            this.sysproService.editDocumentCategory(this.categoryDetail, this.categoryDetail.id).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));
          }
        }
        else {
          if (this.validateCategorySave()) {
            if (this.categoryDetail.parentCategoryName == "Categories") {
              this.categoryDetail.parentCategoryName = "Root";
            }
            this.sysproService.addDocumentCategory(this.categoryDetail).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));
          }
        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a document folder name`, MessageSeverity.warn);
      }
    }
  }

  validateCategorySave() {
    let error = "";

    error = (this.categoryList.filter(x => x.categoryName.toUpperCase() == this.categoryDetail.categoryName.toUpperCase()).length > 0 ? "This folder name has already been added" : "");

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  validateFileSave() {
    let error = "";

    error = (this.repoDocsList.filter(x => x.documentName.toUpperCase() == this.documentDetail.documentName.toUpperCase()).length > 0 ? "This file has already been added" : "");

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  uploadFile(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
    this.expandIndex = this.breadcrumbs[this.breadcrumbs.length - 1].categoryId;

    this.enableEditIndex = row.value.index;

    this.documentDetail.description = "";
    this.documentDetail.categoryName = row.text;
    this.documentDetail.enabled = true;
    this.documentDetail.createdBy = this.accountService.currentUser.email;
    this.documentDetail.responsibility = "";

    if (!this.attachmentModal) {
      setTimeout(() => {
        if (this.attachmentModal) {
          this.attachmentModal.show();
        }
      });
    }
    else {
      this.attachmentModal.show();
    }
  }


  editFile(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
    this.expandIndex = this.breadcrumbs[this.breadcrumbs.length - 1].categoryId;

    this.enableEditIndex = row.value.index;

    this.documentDetail.documentName = row.value.document.DocumentName;
    this.documentDetail.description = row.value.document.Description;
    this.documentDetail.categoryName = row.value.document.CategoryName;
    this.documentDetail.enabled = row.value.document.Enabled;
    this.documentDetail.createdBy = row.value.document.CreatedBy;
    this.documentDetail.responsibility = row.value.document.Responsibility;
    this.documentDetail.id = row.value.document.Id;

    this.compareFileName = this.documentDetail.documentName;
    this.fileCategorySelected = this.documentDetail.categoryName;

    if (!this.attachmentModal) {
      setTimeout(() => {
        if (this.attachmentModal) {
          this.attachmentModal.show();
        }
      });
    }
    else {
      this.attachmentModal.show();
    }
  }

  attachmentModallHide() {
    this.documentDetail = new DocumentManagmement();
    this.newformData = new FormData();
    this.myFileInput.nativeElement.value = '';
    this.attachmentModal.hide();
  }


  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newformData.append('file', file);
      this.documentDetail.documentName = file.name;
    }
  }

  saveDocument() {

    //Validate save
    if (this.documentDetail) {
      if (this.documentDetail.documentName) {
        if (this.changeFileParent && this.fileCategorySelected != "") {
          this.documentDetail.categoryName = this.fileCategorySelected;
        }
        if (this.documentDetail.id > 0) {
          if (this.compareFileName && this.compareFileName.toUpperCase() != this.documentDetail.documentName.toUpperCase()) {
            //if (this.validateFileSave()) {
              if (this.newformData.has('file')) {
                this.sysproService.postDocument(this.newformData, "DOC", this.documentDetail.description.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => {
                  this.documentDetail.documentName = x.fileName;
                  this.sysproService.updateRepoDoc(this.documentDetail, this.documentDetail.id).subscribe(x =>
                    this.onNewFolderAdded(new DocumentCategory()), error => this.onNewFolderAddedFailed(error));

                }, error => this.onPostDataLoadFailed(error));
              }
          }
          else {
            this.sysproService.updateRepoDoc(this.documentDetail, this.documentDetail.id).subscribe(x => this.onNewFolderAdded(new DocumentCategory()), error => this.onNewFolderAddedFailed(error));
          }
        }
        else {
         /* if (this.validateFileSave()) {*/
            if (this.newformData.has('file')) {
              this.sysproService.postDocument(this.newformData, "DOC", this.documentDetail.description.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => {
                this.documentDetail.documentName = x.fileName;
                this.sysproService.addNewDoc(this.documentDetail).subscribe(x =>
                  this.onNewFolderAdded(new DocumentCategory()), error => this.onNewFolderAddedFailed(error));

              }, error => this.onPostDataLoadFailed(error));
            }
            else {
              this.alertService.showMessage('Warning', `Remeber to add a file first`, MessageSeverity.warn);
            }
          //}
        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to add a document first`, MessageSeverity.warn);
      }
    }

    
  }

  removeFile(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.value.document.CategoryName).subscribe(result => this.breadcrumbs = [...result]);
    this.expandIndex = this.breadcrumbs[this.breadcrumbs.length - 1].categoryId;

    this.enableEditIndex = row.value.index;

    if (row.value.document) {
      this.sysproService.deleteRepoDoc(row.value.document.Id).subscribe(x => this.onNewFolderAdded(new DocumentCategory()), error => this.onNewFolderAddedFailed(error));
    }

  }


  newFilePostedSuccess(x: any) {
    this.documentDetail.createdBy = this.accountService.currentUser.email;
    this.documentDetail.documentName = x.fileName;
    this.sysproService.addNewDoc(this.documentDetail).subscribe(x => this.onNewFolderAdded(new DocumentCategory()), error => this.onPostDataLoadFailed(error));
  }

    onPostDataLoadFailed(error: any) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    }
   

  onNewFolderAdded(x: DocumentCategory): void {

    if (x && x.id) {

      this.expandIndex = x.id;
    }

    this.loadData();
    // Open to correct level.
    this.categoryModalHide();
    this.attachmentModallHide();
  }


  onNewFolderAddedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

  }

  removeFolder(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
    if (row.value.category) {
      this.sysproService.deleteDocumentCategory(row.value.category.Id).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));
    }

  }


  expandItem(item: TreeviewItem): void {
   
    for (const tmpItem of this.items) {
      if (tmpItem === item) {
        item.collapsed = false;
        let parent = TreeviewHelper.findParent(tmpItem, item);
        parent.collapsed = false;
      }
    }

    this.treeviewComponent.raiseSelectedChange();
  }

  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }


  get canManageDocuments() {
    return this.accountService.userHasPermission(Permission.manageDocumentsPermission);
  }
}
