
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, Pipe, PipeTransform } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CurrencyPipe } from '@angular/common';


import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { forEach } from '@angular/router/src/utils/collection';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Email } from 'src/app/models/email.model';
import { validation } from 'src/app/services/validation.service';
import { Calculations } from 'src/app/services/calculations.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { InventoryAdditional } from '../../models/inventory-additional.model';


@Component({
  selector: 'products-new',
  templateUrl: './products-new.component.html',
  styleUrls: ['./products-new.component.scss']
})

  
export class ProductsNewComponent implements OnInit {
    columns: any[] = [];
    rows: Inventory[] = [];
    rowsCache: Inventory[] = [];
    cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

    @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;


  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('sellpriceTemplate')
  sellpriceTemplate: TemplateRef<any>;

  @ViewChild('unitCostTemplate')
  unitCostTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;


  emailObject: Email = new Email();

  @ViewChild('emailModal')
  emailModal: ModalDirective;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  stockCode: string;

    id: string;
    cartId: string;
    customer: ArCustomer;
    stockCodedetail: Inventory;
    isNavigating: boolean;
    isSaving: boolean;
    cartSaved: boolean;
    cartlinenumber: number = 0;
    saveCount: number;
    whDetail: WHAvailQTY[] = [];
  stockDescription: string;
    isNavigatingTo: string;
    hasDataSheets: boolean;
    hasSpecials: boolean;
    alternateKey: string;
    savedLineCount: number;
    isReloading: boolean;
  src: string;
  searchTerm: string = "";
  chkNew: boolean;
  chkSp: boolean;
  chkAlt: boolean;
  chkDis: any;
  chkUnmapped: boolean;
  chkAll: boolean;
  chkDt1: boolean;
  chkDt2: boolean;


  searchType: string = "";
  searchText: string = "";
  searchResults: Inventory[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  attachDataSheets: boolean;
  attachSpecials: boolean;

  inventoryList: Inventory[];
  searchingOn: string;
  chkShortDescription: boolean;
  chkLongDescription: boolean;
  chkAnyDescription: boolean;
  hasAddDataSheets: boolean;
  hasOrphinRecords: boolean;
  deletedCount: any;
  deletingNumber: number;
    unmappedCount: number;
    buyerResponsible: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService, private reportService: ReportService, 
    private router: Router, private route: ActivatedRoute, private numberPipe: CurrencyPipe) {
  }


  ngOnInit() {

    this.searchType = "unmapped";
    this.SetDataGrid();

    //this.loadData();
  }

  
  private SetDataGrid() {
    
    if (this.searchType == "unmapped") {
      this.columns = [
        { prop: 'stockCode', name: 'StockCode', width: 150, cellTemplate: this.stockCodeTemplate, cellClass: "left" },
        { prop: 'description', name: 'Description', width: 370, cellTemplate: this.descriptionTemplate, cellClass: "left" },
        { prop: 'qtyCT', name: 'QTY CT', width: 50, cellClass: "right" },
        { prop: 'binLocCT', name: 'Bin Location', width: 50, cellClass: "right" },
        { prop: 'qtyCombined', name: 'Qty All Warehouses', width: 50, cellClass: "right" }
      ];

      this.columns.push({ name: 'CMS', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }
    else {
      this.columns = [
        { prop: 'stockCode', name: 'StockCode', width: 150, cellTemplate: this.stockCodeTemplate, cellClass: "left" },
        { prop: 'description', name: 'Description', width: 370, cellTemplate: this.descriptionTemplate, cellClass: "left" },
        { prop: 'uom', name: 'UOM', width: 50, cellClass: "center" },
        { prop: 'warehouse', name: 'WH', width: 50, cellClass: "center" },
        { prop: 'availQty', name: 'Qty On Hand', width: 50, cellClass: "right" },
        { prop: 'binLoc', name: 'Bin', width: 70, cellClass: "right" },
        { prop: 'qtyOnOrder', name: 'Qty On Order', width: 70, cellClass: "right" },
        { prop: 'qtyInTransit', name: 'Qty In Transit', width: 50, cellClass: "right" },
        { prop: 'sellingPrice', name: 'Selling Price', width: 90, cellTemplate: this.priceTemplate, cellClass: "right", pipe: { transform: this.currencyPipe } },
        { prop: 'unitCost', name: 'Unit Cost', width: 90, cellTemplate: this.unitCostTemplate, cellClass: "right", pipe: { transform: this.currencyPipe } }
      ];

      this.columns.push({ name: 'Delete', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }
   

  }

  currencyPipe(value: any, ...args: any[]) {
    let updated = value.toFixed(2);
    updated += '';
    let x = updated.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
    //return value.toFixed(2);
  }

  ngAfterViewInit() {
    this.SetDataGrid();
  }

  reset() {
    this.chkShortDescription = false;
    this.chkLongDescription = false;
    this.chkAnyDescription = false;
    this.chkNew = false;
    this.chkSp = false;
    this.chkAlt = false;
    this.chkDis = false;
    this.chkUnmapped = false;
    this.chkDt1 = false;
    this.chkDt2 = false;
    this.chkAll = false;
    this.searchTerm = "";
    this.searchingOn = "";
    this.rows = [];
    this.rowsCache = [];
    this.SetDataGrid();
    this.loadData();

  }

  loadData() {
    this.specificSearch();

  }

  specificSearch() {
    if (this.searchType) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchingOn = this.searchType;
      if (this.searchType == "unmapped") {
        this.sysproService.getStockCodeAdditionalDetail("ALL").subscribe(
          x => this.onUnmappedSearchSuccesful(x), error => this.onDataLoadFailed(error));
      }
      else {
        this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchType, "ALL").subscribe(
          x => this.onSpecificSearchSuccesful([x]), error => this.onDataLoadFailed(error));
      }
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
    onUnmappedSearchSuccesful(x: any): void {
      this.inventoryList = x;
      if (this.inventoryList && this.inventoryList.length > 0) {
        this.inventoryList.forEach((stockCode, index, inventory) => {
          (<any>stockCode).index = index + 1;
        });

        this.rowsCache = [...this.inventoryList];
        this.rows = [...this.rowsCache];
        this.unmappedCount = this.rows.length;
      }
      else {
        this.rows = null;
        this.rowsCache = null;

        this.alertService.showMessage('No Records', 'Could not find any records for your search.',
          MessageSeverity.warn);
      }

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }


  onSpecificSearchSuccesful(x: any[]): void {

    this.inventoryList = x[0];

    if (this.inventoryList && x.length > 0) {

      this.inventoryList.forEach((stockCode, index, inventory) => {
        (<any>stockCode).index = index + 1;
        stockCode.description = stockCode.shortDescription + ' - ' + stockCode.longDescription;
        (<any>stockCode).orphin = (this.searchType == "deleted");
      });

      this.rowsCache = [...this.inventoryList];
      this.rows = [...this.rowsCache];

      if (this.searchType == "deleted") {
        this.hasOrphinRecords = true;
        this.deletedCount = this.rows.length;
      }
    }
    else {
      this.rows = null;
      this.rowsCache = null;

      if (this.searchType == "deleted") {
        this.alertService.showMessage('In Sync', 'All stock codes have been removed from catalogue - Next Gen is now up to date with SYSPRO',
          MessageSeverity.success);
      }
      else {
        this.alertService.showMessage('No Records', 'Could not find any records for your search.',
          MessageSeverity.warn);

      }
    }


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }


  deleteStockCode(detail: any, event) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.deletingNumber = 1;
    this.sysproService.deleteCMStockCode(detail.id).subscribe(x => this.stockCodeDeleteSuccess(detail.stockCode), error => this.onDataLoadFailed(error));

  }

  stockCodeDeleteSuccess(deletedCode: string): void {

    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', deletedCode + ` Has been deleted successfully`, MessageSeverity.success);
    this.specificSearch();

    //this.deletedCount++;

    //if (this.deletedCount == this.deletingNumber) {

    
    //}
  }

  deleteAllOrphins() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var updateRows = this.rowsCache;
    this.deletingNumber = updateRows.length;
    for (var orphin of updateRows) {
      this.sysproService.deleteCMStockCode(orphin.id).subscribe(x => this.stockCodeDeleteSuccess(orphin.stockCode), error => this.onDataLoadFailed(error));
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }
    

  //onStockLoadSuccessful(inventory: any[], trim?: boolean) {


  //  this.isReloading = false;
  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;

  //  if (this.searchTerm.trim() != "") {
  //    this.onSearchChanged(this.searchTerm);
  //  }
   
  //}


  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
   
    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      console.log(error.status);
      if (error.status == 404) { return;}
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

  filterNew() {
    this.chkNew = !this.chkNew;
    this.filterChanged();
  }

  filterSpecial() {
    this.chkSp = !this.chkSp;
    this.filterChanged();
  }

  filterDatasheet1() {
    this.chkDt1 = !this.chkDt1;
    this.filterChanged();
  }

  filterDatasheet2() {
    this.chkDt2 = !this.chkDt2;
    this.filterChanged();
  }

  filterAlternate() {
    this.chkAlt = !this.chkAlt;
    this.filterChanged();
  }

  filterDisabled() {
    this.chkDis = !this.chkDis;
    this.filterChanged();
  }

  filterUnmapped() {
    this.chkUnmapped = !this.chkUnmapped;
    this.filterChanged();
  }

  filterAll() {
    this.chkAll = !this.chkAll;
    this.filterChanged();
  }

    filterChanged() {
      if (this.chkAll) {
        this.chkAlt = false;
        this.chkSp = false;
        this.chkNew = false;
        this.chkUnmapped = false;
        this.chkDis = false;
        this.chkDt1 = false;
        this.chkDt2 = false;
        this.searchTerm = "";
        this.rows = this.rowsCache;
        return;
      }

      if (this.chkAlt || this.chkSp || this.chkNew || this.chkUnmapped || this.chkDis || this.chkDt1 || this.chkDt2) {
        this.rows = this.rowsCache.filter(m => (this.chkAlt ? m.showAlternate == true : '') ||
          (this.chkSp ? (m.onSpecial == true || m.onSpecialFowkes == true) : '') ||
          (this.chkDt1 ? m.dataSheetImage != "" : '') || (this.chkDt2 ? m.addSheetImage != "" : '') ||
          (this.chkNew ? m.newArrival == true : '') ||
          (this.chkUnmapped ? m.mapped == false : '') || (this.chkDis ? m.status == 'D' : ''));
      }
      else {
        this.rows = this.rowsCache;
      }
      
    }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      var filterValues = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.description, r.stockCode, r.warehouse));    

      this.rows = this._sortByTerm(filterValues, "description", value.toUpperCase());

      if (this.chkAlt || this.chkSp || this.chkNew || this.chkDt1 || this.chkDt2) {
        this.rows = this.rows.filter(m => (this.chkAlt ? m.showAlternate == true : '') ||
          (this.chkSp ? (m.onSpecial == true || m.onSpecialFowkes == true) : '') ||
          (this.chkDt1 ? m.dataSheetImage != "" : '') || (this.chkDt2 ? m.addSheetImage != "" : '') ||
          (this.chkNew ? m.newArrival == true : ''));
      }

      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  _sortByTerm(data, key, term) {
    return data.sort(function (a, b) {
      return a[key].indexOf(term) < b[key].indexOf(term) ? -1 : 1;
    });
  }

  _sortByrank(array, searchKey, props) {
    if (!array || !searchKey || !props) return array;

    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.rankSearch = 0;
      for (var j = 0; j < props.length; j++) {
        var index = obj[props[j]].indexOf(searchKey);
        // i should probably spend time tweaking these arbitrary numbers
        // to find good values that produce the best results, but
        // here's what I have so far...
        obj.rankSearch += (index === -1 ? 15 : index) * ((j + 1) * 8);
      }
    }

    return array;
  }


  results() {
    if(this.searchType == "unmapped")
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "QtyCT", "BinLocCT", "QtyCombined"]
    };

    var filename = "NewInventoryList";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        StockCode: row.stockCode, Description: row.description, QtyCT: row.qtyCT, BinLocCT: row.binLocCT, QtyCombined: row.qtyCombined
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }



  //private refreshAll() {
  //  this.isSaving = false;
  //  this.alertService.stopLoadingMessage();

  //  this.isReloading = true;
  //  this.alertService.startLoadingMessage("Reloading...");
  //  this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x], true), error => this.onDataLoadFailed(error));
  //}

  private saveFailedHelper(error: any) {
    //Check if all the lines have been saved
    if (this.saveCount == this.savedLineCount) {
      
      //Reload the inventory and cart details
      //this.refreshAll();
    }
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  stockCodeModallHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
    this.loadData();
  }

  addStockCode(row: any, event) {
   
    this.stockCodedetail = new Inventory();
    this.stockCodedetail.stockCode = row.stockCode;
    this.stockDescription = row.description;
    this.stockCode = this.stockCodedetail.stockCode;

    if (sessionStorage.getItem(localStorageActions.StockCode) != this.stockCode) {
      sessionStorage.setItem(localStorageActions.StockCode, this.stockCode);
    }

    if (!!this.stockCodedetail.stockCode) {
      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }
    }
  }


  editStockCode(row: Inventory) {
    if (row) {
      if (sessionStorage.getItem(localStorageActions.StockCode) != row.stockCode) {
        sessionStorage.setItem(localStorageActions.StockCode, row.stockCode);
      }
      this.router.navigate(['../../products'], { fragment: 'stockcodes' });
    }    
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey &&  row.mapped) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.alternateKey;
      this.stockCode = this.stockCodedetail.stockCode;
      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }


  openSpecials(specialsImg: string) {
    if (!!specialsImg && specialsImg != "") {
      window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
    }
  }

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
     
    }
  }


  onEmailLoadSuccessful() {
    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasAddDataSheets = false;
    this.hasSpecials = false;

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);
    this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);

    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage.length >= 1 && (this.stockCodedetail.onSpecial || this.stockCodedetail.onSpecialFowkes));

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---").replace(/\%/gi, "---"), this.branchCode, this.application).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));

    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.accountService.currentUser.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();     
    }

    if (this.hasAddDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  titleCase(str) {
    str = str.toLowerCase();
    return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
  }

  viewInventory() {
    console.log("TEST");

  }

  viewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailPDF() {

    console.log(this.emailObject);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.emailObject.AddDoc1 = (this.attachDataSheets && this.hasDataSheets ? this.emailObject.AddDoc1 : "");
    this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");
    this.emailObject.AddDoc3 = (this.attachDataSheets && this.hasAddDataSheets ? this.emailObject.AddDoc3 : "");
    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var stockCode = this.emailObject.Attachment.replace('/', '_').replace('+', '_').replace('%', '_');

    this.src = url;
    // window.open(this.src).print();
    // const blobUrl = window.URL.createObjectURL((test));
    //const iframeDoc = document.getElementsByTagName("iframe")[0].contentWindow;
    // iframeDoc.src = url;



    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = stockCode + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  calcOrderQty(row: Inventory, event) {
    var val = event.target.value;
    row.orderqty = val;

    let total = (row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
  }
  
  calcDisc(row: Inventory, event) {
    var val = event.target.value;
    row.discountpercentage1 = val;

    let total = (row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
  }


  calcPrice(row: Inventory, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = (row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
  }
  
   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }

  get canManageInventory() {
    return this.accountService.userHasPermission(Permission.manageInventoryPermission);
  }
}
