
export enum Gender {
    None,
    Female,
    Male
}

export class localStorageActions {
  public static Customer: string = "Customer";
  public static Branch: string = "Branch";
  public static StockCode: string = "StockCode";
  public static CartId: string = "CartId";
  public static QuoteNumber: string = "QuoteNumber";
  public static Reset: string = "Reset";
  public static SalesOrderNumber: string = "SalesOrderNumber";
  public static InvoiceNumber: string = "InvoiceNumberNumber";
  public static ITSInvoiceNumber: string = "ITSInvoiceNumberNumber";
  public static PurchaseOrder: string = "PurchaseOrder";
  public static PODReference: string = "PODReference";
  public static GroupedValues: string = "GroupedValues";
  public static SearchValue: string = "SearchValue";
}



