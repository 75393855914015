
import { Injectable } from '@angular/core';
import { FowkesOnlineService } from './fowkes-online.service';
import { isNumber } from 'util';

@Injectable()
export class validation {

  //TODO: Add to admin config, set to 10% for now
  static minimumPriceMargin: number = 10;

  public static validateCustomerOnHold(customerOnHold: string, creditLimit: string, currentBalance1: string, message: string): string {
    
    if (customerOnHold === "Y") {
      message += "Customer on hold";
    }
   
    return message;
  }

  public static validateCustomerOverCredit(customerOnHold: string, creditLimit: string, currentBalance1: string, message: string): string {
    let overLimit = parseFloat(creditLimit) - parseFloat(currentBalance1);

    if (customerOnHold === "Y") {
      message += (overLimit < 0 ? "On hold and exceeded credit limit" : "Customer on hold");
    }
    else {
      message += (overLimit < 0 ? "Customer exceeded credit limit" : "");
    }
    return message;
  }

  public static validateCanEditOrder(currentstatus: string): boolean {
    if (currentstatus != "9" && currentstatus != '"\"') {
      return true;
    }
    else {
      return false;
    }
  }

  public static validateOrderStatus(currentstatus: string, status: string, message: string): string {
    if (currentstatus != "9" && currentstatus != '"\"') {
      if (status == "9" && currentstatus != "8") {
        message += "You cannot invoice this order due to it's status"; 
      }
    }
    else {
      message += "You can not modify cancelled or complete orders"; 
    }
    return message;
  }

  public static validatePurchaseOrderPrinted(purchaseOrder: string,  message: string): string {
    message += purchaseOrder.substring(7, purchaseOrder.length);
    return message;
  }

  public static validateQtyForOrder(stockCode: string, availQty: number, orderQty: number,  message: string): string {
    if (availQty <= 0 && orderQty >= 1 || orderQty > availQty) {
      message += "Cannot convert to sales order, due to insufficient stock for stock codes: " + stockCode;
    }
   return message;
  }

  public static validateOperator(operator: string, password: string, sessionId: string, message: string): string {
    if (operator === 'undefined' || operator == null || operator == "") {
      message += "You do not have rights to place orders. Please contact your system administrator";
    }
    return message;
  }
  public static validateSalesPerson(salesperson: string, message: string): string {
    if (salesperson === 'undefined' || salesperson == null || salesperson == "") {
      message += "Please select a Salesperson";
    }
    return message;
  }
  public static validateCustomerPO(customerPONumber: string, message: string): string {
    if (customerPONumber === 'undefined' || customerPONumber == null || customerPONumber == "" || customerPONumber.length > 50) {
      message += "Please provide a Customer P/O no longer than 50 characters";
    }
    return message;
  }
  public static validateShippingInstructions(shippingInstr: string, message: string): string {
    if (shippingInstr === 'undefined' || shippingInstr == null || shippingInstr == "") {
      message += "Please select Shipping Instruction";
    }
    return message;
  }
  public static validateShipDate() {
    //if (!appBaseContainer.typeValidator.IsDateTime(dd, mm, yyyy, out tempDate)) {
    //  errorMessage = "The ship date is invalid";
    //}
  }

 
  public static stockCode(stockCode: string, message: string): string {
    switch (stockCode) {
      case null:
        message += "Stock Code can not be empty. "; break;
      case "":
        message += "Stock Code can not be empty. "; break;
      case "##_":
        message += "Please enter a stock code. "; break;
      default:
    }
    return message;
  }

  public static description(description: string, message: string): string {
    switch (description) {
      case null:
        message += "Please enter a valid item description. "; break;
      case "":
        message += "Please enter a valid item description. "; break;
      default:
    }
    return message;
  }


  public static qty(qty: number, message: string): string {
    switch (true) {
      case qty == null:
        message += "Please enter a valid quantity. "; break;
      case !isNumber(Number(qty)):
        message += "Please enter a valid quantity. "; break;
      case qty == 0:
        message += "Quantity can not be 0. "; break;
      case qty < 0:
        message += "Quantity can not be less than 0. "; break;
      default:
    }
    return message;
  }

  public static uom(uom: string, message: string): string {
    switch (uom) {
      case null:
        message += "Please enter a valid unit of measure. "; break;
      case "":
        message += "Please enter a valid unit of measure. "; break;
      default:
    }
    return message;
  }
  
  public static price(price: number, message: string): string {
    switch (true) {
      case price == null:
      case !isNumber(Number(price)):
        message += "Please enter a valid price. "; break;
      case price == 0:
        message += "Price can not be 0.  "; break;
      case price < 0:
        message += "Price can not be less than 0.  "; break;
      default:
    }
    return message;
  }

  public static NSprice(price: number, message: string): string {
    switch (true) {
      case price == null:
      case !isNumber(Number(price)):
        message += "Please enter a valid price. "; break;
      case price < 0:
        message += "Price can not be less than 0.  "; break;
      default:
    }
    return message;
  }

  public static discount(discount: number, message: string): string {
    switch (true) {
      case discount == null:
      case !isNumber(Number(discount)):
        message += "Please enter a valid discount. "; break;
      case discount < 0:
        message += "Discount can not be less than 0.  "; break;
      case discount >= 100:
        message += "Discount can not be 100%.  "; break;
      default:
    }
    return message;
  }

  public static cost(cost: number, message: string): string {
    switch (true) {
      case cost == null:
      case !isNumber(Number(cost)):
        message += "Please enter a valid cost. "; break;
      case cost == 0:
        message += "Cost can not be 0.  "; break;
      case cost < 0:
        message += "Cost can not be less than 0.  "; break;
      default:
    }
    return message;
  }


  public static NScost(cost: number, message: string): string {
    switch (true) {
      case cost == null:
      case !isNumber(Number(cost)):
        message += "Please enter a valid cost. "; break;
      case cost == 0:
        message += "Cost should at least be 0.1 "; break;
      case cost < 0:
        message += "Cost can not be less than 0.  "; break;
      default:
    }
    return message;
  }


  public static minPrice(cost: number, price: number, discount: number, message: string): string {

    var total = price - ((price / 100) * discount);

    //Check that the specified price and discount is above cost + price margin in web.config
    if (total < (cost + ((cost / 100) * this.minimumPriceMargin))) {
      message = "Invalid discounts: Price of R" + total.toFixed(2).toString() + " to Cost of R" + cost.toFixed(2).toString() + " ";
    }

    return message;
  }


  public static minSpecialPrice(cost: number, specialprice: number, message: string): string {

    //var total = price;// - ((price / 100) * discount);

    //Check that the specified price and discount is above cost + price margin in web.config
    if (specialprice < (cost + ((cost / 100) * this.minimumPriceMargin))) {
      message = "Invalid discounts: Price of R" + specialprice + " to Cost of R" + cost.toFixed(2).toString() + " ";
    }

    return message;
  }


  public static minNettPrice(cost: number, price: number, discount: number, message: string): string {

    var total = price;// - ((price / 100) * discount);

    //Check that the specified price and discount is above cost + price margin in web.config
    if (total < (cost + ((cost / 100) * this.minimumPriceMargin))) {
      message = "Invalid discounts: Price of R" + total.toFixed(2).toString() + " to Cost of R" + cost.toFixed(2).toString() + " ";
    }

    return message;
  }

  public static supplier(code: string, name: string, info: string, message: string): string {
    switch (code) {
      case null:
        message += "Please select a supplier. "; break;
      case "":
        message += "Please select a supplier. "; break;
      case "COD001":
        if (!info || info.trim().length <= 1)
          message += "Please enter a name for the cash supplier. "; break;
      case "COD002":
        if (!info || info.trim().length <= 1)
          message += "Please enter a name for the cash supplier. "; break;

      default:
    }
    return message;
  }


  public static precise_round(num, decimals) {
  var t = Math.pow(10, decimals);
  return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);

}

  public static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {
    const separatorIndex = text.indexOf(separator);

    if (separatorIndex == -1) {
      return { firstPart: text, secondPart: null };
    }

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static safeStringify(object) {

    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    } catch (error) {

    }

    const simpleObject = {};

    for (const prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof (object[prop]) == 'object') {
        continue;
      }
      if (typeof (object[prop]) == 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }

    result = '[***Sanitized Object***]: ' + JSON.stringify(simpleObject);

    return result;
  }

  public static JsonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }
      return value;
    }
  }

  public static TestIsObjectEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  public static TestIsUndefined(value: any) {
    return typeof value === 'undefined';
    // return value === undefined;
  }

  public static TestIsString(value: any) {
    return typeof value === 'string' || value instanceof String;
  }

  public static capitalizeFirstLetter(text: string) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return text;
    }
  }

  public static toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (subString) => {
      return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
    });
  }

  public static toLowerCase(items: string);
  public static toLowerCase(items: string[]);
  public static toLowerCase(items: any): string | string[] {

    if (items instanceof Array) {
      const loweredRoles: string[] = [];

      for (let i = 0; i < items.length; i++) {
        loweredRoles[i] = items[i].toLowerCase();
      }

      return loweredRoles;
    } else if (typeof items === 'string' || items instanceof String) {
      return items.toLowerCase();
    }
  }

  public static uniqueId() {
    return this.randomNumber(1000000, 9000000).toString();
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static baseUrl() {
    let base = '';

    if (window.location.origin) {
      base = window.location.origin;
    } else {
      base = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return base.replace(/\/$/, '');
  }

  public static printDateOnly(date: Date) {

    date = new Date(date);

    const dayNames = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    const monthNames = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');

    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    let sup = '';
    const month = date.getMonth();
    const year = date.getFullYear();

    if (dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31) {
      sup = 'st';
    } else if (dayOfMonth == 2 || dayOfMonth == 22) {
      sup = 'nd';
    } else if (dayOfMonth == 3 || dayOfMonth == 23) {
      sup = 'rd';
    } else {
      sup = 'th';
    }

    const dateString = dayNames[dayOfWeek] + ', ' + dayOfMonth + sup + ' ' + monthNames[month] + ' ' + year;

    return dateString;
  }

  public static printTimeOnly(date: Date) {

    date = new Date(date);

    let period = '';
    let minute = date.getMinutes().toString();
    let hour = date.getHours();

    period = hour < 12 ? 'AM' : 'PM';

    if (hour == 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }

    if (minute.length == 1) {
      minute = '0' + minute;
    }

    const timeString = hour + ':' + minute + ' ' + period;


    return timeString;
  }

  public static searchArray(searchTerm: string, caseSensitive: boolean, ...values: any[]) {
    if (!searchTerm) {
      return true;
    }

    let filter = searchTerm.trim();
    let data = values.join();

    if (!caseSensitive) {
      filter = filter.toLowerCase();
      data = data.toLowerCase();
    }

    return data.indexOf(filter) != -1;
  }

  public static moveArrayItem(array: any[], oldIndex, newIndex) {

    while (oldIndex < 0) {
      oldIndex += this.length;
    }

    while (newIndex < 0) {
      newIndex += this.length;
    }

    if (newIndex >= this.length) {
      let k = newIndex - this.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  public static expandCamelCase(text: string) {

    if (!text) {
      return text;
    }

    return text.replace(/([A-Z][a-z]+)/g, ' $1')
      .replace(/([A-Z][A-Z]+)/g, ' $1')
      .replace(/([^A-Za-z ]+)/g, ' $1');
  }

  public static testIsAbsoluteUrl(url: string) {

    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
  }

  public static debounce(func: (...args) => any, wait: number, immediate?: boolean) {
    let timeout;

    return function () {
      const context = this;
      const args_ = arguments;

      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args_);
        }
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args_);
      }
    };
  }
}
