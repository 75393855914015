
export class WAPP {
  constructor(recepientName?: string, recepientTel?: string, subject?: string, bod?: string, senderName?: string, senderEmail?: string,
    attachment?: string, cc?: string, bcc?: string, wappType?: string, senderBranch?: string, addDoc1?: string, addDoc2?: string, addDoc3?: string, senderCompany?: string) {

    this.RecepientName = recepientName;
    this.RecepientTel = recepientTel;
    this.Subject = subject;
    this.Bod = bod;
    this.SenderName = senderName;
    this.SenderEmail = senderEmail;
    this.Attachment = attachment;
    this.CC = cc;
    this.BCC = bcc;
    this.WappType = wappType;
    this.SenderBranch = senderBranch;
    this.AddDoc1 = addDoc1;
    this.AddDoc2 = addDoc2;
    this.AddDoc3 = addDoc3;
    this.SenderCompany = senderCompany;
  }

  public RecepientName: string
  public RecepientTel: string
  public Subject: string
  public Bod: string
  public SenderName: string
  public SenderEmail: string
  public Attachment: string
  public CC: string
  public BCC: string
  public WappType: string
  public SenderBranch: string
  public AddDoc1: string
  public AddDoc2: string
  public AddDoc3: string
  public SenderCompany: string

}

