export class DocumentCategory {
  constructor(title?: string, categoryName?: string, parentCategoryName?: string, enabled?: boolean, responsibility?: string) {
    this.categoryName = categoryName;
    this.parentCategoryName = parentCategoryName;
    this.enabled = enabled;
    this.responsibility = responsibility;
    this.enabled = enabled;
  }

  public json: string;
  public id: number;
  public categoryName: string;
  public parentCategoryName: string;
  public enabled: boolean;
  public responsibility: string;
  public createdDate: string;
  public createdBy: string;
  public displayName: string;
}
