import { Component, ViewChild, Input } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
//import { Permission } from 'src/app/models/permission.model';
//import { Utilities } from 'src/app/services/utilities';
//import { SysproService } from 'src/app/services/syspro.service';
//import { localStorageActions } from 'src/app/models/enums';
//import { ReportsInvoiceReprintComponent } from './report-components/reports-invoice-reprint-component';
//import { ReportsInvoiceStatusComponent } from './report-components/reports-invoice-status-component';
//import { ReportsSalesComponent } from './report-components/reports-sales-component';
//import { ReportsInvoiceTrackingPurgeComponent } from './report-components/reports-invoice-tracking-purge-component';



@Component({
  selector: 'pod-editbatch',
  templateUrl: './pod-editbatch.component.html',
  styleUrls: ['./pod-editbatch.component.scss'],
  animations: [fadeInOut]
})
export class PodEditBatchComponent {

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService) {
  }
  
}
