export class ITSDriversLog {
  public id: number;
  public driverCode: string;
  public driverName: string;
  public driverImage: string;
  public filePath: string;
  public vehicleNumber: string;
  public vehicleDescription: string;
  public cageNumber: string;
  public cageDescription: string;
  public totalParcels: number;
  public totalParcelsUndelivered: number;
  public trackingInsertDate: Date;
  public ReadyForPrint: boolean;
  public PrintedDate: Date | null;

  details: ITSParcelTrackingDetailLog[];
}

export class ITSParcelTrackingDetailLog {
  public id: number;
  public trackingHeaderId: number;
  public invoiceNumber: string;
  public salesOrder: string;
  public scannedStatus: string;
  public scannedCount: number;
  public scannedDate: Date;
  public fromCompany: string;
  public operator: string;
  public undeliveredStatus: string;
  public undeliveredDate: Date | null;
  public undeliveredCount: number | null;
  public undeliveredUser: string;
  public undeliveredReason: string;
  public customer: string;
  public shipToAddr1: string;
  public shipToAddr2: string;
  public shipToAddr3: string;
  public shipToAddr4: string;
  public shipToAddr5: string;
  public shipPostalCode: string;
  public shippingInstrs: string;
  public specialInstrs: string;
  public serverDate: Date;

  // Additional properties based on the UPDATE statements in the stored procedure
  public customerName: string;
  public telephone: string;
  public addTelephone: string;
  public contact: string;
  public createDate: Date | null;
  public branchCode: string;
  public salesperson: string;
  public customerPoNumber: string;
  public shipDate: Date | null;
  public orderDate: Date | null;
  public rep: string;
  public totalIncl: number | null;
  public totalExcl: number | null;
  public totalDiscount: number | null;
  public invoiceOperator: string;
  public salesPersonName: string;
  public itsParcelCount: number | null;
  public itsDespatchNotes: string;
  public itsUpdatedBy: string;
}

