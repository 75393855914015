
export class QuoteDetail {
  constructor(quoteNumber?: string, quoteLineNumber?: number, lineType?: string, stockCode?: string, stockDescription?: string,
    warehouse?: string, orderQty?: number, unitCost?: number, orderUom?: string, sellingPrice?: number, discountPercentage1?: number,
    discountvalue?: number, priceConvFac?: number, lineTotal?: number, boSupplierCode?: string, boSupplierName?: string, boSupplierInvoice?: string,
    boLineInfo?: string, vatExempt?: boolean, originalPrice?: number, originalDiscount?: number, nettPrice?: number,
    nettDiscount?: number, gP?: number) {

    this.quoteNumber = quoteNumber;
    this.quoteLineNumber = quoteLineNumber;
    this.lineType = lineType;
    this.stockCode = stockCode;
    this.stockDescription = stockDescription;
    this.warehouse = warehouse;
    this.orderQty = orderQty;
    this.unitCost = unitCost;
    this.orderUom = orderUom;
    this.sellingPrice = sellingPrice;
    this.discountPercentage1 = discountPercentage1;
    this.discountValue = discountvalue;
    this.priceConvFac = priceConvFac;
    this.lineTotal = lineTotal;
    this.boSupplierCode = boSupplierCode;
    this.boSupplierName = boSupplierName;
    this.boSupplierInvoice = boSupplierInvoice;
    this.boLineInfo = boLineInfo;
    this.vatExempt = vatExempt;
    this.originalPrice = originalPrice;
    this.originalDiscount = originalDiscount;
    this.nettPrice = nettPrice;
    this.nettDiscount = nettDiscount;
    this.gP = gP;
  }

  public quoteNumber: string;
  public quoteLineNumber: number;
  public lineType: string;
  public stockCode: string;
  public stockDescription: string;
  public warehouse: string;
  public orderQty: number;
  public unitCost: number;
  public orderUom: string;
  public sellingPrice: number;
  public discountPercentage1: number;
  public discountValue: number;
  public unitmass: number;
  public unitvolume: number;
  public comment: string;
  public commentFromLineNumber: number;
  public commentType: string;
  public shipDate: Date;
  public lineCreateDate: Date;
  public boSupplierName: string;
  public boSupplierInvoice: string;
  public boLineInfo: string;
  public boSupplierCode: string;
  public priceConvFac: number;
  public lineTotal: number;
  public vatExempt: boolean;
  public originalPrice: number;
  public originalDiscount: number;
  public nettPrice: number;
  public nettDiscount: number;
  public gP: number;
  public mapped: boolean;
  public newArrival: boolean;
  public onSpecial: boolean;
  public showAlternate: boolean;
  public alternateKey: string;
  public specialsImage: string;
  public specialPrice: number;
  public dataSheetImage: string;
  public addSheetImage: string;
}

