
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../../models/permission.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { CustomerMovements } from 'src/app/models/customer-movements.model';
import { CustomerPayments } from 'src/app/models/customer-payments.model';
import { localStorageActions } from 'src/app/models/enums';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigurationService } from '../../../services/configuration.service';
import { Email } from '../../../models/email.model';
import { ReportService } from '../../../services/report.service';
import { CustomerOTPReference } from '../../../models/customerOTPReference.model';
import { CustomerOTPHeader } from '../../../models/customerOTPHeader.model';


@Component({
  selector: 'customer-statements',
  templateUrl: './customer-statements.component.html',
  styleUrls: ['./customer-statements.component.scss']
})
export class SysproCustomerStatementsComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
  rows: CustomerPayments[] = [];
  rowsCache: CustomerPayments[] = [];
  customerPaymentsList: CustomerPayments[];


  rowsStatements: CustomerOTPReference[] = [];
  rowsStatementsCache: CustomerOTPReference[] = [];
  rowsStatementsList: CustomerOTPReference[];


  rowsHeader: CustomerOTPHeader[] = [];
  rowsHeaderCache: CustomerOTPHeader[] = [];
  rowsHeaderList: CustomerOTPHeader[];

  loadingIndicator: boolean;

  currentMonth: string;
  startDate: string;
  endDate: string;


  @ViewChild('paymentsModal')
  paymentsModal: ModalDirective;
  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;


  @ViewChild('statusTemplate')
  statusTemplate: TemplateRef<any>;

  @ViewChild('removeTemplate')
  removeTemplate: TemplateRef<any>;


  @ViewChild('paymentsTemplate')
  paymentsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();

  @Input()
  isViewOnly: boolean;

  @Input()
  selectedCustomer: string;
  
  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  isSearch: boolean; 
  id: string;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('debtorSelector')
  private debtorSelector;

  debtorsList: string[] = [];
  debtorSelected: string;
  private selectAllOptionValue: string = "All Debtors";

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  //moment = _moment;
  selected: { startDate: Moment, endDate: Moment };
  ranges = {
    'All Dates': [moment().subtract(5, 'year').startOf('month'), moment()],
    //'Today': [moment(), moment()],
    //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 2 Years': [moment().subtract(24, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Years': [moment().subtract(36, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
    locale: LocaleConfig = {
      applyLabel: 'Done'
    };
    start: Date;
    end: Date;
    inlineDate: any;
    inlineDateTime: any;
    show: boolean;
    chkDb: boolean;
    chkCr: boolean;
  emailObject: Email = new Email();


  chkInvalid: boolean;
  chkSent: boolean;

    customerOTP: string;
    src: string;
    selectedInvoice: string;
    selectedSalesOrder: string;
    isCreditNote: boolean;
    url: string;
    showCustomerDiv: boolean;
    headerToSend: CustomerOTPHeader;
    smartsearch: any;
    sendingCount: number;
    sentCount: number;
    chkNoRecords: boolean;
    currentDebtor: string;
    isAdministrator: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private reportService: ReportService) {
  }


  ngOnInit() {

    this.searchType = "customer";

    this.loadFresh();

    //Add search
    //Add export

    //Update status to sending / sent
    //Have results list after email sent
    //Disable page while sending
    //Test bulk sending
    //Clear statement list
    //Regenerate statement list
    //Flag invalid email addresses

    //Payments preview when clicking on customer??

    //Add and load for specific debtors


  }

  private loadFresh() {


    this.selected = {
      startDate: moment().subtract(3, 'month').startOf('month'),
      endDate: moment().subtract(1, 'month').endOf('month')
    };

    var start = new Date(this.selected.startDate.toString());
    var end = new Date(this.selected.endDate.toString());

    let currentDate = new Date();
    this.currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    this.startDate = start.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' });
    this.endDate = end.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' });


    this.debtorsList = [];
    this.debtorSelected = this.selectAllOptionValue;
    this.currentDebtor = '';    

    this.loadCollumns();

    this.sysproService.getCustomersLoadedForStatements(this.application, start.toJSON(), end.toJSON()).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }

  refresh() {
    this.loadFresh();
  }

  toggleNewCustomer() {
    this.showCustomerDiv = !this.showCustomerDiv;
  }

  onDataLoadSuccessful(customers: any[]) {
    if (customers && customers.length >= 1 && customers[0].length >= 1) {

      this.currentDebtor = this.accountService.currentUser.email;

      //Get user role
      var currentRole = this.accountService.currentUser.roles[0];
      if (currentRole == "Debtors") {

        this.rowsHeader = customers[0];
        this.rowsHeader = this.rowsHeader.filter(c => c.createdBy == this.currentDebtor);
        this.rowsHeaderCache = [...this.rowsHeader];
        this.isAdministrator = false;
      }
      else {
        if (currentRole.includes("Admin")) {

          this.rowsHeader = customers[0];
          this.rowsHeaderCache = [...this.rowsHeader];
          this.isAdministrator = true;
          this.currentDebtor = this.selectAllOptionValue;

          //Load list of debtors
          const createdByList = Array.from(new Set(this.rowsHeader.map((c: any) => c.createdBy)));
          this.debtorsList.push(this.currentDebtor);
          this.debtorsList = [...this.debtorsList, ...createdByList];
          setTimeout(() => {
            this.debtorSelector.refresh();
          });
        }
      }
    }
    else {
      this.alertService.showMessage('No results', 'No customer statements loaded for the selected dated range',
        MessageSeverity.warn);
    }

    this.onSearchChanged('');

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  private loadCollumns() {
    this.columns = [
      { prop: 'customerCode', name: 'Customer', width: 80, cellClass: "left", cellTemplate: this.paymentsTemplate },
      { name: 'Delete', width: 50, cellClass: "left", cellTemplate: this.removeTemplate },
      { prop: 'statement', name: 'Statement', width: 200, cellClass: "left", cellTemplate: this.selectTemplate },
      { prop: 'otpHeaderReference', name: 'Reference', width: 100, cellClass: "left" },
      { prop: 'dateFrom', name: 'From', width: 80, cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'dateTo', name: 'To', width: 80, cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'updatedStatus', name: 'Records', width: 100, cellClass: "left" },
      { prop: 'updatedCustomerStatus', name: 'Status', width: 100, cellClass: "left", cellTemplate: this.statusTemplate },
      { prop: 'sentTo', name: 'Email', width: 230, cellClass: "left" },
    ];

    this.columns.push({ name: 'Send Email', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }

  ngAfterViewInit() {
    this.loadCollumns();
  }

  filterByDebtorSelected() {
    if (this.debtorSelected) {
      this.currentDebtor = this.debtorSelected;
      if (this.debtorSelected != this.selectAllOptionValue) {
        this.rowsHeader = this.rowsHeaderCache.filter(c => c.createdBy == this.debtorSelected);
      }
      else {

        this.rowsHeader = this.rowsHeaderCache;
      }
    }
  }

  filterSent() {
    this.chkSent = !this.chkSent;

    if (this.chkSent == true) {
      this.rowsHeader = this.rowsHeader.filter(f => f.updatedCustomerStatus != "Email Sent");
    }
    else {
      this.rowsHeader = this.rowsHeaderCache;
    }
  }

  filterInvalid() {
    this.chkInvalid = !this.chkInvalid;
    this.onSearchChanged('');
  }

  filterNoRecords() {
    this.chkNoRecords = !this.chkNoRecords;
    this.onSearchChanged('');
  }


  //previewCustomerPayment(row: CustomerOTPHeader) {
  //  this.selectedCustomer = row.customerCode;

  //  if (!!this.selectedCustomer) {
  //    if (!this.paymentsModal) {
  //      setTimeout(() => {
  //        if (this.paymentsModal) {
  //          this.paymentsModal.show();
  //        }
  //      });
  //    }
  //    else {
  //      this.paymentsModal.show();
  //    }
  //  }
  //}

  //paymentsModalHide() {
  //  this.selectedCustomer = null;
  //  this.paymentsModal.hide();
  //}

  //goToCustomerPayment() {
  //  if (sessionStorage.getItem(localStorageActions.Customer) != this.selectedCustomer ) {
  //    sessionStorage.setItem(localStorageActions.Customer, this.selectedCustomer );
  //  }

  //  this.router.navigate(['../../customers'], { fragment: 'payments', queryParams: { customer: this.selectedCustomer } });
  //}

  onSearchChanged(value: string) {
    if (value != "") {
      this.rowsHeader = this.rowsHeaderCache.filter(r => Utilities.searchArray(value, false, r.customerCode, r.updatedStatus, r.updatedCustomerStatus, r.customerName, r.otpHeaderReference, r.sentTo));
    }

    else {
      this.rowsHeader = this.rowsHeaderCache;
    }
    //if (this.chkInvalid == true) {
    //  this.rowsHeader = this.rowsHeader.filter(f => f.updatedStatus != "Invalid Email");
    //}

    //if (this.chkSent == true) {
    //  this.rowsHeader = this.rowsHeader.filter(f => f.updatedStatus != "Email Sent");
    //}

    //if (this.chkNoRecords == true) {
    //  this.rowsHeader = this.rowsHeader.filter(f => f.updatedStatus != "No Records");
    //}
  }

  getStatement(otp: CustomerOTPHeader): void {

    this.reportService.getFileStreamStatement(otp.statement).subscribe(x => this.onStatementSuccessful(x, false, otp.statement), error => this.onDataLoadFailed(error));
  }

  onStatementSuccessful(x: Blob, preview: boolean, statement: string): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = statement;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  removeCustomer(header?: CustomerOTPHeader) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.RemoveOTPCustomer(header).subscribe(x => {
      this.alertService.showStickyMessage('', 'Customer Removed',
        MessageSeverity.success);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.loadFresh();
    }, error => {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.loadFresh();});


  }
    //onHeaderRemovedSuccess(x: any): void {
    //  this.alertService.showStickyMessage('', 'Customer Removed',
    //    MessageSeverity.success);
    //  this.alertService.stopLoadingMessage();
    //  this.loadingIndicator = false;
    //  this.loadFresh();
    //}

  async sendAllStatements() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var sendingList = this.rowsHeader.filter(l => l.updatedCustomerStatus != 'Email Sent');

    this.sendingCount = sendingList.length;
    var sentCount = 0;

    for (var header of sendingList) {
      sentCount++;
      if (header.updatedStatus != 'No Records') {
        var emailObject = new Email();
        var customerOTP = header.otpHeaderReference;
        emailObject.RecepientEmail = header.sentTo;

        if (this.isValidEmail(emailObject.RecepientEmail)) {
          // send email
          //For Testing!!
          // emailObject.RecepientEmail = "lara@wyldeweb.co.za; lara.vanniekerk@startupservices.co.za";

          emailObject.CC = "";
          emailObject.BCC = this.accountService.currentUser.email;
          emailObject.Subject = "Customer Account: " + header.customerCode;
          emailObject.SenderName = this.accountService.currentUser.fullName;
          emailObject.SenderEmail = this.accountService.currentUser.email;
          emailObject.SenderBranch = this.branchCode;
          emailObject.SenderCompany = this.application;
          emailObject.Attachment = header.statement;

          this.url = (this.application == "FowkesOnline" ? "https://nextgen.fowkes.co.za/viewportal" : "https://nextgen.maxarcus.co.za/viewportal");

          emailObject.Bod = header.otpHeaderReference;
          emailObject.EmailType = "Statement";

          if (emailObject.RecepientEmail && emailObject.RecepientEmail.trim().length >= 1) {
            this.alertService.startLoadingMessage();
            this.loadingIndicator = true;
            header.updatedCustomerStatus = "...Sending";

           // this.sysproService.sendEmail(emailObject).subscribe(x => this.onBatchSentSuccessful(x, header, sentCount), error => this.onBatchSendFailed(error, header, sentCount));
            const emailPromise = new Promise<void>((resolve, reject) => {
              this.sysproService.sendEmail(emailObject).subscribe(x => {
                this.onBatchSentSuccessful(x, header, sentCount);
                resolve();
              }, error => {
                this.onBatchSendFailed(error, header, sentCount);
                reject();
              });
            });

            await emailPromise; // pause loop until emailPromise is resolved
          }
          
          else {
            //this.alertService.showMessage('Warning', `Remeber to add email address for recepient`, MessageSeverity.warn);
          }
        } else {
          header.updatedCustomerStatus = "Invalid Email";
          this.alertService.showStickyMessage('Error', `Invalid email address: ${header.customerCode}`, MessageSeverity.error);
          this.fowkesService.UpdateOTPCustomer(header).subscribe(x => this.onHeaderUpdatedSuccess(x, sentCount), error => this.onDataLoadFailed(error));
        }
        
      }
      else {

      }
    }
     
  }

  isValidEmail(emails: string): boolean {
    const emailArray = emails.split(/[;,]/); // Splitting the input string by either semicolons or commas
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for validating email addresses

    for (const email of emailArray) {
      const trimmedEmail = email.trim(); // Remove leading/trailing whitespace from each email address

      if (!emailRegex.test(trimmedEmail)) {
        return false; // Return false if any email address is invalid
      }
    }

    return true; // All email addresses are valid
}

  onBatchSendFailed(error: any, header: CustomerOTPHeader, count: number): void {
    //Update Header Status
    header.updatedCustomerStatus = "Email Failed";
    this.fowkesService.UpdateOTPCustomer(header).subscribe(x => this.onHeaderUpdatedSuccess(x, count), error => this.onDataLoadFailed(error));
    }

  onBatchSentSuccessful(x: Email, header: CustomerOTPHeader, count: number): void {
    //Update Header Status
    header.updatedCustomerStatus = "Email Sent";
    this.fowkesService.UpdateOTPCustomer(header).subscribe(x => this.onHeaderUpdatedSuccess(x, count), error => this.onDataLoadFailed(error));

  }

  onHeaderUpdatedSuccess(x: any, count: number): void {

    console.log(count);
    console.log(x);
    if (count === this.sendingCount) {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', count + ` Emails sent successfully`, MessageSeverity.success);

      this.loadFresh();
    }
    else {
      console.log(count);
    }
  }

  sendEmail(header?: CustomerOTPHeader) {

    this.headerToSend = new CustomerOTPHeader();

    if (header.customerCode) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;  
      this.customerOTP = header.otpHeaderReference;

     //this.emailObject.RecepientName = header.;
      this.emailObject.RecepientEmail = header.sentTo;
      this.emailObject.CC = "";
      this.emailObject.BCC = this.accountService.currentUser.email;
      this.emailObject.Subject = "Customer Account: " + header.customerCode;
      this.emailObject.SenderName = this.accountService.currentUser.fullName;
      this.emailObject.SenderEmail = this.accountService.currentUser.email;
      this.emailObject.SenderBranch = this.branchCode;
      this.emailObject.SenderCompany = this.application;
      this.emailObject.Attachment = header.statement;

      this.url = (this.application == "FowkesOnline" ? "https://nextgen.fowkes.co.za/viewportal" : "https://nextgen.maxarcus.co.za/viewportal");

      // this.emailObject.Bod = "These documents can be viewed online at " + this.url + ", please be sure to enter your unique OTP: " + this.customerOTP + " valid for 5 days only.";
      this.emailObject.Bod = header.otpHeaderReference;
      this.emailObject.EmailType = "Statement";

      this.emailModal.show();
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.headerToSend = header;

    }

  }



  toggle() {
    this.show = true;
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }



  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;      

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }

  }
 
  private loadCustomer() {
    this.id = this.searchResultSelected.customer;
    this.currentCustomer = this.id;

    //Check if customer is already in the list?
    var chk = this.rowsHeaderCache.filter(c => c.customerCode == this.currentCustomer);
    if (chk && chk.length >= 1) {
      this.alertService.showStickyMessage('', 'Customer already in the list',
        MessageSeverity.warn);
    }
    else {
      this.alertService.startLoadingMessage("Creating customer records...");
      this.loadingIndicator = true;

      this.customerHeading = this.searchResultSelected;

      var uniqueCode = this.currentCustomer + "-" + this.generateOTP();
       var code = uniqueCode;

      var filename = "CustomerAccount_" + code + ".pdf";

      //Create New Customer Header OTP Record
      var OTPHeader = new CustomerOTPHeader();
      OTPHeader.application = this.application;
      OTPHeader.dateFrom = new Date(this.selected.startDate.toString());
      OTPHeader.dateTo = new Date(this.selected.endDate.toString());
      OTPHeader.customerCode = this.customerHeading.customer;
      OTPHeader.customerName = this.customerHeading.customerName;
      OTPHeader.statement = filename;
      OTPHeader.otpHeaderReference = code;
      OTPHeader.createdBy = this.accountService.currentUser.email;
      OTPHeader.sentTo = this.customerHeading.email;
      OTPHeader.status = this.currentMonth + "Statements";

      //UpdateRecord
      this.fowkesService.newOTPHeader(OTPHeader).subscribe(x => this.onHeaderSuccess(x), error => this.onDataLoadFailed(error));      
    }
  }

  onHeaderSuccess(OTPHeader: CustomerOTPHeader): void {

    if (OTPHeader && OTPHeader.otpHeaderReference) {
      this.alertService.showStickyMessage('', 'Customer record created',
        MessageSeverity.success);

      this.alertService.startLoadingMessage("Creating customer statement...");
      this.loadingIndicator = true;

      this.reportService.getStatementPDF(OTPHeader.customerCode, this.application, OTPHeader.dateFrom.toString(), OTPHeader.dateTo.toString(), OTPHeader.otpHeaderReference).subscribe(x => {

        this.getPaymentsAndGenerateDocuments(OTPHeader);

      }, error => this.onDataLoadFailed(error));
    }
    else {
     
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.loadFresh();
    }
  }

  getPaymentsAndGenerateDocuments(OTPHeader: CustomerOTPHeader) {
    if (OTPHeader && OTPHeader.otpHeaderReference) {
      this.alertService.showStickyMessage('', 'Customer statement created',
        MessageSeverity.success);

      this.alertService.startLoadingMessage("Creating related documents...");
      this.loadingIndicator = true;

      this.sysproService.getCustomerPayments(OTPHeader.dateFrom.toString(), OTPHeader.dateTo.toString(), OTPHeader.customerCode).subscribe(x => this.onPaymentsLoadSuccessful([x], OTPHeader.otpHeaderReference), error => this.onDataLoadFailed(error));
    }
    else {
      
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.loadFresh();
    }
  }
  onPaymentsLoadSuccessful(customers: any[], code?: string): void {

      this.customerPaymentsList = customers[0];

      if (this.customerPaymentsList && this.customerPaymentsList.length >= 1) {

        this.customerPaymentsList.forEach((customer, index, customers) => {
          (<any>customer).index = index + 1;
        });

        for (var row of this.customerPaymentsList) {

          if (row.invoice != "" && row.transaction == "Invoice") {

            var OTPDetails = new CustomerOTPReference();
            OTPDetails.application = this.application;
            OTPDetails.dateFrom = new Date(this.selected.startDate.toString());
            OTPDetails.dateTo = new Date(this.selected.endDate.toString());
            OTPDetails.customerCode = this.currentCustomer;
            OTPDetails.documentNumber = "Invoice_" + row.invoice + ".pdf";
            OTPDetails.otpReference = code;
            OTPDetails.createdBy = this.accountService.currentUser.email;
            OTPDetails.sentTo = this.customerHeading.email;
            OTPDetails.status = this.currentMonth + "Statements";

            //UpdateRecord
            this.fowkesService.newOTPDetail(OTPDetails).subscribe(x => this.onReferenceSuccess(x, false), error => this.onDataLoadFailed(error));
          }

          if (row.invoice != "" && row.transaction == "Credit") {

            var OTPDetails = new CustomerOTPReference();
            OTPDetails.application = this.application;
            OTPDetails.dateFrom = new Date(this.selected.startDate.toString());
            OTPDetails.dateTo = new Date(this.selected.endDate.toString());
            OTPDetails.customerCode = this.currentCustomer;
            OTPDetails.documentNumber = "InvoiceCredit_" + row.invoice + ".pdf";
            OTPDetails.otpReference = code;
            OTPDetails.createdBy = this.accountService.currentUser.email;
            OTPDetails.sentTo = this.customerHeading.email;
            OTPDetails.status = this.currentMonth + "Statements";

            //UpdateRecord
            this.fowkesService.newOTPDetail(OTPDetails).subscribe(x => this.onReferenceSuccess(x, false), error => this.onDataLoadFailed(error));
          }
        }

        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.loadFresh();

      }
      else {
        this.alertService.showStickyMessage('No results', 'No payment details for the search criteria',
          MessageSeverity.warn);
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.loadFresh();
      }
    }


   

  onStatementsLoadSuccessful(statements: any[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rowsStatementsList = statements[0];

    this.rowsStatementsCache = [...this.rowsStatementsList];
    this.rowsStatements = this.rowsStatementsCache;

  }


  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }


  onCustomerLoadSuccessful(x: ArCustomer[]): void {
    this.customerSearch = x;
    this.customerHeading = this.customerSearch.find(x => x.customer == this.currentCustomer);
    setTimeout(() => {
      if (this.customerSelector) {
        this.customerSelector.refresh();
      }
    });
  }


    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  
  onReferenceSuccess(x: CustomerOTPReference, arg1: boolean): void {

    

  }

  generateOTP() {

    // Declare a digits variable 
    // which stores all digits
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }


  viewPDF() {
    this.reportService.getFileStreamStatement(this.emailObject.Attachment).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {

    this.reportService.getFileStreamStatement(this.emailObject.Attachment).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  emailPDF() {


    if (this.emailObject.RecepientEmail && this.emailObject.RecepientEmail.trim().length >= 1) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sendingCount = 1;

      this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showMessage('Warning', `Remeber to add email address for recepient`, MessageSeverity.warn);
    }

  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();

    //Update Header Status
    this.headerToSend.updatedCustomerStatus = "Email Sent";
    this.fowkesService.UpdateOTPCustomer(this.headerToSend).subscribe(x => this.onHeaderUpdatedSuccess(x, 1), error => this.onDataLoadFailed(error));

   

  }

  //getPDF(x: Blob, code: string): void {

  //  this.reportService.getFileStreamStatement(code).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  //}

  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = this.emailObject.Attachment;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  //previewSalesOrder(row: CustomerPayments) {

  //  this.selectedInvoice = row.invoice;
  //  this.selectedSalesOrder = row.salesOrder;
  //  this.isCreditNote = (row.transaction == "Credit" ? true : false);

  //  if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
  //    sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
  //  }

  //  if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != row.invoice) {
  //    sessionStorage.setItem(localStorageActions.InvoiceNumber, row.invoice);
  //  }

  //  if (!!this.selectedSalesOrder) {
  //    if (!this.salesOrderModal) {
  //      setTimeout(() => {
  //        if (this.salesOrderModal) {
  //          this.salesOrderModal.show();
  //        }
  //      });
  //    }
  //    else {
  //      this.salesOrderModal.show();
  //    }
  //  }
  //}

  //salesOrderModalHide() {
  //  this.selectedSalesOrder = null;
  //  this.selectedInvoice = null;
  //  this.isCreditNote = false;

  //  this.salesOrderModal.hide();
  //}


  //viewSalesOrder() {

  //  this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  //}


  exportList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Customer", "Name", "Debtor", "Statement", "Records", "Status", "Reference", "CreateDate", "DateFrom", "DateTo", "CustomerEmail", "ViewOnline"]
    };

   var url = (this.application == "FowkesOnline" ? "https://nextgen.fowkes.co.za/viewportal" : "https://nextgen.maxarcus.co.za/viewportal");
    var filename = this.currentMonth + "Statements";
    var selectRows = [...this.rowsHeader];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.createDate).toISOString().split('T')[0];
      let dateFrom = new Date(row.dateFrom).toISOString().split('T')[0];
      let dateTo = new Date(row.dateTo).toISOString().split('T')[0];
      exportRows.push({
        Customer: row.customerCode, Name: row.customerName, Debtor: row.createdBy, Statement: row.statement, Records: row.updatedStatus, Status: row.updatedCustomerStatus, Reference: row.otpHeaderReference,
        CreateDate: date,
        DateFrom: dateFrom, DateTo: dateTo, CustomerEmail: row.sentTo.replace(/;/g, "|").replace(/,/g, "|"), ViewOnline: url });
    }

    return new ngxCsv(exportRows, filename, options);
  }


   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }

  get canCreateStatementsCustomers() {
    return this.accountService.userHasPermission(Permission.customerStatementsPermission);
  }
}
