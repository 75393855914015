import { ArCustomer } from './arcustomer.model';


export class ArCustomerEdit extends ArCustomer {
    constructor() {
        super();

    }

}
