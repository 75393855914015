export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string, roles?: string[],
    isSupervisor?: boolean,
    branch?: string, sysproOperator?: string, sysproBuyer?: string, sysproPassword?: string, aspnetApplication?: string, sysproSessionId?: string,
    defaultPrinter?: string, configuration?: string) {

    this.id = id;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.branch = branch;
    this.sysproOperator = sysproOperator;
    this.sysproBuyer = sysproBuyer;
    this.sysproPassword = sysproPassword;
    this.sysproSessionId = sysproSessionId;
    this.aspnetApplication = aspnetApplication;
    this.isSupervisor = isSupervisor;
    this.defaultPrinter = defaultPrinter;
    this.configuration = configuration;
  }


  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + ' ' + name;
    }

    return name;
  }


  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public roles: string[];
  public branch: string;
  public sysproOperator: string;
  public sysproBuyer: string;
  public sysproRep: string;
  public sysproPassword: string;
  public sysproSessionId: string;
  public aspnetApplication: string;
  public isSupervisor: boolean;
  public defaultPrinter: string;
  public configuration: string;
}
