
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { CustomerMovements } from 'src/app/models/customer-movements.model';
import { CustomerPayments } from 'src/app/models/customer-payments.model';
import { localStorageActions } from 'src/app/models/enums';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigurationService } from '../../services/configuration.service';
import { Email } from '../../models/email.model';
import { ReportService } from '../../services/report.service';
import { CustomerOTPReference } from '../../models/customerOTPReference.model';


@Component({
  selector: 'syspro-customers-view-portal',
  templateUrl: './syspro-customers-view-portal.component.html',
  styleUrls: ['./syspro-customers-view-portal.component.scss']
})
export class SysproCustomersViewPortalComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
  rows: CustomerPayments[] = [];
  rowsCache: CustomerPayments[] = [];
  customerPaymentsList: CustomerPayments[];
  loadingIndicator: boolean;


  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();

  id: string;

  @Input()
  OTP: string;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  //moment = _moment;
  selected: { startDate: Moment, endDate: Moment };
  ranges = {
    'All Dates': [moment().subtract(5, 'year').startOf('month'), moment()],
    //'Today': [moment(), moment()],
    //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 2 Years': [moment().subtract(24, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Years': [moment().subtract(36, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  locale: LocaleConfig = {
    applyLabel: 'Done'
  };
  start: Date;
  end: Date;
  inlineDate: any;
  inlineDateTime: any;
  show: boolean;
    chkDb: boolean;
    chkCr: boolean;


  emailObject: Email = new Email();
    customerOTP: string;
    src: string;
    hasresults: boolean;

  constructor(private alertService: AlertService,
     private router: Router, private route: ActivatedRoute,
    private reportService: ReportService, private fowkesService: FowkesOnlineService) {
    }


  ngOnInit() {
    this.hasresults = false;
    this.columns = [
      { prop: 'invoice', name: 'Invoice', width: 100, cellClass: "left" },
      { prop: 'filterDate', name: 'Date', width: 100, cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'transaction', name: 'Transaction', width: 210, cellClass: "left" },
      { prop: 'customerPoNumber', name: 'Customer Po Number', width: 210, cellClass: "left" },
      { prop: 'reference', name: 'Transaction Reference', width: 210, cellClass: "left" }, 
   //   { prop: 'operator', name: 'Operator', width: 80, cellClass: "left" },
      { prop: 'amount', name: 'Total (Incl VAT)', width: 210, cellClass: "right" }
    ];

    this.selected = {
      startDate: moment().subtract(0, 'month').startOf('month'), 
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.getDocumentsForOTP();

     //   this.loadData();
    }
  getDocumentsForOTP() {

    if (this.OTP) {

      this.fowkesService.getOTPDetails(this.OTP).subscribe(x => this.onDataLoadSuccesful(x), error => this.onDataLoadFailed(error));
    }
  }
    onDataLoadSuccesful(x: CustomerOTPReference[]): void {
      this.hasresults = true;
      var test = x;
    }


    ngAfterViewInit() {
      this.columns = [
        { prop: 'invoice', name: 'Invoice', width: 100, cellClass: "left" },
        { prop: 'filterDate', name: 'Date', width: 100, cellClass: "left", cellTemplate: this.dateTemplate },
        { prop: 'transaction', name: 'Transaction', width: 150, cellClass: "left" },
        { prop: 'customerPoNumber', name: 'Customer Po Number', width: 210, cellClass: "left" },
        { prop: 'reference', name: 'Transaction Reference', width: 210, cellClass: "left" },
       // { prop: 'operator', name: 'Operator', width: 80, cellClass: "left" },
        { prop: 'amount', name: 'Total (Incl VAT)', width: 210, cellClass: "right" }
      ];

    }
  
  toggle() {
    this.show = true;
  }

  dateFilterChanged() {
    if (this.currentCustomer) {
      var start = new Date(this.selected.startDate.toString());
      var end = new Date(this.selected.endDate.toString());
      this.rows = this.rowsCache.filter(m => new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
      if (this.rows && this.rows.length >= 1) {

        if (this.chkCr) {
          this.rows = this.rowsCache.filter(m => m.transaction == "Credit" && new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
        }
        else {
          if (this.chkDb) {
            this.rows = this.rowsCache.filter(m => m.transaction == "Payment" && new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
          }
          else {
            this.rows = this.rowsCache.filter(m => new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
          }
        }
      }
    }
    else {
      //this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
      //  MessageSeverity.warn);
    }
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadDetails();
   // this.dateFilterChanged();
    this.show = false;
  }


  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadDetails();
    //this.dateFilterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }
    this.loadDetails();
   // this.dateFilterChanged();

    this.show = false;
  }

  showPaymentsOnly() {
    this.chkDb = !this.chkDb;
    this.chkCr = false;
    this.dateFilterChanged();
  }

  showCreditsOnly() {
    this.chkDb = false;
    this.chkCr = !this.chkCr;
    this.dateFilterChanged();
  }

  loadData() {
    this.searchType = "customer";
    let action = sessionStorage.getItem(localStorageActions.Customer);
    if (action && action.length >= 4) {
      this.id = action;
      this.currentCustomer = this.id;
      this.searchText = this.id;
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      //this.sysproService.getCustomerDetail(this.id).subscribe(
      //  x => this.onSpecificSearchSuccesful([x]), error => this.onDataLoadFailed(error));
    }
    else {

    }
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.customerPaymentsList = null;
      this.rowsCache = null;
      this.rows = null;

      //this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
      //  x => this.onSpecificSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }

  }
    
  onDataLoadSuccessful(customers: any[]) {

    this.customerPaymentsList = customers[0];

    if (this.customerPaymentsList && this.customerPaymentsList.length >= 1) {

      this.customerPaymentsList.forEach((customer, index, customers) => {
        (<any>customer).index = index + 1;
      });

      this.rowsCache = [...this.customerPaymentsList];

      this.dateFilterChanged();

    }
    else {
      this.alertService.showMessage('No results', 'No payment details for the search criteria',
        MessageSeverity.warn);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  private loadCustomer() {
    this.id = this.searchResultSelected.customer;
    this.currentCustomer = this.id;
    sessionStorage.removeItem(localStorageActions.Customer);
    sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);   
    this.customerHeading = this.searchResultSelected;
    this.loadDetails();
    this.router.navigate(['../../customers'], { fragment: 'payments', queryParams: { customer: this.currentCustomer } });
  }

    loadDetails() {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      var start = new Date(this.selected.startDate.toString());
      var end = new Date(this.selected.endDate.toString());


      this.customerPaymentsList = null;
      this.rowsCache = null;
      this.rows = null;

      /*this.sysproService.getCustomerPayments(start.toJSON(), end.toJSON(),this.id).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));*/
    }


  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }


  onCustomerLoadSuccessful(x: ArCustomer[]): void {
    this.customerSearch = x;
    this.customerHeading = this.customerSearch.find(x => x.customer == this.currentCustomer);
    setTimeout(() => {
      if (this.customerSelector) {
        this.customerSelector.refresh();
      }
    });
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Customer", "Invoice", "Date", "Transaction", "Customer PO", "Payment Ref",
         "Operator",
        "Total (Incl VAT)"]
    };

    var filename = this.id + "-Payments";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Customer: row.customer, Invoice: row.invoice, Date: (moment(row.filterDate)).format('DD-MMM-YYYY'), Transaction: row.transaction,
        CustomerPO: row.customerPoNumber, PaymentRef: row.reference,
        Operator: row.operator,
        Total: row.amount
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  onSearchChanged(value: string) {
      if (value != "") {
        this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.invoice, r.date, r.customerPoNumber, r.operator,  r.transaction, r.reference, r.amount));
    }

      else {
        this.dateFilterChanged();
      }
    }



  onEmailLoadSuccessful(type?: string) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var uniqueCode = this.currentCustomer + "-" + this.generateOTP();
    this.customerOTP = uniqueCode;

    this.emailObject.RecepientName = this.customerHeading.contact;
    this.emailObject.RecepientEmail = this.customerHeading.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Customer Account: " + this.currentCustomer;
    //this.emailObject.SenderName = this.accountService.currentUser.fullName;
    //this.emailObject.SenderEmail = this.accountService.currentUser.email;
    //this.emailObject.SenderBranch = this.branchCode;
    //this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = "CustomerAccount_" + uniqueCode + ".pdf";
    this.emailObject.Bod = "Please use this number when accesing the documents referenced in this document: " + uniqueCode;
    this.emailObject.EmailType = "Statement";

    console.log(this.emailObject);


    var start = new Date(this.selected.startDate.toString());
    var end = new Date(this.selected.endDate.toString());

   // this.reportService.getStatementPDF(this.currentCustomer, this.application, start.toJSON(), end.toJSON(), uniqueCode).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));
    //console.log(this.quoteHeader);

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  generateOTP() {

    // Declare a digits variable 
    // which stores all digits
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }


  viewPDF() {
    this.reportService.getFileStreamStatement(this.emailObject.Attachment).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {

    this.reportService.getFileStreamStatement(this.emailObject.Attachment).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  emailPDF() {


    if (this.emailObject.RecepientEmail && this.emailObject.RecepientEmail.trim().length >= 1) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      //this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showMessage('Warning', `Remeber to add email address for recepient`, MessageSeverity.warn);
    }

  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }

  getPDF(x: Blob, quoteNumber: string): void {

    this.reportService.getFileStreamStatement(this.customerOTP).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = this.emailObject.Attachment;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

}
