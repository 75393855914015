import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
//import { AccountService } from '../../services/account.service';
import { Utilities } from '../../../services/utilities';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
//import { User } from '../../models/user.model';
//import { UserEdit } from '../../models/user-edit.model';
//import { Role } from '../../models/role.model';
import { Branch } from '../../../models/branch.model';

import { CreditNoteSalesReport } from '../../../models/creditnote-report.model';

//import { ArCustomer } from '../../models/arcustomer.model';
//import { CartHeader } from '../../models/cart-header.model';
//import { ArCustomerEdit } from '../../models/arcustomer-edit.model';
//import { SalesPerson } from '../../models/salesperson.model';
import { AspnetApplications } from '../../../models/applications.model';
//import { Permission } from '../../models/permission.model';
import { SysproService } from '../../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv';

//import { ArNarration } from 'src/app/models/arnarration.model';
//import { localStorageActions } from 'src/app/models/enums';
//import { ConfigurationService } from 'src/app/services/configuration.service';


@Component({
  selector: 'reports-credit-notes-sales',
  templateUrl: './reports-credit-notes-sales-component.html',
  styleUrls: ['./reports-credit-notes-sales-component.scss']
})
export class ReportsCreditNotesSalesComponent implements OnInit {

  private isEditMode = false;

  allBranches: Branch[] = [];

  allApplications: AspnetApplications[] = [];

  rowsCreditNoteSales: CreditNoteSalesReport[] = [];

  columnsCreditNoteSales: any[] = [];

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  searchType: string = "ALL";

  applicationType: string = "00000000-0000-0000-0000-000000000000";

  private loadingIndicatorCreditNotesSales: boolean;

  show: boolean;

  selected: { startDate: Moment, endDate: Moment };

  @ViewChild('branches')
  private branches;

  @ViewChild('searchBy-search')
  private branchSelector;
  branchSelected: Branch;
  private selectUndefinedOptionValue: string = "All";

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService) {
  }

  ngOnInit() {

    this.columnsCreditNoteSales = [
      { prop: 'operator', name: 'Operator', width: 200, cellClass: "left" },
      { prop: 'role', name: 'Role', width: 120, cellClass: "left" },
      //{ prop: 'startDate', name: 'Start Date', width: 200, cellClass: "left" },
      //{ prop: 'endDate', name: 'End Date', width: 200, cellClass: "left" },
      { prop: 'company', name: 'Company', width: 120, cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: 50, cellClass: "left" },
      { prop: 'getFormattedTotalCreditNotesValue', name: 'Total Credit Notes Value', width: 120, cellClass: "right" },
      { prop: 'getFormattedTotalDiscountValue', name: 'Total Discount Given', width: 120, cellClass: "right" },
      { prop: 'getFormattedTotalMerchValue', name: 'Total Merch Value', width: 120, cellClass: "right" },
      { prop: 'getFormattedTotalCost', name: 'Total Cost', width: 120, cellClass: "right" },
      { prop: 'getFormattedTotalProfit', name: 'Total Profit', width: 120, cellClass: "right" }

    ];

    this.allBranches.push(new Branch("ALL"));

    let app: AspnetApplications;

    app = new AspnetApplications();

    app.applicationName = "ALL";

    app.applicationId = "00000000-0000-0000-0000-000000000000";

    this.allApplications.push(app);

    this.selected = {
      startDate: moment().subtract(2, 'year').startOf('year'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.getAllBranches();

    this.getAllCompanies();
  }

  getAllBranches() {
    this.alertService.startLoadingMessage();
   
    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));
  }

  onBranchLoadSuccessful(branches: Branch[]) {

    this.alertService.stopLoadingMessage();

    this.allBranches = [...this.allBranches, ...branches];

    console.log("Branches", this.allBranches);

    this.getCreditNotesSalesData();
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    //this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  getAllCompanies() {
    this.alertService.startLoadingMessage();

    this.fowkesOnline.getApplications().subscribe(x => this.onAppLoadSuccessful(x), error => this.onAppDataLoadFailed(error));

    }

  onAppLoadSuccessful(applications: AspnetApplications[]) {
     this.alertService.stopLoadingMessage();

     this.allApplications = [...this.allApplications, ...applications];

     console.log("allApplications", this.allApplications);
  }

  onAppDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve company data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  getCreditNotesSalesData() {

    this.loadingIndicatorCreditNotesSales = true;

    this.show = false;

    this.alertService.startLoadingMessage();

    let startD: Date = new Date(this.selected.startDate.toString());

    let endD: Date = new Date(this.selected.endDate.toString());

    this.sysproService.getCreditNoteSales(startD.toJSON(), endD.toJSON(), this.searchType, this.applicationType).subscribe(x => this.onCreditNoteSalesDataLoadSuccessful([x]), error => this.onCreditNoteSalesDataLoadFailed(error));

  }

  onCreditNoteSalesDataLoadSuccessful(sales: any[]) {
    this.alertService.stopLoadingMessage();

    this.rowsCreditNoteSales = sales[0];

    this.loadingIndicatorCreditNotesSales = false;

    this.alertService.stopLoadingMessage();
  }

  onCreditNoteSalesDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicatorCreditNotesSales = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve credit note sales data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);

  }

  toggle() {
    this.show = true;
  }

  //dateQuoteSalesFilterChanged() {

    //var start = new Date(this.selected.startDate.toString());

    //var end = new Date(this.selected.endDate.toString());

    //this.rowsQuoteSales = this.rowsQuoteSalesCache.filter(m => new Date(m.startDate) >= new Date(start) && new Date(m.startDate) <= new Date(end));
  //}

  //dateCreditNoteSalesFilterChanged() {

    //var start = new Date(this.selected.startDate.toString());

    //var end = new Date(this.selected.endDate.toString());

    //this.rowsCreditNoteSales = this.rowsCreditNoteSalesCache.filter(m => new Date(m.startDate) >= new Date(start) && new Date(m.startDate) <= new Date(end));
  //}

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    let dateDiff: any = moment().subtract(2, 'year').startOf('month');

    if (this.selected.startDate < dateDiff) {
      this.alertService.showMessage('', `Date range further than 2 years`, MessageSeverity.warn);

      return;
    }

    let startD: Date = new Date(this.selected.startDate.toString());

    let endD: Date = new Date(this.selected.endDate.toString());

    this.sysproService.getCreditNoteSales(startD.toJSON(), endD.toJSON(), this.searchType, this.applicationType).subscribe(x => this.onCreditNoteSalesDataLoadSuccessful([x]), error => this.onCreditNoteSalesDataLoadFailed(error));

    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    let dateDiff: any = moment().subtract(2, 'year').startOf('month');

    if (this.selected.startDate < dateDiff) {
      this.alertService.showMessage('', `Date range further than 2 years`, MessageSeverity.warn);

      return;
    }

    let startD: Date = new Date(this.selected.startDate.toString());

    let endD: Date = new Date(this.selected.endDate.toString());

    this.sysproService.getCreditNoteSales(startD.toJSON(), endD.toJSON(), this.searchType, this.applicationType).subscribe(x => this.onCreditNoteSalesDataLoadSuccessful([x]), error => this.onCreditNoteSalesDataLoadFailed(error));

    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    //this.dateQuoteSalesFilterChanged();

    //this.dateCreditNoteSalesFilterChanged();

    this.show = false;
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Operator", "Role", "Company", "Branch", "TotalCreditNotesValue",
        "TotalDiscountValue", "TotalMerchValue", "TotalCost",
        "TotalProfit"]
    };

    var filename = "CreditNotesSales";
    var selectRows = [...this.rowsCreditNoteSales];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Operator: row.operator, Role: row.role, Company: row.company, Branch: row.branch,
        TotalCreditNotesValue: row.getFormattedTotalCreditNotesValue, TotalDiscountValue: row.getFormattedTotalDiscountValue,
        TotalMerchValue: row.getFormattedTotalMerchValue, TotalCost: row.getFormattedTotalCost,
        TotalProfit: row.getFormattedTotalProfit
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
