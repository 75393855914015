
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { Email } from 'src/app/models/email.model';
import { localStorageActions } from 'src/app/models/enums';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Inventory } from 'src/app/models/inventory.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CMCategories } from '../../models/cms-category.model';
import { Permission } from '../../models/permission.model';
import { SimpleTree } from '../../models/simple-tree.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { ReportService } from '../../services/report.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';

import { CoreAudit } from '../../models/core-audit.model';
import { AuthService } from '../../services/auth.service';



@Component({
  selector: 'products-catalogue',
  templateUrl: './products-catalogue.component.html',
  styleUrls: ['./products-catalogue.component.scss']
})

  
export class ProductsCatalogueComponent implements OnInit {

  auditObj = new CoreAudit();

  columns: any[] = [];
  rows: Inventory[] = [];
  rowsCache: Inventory[] = [];

  loadingIndicator: boolean;

  items: TreeviewItem[];
  treeItems: TreeviewItem[] = [];
  treeItem: any;
  treerows: string[];
  item: any;
  data: CMCategories;
  categoryData: CMCategories[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild(TreeviewComponent)
  categoryTreeviewComponent: TreeviewComponent;

  @ViewChild('treeModal')
  treeModal: ModalDirective;

  @ViewChild('treeItemTemplate')


    @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;


  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('sellpriceTemplate')
  sellpriceTemplate: TemplateRef<any>;

  @ViewChild('unitCostTemplate')
  unitCostTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;



  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;


  emailObject: Email = new Email();

  @ViewChild('emailModal')
  emailModal: ModalDirective;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  stockCode: string;

    id: string;
    cartId: string;
    customer: ArCustomer;
    stockCodedetail: Inventory;
    isNavigating: boolean;
    isSaving: boolean;
    cartSaved: boolean;
    cartlinenumber: number = 0;
    saveCount: number;
    whDetail: WHAvailQTY[] = [];
  stockDescription: string;
    isNavigatingTo: string;
    hasDataSheets: boolean;
    hasSpecials: boolean;
    alternateKey: string;
    savedLineCount: number;
    isReloading: boolean;
  src: string;
  searchTerm: string = "";
  chkNew: boolean;
  chkSp: boolean;
  chkAlt: boolean;
  chkDis: any;
  chkUnmapped: boolean;
  chkAll: boolean;
  chkDt1: boolean;
  chkDt2: boolean;


  searchType: string = "";
  searchText: string = "";
  reportTitle: string = "";
  reportType: string = "";

  searchResults: Inventory[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  attachDataSheets: boolean;
  attachSpecials: boolean;

  inventoryList: Inventory[];
  searchingOn: string;
  chkShortDescription: boolean;
  chkLongDescription: boolean;
  chkAnyDescription: boolean;
    hasAddDataSheets: boolean;
    buyerResponsible: string;
    brandList: any[];
    newParentName: string;
  selectedBrand: string;
  chkIncludeIndexPage: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService, private reportService: ReportService, 
    private router: Router, private route: ActivatedRoute, private numberPipe: CurrencyPipe, private authService: AuthService) {
  }


  ngOnInit() {
    this.auditObj.referenceType = "CATALOGUE";
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.authService.currentUser.email;
    this.auditObj.actionedBy = this.authService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;

    this.chkIncludeIndexPage = false;
    this.SetDataGrid();

    this.loadData();
  }

  
  private SetDataGrid() {
    
    var variableWidth = this.canManageInventory ? 100 : 160;
    this.columns = [
      { prop: 'stockCode', name: 'StockCode', width: variableWidth, cellTemplate: this.stockCodeTemplate, cellClass: "left" },
      { prop: 'description', name: 'Description', width: 370, cellTemplate: this.descriptionTemplate, cellClass: "left" },
      { prop: 'brandName', name: 'Brand', width: 70, cellClass: "right" },
      { prop: 'categoryTree', name: 'Category', width: 120, cellClass: "right" },
      { prop: 'uom', name: 'UOM', width: 50, cellClass: "center" },
      //{ prop: 'warehouse', name: 'WH', width: 50, cellClass: "center" },
      { prop: 'availQty', name: 'Qty Available', width: 50, cellClass: "right" },
      //{ prop: 'qtyOnOrder', name: 'Qty On Order', width: 70, cellClass: "right" },
      //{ prop: 'qtyInTransit', name: 'Qty In Transit', width: 50, cellClass: "right" },
      { prop: 'specialPrice', name: 'Special Price', width: 90, cellTemplate: this.priceTemplate, cellClass: "right", pipe: { transform: this.currencyPipe } }
      //{ prop: 'unitCost', name: 'Unit Cost', width: 90, cellTemplate: this.unitCostTemplate, cellClass: "right", pipe: { transform: this.currencyPipe } }
    ];

    if (this.canManageInventory) {
      this.columns.push({ name: 'CMS', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    }
  }

  currencyPipe(value: any, ...args: any[]) {
    let updated = value.toFixed(2);
    updated += '';
    let x = updated.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
    //return value.toFixed(2);
  }

  ngAfterViewInit() {
    this.SetDataGrid();
  }


  loadData() {

    this.searchType = "";
    this.searchText = "";
    this.searchTerm = "";

    this.sysproService.getBrands().subscribe(x => this.onBrandsLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onCategoriesLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  specificSearch() {
    if (this.searchType != "") {

      this.rows = [];
      this.rowsCache = [];


      this.auditObj.referenceNumber = this.searchType;

      switch (this.searchType) {
        case "new": {

          this.alertService.startLoadingMessage();
          this.loadingIndicator = true;
          this.reportType = "report";
          this.reportTitle = "New In";
          this.searchText = "new";
          this.sysproService.getStockCodeSearchByCriteria(this.branchCode, "report", "new").subscribe(x => this.onStockLoadSuccessful([x], false),
            error => this.onDataLoadFailed(error));

          break;
        }
        case "specialsF": {

          this.alertService.startLoadingMessage();
          this.loadingIndicator = true;

          this.reportType = "report";
          this.reportTitle = "Fowkes Bros. Specials";
          this.searchText = "specialsF";
          this.sysproService.getStockCodeSearchByCriteria(this.branchCode, "report", "specialsF").subscribe(x => this.onStockLoadSuccessful([x], false),
            error => this.onDataLoadFailed(error));

          //this.rows = this.rowsCache.filter(x => x.onSpecialFowkes == true);

          break;
        }
        case "specialsM": {

          this.alertService.startLoadingMessage();
          this.loadingIndicator = true;

          this.reportType = "report";
          this.reportTitle = "Max Arcus Specials";
          this.searchText = "specialsM";
          this.sysproService.getStockCodeSearchByCriteria(this.branchCode, "report", "specialsM").subscribe(x => this.onStockLoadSuccessful([x], false),
            error => this.onDataLoadFailed(error));

          break;
        }

        case "category": {

          this.searchText = "";
          this.reportType = "";
          this.loadCategoryModal();

          break;
        }
        case "brand": {
          if (this.selectedBrand && this.selectedBrand != "") {

            this.alertService.startLoadingMessage();
            this.loadingIndicator = true;

            this.reportType = "reportbrand";
            this.reportTitle = this.selectedBrand;
            this.searchText = this.selectedBrand;
            this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.reportType, this.selectedBrand).subscribe(x => this.onStockLoadSuccessful([x], false),
              error => this.onDataLoadFailed(error));
          }
          break;
        }
        case "catLowList": {

          this.alertService.startLoadingMessage();
          this.loadingIndicator = true;

          this.reportType = "report";
          this.reportTitle = "Category List";
          this.searchText = "catLowList";
          this.createCatalogue();
          //this.sysproService.getStockCodeSearchByCriteria(this.branchCode, "report", "catLowList").subscribe(x => this.onStockLoadSuccessful([x], false),
          //  error => this.onDataLoadFailed(error));

          break;
        }
        default: {
          this.searchText = "";
          this.reportType = "";
          //statements; 
          break;
        }
      } 

      this.isReloading = false;
      this.auditObj.actionType = "SEARCH";
      this.auditObj.referenceNumber = this.reportTitle;
      this.auditObj.actionInput = this.searchText;

      this.postAudit();
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onBrandsLoadSuccessful(brands: any[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (brands[0]) {
      this.brandList = [...brands[0]];
    }
  }

  onCategoriesLoadSuccessful(treeview: any[]) {
    if (treeview[0]) {
      let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

      this.items = this.getItems([treeviewItems]);
      this.treeItems = this.getItems([treeviewItems]);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }

  list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentCategory !== "Root") {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parentCategory]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }


  loadCategoryModal() {
    if (!this.treeModal) {
      setTimeout(() => {
        if (this.treeModal) {
          this.treeModal.show();
        }
      });
    }
    else {
      this.treeModal.show();
    }
  }


  selectTreeItem(row: TreeviewItem) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.newParentName = row.text;

    this.reportType = "reportcategory";
    this.reportTitle = this.newParentName;
    this.searchText = this.newParentName;

    this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.reportType, this.newParentName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onStockLoadSuccessful([x], false),
      error => this.onDataLoadFailed(error));

    this.treeModal.hide();

  }


  treeItemBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
      }
    }
  }

  onSpecificSearchSuccesful(x: Inventory[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;
    this.onStockLoadSuccessful([x], false);

  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }
    
  onDataLoadSuccessful(customer: ArCustomer) {
    this.customer = customer;
  }

  onStockLoadSuccessful(inventory: any[], trim?: boolean) {

    this.searchTerm = "";
    this.inventoryList = inventory[0];

    this.inventoryList.forEach((stockCode, index, inventory) => {
      (<any>stockCode).index = index + 1;
      stockCode.description = stockCode.shortDescription + ' - ' + stockCode.longDescription;
      //stockCode.sellingPrice = stockCode.sellingPrice.toFixed(2);
    });

    this.rowsCache = [...this.inventoryList];
    this.rows = [...this.rowsCache];

    this.isReloading = false;
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    
   
  }

  createCatalogue() {

    this.alertService.startLoadingMessage("Generating Report...");
    this.loadingIndicator = true;

    var extrafilter = (this.searchTerm && this.searchTerm != "" ? this.searchTerm : "ALL");

    this.reportService.getCataloguePDF(this.branchCode, this.application, this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"),
      this.reportType, this.searchText.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"),
      this.chkIncludeIndexPage, extrafilter.replace(/\//g, "--").replace(/\%/gi, "---").replace(/\+/gi, "___")).subscribe(x => this.previewCatalogue(), error => this.onDataLoadFailed(error));
  }


  previewCatalogue() {

    this.auditObj.actionInput = this.reportTitle + " Catalogue";
    this.auditObj.actionType = "GENERATE";

    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.accountService.currentUser.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = this.reportTitle + " Catalogue";
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = this.reportTitle;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Catalogue";

    this.reportService.getFileStreamCatalogue(this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }



  onFileSuccessful(x: Blob, preview: boolean): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var attachement = this.emailObject.Attachment.replace('/', '_').replace('+', '_').replace('%', '_');

    this.src = url;

    if (!preview) {
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = attachement + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  viewPDF() {
    var attachement = this.emailObject.Attachment;
    if (this.emailObject.EmailType == "Inventory") {

      this.reportService.getFileStreamInventory(attachement.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));

    } else {

      this.reportService.getFileStreamCatalogue(this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));

      this.auditObj.actionType = "VIEW";
      this.auditObj.referenceNumber = this.reportTitle;
      this.auditObj.actionInput = this.reportTitle;

      this.postAudit();
    }
  }

  previewPDF() {
    var attachement = this.emailObject.Attachment;
    if (this.emailObject.EmailType == "Inventory") {

      this.reportService.getFileStreamInventory(attachement.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));

    } else {

      this.reportService.getFileStreamCatalogue(this.reportTitle.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));

      this.auditObj.actionType = "PRINT";
      this.auditObj.referenceNumber = this.reportTitle;
      this.auditObj.actionInput = this.reportTitle;

      this.postAudit();
    }
  }

  emailPDF() {

    console.log(this.emailObject);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    if (this.emailObject.EmailType == "Inventory") {
      this.emailObject.AddDoc1 = (this.attachDataSheets && this.hasDataSheets ? this.emailObject.AddDoc1 : "");
      this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");
      this.emailObject.AddDoc3 = (this.attachDataSheets && this.hasAddDataSheets ? this.emailObject.AddDoc3 : "");
    }
    else {
      this.auditObj.referenceNumber = this.reportTitle;
      this.auditObj.actionType = "EMAIL";
      this.auditObj.actionOutput = this.emailObject.RecepientEmail;

      this.postAudit();
    }
    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));


  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
   
    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      console.log(error.status);
      if (error.status == 404) { return;}
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      var filterValues = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.description, r.stockCode, r.brandName, r.categoryName));    

      this.rows = this._sortByTerm(filterValues, "description", value.toUpperCase());
     
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  _sortByTerm(data, key, term) {
    return data.sort(function (a, b) {
      return a[key].indexOf(term) < b[key].indexOf(term) ? -1 : 1;
    });
  }

  _sortByrank(array, searchKey, props) {
    if (!array || !searchKey || !props) return array;

    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.rankSearch = 0;
      for (var j = 0; j < props.length; j++) {
        var index = obj[props[j]].indexOf(searchKey);
        // i should probably spend time tweaking these arbitrary numbers
        // to find good values that produce the best results, but
        // here's what I have so far...
        obj.rankSearch += (index === -1 ? 15 : index) * ((j + 1) * 8);
      }
    }

    return array;
  }


  results() {

    this.auditObj.actionType = "CSV";
    this.auditObj.referenceNumber = this.reportTitle;
    this.auditObj.actionInput = this.searchText;

    this.postAudit();

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "UOM", "WH", "Qty On Hand", "Bin", "Qty On Order", "Qty In Transit",
        "Selling Price", "Special Price", "Unit Cost", "Brand", "StockCodeImage", "DataSheetImage", "DataSheetImage2", "SpecialsImage", "Status",
        "NewArrival", "OnSpecial", "ShowAlternate", "AlternateKey", "Mapped", "Category", "CategoryTree"]
    };

    var filename = "InventoryList";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        StockCode: row.stockCode, Description: row.description, UOM: row.uom, WH: row.warehouse,
        QtyOnHand: row.qtyOnHand, Bin: row.binLoc, QtyOnOrder: row.qtyOnOrder, QtyInTransit: row.qtyInTransit,
        SellingPrice: row.sellingPrice, SpecialPrice: row.specialPrice, UnitCost: row.unitCost, Brand: row.brandName, StockCodeImage: row.stockCodeImage,
        DataSheetImage: row.dataSheetImage, DataSheetImage2: row.addSheetImage, SpecialsImage: row.specialsImage, Status: row.status,
        NewArrival: row.newArrival, OnSpecial: row.onSpecial, ShowAlternate: row.showAlternate,
        AlternateKey: row.alternateKey, Mapped: row.mapped, Category: row.categoryName, CategoryTree: row.categoryTree
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }



  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
   
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.description;
      this.stockCode = this.stockCodedetail.stockCode;
      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.addSheetImage = row.addSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.onSpecialFowkes = row.onSpecialFowkes;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }


  editStockCode(row: Inventory) {
    if (row) {
      if (sessionStorage.getItem(localStorageActions.StockCode) != row.stockCode) {
        sessionStorage.setItem(localStorageActions.StockCode, row.stockCode);
      }
      this.router.navigate(['../../products'], { fragment: 'stockcodes' });
    }    
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey &&  row.mapped) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.alternateKey;
      this.stockCode = this.stockCodedetail.stockCode;
      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }


  openSpecials(row: Inventory) {
    if (!!row.specialsImage && row.specialsImage != "") {
      window.open(row.filePath + "/SpecialImages/" + row.specialsImage, "_blank");
    }
  }

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
     
    }
  }


  onEmailLoadSuccessful() {
    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasAddDataSheets = false;
    this.hasSpecials = false;

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);
    this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);

    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage.length >= 1 && (this.stockCodedetail.onSpecial || this.stockCodedetail.onSpecialFowkes));

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"), this.branchCode, this.application).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));

    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.accountService.currentUser.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();     
    }

    if (this.hasAddDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  get application(): string {
    return this.configurations.applicationName;
  }


  get canManageInventory() {
    return this.accountService.userHasPermission(Permission.manageInventoryPermission);
  }


  private postAudit() {
    this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  }
}
