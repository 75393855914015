import { PurchaseOrderList } from "./purchaseorder-list.model";
import { Supplier } from "./supplier.model";

export class JobCard {
  id: number;
  jobCardNumber: string;
  referenceNumber: string;
  customerCode: string;
  customerName: string;
  machineDescription: string;
  statusId: number;
  repairTime: number;
  dateMachineReceived: string;
  invoiceDate?: Date;
  invoiceNumber?: string;
  salesOrderNumber?: string;
  quoteNumber?: string;
  comments?: string;
  assignedTechnician?: string;
  completionDate?: Date;
  costOfRepair: number;
  branchCode: string;
  createdDate: Date;
  modifiedDate?: Date;
  customerContactInfo?: string;
  purchaseOrderNumber?: string;
  formattedDate: string;
  statusName: string;
  jobContactName: string;
  jobContactEmail: string;
  jobContactTelephone: string;
  binNumber: string;
  supplierCode: string;
  supplierName: string;
  ptrNumber: string;
  poNumbers: PurchaseOrderList[];
  salespersonEmail: string;
  salesPersonName: string;
  salesPersonOperator: string;
  supplierEmail: string;
  supplierContact: string;
  supplierTelephone: string;
  customerDescription: string
  company: string;
  accessories: string;
  accessoriesList: string[] = [];
  additionalSuppliers: JobCardSuppliers[] = [];
  originalInvoice: string | null;
  originalInvoiceDate: Date | null;
  originalInvoiceDetails: string;
  originalInvoiceHasPODs: boolean;
  modifiedBy: string;
  daysSinceStatusChanged: number;
  lastStatusChangeDate: Date | null;
  isHighlight : boolean;

  constructor() {
    this.id = 0;
    this.jobCardNumber = '';
    this.referenceNumber = '';
    this.customerCode = '';
    this.customerName = '';
    this.machineDescription = '';
    this.statusId = 0;
    this.repairTime = 0.00;
    this.dateMachineReceived = new Date().toISOString();
    this.invoiceDate = undefined;
    this.invoiceNumber = '';
    this.salesOrderNumber = '';
    this.quoteNumber = '';
    this.comments = '';
    this.assignedTechnician = '';
    this.completionDate = undefined;
    this.costOfRepair = 0.00;
    this.branchCode = '';
    this.createdDate = new Date();
    this.modifiedDate = undefined;
    this.customerContactInfo = '';
    this.purchaseOrderNumber = '';
    this.jobContactName = '';
    this.jobContactEmail = '';
    this.jobContactTelephone = '';
    this.binNumber = '';
    this.supplierCode = '';
    this.supplierName = '';
    this.ptrNumber = '';
    this.salespersonEmail = '';
    this.salesPersonName = '';
    this.salesPersonOperator = '';
    this.supplierEmail = '';
    this.supplierContact = '';
    this.supplierTelephone = '';
    this.accessories = '';
    this.accessoriesList = [];
    this.originalInvoice = '';
    this.originalInvoiceDate = null;
    this.originalInvoiceDetails = '';
    this.originalInvoiceHasPODs = true;
    this.modifiedBy = '';
    this.daysSinceStatusChanged = 0;
  }
}

export class CoreJCStatus {
  statusId: number;
  statusName: string;
  statusDescription?: string;
  isActive: boolean;
  createdDate: Date;
  modifiedDate: Date;

  constructor() {
    this.statusId = 0;
    this.statusName = '';
    this.statusDescription = '';
    this.isActive = true;
    this.createdDate = new Date();
    this.modifiedDate = new Date();
  }
}


export class JobCardStatusHistory {
  id: number;           // Primary key
  jobCardId: number;     // Foreign key to the job card
  updateType: string;     // The previous status name
  newStatus: string;     // The new status name
  changedBy: string;     // The user who made the change
  changeDate: Date;      // When the status was changed
  notes: string;         // Any notes related to the change

  constructor() {
    this.id = 0;
    this.jobCardId = 0;
    this.updateType = '';
    this.newStatus = '';
    this.changedBy = '';
    this.changeDate = new Date();
    this.notes = '';
  }
}

export class JobCardPredefinedComments {
  id: number;         
  commentText: string;
  sequence: number;

  constructor() {
    this.id = 0;
    this.commentText = '';
    this.sequence = 0;
  }
}


export class JobCardSuppliers {
  id: number;
  jobCardId: number;
  supplierCode: string;
  supplierName: string;
  supplierEmail: string;
  supplierTelephone: string;
  addedDate: Date;

  // New fields
  originalInvoice: string | null;
  originalInvoiceDate: Date | null;
  supplierSpecificPo: string | null;
  completedDate: Date | null;
  supplierStatus: string;

  constructor() {
    this.id = 0;
    this.jobCardId = 0;
    this.supplierCode = '';
    this.supplierName = '';
    this.supplierEmail = '';
    this.supplierTelephone = '';
    this.addedDate = new Date();
    this.originalInvoice = null;
    this.originalInvoiceDate = null;
    this.supplierSpecificPo = null;
    this.completedDate = null;
    this.supplierStatus = '';
  }
}
