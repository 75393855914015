import { HttpClient } from '@angular/common/http';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { User } from '../../../models/user.model';
import { Inbox, InboxMessageDetails, Message, Outbox } from '../../../models/wapp-inbox.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ReportService } from '../../../services/report.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ConfigurationService } from '../../../services/configuration.service';
import { localStorageActions } from '../../../models/enums';

@Component({
  selector: 'whatsapp-sales-inbox',
  templateUrl: './whatsapp-sales-inbox.component.html',
  styleUrls: ['./whatsapp-sales-inbox.component.scss'],
  animations: [fadeInOut]
})
export class WhatsappSalesInboxComponent {

  @Input()
  isSpecificUser: boolean;

  isSaving: boolean;
  loadingIndicator: boolean;

  columns: any[] = [];

  inboxCache: Inbox[] = [];
  inbox: Inbox[] = [];
  inboxDetail: Inbox


  outboxColumns: any[] = [];
  outboxDetail: Outbox;
  outbox: Outbox[] = [];
  outboxCache: Outbox[] = [];

  @ViewChild('whatsappModal')
  whatsappModal: ModalDirective;


  @ViewChild('quotesModal')
  quotesModal: ModalDirective;

  emptyDetails: boolean;

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;


  @ViewChild('quoteTemplate')
  quoteTemplate: TemplateRef<any>;

  @ViewChild('resendTemplate')
  resendTemplate: TemplateRef<any>;

  searchTerm: string = "";
    selectedQuote: string;
    user: User;
  selectedInvoice: any;

  messages: InboxMessageDetails[] = [];  // Would typically be fetched from a service
  messagesCache: InboxMessageDetails[] = [];
  newMessage: string = '';
  selectedSenderMessages: InboxMessageDetails[] = [];
  groupedMessages: { [key: string]: InboxMessageDetails[] } = {};

  ranges = {
    'All Dates': [moment().subtract(5, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  show: boolean;

  selected: { startDate: Moment, endDate: Moment };

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;
    invoiceNumber: string;
  selectedSalesOrder: any;

  replyContent: string = '';
  selectedMessageId: string; // You need to set this when a message is selected
    selectedSenderNumber: any;


  constructor(private http: HttpClient, private alertService: AlertService, private fowkesService: FowkesOnlineService,
    private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private reportService: ReportService, private configurations: ConfigurationService) {
  }

  ngOnInit() {

    this.loadGrids();
    this.loadData();

  }

  ngAfterViewInit() {
    this.loadGrids();
  }


  private loadGrids() {
    this.columns = [
      { prop: 'timestamp', name: 'Received On', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'profileName', name: 'Name', width: 100 },
      { prop: 'content', name: 'content', width: 200 },
      { name: 'Reply', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      { prop: 'senderNumber', name: 'Received From', width: 100 }
    ];


    this.outboxColumns = [
      { prop: 'sentAt', name: 'Sent On', width: 150, cellTemplate: this.dateTemplate },
      { prop: 'content', name: 'Content', width: 150 },
      { prop: 'bodyParameters', name: 'Reference', width: 200, cellTemplate: this.quoteTemplate },
      { prop: 'status', name: 'Status', width: 250 },
      { prop: 'toPhoneNumber', name: 'Sent To', width: 100 },
      { prop: 'nextGenUser', name: 'Sent By', width: 200 }
    ];
  }

  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

     this.sysproService.GetInboxMessagesAsync().subscribe(x => this.OnInboxLoaded(x), error => this.onDataLoadFailed(error));

    if (this.isSpecificUser) {
      //Get Outbox Messages for logged in user
      this.user = this.accountService.currentUser;
      this.sysproService.GetUserTel(this.user.userName).subscribe(x => this.onTelReceived(x), error => this.onDataLoadFailed(error));

    }
    else {

      //this.selected = {
      //  startDate: moment().subtract(1, 'days'),
      //  endDate: moment().subtract(0, 'days').endOf('day')
      //}
    //this.sysproService.GetAllOutboxMessages().subscribe(x => this.OnOutboxLoaded(x), error => this.onDataLoadFailed(error));
     // this.sysproService.GetAllInboxMessages().subscribe(x => this.OnInboxLoaded(x), error => this.onDataLoadFailed(error));

    }

  }

  sendReply(messageId: string): void {
    const replyRequest = {
      replyToMessageId: messageId,
      content: this.replyContent,
      to: this.selectedSenderNumber // You need to have this from message selection
    };

    //this.messageService.replyToMessage(replyRequest).subscribe({
    //  next: (response) => {
    //    console.log('Reply sent successfully');
    //    this.replyContent = ''; // Clear the input field
    //  },
    //  error: (error) => {
    //    console.error('Failed to send reply', error);
    //  }
    //});
  }

  toggleMessage(message: Message): void {
    message.isExpanded = !message.isExpanded; // Toggle the expanded state
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onTelReceived(x: string): void {
    console.log(x);
    this.user.phoneNumber = x;
  }

  refresh() {
    this.loadData();
  }

    OnOutboxLoaded(x: Outbox[]): void {
      if (x[0]) {
        this.outboxCache = [...x];
        this.outbox = this.outboxCache;

        if (this.isSpecificUser) {
          this.outboxCache = this.outboxCache.filter(u => u.nextGenUser == this.user.userName);
          this.outbox = this.outboxCache;
        }

        //Filter out by company
        if (this.application == "MaxArcusOnline") {
          this.outboxCache = this.outboxCache.filter(u => u.nextGenUser.includes('maxarcus.co.za'));
          this.outbox = this.outboxCache;
        }
        else {
          this.outboxCache = this.outboxCache.filter(u => !u.nextGenUser.includes('maxarcus.co.za'));
          this.outbox = this.outboxCache;
        }

        if (this.searchTerm.trim() != "") {
          this.onSearchChanged(this.searchTerm);
        }

        this.dateFilterChanged();

      }
      else {
        this.emptyDetails = true;
      }


      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }

  // Update to handle an array of Inbox items
  private mapInboxToMessages(inboxes: Inbox[]): Message[] {
    return inboxes.map(inbox => ({
      id: inbox.id,
      senderName: inbox.profileName, // Assuming the profileName is what you use as senderName
      senderNumber: inbox.senderNumber,
      content: inbox.content,
      timestamp: new Date(inbox.timestamp), // Convert string or number to Date object
      isIncoming: this.determineIncoming(inbox), // Implement this based on your logic
      isExpanded: false
    }));
  }

  OnInboxLoaded(messages: InboxMessageDetails[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (messages && messages.length > 0) {

      const grouped = messages.reduce((acc, message) => {
        acc[message.senderNumber] = acc[message.senderNumber] || [];
        acc[message.senderNumber].push(message);
        return acc;
      }, {} as { [key: string]: InboxMessageDetails[] });

      this.messages = Object.values(grouped).map(group => {
        return group.reduce((prev, current) => {
          return (new Date(prev.timestamp) > new Date(current.timestamp)) ? prev : current;
        });
      }).sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp)); // Sort messages by most recent

      //this.messages = messages.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));
      this.messagesCache = messages;

      if (this.searchTerm.trim() !== "") {
        this.onSearchChanged(this.searchTerm);
      }
    } else {
      this.emptyDetails = true;
      this.messages = []; // Ensure messages is an empty array if no data is available
    }
  }

  private determineIncoming(inbox: Inbox): boolean {
    // Implement logic to determine if a message is incoming
    // This could be based on comparing senderNumber and receiverNumber
    return true; // Placeholder
  }

  openThread(senderNumber: string) {
    this.selectedSenderMessages = this.messagesCache.filter(msg => msg.senderNumber === senderNumber);
  }


  toggle() {
    this.show = true;
  }

  dateFilterChanged() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.outbox = this.outboxCache;

    if (this.outboxCache && this.outboxCache.length >= 1) {
    
      this.outbox = this.outboxCache.filter(m => new Date(m.createdAt) >= new Date(startD) && new Date(m.createdAt) <= new Date(endD));
    }

    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.dateFilterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }


    this.dateFilterChanged();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.dateFilterChanged();
    this.show = false;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    this.searchTerm = value.trim();
    if (this.searchTerm) {
      this.messages = this.messagesCache.filter(r => Utilities.searchArray(this.searchTerm, false, r.content, r.profileName, r.senderNumber));
    } else {
      this.messages = [...this.messagesCache];
    }
  }


  replyFreeText(row: Inbox) {

    this.inboxDetail = row;
    this.outboxDetail = new Outbox();

    this.outboxDetail.toPhoneNumber = this.inboxDetail.senderNumber;
    this.outboxDetail.content = "Hi " + this.inboxDetail.profileName;
    this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;
    //Needs to be a single line
    if (!this.whatsappModal) {
      setTimeout(() => {
        if (this.whatsappModal) {
          this.whatsappModal.show();
        }
      });
    }
    else {
      this.whatsappModal.show();
    }

  }

  resendMessage(row: Outbox) {

    this.outboxDetail = new Outbox();
    this.outboxDetail = row;

    if (row.toPhoneNumber) {
      this.sysproService.resendWhatsapp(this.outboxDetail, this.outboxDetail.nextGenUser).subscribe(x => this.onMessageSentSuccesful(), error => this.onPostDataLoadFailed(error));

    }

  }


  wappModalHide() {
    this.inboxDetail = new Inbox();
    this.outboxDetail = new Outbox();
    this.whatsappModal.hide();
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  sendMessage() {
    //Validate save
    if (this.outboxDetail) {
      if (this.outboxDetail.content) {

        if (this.outboxDetail.toPhoneNumber) {
          this.sysproService.SendReplyMessage(this.outboxDetail.toPhoneNumber, this.outboxDetail.content.replace(/(\r\n|\n|\r)/gm, " "), this.outboxDetail.nextGenUser).subscribe(x => this.onMessageSentSuccesful(), error => this.onPostDataLoadFailed(error));
        }
        else {

          this.alertService.showMessage('Warning', `Make sure to enter a phone number`, MessageSeverity.warn);

        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a message`, MessageSeverity.warn);
      }
    }
  }
    onMessageSentSuccesful(): void {
      this.whatsappModal.hide();
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', `Message sent successfully`, MessageSeverity.success);
      this.loadData();
    }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  previewQuote(row: Outbox) {
    this.selectedQuote = row.bodyParameters;

    this.reportService.getFileStream(this.selectedQuote).subscribe(x => this.onFileSuccessful(x, row.bodyParameters), error => this.onDataLoadFailed(error));

    //if (sessionStorage.getItem(localStorageActions.QuoteNumber) != this.selectedQuote ) {
    //  sessionStorage.setItem(localStorageActions.QuoteNumber, this.selectedQuote );
    //}

    //if (!!this.selectedQuote) {
    //  if (!this.quotesModal) {
    //    setTimeout(() => {
    //      if (this.quotesModal) {
    //        this.quotesModal.show();
    //      }
    //    });
    //  }
    //  else {
    //    this.quotesModal.show();
    //  }
    //}
  }

  quotesModalHide() {
    this.selectedQuote = null;
    this.quotesModal.hide();
  }

  gotToQuote() {
    this.router.navigate(['../../quotes'], { fragment: 'quote' });
  }


  previewPOD(row: Outbox) {
    this.sysproService.getFileStream(row.bodyParameters, 0).subscribe(x => this.onFileSuccessful(x, row.bodyParameters), error => this.onDataLoadFailed(error));
  }


  onFileSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["SentOn", "content", "attachment", "status", "SentTo", "nextGenUser"]
    };

    var filename = "Whatsapps";
    var selectRows = [...this.outbox];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        SentOn:  (moment(row.sentAt)).format('DD-MMM-YYYY HH:mm'), content: row.content, attachment: row.bodyParameters,
        status: row.status, SentTo: row.toPhoneNumber, nextGenUser: row.nextGenUser
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
  // this.alertService.showMessage(text, `Copied to Clipboard`, MessageSeverity.info);
  //copyTextToClipboard(text: string) {
  //  // Assert that navigator.clipboard exists
  //  const clipboard = (navigator as any).clipboard as Clipboard;
  //  if (clipboard && typeof clipboard.writeText === 'function') {
  //    clipboard.writeText(text).then(
  //      () => {
  //        console.log('Copying to clipboard was successful!');
  //        this.alertService.showMessage(text, `Copied to Clipboard`, MessageSeverity.info);
  //      },
  //      (err) => {
  //        console.error('Could not copy text: ', err);
  //      }
  //    );
  //  } else {
  //    console.error('Clipboard API is not available.');
  //   // You might want to fallback to a different method if Clipboard API is not available
  //  }
  //}

}
