
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { Establishment } from '../models/company-establishment.model';
import { ITSDriver } from '../models/invoice-tracking-driver.model';
import { TrackingAreas } from '../models/invoice-tracking-areas.model';
import { ITSDriverCages, ITSDriverCagesDetail } from '../models/invoice-tracking-driver-cages.model';
import { ITSVehicles } from '../models/invoice-tracking-vehicles.model';
import { ITSLabelPrinting } from '../models/invoice-tracking-label-printing.model';
import { ITSParcelTrackingDetailLog } from '../models/invoice-tracking-drivers-log.model';
import { WarehouseApplicationDefault } from '../models/warehouse-application-settings.model';
import { WarehouseUserDetails } from '../models/warehouse-user.model';
import { CoreJCStatus, JobCard, JobCardStatusHistory, JobCardSuppliers } from '../models/job-card.model';
import { Email } from '../models/email.model';


@Injectable()
export class FowkesOnlineEndpoint extends EndpointFactory {

  private readonly _branchesUrl: string = '/api/Branch/';
  private readonly _cartUrl: string = '/api/CartHeaders';
  private readonly _cartDetailUrl: string = '/api/CartDetails';
  private readonly _newCartUrl: string = '/api/CartHeaders';
  private readonly _applicationsUrl: string = '/api/AspnetApplications/GetAllApplications';
  private readonly _fowkesUrl: string = '/api/fowkesonline/';
  private readonly _trackingUrl: string = '/api/Tracking/';
  private readonly _itsUrl: string = '/api/ITS/';
  private readonly _fingerprintUrl: string = '/api/fingerprint/';
  private readonly _repairsUrl: string = '/api/ToolRepairs/';

  get branchesUrl() { return this.configurations.baseUrl + this._branchesUrl; }
  get applicationsUrl() { return this.configurations.baseUrl + this._applicationsUrl; }
  get cartUrl() { return this.configurations.baseUrl + this._cartUrl; }
  get newCartUrl() { return this.configurations.baseUrl + this._newCartUrl; }
  get fowkesUrl() { return this.configurations.baseUrl + this._fowkesUrl; }
  get trackingUrl() { return this.configurations.baseUrl + this._trackingUrl; }
  get itsUrl() { return this.configurations.baseUrl + this._itsUrl; }
  get fingerprintURL() { return this.configurations.baseUrl + this._fingerprintUrl; }
  get jobcardUrl() { return this.configurations.baseUrl + this._repairsUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getBranchesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.branchesUrl}GetAllBranches`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBranchesEndpoint());
      }));
  }


  verifyPasswordEndpoint<T>(branch: string, type: string, stringToCompare: string): Observable<T> {
    const endpointUrl = `${this.branchesUrl}VerifyPassword/${branch}/${type}/${stringToCompare}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.verifyPasswordEndpoint(branch, type, stringToCompare));
      }));
  }


  getApplicationsEndpoint<T>(): Observable<T> {
    const endpointUrl = this.applicationsUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getApplicationsEndpoint());
      }));
  }

  newOTPDetailEndpoint<T>(otpReferenceDetails: any): Observable<T> {

    return this.http.post<T>(`${this.fowkesUrl}PostOTPDetail`, JSON.stringify(otpReferenceDetails), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.newOTPDetailEndpoint(otpReferenceDetails));
      }));
  }

  newOTPHeaderEndpoint<T>(otpHeaderDetails: any): Observable<T> {

    return this.http.post<T>(`${this.fowkesUrl}PostOTPHeader`, JSON.stringify(otpHeaderDetails), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.newOTPHeaderEndpoint(otpHeaderDetails));
      }));
  }

  RemoveOTPCustomerEndpoint<T>(otpHeaderDetails: any): Observable<T> {

    return this.http.post<T>(`${this.fowkesUrl}RemoveOTPCustomer`, JSON.stringify(otpHeaderDetails), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.RemoveOTPCustomerEndpoint(otpHeaderDetails));
      }));
  }

  UpdateOTPCustomerEndpoint<T>(otpHeaderDetails: any): Observable<T> {

    return this.http.put<T>(`${this.fowkesUrl}UpdateOTPCustomerStatus`, JSON.stringify(otpHeaderDetails), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.UpdateOTPCustomerEndpoint(otpHeaderDetails));
      }));
  }

  getOTPDetailsEndpoint<T>(otp: string): Observable<T> {
    const endpointUrl = `${this.fowkesUrl}GetDocsForOTP/${encodeURIComponent(otp)}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getOTPDetailsEndpoint(otp));
      }));
  }

  getCustomerPaymentsEndpoint<T>(startDate?: string, endDate?: string, customer?: string): Observable<T> {
    const endpointUrl = `${this.fowkesUrl}GetCustomerPayments/${startDate}/${endDate}/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerPaymentsEndpoint(startDate, endDate, customer));
      }));
  }

  getCustomerDetailEndpoint<T>(customerId?: string): Observable<T> {
    const endpointUrl = `${this.fowkesUrl}GetCustDetails/${customerId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerDetailEndpoint(customerId));
      }));
  }

  getNewCartEndpoint<T>(cartObject: any): Observable<T> {

    return this.http.post<T>(this.newCartUrl, JSON.stringify(cartObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCartEndpoint(cartObject));
      }));
  }

  getCartDetailEndpoint<T>(cartObject: any, cartId?: string): Observable<T> {
    if (cartId) {
      const endpointUrl = cartId ? `${this._cartDetailUrl}/${cartId}` : this._cartDetailUrl;
      return this.http.put<T>(endpointUrl, JSON.stringify(cartObject), this.getRequestHeaders()).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getCartDetailEndpoint(cartObject, cartId));
        }));
    }
    else {
      return this.http.post<T>(this._cartDetailUrl, JSON.stringify(cartObject), this.getRequestHeaders()).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getCartDetailEndpoint(cartObject));
        }));
    }
  }

  getCartHeaderEndpoint<T>(cartId: string): Observable<T> {
    const endpointUrl = `${this.cartUrl}/${cartId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCartHeaderEndpoint(cartId));
      }));
  }

  getCartDetailDeleteEndpoint<T>(cartId: string, cartLineNumber?: number): Observable<T> {
    const endpointUrl = `${this._cartDetailUrl}/${cartId}/${cartLineNumber}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCartDetailDeleteEndpoint(cartId, cartLineNumber));
      }));
  }

  getAllEstablishmentDetailsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.branchesUrl}GetAllEstablishmentDetails`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllEstablishmentDetailsEndpoint());
      }));
  }

  editEstablishmentEndpoint<T>(establishment: Establishment, id: number) {
    const endpointUrl = `${this.branchesUrl}EditEstablishment/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(establishment), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.editEstablishmentEndpoint(establishment, id));
      }));
  }

  newEstablishmentEndpoint<T>(establishment: Establishment) {
    const endpointUrl = `${this.branchesUrl}NewEstablishment`;

    return this.http.put<T>(endpointUrl, JSON.stringify(establishment), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.newEstablishmentEndpoint(establishment));
      }));
  }

  deleteEstablishmentEndpoint<T>(id: number) {
    const endpointUrl = `${this.branchesUrl}DeleteEstablishment/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteEstablishmentEndpoint(id));
      }));
  }

  //NEXT GEN ITS ENDPOINTS
  
  //Replacing with ITSController
  getAllDriversEndpoint<T>() {
    const endpointUrl = `${this.itsUrl}GetAllITSDrivers`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAllDriversEndpoint());
      })
    );
  }


  //Adding from ITSController
  getDriverDetailsEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}GetITSDriverDetails/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getDriverDetailsEndpoint(id));
      })
    );
  }

  //Replacing with ITSController
  addNewDriverEndpoint<T>(driver: ITSDriver) {
    const endpointUrl = `${this.itsUrl}CreateITSDriver`;

    return this.http.post<T>(endpointUrl, JSON.stringify(driver), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addNewDriverEndpoint(driver));
      })
    );
  }

  //Replacing with ITSController
  updateDriverEndpoint<T>(id: number, driver: ITSDriver) {
    const endpointUrl = `${this.itsUrl}UpdateITSDriver/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(driver), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateDriverEndpoint(id, driver));
      })
    );
  }
  //Replacing with ITSController
  deleteDriverEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}DeleteITSDriver/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteDriverEndpoint(id));
      })
    );
  }

   //Replacing with ITSController
  getAllVehiclesEndpoint<T>() {
    const endpointUrl = `${this.itsUrl}GetAllITSVehicles`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAllVehiclesEndpoint());
      })
    );
  }

  //Adding from ITSController
  getITSVehicleDetailsEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}GetITSVehicleDetails/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getITSVehicleDetailsEndpoint(id));
      })
    );
  }
  //Replacing with ITSController
  addNewVehicleEndpoint<T>(vehicle: ITSVehicles) {
    const endpointUrl = `${this.itsUrl}CreateITSVehicle`;

    return this.http.post<T>(endpointUrl, JSON.stringify(vehicle), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addNewVehicleEndpoint(vehicle));
      })
    );
  }
  //Replacing with ITSController
  updateVehicleEndpoint<T>(id: number, vehicle: ITSVehicles) {
    const endpointUrl = `${this.itsUrl}UpdateITSVehicle/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(vehicle), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateVehicleEndpoint(id, vehicle));
      })
    );
  }

  //Replacing with ITSController
  deleteVehicleEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}DeleteITSVehicle/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteVehicleEndpoint(id));
      })
    );
  }

   //Replacing with ITSController
  getAllDriverCagesEndpoint<T>() {
    const endpointUrl = `${this.itsUrl}GetAllITSDriverCages`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAllDriverCagesEndpoint());
      })
    );
  }

  //Adding from ITSController
  getAllDriverCagesDetailEndpoint<T>() {
    const endpointUrl = `${this.itsUrl}GetAllITSDriverCagesDetail`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAllDriverCagesDetailEndpoint());
      })
    );
  }

  //Adding from ITSController
  getITSDriverCageDetailEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}GetITSDriverCageDetail/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getITSDriverCageDetailEndpoint(id));
      })
    );
  }

  addNewDriverCageEndpoint<T>(driverCageDto: any) {
    const endpointUrl = `${this.itsUrl}CreateITSDriverCage`; 

    return this.http.post<T>(endpointUrl, JSON.stringify(driverCageDto), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addNewDriverCageEndpoint(driverCageDto));
      })
    );
  }

  //Replacing with ITSController
  updateDriverCageEndpoint<T>(id: number, driverCage: ITSDriverCages) {
    const endpointUrl = `${this.itsUrl}UpdateITSDriverCage/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(driverCage), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateDriverCageEndpoint(id, driverCage));
      })
    );
  }

  //Adding from ITSController
  addNewDriverCageDetailEndpoint<T>(driverCageDetail: ITSDriverCagesDetail) {
    const endpointUrl = `${this.itsUrl}AddNewITSDriverCagesDetail`;

    return this.http.post<T>(endpointUrl, JSON.stringify(driverCageDetail), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addNewDriverCageDetailEndpoint(driverCageDetail));
      })
    );
  }


  //updateDriverCageDetailEndpoint<T>(id: number, driverCageDetail: ITSDriverCagesDetail): Observable<T> {
  //  const endpointUrl = `${this.trackingUrl}PutITSDriverCagesDetail/${id}`;

  //  return this.http.put<T>(endpointUrl, driverCageDetail).pipe(
  //    catchError(error => {
  //      return this.handleError(error, () => this.updateDriverCageDetailEndpoint(id, driverCageDetail));
  //    })
  //  );
  //}

  //Replacing with ITSController
  deleteDriverCageEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}DeleteITSDriverCage/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteDriverCageEndpoint(id));
      })
    );
  }
  //Replacing with ITSController
  deleteDriverCageDetailEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.itsUrl}DeleteITSDriverCagesDetail/${id}`;

    return this.http.delete<T>(endpointUrl).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteDriverCageDetailEndpoint(id));
      })
    );
  }

  //Replacing with ITSController
  getAllTrackingAreasEndpoint<T>() {
    const endpointUrl = `${this.itsUrl}GetAllITSTrackingAreas`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAllTrackingAreasEndpoint());
      })
    );
  }

  //Replacing with ITSController
  addNewTrackingAreaEndpoint<T>(area: TrackingAreas) {
    const endpointUrl = `${this.itsUrl}CreateITSTrackingArea`;

    return this.http.post<T>(endpointUrl, JSON.stringify(area), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addNewTrackingAreaEndpoint(area));
      })
    );
  }

  //Replacing with ITSController
  updateTrackingAreaEndpoint<T>(id: number, area: TrackingAreas) {
    const endpointUrl = `${this.itsUrl}UpdateITSTrackingArea/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(area), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateTrackingAreaEndpoint(id, area));
      })
    );
  }

  //Replacing with ITSController
  deleteTrackingAreaEndpoint<T>(id: number) {
    const endpointUrl = `${this.itsUrl}DeleteITSTrackingArea/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteTrackingAreaEndpoint(id));
      })
    );
  }

  //Replacing with ITSController
  getPrintingByDateEndpoint<T>(startDate?: string, endDate?: string) {
    const endpointUrl = `${this.itsUrl}GetAllITSParcelPrintingRecordsByDate/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getPrintingByDateEndpoint(startDate, endDate));
      })
    );
  }

  //getAllPrintingEndpoint<T>() {
  //  const endpointUrl = `${this.trackingUrl}printing`;

  //  return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
  //    catchError(error => {
  //      return this.handleError(error, () => this.getAllPrintingEndpoint());
  //    })
  //  );
  //}

  //Added to its controller - add new label
  //[HttpPost("PostNewLabelForPrinting/{invoiceNumber}/{Operator}/{SalesOrder}/{NumberOfLabels}")]
  printAdditionalLabelsEndpoint<T>(invoiceNumber: string, Operator: string, SalesOrder: string, numberOfLabels: number) {
    const endpointUrl = `${this.itsUrl}PostNewLabelForPrinting/${invoiceNumber}/${Operator}/${SalesOrder}/${numberOfLabels}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.printAdditionalLabelsEndpoint(invoiceNumber, Operator, SalesOrder, numberOfLabels));
      })
    );
  }

  //Updated from ITS Controller - reprint existing label
  initiatelabelprintingEndpoint<T>(id: number, numberOfLabels: number) {
    const endpointUrl = `${this.itsUrl}ITSReprintLabel/${id}/${numberOfLabels}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.initiatelabelprintingEndpoint(id, numberOfLabels));
      })
    );
  }

  //Updated from ITS Controller - modify existing label
  updatePrintingEndpoint<T>(id: number, printing: ITSLabelPrinting) {
    const endpointUrl = `${this.itsUrl}UpdateITSPrintingRecord/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(printing), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updatePrintingEndpoint(id, printing));
      })
    );
  }

  //Print directly to Zebra - Added to ITS COntroller
  printBarcodeLabelDirectEndpoint<T>(InvoiceNumber: string, Operator: string, SalesOrder: string, NoOfLabels: number, selectedPrinter: string) {
    const endpointUrl = `${this.itsUrl}PrintBarcodeLabelDirect/${InvoiceNumber}/${Operator}/${SalesOrder}/${NoOfLabels}/${selectedPrinter}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.printBarcodeLabelDirectEndpoint(InvoiceNumber, Operator, SalesOrder, NoOfLabels, selectedPrinter));
      })
    );
  }

  //Replace with direct print
  //getITSLabelPDFEndpoint<T>(salesOrder?: string, invoice?: string, application?: string): Observable<T> {
  //  const endpointUrl = `${this.trackingUrl}GetITSLabelPDF/${encodeURIComponent(salesOrder)}/${invoice}/${application}`;

  //  return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
  //    catchError(error => {
  //      return this.handleError(error, () => this.getITSLabelPDFEndpoint(salesOrder, invoice, application));
  //    }));
  //}

  //Moved to ITS Controller
  getPrintCardPDFEndpoint<T>(cardType?: string, id?: string, application?: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}PrintCard/${encodeURIComponent(cardType)}/${id}/${application}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPrintCardPDFEndpoint(cardType, id, application));
      }));

  }
  //Seperate log book print to pass in sort order
  getPrintLogBookPDFEndpoint<T>(cardType?: string, id?: string, sortOrder?: string): Observable<T> {

    const endpointUrl = `${this.itsUrl}PrintLogBook/${cardType}/${id}/${encodeURIComponent(sortOrder)}`
   
      return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getPrintLogBookPDFEndpoint(cardType, id, sortOrder));
        }));
  }


  getITSDriversReportEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetDriversReport/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSDriversReportEndpoint(startDate, endDate));
      }));
  }

  getITSDriversLogEndpoint<T>( startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetAllDriversLog/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSDriversLogEndpoint( startDate, endDate));
      }));
  }


  getITSParcelTrackingDetailEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetParcelTrackingDetails/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSParcelTrackingDetailEndpoint(startDate, endDate));
      }));
  }

  getITSParcelTrackingDetailListForHeaderIdEndpoint<T>(id?: number): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetITSTrackingDetailListForHeaderId/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSParcelTrackingDetailListForHeaderIdEndpoint(id));
      }));
  }


  getITSDeliveryDetailsEndpoint<T>(invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetITSDeliverDetails/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSDeliveryDetailsEndpoint(invoiceNumber));
      }));
  }



  getITSDeliveryDetailsAdditionalEndpoint<T>(id?: number): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetITSDeliverDetailsAdditional/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getITSDeliveryDetailsAdditionalEndpoint(id));
      }));
  }

  updateparcelsEndpoint<T>(id: number, totalParcels: number): Observable<T> {
    const endpointUrl = `${this.itsUrl}Updateparcels/${id}/${totalParcels}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateparcelsEndpoint(id, totalParcels));
      })
    );
  }

  updateDespatchParcelsEndpoint<T>(id: number, details: ITSParcelTrackingDetailLog): Observable<T> {
    const endpointUrl = `${this.itsUrl}UpdateDespatchedParcels/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(details), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateDespatchParcelsEndpoint(id, details));
      })
    );
  }


  updateparcelsUndeliveredEndpoint<T>(id: number, totalParcels: number, reason: string, user: string): Observable<T> {
    const endpointUrl = `${this.itsUrl}UpdateParcelsUndelivered/${id}/${totalParcels}/${reason}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateparcelsUndeliveredEndpoint(id, totalParcels, reason, user));
      })
    );
  }

  //Need the ability to re-trigger printing services from Next Gen

  RefireLabelPrintingQueueEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.itsUrl}RefireLabelPrintingQueue`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.RefireLabelPrintingQueueEndpoint());
      })
    );
  }

  RefireLogPrintingQueueEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.itsUrl}RefireLogPrintingQueue`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.RefireLogPrintingQueueEndpoint());
      })
    );
  }

  scanFingerprintEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.fingerprintURL}scan`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.scanFingerprintEndpoint());
      })
    );
  }


  enrollEndpoint<T>(fingerprintData: any): Observable<T> {
    const endpointUrl = `${this.fingerprintURL}enroll`;

    return this.http.post<T>(endpointUrl, JSON.stringify(fingerprintData), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.enrollEndpoint(fingerprintData));
      })
    );
  }

  verifyEndpoint<T>(fingerprintData: any): Observable<T> {
    const endpointUrl = `${this.fingerprintURL}verify`;

    return this.http.post<T>(endpointUrl, JSON.stringify(fingerprintData), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.verifyEndpoint(fingerprintData));
      })
    );
  }


  getITSWarehouseDefaultsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetITSWarehouseDefaults`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getITSWarehouseDefaultsEndpoint());
      })
    );
  }

  updateITSWarehouseDefaultsEndpoint<T>(defaultSetting: WarehouseApplicationDefault): Observable<T> {
    const endpointUrl = `${this.itsUrl}UpdateITSWarehouseDefaults/${defaultSetting.id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(defaultSetting), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateITSWarehouseDefaultsEndpoint(defaultSetting));
      })
    );
  }

  getITSWarehouseUsersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.itsUrl}GetITSWarehouseUsers`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getITSWarehouseUsersEndpoint());
      })
    );
  }

  updateITSWarehouseUserEndpoint<T>(user: WarehouseUserDetails): Observable<T> {
    const endpointUrl = `${this.itsUrl}UpdateITSWarehouseUser`;

    return this.http.put<T>(endpointUrl, JSON.stringify(user), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateITSWarehouseUserEndpoint(user));
      })
    );
  }

  // Endpoint for Job Cards
  getJobCardsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetAllJobCards`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardsEndpoint());
      })
    );
  }

  getJobCardByIdEndpoint<T>(jobCardId: number): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetJobCardById/${jobCardId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardByIdEndpoint(jobCardId));
      })
    );
  }

  createJobCardEndpoint<T>(jobCard: JobCard): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}CreateJobCard`;

    return this.http.post<T>(endpointUrl, JSON.stringify(jobCard), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.createJobCardEndpoint(jobCard));
      })
    );
  }


  // Save the job card status history
  saveJobCardStatusHistoryEndpoint<T>(statusHistory: JobCardStatusHistory): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}saveStatusHistory`;

    return this.http.post<T>(endpointUrl, JSON.stringify(statusHistory), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.saveJobCardStatusHistoryEndpoint(statusHistory));
      })
    );
  }


  // Fetch the status history for a job card
  getJobCardStatusHistoryEndpoint<T>(jobCardId: number): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetJobCardStatusHistory/${jobCardId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardStatusHistoryEndpoint(jobCardId));
      })
    );
  }

  updateJobCardEndpoint<T>(jobCard: JobCard): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}UpdateJobCard/${jobCard.id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(jobCard), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateJobCardEndpoint(jobCard));
      })
    );
  }

  // Endpoint for CoreJCStatus
  getJobCardStatusesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetJobCardStatuses`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardStatusesEndpoint());
      })
    );
  }

  createJobCardStatusEndpoint<T>(status: CoreJCStatus): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}CreateJobCardStatus`;

    return this.http.post<T>(endpointUrl, JSON.stringify(status), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.createJobCardStatusEndpoint(status));
      })
    );
  }

  updateJobCardStatusEndpoint<T>(status: CoreJCStatus): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}UpdateJobCardStatus/${status.statusId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(status), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateJobCardStatusEndpoint(status));
      })
    );
  }


  addJobCardHistoryEndpoint<T>(historyEntry: JobCardStatusHistory): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}AddJobCardHistoryEntry`;

    return this.http.post<T>(endpointUrl, JSON.stringify(historyEntry), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addJobCardHistoryEndpoint(historyEntry));
      })
    );
  }

  //Job Card PDF
  getJobCardPDFEndpoint<T>(jobCardNumber?: string): Observable<T> {

    const endpointUrl = `${this.jobcardUrl}JobCardPDF/${jobCardNumber}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardPDFEndpoint(jobCardNumber));
      }));
  }


   //Job Card Customer PDF
  getJobCardCustomerPDFEndpoint<T>(jobCardNumber?: string, company?: string, includeSlip?: boolean): Observable<T> {

    const endpointUrl = `${this.jobcardUrl}JobCardCustomerPDF/${jobCardNumber}/${company}/${includeSlip}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardCustomerPDFEndpoint(jobCardNumber, company, includeSlip));
      }));
  }


  //Job Card Supplier PDF
  getJobCardSupplierPDFEndpoint<T>(jobCardNumber?: string, company?: string, supplierCode?: string): Observable<T> {

    const endpointUrl = `${this.jobcardUrl}JobCardSupplierPDF/${jobCardNumber}/${company}/${supplierCode}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardSupplierPDFEndpoint(jobCardNumber, company, supplierCode));
      }));
  }


  //Job Card Supplier PDF
  getJobCardMasterPDFEndpoint<T>(jobCardNumber?: string, company?: string, quoteNumber?: string): Observable<T> {

    const endpointUrl = `${this.jobcardUrl}JobCardMasterPDF/${jobCardNumber}/${company}/${quoteNumber}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardMasterPDFEndpoint(jobCardNumber, company, quoteNumber));
      }));
  }

  // Endpoint for CoreJCPredefinedComments
  getJobCardCommentsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetPredefinedComments`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getJobCardCommentsEndpoint());
      })
    );
  }

  // Get additional suppliers for a specific job card
  getAdditionalSuppliersEndpoint<T>(jobCardId: number): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetAdditionalSuppliers/${jobCardId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAdditionalSuppliersEndpoint(jobCardId));
      })
    );
  }

  // Add a new additional supplier
  addAdditionalSupplierEndpoint<T>(supplier: JobCardSuppliers): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}AddAdditionalSupplier`;

    return this.http.post<T>(endpointUrl, JSON.stringify(supplier), this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.addAdditionalSupplierEndpoint(supplier));
      })
    );
  }

  // Update an existing additional supplier
  updateAdditionalSupplierEndpoint<T>(supplierId: number, supplier: JobCardSuppliers): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}UpdateAdditionalSupplier/${supplierId}`;

    return this.http.put<T>(endpointUrl, supplier, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.updateAdditionalSupplierEndpoint(supplierId, supplier));
      })
    );
  }

  // Delete an additional supplier
  deleteAdditionalSupplierEndpoint<T>(supplierId: number): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}DeleteAdditionalSupplier/${supplierId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.deleteAdditionalSupplierEndpoint(supplierId));
      })
    );
  }

  sendjobCardEmailEndpoint<T>(emailDetail?: Email): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}SendJobCardEmail`;

    return this.http.post<T>(endpointUrl, JSON.stringify(emailDetail), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendjobCardEmailEndpoint(emailDetail));
      }));
  }

  // Get Email templates
  getEmailTemplatesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.jobcardUrl}GetEmailTemplates`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getEmailTemplatesEndpoint());
      })
    );
  }

}
