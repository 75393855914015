
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { localStorageActions } from 'src/app/models/enums';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Branch } from '../../../models/branch.model';
import { InvoiceTrackingStatus } from '../../../models/invoice-tracking-status.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ReportService } from '../../../services/report.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'reports-invoice-tracking-delivery',
  templateUrl: './reports-invoice-tracking-delivery.component.html',
  styleUrls: ['./reports-invoice-tracking-delivery.component.scss']
})
export class ReportsInvoiceTrackingDeliveryComponent implements OnInit, AfterViewInit {


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];

  loadingIndicator: boolean;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;


  @ViewChild('dateOnlyTemplate')
  dateOnlyTemplate: TemplateRef<any>;

  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "ALL";


  @ViewChild('#companiesListSelector')
  private companiesListSelector;

  invoiceTrackingPODsReceived: InvoiceTrackingStatus[] = [];
  invoiceTrackingListNotPrinted: InvoiceTrackingStatus[] = [];
  invoiceTrackingListOnHold: InvoiceTrackingStatus[] = [];
  invoiceTrackingListNotCompleted: InvoiceTrackingStatus[] = [];
  rows: InvoiceTrackingStatus[] = [];
  rowsCache: InvoiceTrackingStatus[] = [];

  gridHeight: number = 0;
  selectedItems: any;
  isNavigating: boolean;
  countRecords: number;
  percentageCount: number;
  chkPurged: boolean;
  chkTBC: boolean;
  chkIncomplete: boolean;
  chkShowAll: boolean;
  selectedCompany: string;

  @ViewChild('itsModal')
  itsModal: ModalDirective;
  itsDetail: InvoiceTrackingStatus;


  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  selectedBranch: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute) {
  }


  ngOnInit() {


    this.setGrid();

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day')
    }

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesService.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    // (this.application == "MaxArcusOnline" ? "Max Arcus" : "Fowkes Bros");
    this.selectedCompany = "ALL";
    this.selectedBranch = "ALL";

    this.chkTBC = true;
    this.chkPurged = true;
    this.chkIncomplete = false;
    this.chkShowAll = true;

    this.loadData();

  }


  ngAfterViewInit() {
    this.setGrid();
  }

  setGrid() {
    this.columns = [
      { prop: 'invoiceNumber', name: 'Invoice', width: 100, cellTemplate: this.invoiceTemplate },
      /*{ prop: 'lastOperator', name: 'Assigned User', width: 120 },*/
      { prop: 'currentStatus', name: 'Status', width: 120 },
      { prop: 'insertDate', name: 'Create Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'operatorCode', name: 'Operator', width: 80 },
      { prop: 'printedDateTime', name: 'Printed Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'dispatchDateTime', name: 'Dispatched Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'deliveryDateTime', name: 'Delivery Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'returnedDateTime', name: 'Returned Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'collectionsDateTime', name: 'Collections Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'podReceivedDate', name: 'POD Received Date', width: 120, cellTemplate: this.dateOnlyTemplate },
      { prop: 'invoiceCreditedDate', name: 'Credited Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'purgeReason', name: 'Purge Reason', width: 120 },
      { prop: 'fromCompany', name: 'Company', width: 120 }
    ];
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.invoiceTrackingPODsReceived = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.lastOperator, r.salesOrder, r.customerCode,
        r.currentStatus, r.insertDate, r.invoiceNumber, r.customerName, r.operatorCode, r.fromCompany));
    }
  }

  specificSearch() {
    this.loadData();
  }


  //  onSpecificSearchSuccesful(x: SalesOrderList[]): void {

  //    this.alertService.stopLoadingMessage();
  //    this.loadingIndicator = false;
  //    this.searchResults = x.filter(i => i.invoiceNumber != '');

  //}


  viewInvoiceSelected() {
    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, this.searchResultSelected.invoiceNumber);
    this.router.navigate(['/reports'], { fragment: 'invoicestatus' });

  }


  viewInvoice(row: InvoiceTrackingStatus) {


    //if ((this.application == "MaxArcusOnline" && row.fromCompany == "Max Arcus") || (this.application == "FowkesOnline" && row.fromCompany == "Fowkes Bros")) {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);

    this.itsDetail = row;

    if (!this.itsModal) {
      setTimeout(() => {
        if (this.itsModal) {
          this.itsModal.show();
        }
      });
    }
    else {
      this.itsModal.show();
    }
    //}

  }




  itsModallHide() {
    this.itsDetail = null;
    this.itsModal.hide();
  }


  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);

    this.sysproService.getInvoiceTrackingStatusDeliveryControl(startD.toJSON(), endD.toJSON(), company, "ALL", "ALL").subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });

    this.selectedBranch = this.branchCode;
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onSearchSuccesful(x: InvoiceTrackingStatus[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.countRecords = 0;
    this.percentageCount = 0;

    if (x && x.length > 0) {
      this.rowsCache = x;

      this.countRecords = this.invoiceTrackingPODsReceived.length;
      this.percentageCount = (this.countRecords / this.rowsCache.length) * 100;

      this.rows = this.rowsCache;

    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }


  showAll() {
    this.chkShowAll = !this.chkShowAll;
    this.chkIncomplete = false;
    this.filterChanged();
  }

  showOnlyIncomplete() {
    this.chkIncomplete = !this.chkIncomplete;
    this.chkShowAll = false;
    this.filterChanged();
  }

  showPurged() {
    this.chkPurged = !this.chkPurged;
    this.filterChanged();
  }

  showTBC() {
    this.chkTBC = !this.chkTBC;
    this.filterChanged();
  }

  filterChanged() {

    if (this.chkIncomplete) {
      this.rows = this.rowsCache.filter(x => x.dispatch != "A" && x.collections != "A");
    }

    if (this.chkShowAll) {
      this.rows = this.rowsCache;
    }
  }

  branchSelected() {

    if (this.selectedBranch != "ALL") {

      this.rows = this.rows.filter(x =>  x.branch == this.selectedBranch);
    }
    else {
      this.rows = this.rows;
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;

  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.loadData();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Invoice", "Status", "CreateDate", "Operator", "PrintedDate", "DispatchedDate", "DeliveryDate", "ReturnedDate", "CollectionsDate", "PODReceivedDate", "CreditedDate", "PurgeReason", "Company"]
    };

    var filename = "InvoiceTracking";
    var selectRows = [...this.rowsCache];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.insertDate).toISOString().split('T')[0];

      exportRows.push({
        Invoice: row.invoiceNumber, Status: row.currentStatus, CreateDate: date, Operator: row.operatorCode,
        PrintedDate: this.isValidDate(row.printedDateTime) ? new Date(row.printedDateTime).toISOString().split('T')[0] : "",
        DispatchedDate: this.isValidDate(row.dispatchDateTime) ? new Date(row.dispatchDateTime).toISOString().split('T')[0] : "",
        DeliveryDate: this.isValidDate(row.deliveryDateTime) ? new Date(row.deliveryDateTime).toISOString().split('T')[0] : "",
        ReturnedDate: this.isValidDate(row.returnedDateTime) ? new Date(row.returnedDateTime).toISOString().split('T')[0] : "",
        CollectionsDate: this.isValidDate(row.collectionsDateTime) ? new Date(row.collectionsDateTime).toISOString().split('T')[0] : "",
        PODReceivedDate: this.isValidDate(row.podReceivedDate) ? new Date(row.podReceivedDate).toISOString().split('T')[0] : "",
        CreditedDate: this.isValidDate(row.invoiceCreditedDate) ? new Date(row.invoiceCreditedDate).toISOString().split('T')[0] : "",
        PurgeReason: row.purgeReason,
        Company: row.fromCompany
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  isValidDate(d: any): boolean {

    var isValid = (d != null && d != "" && d != "1900-01-01T00:00:00");
    return isValid;
   // return d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime()) && d > new Date(1900, 1, 2);
  }


}
