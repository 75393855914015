import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { Brand } from '../../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ITSDriver } from '../../../models/invoice-tracking-driver.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { TrackingAreas } from '../../../models/invoice-tracking-areas.model';

@Component({
  selector: 'tracking-areas',
  templateUrl: './tracking-areas.component.html',
  styleUrls: ['./tracking-areas.component.scss'],
  animations: [fadeInOut]
})
export class TrackingAreasComponent {

  @ViewChild('areaModal')
  areaModal: ModalDirective;

  emptyDetails: boolean;

  rows: TrackingAreas[] = [];
  rowsCache: TrackingAreas[];
  areaDetail: TrackingAreas = new TrackingAreas();

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private fowkesService: FowkesOnlineService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.fowkesService.getAllTrackingAreas().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(areas: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (areas[0]) {

      this.rowsCache = [...areas[0]];      
      this.rows = [...this.rowsCache];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.area, r.city, r.poBoxCode, r.streetCode, r.suburb, r.province));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }


  enableEditMethod(row: ITSDriver, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: ITSDriver) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    row.createdBy = this.accountService.currentUser.email;
    this.fowkesService.updateDriver(row.id, row).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  areaModalHide() {
    this.areaDetail = new TrackingAreas();
    this.areaModal.hide();
  }

  newArea() {

    this.areaDetail = new TrackingAreas();
    if (!this.areaModal) {
      setTimeout(() => {
        if (this.areaModal) {
          this.areaModal.show();
        }
      });
    }
    else {
      this.areaModal.show();
    }

  }

  saveArea() {

    //Validate save
    if (this.areaDetail) {
      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');

      this.fowkesService.addNewTrackingArea(this.areaDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
    }
      
  }

  onSaveSuccesful(): void {

    this.areaModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  deleteRow(row: TrackingAreas) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.fowkesService.deleteDriver(row.id).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success',  ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

}
