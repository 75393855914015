import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { CMCategoryList } from '../../models/cms-category-list.model';
import { CMCategories } from '../../models/cms-category.model';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { SimpleTree } from '../../models/simple-tree.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'Products-Edit-MetaTags',
  templateUrl: './products-edit-metatags.component.html',
  styleUrls: ['./products-edit-metatags.component.scss'],
  animations: [fadeInOut]
})
export class ProductsEditMetaTagsComponent {

  items: TreeviewItem[];
  rows: string[];
  item: any;
  data: CMCategories;
  categoryData: CMCategories[];

  categoryrows: CMCategories[] = [];
  categoryrowsCache: CMCategories[] = [];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild('itemTemplate')
  itemTemplate: TemplateRef<any>;

  loadingIndicator: boolean;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('categoryListSelector')
  private categoryListSelector;
  private selectUndefinedOptionValue: string = "Please Select";
  categorySelected: string = "";
  categoryList: CMCategories[] = [];
  categoryListChangeParent: CMCategories[] = [];
  changeParent: boolean = false;


  @ViewChild('fileListSelector')
  private fileListSelector;
  fileCategorySelected: string = "";
  fileListChangeParent: CMCategories[] = [];
  changeFileParent: boolean = false;

  @ViewChild('categoryModal')
  categoryModal: ModalDirective;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  @ViewChild('myFileInput')
  myFileInput: ElementRef;

  categoryDetail: CMCategories = new CMCategories();
  rowsCache: TreeviewItem[];
  headerTemplateContext: { config: TreeviewConfig; item: any};
  modalHeader: string;
    isSaving: boolean;
    compareFolderName: any;
  documentDetail: CMStockCode = new CMStockCode();

  //newformData = new FormData();
  compareFileName: string;
  repoDocsList: CMStockCode[] = [];
  stockCodeList: CMStockCode[] = [];
  changeImage: boolean;
  newCategoryImageData = new FormData();

  @ViewChild(TreeviewComponent)
  categoryTreeviewComponent: TreeviewComponent;

  @ViewChild('treeModal')
  treeModal: ModalDirective;

  @ViewChild('treeItemTemplate')
  treeItemTemplate: TemplateRef<any>;

  treeItems: TreeviewItem[] = [];
  treeItem: any;
  newParentName: string = "";
    isEnabled: boolean;
    stockCodedetail: any;
    hasDataSheet: boolean;
    hasSpecials: boolean;
    hasImpaCodes: boolean;
    hasAddDataSheet: boolean;
    alternatesList: any[];
    configurations: any;
    updatedStockCodeDetail: any;
    changeStockCodeImage: boolean;
    newStockCodeImageData: FormData;
    newSpecialsImageData: any;
    newDatasheetImageData: any;
    newAddDatasheetImageData: any;
    stockCode: any;
    newStockCode: boolean;
    selectedCategory: string;

  columns: any[] = [];



  searchType: string = "";
  reportTitle: string = "";
  reportType: string = "";
  searchText: string = "";

  columnsCat: any[] = [];
  rowsCat: CMCategoryList[] = [];
  rowsCatcache: CMCategoryList[] = [];


  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

  @ViewChild('imageTemplate')
  imageTemplate: TemplateRef<any>;

  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private accountService: AccountService) {
  }


  ngOnInit() {
    this.searchType = "";
    this.SetDataGrid();
    this.loadData();

  }

  ngOnDestroy() { }


  ngAfterViewInit() {
    this.SetDataGrid();
  }

  SetDataGrid() {
    this.columns = [
      { name: '', width: 60, cellTemplate: this.imageTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      { prop: 'categoryName', name: 'Category', width: 280, cellTemplate: this.descriptionTemplate, cellClass: "left" },
      { prop: 'metaDescription', name: 'Meta Description', width: 250, cellClass: "left" },
      { prop: 'keywords', name: 'Key Words', width: 250, cellClass: "left" },
      { prop: 'title', name: 'Title', width: 250, cellClass: "left" },
      { prop: 'parentCategoryName', name: 'Parent', width: 250, cellClass: "left" },
     /* { name: 'Edit', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },*/
      { prop: 'tree', name: 'Tree', width: 400,  cellClass: "left" }
    ]

    this.columnsCat = [
      { name: '', width: 60, cellTemplate: this.imageTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      { prop: 'categoryName', name: 'Category', width: 280, cellTemplate: this.descriptionTemplate, cellClass: "left" },
      { prop: 'categoryGroup', name: 'Group', width: 250, cellClass: "left" },
      { prop: 'categoryTree', name: 'Tree', width: 400, cellClass: "left" }
    ]
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getAllCMCategories().subscribe(x => this.onCategoriesLoadSuccessful(x), error => this.onDataLoadFailed(error));

/*    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));*/
  }


  specificSearch() {

    if (this.searchType == "metatags") {
      this.loadData();
    }
    else {
      if (this.searchType == "catLowList") {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        this.reportType = "report";
        this.reportTitle = "Category List";
        this.searchText = "catLowList";
        this.loadList();
        // this.createCatalogue();
      }
    }

  }

  loadList() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    //Scrape and Load Categories
    this.sysproService.getCategoryCatalogueList("CT", "catLowList", "ALL", "ALL").subscribe(x => this.onCategoryListLoadSuccessful(x), error =>
      this.onDataLoadFailed(error));
  }

  onCategoryListLoadSuccessful(x: CMCategoryList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x[0]) {
      this.rowsCatcache = [...x];
      this.rowsCat = this.rowsCatcache;
    }
  }
     
  onDataLoadSuccessful(treeview: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
       
    let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

    this.items = this.getItems([treeviewItems]);
    this.treeItems = this.getItems([treeviewItems]);
  }

  onCategoriesLoadSuccessful(results: CMCategories[]) {
    this.categoryList = results;
    const updateRows = this.categoryList.filter(r => r.categoryName != "Root");
    for (var category of updateRows) {
      var parentTree = "";

      category.tree = this.buildParentTree(category.parentCategoryName, parentTree);
    }

    updateRows.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)
    this.categoryrows = [...updateRows];
    this.categorySelected = "";

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    console.log(this.categoryrows);
  }

  buildParentTree(parentCategoryName: string, treePath: string): string {
    var path = "";
    if (parentCategoryName && parentCategoryName != "Root") {
      let parents = this.categoryList.filter(x => x.categoryName == parentCategoryName);
      if (parents && parents.length >= 1) {
        let parent = parents[0];
        if (parent) {
          path = parentCategoryName + " | " + treePath;
          treePath = this.buildParentTree(parent.parentCategoryName, path);
        }
        else {
          return treePath;
        }
      }
      else {
        return treePath;
      }
    }
    return treePath;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onSearchChanged(value: string) {
    if (value != "") {
      this.categoryrows = this.categoryList.filter(r => Utilities.searchArray(value, false, r.categoryName, r.parentCategoryName, r.tree,
        r.title, r.metaDescription, r.keywords));
    }
    else {
      this.categoryrows = this.categoryList;
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get application(): string {
    return this.configurations.applicationName;
  }




  enableEditMethod(row: CMCategories) {
    this.enableEdit = true;
    this.modalHeader = "Edit Category";


    this.isEnabled = (row.statusIndicator == "E") ? true : false;

    this.categoryDetail = new CMCategories();

    this.categoryDetail.id = row.id;
    this.categoryDetail.parentCategoryName = row.parentCategoryName;
    this.categoryDetail.categoryName = row.categoryName;
    this.categoryDetail.categoryImage = row.categoryImage;
    this.categoryDetail.title = row.title;
    this.categoryDetail.metaDescription = row.metaDescription;
    this.categoryDetail.keywords = row.keywords;
    this.categoryDetail.statusIndicator = row.statusIndicator;
    this.categoryDetail.filePath = row.filePath;
    this.categoryDetail.tree = row.tree;

    this.compareFolderName = this.categoryDetail.categoryName;
    this.categorySelected = this.categoryDetail.parentCategoryName;


    this.changeImage = false;
    this.newCategoryImageData = new FormData();

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }

  //addNewCategory(row: TreeviewItem) {
  //  this.modalHeader = "Add new Category";
  //  this.enableEditIndex = row.value.index;

  //  this.isEnabled = false;

  //  this.categoryDetail = new CMCategories();
  //  this.categoryDetail.statusIndicator = "D";
  //  this.categoryDetail.categoryName = "";
  //  this.categoryDetail.parentCategoryName = row.text;
  //  this.categoryDetail.createdBy = this.accountService.currentUser.email;
  //  this.categoryDetail.title = "";
  //  this.categoryDetail.metaDescription = "";
  //  this.categoryDetail.keywords = "";
  //  this.categoryDetail.filePath ="";

  //  this.categorySelected = row.text;
  //  this.compareFolderName = "";

  //  if (!this.categoryModal) {
  //    setTimeout(() => {
  //      if (this.categoryModal) {
  //        this.categoryModal.show();
  //      }
  //    });
  //  }
  //  else {
  //    this.categoryModal.show();
  //  }
  //}


  categoryModalHide() {
    this.categoryDetail = new CMCategories();
    this.changeParent = false;
    this.categorySelected = "";
    this.categoryModal.hide();
  }

  saveCategory() {
    if (this.categoryDetail && this.categoryDetail.categoryName != "") {

      if (this.validateCategorySave()) {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');

        var newFileName = this.categoryDetail.categoryName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---");

        this.handleFiles(newFileName, 0);
      }

    }
    else {
      this.alertService.showMessage('Warning', `Make sure to enter a category name`, MessageSeverity.warn);
    }
  }

  //deleteCategory(row: TreeviewItem) {
  //  console.log(row);
  //  if (row.value.isLasLevel) {
  //    if (null == row.children) {
  //      this.alertService.startLoadingMessage();
  //      this.loadingIndicator = true;
  //      this.sysproService.getStockCodesForCategory(row.value.category.Id).subscribe(x => this.onStockCodesLoadSuccesfull(x, row, true), error => this.onDataLoadFailed(error));
  //    }
  //  }
  //}

  //onCategoryDeleted(x: CMCategories): void {
  //  this.loadData();
  //}


  toggleImageUpload(type: string) {
    switch (type) {
      case "categoryImage": {
        this.changeImage = !this.changeImage;
        if (!this.changeImage) {
          this.newCategoryImageData = new FormData();
        }
        break;
      }
      case "stockCodeImage": {
        this.changeStockCodeImage = !this.changeStockCodeImage;
        if (!this.changeStockCodeImage) {
          this.newStockCodeImageData = new FormData();
        }
        break;
      }
      default: {
        break;
      }
    }
  }


  
  handleFiles(newFileName: string, numberDone: number) {

    //Update Files
    if (numberDone == 0) {
      if (this.newCategoryImageData.has('file')) {
        this.sysproService.postFileData(this.newCategoryImageData, "CATEGORY", newFileName).subscribe(x => {
          this.categoryDetail.categoryImage = x.fileName;
          this.categoryDetail.categoryImageChanged = new Date();
          this.handleFiles(newFileName, 1);
          this.alertService.showMessage('Success', `Added Category Image`, MessageSeverity.success);
        }, error => this.onPostDataLoadFailed(error));
      }
      else {
        if ((!this.categoryDetail.categoryImage || this.categoryDetail.categoryImage == "")) {
          this.categoryDetail.categoryImage = "";
        }
        this.handleFiles(newFileName, 1)
      }
    }
    if (numberDone == 1) {      
      if (this.isEnabled) {
        this.categoryDetail.statusIndicator ="E";
      }
      else {
        this.categoryDetail.statusIndicator ="D";
      }

      if (!this.categoryDetail.categoryImage) {

        this.categoryDetail.categoryImage = "";
      }

      if (this.categoryDetail.parentCategoryName != this.newParentName && this.newParentName != "") {
        this.categoryDetail.parentCategoryName = this.newParentName;
        this.changeParent = false;
      }
      if (this.categoryDetail.parentCategoryName == "Categories") {
        this.categoryDetail.parentCategoryName = "Root";
      }
      //Save Category
      if (!this.categoryDetail.id || this.categoryDetail.id == 0) {
        this.sysproService.addCMCategory(this.categoryDetail).subscribe(x => this.onNewCategoryAdded(x), error => this.onNewCategoryAddedFailed(error));
      }
      else {
        //Edit Category
        if (this.categoryDetail.id > 0) {       
          this.sysproService.updateCMCategory(this.categoryDetail, this.categoryDetail.id).subscribe(x => this.onNewCategoryAdded(x), error => this.onNewCategoryAddedFailed(error));

        }
      }
    }
  }


  onNewCategoryAddedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  onNewCategoryAdded(x: CMCategories): void {
    //if (x && x.id) {

    //  this.expandIndex = x.id;
    //}

    this.loadData();
    // Open to correct level.
    this.categoryModalHide();
   // this.stockCodeModallHide();
  }

  


  validateCategorySave() {
    let error = "";
    if (this.compareFolderName != this.categoryDetail.categoryName) {
      error = (this.categoryList.filter(x => x.categoryName.toUpperCase() == this.categoryDetail.categoryName.toUpperCase()).length > 0 ? "This category name has already been added.  " : "");
    }
    //error += ((!this.categoryDetail.categoryImage || this.categoryDetail.categoryImage == "" ) && (!this.newCategoryImageData || !this.newCategoryImageData.has('file')) ? "Remember to add an image for the category" : "")
    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  validateFileSave() {
    let error = "";

    //error = (this.repoDocsList.filter(x => x.documentName.toUpperCase() == this.documentDetail.documentName.toUpperCase()).length > 0 ? "This file has already been added" : "");

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }



  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newCategoryImageData.append('file', file);
    }
  }


    onPostDataLoadFailed(error: any) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    }
   


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }


  metaList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Tree", "CategoryName", "Title", "Keywords", "MetaDescription", "ParentCategoryName", "Status"]
    };

    var filename = "MetaTags";
    var selectRows = [...this.categoryrows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Tree: row.tree, CategoryName: row.categoryName, Title: row.title, Keywords: row.keywords,
        MetaDescription: row.metaDescription, ParentCategoryName: row.parentCategoryName, Status: row.statusIndicator
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
