import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Brand } from '../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CMCategories } from '../../models/cms-category.model';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'missing-images',
  templateUrl: './products-missing-images.component.html',
  styleUrls: ['./products-missing-images.component.scss'],
  animations: [fadeInOut]
})
export class MissingImagesComponent {

  emptyDetails: boolean;

  allcategories: CMCategories[] = [];
  categoryrows: CMCategories[] = [];
  categoryrowscache: CMCategories[] = [];
 // categoryurlString: string = "https://www.fowkes.co.za/FowkesOnline/FowkesDocuments/ContentManagementDocuments/CategoryImages/";

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
  searchText: string = "";
    missingImageCount: number;

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    //Scrape and Load Categories
    this.sysproService.getAllCMSCategoryMissingImages().subscribe(x => this.onDataLoadSuccessful([x]), error =>
      this.noMissingImage());
  }

  noMissingImage() {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `No missing images found!`, MessageSeverity.success);
  }

  onDataLoadSuccessful(categories: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (categories[0]) {
      this.categoryrowscache = [...categories[0]];

      this.categoryrowscache = this.categoryrowscache.filter(x => x.statusIndicator == "E");
      this.missingImageCount = this.categoryrowscache.length - 1;
      this.sysproService.getAllCMCategories().subscribe(x => this.onCategoriesLoadSuccessful(x), error => this.onDataLoadFailed(error));

      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }

  refresh() {
    this.searchTerm = "";
    this.loadData();
  }

  onCategoriesLoadSuccessful(x: any) {
    this.allcategories = x;

    const updateRows = this.categoryrowscache.filter(r => r.categoryName != "Root");
    for (var category of updateRows) {
      var parentTree = "";
   
      category.tree = this.buildParentTree(category.parentCategoryName, parentTree);
    }

    updateRows.sort((a, b) => (a.tree > b.tree) ? 1 : -1)
    this.categoryrows = [...updateRows];
  }

  buildParentTree(parentCategoryName: string, treePath: string): string {
    var path = "";
    if (parentCategoryName && parentCategoryName != "Root") {
      let parents = this.allcategories.filter(x => x.categoryName == parentCategoryName);
      if (parents && parents.length >= 1) {
        let parent = parents[0];
        if (parent) {
          path = parentCategoryName + " > " +  treePath;
          treePath = this.buildParentTree(parent.parentCategoryName, path);
        }
        else {
          return treePath;
        }
      }     
    }
    return treePath;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
       this.categoryrows = this.categoryrowscache.filter(r => Utilities.searchArray(value, false, r.categoryName, r.parentCategoryName));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.categoryrows = [...this.categoryrowscache];
    }
  }

  deleteCategory(row: CMCategories) {
    //Check that category doesnt have any stock codes assigned
    this.sysproService.getStockCodesForCategory(row.id).subscribe(x => this.onValidationSuccesful(x, row), error => this.onPostDataLoadFailed(error));

  }
  onValidationSuccesful(x: CMStockCode[], row: CMCategories): void {
    console.log(x);
    if (x && x.length >= 1) {
      this.alertService.showMessage('Warning', `You cannot delete a category with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
    }
    else {
      this.isSaving = true;
      this.alertService.startLoadingMessage('Saving changes...');
      this.sysproService.deleteCMCategory(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
    }
  }


  enableCategory(row: CMCategories) {
    row.statusIndicator = "E";
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateCMCategory(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  disableCategory(row: CMCategories) {
    row.statusIndicator = "D";
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateCMCategory(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));    
  }

  saveSegment(row: CMCategories) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.updateCMCategory(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  onSaveSuccesful(): void {

    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
   // this.loadData();
  }


  onFileChange(event, row: CMCategories) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.sysproService.postFileData(formData, "CATEGORY", row.categoryName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.filePostedSuccess(x, row), error => this.onPostDataLoadFailed(error));
      
    }
  }

  filePostedSuccess(result: any, row: CMCategories) {

    console.log(result);
    row.categoryImage = result.fileName;
    row.categoryImageChanged = new Date();
    this.saveSegment(row);
  }

  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  exportCategories() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Path", "Category", "Status", "Title", "KeyWords", "Meta Description"]
    };

    var filename = "Missing Category Images";
    var selectRows = [...this.categoryrows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Path: row.tree, Category: row.categoryName, Status: row.statusIndicator, Title: row.title, KeyWords: row.keywords, MetaDescription: row.metaDescription
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
