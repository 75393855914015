
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { CustomerMovements } from 'src/app/models/customer-movements.model';
import { localStorageActions } from 'src/app/models/enums';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { ngxCsv } from 'ngx-csv';
import { ConfigurationService } from '../../services/configuration.service';



@Component({
  selector: 'syspro-customers-movements',
  templateUrl: './syspro-customers-movements.component.html',
  styleUrls: ['./syspro-customers-movements.component.scss']
})
export class SysproCustomersMovementsComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
    rows: CustomerMovements[] = [];
  rowsCache: CustomerMovements[] = [];
  customerMovementsList: CustomerMovements[];
  loadingIndicator: boolean;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('bsDatepicker')
  bsDatepicker: BsDatepickerModule;


  @ViewChild('sellpriceTemplate')
  sellpriceTemplate: TemplateRef<any>;

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;


  @ViewChild('bsValue')
  bsValue: TemplateRef<any>;
  myDateValue: Date;
  bsRangeValue: Date[];

    @ViewChild('customerEditor')
  customerEditor: SysproCustomerViewComponent;

  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();


  @Input()
  isViewOnly: boolean;
  
  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  isSearch: boolean; 
  id: string;


  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  //moment = _moment;
  selected: { startDate: Moment, endDate: Moment };
  ranges = {
    'All Dates': [moment().subtract(5, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 2 Years': [moment().subtract(24, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Years': [moment().subtract(36, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  locale: LocaleConfig = {
    applyLabel: 'Done'
  };
  start: Date;
  end: Date;
  inlineDate: any;
  inlineDateTime: any;
  show: boolean;
    chkNS: boolean;
    chkAll: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute) {
    
  }

  ngOnInit() {
    this.columns = [
      { prop: 'invoice', name: 'Invoice', width: 70, cellClass: "left" },
      { prop: 'filterDate', name: 'Date', width: 80, cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'customerPoNumber', name: 'PONumber', width: 100, cellClass: "left" },
      { prop: 'documentType', name: 'Doc', width: 50, cellClass: "left" },
      { prop: 'shippingInstrs', name: 'Delivery',  cellClass: "left" },
      { prop: 'stockCode', name: 'StockCode', width: 100, cellClass: "left" },
      { prop: 'stockDescription', name: 'Description', width: 250, cellClass: "left" },
      { prop: 'warehouse', name: 'WH', width: 30, cellClass: "left" },
      { prop: 'operator', name: 'Operator', width: 70, cellClass: "left" },
      { prop: 'orderQty', name: 'Qty', width: 50, cellClass: "right" },
      { prop: 'sellingPrice', name: 'Price', width: 80, cellTemplate: this.sellpriceTemplate, cellClass: "right" },
      { prop: 'total', name: 'Total', width: 90, cellTemplate: this.totalTemplate, cellClass: "right" }
    ];

    this.selected = {
      startDate: moment().subtract(0, 'month').startOf('month'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.loadData();
  }

  toggle() {
    this.show = true;
  }

  showNSOnly() {
    this.chkNS = !this.chkNS;
    this.chkAll = !this.chkNS;
    this.dateFilterChanged();
  }

  showAll() {
    this.chkAll = !this.chkAll;
    this.chkNS = !this.chkAll;
    this.dateFilterChanged();
  }

  dateFilterChanged() {
    if (this.currentCustomer) {
      var start = new Date(this.selected.startDate.toString());
      var end = new Date(this.selected.endDate.toString());
      if (this.rowsCache && this.rowsCache.length >= 1) {
        if (this.chkNS) {
          this.rows = this.rowsCache.filter(m => m.lineType == "7" && new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
        }
        else {
          this.rows = this.rowsCache.filter(m => new Date(m.filterDate) >= new Date(start) && new Date(m.filterDate) <= new Date(end));
        }
      }
    }
  }

  searchDates(e) {
   
    this.selected = {
     startDate: e.startDate,
     endDate: e.endDate
    }

    this.loadDetails();
   // this.dateFilterChanged();

    this.show = false;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadDetails();
   // this.dateFilterChanged();

    this.show = false;
  }

 
  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }


    this.loadDetails();
   // this.dateFilterChanged();

    this.show = false;
  }

    ngAfterViewInit() {
      this.columns = [
        { prop: 'invoice', name: 'Invoice', width: 70, cellClass: "left" },
        { prop: 'filterDate', name: 'Date', width: 80, cellClass: "left", cellTemplate: this.dateTemplate },
        { prop: 'customerPoNumber', name: 'PONumber', width: 100, cellClass: "left" },
        { prop: 'documentType', name: 'Doc', width: 50, cellClass: "left" },
        { prop: 'shippingInstrs', name: 'Delivery',  cellClass: "left" },
        { prop: 'stockCode', name: 'StockCode', width: 100, cellClass: "left" },
        { prop: 'stockDescription', name: 'Description', width: 250, cellClass: "left" },
        { prop: 'warehouse', name: 'WH', width: 30, cellClass: "left" },
        { prop: 'operator', name: 'Operator', width: 70, cellClass: "left" },
        { prop: 'orderQty', name: 'Qty', width: 50, cellClass: "right" },
        { prop: 'sellingPrice', name: 'Price', width: 80, cellTemplate: this.sellpriceTemplate, cellClass: "right" },
        { prop: 'total', name: 'Total', width: 90, cellTemplate: this.totalTemplate, cellClass: "right" }
      ];

  }



  loadData() {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    this.searchType = "customer";
    if (action && action.length >= 4) {
      this.id = action;
      this.currentCustomer = this.id;
      this.searchText = this.id;

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getCustomerDetail(this.id).subscribe(
        x => this.onSpecificSearchSuccesful([x]), error => this.onDataLoadFailed(error));
    }
    else {

    }
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.customerMovementsList = null;
    this.rowsCache = null;
    this.rows = null;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }

  }
    
  private loadCustomer() {
        this.id = this.searchResultSelected.customer;
        this.currentCustomer = this.id;
        sessionStorage.removeItem(localStorageActions.Customer);
        sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);

        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        this.customerHeading = this.searchResultSelected;
        this.loadDetails();
        this.router.navigate(['../../customers'], { fragment: 'movements', queryParams: { customer: this.currentCustomer } });
    }

  onDataLoadSuccessful(customers: any[]) {

    if (customers && customers[0].length >= 1) {
      this.customerMovementsList = customers[0];

      this.customerMovementsList.forEach((customer, index, customers) => {
        (<any>customer).index = index + 1;
      });

      this.rowsCache = [...this.customerMovementsList];

      this.chkAll = true;
      this.dateFilterChanged();
    }
    else {
      this.alertService.showStickyMessage('No results', 'No reults found',
        MessageSeverity.warn);
    }
   

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }

  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }


  loadDetails() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.rows = null;
    this.rowsCache = null;

    var start = new Date(this.selected.startDate.toString());
    var end = new Date(this.selected.endDate.toString());

    this.sysproService.getCustomerMovements(start.toJSON(), end.toJSON(), this.id).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Customer", "Invoice", "Date", "CustomerPO", "Document", "ShippingInstrs", "StockCode", "Description", "WH", "Operator", "Qty", "Price", "Total"]
    };

    var filename = this.id + "-Movements";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Customer: row.customer, Invoice: row.invoice, Date: (moment(row.filterDate)).format('DD-MMM-YYYY'), 
        CustomerPO: row.customerPoNumber, Document: row.documentType, ShippingInstrs: row.shippingInstrs,  StockCode: row.stockCode,
        Description: row.stockDescription, WH: row.warehouse, Operator: row.operator,
        Qty: row.orderQty, Price: row.sellingPrice, Total: row.total
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rows.filter(r => Utilities.searchArray(value, false, r.invoice, r.date, r.customerPoNumber, r.warehouse, r.documentType, r.operator, r.stockCode, r.stockDescription));
    }
    else {
      this.dateFilterChanged();
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
