import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    animations: [fadeInOut]
})
export class ReportsComponent {

  isListActivated = false;
  isReprintActivated = false;
  isInvoiceStatusActivated = true;
  isProductivityActivated = false;
  isControlReportActivated = false;
  isDeliveryReportActivated = false;
  isCollectionsReportActivated = false;
  isSalesReportActivated = false;
  isQuoteSalesReportActivated = false;
  isDebtorsReleaseReportActivated = false;
  isCreditorsReportActivated = false;
  isCMSReportActivated = false;
  isInvoiceTrackingPurgeActivated = false;
  isBankEFTExportActivated = false;
  isInventoryMovementsReportActivated = false;
  isInventoryAdjustmentsReportActivated = false;
  isCollapsed = false;

  fragmentSubscription: any;

  readonly reportsItsListTab = 'itslist';
  readonly reportsInvoiceReprintTab = 'reprint';
  readonly reportsInvoiceStatusTab = 'invoicestatus';
  readonly reportsSalesReportTab = 'salesreport';
  readonly reportsQuoteSalesReportTab = 'quotesalesreport';
  readonly reportsDebtorsReleaseReportTab = 'debtorsreleasereport';
  readonly reportsCreditorsReportTab = 'creditorsreport';
  readonly reportInvoiceTrackingPurgeTab = 'invoicetrackingpurge';
  readonly reportsProductivityTab = 'productivity';
  readonly reportsInventoryMovementsReportTab = 'inventorymovementreport';
  readonly reportsInventoryAdjustmentsReportTab = 'inventoryadjustmentreport';
  readonly reportsCMSTab = 'cms';
  readonly reportsControlTab = 'control';
  readonly reportsDeliveryTab = 'delivery';
  readonly reportsCollectionsTab = 'collections';
  readonly bankeftexportTab = 'bankeftexport';

  @ViewChild('tab')
  tab: BootstrapTabDirective;


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {
   
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isCollapsed = !this.configurations.menuExpanded;
  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }


  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  showContent(anchor: string) {

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.reportsItsListTab}Tab`);
 
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isReprintActivated = activeTab == this.reportsInvoiceReprintTab;
    this.isInvoiceStatusActivated = activeTab == this.reportsInvoiceStatusTab;
    this.isSalesReportActivated = activeTab == this.reportsSalesReportTab;
    this.isQuoteSalesReportActivated = activeTab == this.reportsQuoteSalesReportTab;
    this.isDebtorsReleaseReportActivated = activeTab == this.reportsDebtorsReleaseReportTab;
    this.isCreditorsReportActivated = activeTab == this.reportsCreditorsReportTab;
    this.isInvoiceTrackingPurgeActivated = activeTab == this.reportInvoiceTrackingPurgeTab;
    this.isProductivityActivated = activeTab == this.reportsProductivityTab;
    this.isControlReportActivated = activeTab == this.reportsControlTab;
    this.isDeliveryReportActivated = activeTab == this.reportsDeliveryTab;
    this.isCollectionsReportActivated = activeTab == this.reportsCollectionsTab;
    this.isBankEFTExportActivated = activeTab == this.bankeftexportTab;
    this.isInventoryMovementsReportActivated = activeTab == this.reportsInventoryMovementsReportTab;
    this.isInventoryAdjustmentsReportActivated = activeTab == this.reportsInventoryAdjustmentsReportTab;
    this.isCMSReportActivated = activeTab == this.reportsCMSTab;

    this.router.navigate([], { fragment: activeTab });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isReprintActivated = activeTab == this.reportsInvoiceReprintTab;
    this.isInvoiceStatusActivated = activeTab == this.reportsInvoiceStatusTab;
    this.isSalesReportActivated = activeTab == this.reportsSalesReportTab;
    this.isQuoteSalesReportActivated = activeTab == this.reportsQuoteSalesReportTab;
    this.isDebtorsReleaseReportActivated = activeTab == this.reportsDebtorsReleaseReportTab;
    this.isCreditorsReportActivated = activeTab == this.reportsCreditorsReportTab;
    this.isInvoiceTrackingPurgeActivated = activeTab == this.reportInvoiceTrackingPurgeTab;
    this.isProductivityActivated = activeTab == this.reportsProductivityTab;
    this.isControlReportActivated = activeTab == this.reportsControlTab;
    this.isDeliveryReportActivated = activeTab == this.reportsDeliveryTab;
    this.isCollectionsReportActivated = activeTab == this.reportsCollectionsTab;
    this.isBankEFTExportActivated = activeTab == this.bankeftexportTab;
    this.isInventoryMovementsReportActivated = activeTab == this.reportsInventoryMovementsReportTab;
    this.isInventoryAdjustmentsReportActivated = activeTab == this.reportsInventoryAdjustmentsReportTab;
    this.isCMSReportActivated = activeTab == this.reportsCMSTab;


    this.router.navigate([], { fragment: activeTab });
  }
   

  get canViewRerpintReport() {
    return this.accountService.userHasPermission(Permission.reprintReportsPermission);
  }


  get canViewSalesReports() {
    return this.accountService.userHasPermission(Permission.salesReportsPermission);
  }

  get canViewDebtorsReleaseReports() {
    return this.accountService.userHasPermission(Permission.debtorsReleaseReportsPermission);
  }

  get canViewProductivityReport() {
    return this.accountService.userHasPermission(Permission.productivityReportsPermission);
  }

  get canViewControlReport() {
    return this.accountService.userHasPermission(Permission.itsControlReportsPermission);
  }

  get canViewBankExport() {
    return this.accountService.userHasPermission(Permission.bankEFTExportPermission);
  }


  get canViewCreditNoteReports() {
    //TODO
    return this.accountService.userHasPermission(Permission.debtorsReleaseReportsPermission);
  }


  get canViewCMSReport() {
    //TODO
    return true;
  }

}
