import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
    animations: [fadeInOut]
})
export class TrackingComponent {

  isListActivated = false;
  isInvoiceStatusActivated = true;
  isInvoiceTrackingPurgeActivated = false;
  isDriversListActivated = false;
  isVehicleListActivated = false;
  isCageListActivated = false;
  isAreaListActivated = false;
  isPrintQueueActivated = false;
  isDriversReportActivated = false;
  isCollapsed = false;
  isLogInActivated = false;

  fragmentSubscription: any;

  readonly reportsItsListTab = 'itslist';
  readonly reportsInvoiceStatusTab = 'invoicestatus';
  readonly reportInvoiceTrackingPurgeTab = 'invoicetrackingpurge';
  readonly driversListTab = 'driverslist';
  readonly vehiclesListTab = 'vehicleslist';
  readonly cagesListTab = 'cageslist';
  readonly areaListTab = 'arealist';
  readonly itsPrintQueueTab = 'itsprintqueue';
  readonly driversReportTab = 'itsdriversreport';
  readonly loginTab = 'itslogin';

  @ViewChild('tab')
  tab: BootstrapTabDirective;


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {
   
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isCollapsed = !this.configurations.menuExpanded;
  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }


  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  showContent(anchor: string) {

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.reportsItsListTab}Tab`);
 
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isInvoiceStatusActivated = activeTab == this.reportsInvoiceStatusTab;
    this.isInvoiceTrackingPurgeActivated = activeTab == this.reportInvoiceTrackingPurgeTab;

    this.isDriversListActivated = activeTab == this.driversListTab;
    this.isVehicleListActivated = activeTab == this.vehiclesListTab;
    this.isCageListActivated = activeTab == this.cagesListTab;
    this.isAreaListActivated = activeTab == this.areaListTab;
    this.isPrintQueueActivated = activeTab == this.itsPrintQueueTab;
    this.isDriversReportActivated = activeTab == this.driversReportTab;

    this.isLogInActivated = activeTab == this.loginTab;

    this.router.navigate([], { fragment: activeTab });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isInvoiceStatusActivated = activeTab == this.reportsInvoiceStatusTab;
    this.isInvoiceTrackingPurgeActivated = activeTab == this.reportInvoiceTrackingPurgeTab;
    this.isDriversListActivated = activeTab == this.driversListTab;
    this.isVehicleListActivated = activeTab == this.vehiclesListTab;
    this.isCageListActivated = activeTab == this.cagesListTab;
    this.isAreaListActivated = activeTab == this.areaListTab;
    this.isPrintQueueActivated = activeTab == this.itsPrintQueueTab;
    this.isDriversReportActivated = activeTab == this.driversReportTab;

    this.isLogInActivated = activeTab == this.loginTab;

    this.router.navigate([], { fragment: activeTab });
  }
   

  get canViewDriversLog() {
    return this.accountService.userHasPermission(Permission.viewITSConfigPermission);
  }


  get canManageDriversSetup() {
    return this.accountService.userHasPermission(Permission.manageITSConfigPermission);
  }

}
