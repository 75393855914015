import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { WarehouseApplicationDefault } from '../../../models/warehouse-application-settings.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';

@Component({
  selector: 'ITS-settings',
  templateUrl: './settings.ITS.component.html',
  styleUrls: ['./settings.ITS.component.scss'],
  animations: [fadeInOut]
})
export class ITSSettingsComponent {

  rows: any[];
  userrows: any[];
  enableEdit: boolean = false;
  enableEditIndex: number | null = null;
  loadingIndicator: boolean = false;
  isSaving: boolean = false;
  isWarehouseCollapsed: boolean = false;
  isUserCollapsed: boolean = true;

  constructor(private http: HttpClient, private alertService: AlertService, private fowkesService: FowkesOnlineService) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getITSWarehouseDefaults().subscribe(
      (x) => this.onDataLoadSuccessful(x),
      (error) => this.onDataLoadFailed(error)
    );

  }

  onDataLoadSuccessful(WarehouseApplicationDefault: any[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rows = WarehouseApplicationDefault;
  }


  enableEditMethod(row: any, i: number) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  }

  saveSegment(row: any) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.fowkesService.updateITSWarehouseDefaults(row).subscribe(
      () => this.onUpdateSuccesful(),
      (error) => this.saveFailedHelper(error)
    );
  }


  onUpdateSuccesful() {
    this.isSaving = false;
    this.enableEdit = false;
    this.enableEditIndex = null;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Updated successfully', MessageSeverity.success);
    this.loadData();
  }

  saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occurred whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  toggleWarehouseSection() {
    this.isWarehouseCollapsed = !this.isWarehouseCollapsed;
  }


}
