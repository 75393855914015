import { Brand } from "./brand.model";
import { StockCodeDocuments } from "./cms-stockcode.documents.model";

export class CMStockCode {
  constructor(stockCode?: string, description?: string, onSpecial?: boolean, onSpecialFowkes?: boolean, alternateKey?: string,
    specialsImage?: string, specialPrice?: number,   
    extraDescription1?: string, extraDescription2?: string,
    categoryName?: string, brandName?: string, stockCodeImage?: string,
    dataSheetImage?: string, addSheetImage?: string, status?: string, stockCodeImageChanged?: Date, dataSheetImageChanged?: Date,
  addSheetImageChanged?: Date, specialsImageChanged?: Date ) {

    this.brandName = brandName;
    this.stockCode = stockCode;
    this.description = description;
    this.onSpecial = onSpecial;
    this.onSpecialFowkes = onSpecialFowkes;
    this.alternateKey = alternateKey;
    this.specialsImage = specialsImage;
    this.specialPrice = specialPrice;
    this.extraDescription1 = extraDescription1;
    this.extraDescription2 = extraDescription2;
    this.categoryName = categoryName;
    this.stockCodeImage = stockCodeImage;
    this.dataSheetImage = dataSheetImage;
    this.addSheetImage = addSheetImage;
    this.stockCodeImageChanged = stockCodeImageChanged;
    this.dataSheetImageChanged = dataSheetImageChanged;
    this.addSheetImageChanged = addSheetImageChanged;
    this.specialsImageChanged = specialsImageChanged;
  }

  public stockCode: string;
  public description: string;
  public extraDescription1: string;
  public extraDescription2: string;
  public categoryName: string;
  public brandName: string;
  public stockCodeImage: string;
  public dataSheetImage: string;
  public addSheetImage: string;
  public status: string;
  public showAlternate: boolean;
  public newArrival: boolean;
  public onSpecial: boolean;
  public onSpecialFowkes: boolean;
  public alternateKey: string;
  public specialsImage: string;
  public categoryTree: string;
  //public webDescription: string;
  //public showWebDescription: boolean;
  //public createdDate: string;
  //public createdBy: string;
  public id: number;
  public filePath: string;


  public qtyCT: number;
  public qtyCombined: number;
  public binLocCT: string;
  public createDate: Date;

  public specialPrice: number;


  public stockCodeImageChanged: Date;//null;
  public dataSheetImageChanged: Date;//null;
  public addSheetImageChanged: Date;//null;
  public specialsImageChanged: Date;//null;


  //public path: string;

  //public brandDetail: Brand;
  //public stockCodeDocuments: StockCodeDocuments[];

}
