import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';


import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [fadeInOut]
})
export class SettingsComponent implements OnInit, OnDestroy {

  isProfileActivated = false;
  isPreferencesActivated = true;
  isUsersActivated = false;
  isRolesActivated = false;
  isConfigActivated = false;
  isServiceSettingsActivated = false;
  isBranchSettingsActivated = false;
  isPrinterSettingsActivated = false;
  isInfoSettingsActivated = false;
  isInfoDocsActivated = false;
  isInboxActivated = false;
  isSalesInboxActivated = false;
  isITSSettingsActivated = false;
  isITSUserSettingsActivated = false;

  isCollapsed = false;

  fragmentSubscription: any;

  readonly profileTab = 'profile';
  readonly preferencesTab = 'preferences';
  readonly usersTab = 'users';
  readonly rolesTab = 'roles';
  readonly configTab = 'configuration';
  readonly branchsettingsTab = 'branchsettings';
  readonly printersettingsTab = 'printersettings';
  readonly servicesettingsTab = 'servicesettings';
  readonly infosettingsTab = 'infosettings';
  readonly infodocsTab = 'infodocs';
  readonly inboxTab = 'inbox';
  readonly salesinboxTab = 'salesinbox';
  readonly itssettingsTab = 'itssettings';
  readonly itsuserTab = 'itsusersettings';


  @ViewChild('tab')
  tab: BootstrapTabDirective;


  constructor(private router: Router, private route: ActivatedRoute, private configurations: ConfigurationService, private accountService: AccountService) {
  }


  ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isCollapsed = !this.configurations.menuExpanded;
  }


  ngOnDestroy() {
    this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string) {
    if (anchor) {
      anchor = anchor.toLowerCase();
    }

    if ((this.isFragmentEquals(anchor, this.usersTab) && !this.canViewUsers) ||
      (this.isFragmentEquals(anchor, this.rolesTab) && !this.canViewRoles)) {
      return;
    }

    this.tab.show(`#${anchor || this.preferencesTab}Tab`);
  }


  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isProfileActivated = activeTab == this.profileTab;
    this.isPreferencesActivated = activeTab == this.preferencesTab;
    this.isUsersActivated = activeTab == this.usersTab;
    this.isRolesActivated = activeTab == this.rolesTab;
    this.isConfigActivated = activeTab == this.configTab;
    this.isBranchSettingsActivated = activeTab == this.branchsettingsTab;
    this.isPrinterSettingsActivated = activeTab == this.printersettingsTab;
    this.isServiceSettingsActivated = activeTab == this.servicesettingsTab;
    this.isInfoSettingsActivated = activeTab == this.infosettingsTab;
    this.isInfoDocsActivated = activeTab == this.infodocsTab;
    this.isInboxActivated = activeTab == this.inboxTab;
    this.isSalesInboxActivated = activeTab == this.salesinboxTab;
    this.isITSSettingsActivated = activeTab == this.itssettingsTab;
    this.isITSUserSettingsActivated = activeTab == this.itsuserTab;


    this.router.navigate([], { fragment: activeTab });
  }

  get canManageUsers() {
    return this.accountService.userHasPermission(Permission.manageUsersPermission);
  }


  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }


  get canViewConfiguration() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
