
import { NgModule } from '@angular/core';
import {
  Routes, RouterModule, PreloadingStrategy,
  PreloadAllModules,  DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { CustomerPortalComponent } from './components/customer-portal/customer-portal.component';
import { RepairsComponent } from './components/repairs/repairs.component';
import { CustomersComponent } from './components/customers/customers.component';
import { OrdersComponent } from './components/orders/orders.component';
import { SalesOrdersComponent } from './components/salesorders/salesorders.component';
import { PurchaseOrdersComponent } from './components/purchaseorders/purchaseorders.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PodComponent } from './components/pod/pod.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { Utilities } from './services/utilities';
import { SysproCustomerViewComponent } from './components/controls/syspro-customer-view.component';
import { DocumentsComponent } from './components/documents/document.component';
import { ProductsComponent } from './components/products/products.component';
import { WebComponent } from './components/web/web.component';
import { ProductsAdvertsComponent } from './components/products/products.adverts.component';
import { ProductsClearanceComponent } from './components/products/products.clearance.component';
import { ProductsCalculatorComponent } from './components/products/products-calculator.component';



export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}
 

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Home' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: { title: 'Customers' },
    children: [{
      path: 'customers/view', // child route path
      component: SysproCustomerViewComponent, // child route component that the router renders
    }]
  },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard], data: { title: 'Products' } },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: { title: 'Orders' } },
  { path: 'salesorders', component: SalesOrdersComponent, canActivate: [AuthGuard], data: { title: 'Sales Orders' } },
  { path: 'purchaseorders', component: PurchaseOrdersComponent, canActivate: [AuthGuard], data: { title: 'Purchase Orders' } },
  { path: 'pods', component: PodComponent, canActivate: [AuthGuard], data: { title: 'Pods' } },
  { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard], data: { title: 'Documents' } },
  { path: 'quotes', component: QuotesComponent, canActivate: [AuthGuard], data: { title: 'Quotes' } },
  { path: 'web', component: WebComponent, canActivate: [AuthGuard], data: { title: 'Web' } },
  { path: 'repairs', component: RepairsComponent, canActivate: [AuthGuard], data: { title: 'Repairs' } },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], data: { title: 'Reports' } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Settings' } },
  { path: 'about', component: AboutComponent, data: { title: 'About Us' } },
  { path: 'viewportal', component: CustomerPortalComponent, data: { title: 'Customer Portal' } },
  { path: 'tracking', component: TrackingComponent, data: { title: 'ITS' } },
  { path: 'adverts', component: ProductsAdvertsComponent, data: { title: 'Adverts' } },
  { path: 'clearance', component: ProductsClearanceComponent, data: { title: 'Clearance' } },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found' } }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
})
export class AppRoutingModule { }
