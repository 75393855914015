
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Inventory } from 'src/app/models/inventory.model';
import { Calculations } from 'src/app/services/calculations.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Brand } from '../../models/brand.model';
import { CMCategories } from '../../models/cms-category.model';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { CMSAdditionalImage } from '../../models/cms-additional-images.model';
import { localStorageActions } from '../../models/enums';
import { SimpleTree } from '../../models/simple-tree.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { ReportService } from '../../services/report.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'stockcodes',
  templateUrl: './products.stockcodes.component.html',
  styleUrls: ['./products.stockcodes.component.scss']
})


export class StockCodesComponent implements OnInit {

  loadingIndicator: boolean;
  inventoryList: Inventory[];

  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;

  @ViewChild('categoryListSelector')
  private categoryListSelector;
  private selectUndefinedOptionValue: string = "Please Select";
  categorySelected: string = "";
  categoryList: CMCategories[] = [];
  categoryListChangeParent: CMCategories[] = [];
  changeParent: boolean = false;

  searchType: string = "";
  searchText: string = "";
  searchResults: Inventory[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  columns: any[] = [];
  rows: Inventory[] = [];
  rowsCache: Inventory[] = [];

  searchingOn: string;
  chkShortDescription: boolean;
  chkLongDescription: boolean;
  chkAnyDescription: boolean;
  hasAddDataSheets: boolean;
  hasAdditionalImages: boolean;


  @ViewChild('brandListSelector')
  private brandListSelector;
  brandSelected: string = "";
  brandList: Brand[] = [];

  @ViewChild('alternateListSelector')
  private alternateListSelector;
  alternateSelected: string = "";
  alternatesList: any[] = [];

  newAdditionalImageData = new FormData();
  newStockImageData = new FormData();
  newSpecialsImageData = new FormData();
  newDatasheetImageData = new FormData();
  newAddDatasheetImageData = new FormData();

  @Input()
  stockCode: string;

  @Input()
  isManagement: boolean;

  @Input()
  selectedCategory: string;

  stockCodedetail: Inventory;
  updatedStockCodeDetail: CMStockCode = new CMStockCode();
  additionalImages: CMSAdditionalImage[] = [];
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  hasDataSheet: boolean;
  hasAddDataSheet: boolean;
  hasSpecials: boolean;
  hasSpecialImage: boolean;
  alternateKey: string;
  hasImpaCodes: boolean;
  isSaving: boolean;
  isEnabled: boolean;
  chkDeleteDS1: boolean;
  chkDeleteDS2: boolean;

  changeImage: boolean;
  changeSpecials: boolean;
  changeDatasheet1: boolean;
  changeDatasheet2: boolean;
  changeAdditionalImage: boolean;
  changeAdditionalVideo: boolean;

  toggleGrid: boolean;

  items: TreeviewItem[];
  treerows: string[];
  item: any;
  data: CMCategories;
  categoryData: CMCategories[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild(TreeviewComponent)
  categoryTreeviewComponent: TreeviewComponent;

  @ViewChild('treeModal')
  treeModal: ModalDirective;

  @ViewChild('treeItemTemplate')
  treeItemTemplate: TemplateRef<any>;

  treeItems: TreeviewItem[] = [];
  treeItem: any;
  newParentName: any;

  newStockCodeForCategory: boolean;
    hasOrphinRecords: boolean;
    deletedCount: any;
    deletingNumber: number;
    showExport: boolean;
    buyerResponsible: string;
    newVideoURL: string;
    hasClearanceFlag: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute, private http: HttpClient, private sanitizer: DomSanitizer) {
  }


  ngOnInit() {

    this.searchType = "stockcode";
    let stockCode = sessionStorage.getItem(localStorageActions.StockCode);

    this.isManagement = this.isManagement == true ? this.isManagement : false;
    this.loadData();

    this.chkShortDescription = true;
    this.chkLongDescription = false;
    this.chkAnyDescription = false;

    if (stockCode) {
      this.stockCode = stockCode;
      this.loadStockCode(stockCode);
    }
    else {
      if (this.selectedCategory) {
        this.newStockCodeForCategory = true;
        this.searchType = "unmapped";
        this.changeParent = true;
        this.newParentName = this.selectedCategory;
      }
    }
  }

 
  ngAfterViewInit() {
   
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getBrands().subscribe(x => this.onBrandsLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onCategoriesLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }

  clearData() {
    this.stockCode = "";
    this.stockCodedetail = new Inventory();
    this.hasDataSheet = false;
    this.hasAddDataSheet = false;
    this.hasSpecials = false;
    this.hasSpecialImage = false;
    this.hasImpaCodes = false;
    this.isEnabled = false;
    this.isSaving = false;
    this.chkDeleteDS1 = false;
    this.chkDeleteDS2 = false;
    this.changeImage = false;
    this.changeSpecials = false;
    this.changeDatasheet1 = false;
    this.changeDatasheet2 = false;
    this.changeAdditionalImage = false;
    this.changeAdditionalVideo = false;
    this.newStockImageData = new FormData();
    this.newSpecialsImageData = new FormData();
    this.newDatasheetImageData = new FormData();
    this.newAddDatasheetImageData = new FormData();
    this.newAdditionalImageData = new FormData();
    this.changeParent = false;
    this.newParentName = "";

    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onCategoriesLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    let stockCode = sessionStorage.getItem(localStorageActions.StockCode);

    if (stockCode) {
      this.stockCode = stockCode;
      this.loadStockCode(stockCode);
    }
  }

  showStockCode(detail: any) {

    this.stockCode = detail.stockCode;
    this.loadStockCode(detail.stockCode);
  }


  selectStockCode(detail: any, event) {

    this.stockCode = detail.stockCode;
    this.loadStockCode(detail.stockCode);
  }


  deleteStockCode(detail: any, event) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.deletingNumber = 1;
    this.sysproService.deleteCMStockCode(detail.id).subscribe(x => this.stockCodeDeleteSuccess(detail.stockCode), error => this.onDataLoadFailed(error));
   
  }

  stockCodeDeleteSuccess(deletedCode: string): void {
    this.deletedCount++;

    if (this.deletedCount == this.deletingNumber) {

      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', deletedCode + ` Has been deleted successfully`, MessageSeverity.success);
      this.specificSearch();
    }
  }


  deleteAllOrphins() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var updateRows = this.rowsCache;
    this.deletingNumber = updateRows.length;
    for (var orphin of updateRows) {
      this.sysproService.deleteCMStockCode(orphin.id).subscribe(x => this.stockCodeDeleteSuccess(orphin.stockCode), error => this.onDataLoadFailed(error));
    }
  }

  loadStockCode(stockCode: string) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    console.log(this.stockCode);
    this.sysproService.getStockCodeDetail(this.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onStockCodeLoadSuccessful(x), error => this.onDataLoadFailed(error));

  }
  specificSearch() {

    this.deletedCount = 0;
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      // this.inventoryList = null;
      this.searchingOn = this.searchType;
      this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchingOn, this.searchText.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(
        x => this.onStockLoadSuccessful([x], false), error => this.onDataLoadFailed(error));
    }
    else {
      if (this.searchType == "unmapped" || this.searchType == "deleted") {
        this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchType, "ALL").subscribe(
          x => this.onStockLoadSuccessful([x], true), error => this.onDataLoadFailed(error));
      }
      else {

        this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
          MessageSeverity.warn);
      }
    }
  }


  onSpecificSearchSuccesful(x: Inventory[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;
    this.onStockLoadSuccessful([x], false);

  }


  onStockLoadSuccessful(stockCodes: any[], includeAdditional: boolean): void {

    this.showExport = includeAdditional;
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.inventoryList = stockCodes[0];

    if (this.inventoryList.length > 0) {

      this.inventoryList.forEach((stockCode, index, inventory) => {
        (<any>stockCode).index = index + 1;
        stockCode.description = stockCode.shortDescription + ' - ' + stockCode.longDescription;
        (<any>stockCode).orphin = (this.searchType == "deleted");
        if (includeAdditional && this.searchType != "deleted" ) {
          this.sysproService.getStockCodeAdditionalDetail(stockCode.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(
            x => {
              var addDetails = x[0];
              stockCode.qtyCT = addDetails.qtyCT;
              stockCode.qtyCombined = addDetails.qtyCombined;
              stockCode.binLocCT = addDetails.binLocCT;
              stockCode.createDate = addDetails.createDate;

            }, error => this.onDataLoadFailed(error));
        }
      });

      this.rowsCache = [...this.inventoryList];
      this.rows = [...this.rowsCache];

      if (this.searchType == "deleted") {
        this.hasOrphinRecords = true;
      }

      this.toggleGrid = true;
    }
    else {
      this.rows = null;
      this.rowsCache = null;

      if (this.searchType == "deleted") {
        this.alertService.showMessage('In Sync', 'All stock codes have been removed from catalogue - Next Gen is now up to date with SYSPRO',
          MessageSeverity.success);
      }
      else {
        this.alertService.showMessage('No Records', 'Could not find any records for your search.',
          MessageSeverity.warn);
       
      }
    }



  }

  toggleGridOpen() {
    this.toggleGrid = !this.toggleGrid;
  }

  onBrandsLoadSuccessful(brands: any[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (brands[0]) {
      this.brandList = [...brands[0]];     
    }
  }

  changeCategoryParent() {
    this.changeParent = !this.changeParent;

    if (this.changeParent) {

      if (!this.treeModal) {
        setTimeout(() => {
          if (this.treeModal) {
            this.treeModal.show();
          }
        });
      }
      else {
        this.treeModal.show();
      }
    }
  }


  treeItemBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
      }
    }
  }

  selectTreeItem(row: TreeviewItem) {

    this.newParentName = row.text;
    this.treeModal.hide();
  }


  onCategoriesLoadSuccessful(treeview: any[]) {
    if (treeview[0]) {
      let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

      this.items = this.getItems([treeviewItems]);
      this.treeItems = this.getItems([treeviewItems]);
    }
  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }

  list_to_tree(list) {
  var map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentCategory !== "Root") {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentCategory]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

  onAltStockCodesLoadSuccessful(altStockCodes: any[]) {
    if (altStockCodes[0]) {
      this.alternatesList = [...altStockCodes[0]];
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName ;
  }

  onStockCodeLoadSuccessful(row: Inventory): void {
    this.hasClearanceFlag = false;
    if (row) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail = row[0];
      this.stockCodedetail.categoryName = row[0].categoryName;
      this.hasDataSheet = false;
      this.hasAddDataSheet = false;
      this.hasSpecials = false;
      this.hasSpecialImage = false;
      this.hasImpaCodes = false;
      this.hasAdditionalImages = false;
      this.isEnabled = this.stockCodedetail.status == "E";
      var test = this.stockCodedetail.modifiedDate;
      this.changeAdditionalImage = false;
      this.changeAdditionalVideo = false;

      if (this.stockCodedetail) {
        if (this.stockCodedetail.dataSheetImage) {
          this.hasDataSheet = true;
          this.stockCodedetail.dataSheetImage = encodeURIComponent(this.stockCodedetail.dataSheetImage);
        }
        if (this.stockCodedetail.addSheetImage) {
          this.hasAddDataSheet = true;
          this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
        }
        if (this.stockCodedetail.specialsImage) {
          this.stockCodedetail.specialsImage = encodeURIComponent(this.stockCodedetail.specialsImage);
          this.hasSpecialImage = true;
        }
        if ((this.stockCodedetail.onSpecialFowkes || this.stockCodedetail.onSpecial)) {
          this.hasSpecials = true;
        }
        if (this.stockCodedetail.impaCodes && this.stockCodedetail.impaCodes != "") {
          this.hasImpaCodes = true;
        }
      }
      this.stockCodedetail.stockCodeImage = encodeURIComponent(this.stockCodedetail.stockCodeImage);
    }

    if (this.stockCodedetail.id && this.stockCodedetail.id >= 1) {
      this.sysproService.getAdditionalImagesForStockCode(this.stockCodedetail.id).subscribe(x => this.onAdditionalImagesLoaded(x), error => this.onDataLoadFailed(error));

      //Check if cleanrance flag has been set

      this.sysproService.getStockCearanceFlag(this.stockCodedetail.id).subscribe(x => this.onClearanceFlagLoadSuccessful(x), error => this.onDataLoadFailed(error));

    }
    this.sysproService.getAltStockCodeDetail(this.stockCodedetail.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onAltStockCodesLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.updatedStockCodeDetail = this.stockCodedetail;
    

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }
    onClearanceFlagLoadSuccessful(x: string): void {
      if (x && x.length >= 1) {
        this.hasClearanceFlag = true;
      }
      else {

        this.hasClearanceFlag = false;
      }
    }
    onAdditionalImagesLoaded(x: CMSAdditionalImage[]): void {

      if (x && x.length > 0) {
        this.hasAdditionalImages = true;

        this.additionalImages = x.map(image => {
          if (image.referenceType === 'VIDEO') {
            // Generate safe URL by combining filePath and imageName
            const videoUrl = `${image.filePath}/${image.imageName}`;
            const safeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);

            // Update the safeURL property of the CMSAdditionalImage object
            return { ...image, safeURL: safeUrl };
          } else {
            return image;
          }
        });
      }
      else {
        this.hasAdditionalImages = false;
      }

    }

  onImageAddedSuccess(x: CMSAdditionalImage): void {

    this.changeAdditionalImage = !this.changeAdditionalImage;
    if (!this.changeAdditionalImage) {
      this.newAdditionalImageData = new FormData();
    }
    this.sysproService.getAdditionalImagesForStockCode(this.stockCodedetail.id).subscribe(x => this.onAdditionalImagesLoaded(x), error => this.onDataLoadFailed(error));

  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());

    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    console.log(error.status);
    if (error.status == 404) { return; }
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  toggleImageUpload(type: string) {
    switch (type) {
      case "stockcode": {
        this.changeImage = !this.changeImage;
        if (!this.changeImage) {
          this.newStockImageData = new FormData();
        }
        break;
      }
      case "special": {
        this.changeSpecials = !this.changeSpecials;
        if (!this.changeSpecials) {
          this.newSpecialsImageData = new FormData();
        }
        break;
      }
      case "datasheet1": {
        this.changeDatasheet1 = !this.changeDatasheet1;
        if (!this.changeDatasheet1) {
          this.newDatasheetImageData = new FormData();
        }
        break;
      }
      case "datasheet2": {
        this.changeDatasheet2 = !this.changeDatasheet2;
        if (!this.changeDatasheet2) {
          this.newAddDatasheetImageData = new FormData();
        }
        break;
      }
      case "additional": {
        this.changeAdditionalImage = !this.changeAdditionalImage;
        if (!this.changeAdditionalImage) {
          this.newAdditionalImageData = new FormData();
        }
        break;
      }
      case "video": {
        this.changeAdditionalVideo = !this.changeAdditionalVideo;
        if (!this.changeAdditionalVideo) {
          this.newVideoURL = "";
        }
        break;
      }
      default: {
        break;
      }
    }
  }


  anAdditionalImageFileUpload(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newAdditionalImageData.append('file', file);
      this.saveNewImage();

    }
  }


  saveNewVideo() {
    if (this.newVideoURL != "") {
      var imageIndex = 0;
      if (this.additionalImages.length >= 1) {
        imageIndex = Math.max(...this.additionalImages.map(o => o.imageIndex)) + 1;
      }
      var newAddImage = new CMSAdditionalImage();
      newAddImage.imageIndex = imageIndex;
      newAddImage.referenceId = this.stockCodedetail.id;
      newAddImage.stockCode = this.stockCodedetail.stockCode;
      newAddImage.display = true;
      newAddImage.referenceType = "VIDEO";
      newAddImage.imageName = this.newVideoURL;
      newAddImage.modifiedDate = new Date();
      this.sysproService.addNewCMSAdditionalImage(newAddImage).subscribe(x => this.onImageAddedSuccess(x), error => this.onPostDataLoadFailed(error));
     
    }
  }

  deleteAdditionalImage(item: CMSAdditionalImage) {
    this.sysproService.deleteCMSAdditionalImage(item.id).subscribe(x => this.onAdditionalImageDeleted(x), error => this.onDataLoadFailed(error));
  }

  onAdditionalImageDeleted(x: CMSAdditionalImage): void {
    this.sysproService.getAdditionalImagesForStockCode(this.stockCodedetail.id).subscribe(x => this.onAdditionalImagesLoaded(x), error => this.onDataLoadFailed(error));

  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      //this.alternateKey = row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openCategoryLink(category: string) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/Search.aspx?s=" + encodeURIComponent(category), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(category), "_blank");
    }
  }

  openWebsiteLink(stockCode: string) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(stockCode), "_blank");
    }
  }

  openInternalLink(stockCode: string) {
    if (this.application == "MaxArcusOnline") {
      window.open("http://old.maxarcus.co.za/Search.aspx?s=" + encodeURIComponent(stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("http://old.fowkes.co.za/Search.aspx?s=" + encodeURIComponent(stockCode), "_blank");
    }
  }

  openImage() {
    if (!!this.stockCodedetail.stockCodeImage && this.stockCodedetail.stockCodeImage != "") {
      window.open(this.stockCodedetail.filePath + "/" + this.stockCodedetail.stockCodeImage, "_blank");
    }
  }

  openVideo(item: CMSAdditionalImage) {
    if (item.filePath && item.imageName != "") {
      window.open("https://www.youtube.com/watch?v=" + item.imageName, "_blank");
    }
  }

  openAdditionalImage(item: CMSAdditionalImage) {
    if (item.filePath && item.imageName != "") {
      window.open(item.filePath + "/" + item.imageName, "_blank");
    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }


  openSpecials(specialsImg: string) {
    if (!!specialsImg && specialsImg != "") {
      window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
    }
  }

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }


  formatSpecialPrice(): any {
    let row = this.stockCodedetail;
    row.specialPrice = Calculations.setSpecialPriceDecimals(row.specialPrice);
  }

  saveStockCode() {

    //Validate save
    if (this.stockCodedetail) {
      if (this.stockCodedetail.stockCode) {
        if (!this.stockCodedetail.brandName || this.stockCodedetail.brandName == "")
        {
          this.alertService.showMessage('Warning', `No Brand Selected`, MessageSeverity.warn);
        }
        else {
          if ((!this.stockCodedetail.categoryName || this.stockCodedetail.categoryName == "") && (!this.newParentName || this.newParentName == "")) {
            this.alertService.showMessage('Warning', `No Category Selected`, MessageSeverity.warn);
          }
          else {
            this.isSaving = true;
            this.alertService.startLoadingMessage('Saving changes...');

            var newFileName = this.stockCodedetail.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---").replace(/\#/g, "-_-");

            this.handleFiles(newFileName, 0);       
          }

         
        }

      }
      else {
        this.alertService.showMessage('Warning', `No Stock Code Selected`, MessageSeverity.warn);
      }
    }
  }


  saveNewImage() {
    var imageIndex = 0;
    if (this.additionalImages.length >= 1) {
      imageIndex = Math.max(...this.additionalImages.map(o => o.imageIndex)) + 1;
    }
    var newAddImage = new CMSAdditionalImage();
    newAddImage.imageIndex = imageIndex;
    newAddImage.referenceId = this.stockCodedetail.id;
    newAddImage.stockCode = this.stockCodedetail.stockCode;
    newAddImage.display = true;
    newAddImage.referenceType = "STOCKCODE";
    var newFileName = this.stockCodedetail.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---").replace(/\#/g, "-_-") + "_" + imageIndex;
    if (this.newAdditionalImageData.has('file')) {
      this.sysproService.postAdditionalFileData(this.newAdditionalImageData, "STOCKCODE", newFileName).subscribe(x => {
        newAddImage.imageName = x.fileName;
        newAddImage.modifiedDate = new Date();
        this.alertService.showMessage('Success', `Added Stockcode Image`, MessageSeverity.success);
        //save record
        this.sysproService.addNewCMSAdditionalImage(newAddImage).subscribe(x => this.onImageAddedSuccess(x), error => this.onPostDataLoadFailed(error));
      }, error => this.onPostDataLoadFailed(error));
    }
  }

  handleFiles(newFileName: string, numberDone: number) {
    
    //Update Files
    if (numberDone == 0) {
      if (this.newStockImageData.has('file')) {
        this.sysproService.postFileData(this.newStockImageData, "STOCKCODE", newFileName).subscribe(x => {
          this.stockCodedetail.stockCodeImage = x.fileName;
          this.stockCodedetail.stockCodeImageChanged = new Date();
          this.handleFiles(newFileName, 1);
          this.alertService.showMessage('Success', `Added Stockcode Image`, MessageSeverity.success);
        }, error => this.onPostDataLoadFailed(error));
      }
      else {
        if (!this.stockCodedetail.stockCodeImage || this.stockCodedetail.stockCodeImage == "") {
          this.alertService.showMessage('Warning', `No Image Selected`, MessageSeverity.warn);
          //this.isSaving = false;
          this.handleFiles(newFileName, 1)
        }
        else {
          this.stockCodedetail.stockCodeImage = (this.stockCodedetail.stockCodeImage ? decodeURIComponent(this.stockCodedetail.stockCodeImage) : "");
          this.handleFiles(newFileName, 1)
        }
      }
    }
    if (numberDone == 1) {
      if (this.newSpecialsImageData.has('file')) {
        this.sysproService.postFileData(this.newSpecialsImageData, "SPECIALS", newFileName).subscribe(x => {
          this.stockCodedetail.specialsImage = x.fileName;
          this.stockCodedetail.specialsImageChanged = new Date();
          this.handleFiles(newFileName, 2);
          this.alertService.showMessage('Success', `Added specials Image`, MessageSeverity.success);
        }, error => this.onPostDataLoadFailed(error));
      }
      else {
        this.stockCodedetail.specialsImage = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage != "" ? this.stockCodedetail.specialsImage : "");
        this.handleFiles(newFileName, 2)
      }
    }
    if (numberDone == 2) {
      if (this.newDatasheetImageData.has('file')) {
        this.sysproService.postFileData(this.newDatasheetImageData, "DATASHEET", newFileName).subscribe(x => {
          this.stockCodedetail.dataSheetImage = x.fileName;
          this.stockCodedetail.dataSheetImageChanged = new Date();
          this.handleFiles(newFileName, 3);
          this.alertService.showMessage('Success', `Added datasheet 1`, MessageSeverity.success);
        }, error => this.onPostDataLoadFailed(error));
      }
      else {

        this.stockCodedetail.dataSheetImage = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage != "" ? this.stockCodedetail.dataSheetImage : "");
        this.handleFiles(newFileName, 3)
      }
    }
    if (numberDone == 3) {
      if (this.newAddDatasheetImageData.has('file')) {
        this.sysproService.postFileData(this.newAddDatasheetImageData, "DATASHEET", newFileName + "-01").subscribe(x => {
          this.stockCodedetail.addSheetImage = x.fileName;
          this.stockCodedetail.addSheetImageChanged = new Date();
          this.alertService.showMessage('Success', `Added datasheet 2`, MessageSeverity.success);
          this.handleFiles(newFileName, 4);
          
        }, error => this.onPostDataLoadFailed(error));
      }
      else {

        this.stockCodedetail.addSheetImage = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage != "" ? this.stockCodedetail.addSheetImage : "");
        this.handleFiles(newFileName, 4)
      }
    }
    if (numberDone == 4) {
      if (this.isEnabled) {
        this.stockCodedetail.status = "E";
      }
      else {
        this.stockCodedetail.status = "D";
      }
      if (this.newParentName && this.newParentName != "" && this.stockCodedetail.categoryName != this.newParentName  ) {
        this.stockCodedetail.categoryName = this.newParentName;
        this.changeParent = false;
      }
      //Save StockCode
      if (!this.stockCodedetail.id || this.stockCodedetail.id == 0) {
        this.sysproService.addCMStockCode(this.stockCodedetail).subscribe(x => this.onSaveSuccesful(x, true), error => this.onPostDataLoadFailed(error));
      }
      else {
        //Edit Stock Code
        if (this.stockCodedetail.id > 0) {

          if (this.chkDeleteDS1) {
            this.stockCodedetail.dataSheetImage = "";
          }
          if (this.chkDeleteDS2) {
            this.stockCodedetail.addSheetImage = "";
          }
          if (!this.stockCodedetail.onSpecialFowkes && !this.stockCodedetail.onSpecial) {
            this.stockCodedetail.specialsImage = "";
          }

          this.sysproService.updateCMStockCode(this.stockCodedetail, this.stockCodedetail.id).subscribe(x => this.onSaveSuccesful(x, false), error => this.onPostDataLoadFailed(error));
        }
      }
    }
  }

  onSaveSuccesful(result: CMStockCode, newStockCode?: boolean): void {

    this.isSaving = false;
    this.alertService.stopLoadingMessage();


    this.stockCode = result.stockCode;
    if (sessionStorage.getItem(localStorageActions.StockCode) != result.stockCode) {
      sessionStorage.setItem(localStorageActions.StockCode, result.stockCode);
    }

    //Update Syspro with image path
    if (this.stockCodedetail.stockCodeImage != "") {

      this.sysproService.updateStockCodeImageSyspro(result.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"), result.stockCodeImage).subscribe(x =>
        (this.alertService.showMessage('Success', `Syspro Image Updated`, MessageSeverity.success)), error => this.onPostDataLoadFailed(error));

    }

    //Call website webhook to initiate stock code update
    this.http.get('https://www.fowkes.co.za/api/products/sync/' + result.id).subscribe(x =>
      (this.alertService.showMessage('Success', `Website Request for Update Sent`, MessageSeverity.success)), error => this.onPostDataLoadFailed(error));


    this.alertService.showMessage('Success', `Stock Code Updated`, MessageSeverity.success);
    this.clearData();
  }
    

  newFilePostedSuccess(x: any) {
   
    this.stockCodedetail.stockCodeImage = x.fileName;
    this.stockCodedetail.stockCodeImageChanged = new Date();
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newStockImageData.append('file', file);
      
    }
  }

  onNewspecialsImageFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newSpecialsImageData.append('file', file);

    }
  }

  onNewdataSheetImageFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newDatasheetImageData.append('file', file);

    }
  }

  onNewaddSheetImageFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newAddDatasheetImageData.append('file', file);

    }
  }


  exportList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "QtyCT", "BinLocCT", "QtyCombined"]
    };

    var filename = "New StockCodes";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
         StockCode: row.stockCode, Description: row.description, QtyCT: row.qtyCT, BinLocCT: row.binLocCT, QtyCombined: row.qtyCombined
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
