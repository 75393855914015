
export class ITSVehicles {
  id: number;
  vehicleName: string;
  vehicleNumber: string | null;
  vehicleDescription: string | null;
  createDate: Date;
  createdBy: string;
  modifiedDate: Date | null;
  modifiedBy: string | null;
}

