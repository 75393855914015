import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, Input } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';

import { Moment } from 'moment';
import * as moment from 'moment';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { Branch } from '../../../models/branch.model';

//import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
//import { ReportsInvoiceReprintComponent } from './report-components/reports-invoice-reprint-component';
//import { ReportsInvoiceStatusComponent } from './report-components/reports-invoice-status-component';
//import { ReportsSalesComponent } from './report-components/reports-sales-component';
//import { ReportsInvoiceTrackingPurgeComponent } from './report-components/reports-invoice-tracking-purge-component';
//import { MySQLDocument } from '../../../models/mysqldocument.model';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { ValueHelper } from '../../../models/valuehelper.model';
import { validation } from '../../../services/validation.service';
import { InvoiceHeader } from '../../../models/invoice-header.model';
import { SalesOrderHeader } from '../../../models/salesorder-header.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { InvoiceDocuments } from '../../../models/invoice-documents.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { Permission } from '../../../models/permission.model';



@Component({
  selector: 'pod-view',
  templateUrl: './pod-view.component.html',
  styleUrls: ['./pod-view.component.scss']
})
export class PodViewComponent implements OnInit, AfterViewInit {

  invoice: string;

  loadingIndicator: boolean;

  isEditMode: boolean;

  isSaving: boolean;

  invoiceHeader: InvoiceHeader = new InvoiceHeader();
  unallocated: boolean;

  referenceList: BarcodeReference[];

  rows: BarcodeReference[] = [];
  rowsCache: BarcodeReference[];
  rowsExistingCache: BarcodeReference[];
  rowsExisting: BarcodeReference[];

    oldReference: string;
  showInvoiceSearch: boolean;

  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
  checkCreditNotes: boolean;
  salesOrderNumber: string;


  @ViewChild('searchResultSelector')
  private searchResultSelector;
    showSalesOrder: boolean;
    moveToInvoice: string;
    rowsToSave: number;
    order: string;
    originalInvoice: string;
    originalOrder: string;
    salesOrderHeader: SalesOrderHeader;

  constructor(private router: Router, private alertService: AlertService, private sysproService: SysproService,
    private route: ActivatedRoute, private accountService: AccountService, private fowkesOnline: FowkesOnlineService, private configurations: ConfigurationService) {

  }

  ngOnInit() {

    const invoiceNumber = sessionStorage.getItem(localStorageActions.PODReference);
    const podSalesOrder = sessionStorage.getItem(localStorageActions.SalesOrderNumber);

    this.originalInvoice = invoiceNumber;
    this.originalOrder = podSalesOrder;

    if (!podSalesOrder || podSalesOrder == "") {
      this.originalOrder = this.originalInvoice;
    }

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getPODByInvoice(this.originalInvoice).subscribe(x => this.onBarcodesLoadSuccesful(x), error => this.onDataLoadFailed(error));

  }


  ngAfterViewInit() {}

  onBarcodesLoadSuccesful(x: BarcodeReference[]): void {
    console.log(x);
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {

      this.referenceList = x;

      this.referenceList.forEach((barcode, index, list) => {
        (<any>barcode).index = index + 1;
        if (barcode.company.trim() == "") {
          (<any>barcode).status = "Unallocated";
        }
      });

      this.rowsCache = [...this.referenceList];
      this.rows = this.referenceList;
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

    //Try and get the invoice this document belongs to
    this.sysproService.getInvoiceHeader(this.originalOrder, this.originalInvoice).subscribe(x => this.onInvoiceLoadSuccessful(x), error => this.onInvoiceNotFound(error));

  }

  onInvoiceLoadSuccessful(order: InvoiceHeader) {
    if (order) {
      let res = order;
      this.invoiceHeader = res[0];
    }
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  onInvoiceNotFound(error: any): void {
      //Try and find the sales order this document belongs to
      this.sysproService.getSalesOrderHeader(this.originalInvoice).subscribe(x => this.onSalesOrderLoadSuccessful(x), error => this.onSalesOrderNotFound(error));
  }
 
  onSalesOrderLoadSuccessful(x: SalesOrderHeader): void {
    this.salesOrderHeader = x;
  }

  onSalesOrderNotFound(error: any): void {

     this.alertService.showStickyMessage('Warning', 'These documents does not match the invoice or sales order search and might be a transfer, debit or credit note',
        MessageSeverity.warn);
  }

  viewPOD(row: BarcodeReference) {

    this.sysproService.getFileStream(row.fileName, row.fileNumber).subscribe(x => this.onFileSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));

  }


  onFileSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }


  

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: InvoiceDocuments[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length === 1) {

      this.sysproService.getInvoiceHeader(x[0].salesOrderNumber, x[0].invoiceNumber).subscribe(x => this.onInvoiceLoadSuccessful(x), error => this.onSalesOrderNotFound(error));
      this.sysproService.getPODByInvoice(x[0].invoiceNumber).subscribe(x => this.onBarcodesLoadSuccesful(x), error => this.onDataLoadFailed(error));
    }

  }

  viewInvoice() {
   
    this.setLocalCustomer(this.invoiceHeader.customer, this.invoiceHeader.salesOrderNumber, this.invoiceHeader.invoiceNumber);
    if (this.invoiceHeader.invoiceNumber && this.invoiceHeader.invoiceNumber.length >= 1) {
      this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.invoiceHeader.customer } });
    }
    else {

      this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: this.invoiceHeader.customer } });
    }
  }

  setLocalCustomer(customer: string, salesOrderNumber: string, invoiceNumber: string) {

    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != salesOrderNumber) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != invoiceNumber) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, invoiceNumber);
    }
    
  }


  viewInvoiceSelected() {
    if (this.searchResultSelected) {
      this.salesOrderNumber = this.searchResultSelected.salesOrderNumber;
      this.showSalesOrder = true;
      this.moveToInvoice = this.salesOrderNumber + ": " + this.searchResultSelected.orderHistory;

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getPODByInvoice(this.salesOrderNumber).subscribe(x => this.onBarcodesForNewInvoiceLoadSuccesful(x), error => this.onDataLoadFailed(error));

    }
  }
    onBarcodesForNewInvoiceLoadSuccesful(x: any) {
      console.log(x);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      if (x && x[0] && x.length > 0) {

        this.referenceList = x;

        this.referenceList.forEach((barcode, index, list) => {
          (<any>barcode).index = index + 1;
          if (barcode.company.trim() == "") {
            (<any>barcode).status = "Unallocated";
          }
        });

        this.rowsExistingCache = [...this.referenceList];
        this.rowsExisting = this.referenceList;
      }
    
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  reAllocateAll() {

    this.alertService.startLoadingMessage('Saving changes...');
    this.rowsToSave = this.rows.length;
    for (var row of this.rows) {
      row.company = (this.application == "MaxArcusOnline" ? "M" : "F");
      row.branch = row.invoice.substr(0, 2);
      row.status = "Allocated";
      this.oldReference = row.barcode;
      this.rowsToSave--;
      this.sysproService.savePOD(row, row.invoice).subscribe(_response => this.saveSuccessHelper(_response, row.fileNumber, row.barcode, row.invoice), error => this.saveFailedHelper(error));
    }

  }

  allocate(row: BarcodeReference) {
    if (row.invoice && row.invoice.length >= 5) {
      row.company = (this.application == "MaxArcusOnline" ? "M" : "F");
      row.branch = row.invoice.substr(0, 2);
      row.status = "Allocated";
      this.rowsToSave = 0;
      this.invoice = row.invoice;
      this.sysproService.savePOD(row, row.invoice).subscribe(_response => this.saveSuccessHelper(_response, row.fileNumber, row.barcode, row.invoice), error => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper(x: BarcodeReference, oldRow: number, oldInvoice: string, newInvoice: string) {
    if (oldInvoice != newInvoice) {

      this.alertService.showMessage('Success', ` POD mapped from ` + oldInvoice + ` to ` + newInvoice, MessageSeverity.success);
      this.sysproService.deletePOD(oldInvoice, oldRow).subscribe(x => this.onBarcodesForNewInvoiceLoadSuccesful(x), error => this.onDataLoadFailed(error));
    }
    if (this.rowsToSave == 0) {

      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', ` PODs Updated Succesfully `, MessageSeverity.success);
      this.sysproService.getInvoiceDocumentsSearchByCriteria("invoice", this.salesOrderNumber).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }   

  }

  get canManagePODs() {
    return this.accountService.userHasPermission(Permission.managePODsPermission);
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  deleteRow(row: BarcodeReference) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.invoice + ' - ' + row.fileName + '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: BarcodeReference) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;


    this.sysproService.deletePOD(row.invoice, row.fileNumber).subscribe(results => {
      this.alertService.showMessage('Success', row.invoice + ` ` + row.fileNumber + ` Successfully deleted`, MessageSeverity.success);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }


}
