
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, Pipe, PipeTransform } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { Inventory } from 'src/app/models/inventory.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';


@Component({
  selector: 'products.adverts',
  templateUrl: './products.adverts.component.html',
  styleUrls: ['./products.adverts.component.scss']
})


export class ProductsAdvertsComponent implements OnInit {
 
  loadingIndicator: boolean;

  rows: Inventory[] = [];
  rowsCache: Inventory[] = [];
  inventoryList: Inventory[] = [];


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  stockCodedetail: Inventory;
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;
    hasImpaCodes: boolean;
    hasAddDataSheet: boolean;
  buyerResponsible: string;
    branchCode: string;
    currentIndex: number;
    showAdvert: boolean;
    elem: HTMLElement;
    timer: any;


  constructor(private alertService: AlertService, private fowkesService: FowkesOnlineService,
    private sysproService: SysproService) {
  }


  ngOnInit() {

    this.loadData();
    this.currentIndex = 0;
    this.showAdvert = false;


    //setInterval(() => {
    //  this.currentIndex = (this.currentIndex + 1) % this.rows.length;
    //}, 5000); // Change image every 5 seconds

  }




  loadData() {
    this.branchCode = "CT";
    this.sysproService.getInventoryForAdverts(this.branchCode).subscribe(x => this.onStockListLoadSuccessful([x], true), error => this.onDataLoadFailed(error));

  }
  onStockListLoadSuccessful(inventory: any[], trim?: boolean): void {
    const stockCodes = inventory[0];

    if (!Array.isArray(stockCodes)) {
      // Handle the case when stockCodes is not an array
      // Display an error message, log the issue, or handle it as per your requirements
      return;
    }

    const newData = stockCodes.map((stockCode, index) => {
      return {
        ...stockCode,
        index: index + 1,
        description: stockCode.shortDescription + ' - ' + stockCode.longDescription
      };
    });

    this.inventoryList = newData;

    this.rowsCache = this.inventoryList.filter(
      s => s.onSpecialFowkes || s.newArrival || s.topSellerText.trim() !== ''
    );
    this.rows = [...this.rowsCache];

    // Remove the previous setInterval, if any
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.rows.length;
      this.onStockCodeLoadSuccessful(this.rows[this.currentIndex]);
    }, 10000); // Change image every 10 seconds
  }


  onStockCodeLoadSuccessful(row: any): void {

    if (row) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail = row;
      this.hasDataSheet = false;
      this.hasSpecials = false;
      this.hasImpaCodes = false;

      if (this.stockCodedetail) {
        if (this.stockCodedetail.dataSheetImage) {
          this.hasDataSheet = true;
          this.stockCodedetail.dataSheetImage = encodeURIComponent(this.stockCodedetail.dataSheetImage);
        }
        if (this.stockCodedetail.addSheetImage) {
          this.hasAddDataSheet = true;
          this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
        }
        if (this.stockCodedetail.specialsImage) {
          this.hasSpecials = true;
          this.stockCodedetail.specialsImage = encodeURIComponent(this.stockCodedetail.specialsImage);
        }
        if (this.stockCodedetail.impaCodes && this.stockCodedetail.impaCodes.trim() != "") {
          this.hasImpaCodes = true;
        }
      }
      this.stockCodedetail.stockCodeImage = this.ensureUrlEncoded(this.stockCodedetail.stockCodeImage);

    }
   
  }

  ensureUrlEncoded(url: string): string {
    if (url && !url.includes('%')) {
      return encodeURIComponent(url);
    }
    return url;
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());

    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    console.log(error.status);
    if (error.status == 404) { return; }
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }



  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      //this.alternateKey = row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }

  openSpecials(specialsImg: string) {
    if (!!specialsImg && specialsImg != "") {
      window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
    }
  }

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

}
