import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { Video } from '../../models/video.model';
import { AuthService } from '../../services/auth.service';
import { NewVideosComponent } from './products.newvideos.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService } from '../../services/account.service';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'videos',
  templateUrl: './products.videos.component.html',
  styleUrls: ['./products.videos.component.scss'],
  animations: [fadeInOut]
})
export class VideosComponent {

  emptyDetails: boolean;

  @ViewChild('rows')
  rows: Video[] = [];
  rowsCache: Video[] = [];

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";

  @ViewChild('videoEditor')
  videoEditor: NewVideosComponent;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('videoModal')
  videoModal: ModalDirective;

  videoDetail: Video = new Video();
  safeSrc: SafeResourceUrl;

  constructor(private alertService: AlertService, private sysproService: SysproService, private authService: AuthService, private accountService: AccountService, private sanitizer: DomSanitizer) {
   
  }

  ngOnInit() {

  
    this.loadData();

  }

  ngAfterViewInit() { }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getVideos().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  //videoURL(row: Video) {
  //  return this.santizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + row.url + "?autoplay=0");
  //}

  onDataLoadSuccessful(videos: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (videos[0]) {

      this.rowsCache = [...videos[0]];

      this.rows = this.rowsCache.map(row => {
        // Generate a safe URL for each row's URL property
        const safeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + row.url);

        // Create a new row object with the updated safe URL
        return { ...row, safeUrl, safeURL: safeUrl };
      });

      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }   
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.title, r.description));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }


  enableEditMethod(row: Video, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: Video) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    this.sysproService.editVideo(row, row.id).subscribe(x => this.onUpdateSuccesful(), error => this.saveFailedHelper(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  private cancel() {
    this.isEditMode = false;
  }

  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    this.rows.forEach(p => {

  //    this.sysproService.editVideo(p, p.title).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));

    });

  }

  private saveSuccessHelper() {

    //this.savedLineCount++;
    //if (this.saveCount === this.savedLineCount) {
    //  this.cartSaved = true;

    //  this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));

      this.isSaving = false;
      this.isEditMode = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', ` Updated successfully`, MessageSeverity.success);

  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }


  deleteRow(row: Video) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.title + ' - ' + row.description + '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: Video) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.sysproService.deleteVideo(row.id).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.title + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }


  videoModalHide() {
    this.videoDetail = new Video();
    this.videoModal.hide();
  }

  newVideo() {

    if (!this.videoModal) {
      setTimeout(() => {
        if (this.videoModal) {
          this.videoModal.show();
        }
      });
    }
    else {
      this.videoModal.show();
    }

  }

  saveVideo() {
    //Validate save
    if (this.videoDetail) {
      if (this.videoDetail.title) {
        if (this.rowsCache.filter(x => x.title == this.videoDetail.title).length >= 1) {
          this.alertService.showMessage('Warning', `This title already exists`, MessageSeverity.warn);
        }
        else {
          if (this.videoDetail.sequence && this.videoDetail.url) {
            this.isSaving = true;
            this.alertService.startLoadingMessage('Saving changes...');

            this.videoDetail.createdBy = this.accountService.currentUser.email;
            this.videoDetail.detail = (this.videoDetail.detail ? this.videoDetail.detail : "");
            this.sysproService.newVideo(this.videoDetail).subscribe(x => this.onSaveSuccesful(), error => this.saveFailedHelper(error));
          }
          else {
            this.alertService.showMessage('Warning', `Remeber to add sequence and url`, MessageSeverity.warn);
          }
        }
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a title`, MessageSeverity.warn);
      }
    }
  }

  onSaveSuccesful(): void {

    this.videoModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }
}
