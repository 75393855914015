
import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { AccountService } from '../../services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [fadeInOut]
})
export class HomeComponent {


  isSysprorepsActivated = true;
  isUserWhatsappsActivated = false;

  fragmentSubscription: any;

  readonly sysprorepsTab = 'sysproreps';
  readonly userwhatsappsTab = 'userwhatsapps';

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  @ViewChild('sidenav')
  sidenav: MatSidenav;

  @Input()
  isSpecificUser: boolean;
    isCollapsed: boolean;


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService, private configurations: ConfigurationService
  ) {
  }


  ngOnInit() {

    this.isCollapsed = !this.menuExpanded;
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));

  }

  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string, test?: any) {
    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.sysprorepsTab}Tab`);

  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isSysprorepsActivated = activeTab == this.sysprorepsTab;
    this.isUserWhatsappsActivated = activeTab == this.userwhatsappsTab;

    this.router.navigate([], { fragment: activeTab });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isSysprorepsActivated = activeTab == this.sysprorepsTab;
    this.isUserWhatsappsActivated = activeTab == this.userwhatsappsTab;

    this.router.navigate([], { fragment: activeTab });
  }


}
