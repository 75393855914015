
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { Email } from '../models/email.model';
import { CoreAudit } from '../models/core-audit.model';
import { BarcodeReference } from '../models/barcode-reference.model';
import { Video } from '../models/video.model';
import { Brand } from '../models/brand.model';
import { CMStockCode } from '../models/cms-stockcode.model';
import { DocumentCategory } from '../models/document-category.model';
import { DocumentManagmement } from '../models/document-management.model';
import { CMCategories } from '../models/cms-category.model';
import { InvoiceTrackingLookup } from '../models/invoice-tracking-lookup.model';
import { Branch } from '../models/branch.model';
import { InfoBoardNotes } from '../models/info-board-notes.model';
import { CoreCustomer } from '../models/core-customer.model';
import { CoreCustomerRule } from '../models/core-customer-rule.model';
import { CoreCustomerPrices } from '../models/core-customer-prices.model';
import { WebOrder } from '../models/web-order.model';
import { CMSAdditionalImage } from '../models/cms-additional-images.model';
import { OptIns, Outbox } from '../models/wapp-inbox.model';


@Injectable()
export class SysproEndpoint extends EndpointFactory {  

  public  regex = new RegExp('/', 'g');
  private readonly _branchesUrl: string = '/api/Branch/GetAllBranches';
  private readonly _customersUrl: string = '/api/Customers/GetCustomerList';
  private readonly _customerbaseUrl: string = '/api/Customers/';
  private readonly _customerDetailUrl: string = '/api/Customers/GetCustomerDetail';
  private readonly _customerNotesUrl: string = '/api/Customers/CustomerNotes';
  private readonly _customerMovementsUrl: string = '/api/Customers/GetCustomerMovements';
  private readonly _customerPaymentsUrl: string = '/api/Customers/GetCustomerPayments';
  private readonly _salPersonUrl: string = '/api/Customers/GetAllSalesPersons';
  private readonly _inventoryUrl: string = '/api/Inventory/GetStockCodeList';
  private readonly _inventoryBaseUrl: string = '/api/Inventory/';
  private readonly _inventoryWHUrl: string = '/api/Inventory/StockCodeAvailableQuantities';
  private readonly _cartDetailUrl: string = '/api/CartDetails/GetCartDetails';
  private readonly _quoteFromCartUrl: string = '/api/Customers/QuoteFromCart';
  private readonly _quoteUrl: string = '/api/Quotes/';
  private readonly _cartUrl: string = '/api/Cart/';
  private readonly _supplierListUrl: string = '/api/CartDetails/GetAllSuppliers';
  private readonly _emailUrl: string = '/api/customers/email';
  private readonly _smsUrl: string = '/api/customers/sms';
  private readonly _shippingInstructionsUrl: string = '/api/customers/GetShippingInstructionsList';
  private readonly _inventoryDetailurl: string = '/api/Inventory';
  private readonly _establishmentUrl: string = '/api/customers/GetEstablishmentDetails';
  private readonly _salesOrderUrl: string = '/api/SalesOrders/';
  private readonly _salesMenuReportsUrl: string = '/api/MenuReports/';
  private readonly _settingsUrl: string = '/api/Settings';
  private readonly _mysqlDocumentUrl: string = '/api/DocumentServer/';
  private readonly _documentManagementUrl: string = '/api/DocumentManagement/';
  private readonly _inventoryManagementUrl: string = '/api/Inventory/';
  private readonly _coreCustomersUrl: string = '/api/customers';
  private readonly _fowkesonlineUrl: string = '/api/fowkesonline';
  private readonly _whatsappUrl: string = '/api/whatsapp/';

  get branchesUrl() { return this.configurations.baseUrl + this._branchesUrl; }
  get customersUrl() { return this.configurations.baseUrl + this._customersUrl; }
  get customerbaseUrl() { return this.configurations.baseUrl + this._customerbaseUrl; }
  get customerDetailUrl() { return this.configurations.baseUrl + this._customerDetailUrl; }
  get salPersonUrl() { return this.configurations.baseUrl + this._salPersonUrl; }
  get customerNotesUrl() { return this.configurations.baseUrl + this._customerNotesUrl; }
  get customerMovementsUrl(){
    return this.configurations.baseUrl + this._customerMovementsUrl;
  }
  get customerPaymentsUrl(){
    return this.configurations.baseUrl + this._customerPaymentsUrl;
  }
  get inventoryUrl() { return this.configurations.baseUrl + this._inventoryUrl; }
  get inventoryBaseUrl() { return this.configurations.baseUrl + this._inventoryBaseUrl; }
  get inventoryDetailUrl() { return this.configurations.baseUrl + this._inventoryDetailurl; }
  get inventoryWHUrl() { return this.configurations.baseUrl + this._inventoryWHUrl; }
  get cartDetailsUrl() { return this.configurations.baseUrl + this._cartDetailUrl; }
  get quoteFromCartUrl() {
    return this.configurations.baseUrl + this._quoteFromCartUrl;
  }

  get quoteUrl() { return this.configurations.baseUrl + this._quoteUrl; }
  get salesOrderUrl() { return this.configurations.baseUrl + this._salesOrderUrl; }
  get cartUrl() { return this.configurations.baseUrl + this._cartUrl; }
  get supplierListUrl() { return this.configurations.baseUrl + this._supplierListUrl; }
  get whatsappUrl() { return this.configurations.baseUrl + this._whatsappUrl; }
  get shippingInstructionsUrl() {
    return this.configurations.baseUrl + this._shippingInstructionsUrl;
  }
  get establishmentUrl() {
    return this.configurations.baseUrl + this._establishmentUrl;
  }
  get emailUrl() { return this.configurations.baseUrl + this._emailUrl; }
  get smsUrl() { return this.configurations.baseUrl + this._smsUrl; }

  get settingsUrl() { return this.configurations.baseUrl + this._settingsUrl; }

  get coreCustomersUrl() {
    return this.configurations.baseUrl + this._coreCustomersUrl;
  }
  get coreFowkesOnlineUrl() {
    return this.configurations.baseUrl + this._fowkesonlineUrl;
  }
  
  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getBranchesEndpoint<T>(): Observable<T> {
    const endpointUrl = this.branchesUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBranchesEndpoint());
      }));
  }

  getCustomersEndpoint<T>(branch?: string): Observable<T> {
    let endpointUrl = this.customersUrl;
    if (branch != "") {
      endpointUrl = `${this.customersUrl}/${branch}`;
    }

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersEndpoint(branch));
      }));
  }


  getCustomerSearchByCriteriaEndpoint<T>(searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this.customerbaseUrl}CustomerSearchByCriteria/${searchOn}/${searchText}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerSearchByCriteriaEndpoint(searchOn, searchText));
      }));
  }


  getCustomerSearchByCriteriaByCompanyEndpoint<T>(searchOn?: string, searchText?: string, company?: string): Observable<T> {
    const endpointUrl = `${this.customerbaseUrl}CustomerSearchByCriteriaByCompany/${searchOn}/${searchText}/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerSearchByCriteriaByCompanyEndpoint(searchOn, searchText, company));
      }));
  }

  getCustomerDetailEndpoint<T>(customerId?: string): Observable<T> {
    const endpointUrl = `${this.customerDetailUrl}/${customerId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerDetailEndpoint(customerId));
      }));
  }


  getCustomerOrderConversionEndpoint<T>(customer?: string): Observable<T> {
    const endpointUrl = `${this.customerbaseUrl}GetCustomerOrderConversion/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerOrderConversionEndpoint(customer));
      }));
  }


  getCustomerQuotePDFEndpoint<T>(quoteNumber?: string): Observable<T> {
    const endpointUrl = `${this.customerDetailUrl}/${quoteNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerQuotePDFEndpoint(quoteNumber));
      }));
  }


  getCustomerMovementsEndpoint<T>(startDate?: string, endDate?: string, customer?: string): Observable<T> {
    const endpointUrl = `${this.customerMovementsUrl}/${startDate}/${endDate}/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerMovementsEndpoint(startDate, endDate, customer));
      }));
  }


  getCustomerPaymentsEndpoint<T>(startDate?: string, endDate?: string, customer?: string): Observable<T> {
    const endpointUrl = `${this.customerPaymentsUrl}/${startDate}/${endDate}/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerPaymentsEndpoint(startDate, endDate, customer));
      }));
  }

  getCustomersLoadedForStatementsEndpoint<T>(application?: string, startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.customerbaseUrl}GetCustomersLoadedForStatements/${application}/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersLoadedForStatementsEndpoint(application, startDate, endDate));
      }));
  }
  


  getCustStatementsReadyForEmailEndpoint<T>(application?: string, startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.customerbaseUrl}GetCustStatementsReadyForEmail/${application}/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustStatementsReadyForEmailEndpoint(application, startDate, endDate));
      }));
  }


  getCustomerNotesEndpoint<T>(customerId?: string): Observable<T> {
    const endpointUrl = `${this.customerNotesUrl}/GetCustomerNotes/${customerId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerNotesEndpoint(customerId));
      }));
  }


  updateCustomerNotesHeaderEndpoint<T>(customerId?: string): Observable<T> {
    const endpointUrl = `${this.customerNotesUrl}/UpdateCustomerNotesHeader/${customerId}`;

    return this.http.get<T>(encodeURI(endpointUrl), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCustomerNotesHeaderEndpoint(customerId));
      }));
  }


  insertCustomerNotesEndpoint<T>(customerId?: string, line?: number, notation?: string): Observable<T> {
    const endpointUrl = `${this.customerNotesUrl}/InsertCustomerNote/${customerId}/${line}/${notation}`;

    return this.http.get<T>(encodeURI(endpointUrl), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.insertCustomerNotesEndpoint(customerId, line, notation));
      }));
  }


  deleteCustomerNotesEndpoint<T>(customerId?: string, line?: number): Observable<T> {
    const endpointUrl = `${this.customerNotesUrl}/DeleteCustomerNotes/${customerId}/${line}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCustomerNotesEndpoint(customerId, line));
      }));
  }


  getSalesPersonsEndpoint<T>(branch?: string): Observable<T> {
    const endpointUrl = `${this.salPersonUrl}/${branch}`;
    
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesPersonsEndpoint());
      }));
  }


  getInventoryForBranchEndpoint<T>(branch?: string): Observable<T> {
    const endpointUrl = `${this.inventoryUrl}/${branch}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInventoryForBranchEndpoint(branch));
      }));
  }


  getInventoryForAdvertsEndpoint<T>(branch?: string): Observable<T> {
    const endpointUrl = `${this._fowkesonlineUrl}/GetStockCodeList/${branch}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInventoryForAdvertsEndpoint(branch));
      }));
  }


  getStockCodeSearchByCriteriaEndpoint<T>(branch?: string, searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this.inventoryBaseUrl}GetStockCodeSearchByCriteria/${branch}/${searchOn}/${encodeURIComponent(searchText)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodeSearchByCriteriaEndpoint(branch, searchOn, searchText));
      }));
  }


  getStockCodeDetailEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/StockCodeDetail/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodeDetailEndpoint(stockCode));
      }));
  }


  getStockCodeAdditionalDetailEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/GetStockCodeAdditionalDetail/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodeAdditionalDetailEndpoint(stockCode));
      }));
  }


  getAltStockCodeDetailEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/GetAltStockCodes/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAltStockCodeDetailEndpoint(stockCode));
      }));
  }

  getStockCearanceFlagEndpoint<T>(id?: number): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/StockCearanceFlag/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCearanceFlagEndpoint(id));
      }));
  }

  getStockCodeMovementHistoryEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/GetStockCodeMovementDetail/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodeMovementHistoryEndpoint(stockCode));
      }));
  }


  getCategoriesForStockCodesListEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/CategoriesForStockCodesList`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCategoriesForStockCodesListEndpoint());
      }));
  }

  getStockCodeAvailableQuantitiesEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.inventoryWHUrl}/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodeAvailableQuantitiesEndpoint(stockCode));
      }));
  }

  getCartDetailEndpoint<T>(cartNumber?: string, branch?: string): Observable<T> {
    const endpointUrl = `${this.cartDetailsUrl}/${cartNumber}/${branch}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCartDetailEndpoint(cartNumber, branch));
      }));
  }


  updateCartCommentsEndpoint<T>(cartNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}CartCommentsInsert/${cartNumber}/${lineNumber}/${commentFromLine}/${encodeURIComponent(comment)}/${commentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCartCommentsEndpoint(cartNumber, lineNumber, commentFromLine, comment, commentType));
      }));
  }


  updateCartCommentsForPackSizeEndpoint<T>(cartNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string, includeLines?: boolean): Observable<T> {
    const endpointUrl = `${this.quoteUrl}CartCommentsInsertForPackSize/${cartNumber}/${lineNumber}/${commentFromLine}/${encodeURIComponent(comment)}/${commentType}/${includeLines}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCartCommentsForPackSizeEndpoint(cartNumber, lineNumber, commentFromLine, comment, commentType, includeLines));
      }));
  }

  postCartToQuoteEndpoint<T>(cartNumber?: string): Observable<T> {
    const endpointUrl = `${this._quoteFromCartUrl}/${cartNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postCartToQuoteEndpoint(cartNumber));
      }));
  }


  getQuoteSearchAllEndpoint<T>(applicationId?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}SearchAll/${applicationId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteSearchAllEndpoint(applicationId));
      }));
  }


  getAllWebOrdersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetAllWebOrders`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllWebOrdersEndpoint());
      }));
  }

  getWebOrderPaymentInfoEndpoint<T>(orderId?: number): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetWebOrderPayment/${orderId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getWebOrderPaymentInfoEndpoint(orderId));
      }));
  }



  updateWebOrderStatusEndpoint<T>(invoice?: string, status?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}UpdateWebOrderStatus/${invoice}/${status}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateWebOrderStatusEndpoint(invoice, status));
      }));
  }


  UpdateWebOrderStatusWithCommentEndpoint<T>(Id?: number, orderDetail?: WebOrder): Observable<T> {
    const endpointUrl = `${this.quoteUrl}UpdateWebOrderStatusWithComment/${Id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(orderDetail), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.UpdateWebOrderStatusWithCommentEndpoint(Id, orderDetail));
      }));
  }


  getWebOrderStatusEndpoint<T>(waybill?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetWebOrderStatus/${waybill}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getWebOrderStatusEndpoint(waybill));
      }));
  }


  getWebOrderLineInfoEndpoint<T>(orderId?: number): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetWebOrderLines/${orderId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getWebOrderLineInfoEndpoint(orderId));
      }));
  }


  getQuoteNumberSearchByCriteriaEndpoint<T>(searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetQuoteNumberSearchByCriteria/${searchOn}/${searchText}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteNumberSearchByCriteriaEndpoint(searchOn, searchText));
      }));
  }



  getQuoteNumberSearchByCriteriaByCompanyEndpoint<T>(searchOn?: string, searchText?: string, company?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetQuoteNumberSearchByCriteriaByCompany/${searchOn}/${searchText}/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteNumberSearchByCriteriaByCompanyEndpoint(searchOn, searchText, company));
      }));
  }


  getQuoteHeaderEndpoint<T>(quoteNumber?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetQuoteHeader/${quoteNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteHeaderEndpoint(quoteNumber));
      }));
  }


  getQuoteDetailEndpoint<T>(quoteNumber?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}GetQuoteDetails/${quoteNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteDetailEndpoint(quoteNumber));
      }));
  }


  getUpdateQuoteStatusEndpoint<T>(quoteNumber?: string, quoteStatus?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}UpdateQuoteStatus/${quoteNumber}/${quoteStatus}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateQuoteStatusEndpoint(quoteNumber, quoteStatus));
      }));
  }


  postQuoteTotalsEndpoint<T>(quoteNumber?: string, totalIncl?: number, totalExcl?: number, totalDiscount?: number): Observable<T> {
    const endpointUrl = `${this.quoteUrl}UpdateQuoteTotals/${quoteNumber}/${totalIncl}/${totalExcl}/${totalDiscount}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postQuoteTotalsEndpoint(quoteNumber, totalIncl, totalExcl, totalDiscount));
      }));
  }
  
  updateCartStatusEndpoint<T>(cartNumber?: string, hasNettPrices?: boolean): Observable<T> {
    const endpointUrl = `${this.quoteUrl}UpdateCartStatus/${cartNumber}/${hasNettPrices}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCartStatusEndpoint(cartNumber, hasNettPrices));
      }));
  }

  updateQuoteCommentsEndpoint<T>(quoteNumber?: string, lineNumber?: number, commentFromLine?: number, comment?: string, commentType?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}QuoteCommentsInsert/${quoteNumber}/${lineNumber}/${commentFromLine}/${encodeURIComponent(comment)}/${commentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateQuoteCommentsEndpoint(quoteNumber, lineNumber, commentFromLine, comment, commentType));
      }));
  }

  getCartFromQuoteEndpoint<T>(quoteNumber?: string, cartNumber?: string, branch?: string): Observable<T> {
    const endpointUrl = `${this.quoteUrl}CartFromQuote/${quoteNumber}/${cartNumber}/${branch}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCartFromQuoteEndpoint(quoteNumber, cartNumber, branch));
      }));
  }

  getAllSuppliersEndpoint<T>(): Observable<T> {
    const endpointUrl = this.supplierListUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllSuppliersEndpoint());
      }));
  }


  

  postSalesOrderSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostSalesOrderSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postSalesOrderSysproEndpoint(postObject));
      }));
  }

  updateSalesOrderSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}UpdateSalesOrderSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateSalesOrderSysproEndpoint(postObject));
      }));
  }


  getSysproCommentsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetSysproComments`;
    
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproCommentsEndpoint());
      }));
  }

  insertSalesOrderCommentsEndpoint<T>(SalesOrder?: string, CartLineNumber?: number,
    Comment?: string, NCommentFromLin?: number, NCommentType?: string, includeLines?: boolean): Observable<T> {

    const endpointUrl = `${this.salesOrderUrl}InsertSalesOrderComments/${SalesOrder}/${CartLineNumber}/${encodeURIComponent(Comment)}/${NCommentFromLin}/${NCommentType}/${includeLines}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.insertSalesOrderCommentsEndpoint(SalesOrder, CartLineNumber, Comment, NCommentFromLin, NCommentType, includeLines));
      }));
  }

  insertSalesOrderPaymentCommentsEndpoint<T>(SalesOrder?: string, CartLineNumber?: number,
    Comment?: string, NCommentFromLin?: number, NCommentType?: string, includeLines?: boolean): Observable<T> {

    const endpointUrl = `${this.salesOrderUrl}InsertSalesOrderPaymentComments/${SalesOrder}/${CartLineNumber}/${encodeURIComponent(Comment)}/${NCommentFromLin}/${NCommentType}/${includeLines}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.insertSalesOrderPaymentCommentsEndpoint(SalesOrder, CartLineNumber, Comment, NCommentFromLin, NCommentType, includeLines));
      }));
  }


  postUpdateSalesOrderStatusEndpoint<T>(salesOrder: string, status: string, cancelReason: string, sessionId: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}UpdateSalesOrderStatus/${salesOrder}/${status}/${cancelReason}/${sessionId}`;
    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postUpdateSalesOrderStatusEndpoint(salesOrder, status, cancelReason, sessionId));
      }));
  }

  postSalesOrderUpdateToSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostSalesOrderUpdateToSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postSalesOrderUpdateToSysproEndpoint(postObject));
      }));
  }


  postInvoiceToSysproEndpoint<T>(salesorder?: string, sessionId?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostInvoiceToSyspro/${salesorder}/${sessionId}`;
    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postInvoiceToSysproEndpoint(salesorder, sessionId));
      }));
  }


  printInvoiceEndpoint<T>(salesorder: string, isReprint: boolean, userObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PrintInvoice/${salesorder}/${isReprint}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.printInvoiceEndpoint(salesorder, isReprint, userObject));
      }));
  }

  postPurchaseOrderToSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostPurchaseOrderToSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postPurchaseOrderToSysproEndpoint(postObject));
      }));
  }


  insertPurchaseOrderStagingEndpoint<T>(postObject: any, salesOrder: string, supplier: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}InsertPurchaseOrderStaging/${salesOrder}/${supplier}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.insertPurchaseOrderStagingEndpoint(postObject, salesOrder, supplier));
      }));
  }


  updatePurchaseOrderStatusEndpoint<T>(PONumber: string, Status: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}UpdatePurchaseOrderStatus/${PONumber}/${Status}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updatePurchaseOrderStatusEndpoint(PONumber, Status));
      }));
  }


  updatePurchaseOrderTotalsEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}UpdatePurchaseOrderTotals`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updatePurchaseOrderTotalsEndpoint(postObject));
      }));
  }


  postGRNToSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostGRNToSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postGRNToSysproEndpoint(postObject));
      }));
  }


  getSysproSessionIdEndpoint<T>(userObject: any, override?: boolean, compOverride?: string): Observable<T> {
    let endpointUrl = `${this.salesOrderUrl}GetSysproSessionId`;
    if (override && override == true) {
      endpointUrl = `${this.salesOrderUrl}GetFowkesSysproSessionId/${override}/${compOverride}`;
    }
    if (compOverride) {
      endpointUrl = `${this.salesOrderUrl}GetFowkesSysproSessionId/${override}/${compOverride}`;
    }

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproSessionIdEndpoint(userObject, override, compOverride));
      }));
  }

  getSalesOrderSearchAllEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}SearchAll`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderSearchAllEndpoint());
      }));
  }


  getSalesOrderSearchByCriteriaEndpoint<T>(searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}SearchByCriteria/${searchOn}/${searchText}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderSearchByCriteriaEndpoint(searchOn, searchText));
      }));
  }


  getSalesOrderSearchByCriteriaByCompanyEndpoint<T>(searchOn?: string, searchText?: string, company?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}SalesOrderSearchByCriteriaByCompany/${searchOn}/${searchText}/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderSearchByCriteriaByCompanyEndpoint(searchOn, searchText, company));
      }));
  }


  updateInvoiceTotalsEndpoint<T>(application?: string, salesOrderNumber?: string, invoiceNumber?: string
    , vat?: number, totalIncl?: number, totalExcl?: number, totalDisc?: number ): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}UpdateInvoiceTotals/${application}/${salesOrderNumber}/${invoiceNumber}/${vat}/${totalIncl}/${totalExcl}/${totalDisc}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateInvoiceTotalsEndpoint(application, salesOrderNumber, invoiceNumber, vat, totalIncl, totalExcl, totalDisc));
      }));
  }

  getCreditNoteSearchByCriteriaEndpoint<T>(searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}CreditNoteSearchByCriteria/${searchOn}/${searchText}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreditNoteSearchByCriteriaEndpoint(searchOn, searchText));
      }));
  }

  getSalesOrderHeaderEndpoint<T>(salesOrderNumber?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetSalesOrderHeader/${salesOrderNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderHeaderEndpoint(salesOrderNumber));
      }));
  }


  getSalesOrderDetailEndpoint<T>(salesOrderNumber?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetSalesOrderDetails/${salesOrderNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderDetailEndpoint(salesOrderNumber));
      }));
  }


  getEmailEndpoint<T>(emailDetail?: Email): Observable<T> {
    const endpointUrl = this.emailUrl;

    return this.http.post<T>(endpointUrl, JSON.stringify(emailDetail), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getEmailEndpoint(emailDetail));
      }));
  }


  getSMSEndpoint<T>(test?: string): Observable<T> {
   
    const endpointUrl = `${this.coreCustomersUrl}/SendSMS/${test}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSMSEndpoint(test));
      }));
  }



  getShippingInstructionsEndpoint<T>(): Observable<T> {
    const endpointUrl = this.shippingInstructionsUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getShippingInstructionsEndpoint());
      }));
  }


  getSalesOrderCancelReasonCodesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetSalesOrderCancelReasonCodes/`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderCancelReasonCodesEndpoint());
      }));
  }


  getSalesOrderStatusesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetSalesOrderStatuses/`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesOrderStatusesEndpoint());
      }));
  }


  getEstablishmentDetailsEndpoint<T>(company?: string, branchCode?: string): Observable<T> {

    const endpointUrl = `${this.customerbaseUrl}GetEstablishmentDetails/${company}/${branchCode}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getEstablishmentDetailsEndpoint(company, branchCode));
      }));
  }


  getInvoiceHeaderEndpoint<T>(salesOrderNumber?: string, invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetInvoiceHeader/${salesOrderNumber}/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceHeaderEndpoint(salesOrderNumber, invoiceNumber));
      }));
  }


  getInvoiceDetailEndpoint<T>(salesOrderNumber?: string, invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetInvoiceDetails/${salesOrderNumber}/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceDetailEndpoint(salesOrderNumber, invoiceNumber));
      }));
  }


  getCustomerPOListEndpoint<T>(customer?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetCustomerPOList/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerPOListEndpoint(customer));
      }));
  }



  getPurchaseOrderSearchByCriteriaEndpoint<T>(searchCriteria?: string, searchValue?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PurchaseOrderSearch/${searchCriteria}/${searchValue}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseOrderSearchByCriteriaEndpoint(searchCriteria, searchValue));
      }));
  }


  getPurchaseOrderSearchByCriteriaByCompanyEndpoint<T>(searchCriteria?: string, searchValue?: string, company?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PurchaseOrderSearchByCompany/${searchCriteria}/${searchValue}/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseOrderSearchByCriteriaByCompanyEndpoint(searchCriteria, searchValue, company));
      }));
  }


  getPurchaseOrderHeaderEndpoint<T>(purchaseOrder?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetPurchaseOrderHeader/${purchaseOrder}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseOrderHeaderEndpoint(purchaseOrder));
      }));
  }


  getPurchaseOrderDetailEndpoint<T>(poNumber?: string): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}GetPurchaseOrderDetails/${poNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseOrderDetailEndpoint(poNumber));
      }));
  }


  PostCoreAuditEndpoint<T>(coreAudit: CoreAudit): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostCoreAudit`;

    return this.http.post<T>(endpointUrl, JSON.stringify(coreAudit), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.PostCoreAuditEndpoint(coreAudit));
      }));
  }

  getInvoiceReprintEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceReprintList/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceReprintEndpoint(startDate, endDate));
      }));
  }


  getInvoiceReprintDetailsEndpoint<T>(invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceReprintDetails/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceReprintDetailsEndpoint(invoiceNumber));
      }));
  }

  getQuoteSalesDataEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetQuoteSalesData/${startDate}/${endDate}/${company}/${branch}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteSalesDataEndpoint(startDate, endDate, company, branch, user));
      }));
  }


  getQuoteSalesSummaryEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetQuoteSalesSummary/${startDate}/${endDate}/${company}/${branch}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getQuoteSalesSummaryEndpoint(startDate, endDate, company, branch, user));
      }));
  }

  getCreditNoteSalesEndpoint<T>(startDate?: string, endDate?: string, branch?: string, applicationId?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetCreditNoteSales/${startDate}/${endDate}/${branch}/${applicationId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreditNoteSalesEndpoint(startDate, endDate, branch, applicationId));
      }));
  }

  getAllInvoiceTrackingStatusEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetAllInvoiceTrackingStatus`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllInvoiceTrackingStatusEndpoint());
      }));
  }

  getInvoiceTrackingStatusControlEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceTrackingStatusControl/${startDate}/${endDate}/${company}/${branch}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingStatusControlEndpoint(startDate, endDate, company, branch, user));
      }));
  }


  getInvoiceTrackingStatusDeliveryControlEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceTrackingStatusDeliveryControl/${startDate}/${endDate}/${company}/${branch}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingStatusDeliveryControlEndpoint(startDate, endDate, company, branch, user));
      }));
  }


  getInvoiceTrackingStatusCollectionsControlEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceTrackingStatusCollectionsControl/${startDate}/${endDate}/${company}/${branch}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingStatusCollectionsControlEndpoint(startDate, endDate, company, branch, user));
      }));
  }

  getInvoiceTrackingStatusEndpoint<T>(salesOrder?: string, invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceTrackingStatus/${salesOrder}/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingStatusEndpoint(salesOrder, invoiceNumber));
      }));
  }


  postInvoiceTrackingPurgeEndpoint<T>(salesOrder?: string, invoiceNumber?: string, PurgeUser?: string, PurgeReason?: string, LastOperator?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}PostInvoiceTrackingPurge/${salesOrder}/${invoiceNumber}/${PurgeUser}/${encodeURIComponent(PurgeReason)}/${LastOperator}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postInvoiceTrackingPurgeEndpoint(salesOrder, invoiceNumber, PurgeUser, PurgeReason, LastOperator));
      }));
  }



  postInvoiceTrackingCollectedUpdateEndpoint<T>(salesOrder?: string, invoiceNumber?: string, user?: string, status?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}PostInvoiceTrackingCollectedUpdate/${salesOrder}/${invoiceNumber}/${user}/${encodeURIComponent(status)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postInvoiceTrackingCollectedUpdateEndpoint(salesOrder, invoiceNumber, user, status));
      }));
  }

  deleteInvoiceTrackingDuplicateEndpoint<T>(salesOrder?: string, invoiceNumber?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}DeleteInvoiceTrackingDuplicate/${salesOrder}/${invoiceNumber}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteInvoiceTrackingDuplicateEndpoint(salesOrder, invoiceNumber));
      }));
  }



  getConfigurationSettingsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/SearchAllConfigurationSettings`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getConfigurationSettingsEndpoint());
      }));
  }


  updateConfigurationSettingsEndpoint<T>(Configuration?: string, Value?: string, UserName?: string, EffectiveDate?: string): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/UpdateConfigurationSettings/${Configuration}/${Value}/${UserName}/${EffectiveDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateConfigurationSettingsEndpoint(Configuration, Value, UserName, EffectiveDate));
      }));
  }


  deleteConfigurationSettingsEndpoint<T>(ID?: number, Configuration?: string): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/DeleteConfigurationSettings/${ID}/${Configuration}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteConfigurationSettingsEndpoint(ID, Configuration));
      }));
  }


  updateBranchSettingsEndpoint<T>(Branch?: string, PriceOverridePassword?: string, InvoiceReprintPassword?: string,
    SalesOrderPassword?: string, PriceCode?: string, LedgerCode?: string): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/UpdateBranchSettings/${Branch}/${PriceOverridePassword}/${InvoiceReprintPassword}/${SalesOrderPassword}/${PriceCode}/${LedgerCode}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateBranchSettingsEndpoint(Branch, PriceOverridePassword,
          InvoiceReprintPassword, SalesOrderPassword, PriceCode, LedgerCode));
      }));
  }


  getSysproTaxCodesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/SearchAllSysproTaxCodes`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproTaxCodesEndpoint());
      }));
  }


  getInvoiceTrackingProductivityEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetInvoiceTrackingProductivityReport/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingProductivityEndpoint(startDate, endDate));
      }));
  }


  getDebtorsReleaseDataEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetDebtorsReleaseData/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDebtorsReleaseDataEndpoint(startDate, endDate));
      }));
  }

  getDebtorsReleaseSummaryEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetDebtorsReleaseSummary/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDebtorsReleaseSummaryEndpoint(startDate, endDate));
      }));
  }


  getCreditNoteReportDataEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetCreditNoteReportData/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreditNoteReportDataEndpoint(startDate, endDate));
      }));
  }

  getCreditNoteReportSummaryEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetCreditNoteReportSummary/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreditNoteReportSummaryEndpoint(startDate, endDate));
      }));
  }

  getSalesDataEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSalesDataReport/${startDate}/${endDate}/${company}/${branch}/${user}/${documentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesDataEndpoint(startDate, endDate, company, branch, user, documentType));
      }));
  }

  getSalesSummaryEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSalesSummaryReport/${startDate}/${endDate}/${company}/${branch}/${user}/${documentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesSummaryEndpoint(startDate, endDate, company, branch, user, documentType));
      }));
  }


  getSalesRepDataEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string, rep?: string, customer?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSalesRepData/${startDate}/${endDate}/${company}/${branch}/${user}/${documentType}/${rep}/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesRepDataEndpoint(startDate, endDate, company, branch, user, documentType, rep, customer));
      }));
  }

  getSalesRepSummaryEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, user?: string, documentType?: string, rep?: string, customer?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSalesRepSummaryReport/${startDate}/${endDate}/${company}/${branch}/${user}/${documentType}/${rep}/${customer}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesRepSummaryEndpoint(startDate, endDate, company, branch, user, documentType, rep, customer));
      }));
  }


  getInvoiceBarcodeSyncReportEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, customer?: string, documentType?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}GetInvoiceBarcodeSyncReport/${startDate}/${endDate}/${company}/${branch}/${customer}/${documentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceBarcodeSyncReportEndpoint(startDate, endDate, company, branch, customer, documentType));
      }));
  }


  getBarcodeForInvoiceSyncEndpoint<T>(invoice?: string, branch?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}SyncDocumentsForInvoice/${invoice}/${branch}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBarcodeForInvoiceSyncEndpoint(invoice, branch));
      }));
  }


  getInvoiceDocumentsSearchByCriteriaEndpoint<T>(searchOn?: string, searchText?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}GetInvoiceDocumentsSearchByCriteria/${searchOn}/${searchText}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceDocumentsSearchByCriteriaEndpoint(searchOn, searchText));
      }));
  }


  updateBarcodeExcludedForInvoiceSyncEndpoint<T>(invoice?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}UpdateBarcodeReferenceExclude/${invoice}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateBarcodeExcludedForInvoiceSyncEndpoint(invoice));
      }));
  }

  syncFileReferencesByDateEndpoint<T>(month?: string, day?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}SyncFilesByDate/${month}/${day}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.syncFileReferencesByDateEndpoint(month, day));
      }));
  }


  getBarcodesAvailableReportEndpoint<T>(startDate?: string, endDate?: string, company?: string, branch?: string, status?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}GetBarcodesAvailableReport/${startDate}/${endDate}/${company}/${branch}/${status}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBarcodesAvailableReportEndpoint(startDate, endDate, company, branch, status));
      }));
  }


  getSysproUserDetailListEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSysproUserDetailList`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproUserDetailListEndpoint());
      }));
  }


  getSysproUserDetailListByCompanyEndpoint<T>(company?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSysproUserDetailListByCompany/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproUserDetailListByCompanyEndpoint(company));
      }));
  }

  getMySQLDocumentsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}GetAllDocuments`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMySQLDocumentsEndpoint());
      }));
  }

  getDocumentsPerMonth<T>(year?:string, month?: string): Observable<T> {
    const endpointUrl = `${this._mysqlDocumentUrl}GetAllDocumentsPerMonth/${year}/${month}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentsPerMonth(year, month));
      }));
  }

  getDocumentByPrimaryKey<T>(barcode: string, prefix: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetDocumentByPrimaryKey/${barcode}/${prefix}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentByPrimaryKey(barcode, prefix));
      }));
  }

  getDocumentsByRange<T>(from: string, to: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetDocumentsByRange/${from}/${to}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentsByRange(from, to));
      }));
  }

  getDocumentsByDateRange<T>(start: string, end: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetDocumentsByDateRange/${start}/${end}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentsByDateRange(start, end));
      }));
  }

  getBarcodeReferencesEndpoint<T>(start: string, end: string, company: string, branch: string, batchFrom: string, batchTo: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetBarcodeReferences/${start}/${end}/${company}/${branch}/${batchFrom}/${batchTo}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBarcodeReferencesEndpoint(start, end, company, branch, batchFrom, batchTo));
      }));
  }

  getBarcodesUnallocatedEndpoint<T>(start: string, end: string, company: string, branch: string, batchFrom: string, batchTo: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetBarcodesUnallocated/${start}/${end}/${company}/${branch}/${batchFrom}/${batchTo}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBarcodesUnallocatedEndpoint(start, end, company, branch, batchFrom, batchTo));
      }));
  }


  getFileStream<T>(fileName: string, bnumber: number) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetFileStream/${fileName}/${bnumber}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFileStream(fileName, bnumber));
      }));
  }

  getPODByInvoice<T>(invoice: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}GetPODByInvoice/${invoice}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPODByInvoice(invoice));
      }));
  }

  deletePOD<T>(invoice: string, fileNumber: number) {
    const endpointUrl = `${this._mysqlDocumentUrl}DeletePOD/${invoice}/${fileNumber}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deletePOD(invoice, fileNumber));
      }));
  }

  savePOD<T>(pod: BarcodeReference, invoice: string) {
    const endpointUrl = `${this._mysqlDocumentUrl}SavePOD/${invoice}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(pod),  this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.savePOD(pod, invoice));
      }));
  }



  updateSalesStatsEndpoint<T>(reference?: string, description?: string, value?: number): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}UpdateSalesStats/${reference}/${description}/${value}`;
    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateSalesStatsEndpoint(reference, description, value));
      }));
  }




  getSalesStatsEndpoint<T>(startDate?: string, endDate?: string, company?: string, user?: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSalesStats/${startDate}/${endDate}/${company}/${user}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSalesStatsEndpoint(startDate, endDate, company, user));
      }));
  }

  getDocumentCatergories<T>(expandIndex: Number) {
    const endpointUrl = `${this._documentManagementUrl}GetAllCategories/${expandIndex}`;

    return this.http.get<string>(endpointUrl, { responseType: 'string' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentCatergories(expandIndex));
      }));
  }

  getVideos<T>() {
    const endpointUrl = `${this._inventoryManagementUrl}GetAllVideos`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getVideos());
      }));
  }

  editVideoEndpoint<T>(video: Video, id: number) {
    const endpointUrl = `${this._inventoryManagementUrl}EditVideo/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(video), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.editVideoEndpoint(video, id));
      }));
  }

  newVideoEndpoint<T>(video: Video) {
    const endpointUrl = `${this._inventoryManagementUrl}NewVideo`;

    return this.http.put<T>(endpointUrl, JSON.stringify(video), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.newVideoEndpoint(video));
      }));
  }

  deleteVideoEndpoint<T>(id: number) {
    const endpointUrl = `${this._inventoryManagementUrl}DeleteVideo/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteVideoEndpoint(id));
      }));
  }

  postFileDataEndPoint<T>(formData: FormData, cmsType?: string, fileName?: string) {
    const endpointUrl = `${this._inventoryManagementUrl}UploadFiles/${cmsType}/${encodeURIComponent(fileName)}`;

    return this.http.post<T>(endpointUrl,formData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postFileDataEndPoint(formData, cmsType, fileName));
      }));
  }


  postAdditionalFileDataEndPoint<T>(formData: FormData, cmsType?: string, fileName?: string) {
    const endpointUrl = `${this._inventoryManagementUrl}UploadAdditionalFiles/${cmsType}/${encodeURIComponent(fileName)}`;

    return this.http.post<T>(endpointUrl, formData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postAdditionalFileDataEndPoint(formData, cmsType, fileName));
      }));
  }


  getBrands<T>() {
    const endpointUrl = `${this._inventoryDetailurl}/GetAllBrands`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBrands());
      }));
  }


  addDocumentCategoryEndpoint<T>(category: DocumentCategory): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}AddNewDocumentCategory`;

    return this.http.post<T>(endpointUrl, JSON.stringify(category), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addDocumentCategoryEndpoint(category));
      }));
  }

  updateDocumentCategoryEndpoint<T>(category: DocumentCategory, id?: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}EditDocumentCategory/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(category), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateDocumentCategoryEndpoint(category, id));
      }));
  }

  deleteDocumentCategoryEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}DeleteDocumentCategory/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteDocumentCategoryEndpoint(id));
      }));
  }

  getDocumentsForCategoryEndPoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}GetDocumentsForCategory/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentsForCategoryEndPoint(id));
      }));
  }

  postDocumentEndPoint<T>(formData: FormData, cmsType?: string, fileName?: string) {
    const endpointUrl = `${this._documentManagementUrl}UploadFiles/${cmsType}/${encodeURIComponent(fileName)}`;

    return this.http.post<T>(endpointUrl, formData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postDocumentEndPoint(formData, cmsType, fileName));
      }));
  }




  GetAllCMStockCodesEndpoint<T>() {
    const endpointUrl = `${this._inventoryManagementUrl}GetAllCMStockCodes`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMStockCodesEndpoint());
      }));
  }


  addCMStockCodeEndpoint<T>(stockCode: CMStockCode): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}AddNewCMStockCode`;

    return this.http.post<T>(endpointUrl, JSON.stringify(stockCode), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addCMStockCodeEndpoint(stockCode));
      }));
  }

  updateCMStockCodeEndpoint<T>(stockCode: CMStockCode, id?: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}EditCMStockCode/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(stockCode), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCMStockCodeEndpoint(stockCode, id));
      }));
  }



  addNewCMSAdditionalImageEndpoint<T>(item: CMSAdditionalImage): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}AddNewCMSAdditionalImage`;

    return this.http.post<T>(endpointUrl, JSON.stringify(item), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addNewCMSAdditionalImageEndpoint(item));
      }));
  }

  deleteCMSAdditionalImageEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}DeleteCMSAdditionalImage/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCMSAdditionalImageEndpoint(id));
      }));
  }


  getAdditionalImagesForStockCodeEndPoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}GetAdditionalImagesForStockCode/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAdditionalImagesForStockCodeEndPoint(id));
      }));
  }



  UpdateStockCodeImageSysproEndpoint<T>(stockCode: string, image?: string): Observable<T> {
    const endpointUrl = `${this._inventoryBaseUrl}UpdateStockCodeImageSyspro/${encodeURIComponent(stockCode)}/${image}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.UpdateStockCodeImageSysproEndpoint(stockCode, image));
      }));
  }

  deleteCMStockCodeEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}DeleteStockCode/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCMStockCodeEndpoint(id));
      }));
  }

  getDocumentsForStockCodeEndPoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}GetDocumentsForStockCode/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDocumentsForStockCodeEndPoint(id));
      }));
  }


  GetAllCMCategoriesEndpoint<T>(name?: string) {
    let endpointUrl = "";
    if (name) {
      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategoriesForParent/${name}`;
    }
    else {

      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategories`;
    }

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMCategoriesEndpoint(name));
      }));
  }


  addCMCategoryEndpoint<T>(category: CMCategories): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}AddNewCMCategory`;

    return this.http.post<T>(endpointUrl, JSON.stringify(category), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addCMCategoryEndpoint(category));
      }));
  }

  updateCMCategoryEndpoint<T>(category: CMCategories, id?: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}EditCMCategory/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(category), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCMCategoryEndpoint(category, id));
      }));
  }

  deleteCMCategoryEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}DeleteCMCategory/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCMCategoryEndpoint(id));
      }));
  }


  getStockCodesForCategoryEndPoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}GetStockCodesForCategory/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodesForCategoryEndPoint(id));
      }));
  }


  getSimpleDocumentTreeEndpoint<T>(categoryName?: string) {
    const endpointUrl = `${this._documentManagementUrl}CMSDocumentTree/${categoryName}`;

    return this.http.get<string>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSimpleDocumentTreeEndpoint(categoryName));
      }));
  }


  GetAllDocumentCategoriesListEndpoint<T>() {
    let endpointUrl = `${this._documentManagementUrl}GetAllDocumentCategoriesList`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllDocumentCategoriesListEndpoint());
      }));
  }


  getAllRepoDocsEndpoint<T>() {
    const endpointUrl = `${this._documentManagementUrl}GetAllRepoDocs`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllRepoDocsEndpoint());
      }));
  }


  addNewDocEndpoint<T>(doc: DocumentManagmement): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}AddNewRepoDoc`;

    return this.http.post<T>(endpointUrl, JSON.stringify(doc), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addNewDocEndpoint(doc));
      }));
  }


  updateRepoDocEndpoint<T>(doc: DocumentManagmement, id?: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}EditRepoDoc/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(doc), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateRepoDocEndpoint(doc, id));
      }));
  }

  deleteRepoDocEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}DeleteRepoDoc/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteRepoDocEndpoint(id));
      }));
  }

  addBrandEndpoint<T>(brand: Brand): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}AddNewBrand`;

    return this.http.post<T>(endpointUrl, JSON.stringify(brand), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addBrandEndpoint(brand));
      }));
  }

  updateBrandEndpoint<T>(brand: Brand, id?: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}EditBrand/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(brand), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateBrandEndpoint(brand, id));
      }));
  }

  deleteBrandEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}DeleteBrand/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteBrandEndpoint(id));
      }));
  }

  getStockCodesForBrandEndPoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}GetStockCodesForBrand/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodesForBrandEndPoint(id));
      }));
  }


  getAllCMCatergoriesTreeEndpoint<T>(expandIndex: Number) {
    const endpointUrl = `${this._inventoryManagementUrl}GetAllStockCodeCategories/${expandIndex}`;

    return this.http.get<string>(endpointUrl, { responseType: 'string' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllCMCatergoriesTreeEndpoint(expandIndex));
      }));
  }


  GetAllStockCodesAndCategoriesMissingImagesEndpoint<T>(expandIndex: Number) {
    const endpointUrl = `${this._inventoryManagementUrl}GetAllStockCodesAndCategoriesMissingImages/${expandIndex}`;

    return this.http.get<string>(endpointUrl, { responseType: 'string' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllStockCodesAndCategoriesMissingImagesEndpoint(expandIndex));
      }));
  }


  GetAllCMSCategoryMissingImagesEndpoint<T>(name?: string) {
    let endpointUrl = "";
    if (name) {
      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategoriesForParent/${name}`;
    }
    else {

      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategoryMissingImages`;
    }

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMSCategoryMissingImagesEndpoint(name));
      }));
  }


  GetAllCMStockCodesMissingImagesEndpoint<T>(name?: string) {
    let endpointUrl = "";
    if (name) {
      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategoriesForParent/${name}`;
    }
    else {

      endpointUrl = `${this._inventoryManagementUrl}GetAllCMStockCodesMissingImages`;
    }

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMStockCodesMissingImagesEndpoint(name));
      }));
  }



  GetAllCMSOrphanFileImagesEndpoint<T>() {
    let endpointUrl = `${this._inventoryManagementUrl}GetAllCMSOrphanFileImages`;
    

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMSOrphanFileImagesEndpoint());
      }));
  }


  GetAllCMStockCodesMissingDataSheetsEndpoint<T>(name?: string) {
    let endpointUrl = "";
    if (name) {
      endpointUrl = `${this._inventoryManagementUrl}GetAllCMSCategoriesForParent/${name}`;
    }
    else {

      endpointUrl = `${this._inventoryManagementUrl}GetAllCMStockCodesMissingDataSheets`;
    }

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetAllCMStockCodesMissingDataSheetsEndpoint(name));
      }));
  }

  getInvoiceTrackingStatusControlDirectEndPoint<T>(startDate?: string, endDate?: string, company?: string): Observable<T> {
    const endpointUrl = `${this._inventoryManagementUrl}GetInvoiceTrackingStatusControlDirect/${startDate}/${endDate}/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvoiceTrackingStatusControlDirectEndPoint(startDate, endDate, company));
      }));
  }


  getMissingITSEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetMissingITSRecords`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMissingITSEndpoint());
      }));
  }


  addInvoiceTrackingLookupEndpoint<T>(ITS: InvoiceTrackingLookup): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}InsertITSLookup`;

    return this.http.post<T>(endpointUrl, JSON.stringify(ITS), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addInvoiceTrackingLookupEndpoint(ITS));
      }));
  }


  uploadBankFilesEndPoint<T>(formData: FormData, company?: string, fileName?: string, bank?: string, period?: string) {
    const endpointUrl = `${this._documentManagementUrl}UploadBankFiles/${company}/${encodeURIComponent(fileName)}/${bank}/${period}`;

    return this.http.post<T>(endpointUrl, formData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadBankFilesEndPoint(formData, company, fileName, bank, period));
      }));
  }


  postPaymentsToSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostPaymentToSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postPaymentsToSysproEndpoint(postObject));
      }));
  }


  postSinglePaymentToSysproEndpoint<T>(postObject: any): Observable<T> {
    const endpointUrl = `${this.salesOrderUrl}PostSinglePaymentToSyspro`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postSinglePaymentToSysproEndpoint(postObject));
      }));
  }


  getBankImportByCompanyEndPoint<T>(company?: string): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}GetBankImportByCompany/${company}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBankImportByCompanyEndPoint(company));
      }));
  }


  getBankImportOverviewEndPoint<T>(): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}GetBankImportOverview`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBankImportOverviewEndPoint());
      }));
  }


  getBankImportByFileNameEndPoint<T>(fileName?: string): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}GetBankImportByFileName/${fileName}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBankImportByFileNameEndPoint(fileName));
      }));
  }


  updateBankImportLineEndpoint<T>(postObject: any, id: number): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}EditBankImportLine/${id}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateBankImportLineEndpoint(postObject, id));
      }));
  }


  getBankImportLatestEndpoint<T>(company?: string, bankReference?: string, trnYear?: string, trnMonth?: string, customer?: string, startDate?: string, endDate?: string
    , searchType?: string, fileName?: string, userName?: string): Observable<T> {
    const endpointUrl = `${this._documentManagementUrl}GetBankImportLatest/${company}/${bankReference}/${trnYear}/${trnMonth}/${customer}/${startDate}/${endDate}/${searchType}/${fileName}/${userName}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBankImportLatestEndpoint(company, bankReference, trnYear, trnMonth, customer, startDate, endDate, searchType, fileName, userName));
      }));
  }


  getSysproBankListEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSysproBankDetailList`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproBankListEndpoint());
      }));
  }


  getSysproPaymentRunHeaderEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSysproPaymentRunHeaderList`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproPaymentRunHeaderEndpoint());
      }));
  }


  getSysproPaymentRunDetailListEndpoint<T>(paymentNumber: string): Observable<T> {
    const endpointUrl = `${this._salesMenuReportsUrl}GetSysproPaymentRunDetailList/${paymentNumber}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSysproPaymentRunDetailListEndpoint(paymentNumber));
      }));
  }

  getCategoryCatalogueListEndpoint<T>(branch?: string, searchOn?: string, searchText?: string, filter?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/GetCategoryCatalogueList/${branch}/${searchOn}/${searchText}/${filter}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCategoryCatalogueListEndpoint(branch, searchOn, searchText, filter));
      }));
  }

  ///Report for Ryan
  getCatalogueUseReportDataEndpoint<T>(startDate?: string, endDate?: string): Observable<T> {
    const endpointUrl = `${this.inventoryDetailUrl}/GetCatalogueUseReportData/${startDate}/${endDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCatalogueUseReportDataEndpoint(startDate, endDate));
      }));
  }


  getAllInfoNotesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/GetAllInfoNotes`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllInfoNotesEndpoint());
      }));
  }


  addInfoNoteEndpoint<T>(info: InfoBoardNotes): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/AddNewInfoNote`;

    return this.http.post<T>(endpointUrl, JSON.stringify(info), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addInfoNoteEndpoint(info));
      }));
  }

  updateInfoNoteEndpoint<T>(info: InfoBoardNotes, id?: number): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/EditInfoNote/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(info), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateInfoNoteEndpoint(info, id));
      }));
  }

  deleteInfoNoteEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.settingsUrl}/DeleteInfoNote/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteInfoNoteEndpoint(id));
      }));
  }

  postInfoFileEndPoint<T>(formData: FormData, cmsType?: string, fileName?: string) {
    const endpointUrl = `${this.settingsUrl}/UploadInfoFiles/${cmsType}/${encodeURIComponent(fileName)}`;

    return this.http.post<T>(endpointUrl, formData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postInfoFileEndPoint(formData, cmsType, fileName));
      }));
  }


  getInfoFileEndpoint<T>(fileName: string) {
    const endpointUrl = `${this.settingsUrl}/GetInfoFile/${fileName}`;

    return this.http.get<Blob>(endpointUrl, { responseType: 'blob' as 'json' }).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInfoFileEndpoint(fileName));
      }));
  }


  //Core Customers
  getAllPricingCustomersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetAllPricingCustomers`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllPricingCustomersEndpoint());
      }));
  }

  getPricingCustomerEndpoint<T>(customer: string, application: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetCorePricingCustomer/${customer}/${application}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPricingCustomerEndpoint(customer, application));
      }));
  }


  updatePricingCustomerEndpoint<T>(customer: CoreCustomer, id?: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/EditPricingCustomer/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(customer), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updatePricingCustomerEndpoint(customer, id));
      }));
  }

  addNewPricingCustomerEndpoint<T>(customer: CoreCustomer): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/AddNewPricingCustomer`;

    return this.http.post<T>(endpointUrl, JSON.stringify(customer), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addNewPricingCustomerEndpoint(customer));
      }));
  }

  getAllPricingCustomerRulesEndpoint<T>(CustomerId?: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetAllPricingCustomerRules/${CustomerId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllPricingCustomerRulesEndpoint(CustomerId));
      }));
  }


  updatePricingCustomerRuleEndpoint<T>(rule: CoreCustomerRule, id?: number, company?: string, branch?: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/EditPricingCustomerRule/${id}/${company}/${branch}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(rule), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updatePricingCustomerRuleEndpoint(rule, id, company, branch));
      }));
  }

  addNewPricingCustomerRuleEndpoint<T>(rule: CoreCustomerRule,  company: string, branch: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/AddNewPricingCustomerRule/${company}/${branch}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(rule), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addNewPricingCustomerRuleEndpoint(rule, company, branch));
      }));
  }

  deletePricingCustomerRuleEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/DeletePricingCustomerRule/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deletePricingCustomerRuleEndpoint(id));
      }));
  }

  //PRICES

  getAllPricingCustomerPricesEndpoint<T>(CustomerId?: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetAllPricingCustomerPrices/${CustomerId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllPricingCustomerPricesEndpoint(CustomerId));
      }));
  }


  getAllPricingCustomerPricesForCustomerCodeEndpoint<T>(CustomerCode?: string, Application?: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetAllPricingCustomerPricesForCustomerCode/${CustomerCode}/${Application}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllPricingCustomerPricesForCustomerCodeEndpoint(CustomerCode, Application));
      }));
  }

  //GetAllPricingCustomerPricesForCustomerCodeInventorySelect

  getAllPricingCustomerPricesForCustomerCodeInventorySelectEndpoint<T>(CustomerCode?: string, Application?: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetAllPricingCustomerPricesForCustomerCodeInventorySelect/${CustomerCode}/${Application}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllPricingCustomerPricesForCustomerCodeInventorySelectEndpoint(CustomerCode, Application));
      }));
  }

  updatePricingCustomerPriceEndpoint<T>(price: CoreCustomerPrices, id?: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/EditPricingCustomerPrice/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(price), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updatePricingCustomerPriceEndpoint(price, id));
      }));
  }

  addNewPricingCustomerPriceEndpoint<T>(price: CoreCustomerPrices): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/AddNewPricingCustomerPrice`;

    return this.http.post<T>(endpointUrl, JSON.stringify(price), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addNewPricingCustomerPriceEndpoint(price));
      }));
  }

  deletePricingCustomerPriceEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/DeletePricingCustomerPrice/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deletePricingCustomerPriceEndpoint(id));
      }));
  }


  deleteAllPricingCustomerPricesForRuleEndpoint<T>(ruleId: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/DeleteAllPricingCustomerPricesForRule/${ruleId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteAllPricingCustomerPricesForRuleEndpoint(ruleId));
      }));
  }


  getStockCodePriceDetailEndpoint<T>(stockCode?: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetStockCodePriceDetail/${encodeURIComponent(stockCode)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodePriceDetailEndpoint(stockCode));
      }));
  }

  getSuppliersForCustomerPricingEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetSuppliersForCustomerPricing`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSuppliersForCustomerPricingEndpoint());
      }));
  } 


  getStockCodePriceDetailForSupplierEndpoint<T>(supplier?: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetStockCodePriceDetailForSupplier/${encodeURIComponent(supplier)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodePriceDetailForSupplierEndpoint(supplier));
      }));
  }


  getStockCodePriceDetailForCategoryEndpoint<T>(categoryId?: number): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/GetStockCodePriceDetailForCategory/${categoryId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStockCodePriceDetailForCategoryEndpoint(categoryId));
      }));
  }


  applyDefaultPricesToCustomerListEndpoint<T>(postObject: any, application: string, user: string, enabled?: boolean): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/ApplyDefaultPricesToCustomerList/${application}/${user}/${enabled}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(postObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.applyDefaultPricesToCustomerListEndpoint(postObject, application, user, enabled ));
      }));
  }


  resetCustomerPricingEndpoint<T>(application: string, customer: string): Observable<T> {
    const endpointUrl = `${this.coreCustomersUrl}/ResetCustomerPricing/${application}/${customer}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.resetCustomerPricingEndpoint(application, customer));
      }));
  }

  getAllInboxMessagesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetAllInboxMessages`;
    //GetAllInboxMessages
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllInboxMessagesEndpoint());
      }));
  }



  getInboxMessagesAsyncEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetInboxMessagesAsync`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInboxMessagesAsyncEndpoint());
      }));
  }


  getAllOutboxMessagesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetAllOutboxMessages`;
    //GetAllInboxMessages
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllOutboxMessagesEndpoint());
      }));
  }

  sendwappReplyEndpoint<T>(sendTo: string, message: string, user: string): Observable<T> {
    const endpointUrl = `SendReply/${sendTo}/${encodeURIComponent(message)}/${user}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendwappReplyEndpoint(sendTo, message, user));
      }));
  }

  ///TestWhatsappWithFile/{sendTo}/{message}/{fileName}

  sendwappWithQuoteEndpoint<T>(sendTo: string, fileName: string, user: string ): Observable<T> {
    const endpointUrl = `SendWhatsappWithQuote/${sendTo}/${fileName}/${user}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendwappWithQuoteEndpoint(sendTo, fileName, user));
      }));
  }

  sendwappWithPDFEndpoint<T>(sendTo: string, fileName: string, user: string, fileType: string, parameter: string): Observable<T> {
    const endpointUrl = `SendWhatsappWithPDF/${sendTo}/${encodeURIComponent(fileName)}/${user}/${fileType}/${parameter}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendwappWithPDFEndpoint(sendTo, fileName, user, fileType, parameter));
      }));
  }
   
  resendWhatsappEndpoint<T>(message?: Outbox, user?: string): Observable<T> {
    const endpointUrl = `ResendWhatsapp/${user}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(message), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.resendWhatsappEndpoint(message, user));
      }));
  }

  getWappOptInDetailsEndpoint<T>(userName?: string): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetWappOptIn/${userName}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getWappOptInDetailsEndpoint(userName));
      }));
  }

  addWappOptInDetailsEndpoint<T>(userDetails?: OptIns): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}SaveUserForOptIn`;

    return this.http.post<T>(endpointUrl, JSON.stringify(userDetails), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addWappOptInDetailsEndpoint(userDetails));
      }));
  }



  getUserTelEndpoint<T>(loggedInUser?: string): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetLoggedInUserTel/${loggedInUser}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserTelEndpoint(loggedInUser));
      }));
  }


  GetMetaUserDetailsEndpoint<T>(loggedInUser?: string): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}GetMetaUserDetailsEndpoint/${encodeURIComponent(loggedInUser)}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.GetMetaUserDetailsEndpoint(loggedInUser));
      }));
  }


  sendTemplateWithFileEndpoint<T>(filename?: string, fileType?: string, recipient_number?: string, user?: string, parameter?: string): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}SendTemplateWithFile/${encodeURIComponent(filename)}/${fileType}/${recipient_number}/${user}/${parameter}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendTemplateWithFileEndpoint(filename, fileType, recipient_number, user, parameter));
      }));
  }


  sendTemplateNoFileEndpoint<T>(recipient_number?: string, user?: string, parameter?: string): Observable<T> {
    const endpointUrl = `${this.whatsappUrl}SendTemplateNoFile/${recipient_number}/${user}/${parameter}`;

    return this.http.post<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendTemplateNoFileEndpoint(recipient_number, user, parameter));
      }));
  }


  //getDPDtrackAndTraceEndpoint<T>(waybillNumber?: string): Observable<T> {

  //  var dpdURL = "https://swatws.dawnwing.co.za/dwwebservices/v2/live/api/Token";
  //  const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  //  const params = new HttpParams()
  //    .append('userName', "FOWKESBROS")
  //    .append('password', "FoWK3SBR0S");

  //  return this.http.post<T>(dpdURL, params, { headers: header });


  //  //const endpointUrl = `${dpdURL}/Token`;
  //  //var postObject = {
  //  //  "userName": "FOWKESBROS",
  //  //  "password": "FoWK3SBR0S"
  //  //};

  //  //return this.http.post<T>(endpointUrl, JSON.stringify(postObject)).pipe<T>(
  //  //  catchError(error => {
  //  //    return this.handleError(error, () => this.getDPDtrackAndTraceEndpoint(waybillNumber));
  //  //  }));
  //}


}
