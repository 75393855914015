

export class AspnetApplications {

  constructor(applicationName?: string, loweredApplicationName?: string, applicationId?: string, description?: string) {

    this.applicationName = applicationName;
    this.loweredApplicationName = loweredApplicationName;
    this.applicationId = applicationId;
    this.description = description;
  }

  public applicationName: string;
  public loweredApplicationName:  string;
  public applicationId: string;
  public description: string;

}
