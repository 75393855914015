import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { count } from 'console';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { empty } from 'rxjs';
import { groupBy } from 'rxjs/operators';
import { Branch } from '../../../models/branch.model';
import { CreditNoteDataModel } from '../../../models/credit-note-data.model';
import { CreditNoteSummaryModel } from '../../../models/credit-note-summary.model';
import { CreditNoteReportDetailData } from '../../../models/creditnote-report-detail-data.model';
import { DebtorsReleaseData } from '../../../models/debtors-release-data.model';
import { DebtorsReleaseOverview, ICompanyTotals, ICustomerTotals, IDataDetails, IOperator } from '../../../models/debtors-release-overview.model';
import { DebtorsReleaseSummary } from '../../../models/debtors-release-summary.model';
import { localStorageActions } from '../../../models/enums';
import { SysproUser } from '../../../models/syspro-user.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'reports-creditors',
  templateUrl: './reports-creditors-component.html',
  styleUrls: ['./reports-creditors-component.scss']
})
export class ReportsCreditorsComponent implements OnInit {


  private loadingIndicator: boolean;

  columns: any[] = [];
  rows: CreditNoteReportDetailData[] = [];
  rowsCache: CreditNoteReportDetailData[] = [];

  rowsSummary: CreditNoteSummaryModel[] = [];
  rowsCacheSummary: CreditNoteSummaryModel[] = [];

  @ViewChild('netTemplate')
  netTemplate: TemplateRef<any>;

  @ViewChild('totalNetMerchandiseCell') totalNetMerchandiseCell: TemplateRef<any>;

  @ViewChild('gpTemplate')
  gpTemplate: TemplateRef<any>;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  operatorModel: IOperator;
  consolidatedDataSet: IOperator[] = [];

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  @ViewChild('accordion') Accordion: MatAccordion;

  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];


  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;


  @ViewChild('creditDetailsModal')
  creditDetailsModal: ModalDirective;

  @ViewChild('operators')
  private operators;

  @ViewChild('operatorSelector')
  private operatorSelector;
  allOperators: SysproUser[] = [];

  selectedBranch: string;
  selectedOperator: string;
  selectedCompany: string;
  selectedDocumentType: string;
  totalNetMerchandiseSumm: number;
  totalInvoiceCostSumm: string;
  totalInvoiceSumm: string;
  uniqueListOfOperators: DebtorsReleaseSummary[];
  public isCollapsed: boolean = true;
    consolidatedDataSetCache: IOperator[];

  selectedSalesOrder: string;
    reportTotalSales: number;
    reportTotalCount: number;
    totalInvoicedLines: number;
    totalCreditedLines: number;
    overallPercentageCredited: number;
    filteredDetails: CreditNoteReportDetailData[];
    

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService) {
  }

  ngAfterViewInit() {
    this.columns = [
      { prop: 'operator', name: 'Operator', width: 100, cellClass: "left" },
      { prop: 'company', name: 'Comp.', width: 100, cellClass: "left" },
      { prop: 'customer', name: 'Customer', width: 100, cellClass: "left" },
      { prop: 'customerName', name: 'Name', width: 200, cellClass: "left" },
      //{ prop: 'branch', name: 'Branch', width: 50, cellClass: "left", summaryFunc: () => null, summaryTemplate: this.totalNetMerchandiseCell },
      { prop: 'invoiceCount', name: 'Invoices', width: 80, cellClass: "center" },
      { prop: 'netSales', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells) }

    ];

  }

  summaryTotal(cells: number[]): string {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += cell), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  ngOnInit() {

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }


    this.columns = [
      { prop: 'operator', name: 'Operator', width: 100, cellClass: "left" },
      { prop: 'company', name: 'Comp.', width: 100, cellClass: "left" },
      { prop: 'customer', name: 'Customer', width: 100, cellClass: "right" },
      { prop: 'customerName', name: 'Name', width: 100, cellClass: "right" },
      { prop: 'invoiceCount', name: 'Invoices', width: 80, cellClass: "left" },
      { prop: 'netSales', name: 'Total Net', width: 140, cellClass: "right", cellTemplate: this.netTemplate, summaryFunc: cells => this.summaryTotal(cells) }

    ];

    this.loadData();

  }


  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    //this.allBranches = [];

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.rowsCache = null;
    this.rows = null;

    //this.consolidatedDataSet = [];

    //let defaultBranch = new Branch();
    //defaultBranch.branch = this.selectUndefinedOptionValue;
    //this.allBranches.push(defaultBranch);
    //this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    this.sysproService.getCreditNoteReportSummary(startD.toJSON(), endD.toJSON()).subscribe(x => this.onSummarySuccesful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getCreditNoteReportData(startD.toJSON(), endD.toJSON()).subscribe(x => this.onDataSuccesful(x), error => this.onDataLoadFailed(error));
  }

  //Reload() {
  //  if (this.selectedCompany && this.selectedCompany != "ALL") {

  //    this.rows = this.rowsCache.filter(x => x.company == this.selectedCompany);
  //   // this.rowsSummary = this.rowsCacheSummary.filter(x => x.company == this.selectedCompany);      

  //  }
  //  else {
  //    this.rows = this.rowsCache;
  //    this.rowsSummary = this.rowsCacheSummary;
  //  }

  //  if (this.selectedBranch && this.selectedBranch != "ALL") {

  //    this.rows = this.rows.filter(x => x.branch == this.selectedBranch);
  //   // this.rowsSummary = this.rowsSummary.filter(x => x.branch == this.selectedBranch);

  //  }
  //  else {
  //    this.rows = this.rows;
  //    this.rowsSummary = this.rowsSummary;
  //  }

  //  //this.formatData();
  //}

  onSummarySuccesful(x: CreditNoteSummaryModel[]): void {
    if (x && x[0] && x.length > 0) {
      const details = x;
      //details.forEach((line, index, details) => {
      //  (<any>line).index = index + 1;
      //});

      this.rowsCacheSummary = [...details];

      //if (this.selectedCompany && this.selectedCompany != "ALL") {

      //  //this.rowsSummary = this.rowsCacheSummary.filter(x => x.company == this.selectedCompany);

      //}
      //else {
      //  this.rowsSummary = this.rowsCacheSummary;
      //}

      //if (this.selectedBranch && this.selectedBranch != "ALL") {

      //  //this.rowsSummary = this.rowsSummary.filter(x => x.branch == this.selectedBranch);

      //}
      //else {
      //  this.rowsSummary = this.rowsSummary;
      //}
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }

  onDataSuccesful(x: CreditNoteReportDetailData[]): void {
    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });


      this.rowsCache = [...details];
      this.rows = this.rowsCache;

      //if (this.selectedCompany && this.selectedCompany != "ALL") {

      //  this.rows = this.rowsCache.filter(x => x.company == this.selectedCompany);

      //}
      //else {
      //  this.rows = this.rowsCache;
      //}

      //if (this.selectedBranch && this.selectedBranch != "ALL") {

      //  this.rows = this.rows.filter(x => x.branch == this.selectedBranch);

      //}
      //else {
      //  this.rows = this.rows;
      //}

      this.formatData();

    }
    else {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }

  private formatData() {
    if (!this.rowsCacheSummary || !this.rowsCache) {
      console.error("Data arrays are not initialized.");
      return;
    }

    const updatedSummary = this.rowsCacheSummary;
    const detailEntities = this.rowsCache;

    const invoiceToOperatorMap: { [invoice: string]: string } = {};

    detailEntities.forEach(detail => {
      if (detail.documentType !== 'C') {
        invoiceToOperatorMap[detail.invoice] = detail.operator;
      }
    });

    detailEntities.forEach(detail => {
      if (detail.documentType === 'C' && invoiceToOperatorMap[detail.creditedInvoice]) {
        detail.operator = invoiceToOperatorMap[detail.creditedInvoice];
      }
    });


    const invoicedLinesPerOperator = detailEntities.reduce((accumulator, currentInvoice) => {
      if (currentInvoice.documentType != 'C') {
        if (accumulator[currentInvoice.operator]) {
          accumulator[currentInvoice.operator]++;
        } else {
          accumulator[currentInvoice.operator] = 1;
        }
      }
      return accumulator;
    }, {});

    const creditedLinesPerOperator = detailEntities.reduce((accumulator, currentInvoice) => {
      if (currentInvoice.documentType == 'C') {
        if (accumulator[currentInvoice.operator]) {
          accumulator[currentInvoice.operator]++;
        } else {
          accumulator[currentInvoice.operator] = 1;
        }
      }
      return accumulator;
    }, {});

    const invoicesPerOperator = detailEntities.reduce((accumulator, currentInvoice) => {
      if (currentInvoice.documentType !== 'C') {
        accumulator[currentInvoice.operator] = accumulator[currentInvoice.operator] || new Set();
        accumulator[currentInvoice.operator].add(currentInvoice.invoice);
      }
      return accumulator;
    }, {});

    const creditNotesPerOperator = detailEntities.reduce((accumulator, currentInvoice) => {
      if (currentInvoice.documentType === 'C') {
        accumulator[currentInvoice.operator] = accumulator[currentInvoice.operator] || new Set();
        accumulator[currentInvoice.operator].add(currentInvoice.invoice);
      }
      return accumulator;
    }, {});

    updatedSummary.forEach(summary => {
      if (invoicedLinesPerOperator[summary.operator]) {
        summary.invoicedLines = invoicedLinesPerOperator[summary.operator];
      } else {
        summary.invoicedLines = 0;
      }

      if (creditedLinesPerOperator[summary.operator]) {
        summary.creditedLines = creditedLinesPerOperator[summary.operator];
      } else {
        summary.creditedLines = 0;
      }

      const totalLines = (summary.invoicedLines || 0) + (summary.creditedLines || 0);
      summary.percentageLinesCredited = totalLines > 0
        ? (summary.creditedLines || 0) / totalLines * 100
        : 0;

      if (invoicesPerOperator[summary.operator]) {
        summary.invoiceCount = invoicesPerOperator[summary.operator].size;
      } else {
        summary.invoiceCount = 0;
      }

      if (creditNotesPerOperator[summary.operator]) {
        summary.creditNoteCount = creditNotesPerOperator[summary.operator].size;
      } else {
        summary.creditNoteCount = 0;
      }

    });

    // Sort updatedSummary by percentage in descending order
    updatedSummary.sort((a, b) => b.creditedLines - a.creditedLines);

    // Calculate totals after updating the summary
    //this.totalInvoicedLines = updatedSummary.reduce((total, current) => total + current.invoicedLines, 0);
    //this.totalCreditedLines = updatedSummary.reduce((total, current) => total + current.creditedLines, 0);
    //this.overallPercentageCredited = (this.totalInvoicedLines + this.totalCreditedLines) > 0 ?
    //  (this.totalCreditedLines / (this.totalInvoicedLines + this.totalCreditedLines) * 100) :
    //  0;

    this.rowsSummary = updatedSummary;


    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;


  }


  //onSearchChanged(value: string) {
  //  if (value != "") {
  //     this.consolidatedDataSet = this.consolidatedDataSetCache.filter(r => Utilities.searchArray(value, false, r.operator));    
  //  }
  //  else {
  //    this.consolidatedDataSet = this.consolidatedDataSetCache;
  //  }
  //}


  //onSearchChangedCustomer(value: string) {
  //  if (value != "") {
  //    this.consolidatedDataSet = this.consolidatedDataSetCache.filter(
  //      (x) =>
  //        x.companyTotals.find(
  //          (y) => y.customerTotals.find(
  //            (z) => z.customer.toLowerCase().indexOf(value.toLowerCase()) > -1
  //            )
  //        )
  //    );
  //  }
  //  else {
  //    this.consolidatedDataSet = this.consolidatedDataSetCache;
  //  }
  //}


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  //onBranchLoadSuccessful(branches: Branch[]) {

  //  this.allBranches = [...this.allBranches, ...branches];
  //  setTimeout(() => {
  //    this.branchesListSelector.refresh();
  //  });
  //}

  //onBranchDataLoadFailed(error: any) {
  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;
  //  this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  //}


  //onOperatorsLoadSuccessful(operators: SysproUser[]): void {

  //  this.allOperators = [...this.allOperators, ...operators];
  //  setTimeout(() => {
  //    this.operatorSelector.refresh();
  //  });
  //}


  onAppDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve company data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;

    this.loadData();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;

    this.loadData();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;

    this.loadData();
  }

  exportSummary() {

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Format dates to "YYYY-MM-DD"
    let formattedStartDate = `${startD.getFullYear()}-${(startD.getMonth() + 1).toString().padStart(2, '0')}-${startD.getDate().toString().padStart(2, '0')}`;
    let formattedEndDate = `${endD.getFullYear()}-${(endD.getMonth() + 1).toString().padStart(2, '0')}-${endD.getDate().toString().padStart(2, '0')}`;

    // Create filename with date range
    var filename = `CreditedSummary_${formattedStartDate}_to_${formattedEndDate}`;

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Operator", "OperatorName", "LinesInvoiced", "LinesCredited", "Percentage"]
    };

    var selectRows = [...this.rowsSummary];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Operator: row.operator, OperatorName: row.originalOperatorName, LinesInvoiced: row.invoicedLines, LinesCredited: row.creditedLines, Percentage: row.percentageLinesCredited.toFixed(2) + "%"
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  exportDetails() {

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Format dates to "YYYY-MM-DD"
    let formattedStartDate = `${startD.getFullYear()}-${(startD.getMonth() + 1).toString().padStart(2, '0')}-${startD.getDate().toString().padStart(2, '0')}`;
    let formattedEndDate = `${endD.getFullYear()}-${(endD.getMonth() + 1).toString().padStart(2, '0')}-${endD.getDate().toString().padStart(2, '0')}`;

    // Create filename with date range
    var filename = `CreditedDetails_${formattedStartDate}_to_${formattedEndDate}`;

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Operator", "Customer", "Invoice", "QTY", "StockCode", "Description", "CreditedInvoice", "DocumentType"]
    };

    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Operator: row.operator, Customer: row.customer, Invoice: row.invoice, QTY: row.lineQTY, StockCode: row.stockCode, Description: row.stockDescription, CreditedInvoice: row.creditedInvoice, DocumentType: row.documentType
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  exportOperatorLines() {

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Format dates to "YYYY-MM-DD"
    let formattedStartDate = `${startD.getFullYear()}-${(startD.getMonth() + 1).toString().padStart(2, '0')}-${startD.getDate().toString().padStart(2, '0')}`;
    let formattedEndDate = `${endD.getFullYear()}-${(endD.getMonth() + 1).toString().padStart(2, '0')}-${endD.getDate().toString().padStart(2, '0')}`;

    // Create filename with date range
    var filename = `${this.selectedOperator}_${formattedStartDate}_to_${formattedEndDate}`;


    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Operator", "Customer", "Invoice", "QTY", "StockCode", "Description", "CreditedInvoice", "DocumentType"]
    };

    var selectRows = [...this.filteredDetails];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Operator: row.operator, Customer: row.customer, Invoice: row.invoice, QTY: row.lineQTY, StockCode: row.stockCode, Description: row.stockDescription, CreditedInvoice: row.creditedInvoice, DocumentType: row.documentType
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }



  viewDetails(operator: string) {

    this.filteredDetails = null;
    this.selectedOperator = operator;

    //Get all invoice and credit note details for the operator
    this.filteredDetails = this.rowsCache.filter(detail => detail.operator === operator);

    this.filteredDetails.sort((a, b) => {
      return new Date(a.invoiceDate).valueOf() - new Date(b.invoiceDate).valueOf();
    });


    if (!!this.selectedOperator) {
      if (!this.creditDetailsModal) {
        setTimeout(() => {
          if (this.creditDetailsModal) {
            this.creditDetailsModal.show();
          }
        });
      }
      else {
        this.creditDetailsModal.show();
      }
    }
  }


  creditDetailsModalHide() {
    this.creditDetailsModal.hide();
  }

  previewSalesOrder(invoice: string, customer: string) {

    this.selectedSalesOrder = invoice;

    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != invoice) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber,  invoice);
    }

    if (!!this.selectedSalesOrder) {
      if (!this.salesOrderModal) {
        setTimeout(() => {
          if (this.salesOrderModal) {
            this.salesOrderModal.show();
          }
        });
      }
      else {
        this.salesOrderModal.show();
      }
    }
  }

  salesOrderModalHide() {
    this.salesOrderModal.hide();
  }


  viewSalesOrder() {

    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  }
}
