
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { Router } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'syspro-customers-management',
  templateUrl: './syspro-customers-management.component.html',
  styleUrls: ['./syspro-customers-management.component.scss']
})
export class SysproCustomersManagementComponent implements OnInit, AfterViewInit {
    columns: any[] = [];
    rows: ArCustomer[] = [];
    rowsCache: ArCustomer[] = [];
    loadingIndicator: boolean;

  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('selectTemplate')
    selectTemplate: TemplateRef<any>;
    
    @Input()
    isManagement: boolean;

    @Input()
    isCart: boolean;

    @Input()
  isSearch: boolean;


  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";


  constructor(private alertService: AlertService, private accountService: AccountService,
    private sysproService: SysproService, private configurations: ConfigurationService, 
    private router: Router) {
    }


  ngOnInit() {


    let action = sessionStorage.getItem(localStorageActions.Customer);
    let cartId = sessionStorage.getItem(localStorageActions.CartId);

    if (cartId && action) {
      //You have a cart in progress, what would you like to do?
      this.alertService.showDialog('You currently have a cart in progress for customer \"' + action + '. Do you want to start a new order or continue with the current one ' + '\"?',
        DialogType.confirm, () => this.clearCartHelper(), this.continueCartHelper(), "Start New Order", "Update my cart");
    }

    this.SetDataGrid();
    this.loadData();
  }

  continueCartHelper(): any {


  }

  clearCartHelper(): any {
    if (this.isCart) {
      sessionStorage.removeItem(localStorageActions.CartId);
    }
  }


  private SetDataGrid() {
        if (this.isSearch) {
            this.columns = [
                { prop: 'customer', name: 'Customer', width: 70, cellClass: "left", cellTemplate: this.selectTemplate },
                { prop: 'customerName', name: 'Name', width: 250, cellClass: "left", cellTemplate: this.selectTemplate },
                { prop: 'telephone', name: 'Tel', width: 100, cellClass: "left", cellTemplate: this.selectTemplate },
                { prop: 'email', name: 'Email', width: 220, cellClass: "left", cellTemplate: this.selectTemplate },
                { prop: 'addTelephone', name: 'Mobile', width: 100, cellClass: "left", cellTemplate: this.selectTemplate }
            ];
        }
        else {
            this.columns = [
                { prop: 'customer', name: 'Customer', width: 90, cellClass: "center", cellTemplate: this.actionsTemplate },
                { prop: 'customerName', name: 'Name', width: 220, cellClass: "left", cellTemplate: this.actionsTemplate },
                { prop: 'telephone', name: 'Tel', width: 110, cellClass: "left", cellTemplate: this.actionsTemplate },
                { prop: 'email', name: 'Email', width: 200, cellClass: "left", cellTemplate: this.actionsTemplate },
                { prop: 'addTelephone', name: 'Mobile', width: 110, cellClass: "left", cellTemplate: this.actionsTemplate },
                { prop: 'contact', name: 'Contact', width: 130, cellClass: "left", cellTemplate: this.actionsTemplate },
                { prop: 'branch', name: 'Branch', width: 50, cellClass: "center", cellTemplate: this.actionsTemplate },
                { prop: 'customerOnHold', name: 'On Hold', width: 50, cellClass: "center", cellTemplate: this.actionsTemplate }
            ];
        }
    }

  ngAfterViewInit() {
    this.SetDataGrid();
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

  }

  loadData() {

    this.searchType = "customer";
   
    if (this.loadCache == true) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getCustomers(this.branchCode).subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }

  onDataLoadSuccessful(customers: ArCustomer[]) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

      //customers.forEach((customer, index, customers) => {
      //  (<any>customer).index = index + 1;
      //  });

      this.rowsCache = [...customers];
      this.rows = customers.slice(0,18);
  }

  
    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


  onSearchChanged(value: string) {
      if (value != "") {
        var filterValues = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.customerName, r.customer, r.telephone, r.email, r.addTelephone, r.branch, r.contact, r.salesperson));
        this.rows = this._sortByTerm(filterValues, "customerName", value.toUpperCase());
      }    
    }


  _sortByTerm(data, key, term) {
    return data.sort(function (a, b) {
      return a[key].indexOf(term) > b[key].indexOf(term) ? -1 : 1;
    });
  }

  viewCustomer(row: ArCustomerEdit) {
    this.setLocalCustomer(row.customer);
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: row.customer } });
   
  }
  
  customerCart(row: ArCustomerEdit) {
    this.setLocalCustomer(row.customer);   
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: row.customer } });
   
  }
  
  viewCustomerNotes(row: ArCustomerEdit) {
    this.setLocalCustomer(row.customer);
    this.router.navigate(['../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

  setCustomer(row: ArCustomerEdit) {
    this.setLocalCustomer(row.customer);
       
  }

  setLocalCustomer(customer: string) {
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }    
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
