import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { localStorageActions } from 'src/app/models/enums';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'Pods',
    templateUrl: './pod.component.html',
    styleUrls: ['./pod.component.scss'],
    animations: [fadeInOut]
})
export class PodComponent {

  isEditBatchActivated = false;
  isReconcileActivated = false;
  isSearchActivated = true;
  isSearchMissingActivated = false;
  isSyncActivated = false;
  isFileSyncActivated = false;
  isPODViewActivated = false;

  isCollapsed = false;

  fragmentSubscription: any;

  readonly editBatchTab = 'editbatch';
  readonly reconcileTab = 'reconcile';
  readonly searchTab = 'search';
  readonly searchMissingTab = 'searchmissing';
  readonly syncTab = 'sync';
  readonly fileSyncTab = 'filesync';
  readonly podViewTab = 'view';

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  POD: string;

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {

    let POD = sessionStorage.getItem(localStorageActions.PODReference);

    this.POD = POD;

    this.isCollapsed = !this.configurations.menuExpanded;

    if (this.POD) {
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.POD));
    } else {
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    }
  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
  showContent(anchor: string, test?: any) {

    this.POD = sessionStorage.getItem(localStorageActions.PODReference);

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.searchTab}Tab`);
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isEditBatchActivated = activeTab == this.editBatchTab;
    this.isReconcileActivated = activeTab == this.reconcileTab;
    this.isSearchActivated = activeTab == this.searchTab;
    this.isSearchMissingActivated = activeTab == this.searchMissingTab;
    this.isSyncActivated = activeTab == this.syncTab;
    this.isFileSyncActivated = activeTab == this.fileSyncTab;
    this.isPODViewActivated = activeTab == this.podViewTab;

    this.router.navigate([], { fragment: activeTab, queryParams: { Invoice: this.POD } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isEditBatchActivated = activeTab == this.editBatchTab;
    this.isReconcileActivated = activeTab == this.reconcileTab;
    this.isSearchActivated = activeTab == this.searchTab;
    this.isSearchMissingActivated = activeTab == this.searchMissingTab;
    this.isSyncActivated = activeTab == this.syncTab;
    this.isFileSyncActivated = activeTab == this.fileSyncTab;

    this.router.navigate([], { fragment: activeTab });
  }
   
  
  get canViewEquation() {
    return this.accountService.userHasPermission(Permission.eqationPODsPermission);
  }


  get canSearchPODs() {
    return this.accountService.userHasPermission(Permission.searchPODsPermission);
  }


  get canManagePODs() {
    return this.accountService.userHasPermission(Permission.managePODsPermission);
  }


  get canViewPODs() {
    return this.accountService.userHasPermission(Permission.viewPODsPermission);
  }


  get canViewMissingPODs() {
    return this.accountService.userHasPermission(Permission.missingPODsPermission);
  }


  get canSyncPODs() {
    return this.accountService.userHasPermission(Permission.batchSyncPODsPermission);
  }
  
}
