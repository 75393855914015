

export class Branch {

  constructor(branch?: string, priceCode?: string, priceOverridePassword?: string,
    invoiceReprintPassword?: string, ledgerCode?: string, salesOrderPassword?: string) {

    this.branch = branch;
    this.priceCode = priceCode;
    this.priceOverridePassword = priceOverridePassword;
    this.invoiceReprintPassword = invoiceReprintPassword;
    this.ledgerCode = ledgerCode;
    this.salesOrderPassword = salesOrderPassword
  }


  public branch: string;
  public priceCode: string;
  public priceOverridePassword: string;
  public invoiceReprintPassword: string;
  public ledgerCode: string;
  public salesOrderPassword: string;
}
