
export class PurchaseOrderHeader {
  constructor() {

   
  }

  public purchaseOrder: string;
  public supplier: string;
  public supplierName: string;
  public descr: string;
  public warehouse: string;
  public branchDescr: string;
  public discount: number;
  public orderDate: Date;
  public dueDate: Date;
  public memoDate: Date;
  public orderType: string;
  public memoCode: string;
  public customerPoNumber: string;
  public shippingInstrs: string;
  public taxStatus: string;
  public termsCode: string;
  public buyer: string;
  public buyerName: string;
  public buyerEmail: string;
  public exchRateFixed: string;
  public exchangeRate: number;
  public deliveryAddr1: string;
  public deliveryAddr2: string;
  public deliveryAddr3: string;
  public deliveryAddr4: string;
  public deliveryAddr5: string;
  public postalCode: string;
  public orderStatus: string;
  public statusDescr: string;
  public paymentTerms: string;
  public telephone: string;
  public email: string;
  public contact: string;
  public customer: string;
  public salesOrder: string;
  public comment: string;
  public printed: boolean;
  public gRNNumber: string;
  public vatPercentage: number;
  public totalIncl: number;
  public totalExcl: number;
  public totalDiscount: number;
  public totalVat: number;
  public poNumber: string;
}

