import { Component, OnInit, ViewChild, Input, TemplateRef, OnChanges, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { InvoiceTrackingStatus } from 'src/app/models/invoice-tracking-status.model';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { localStorageActions } from '../../../models/enums';
import { User } from '../../../models/user.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { InvoiceBarcode } from '../../../models/invoice-barcode.model';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { ITSParcelTracking, ITSParcelTrackingDetail } from '../../../models/invoice-tracking-parcels.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ITSDriversLog, ITSParcelTrackingDetailLog } from '../../../models/invoice-tracking-drivers-log.model';
import { ngxCsv } from 'ngx-csv';
import { forkJoin } from 'rxjs';
import { ITSDriversReport } from '../../../models/invoice-tracking-driver-report.model';


@Component({
  selector: 'tracking-drivers-report',
  templateUrl: './tracking-drivers-report-component.html',
  styleUrls: ['./tracking-drivers-report-component.scss']
})
export class TrackingDriversReportComponent implements OnInit
{

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];
  rowsHeader: ITSDriversReport[] = [];
  rowsHeaderCache: ITSDriversReport[] = [];

  rowsDetail: ITSParcelTrackingDetail[] = [];
  rowsDetailCache: ITSParcelTrackingDetail[] = [];

  trackingHeader: ITSParcelTracking;

  rowsDriverLogHeader: ITSDriversLog[] = [];
  rowsDriverLogHeaderCache: ITSDriversLog[] = [];

  rowsParcelDetail: ITSParcelTrackingDetailLog[] = [];
  rowsParcelDetailCache: ITSParcelTrackingDetailLog[] = [];


  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild('itsModal')
  itsModal: ModalDirective;

  @ViewChild('reportDetailModal')
  reportDetailModal: ModalDirective;

  @ViewChild('returnedModal')
  returnedModal: ModalDirective;

  @ViewChild('notesModal')
  notesModal: ModalDirective;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate')
  nameTemplate: TemplateRef<any>;

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;


  @ViewChild('previewTemplate')
  previewTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;


  @ViewChild('changedSelector')
  private changedSelector;

  private isEditMode = false;
    loadingIndicator: boolean;
  result: InvoiceTrackingStatus;
  ITSObject: InvoiceTrackingStatus = new InvoiceTrackingStatus();
  

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
  invoiceTracking: InvoiceTrackingStatus;
    hasDuplicates: boolean;
    noResults: boolean;
  user: User;



  @Input()
  isViewOnly: boolean;
  enabled: boolean;

  driverSearch: string;
  driverCageSearch: string;
  vehicleSearch: string;
  barcodeSearch: string;
  addedPackage: string;

  @Input()
  selectedInvoice: string;

  @ViewChild('input1') input1!: ElementRef;
  @ViewChild('input2') input2!: ElementRef;
  @ViewChild('input3') input3!: ElementRef;
  @ViewChild('input4') input4!: ElementRef;
  inputList: string[] = [];

  barcodeList: InvoiceBarcode[];
  inputCount: { [key: string]: number } = {};
  driversLogHeader: ITSDriversLog;
  cardType: string;
  src: string;

  totalParcels: number;
  totalInvoices: number;
  totalDespatched: number;
  totalReturned: number;
  loaded: boolean;

  returnedParcels: number = 0;
  returnedReason: string = '';
  currentReturnedRow: ITSParcelTrackingDetail;

  despatchedParcels: number = 0;
  changedReason: string = '';
  currentUpdatingRow: ITSParcelTrackingDetailLog;

  updatingNotes: string = '';

  selectedCustomer: string;
    comment: string;
    sortBy: string;


  constructor(private router: Router, private route: ActivatedRoute, private sysproService: SysproService, private configurations: ConfigurationService,
    private alertService: AlertService, private accountService: AccountService, private elementRef: ElementRef, private fowkesService: FowkesOnlineService,) {

  }

  private setData() {
   
    this.columns = [

      { prop: 'driverCode', name: 'Driver', width: 100 },
      { prop: 'driverName', name: 'Name', width: 200}, 
      { prop: 'totalCustomers', name: '#Customers', width: 100, cellClass: "right" },
      { prop: 'totalInvoicesDelivered', name: '#Invoices', width: 100, cellClass: "right" },
      { prop: 'totalInvoiceLinesDelivered', name: '#Invoice Lines', width: 100, cellClass: "right" },
      { prop: 'totalInvoiceLineItemsDelivered', name: '#Items', width: 100, cellClass: "right" },
      { prop: 'totalParcelsDelivered', name: '#Parcels', width: 100, cellClass: "right" },
      { prop: 'totalInvoicesReturned', name: '#Invoices Returned', width: 130, cellClass: "right" },
      { prop: 'totalParcelsReturned', name: '#Parcels Returned', width: 130, cellClass: "right" },
    ];


    //this.columns.push({ name: 'View', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });


  }

  refresh() {
    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.setData();
    this.reset();
  }

  ngOnInit() {

    //this.sortBy = "ID";
    this.user = this.accountService.currentUser;

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }
  
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.setData();
    this.reset();
  }

  reset() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
   // this.sortBy = "ID";

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.totalInvoices = 0;
    this.totalParcels = 0;
    this.totalDespatched = 0;
    this.totalReturned = 0;

    this.loaded = false;

    this.fowkesService.getITSDriversReport(startD.toJSON(), endD.toJSON()).subscribe(x => this.onReportSearchSuccesful([x], startD, endD), error => this.onDataLoadFailed(error));
        
  }



  onReportSearchSuccesful(drivers: any[], startD: Date, endD: Date): void {

    if (drivers[0]) {
      this.rowsHeaderCache = drivers[0];
      this.rowsHeader = [...this.rowsHeaderCache];

      this.rowsHeader = this.rowsHeader.sort((a, b) => b.totalInvoiceLinesDelivered - a.totalInvoiceLinesDelivered);

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

    }
    else {

      this.rowsHeaderCache = null;
      this.rowsHeader = null;
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('', `No details found for selected date range`, MessageSeverity.info);
    }
  }

  onLogDetailSearchSuccesful(details: any[]): void {
    if (details[0] && details[0].length > 0) {
     
      this.rowsDriverLogHeaderCache.forEach(headerRow => {
        // Filter the details based on the trackingHeaderId of the current header row
        const matchedDetails = details[0].filter(detail => detail.trackingHeaderId === headerRow.id);

        // Assign the matched details to the header row (you can further process the data if needed)
        headerRow.details = matchedDetails;

      });

      this.rowsDriverLogHeader = [...this.rowsDriverLogHeaderCache];

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }
    else {

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('', `No details found for selected date range`, MessageSeverity.info);
    }
  }

 
  toggle() {
    this.show = true;
  }
  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }
  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  filterChanged() {

    this.reset();

  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rowsHeader = this.rowsHeaderCache.filter(r => Utilities.searchArray(value, false, r.driverCode, r.driverName));
    }
  }

  ngAfterViewInit(): void {

    this.setData();
  }

  viewDetails(row: ITSDriversLog) {

    this.sortBy = "ID";
    this.driversLogHeader = new ITSDriversLog();
    this.driversLogHeader = row;

    this.rowsParcelDetail = row.details;
    if (!this.reportDetailModal) {
      setTimeout(() => {
        if (this.reportDetailModal) {
          this.reportDetailModal.show();
        }
      });
    }
    else {
      this.reportDetailModal.show();
    }

    this.totalInvoices = this.rowsParcelDetail.length;
    this.totalParcels = this.rowsParcelDetail.reduce((total, row) => total + row.scannedCount, 0);
    this.totalDespatched = this.rowsParcelDetail.reduce((total, row) => total + row.itsParcelCount, 0);
    this.totalReturned = this.rowsParcelDetail.reduce((total, row) => total + row.undeliveredCount, 0);

  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  viewSalesInvoice() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode } });
  }


  viewCreditNote() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.creditNoteNumber);
    sessionStorage.setItem(localStorageActions.Customer, this.ITSObject.customerCode);
    sessionStorage.removeItem(localStorageActions.InvoiceNumber);
   // sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: this.ITSObject.customerCode, credit: "C" } });
  }

  viewTransfer() {
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, this.ITSObject.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: 'TRANSFER' } });
  }


  viewDocuments() {
    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, this.ITSObject.invoiceNumber);
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.ITSObject.salesOrder);
    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: this.ITSObject.salesOrder } });
  }

  viewITS() {
    this.router.navigate(['/reports'], { fragment: 'invoicestatus' });
  }


  controlReport() {
    this.router.navigate(['/reports'], { fragment: 'control' });
  }



  reportDetailModalHide() {
    this.driversLogHeader = null;
    if (this.reportDetailModal) {
      this.reportDetailModal.hide();
    }
    this.filterChanged();
  }


  previewITS(row: ITSParcelTrackingDetail) {

    this.selectedInvoice = row.invoiceNumber;
    this.selectedCustomer = row.customer;

    if (sessionStorage.getItem(localStorageActions.ITSInvoiceNumber) != row.invoiceNumber) {
      sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);
    }

    if (sessionStorage.getItem(localStorageActions.Customer) != row.customer) {
      sessionStorage.setItem(localStorageActions.Customer, row.customer);
    }

    if (!!this.selectedInvoice) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModalHide() {
    this.selectedInvoice = null;
    if (this.itsModal) {
      this.itsModal.hide();
    }
  }

  gotToITS() {

    this.router.navigate(['../../reports'], { fragment: 'invoicestatus', queryParams: { customer: this.selectedCustomer } });
  }




  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Driver", "DriverName", "totalCustomers", "totalInvoicesDelivered", "totalInvoiceLinesDelivered", "totalInvoiceLineItemsDelivered", "totalParcelsDelivered", "totalInvoicesReturned", "totalParcelsReturned"]
    };

    var filename = "DriversReport";
    var selectRows = [...this.rowsHeader];
    var exportRows = [];
    for (var row of selectRows) {
     
      exportRows.push({
        Driver: row.driverCode, DriverName: row.driverName, totalCustomers: row.totalCustomers, totalInvoicesDelivered: row.totalInvoicesDelivered,
        totalInvoiceLinesDelivered: row.totalInvoiceLinesDelivered, totalInvoiceLineItemsDelivered: row.totalInvoiceLineItemsDelivered, totalParcelsDelivered: row.totalParcelsDelivered,
        totalInvoicesReturned: row.totalInvoicesReturned, totalParcelsReturned: row.totalParcelsReturned
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  resultDetails() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Date", "Driver", "DriverName", "Vehicle", "VehicleDescription", "Cage", "CageDescription", "NoParcels", "Returned",
        "Detail_InvoiceNumber", "Detail_Customer", "Detail_Customer_Name", "Parcel_Count", "Parcels_Scanned",  "Detail_ScannedDate"]  // ... Invoice	Customer	Customer Name	#Parcels	#Scanned	Telephone	Address	#Returned
    };

    var filename = "DriversLogDetails";
    var selectRows = [...this.rowsDriverLogHeader];
    var exportRows = [];
    for (let row of selectRows) {
      const date = new Date(row.trackingInsertDate).toISOString().split('T')[0];
      const headerData = {
        Id: row.id, Date: date, Driver: row.driverCode, DriverName: row.driverName, Vehicle: row.vehicleNumber, VehicleDescription: row.vehicleDescription,
        Cage: row.cageNumber, CageDescription: row.cageDescription, NoParcels: row.totalParcels, Returned: row.totalParcelsUndelivered
      };

      if (row.details && row.details.length) {
        for (let detail of row.details) {
          exportRows.push({
            ...headerData,
            Detail_InvoiceNumber: detail.invoiceNumber,
            Detail_Customer: detail.customer,
            Detail_Customer_Name: detail.customerName,
            Parcel_Count: detail.itsParcelCount,
            Parcels_Scanned: detail.scannedCount,
            Detail_ScannedDate: detail.scannedDate,
            // ... other detail fields here
          });
        }
      } else {
        exportRows.push(headerData);
      }
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
