
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, Pipe, PipeTransform, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CurrencyPipe } from '@angular/common';


import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AccountService } from '../../../services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { ReportService } from '../../../services/report.service';
import { Utilities } from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../../models/permission.model';
//import { ArCustomer } from 'src/app/models/arcustomer.model';
//import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { forEach } from '@angular/router/src/utils/collection';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Email } from 'src/app/models/email.model';
import { validation } from 'src/app/services/validation.service';
import { Calculations } from 'src/app/services/calculations.service';
import { CoreCustomerPrices } from '../../../models/core-customer-prices.model';
import { CoreCustomer } from '../../../models/core-customer.model';
import { CoreCustomerRule } from '../../../models/core-customer-rule.model';
import { ArCustomer } from '../../../models/arcustomer.model';


@Component({
  selector: 'customers-cart',
  templateUrl: './customers-cart.component.html',
  styleUrls: ['./customers-cart.component.scss']
})

  
export class CustomerCartComponent implements OnInit {
    columns: any[] = [];
    rows: Inventory[] = [];
    rowsCache: Inventory[] = [];
    cartRows: CartDetail[] = [];
    //editedCustomer: ArCustomerEdit;
    //sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
  loadingIndicator: boolean;
  priceRows: CoreCustomerPrices[] = [];
  priceRowsCache: CoreCustomerPrices[] = [];

  @ViewChild('searchInput')
  searchInput: ElementRef;
  smartsearch: string = "";

    @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;


  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
  orderQtyTemplate: TemplateRef<any>;


  @ViewChild('orderQtyViewTemplate')
  orderQtyViewTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('sellpriceTemplate')
  sellpriceTemplate: TemplateRef<any>;

  @ViewChild('unitCostTemplate')
  unitCostTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

  inventoryList: Inventory[];


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;


  emailObject: Email = new Email();

  @ViewChild('emailModal')
  emailModal: ModalDirective;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  stockCode: string;


  @Input()
  isQuote: boolean;

  @Input()
  selectedCartNumber: string;

  @Input()
  selectedCustomerCode: string;

    id: string;
    cartId: string;
    customer: ArCustomer;
    stockCodedetail: Inventory;
    isNavigating: boolean;
    isSaving: boolean;
    cartSaved: boolean;
    cartlinenumber: number = 0;
    saveCount: number;
    whDetail: WHAvailQTY[] = [];
  stockDescription: string;
    isNavigatingTo: string;
  hasDataSheet: boolean;
  hasDataSheets: boolean
  hasAddDataSheets: boolean;
  hasSpecials: boolean;
    alternateKey: string;
    savedLineCount: number;
    isReloading: boolean;
  src: string;
  searchTerm: string = "";

  attachDataSheets: boolean;
  attachSpecials: boolean;

  @ViewChild('authModal')
  authModal: ModalDirective;

  authorized: boolean;
  stringToCompare: string;

  chkShortDescription: boolean;
  chkLongDescription: boolean;
  chkAnyDescription: boolean;

  searchType: string = "";
  searchText: string = "";
  searchResults: Inventory[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";
    searchingOn: string;
    buyerResponsible: string;
    hasPricing: boolean;
    coreCustomer: CoreCustomer;
    applyDiscounts: boolean;
    hasDicounts: boolean;
    rowCustomerRulesCache: CoreCustomerRule[];
    rowCustomerRules: CoreCustomerRule[];
    applyGeneralDiscount: boolean;
    generalDiscountPerc: number;
    rowCustomerRulesSpecialPrice: CoreCustomerRule[];
    rowCustomerRulesSupplierDiscount: CoreCustomerRule[];
    rowCustomerRulesCategoryDiscount: CoreCustomerRule[];
    rowCustomerRulesGeneralDiscount: CoreCustomerRule;
    applyGeneralNett: boolean;
    quantityByStockCode: {};

  constructor(private alertService: AlertService, private accountService: AccountService, private fowkesService: FowkesOnlineService,
    private configurations: ConfigurationService, private sysproService: SysproService, private reportService: ReportService, 
    private router: Router, private route: ActivatedRoute, private numberPipe: CurrencyPipe) {
  }


  ngOnInit() {

    if (this.isQuote) {
      if (sessionStorage.getItem(localStorageActions.Customer) != this.selectedCustomerCode) {
        sessionStorage.setItem(localStorageActions.Customer, this.selectedCustomerCode);
      }

      if (sessionStorage.getItem(localStorageActions.CartId) != this.selectedCartNumber) {
        sessionStorage.setItem(localStorageActions.CartId, this.selectedCartNumber);
      }

      this.SetDataGrid();
      this.authorized = false;
      this.loadData();
    }
  }  
  
  private SetDataGrid() {

    this.columns = [
      { prop: 'stockCode', name: 'StockCode', width: 100, cellTemplate: this.stockCodeTemplate, cellClass: "left" },
      { prop: 'description', name: 'Description', width: 400, cellTemplate: this.descriptionTemplate, cellClass: "left" },
      { prop: 'availQty', name: 'Qty On Hand', width: 50, cellClass: "right" },
      { prop: 'binLoc', name: 'Bin', width: 60, cellClass: "right" },
      { prop: 'orderQty', name: 'Order Qty', width: 60, cellTemplate: this.orderQtyTemplate, cellClass: "right" },
      { prop: 'uom', name: 'UOM', width: 50, cellClass: "center" },
      { prop: 'sellingPrice', name: 'Price (excl)', width: 100, cellTemplate: this.priceTemplate },
      { prop: 'discountpercentage1', name: 'Disc </br> (%)', width: 60, cellTemplate: this.discountTemplate },
      { prop: 'specialPrice', name: 'Nett Price (excl)', width: 80, cellTemplate: this.totalTemplate, cellClass: "right" },
      { prop: 'lineTotal', name: 'Total (excl)', width: 90, cellTemplate: this.totalTemplate, cellClass: "right" }
    ];
  }


  ngAfterViewInit() {
    this.SetDataGrid();
  }

  loadData() {

    this.hasPricing = false;
    this.applyDiscounts = false;

    if (this.selectedCartNumber) {
      this.cartId = this.selectedCartNumber;
      this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }

    if (this.selectedCustomerCode) {
      this.id = this.selectedCustomerCode;
      this.sysproService.getCustomerDetail(this.id).subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
  }


  onCartDetailLoadSuccessful(cartDetail: any[]) {
    if (cartDetail[0]) {
      this.cartRows = cartDetail;
      this.cartlinenumber = cartDetail.length;
    }
  }
      
  onDataLoadSuccessful(customer: ArCustomer) {
    this.customer = customer;
    this.coreCustomer = new CoreCustomer();
    this.sysproService.getPricingCustomer(this.id, this.application).subscribe(x => this.onCoreCustomerLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onCoreCustomerLoadSuccessful(x: CoreCustomer[]): void {
    if (x && x.length == 1) {
      if (x[0].customerCode != "") {
        this.coreCustomer = x[0];
        this.applyDiscounts = (this.coreCustomer.enabled == true? true : false);
        if (this.applyDiscounts) {
          this.sysproService.getAllPricingCustomerRules(this.coreCustomer.id).subscribe(x => this.onPricingRulesLoadSuccessful([x]), error => this.onDataLoadFailed(error));
          this.sysproService.getAllPricingCustomerPricesForCustomerCodeInventorySelect(this.id, this.application).subscribe(x => this.onPricesLoadSuccessful(x), error => this.onDataLoadFailed(error));
        }
        else {
          if (this.loadCache == true) {
            this.alertService.startLoadingMessage();
            this.loadingIndicator = true;
            this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x], true), error => this.onDataLoadFailed(error));
          }
        }
      }
    }
    else {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x], true), error => this.onDataLoadFailed(error));
    }
  }


  onPricesLoadSuccessful(prices: CoreCustomerPrices[]): void {

    if (prices && prices.length > 0) {
      this.priceRows = prices;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Success', 'Autmatic Pricing Applied',
      MessageSeverity.info);

    if (this.loadCache == true) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x], true), error => this.onDataLoadFailed(error));
    }
  }

  onPricingRulesLoadSuccessful(x: CoreCustomerRule[][]): void {
    
    if (x && x.length >= 1) {
      var ruleList = x[0];
      this.hasDicounts = true;
      this.rowCustomerRules = ruleList;
    }
  }

  getOrderQty(stockCode: string): number {
    var qty = 0;
    qty = this.cartRows.filter(s => s.stockCode == stockCode).reduce((sum, current) => sum + current.orderQty, 0);

    return qty;
  }

  onStockLoadSuccessful(inventory: any[], initialLoad?: boolean) {

    if (initialLoad && this.inventoryList && this.inventoryList.length >= 1) {
      // We dont want to replace what we just searched for
    }
    else {
      var generalDiscountPerc = new CoreCustomerRule();
      this.inventoryList = inventory[0];

      //Filter inventory list to only load items that are currently in the cart
      const stockedItemCodes = this.cartRows
        .filter(c => c.lineType === "1")
        .map(item => item.stockCode);

      this.inventoryList = this.inventoryList.filter(item => stockedItemCodes.includes(item.stockCode));

      this.inventoryList.forEach((stockCode, index, inventory) => {

        (<any>stockCode).index = index + 1;
        // (<any>stockCode).lineTotal = 0;
        (<any>stockCode).cartNumber = this.cartId;
        stockCode.description = stockCode.shortDescription + ' - ' + stockCode.longDescription;
        (<any>stockCode).orderQty = this.getOrderQty(stockCode.stockCode);
        (<any>stockCode).orderqty = this.getOrderQty(stockCode.stockCode);
        (<any>stockCode).sellingPrice = Calculations.RoundAwayFromZero(stockCode.sellingPrice, 5);
        (<any>stockCode).specialPrice = Calculations.RoundAwayFromZero((stockCode.sellingPrice / stockCode.priceConvFac), 5);

        if (this.applyDiscounts && this.rowCustomerRules && this.rowCustomerRules.length > 0) {

          //Apply Automatic Pricing
          this.hasPricing = true;
          generalDiscountPerc = this.rowCustomerRules.find(x => x.priority == 4);

          if (this.priceRows && this.priceRows.length >= 1) {

             let specialPrice = this.priceRows.find(x => x.stockCode == stockCode.stockCode);
            if (specialPrice) {
              if (specialPrice.priority == 1) {
                (<any>stockCode).specialPrice = specialPrice.newPrice;
                (<any>stockCode).discountpercentage1 = specialPrice.value;
              }
              else {
                if (stockCode.sysproNettPrice == false || this.coreCustomer.staffAccount == true) {
                  (<any>stockCode).specialPrice = specialPrice.newPrice;
                  (<any>stockCode).discountpercentage1 = specialPrice.value;
                }
              }
            }
            else {
              if (generalDiscountPerc && (stockCode.sysproNettPrice == false || this.coreCustomer.staffAccount == true)) {
                if (generalDiscountPerc.addValue > 0) {
                  (<any>stockCode).specialPrice = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, generalDiscountPerc.addValue, 0, stockCode.priceConvFac, 0);
                  (<any>stockCode).discountpercentage1 = 0;
                }
                if (generalDiscountPerc.value > 0) {
                  (<any>stockCode).discountpercentage1 = generalDiscountPerc.value;
                  (<any>stockCode).specialPrice = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, generalDiscountPerc.value, stockCode.priceConvFac, 0);
                }
              }
            }  
          }
         
          else {
            if (generalDiscountPerc && (stockCode.sysproNettPrice == false || this.coreCustomer.staffAccount == true)) {
              if (generalDiscountPerc.addValue > 0) {
                (<any>stockCode).specialPrice = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, generalDiscountPerc.addValue, 0, stockCode.priceConvFac, 0);
                (<any>stockCode).discountpercentage1 = 0;
              }
              if (generalDiscountPerc.value > 0) {
                (<any>stockCode).discountpercentage1 = generalDiscountPerc.value;
                (<any>stockCode).specialPrice = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, generalDiscountPerc.value, stockCode.priceConvFac, 0);
              }
            }
          }          
        }

        this.setLineTotal(stockCode);
      });
            
      this.rowsCache = [...this.inventoryList];
      this.rows = [...this.inventoryList];

      this.isReloading = false;
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.authorized = false;

      //if (this.searchTerm.trim() != "") {
      //  this.onSearchChanged(this.searchTerm);
      //}
    }   
  }


  getSpecialPrice(stockCode: Inventory, generalDiscountPerc?: CoreCustomerRule): any {
      var specialPrice = this.priceRows.filter(x => x.stockCode == stockCode.stockCode);
      
      if (specialPrice && specialPrice[0]) {
        var priceToApply = specialPrice.sort(p => p.priority)[0];
        stockCode.specialPrice = priceToApply.newPrice;
        stockCode.discountpercentage1 = priceToApply.value;
      }
      
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchTerm = "";
      this.inventoryList = null;
      this.searchingOn = (this.chkAnyDescription && this.searchType == "description" ? "STOCK" :
        this.chkLongDescription && this.searchType == "description" ? "LONGDESCRIPTION" :
          (this.chkShortDescription && this.searchType == "description" ? "SHORTDESCRIPTION" : this.searchType));
      this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchingOn, this.searchText.trim().replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(
        x => this.onStockLoadSuccessful([x], false), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onSpecificSearchSuccesful(x: Inventory[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }

  calcOrderQty(row: Inventory, event) {
    var val = event.target.value;
    row.orderqty = val;
    let total = 0;
    let discount = (row.discountpercentage1 && row.discountpercentage1 >= 0 ? row.discountpercentage1 : 0);

    if (discount > 0) {
      total = Calculations.getGridLineTotal(row.orderqty, row.sellingPrice, row.priceConvFac, discount, total);
    }
    else {
      let specialUnit = row.specialPrice * row.priceConvFac;
      total = Calculations.getGridLineTotal(row.orderqty, specialUnit, row.priceConvFac, discount, total);
    }

    //(row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);

    row.lineTotal = total;
  }

  calcDisc(row: Inventory, event) {
    var val = event.target.value;
    row.discountpercentage1 = val;
    let qty = (row.orderqty && row.orderqty >= 0 ? row.orderqty : 0);
    let total = 0;

    total = Calculations.getGridLineTotal(qty, row.sellingPrice, row.priceConvFac, row.discountpercentage1, total);
    row.specialPrice = Calculations.getGridLineTotal(1, row.sellingPrice, row.priceConvFac, row.discountpercentage1, total);

    //(row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
  }

  calcPrice(row: Inventory, event) {
    var val = event.target.value;
    row.sellingPrice = val;


    let discount = (row.discountpercentage1 && row.discountpercentage1 >= 0 ? row.discountpercentage1 : 0);
    let qty = (row.orderqty && row.orderqty >= 0 ? row.orderqty : 0);
    let total = 0;

    row.specialPrice = Calculations.getGridLineTotal(1, row.sellingPrice, row.priceConvFac, discount, total);

    total = Calculations.getGridLineTotal(qty, row.sellingPrice, row.priceConvFac, discount, total);


    //(row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
  }

  setLineTotal(row: Inventory) {
    let discount = (row.discountpercentage1 && row.discountpercentage1 >= 0 ? row.discountpercentage1 : 0);
    let qty = (row.orderQty && row.orderQty >= 0 ? row.orderQty : 0);
    let total = 0;
    row.specialPrice = Calculations.getGridLineTotal(1, row.sellingPrice, row.priceConvFac, discount, total);
    total = Calculations.getGridLineTotal(qty, row.sellingPrice, row.priceConvFac, discount, total);
    row.lineTotal = total;
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
   
    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      console.log(error.status);
      if (error.status == 404) { return;}
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

  //onSearchChanged(e: any) {
  //  let test = e;
  //  let value = this.smartsearch;
  //  if (value.trim() != "") {
  //    var filterValues = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.description, r.stockCode, r.warehouse, r.extraProductInform));
  //    this.rows = this._sortByTerm(filterValues, "description", value.toUpperCase());
  //    this.searchTerm = value;
  //  }
  //  else {
  //    this.searchTerm = "";
  //    this.rows = [...this.rowsCache];
  //  }
  //}

  //_sortByTerm(data, key, term) {
  //  return data.sort(function (a, b) {
  //    return a[key].indexOf(term) < b[key].indexOf(term) ? -1 : 1;
  //  });
  //}


  //viewCustomer() {
  //  this.isNavigating = true;
  //  this.isNavigatingTo = "customer";
  //  this.save();
  //  //this.cartlinenumber > 0 && 
  //  if (this.rowsCache.filter(x => x.orderqty > 0).length <= 0) {
  //    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.customer, cartId: this.cartId } });
  //  }
  //}

  //goAddNSItems() {
  //  this.isNavigating = true;
  //  this.isNavigatingTo = "nonstock";
  //  this.save();
  //  //this.cartlinenumber > 0 && 
  //  if (this.rowsCache.filter(x => x.orderqty > 0).length <= 0) {
  //    this.router.navigate(['../../orders'], { fragment: 'nonstock', queryParams: { customer: this.customer, cartId: this.cartId } });
  //  }
  //}

  //goSaveItems() {
  //  this.isNavigating = true;
  //  this.isNavigatingTo = "cart";
  //  this.save();
  //  //this.cartlinenumber > 0 && 
  //  if (this.rowsCache.filter(x => x.orderqty > 0).length <= 0) {
  //    this.router.navigate(['../../orders'], { fragment: 'cart', queryParams: { customer: this.customer, cartId: this.cartId } });
  //  }
  //}

  deleteCartLine(cartLine) {
    this.fowkesService.cartDetailDelete(cartLine.cartNumber, cartLine.cartLineNumber).subscribe(
      _response => {
        // Handle successful deletion
        console.log(`Deleted line: ${cartLine.cartLineNumber} successfully`);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  goSaveUpdatedItems() {
    //First delete all stocked items then add them again
    var existingCart = this.cartRows.filter(c => c.lineType == "1");
    const handledLines = new Set();

    existingCart.forEach(cartLine => {
      if (!handledLines.has(cartLine.cartLineNumber)) {
        handledLines.add(cartLine.cartLineNumber);
        this.deleteCartLine(cartLine);
      }
    });
    //var existingCart = this.cartRows.filter(c => c.lineType == "1");
    //for (var cartLine of existingCart) {
    //  this.fowkesService.cartDetailDelete(cartLine.cartNumber, cartLine.cartLineNumber).subscribe(_response => {}, error => this.saveFailedHelper(error));
    //}

    this.isNavigating = true;
    this.isNavigatingTo = "cart";
    this.save();

    ////If we are still busy saving but want to navigate, don't save again
    //if (this.isSaving || this.isReloading) {
    //  return;
    //}
    //else {
    //  //Do we have lines we want to save
    //  const saveRows = this.rowsCache.filter(x => x.orderqty > 0);
    //  this.saveCount = saveRows.length;

    //  if (this.saveCount > 0) {

    //    this.isSaving = true;
    //    this.alertService.startLoadingMessage('Saving changes...');
    //    this.savedLineCount = 0;

    //    const saveRowsMap = new Map();
    //    saveRows.forEach(item => {
    //      if (!saveRowsMap.has(item.stockCode)) {
    //        saveRowsMap.set(item.stockCode, item);
    //      }
    //    });
    //    var existingCart = this.cartRows;
    //    existingCart.forEach(line => {
    //      if (line.lineType === "1" && saveRowsMap.has(line.stockCode)) {
    //        const updatedLine = saveRowsMap.get(line.stockCode);

    //        // Update the existing line with values from the mapped item
    //        line.orderQty = updatedLine.orderqty;
    //        line.unitCost = updatedLine.unitCost;
    //        line.orderUom = updatedLine.uom;
    //        line.discountPercentage1 = updatedLine.discountpercentage1;
    //        line.sellingPrice = updatedLine.sellingPrice;
    //        line.specialPrice = updatedLine.specialPrice;
    //        line.nettPrice = Calculations.getGridNettLinePrice(updatedLine.orderqty, updatedLine.sellingPrice, updatedLine.priceConvFac, updatedLine.discountpercentage1, 0);
    //        line.nettDiscount = 0;
    //        line.gP = 0;

    //        if (this.ValidateSave(line)) {
    //          let newPrice = (updatedLine.discountpercentage1 > 0 ? line.sellingPrice : (updatedLine.specialPrice * updatedLine.priceConvFac));

    //          line.originalPrice = updatedLine.sellingPrice;
    //          if (updatedLine.discountpercentage1 == 0 && Math.floor(newPrice) < Math.floor(line.originalPrice)) {
    //            line.discountPercentage1 = Calculations.calculateSpecialDiscountTotal(line.originalPrice, newPrice, 0);
    //            line.originalDiscount = line.discountPercentage1;
    //          }

    //          this.fowkesService.cartDetail(line, line.cartNumber).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    //          this.savedLineCount++;
    //        }
    //      }
    //    });

    //  }
    //}
  }
  private save() {
        
    //If we are still busy saving but want to navigate, don't save again
    if (this.isSaving || this.isReloading) {
      return;
    }
    else {
      //Do we have lines we want to save
      const saveRows = this.rowsCache.filter(x => x.orderqty > 0);
      this.saveCount = saveRows.length;

      if (this.saveCount > 0) {

        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');
        this.savedLineCount = 0;

        //Populate cart rows with selected input   
        for (var product of saveRows) {
          this.cartlinenumber = this.GenerateUniqueCartLineNumber() + 1;
          product.discountpercentage1 = (product.discountpercentage1 && product.discountpercentage1 >= 0 ? product.discountpercentage1 : 0);
          const cartRow = new CartDetail;
          cartRow.cartNumber = this.cartId;
          cartRow.lineType = "1";
          cartRow.stockCode = product.stockCode;
          cartRow.cartLineNumber = this.cartlinenumber;
          cartRow.stockDescription = product.description.length > 50 ? product.description.substr(0, 50) : product.description;
          cartRow.warehouse = product.warehouse;
          cartRow.orderQty = product.orderqty;
          cartRow.unitCost = product.unitCost;
          cartRow.orderUom = product.uom;
          cartRow.discountPercentage1 = product.discountpercentage1;
          cartRow.discountValue = 0;
          cartRow.unitmass = 0;
          cartRow.unitvolume = 0;
          cartRow.comment = "";
          cartRow.commentFromLineNumber = 0;
          cartRow.commentType = "";
          cartRow.boSupplierName = "";
          cartRow.boSupplierInvoice = "";
          cartRow.boLineInfo = "";
          cartRow.boSupplierCode = "";
          cartRow.vatExempt = product.vatExempt;
          cartRow.sellingPrice = product.sellingPrice;// (product.specialPrice * product.priceConvFac);
          cartRow.originalDiscount = product.discountpercentage1;
          
          cartRow.specialPrice = (product.specialPrice);
          cartRow.nettPrice =  Calculations.getGridNettLinePrice(product.orderqty, product.sellingPrice, product.priceConvFac, product.discountpercentage1, 0);
          cartRow.nettDiscount = 0;
          cartRow.gP = 0;
          
          if (this.ValidateSave(cartRow)) {
            let newPrice = (product.discountpercentage1 > 0 ? cartRow.sellingPrice : (product.specialPrice * product.priceConvFac));
           // cartRow.sellingPrice = (product.discountpercentage1 > 0 ? cartRow.sellingPrice : (product.specialPrice * product.priceConvFac));
            cartRow.originalPrice = product.sellingPrice;
            if (product.discountpercentage1 == 0 && Math.floor(newPrice) < Math.floor(cartRow.originalPrice)) {
              cartRow.discountPercentage1 = Calculations.calculateSpecialDiscountTotal(cartRow.originalPrice, newPrice, 0);
              cartRow.originalDiscount = cartRow.discountPercentage1;
            }
            this.fowkesService.cartDetail(cartRow).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
            this.cartRows.push(cartRow);
            this.savedLineCount++;
          }
        }
      }
    }
  }


  ValidateSave(row: CartDetail): boolean {
    let error = "";
    if (row.lineType !== "6") {
      if (!this.authorized) {
        error = validation.minSpecialPrice(row.unitCost, row.specialPrice,  error);
        if (error.length > 1) {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
          this.authorized = false;
          this.authModal.show();
          return false;
        }
        else {
          this.authorized = true;
        }
      }
      if (this.authorized) {
    row.discountPercentage1 = (row.discountPercentage1 && row.discountPercentage1 >= 0 ? row.discountPercentage1 : 0);
    error = validation.stockCode(row.stockCode, error);
    error = validation.description(row.stockDescription, error);
    error = validation.qty(row.orderQty, error);
    error = validation.uom(row.orderUom, error);
    error = validation.price(row.sellingPrice, error);
    error = validation.discount(row.discountPercentage1, error);
    error = validation.cost(row.unitCost, error);
   // error = validation.minPrice(row.unitCost, row.sellingPrice, row.discountPercentage1, error);

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
      return false;
        }
        else {
          return true;
        }
      }
    }
    else {
      return true;
    }
  }

  //verifyAuth() {
  //  this.alertService.startLoadingMessage("Verfiying...");
  //  this.loadingIndicator = true;
  //  this.fowkesService.VerifyPassword(this.branchCode, "PRICE", encodeURIComponent(this.stringToCompare)).subscribe(x => this.onVerificationSuccess(), error => this.onVerificationFailed(error));

  //}
  //onVerificationSuccess(): void {
  //  this.authorized = true;
  //  this.alertService.stopLoadingMessage();
  //  this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
  //  this.authModal.hide();
  //  this.save();
  //}

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }  

  GenerateUniqueCartLineNumber(): any {
    if (this.cartRows.length > 0) {
      const maxId = this.cartRows.reduce(
        (max, character) => (character.cartLineNumber > max ? character.cartLineNumber : max),
        this.cartRows[0].cartLineNumber
      );
      return maxId;
    }
    else {
      return 0;
    }
  }
   
  private saveSuccessHelper(cart?: CartDetail) {

    this.cartSaved = true;

    //Check if all the lines have been saved
    if (this.saveCount == this.savedLineCount)
    {

      this.router.navigate(['../orders'], { fragment: 'cart', queryParams: { customer: this.selectedCustomerCode, cartId: this.selectedCartNumber } });

    }

    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
  }

  private refreshAll() {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.isReloading = true;
    this.alertService.startLoadingMessage("Reloading...");
    this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));

    if (this.searchText.trim().length >= 1) {
      this.searchingOn = (this.chkAnyDescription && this.searchType == "description" ? "STOCK" :
        this.chkLongDescription && this.searchType == "description" ? "LONGDESCRIPTION" :
          (this.chkShortDescription && this.searchType == "description" ? "SHORTDESCRIPTION" : this.searchType));
      this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchingOn, this.searchText.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(
        x => this.onStockLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    }
    else {
      this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    }
  }

  private saveFailedHelper(error: any) {
    //Check if all the lines have been saved
    if (this.saveCount == this.savedLineCount) {
      
      //Reload the inventory and cart details
      this.refreshAll();
    }
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
   
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.description;
      this.stockCode = this.stockCodedetail.stockCode;
      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.addSheetImage = row.addSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.onSpecialFowkes = row.onSpecialFowkes;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey && row.mapped) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.alternateKey;
      this.stockCode = this.stockCodedetail.stockCode;
      //this.sysproService.getStockCodeAvailableQuantities(row.alternateKey).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }
  openSpecials(row: Inventory) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }

    //if (!!row.specialsImage && row.specialsImage != "") {
    //  window.open(row.filePath + "/SpecialImages/" + row.specialsImage, "_blank");
    //}
  }

  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
     
    }
  }


  onEmailLoadSuccessful() {
    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasAddDataSheets = false;
    this.hasSpecials = false;

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);
    this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);

    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage.length >= 1 && (this.stockCodedetail.onSpecial || this.stockCodedetail.onSpecialFowkes));

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"), this.branchCode, this.application).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));

    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.customer.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();
    }
    if (this.hasAddDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  titleCase(str) {
    str = str.toLowerCase();
    return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
  }


  viewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailPDF() {

    console.log(this.emailObject);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.emailObject.AddDoc1 = (this.attachDataSheets ? this.emailObject.AddDoc1 : "");
    this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");
    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var stockCode = this.emailObject.Attachment.replace('/', '_').replace('+', '_').replace('%', '_');

    this.src = url;

    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = stockCode + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  
   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
