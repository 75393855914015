

export class InventoryMovements {
  constructor() {
  }


  public warehouse: string;
  public stockCode: string;
  public entryDate: Date;
  public movementType: string;
  public movementTypeDescr: string;
  public trnQty: number;
  public trnValue: number;
  public jobSource: string;
  public job: string;
  public enteredCost: number;
  public trnType: string;
  public trnTypeDescr: string;
  public reference: string;
  public addReference: string;
  public unitCost: number;
  public newWarehouse: string;
  public bin: string;
  public supplier: string;
  public salesOrder: string;
  public summaryLine: number;
  public invoice: string;
  public docType: string;
  public docTypeDescr: string;
  public customer: string;
  public customerName: string;
  public costValue: number;

  public branch: string;
  public salesPerson: string;
  public salesPersonName: string;
  public salesBin: string;
  public customerPoNumber: string;
  public orderType: string;
  public saveDate: Date;
  public saveWH: string;
  public saveOrigQtyOnHand: number;
  public saveQtyCaptured: number;
  public saveUom: string;
  public saveReference: string;
  public saveSystemDate: Date;
}

