import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { localStorageActions } from 'src/app/models/enums';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { SysproService } from 'src/app/services/syspro.service';
import { Utilities } from 'src/app/services/utilities';
import { CMCategoryForStockCodeList } from '../../models/cms-categoriesforstockcodes-list.model';
import { CMCategories } from '../../models/cms-category.model';
import { CMStockCode } from '../../models/cms-stockcode.model';
import { CoreAudit } from '../../models/core-audit.model';
import { DocumentCategory } from '../../models/document-category.model';
import { Inventory } from '../../models/inventory.model';
import { SimpleTree } from '../../models/simple-tree.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'Products-Edit-Categories',
  templateUrl: './products-editcategories.component.html',
  styleUrls: ['./products-editcategories.component.scss'],
  animations: [fadeInOut]
})
export class ProductsEditCategoriesComponent {

  auditObj = new CoreAudit();

  items: TreeviewItem[];
  rows: string[];
  item: any;
  data: CMCategories;
  categoryData: CMCategories[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild('itemTemplate')
  itemTemplate: TemplateRef<any>;

  loadingIndicator: boolean;
  enableEdit = false;
  enableEditIndex = null;

  @ViewChild('categoryListSelector')
  private categoryListSelector;
  private selectUndefinedOptionValue: string = "Please Select";
  categorySelected: string = "";
  categoryList: CMCategories[] = [];
  categoryListChangeParent: CMCategories[] = [];
  changeParent: boolean = false;


  @ViewChild('fileListSelector')
  private fileListSelector;
  fileCategorySelected: string = "";
  fileListChangeParent: CMCategories[] = [];
  changeFileParent: boolean = false;

  @ViewChild('categoryModal')
  categoryModal: ModalDirective;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  @ViewChild('myFileInput')
  myFileInput: ElementRef;

  categoryDetail: CMCategories = new CMCategories();
  rowsCache: TreeviewItem[];
  headerTemplateContext: { config: TreeviewConfig; item: any};
  modalHeader: string;
    isSaving: boolean;
    compareFolderName: any;
  documentDetail: CMStockCode = new CMStockCode();

  //newformData = new FormData();
  compareFileName: string;
  repoDocsList: CMStockCode[] = [];
  stockCodeList: CMStockCode[] = [];

  categoryStockCodeList: CMCategoryForStockCodeList[] = [];
  changeImage: boolean;
  newCategoryImageData = new FormData();

  @ViewChild(TreeviewComponent)
  categoryTreeviewComponent: TreeviewComponent;

  @ViewChild('treeModal')
  treeModal: ModalDirective;

  @ViewChild('treeItemTemplate')
  treeItemTemplate: TemplateRef<any>;

  treeItems: TreeviewItem[] = [];
  treeItem: any;
  newParentName: string = "";
    isEnabled: boolean;
    stockCodedetail: any;
    hasDataSheet: boolean;
    hasSpecials: boolean;
    hasImpaCodes: boolean;
    hasAddDataSheet: boolean;
    alternatesList: any[];
    updatedStockCodeDetail: any;
    changeStockCodeImage: boolean;
    newStockCodeImageData: FormData;
    newSpecialsImageData: any;
    newDatasheetImageData: any;
    newAddDatasheetImageData: any;
    stockCode: any;
    newStockCode: boolean;
    selectedCategory: string;
    newParentId: number;

  timestamp: string;


  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private sysproService: SysproService,
    private configurations: ConfigurationService,
    private accountService: AccountService, private authService: AuthService, private http: HttpClient) {
  }


  ngOnInit() {

    this.loadData();

  }

  ngOnDestroy() { }

  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.newParentName = "";

    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    this.sysproService.getAllCMCategories().subscribe(x => this.onCategoriesLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.sysproService.getCategoriesForStockCodesList().subscribe(x => this.onListLoadSuccessful(x), error => this.onDataLoadFailed(error));

    this.auditObj.referenceType = "INVENTORY";
    this.auditObj.referenceNumber = "";
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.accountService.currentUser.email;
    this.auditObj.actionedBy = this.accountService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;

    this.timestamp = Date.now.toString();
  }

  refresh() {
    this.loadData();
  }


  openCategoryLink(category: string) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/Search.aspx?s=" + encodeURIComponent(category), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(category), "_blank");
    }
  }
   
  onDataLoadSuccessful(treeview: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
       
    let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

    this.items = this.getItems([treeviewItems]);
    this.treeItems = this.getItems([treeviewItems]);
  }

  onCategoriesLoadSuccessful(results: CMCategories[]) {

    this.categoryList = results;
    this.categorySelected = "";
  }


  onListLoadSuccessful(x: CMCategoryForStockCodeList[]): void {
    this.categoryStockCodeList = x;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  collapseAll() {
    this.expandIndex = -1;
    this.loadData();
  }

  get application(): string {
    return this.configurations.applicationName;
  }

   //Category Tree Management
   treeBeforeExpand(row: TreeviewItem) {
    if (row) {
      if (row.collapsed) { }
      else {
        if (row.value.isLasLevel) {
          if (null == row.children) {
            this.alertService.startLoadingMessage();
            this.loadingIndicator = true;
            this.sysproService.getStockCodesForCategory(row.value.category.Id).subscribe(x => this.onStockCodesLoadSuccesfull(x, row), error => this.onDataLoadFailed(error));
          }
        }
      }
    }
  }


  enableEditMethod(row: TreeviewItem) {
    this.enableEdit = true;
    this.modalHeader = "Edit Category";
    this.enableEditIndex = row.value.index;

    this.isEnabled =  (row.value.category.StatusIndicator == "E") ? true : false;

    this.categoryDetail = new CMCategories();

    this.categoryDetail.id = row.value.category.Id;
    this.categoryDetail.parentCategoryName = row.value.category.ParentCategoryName;
    this.categoryDetail.parentCategoryId = row.value.category.ParentCategoryId;
    this.categoryDetail.categoryName = row.value.category.CategoryName;
    this.categoryDetail.categoryImage = row.value.category.CategoryImage;
    this.categoryDetail.title = row.value.category.Title;
    this.categoryDetail.metaDescription = row.value.category.MetaDescription;
    this.categoryDetail.keywords = row.value.category.Keywords;
    this.categoryDetail.statusIndicator = row.value.category.StatusIndicator;
    this.categoryDetail.filePath = row.value.category.FilePath;
    this.categoryDetail.modifiedDate = row.value.category.ModifiedDate;
    this.categoryDetail.createDate = row.value.category.CreateDate;
    this.categoryDetail.categoryImageChanged = row.value.category.CategoryImageChanged;

    this.compareFolderName = this.categoryDetail.categoryName;
    this.categorySelected = this.categoryDetail.parentCategoryName;

    this.changeImage = false;
    this.newCategoryImageData = new FormData();

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }

  addNewCategory(row: TreeviewItem) {
    this.modalHeader = "Add new Category";
    this.enableEditIndex = row.value.index;

    this.isEnabled = true;

    this.categoryDetail = new CMCategories();
    this.categoryDetail.statusIndicator = "E";
    this.categoryDetail.categoryName = "";
    this.categoryDetail.parentCategoryName = row.text;
    this.categoryDetail.parentCategoryId = row.value.category.Id;
    this.categoryDetail.createdBy = this.accountService.currentUser.email;
    this.categoryDetail.title = "";
    this.categoryDetail.metaDescription = "";
    this.categoryDetail.keywords = "";
    this.categoryDetail.filePath ="";

    this.categorySelected = row.text;
    this.compareFolderName = "";


    this.changeImage = false;
    this.newCategoryImageData = new FormData();

    if (!this.categoryModal) {
      setTimeout(() => {
        if (this.categoryModal) {
          this.categoryModal.show();
        }
      });
    }
    else {
      this.categoryModal.show();
    }
  }


  categoryModalHide() {
    this.categoryDetail = new CMCategories();
    this.changeParent = false;
    this.categorySelected = "";
    this.categoryModal.hide();
  }

  treemodalHide() {
  
    this.changeParent = false;
    this.newParentName = "";
    this.newParentId = 0;
    this.treeModal.hide();
  }

  saveCategory() {
    if (this.categoryDetail && this.categoryDetail.categoryName != "") {

      if (this.validateCategorySave()) {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');

        var newFileName = this.categoryDetail.categoryName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---");

        this.handleFiles(newFileName, 0);
      }

    }
    else {
      this.alertService.showMessage('Warning', `Make sure to enter a category name`, MessageSeverity.warn);
    }
  }

  deleteCategory(row: TreeviewItem) {
    console.log(row);
    if (row.value.isLasLevel) {
      if (null == row.children) {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;
        this.sysproService.getStockCodesForCategory(row.value.category.Id).subscribe(x => this.onStockCodesLoadSuccesfull(x, row, true), error => this.onDataLoadFailed(error));
      }
    }
  }

  onCategoryDeleted(x: CMCategories): void {
    this.loadData();
  }


  toggleImageUpload(type: string) {
    switch (type) {
      case "categoryImage": {
        this.changeImage = !this.changeImage;
        if (!this.changeImage) {
          this.newCategoryImageData = new FormData();
        }
        break;
      }
      case "stockCodeImage": {
        this.changeStockCodeImage = !this.changeStockCodeImage;
        if (!this.changeStockCodeImage) {
          this.newStockCodeImageData = new FormData();
        }
        break;
      }
      default: {
        break;
      }
    }
  }


   //Change Parent Category Tree Management
  changeCategoryParent() {
    this.changeParent = !this.changeParent;

    if (this.changeParent) {

      if (!this.treeModal) {
        setTimeout(() => {
          if (this.treeModal) {
            this.treeModal.show();
          }
        });
      }
      else {
        this.treeModal.show();
      }
    }
  }


  selectTreeItem(row: TreeviewItem) {
   
   
      if (this.categoryDetail.parentCategoryName != row.text) {

        if (this.categoryDetail.categoryName == row.text) {

          this.alertService.showMessage('Error', "This category is the same as the one selected", MessageSeverity.error);
        }
        else {

          if (row.value.isLasLevel) {
            //Check if row has stock codes
            this.sysproService.getStockCodesForCategory(row.value.category.Id).subscribe(x => this.onStockCodesCheckedSuccesfull(x, row, true), error => this.onDataLoadFailed(error));
          }
          else {

            this.newParentName = row.text;
            this.newParentId = row.value.category.Id;
            this.treeModal.hide();
          }
        }
      }
      else {
        this.alertService.showMessage('Error', "This parent category is the same as the one selected", MessageSeverity.error);
      }
  }


  //Stock Code Management
  onStockCodesCheckedSuccesfull(x: CMStockCode[], row: TreeviewItem, remove?: boolean): void {
   
    this.stockCodeList = x;
    let valueString = JSON.stringify(row.value);
    valueString = valueString.substr(0, valueString.length - 1);
    valueString = valueString + ', "numberOfStockCodes" : ' + x.length + '}';

    if (x.length > 0) {
      this.alertService.showMessage('Error', "This category has stock codes and can not be selected", MessageSeverity.error);
    }
    else {

      this.newParentName = row.text;
      this.newParentId = row.value.category.Id;
      this.treeModal.hide();
    }
   

  }

  
  handleFiles(newFileName: string, numberDone: number) {

    //Update Files
    if (numberDone == 0) {
      if (this.newCategoryImageData.has('file')) {
        this.auditObj.actionType = "CATEGORYIMAGE";
        this.auditObj.actionInput = this.categoryDetail.categoryImage;

        this.sysproService.postFileData(this.newCategoryImageData, "CATEGORY", newFileName).subscribe(x => {

          this.categoryDetail.categoryImage = x.fileName;
          this.categoryDetail.categoryImageChanged = new Date();
          this.auditObj.actionOutput = x.fileName;

          this.alertService.showMessage('Success', `Added Category Image`, MessageSeverity.success);

          //Call website webhook to initiate image update  
          this.http.get('https://www.fowkes.co.za/api/next-gen/update-category-image/' + this.categoryDetail.id).subscribe(x => { console.log(x); }, error => { console.log(error); });
           // (this.alertService.showMessage('Success', `Website Image Update Sent`, MessageSeverity.success)), error => this.onPostDataLoadFailed(error));

          this.handleFiles(newFileName, 1);
        }, error => this.onPostDataLoadFailed(error));
      }
      else {
        if ((!this.categoryDetail.categoryImage || this.categoryDetail.categoryImage == "")) {
          this.categoryDetail.categoryImage = "";
        }
        this.handleFiles(newFileName, 1)
      }
    }
    if (numberDone == 1) {      
      if (this.isEnabled) {
        this.categoryDetail.statusIndicator = "E";
      }
      else {
        this.categoryDetail.statusIndicator = "D";
      }

      if (!this.categoryDetail.categoryImage) {

        this.categoryDetail.categoryImage = "";
      }

      if (this.categoryDetail.parentCategoryName != this.newParentName && this.newParentName != "") {
        this.auditObj.actionType = "CATEGORYPARENTCHANGE";
        this.auditObj.actionInput = this.categoryDetail.parentCategoryName;
        this.auditObj.actionOutput = this.newParentName;

        this.categoryDetail.parentCategoryName = this.newParentName;
        this.newParentId = this.newParentId;
        this.changeParent = false;
      }
      if (this.categoryDetail.parentCategoryName == "Categories") {
        this.categoryDetail.parentCategoryName = "Root";

      }
      //Save Category
      if (!this.categoryDetail.id || this.categoryDetail.id == 0) {
        this.sysproService.addCMCategory(this.categoryDetail).subscribe(x => this.onNewCategoryAdded(x), error => this.onNewFolderAddedFailed(error));
      }
      else {
        //Edit Category
        if (this.categoryDetail.id > 0) {       
          this.sysproService.updateCMCategory(this.categoryDetail, this.categoryDetail.id).subscribe(x => this.onNewCategoryAdded(x), error => this.onNewCategoryAddedFailed(error));

        }
      }
    }
  }


  onNewCategoryAddedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  onNewCategoryAdded(x: CMCategories): void {
    if (x && x.id) {

    //  this.postAudit();
      this.expandIndex = x.id;
    }

    this.loadData();
    // Open to correct level.
    this.categoryModalHide();
   // this.stockCodeModallHide();
  }

  


  validateCategorySave() {
    let error = "";
    if (this.compareFolderName != this.categoryDetail.categoryName) {
      error = (this.categoryList.filter(x => x.categoryName.toUpperCase() == this.categoryDetail.categoryName.toUpperCase()).length > 0 ? "This category name has already been added.  " : "");
    }

    if (this.categoryDetail.statusIndicator == 'E') {
      error += ((!this.categoryDetail.categoryImage || this.categoryDetail.categoryImage == "") && (!this.newCategoryImageData || !this.newCategoryImageData.has('file')) ? "Remember to add an image for the enabled category" : "")
    }
   
    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  validateFileSave() {
    let error = "";

    //error = (this.repoDocsList.filter(x => x.documentName.toUpperCase() == this.documentDetail.documentName.toUpperCase()).length > 0 ? "This file has already been added" : "");

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }



  //Stock Code Management
  onStockCodesLoadSuccesfull(x: CMStockCode[], row: TreeviewItem, remove?: boolean): void {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.stockCodeList = x;
    let valueString = JSON.stringify(row.value);
    valueString = valueString.substr(0, valueString.length - 1);
    valueString = valueString + ', "numberOfStockCodes" : ' + x.length + '}';

    if (x.length > 0) {

      this.stockCodeList.forEach((stockCode, index, inventory) => {
        (<any>stockCode).index = index + 1;

        let stockRow = {
          "text": "(" + stockCode.stockCode + ") - " + stockCode.description,
          "value": {
            "index": stockCode.id,
            "stockCode": stockCode
          },
          "collapsed": true,
          "checked": false,
          "isLink": true
        };

        if (null == row.children) { //if there are no existing child nodes...

          row.children = [new TreeviewItem(stockRow)];  //create the collection of child nodes and set the supplied child to be the sole member

        } else {                      //else, other children exist, so the collection already exists

          row.children.push(new TreeviewItem(stockRow));//so push the child onto the collection
        }

      });

      row.value = JSON.parse(valueString);

      if (remove) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage('Error', "This category has stock codes and can not be deleted", MessageSeverity.error);
      }
    }
    else {
      if (remove) {
        this.sysproService.deleteCMCategory(row.value.category.Id).subscribe(x => this.onCategoryDeleted(x), error => this.onNewCategoryAddedFailed(error));
      }
    }

  }

  selectItem(item: TreeviewItem) {
    if (item) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = item.value.stockCode.stockCode;
      this.stockCode = this.stockCodedetail.stockCode;

      this.stockCodedetail.dataSheetImage = item.value.stockCode.dataSheetImage;
      this.stockCodedetail.specialsImage = item.value.stockCode.specialsImage;
      this.stockCodedetail.onSpecial = item.value.stockCode.onSpecial;

      if (sessionStorage.getItem(localStorageActions.StockCode) != this.stockCode) {
        sessionStorage.setItem(localStorageActions.StockCode, this.stockCode);
      }

      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }

    }

    
  }


  onAltStockCodesLoadSuccessful(altStockCodes: any[]) {
    if (altStockCodes[0]) {
      this.alternatesList = [...altStockCodes[0]];
    }
  }


  treeItemBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
      }
    }
  }

  addNewStockCode(row: TreeviewItem) {

    this.enableEditIndex = row.value.index;

    this.stockCodedetail = new Inventory();
    this.hasDataSheet = false;
    this.hasSpecials = false;
    this.hasImpaCodes = false;
    this.isEnabled = false;

    sessionStorage.removeItem(localStorageActions.StockCode);

    this.newStockCode = true;
    this.selectedCategory = row.text;

    if (!this.stockCodeModal) {
      setTimeout(() => {
        if (this.stockCodeModal) {
          this.stockCodeModal.show();
        }
      });
    }
    else {
      this.stockCodeModal.show();
    }
  }


  stockCodeModallHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
    if (this.newStockCode) {
      this.loadData();
      this.newStockCode = false;
    }
  }

  removeStockCode(row: TreeviewItem) {
    this.enableEditIndex = row.value.index;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.deleteCMStockCode(row.value.stockCode.id).subscribe(x => this.stockCodeDeleteSuccess(row.value.stockCode.stockCode), error => this.onDataLoadFailed(error));
  }


  stockCodeDeleteSuccess(deletedCode: string): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', deletedCode + ` Has been deleted successfully`, MessageSeverity.success);
    this.loadData();
  }

  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newCategoryImageData.append('file', file);
    }
  }


  removeFile(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.value.document.CategoryName).subscribe(result => this.breadcrumbs = [...result]);
    this.expandIndex = this.breadcrumbs[this.breadcrumbs.length - 1].categoryId;

    this.enableEditIndex = row.value.index;

    if (row.value.document) {
      this.sysproService.deleteRepoDoc(row.value.document.Id).subscribe(x => this.onNewFolderAdded(new DocumentCategory()), error => this.onNewFolderAddedFailed(error));
    }

  }


  newFilePostedSuccess(x: any) {
    //this.documentDetail.createdBy = this.accountService.currentUser.email;

    //this.sysproService.addNewDoc(this.documentDetail).subscribe(x => this.onNewFolderAdded(new DocumentCategory()), error => this.onPostDataLoadFailed(error));
  }

    onPostDataLoadFailed(error: any) {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    }
   

  onNewFolderAdded(x: DocumentCategory): void {

    if (x && x.id) {

      this.expandIndex = x.id;
    }

    this.loadData();
    // Open to correct level.
    this.categoryModalHide();
    this.stockCodeModallHide();
  }


  onNewFolderAddedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

  }

  removeFolder(row: TreeviewItem) {

    this.sysproService.getSimpleDocumentTree(row.text).subscribe(result => this.breadcrumbs = [...result]);
    if (row.value.category) {
      this.sysproService.deleteDocumentCategory(row.value.category.Id).subscribe(x => this.onNewFolderAdded(x), error => this.onNewFolderAddedFailed(error));
    }

  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }


  get canManageDocuments() {
    return this.accountService.userHasPermission(Permission.manageDocumentsPermission);
  }



  detailList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "LongDescription", "ExtraProdInfo", "TopSeller", "StockCodeStatus",  "Brand", "CategoryId", "CategoryName", "CategoryStatus", "ParentCategoryId", "ParentCategoryName",
        "Col1", "Col2", "Col3", "Col4", "Col5", "Col6"]
    };

    var filename = "Category Export";
    var selectRows = [...this.categoryStockCodeList];
    var exportRows = [];
    for (var row of selectRows) {
      var a = row.categoryTree.replace(/\,/g, ' ').split(" > ");

      var cols = 5;
      var res = [...Array(cols).keys()].map(c => a.filter((_, i) => i % cols === c));

      exportRows.push({
        StockCode: row.stockCode.replace(/\,/g, ' ').replace(/\;/g, ' '), Description: row.description.replace(/\,/g, ' ').replace(/\;/g, ' '),
        LongDescription: row.longDescription.replace(/\,/g, ' ').replace(/\;/g, ' '), ExtraProdInfo: row.extraProductInform, TopSeller: row.topSellerText, StockCodeStatus: row.status,
        Brand: row.brand,
        CategoryId: row.categoryId,
        CategoryName: row.categoryName, CategoryStatus: row.categoryStatus,
        ParentCategoryId: row.parentCategoryId, ParentCategoryName: row.parentCategoryName, 
        Col1: res
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  //private postAudit() {
  //  this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  //}


}
