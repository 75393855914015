
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteList } from 'src/app/models/quote-list.model';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { PurchaseOrderList } from 'src/app/models/purchaseorder-list.model';
import { User } from '../../models/user.model';


@Component({
  selector: 'syspro-salesorder-management',
  templateUrl: './syspro-salesorder-management.component.html',
  styleUrls: ['./syspro-salesorder-management.component.scss']
})
export class SysproSalesOrderManagementComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  columns: any[] = [];
  rows: SalesOrderList[] = [];
  rowsCache: SalesOrderList[] = [];
    cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;
  
    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('customerTemplate')
  customerTemplate: TemplateRef<any>;

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;
  
  @ViewChild('poTemplate')
  poTemplate: TemplateRef<any>;
  
  @ViewChild('purchaseOrderList')
  purchaseOrderList: TemplateRef<any>;

  @ViewChild('purchaseOrderListSelector')
  private purchaseOrderListSelector;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

  searchType: string = "";
  searchText: string = "";
  searchResults: SalesOrderList[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";


  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
    TotalItems: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    salesOrders: SalesOrderList[];
  salesOrderNumber: string;
  checkCreditNotes: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {    

    let action = sessionStorage.getItem(localStorageActions.Customer);
    sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
    sessionStorage.removeItem(localStorageActions.InvoiceNumber);
   // let quoteNumber = sessionStorage.getItem(localStorageActions.QuoteNumber);
  //  let salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);

  //  this.quoteNumber = quoteNumber;
   // this.salesOrderNumber = salesOrderNumber;
    this.id = action;

      this.columns = [
        { prop: 'salesOrderNumber', name: 'Sales Order', width: 90, cellTemplate: this.salesOrderTemplate},
        { prop: 'orderHistory', name: 'Description', width: 360},
        { prop: 'customerPO', name: 'Customer PO', width: 160 },
        { prop: 'customer', name: 'Customer', width: 70, cellClass: "center" },
        { prop: 'invoiceNumber', name: 'Invoice', width: 90, cellTemplate: this.invoiceTemplate },
        { prop: 'operator', name: 'Operator', width: 70, cellClass: "center" }
      ];

      this.columns.push({ name: 'Purchase Orders', width: 150, cellTemplate: this.selectTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

      this.loadData();
  }


  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.customer, r.salesOrderNumber, r.customerPO,
        r.orderStatus, r.createDate, r.invoiceNumber, r.poNumber, r.gRNNumber, r.internalRef, r.operator));
    }
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      if (this.checkCreditNotes) {
        this.sysproService.getCreditNoteSearchByCriteria(this.searchType, this.searchText.trim()).subscribe(
          x => this.onSearchSuccesful(x), error => this.onSearchFailed(error));
      }
      else {
        this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
          x => this.onSearchSuccesful(x), error => this.onSearchFailed(error));
      }
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


    onSpecificSearchSuccesful(x: SalesOrderList[]): void {

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.searchResults = x;

    }

  viewPDF(row: QuoteList) {
    console.log(row);

    this.reportService.getQuotePDF(row.quoteNumber).subscribe(x => this.getPDF(x, row.quoteNumber), error => this.onDataLoadFailed(error));
  }
  getPDF(x: Blob, quoteNumber: string): void {
    this.reportService.getFileStream(quoteNumber).subscribe(x => this.onFileSuccessful(x), error => this.onDataLoadFailed(error));
    }

  viewSalesOrder(row: SalesOrderList) {
    this.setLocalCustomer(row.customer, row.salesOrderNumber, row.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: row.customer } });
  }

  viewSalesOrderSelected() {

    if (this.searchType == "invoice") {
      if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.searchResultSelected) {
        sessionStorage.setItem(localStorageActions.InvoiceNumber, this.searchResultSelected);
      }

      this.router.navigate(['../../salesorders'], { fragment: 'invoice' });
    }
    else {
      if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.searchResultSelected) {
        sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.searchResultSelected);
      }

      this.router.navigate(['../../salesorders'], { fragment: 'view' });
    }
  }

  viewInvoice(row: SalesOrderList) {
    this.setLocalCustomer(row.customer, row.salesOrderNumber, row.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: row.customer } });
  }



  setLocalCustomer(customer: string, salesOrderNumber: string, invoiceNumber: string, purchaseorder?: string) {

    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != salesOrderNumber) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != invoiceNumber) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, invoiceNumber);
    }
    if (purchaseorder) {
      if (sessionStorage.getItem(localStorageActions.PurchaseOrder) != purchaseorder) {
        sessionStorage.setItem(localStorageActions.PurchaseOrder, purchaseorder);
      }
    }
  }


    ngAfterViewInit() {

      this.columns = [
        { prop: 'salesOrderNumber', name: 'Sales Order', width: 90, cellTemplate: this.salesOrderTemplate },
        { prop: 'orderHistory', name: 'Description', width: 360 },
        { prop: 'customerPO', name: 'Customer PO', width: 160 },
        { prop: 'customer', name: 'Customer', width: 70, cellClass: "center" },
        { prop: 'invoiceNumber', name: 'Invoice', width: 90, cellTemplate: this.invoiceTemplate },
        { prop: 'operator', name: 'Operator', width: 70, cellClass: "center" }
      ];

      this.columns.push({ name: 'Purchase Orders', width: 150, cellTemplate: this.selectTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    }
  

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }

  loadData() {
    this.searchType = "salesorder";
    this.checkCreditNotes = false;
    if (this.loadCache == true) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getSalesOrderSearchAll().subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
  }

    onSearchSuccesful(x: SalesOrderList[]): void {
      console.log(x);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      if (x.length > 0) {
        this.salesOrders = x;
        x.forEach((salesOrderNumber, index, x) => {
          (<any>salesOrderNumber).index = index + 1;
        });

        this.rowsCache = [...x];
        for (var salesOrder of this.rowsCache) {
          if (salesOrder.poNumber != "" && salesOrder.poNumber != "NA") {
            salesOrder.poNumber = "Please Select";
            this.SetPONumbers(salesOrder);
          }
        }

        this.rows = this.rowsCache.slice(0, 100);
      }
      else {
        this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
          MessageSeverity.warn);
      }
     
  }
  SetPONumbers(salesOrder: SalesOrderList) {
    this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", salesOrder.salesOrderNumber).
      subscribe(x => this.POSearchSuccesful(salesOrder, x), error => this.onPOLoadFailed(error));
  }
    onPOLoadFailed(error: any): void {
       
    }
  POSearchSuccesful(salesOrder: SalesOrderList, x: PurchaseOrderList[]): void {
    let POList = x;
      x.forEach((poNumber, index, x) => {
        (<any>poNumber).index = index + 1;
    });

    salesOrder.poNumbers = x;
    }

  onFileSuccessful(x: Blob): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = "Quote.pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
    }


  get branchCode(): string {
    return this.configurations.branchCode;
  }
    
  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    console.log(this.cart);
    
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
  }


  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }
  
  viewCustomer() {
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  viewPurchaseOrder(row: SalesOrderList) {
    console.log(row);
    this.setLocalCustomer(row.customer, row.salesOrderNumber, row.invoiceNumber, row.poNumber);
    this.router.navigate(['../../purchaseorders'], { fragment: 'purchaseorder', queryParams: { customer: row.customer } });
  }

  goAddItems() {
    this.router.navigate(['../../orders'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }
  
  goAddNSItems() {
    this.router.navigate(['../../orders'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goCreateQuote() {
    this.isNavigating = true;
    //this.save("cart");
    //if (this.cartlinenumber > 0 && this.rowsCache.filter(x => x.orderqty > 0).length <= 0) {
    //  this.router.navigate(['../../orders'], { fragment: 'cart', queryParams: { customer: this.customer, cartId: this.cartId } });
    //}
    this.sysproService.postCartToQuote(this.cartId).subscribe(_response => this.saveSuccessHelper(_response), error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(quoteNumber?: any) {

    this.cartSaved = true;

    if (quoteNumber) {
      console.log(quoteNumber[0].quoteNumber);
      this.quoteNumber = quoteNumber[0].quoteNumber;

      this.reportService.getQuotePDF(this.quoteNumber).subscribe(x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/pdf" });

        console.log(x);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "Je kar.pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
      // Object.assign(this.cartRows, cart);      
    }

    if (this.isNavigating) {
      this.router.navigate(['../orders'], { fragment: 'cart', queryParams: { customer: this.id, cartId: this.cartId } });
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', this.quoteNumber + ` Created successfully`, MessageSeverity.success);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback) {
    //  this.changesFailedCallback();
    //}
  }

  //private save(location?) {
  //  this.isSaving = true;
  //  this.alertService.startLoadingMessage('Saving changes...');

    
  //  for (var product of this.rowsCache) {
  //  }


  //}

  //viewInventory() {

  //}

  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcDisc(row: any, event) {
    var val = event.target.value;
    row.discountPercentage1 = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcTotal(row: any) {
    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }

  setDelete(row: any) {
    if (row.selected) {
      this.selectedItems.push(row.lineNumber);
    }
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
    }
}
