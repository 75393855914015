import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { Branch } from '../../../models/branch.model';
import { InvoiceTrackingProductivity } from '../../../models/invoice-tracking-productivity.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';

@Component({
  selector: 'reports-productivity',
  templateUrl: './reports-productivity-component.html',
  styleUrls: ['./reports-productivity-component.scss']
})
export class ReportsProductivityComponent implements OnInit {

  columns: any[] = [];

  loadingIndicator: boolean;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  
  rows: InvoiceTrackingProductivity[] = [];


  ranges = {
    'All Dates': [moment().subtract(5, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  show: boolean;

  selected: { startDate: Moment, endDate: Moment };

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;
  rowsCache: InvoiceTrackingProductivity[];


  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  selectedBranch: string;

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService,
    private configurations: ConfigurationService) {
  }

  ngOnInit() {

    this.columns = [
      { prop: 'branch', name: 'WH', width: 50, cellClass: "left" },
      { prop: 'userName', name: 'User Name', width: 200, cellClass: "left" },
      { prop: 'totalInvoicesPicked', name: 'Invoices Picked', width: 200, cellClass: "right" },
      { prop: 'invoiceLinesPicked', name: 'Invoice Lines Picked', width: 200, cellClass: "right" },
      { prop: 'totalInvoicesChecked', name: 'Invoices Checked', width: 200, cellClass: "right" },
      { prop: 'invoiceLinesChecked', name: 'Invoice Lines Checked', width: 170, cellClass: "right" }

    ]

    this.selected = {
      startDate: moment().subtract(1, 'days'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.dateFilterChanged();

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));
  }


  branchSelected() {

    if (this.selectedBranch != "ALL") {

      this.rows = this.rowsCache.filter(x => x.branch == this.selectedBranch);
    }
    else {
      this.rows = this.rowsCache;
    }
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });

    this.selectedBranch = this.branchCode;
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  onReportLoadSuccessful(result: InvoiceTrackingProductivity[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    if (result && result.length >= 1 && result[0].userName != '') {

      this.rows = result;
      this.rowsCache = this.rows;

      this.branchSelected();
    }
    else {     

      this.rows = null;
      this.rowsCache = this.rows;
      this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
        MessageSeverity.error);
    }

  }

  onReportLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

      this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);


    }
   
  toggle() {
    this.show = true;
  }

  dateFilterChanged() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());
   // end.setHours(23, 59, 59, 999);
    this.sysproService.getInvoiceTrackingProductivity(startD.toJSON(), endD.toJSON()).subscribe(x => this.onReportLoadSuccessful(x), error => this.onReportLoadFailed(error));

  }


  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.dateFilterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();
    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }


    this.dateFilterChanged();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.dateFilterChanged();
    this.show = false;
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Branch", "UserName", "TotalInvoicesPicked", "InvoiceLinesPicked", "TotalInvoicesChecked", "InvoiceLinesChecked"]
    };

    var filename = "UserProductivity";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Branch: row.branch,
        UserName: row.userName, TotalInvoicesPicked: row.totalInvoicesPicked, InvoiceLinesPicked: row.invoiceLinesPicked,
        TotalInvoicesChecked: row.totalInvoicesChecked, InvoiceLinesChecked: row.invoiceLinesChecked
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
