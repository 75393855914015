
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { ArNarration } from 'src/app/models/arnarration.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { BootstrapToggleDirective } from 'src/app/directives/bootstrap-toggle.directive';
import { WHAvailQTY } from 'src/app/models/inventory-wh.model';
import { Email } from 'src/app/models/email.model';
import { validation } from 'src/app/services/validation.service';
import { User } from '../../models/user.model';
import * as moment from 'moment';
import { ShippingInstr } from '../../models/shipping-instructions.model';
import { PurchaseOrderList } from '../../models/purchaseorder-list.model';
import { CoreAudit } from '../../models/core-audit.model';
import { CustomerPOList } from '../../models/customer-po-list.model';
import * as _ from 'lodash';


@Component({
  selector: 'syspro-cart-management',
  templateUrl: './syspro-cart-management.component.html',
  styleUrls: ['./syspro-cart-management.component.scss']
})
export class SysproCartManagementComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  auditObj = new CoreAudit();
  columns: any[] = [];
  rows: CartDetail[] = [];
  rowsCache: CartDetail[] = [];
  cartComments: CartDetail[] = [];
  cartRows: CartDetail[] = [];
  editedCustomer: ArCustomerEdit;
  sourceCustomer: ArCustomerEdit;
  editingCustomerName: { customer: string };
  loadingIndicator: boolean;
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  stockCodedetail: Inventory;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;
  customerNotes: ArNarration[];
  gpPercentage: number;

  @ViewChild('netPriceCheck')
  netPriceCheck: BootstrapToggleDirective;

  @ViewChild('commentsModal')
  commentsModal: ModalDirective;
  @ViewChild('commentsSelector')
  private commentsSelector;

  commentLine: CartDetail = new CartDetail();
  comments: string[];
  newComment: string;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  emailObject: Email = new Email();

  @ViewChild('emailModal')
  emailModal: ModalDirective;


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;


  //@ViewChild('stockCodeModal')
  //stockCodeModal: ModalDirective;

  @ViewChild('notesModal')
  notesModal: ModalDirective;


  @ViewChild('gpModal')
  gpModal: ModalDirective;

  @ViewChild('customerEditor')
  customerEditor: SysproCustomerViewComponent;
  inventoryList: Inventory[];
  customerPOList: CustomerPOList[] = [];


  @ViewChild('shipDate')
  private shipDate;

  @ViewChild('shippingInstr')
  private shippingInstr;

  @ViewChild('shippingInstrSelector')
  private shippingInstrSelector;
  shippingInstructionsList: ShippingInstr[] = [];

  @ViewChild('authModal')
  authModal: ModalDirective;

  authorized: boolean;
  stringToCompare: string;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  stockCode: string;

  itemId: string;

  cartId: string;
  id: string;
  gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
  TotalExcl: number = 0;
  TotalDiscount: number = 0;
  TotalExclAfterDiscount: number = 0;
  TotalVat: number = 0;
  TotalIncl: number = 0;
  isSaving: boolean;
  selectedItems: any;
  isNavigating: boolean;
  cartSaved: boolean;
  quoteNumber: any;
  quoteUpdated: boolean;
  nettPriceSet: boolean = false;

  hasChanged: boolean = false;
  emptyDetails: boolean;
  hasNettPrices: boolean;
  InventoryRow: Inventory[];

  private isEditMode = false;
  saveCount: number;
  savedLineCount: number;

  itemDescription: string = "";
  startDate = new Date().toISOString().slice(0, 16);
  customer: ArCustomer;
  hasBuyouts: boolean;
  placePurchaseOrder: boolean;
  showGPWarning: boolean;
  SysproUser: User;
  TotalGPPercent: number;
  TotalCost: number;
  GPGrid: CartDetail[];
  vatPercentage: number = 0;
  src: string;
  salesOrderNumber: any;
  placePurchaseOrderOnly: boolean;
  quoteId: string;
  //isNewQuote: boolean;
  isCopiedQuote: boolean;
  //  maintainQuote: boolean;
  poValidated: boolean;
  vatExempt: boolean;

  hasDataSheets: boolean;
  hasAddDataSheets: boolean;
  attachDataSheets: boolean;
  attachSpecials: boolean;
    ready: boolean;
    groupKey: string;
    isPosting: boolean;
    buyerResponsible: string;
    isWebOrder: boolean = false;
    SysproTotal: number;
    isWebQuote: boolean;
    firstTimeLoad: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router,
    private route: ActivatedRoute) {
  }


  ngOnInit() {

    const action = sessionStorage.getItem(localStorageActions.Customer);
    const cartId = sessionStorage.getItem(localStorageActions.CartId);
    const reset = sessionStorage.getItem(localStorageActions.Reset);
    this.cartId = cartId;
    this.id = action;
    this.auditObj.referenceType = "CART";
    this.auditObj.referenceNumber = this.cartId;
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.accountService.currentUser.email;
    this.auditObj.actionedBy = this.accountService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;
    this.postAudit();

    if (reset && reset == "true") {
      this.isCopiedQuote = true;
    }
    else {
      this.isCopiedQuote = false;
    }

    this.firstTimeLoad = true;

    this.sysproService.getShippingInstructions().subscribe(x => this.onShippingInstructionsLoadSuccessful([x]), error => this.onShippingInstructionsFailed(error));
    
  }


  ngAfterViewInit() { }


  loadData(detailOnly?: boolean) {
    if (this.cartId) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      if (!detailOnly) {
        this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onStockLoadSuccessful([x]), error => this.onDataLoadFailed(error));
        this.sysproService.getCustomerDetail(this.id).subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
        this.sysproService.getCustomerPOList(this.id).subscribe(x => this.onCustomerPOLoadSuccessful(x), error => this.onDataLoadFailed(error));
        this.sysproService.GetSysproComments().subscribe(x => this.onCommentsLoadSuccessful(x), error => this.onDataLoadFailed(error));
        this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
      }
      else {
        this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
      }



    }

  }


  private onDataLoadSuccessful(customer: ArCustomer) {
    this.alertService.stopLoadingMessage();
    this.customer = customer;
    this.ready = true;

  }

  onCustomerPOLoadSuccessful(x: CustomerPOList[]): void {
    this.customerPOList = x;
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    this.cart.branch_code = this.branchCode;
    this.startDate = moment().format('YYYY-MM-DD');
    this.cart.shipDate = new Date(this.startDate);

    if (this.cart.taxStatus.trim() == 'E') {
      this.vatExempt = true;
      this.vatPercentage = 0;
    }
    else {
      this.vatExempt = false;
    }

    //If this is a copied WEB Quote but not a web order, show discounts
    if (this.cart.quotedFrom && this.cart.quotedFrom.trim().startsWith("WEB")) {
      this.isWebQuote = true;
    }

    if (this.isCopiedQuote) {
      this.hasNettPrices = false;
      this.cart.hasNettPrices = false;
      this.cart.specialInstrs = "";
    }
    else {
      this.hasNettPrices = this.cart.hasNettPrices;
      if (this.cart.specialInstrs = "Normal Delivery") {
        this.cart.specialInstrs = "";
      }
    }
    if (this.cart.shippingInstrs.trim() === "") {
      this.cart.shippingInstrs = "01 - DELIVER VIA FOWKES CT BAKKIE";
      //let clevertsring = (this.application == "FowkesOnline" ? "FOWKES" : "MAX");
      var bestMatch = this.shippingInstructionsList.find(t => t.instrText.includes(this.cart.branch_code + " BAKKIE"));
      if (bestMatch) {
        this.cart.shippingInstrs = bestMatch.instrText;
      }
    }
  
    console.log("CART HEADER LOADED", this.cart);

    this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onShippingInstructionsFailed(error: any): void {
    throw new Error("Method not implemented.");
  }
  onShippingInstructionsLoadSuccessful(x: any[]): void {
    var testlist = x[0];
    this.shippingInstructionsList = testlist;

    setTimeout(() => {
      if (this.shippingInstrSelector) {
        this.shippingInstrSelector.refresh();
      }
    });
    this.loadData();
    console.log("THIS IS THE SHIPPING INSTRUCTIONS", this.shippingInstructionsList);
  }


  onCartDetailLoadSuccessful(cartDetail: any[]) {
    this.placePurchaseOrder = false;
    if (cartDetail[0]) {

      this.rowsCache = [...cartDetail];
      const updateRows = this.rowsCache;

      this.TotalLines = updateRows.filter(x => x.lineType !== "6").length;
      if (this.TotalLines >= 1) {

        this.setNettPrice(false);
        this.rows = cartDetail;
        this.hasBuyouts = cartDetail.some(x => x.lineType === "7");
        this.placePurchaseOrder = this.hasBuyouts;
        this.isWebOrder = (this.id && this.id == "FBWEB") ? true : false;
        if (this.isWebOrder === true) {
          this.hasBuyouts = false;
          this.placePurchaseOrder = false;

          //Check if copied from Web Quote
          if (this.isWebQuote && this.firstTimeLoad) {
            //Calculate discount percentage for each line
            this.setWebDiscountPrice();
            this.rows = cartDetail;
            //Save discounts applied
            this.save();
            this.firstTimeLoad = false;
          }
        }
                     
        if (!this.vatExempt) {
          this.vatPercentage = updateRows.filter(x => x.lineType !== "6")[0].vatPercentage;
        }
      }
      else {

        this.alertService.showStickyMessage('Load Error', 'You have no items in your cart', MessageSeverity.warn);
      }

    }
    else {
      this.emptyDetails = true;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  onCommentsLoadSuccessful(x: string[]): void {
    this.comments = x;
    setTimeout(() => {
      if (this.commentsSelector) {
        this.commentsSelector.refresh();
      }
    });

    console.log("COMMENTS", this.comments);
  }

  openCommentsModal() {
    this.commentsModal.show();
    this.commentLine = new CartDetail();
    this.commentLine.commentType = "I";
    console.log("COMMENT LINE", this.commentLine);
  }

  addTopComment() {
    const lineNumber = 0;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---");
    this.sysproService.updateCartComments(this.cartId, lineNumber, 0, comment, "TOP")
      .subscribe(x => this.loadData(), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }

  addComment() {
    const lineNumber = this.rows[this.rows.length - 1].cartLineNumber + 1;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---");
    let isPreviousLineAComment = (this.rows[this.rows.length - 1].lineType == "6") ? "Y" : "N";
    console.log("COMMENT LINE", comment);
    this.sysproService.updateCartComments(this.cartId, lineNumber, 0, comment, isPreviousLineAComment)
      .subscribe(x => this.loadData(true), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  viewCustomer() {
    this.isNavigating = true;
    this.save();
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goAddItems() {
    this.isNavigating = true;
    this.save();
    this.router.navigate(['../../orders'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goAddNSItems() {
    this.isNavigating = true;
    this.save();
    this.router.navigate(['../../orders'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goCreateQuote() {
    this.isSaving = true;
    this.isNavigating = true;
    this.saveQuote();
  }

  private edit() {
    this.isEditMode = true;
    this.authorized = false;
    for (var product of this.rowsCache) {
      product.sellingPrice = product.originalPrice;
      product.discountPercentage1 = product.originalDiscount;
    }
  }

  private cancel() {
    this.isEditMode = false;
  }

  saveSuccessHeaderHelper(): void {
    this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.hasChanged = false;
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', ` Nett Pricing Updated Succesfully`, MessageSeverity.success);
  }

  private saveSuccessHelper() {

    this.savedLineCount++;
    if (this.saveCount === this.savedLineCount) {
      this.cartSaved = true;

      this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));

      this.isSaving = false;
      this.isEditMode = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', ` Updated successfully`, MessageSeverity.success);
    }
    else {

    }
  }
  saveQuoteSuccessHelper(quoteNumber?: any) {
    if (quoteNumber) {
      this.quoteNumber = quoteNumber[0].quoteNumber;
      sessionStorage.setItem(localStorageActions.QuoteNumber, this.quoteNumber);
      this.auditObj.actionOutput = "Created Quote: " + this.quoteNumber;
      this.postAudit();

      //Update Quote Totals
      this.auditObj.referenceType = "QUOTE";
      this.auditObj.referenceNumber = this.quoteNumber;
      this.auditObj.actionInput = "UPDATE TOTALS: TotalIncl: " + this.TotalIncl + " TotalExcl: " + this.TotalExcl + " TotalDiscount: " + this.TotalDiscount;
      this.sysproService.postQuoteTotals(this.quoteNumber, this.TotalIncl, this.TotalExcl, this.TotalDiscount).subscribe(_response => this.updateSuccessHelper(_response), error => this.saveFailedHelper(error));


      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', ` Quote Created Succesfully`, MessageSeverity.success);
    }
  }

  private updateSuccessHelper(quoteNumber?: any) {

    this.quoteUpdated = true;
    this.auditObj.actionOutput = "Updated Totals " + this.quoteNumber;
    this.postAudit();
    this.alertService.showMessage('Success', this.quoteNumber + ` Updated successfully`, MessageSeverity.success);
    //Clear Cart
    sessionStorage.removeItem(localStorageActions.CartId);
    this.router.navigate(['../quotes'], { fragment: 'quote', queryParams: { customer: this.id, quoteNumber: this.quoteNumber } });
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  validatePost(): boolean {
    let error = "";

    error = validation.validateCustomerOnHold(this.customer.customerOnHold, this.customer.creditLimit, this.customer.currentBalance1, error);
    if (error == "") {
      error = validation.validateCustomerPO(this.cart.customerPoNumber, error);
      if (error == "" && !!this.customerPOList[0] && !this.poValidated) {
        this.validatePO();
        this.isSaving = false;
        return false;
      }
      else {
        error = validation.validateSalesPerson(this.cart.salesperson, error);
        error = validation.validateShippingInstructions(this.cart.shippingInstrs, error);

        for (var product of this.rows) {
          if (product.lineType == "1") {
            error = validation.validateQtyForOrder(product.stockCode, product.availQty, product.orderQty, error);
          }
        }
      }
    }

    if (error.length > 1) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }

  validatePO() {
    //Compare customer PO list
    let exactMatch = this.customerPOList.filter(x => x.customerPoNumber == this.cart.customerPoNumber);
    if (exactMatch.length >= 1) {
      if (exactMatch.length == 1) {
        this.alertService.showDialog('This PO has been used before on Invoice  \"' + exactMatch[0].invoice + '\" do you want to continue?', DialogType.confirm, () => this.continueSaveHelper(), this.tryAgainHelper());

      }
      else {
        this.alertService.showDialog('This PO has been used before on \"' + exactMatch.length.toString() + '\" invoices, do you want to continue?', DialogType.confirm, () => this.continueSaveHelper(), this.tryAgainHelper());

      }
    }
    else {
      let partialString = this.cart.customerPoNumber.length > 4 ? this.cart.customerPoNumber.substr(0, 5) : this.cart.customerPoNumber;
      let partialMatch = this.customerPOList.filter(r => Utilities.searchArray(partialString, false, r.customerPoNumber));
      if (partialMatch.length >= 1) {

        this.alertService.showDialog('This PO is similar to PO \"' + partialMatch[0].customerPoNumber + '\" , do you want to continue?', DialogType.confirm, () => this.continueSaveHelper(), this.tryAgainHelper());

      }
      else {
        partialMatch = this.customerPOList.filter(r => Utilities.searchArray(this.cart.customerPoNumber, false, r.customerPoNumber));
        if (partialMatch.length >= 1) {

          this.alertService.showDialog('This PO is similar to PO \"' + partialMatch[0].customerPoNumber + '\" , do you want to continue?', DialogType.confirm, () => this.continueSaveHelper(), this.tryAgainHelper());

        }
        else {
          this.continueSaveHelper();
        }
      }

    }
  }


  continueSaveHelper(): any {
    this.poValidated = true;
    this.goPlaceOrder();
  }

  tryAgainHelper(): any {
    this.poValidated = false;
    this.isPosting = false;
  }

  ValidateSave(row: CartDetail): boolean {
    let error = "";
    let lineValid = this.authorized;
    if (row.lineType !== "6") {
      if (!lineValid) {
        error = validation.minSpecialPrice(row.unitCost, row.nettPrice, error);
        if (error.length > 1) {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
          this.authorized = false;
          this.authModal.show();
          return false;
        }
        else {
          lineValid = true;
        }
      }
      if (lineValid) {
        error = validation.qty(row.orderQty, error);
        error = validation.uom(row.orderUom, error);
        error = validation.price(row.sellingPrice, error);
        error = validation.discount(row.discountPercentage1, error);
        error = validation.cost(row.unitCost, error);

        if (error.length > 1) {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showMessage('Error', error + 'For item ' + row.stockCode, MessageSeverity.error);
          return false;
        }
        else {
          return true;
        }
      }
    }
    else {
      return true;
    }
  }

  verifyAuth() {
    this.alertService.startLoadingMessage("Verfiying...");
    this.loadingIndicator = true;
    this.fowkesService.VerifyPassword(this.branchCode, "PRICE", encodeURIComponent(this.stringToCompare)).subscribe(x => this.onVerificationSuccess(), error => this.onVerificationFailed(error));

  }
  onVerificationSuccess(): void {
    this.authorized = true;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
    this.authModal.hide();
    this.save();
  }

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }
  //viewInventory() {

  //}

  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    //let total = 0;
    //let discount = (row.discountPercentage1 && row.discountPercentage1 >= 0 ? row.discountPercentage1 : 0);

    //if (discount > 0) {
    //  total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, discount, total);
    //}
    //else {
    //  let specialUnit = row.specialPrice * row.priceConvFac;
    //  total = Calculations.getGridLineTotal(row.orderQty, specialUnit, row.priceConvFac, discount, total);
    //}
    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
  }


  calcDisc(row: any, event) {
    var val = event.target.value;
    row.discountPercentage1 = val;
    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);

    row.specialPrice = Calculations.getGridLineTotal(1, row.sellingPrice, row.priceConvFac, row.discountPercentage1, total);
    row.lineTotal = total;
  }


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;
    let discount = (row.discountPercentage1 && row.discountPercentage1 >= 0 ? row.discountPercentage1 : 0);
    let qty = (row.orderQty && row.orderQty >= 0 ? row.orderQty : 0);
    let total = 0;

    row.specialPrice = Calculations.getGridLineTotal(1, row.sellingPrice, row.priceConvFac, discount, total);

    total = Calculations.getGridLineTotal(qty, row.sellingPrice, row.priceConvFac, discount, total);


    //(row.orderqty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountpercentage1) / 100);
    row.lineTotal = total;
    //let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    //row.lineTotal = total;
  }


  calcTotal(row: any) {
    let total = 0;
    row.nettDiscount = 0;
    row.nettPrice = Calculations.getGridNettLinePrice(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
     total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
  

    //let discount = (row.discountPercentage1 && row.discountPercentage1 >= 0 ? row.discountPercentage1 : 0);

    //if (discount > 0) {
    //  total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, discount, total);
    //}
    //else {
    //  //let specialUnit = row.specialPrice * row.priceConvFac;
    //  total = Calculations.getGridLineTotal(row.orderQty, row.specialPrice, row.priceConvFac, discount, total);
    //}
    row.lineTotal = total;
    //row.gP = Calculations.precise_round(row.gP, 2);
  }


  deleteRow(row: CartDetail) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.stockCode + ' - ' + row.stockDescription + '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: CartDetail) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.auditObj.actionType = "DELETE";
    this.auditObj.actionInput = row.cartNumber + ": Line" + row.cartLineNumber + ": Description" + row.stockDescription;

    this.fowkesService.cartDetailDelete(row.cartNumber, row.cartLineNumber).subscribe(results => {
      this.sysproService.getCartDetail(this.cartId, this.branchCode).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
      //this.auditObj.actionOutput = "Deleted Successfully";
      //this.postAudit();
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.stockCode + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        //this.auditObj.actionOutput = "Delete Failed";
        //this.postAudit();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  private saveQuote() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Creating Quote...');
    this.auditObj.actionType = "UPDATE";
    this.auditObj.actionInput = this.cart.cartNumber;
    //Update the cart header
    if (this.cart.customerPoNumber) {

      this.cart.customerPoNumber = this.cart.customerPoNumber.toUpperCase();
    }

    //Add cut to zize comment
    this.fowkesService.newCart(this.cart).subscribe(_response => this.saveCartSuccessHelper(this.cart.cartNumber, true), error => this.saveFailedHelper(error));
  }

  saveCartSuccessHelper(cartNumber: string, createQuote?: boolean): void {
    this.auditObj.actionOutput = "UPDATED";

    this.alertService.showMessage('Success', `Updated cart successfully ` + cartNumber, MessageSeverity.success);
    if (createQuote) {
      this.auditObj.actionType = "CONVERT TO QUOTE";
      this.auditObj.actionInput = this.cartId;
      this.sysproService.postCartToQuote(this.cartId).subscribe(_response => this.saveQuoteSuccessHelper(_response), error => this.saveFailedHelper(error));
    }
  }


  goPlaceOrder() {
    if (!this.isPosting) {
      if (this.validatePost()) {
        //Check SYSPRO User
        this.isPosting = true;
        this.alertService.startLoadingMessage('Loggin into Syspro...');
        this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onsessionCreatedSuccesful(x), error => this.onSessionFailed(error));
      }
    }    
  }


  onsessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);


    this.alertService.startLoadingMessage('Creating Sales Order...');
    this.cart.shipDate = new Date(this.startDate);

    this.SysproUser = x;
    this.auditObj.sysproOperator = this.SysproUser.sysproOperator;    

    const header = this.cart;
    const detail = this.rows.filter(r => r.lineType != "6");

    if (this.SysproUser.sysproSessionId) {
      this.auditObj.actionOutput = this.SysproUser.sysproSessionId;
      this.postAudit();
     
      if (!this.placePurchaseOrderOnly) {
        const postObject = {
          "user": this.SysproUser, "header": header, "detail": detail
        };
        console.log("POSTING", postObject);
        this.auditObj.actionType = "PLACE ORDER";
        this.auditObj.actionInput = JSON.stringify(postObject);
        this.sysproService.postSalesOrderSyspro(postObject).subscribe(x => this.onSoirtoiPOSTSuccesful(x), error => this.onSoirtoiPOSTFailed(error));
      }
      else {
        this.newMethod(this.salesOrderNumber);
      }
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }

  }
  onSoirtoiPOSTSuccesful(x: any): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    var salesOrderHeader = x[0];

    if (salesOrderHeader) {
      this.alertService.showMessage('Success', `Created Sales Order`, MessageSeverity.success);
      sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderHeader.salesOrderNumber);
      this.salesOrderNumber = salesOrderHeader.salesOrderNumber;

      //Handle Comments
      const commentCount = this.rows.filter(x => x.lineType === "6").length;
      if (commentCount >= 1) {
        this.cartComments = this.rows.filter(x => x.lineType === "6");
        console.log("Handle Comments", commentCount);
        this.postComments(null, commentCount, 0);
      }
      else {
        //Handle Purchase Order
        this.newMethod(salesOrderHeader.salesOrderNumber);
      }
     
    }
    else {
      console.log("POST RESPONSE", x);
      this.isSaving = false;
      this.isPosting = false;
      this.alertService.showStickyMessage('Failed', 'Creating Sales Order Failed ', MessageSeverity.error);
      this.alertService.showStickyMessage(x.message, null, MessageSeverity.error);
    }

  }

  postComments(result: any, commentCount: number, lineNumber: number) {
    if (commentCount > lineNumber) {
      let product = this.cartComments[lineNumber];
      this.sysproService.InsertSalesOrderComments(this.salesOrderNumber, product.cartLineNumber, product.comment.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"),
        product.commentFromLineNumber, product.commentType, false).subscribe(x => this.postComments(x, commentCount, lineNumber + 1), error => this.onCommentFailed(error));
    }
    else {
      //Handle Purchase Order
      this.newMethod(this.salesOrderNumber);
    }
  }

  onCommentFailed(error: any): void {
    console.log(error);
    //Handle Purchase Order
    this.newMethod(this.salesOrderNumber);
  }


  private newMethod(salesOrder: string) {
    console.log("Handle Purchase Order:", this.hasBuyouts);
    if (this.hasBuyouts) {
      this.alertService.startLoadingMessage('Staging Purchase Order...');
      this.auditObj.referenceType = "SALES ORDER PO";
      this.auditObj.referenceNumber = salesOrder;
      this.auditObj.actionType = "STAGE PURCHASE ORDER";
     
      const user = this.SysproUser;
      const filteredRows = this.rows.filter(x => x.lineType === "7");
      const flags = {};
      const newPlaces = filteredRows.filter(function (entry) {
        if (flags[entry.boSupplierCode]) {
          return false;
        }
        flags[entry.boSupplierCode] = true;
        return true;
      });
      console.log(newPlaces);
      console.log(flags);

      this.alertService.startLoadingMessage('Loggin into Syspro...');
      this.sysproService.GetSysproSessionId(this.accountService.currentUser, true).subscribe(x => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);
        this.SysproUser = x;
        if (this.SysproUser && this.SysproUser.sysproBuyer && this.SysproUser.sysproBuyer != '') {
          for (const supplier in flags) {
            const header = this.cart;
            console.log(supplier);
            const detail = filteredRows.filter(x => x.boSupplierCode === supplier);
            const postObject = {
              "user": this.SysproUser, "header": header, "detail": detail, "poResult": new PurchaseOrderList()
            };
            this.auditObj.actionInput = "SUPPLIER :" + supplier + "Values: " + JSON.stringify(postObject);
            this.sysproService.InsertPurchaseOrderStaging(postObject, salesOrder, supplier).subscribe(x => this.onPOSaveSuccesful(x, salesOrder, supplier, postObject), error => this.onPortoiPOSTFailed(error));

          }
        }
        else {
          this.alertService.showStickyMessage('Error', 'Buyer code invalid for placing purchase orders', MessageSeverity.warn);
        }
        
      }, error => this.onSessionFailed(error));
    }
    if (!this.placePurchaseOrder) {

      //clean up cart
      sessionStorage.removeItem(localStorageActions.CartId);    
      this.isNavigating = true;
      this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: this.id, salesorder: this.salesOrderNumber} });

    }
  }
  onPOSaveSuccesful(x: PurchaseOrderList, salesOrder: string, supplier: string, postObject: any): void {
    console.log("Purchase Order staging saved", this.placePurchaseOrder);
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage('Success', 'Purchase Order staging saved:', MessageSeverity.success);
      this.auditObj.actionOutput = "Purchase Order staging saved";
      this.postAudit();
      if (this.placePurchaseOrder)
      {
        this.alertService.startLoadingMessage('Posting Purchase Order...');
        this.auditObj.actionType = "POST PURCHASE ORDER";
        postObject = {
          ...postObject, "poResult": x[0]
        }
        console.log(postObject);
        this.auditObj.actionInput = "SUPPLIER :" + supplier + "Values: " + JSON.stringify(postObject);
        this.sysproService.PostPurchaseOrderToSyspro(postObject).subscribe(x => this.onPortoiPOSTSuccesful(x), error => this.onPortoiPOSTFailed(error));

      }
    }

  onSessionFailed(error: any): void {
    this.isSaving = false;
    this.isPosting = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'Could not log you into SYSPRO', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }


  onSoirtoiPOSTFailed(error: any): void {
    this.isSaving = false;
    this.isPosting = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'The below errors occured whilst placing Sales Order:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
  onPortoiPOSTSuccesful(x: any): void {
    console.log("PO POSTED", x);
    this.placePurchaseOrderOnly = false;
    this.alertService.stopLoadingMessage();
    var poHeader = x[0];
    if (poHeader) {

      this.alertService.showStickyMessage('Success', 'Purchase Order Posted to Syspro:', MessageSeverity.success);
      this.auditObj.actionOutput = "Purchase Order Posted";
      this.postAudit();
      this.isNavigating = true;
      console.log("PO SalesOrder", x[0].salesOrder);
      sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, poHeader.salesOrder);

      //clean up cart
      sessionStorage.removeItem(localStorageActions.CartId);
      this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: this.id, salesorder: x[0].salesOrder } });
    }
    else {
      this.isPosting = false;
      this.isSaving = false;
      this.alertService.showStickyMessage('Failed', 'Creating Purchase Order Failed ', MessageSeverity.error);
      this.alertService.showStickyMessage(x.message, null, MessageSeverity.error);
      this.alertService.showStickyMessage('WARNING', 'Sales Order ' + this.salesOrderNumber + ' has already been created, placing order again will only create the PO', MessageSeverity.info);
      this.placePurchaseOrderOnly = true;
    }

  }
  onPortoiPOSTFailed(error: any): void {
    this.isPosting = false;
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'The below errors occured whilst placing Purchase Order:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
   
  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    if (this.isCopiedQuote) {
      //Make sure to clear copied quote flag to prevent resetting of peices again
      sessionStorage.removeItem(localStorageActions.Reset);
      this.isCopiedQuote = false;
    }

    if (this.cart.customerPoNumber) {

      this.cart.customerPoNumber = this.cart.customerPoNumber.toUpperCase();
    }


    this.fowkesService.newCart(this.cart).subscribe(_response => this.saveCartSuccessHelper(this.cart.cartNumber, false), error => this.saveFailedHelper(error));

    //Update the cart header if nett pricing has been switched on or off
    if (this.hasChanged) {
      this.sysproService.updateCartStatus(this.cart.cartNumber, this.cart.hasNettPrices).subscribe(_response => this.saveSuccessHeaderHelper(), error => this.saveFailedHelper(error));
    }
    this.savedLineCount = 0;
    this.saveCount = this.rows.filter(x => x.lineType !== "6" || x.selected == true).length;
    for (var product of this.rowsCache) {
      //Find each updated row, validate and update cart details
      if (product.selected) {
        this.fowkesService.cartDetailDelete(product.cartNumber, product.cartLineNumber).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
      }
      else {
        if (product.lineType !== "6") {

            //Set Prices
            if (this.cart.hasNettPrices) {
              product.sellingPrice = product.nettPrice;
              product.discountPercentage1 = product.nettDiscount;
            }
            else {
              if (this.isEditMode) {
                product.originalPrice = product.sellingPrice;
                product.originalDiscount = product.discountPercentage1;
              }
              else {
                product.sellingPrice = product.originalPrice;
                product.discountPercentage1 = product.originalDiscount;
              }
            }
            if (product.lineType == "7") {

              product.unitCost = Calculations.setUnitCostDecimals(product.unitCost);
          }

          if (this.ValidateSave(product)) {
           // product.sellingPrice = (product.discountPercentage1 > 0 ? product.sellingPrice : product.specialPrice);
            this.fowkesService.cartDetail(product, product.cartNumber).subscribe(_response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
          }
          //else {
          //  this.savedLineCount++;
          //}
        }
      }
    }   
  }

 

  setNettPrice(hasChanged?: boolean) {
    this.hasChanged = hasChanged;
    this.nettPriceSet = this.cart.hasNettPrices;
    let resetPrice = this.isCopiedQuote;
    

    this.TotalItems = 0;
    this.TotalExcl = 0;
    this.TotalDiscount = 0;
    this.TotalExclAfterDiscount = 0;
    this.TotalVat = 0;
    this.TotalIncl = 0;
    this.TotalCost = 0;
    this.SysproTotal = 0;
    this.TotalGPPercent = 0;
    var sumOfLineDiscount = 0;
    var roundedTotalExclForVatCalc = 0;

    const updateRows = [...this.rowsCache];

    for (var product of updateRows) {
      if (product.lineType == '1' && resetPrice) {
        let sysproPrice = product.currentPrice;
       // product.discountPercentage1 = product.originalDiscount;
        product.discountPercentage1 = product.originalDiscount;
        product.originalPrice = sysproPrice;
        product.sellingPrice = sysproPrice;
        product.nettPrice = sysproPrice;
      }

      if (product.lineType == "7") {
        product.unitCost = Calculations.setUnitCostDecimals(product.unitCost);
      }

      var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.originalPrice, product.priceConvFac, product.lineTotal);
      var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.originalPrice, product.originalDiscount, product.priceConvFac, 0);
      var lineTotalNettPrice = Calculations.calculateNettLinePrice(product.orderQty, product.sellingPrice, product.priceConvFac, lineTotalExclVat, lineDiscount);
      //var lineTotalNettPrice = Calculations.calculateNettLinePriceWithDiscPerc(product.orderQty, product.originalPrice, product.priceConvFac, lineTotalExclVat, product.originalDiscount);
      var lineTotalNett = Calculations.calculateBaseLineTotal(product.orderQty, lineTotalNettPrice, product.priceConvFac, product.lineTotal);
      var lineSysproLineTotal = Calculations.calculateLineSysproTotal(product.orderQty, product.originalPrice, product.originalDiscount, product.priceConvFac, 0);

      //product.unitPrice = (lineTotalExclVat > 0 ? Calculations.RoundAwayFromZero(((lineTotalExclVat - lineDiscount) / product.orderQty), 2) : 0);
      product.unitPrice = (lineTotalExclVat > 0 ? ((lineTotalExclVat - lineDiscount) / product.orderQty) : 0);

      var test = product.specialPrice;

      var lineTotalGpPercent = Calculations.calculateGPLineTotal(product.unitCost, product.specialPrice , product.priceConvFac, product.lineTotal);
      if (lineTotalGpPercent < 20) {
        this.showGPWarning = true;
      }

      this.TotalCost += (product.unitCost * product.orderQty);

      this.TotalItems += product.orderQty;
      if (this.nettPriceSet) {

        this.TotalExcl += lineTotalNett;
        this.TotalDiscount = 0;
      }
      else {
        this.TotalExcl += lineTotalExclVat;
        sumOfLineDiscount += lineDiscount;
        this.SysproTotal += lineSysproLineTotal;
        
      }

      var lineVat = 0;
      if (product.vatPercentage && product.vatExempt == false) {
        lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, product.vatPercentage);
      }

      this.TotalVat += lineVat;    

      this.calcTotal(product);

    }

    //this.TotalExclAfterDiscount = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero( this.TotalDiscount, 2));
    //this.TotalIncl = Calculations.RoundAwayFromZero((this.TotalExclAfterDiscount + this.TotalVat), 2);

    roundedTotalExclForVatCalc = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero(sumOfLineDiscount, 2));
    this.TotalExclAfterDiscount = this.TotalExcl - sumOfLineDiscount;
    this.TotalIncl = Calculations.RoundAwayFromZero((roundedTotalExclForVatCalc + this.TotalVat), 2);
    this.TotalDiscount = this.TotalExcl - this.TotalExclAfterDiscount;

    this.TotalGPPercent = this.TotalExclAfterDiscount > 0 ? Calculations.RoundAwayFromZero(((this.TotalExclAfterDiscount - this.TotalCost) / this.TotalExclAfterDiscount) * 100, 2) : 0;
    if (this.TotalGPPercent < 20) {
      this.showGPWarning = true;
    }

    if (this.isCopiedQuote) {
      //Make sure to clear copied quote flag to prevent resetting of peices again
      sessionStorage.removeItem(localStorageActions.Reset);
      this.isCopiedQuote = false;
    }
  }


  setWebDiscountPrice() {
    this.TotalItems = 0;
    this.TotalExcl = 0;
    this.TotalDiscount = 0;
    this.TotalExclAfterDiscount = 0;
    this.TotalVat = 0;
    this.TotalIncl = 0;
    this.TotalCost = 0;
    this.SysproTotal = 0;
    this.TotalGPPercent = 0;
    var sumOfLineDiscount = 0;
    var roundedTotalExclForVatCalc = 0;

    const webQuoteRows = [...this.rowsCache];

    for (var product of webQuoteRows) {
      if (product.lineType == '1') {
        let sysproPrice = product.currentPrice;

        //Calulcate the discount percentage between Syspro price and Web Price
        //Calculate based on QTY && priceConvFac
        product.originalPrice = sysproPrice;
        product.originalDiscount = Calculations.calculateSpecialDiscountTotal(sysproPrice, product.specialPrice, 0);
        //product.lineTotal
      }

      if (product.lineType == "7") {
        product.unitCost = Calculations.setUnitCostDecimals(product.unitCost);
      }

      var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.originalPrice, product.priceConvFac, product.lineTotal);
      var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.originalPrice, product.originalDiscount, product.priceConvFac, 0);
      var lineTotalNettPrice = Calculations.calculateNettLinePrice(product.orderQty, product.sellingPrice, product.priceConvFac, lineTotalExclVat, lineDiscount);
      //var lineTotalNettPrice = Calculations.calculateNettLinePriceWithDiscPerc(product.orderQty, product.originalPrice, product.priceConvFac, lineTotalExclVat, product.originalDiscount);
      var lineTotalNett = Calculations.calculateBaseLineTotal(product.orderQty, lineTotalNettPrice, product.priceConvFac, product.lineTotal);
      var lineSysproLineTotal = Calculations.calculateLineSysproTotal(product.orderQty, product.originalPrice, product.originalDiscount, product.priceConvFac, 0);

      //product.unitPrice = (lineTotalExclVat > 0 ? Calculations.RoundAwayFromZero(((lineTotalExclVat - lineDiscount) / product.orderQty), 2) : 0);
      product.unitPrice = (lineTotalExclVat > 0 ? ((lineTotalExclVat - lineDiscount) / product.orderQty) : 0);

      var test = product.specialPrice;

      var lineTotalGpPercent = Calculations.calculateGPLineTotal(product.unitCost, product.specialPrice, product.priceConvFac, product.lineTotal);
      if (lineTotalGpPercent < 20) {
        this.showGPWarning = true;
      }

      this.TotalCost += (product.unitCost * product.orderQty);

      this.TotalItems += product.orderQty;
      if (this.nettPriceSet) {

        this.TotalExcl += lineTotalNett;
        this.TotalDiscount = 0;
      }
      else {
        this.TotalExcl += lineTotalExclVat;
        sumOfLineDiscount += lineDiscount;
        this.SysproTotal += lineSysproLineTotal;

      }

      var lineVat = 0;
      if (product.vatPercentage && product.vatExempt == false) {
        lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, product.vatPercentage);
      }

      this.TotalVat += lineVat;

      this.calcTotal(product);

    }

    roundedTotalExclForVatCalc = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero(sumOfLineDiscount, 2));
    this.TotalExclAfterDiscount = this.TotalExcl - sumOfLineDiscount;
    this.TotalIncl = Calculations.RoundAwayFromZero((roundedTotalExclForVatCalc + this.TotalVat), 2);
    this.TotalDiscount = this.TotalExcl - this.TotalExclAfterDiscount;

    this.TotalGPPercent = this.TotalExclAfterDiscount > 0 ? Calculations.RoundAwayFromZero(((this.TotalExclAfterDiscount - this.TotalCost) / this.TotalExclAfterDiscount) * 100, 2) : 0;
    if (this.TotalGPPercent < 20) {
      this.showGPWarning = true;
    }
  }

  showGPModal() {
    this.gpModal.show();
    const gpRows = this.rowsCache.filter(x => x.lineType !== "6");
    for (var product of gpRows) {
      //var specialUnitPrice = (product.specialPrice / product.priceConvFac);
      var lineTotalGpPercent = Calculations.calculateGPLineTotal(product.unitCost, product.specialPrice, product.priceConvFac, product.lineTotal);
      product.gP = lineTotalGpPercent;
    }
    this.GPGrid = gpRows;
  }

  //viewCustomerNotes(row: ArCustomerEdit) {
  //  sessionStorage.setItem(localStorageActions.Customer, row.customer);
  //  this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  //}

  onStockLoadSuccessful(inventory: any[]) {

    this.inventoryList = inventory[0];
  }

  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
    
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.stockDescription;
      this.stockCode = this.stockCodedetail.stockCode;
      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.addSheetImage = row.addSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.onSpecialFowkes = row.onSpecialFowkes;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  onStockCodeLoadSuccessful(row: Inventory): void {

    this.stockCodedetail = new Inventory();
    this.stockCodedetail = row[0];
    this.hasDataSheet = false;
    this.hasAddDataSheets = false;
    this.hasSpecials = false;
    this.stockCodedetail.filePath = row.filePath;

    if (this.stockCodedetail.dataSheetImage) {
      this.hasDataSheet = true;
      this.stockCodedetail.dataSheetImage = encodeURIComponent(this.stockCodedetail.dataSheetImage);
    }
    if (this.stockCodedetail.addSheetImage) {
      this.hasAddDataSheets = true;
      this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
    }
    if (this.stockCodedetail.specialsImage) {
      this.hasSpecials = true;
    }
    if (!!this.stockCodedetail) {
      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }
    }
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }

  openSpecials(row: Inventory) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    //if (!!row.specialsImage && row.specialsImage != "") {
    //  window.open(row.filePath + "/SpecialImages/" + row.specialsImage, "_blank");
    //}
  }

  showWHDetail(row: any, event) {
    if (row) {
      if (row.lineType != "7") {
        this.stockDescription = row.stockCode + ": " + row.stockDescription;
        this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      }
    }
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }


  onEmailLoadSuccessful() {
    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasAddDataSheets = false;
    this.hasSpecials = false;

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);
    this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);
    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage.length >= 1 && (this.stockCodedetail.onSpecial || this.stockCodedetail.onSpecialFowkes));

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"), this.branchCode, this.application).subscribe(x => this.emailModal.show(), error => this.onDataLoadFailed(error));

    this.emailObject.RecepientName = "";
    this.emailObject.RecepientEmail = this.customer.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();
    }

    if (this.hasAddDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  titleCase(str) {
    str = str.toLowerCase();
    return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
  }

  get application(): string {
    return this.configurations.applicationName;
  }


  viewInventory() {
    console.log("TEST");

  }

  viewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailPDF() {

    console.log(this.emailObject);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.emailObject.AddDoc1 = (this.attachDataSheets ? this.emailObject.AddDoc1 : "");
    this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");
    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var stockCode = this.emailObject.Attachment;

    this.src = url;
    // window.open(this.src).print();
    // const blobUrl = window.URL.createObjectURL((test));
    //const iframeDoc = document.getElementsByTagName("iframe")[0].contentWindow;
    // iframeDoc.src = url;



    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = stockCode.replace('/', '_').replace('+', '_') + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  private onNotesLoadSuccessful(notes: any[]) {
    this.alertService.stopLoadingMessage();
    this.customerNotes = notes;
    //if (notes[0].length > 0) {
    //  this.notesModal.show();
    //}
  }


   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }


  private postAudit() {
    this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  }
}
