
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { localStorageActions } from 'src/app/models/enums';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Branch } from '../../../models/branch.model';
import { InvoiceTrackingStatus } from '../../../models/invoice-tracking-status.model';
import { Permission } from '../../../models/permission.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ReportService } from '../../../services/report.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'reports-invoice-tracking',
  templateUrl: './reports-invoice-tracking.component.html',
  styleUrls: ['./reports-invoice-tracking.component.scss']
})
export class ReportsInvoiceTrackingComponent implements OnInit, AfterViewInit {


  columns: any[] = [];
  missingcolumns: any[] = [];
 
    loadingIndicator: boolean;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;


  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('missinginvoiceTemplate')
  missinginvoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;
  
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  invoiceTrackingListNotPrinted: InvoiceTrackingStatus[] = [];
  invoiceTrackingListOnHold: InvoiceTrackingStatus[] = [];
  invoiceTrackingListNotCompleted: InvoiceTrackingStatus[] = [];
  rowsMissing: InvoiceTrackingStatus[] = [];
  rowsCacheMissing: InvoiceTrackingStatus[] = [];
  rowsCache: InvoiceTrackingStatus[] = [];

    gridHeight: number = 0;
    selectedItems: any;
  isNavigating: boolean;


  @ViewChild('itsModal')
  itsModal: ModalDirective;
  itsDetail: InvoiceTrackingStatus;
  currentCompany: string;


  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  selectedBranch: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);

    this.loadGrids();

    let defaultBranch = new Branch();
    defaultBranch.branch = "ALL";
    this.allBranches.push(defaultBranch);
    this.fowkesService.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    this.currentCompany = (this.application == "MaxArcusOnline" ? "Max Arcus" : "Fowkes Bros");
    this.selectedBranch = "ALL";

    this.loadData();

  }

  private loadGrids() {
    this.columns = [
      { prop: 'invoiceNumber', name: 'Invoice', width: 100 , cellTemplate: this.invoiceTemplate },
      { prop: 'lastOperator', name: 'Assigned User', width: 160 },
      { prop: 'currentStatus', name: 'Status', width: 100 },
      { prop: 'insertDate', name: 'Date', width: 120, cellTemplate: this.dateTemplate }
    ];

    this.missingcolumns = [
      { prop: 'invoiceNumber', name: 'Invoice', width: 100 , cellTemplate: this.missinginvoiceTemplate },
      { prop: 'lastOperator', name: 'Assigned User', width: 160 },
      { prop: 'currentStatus', name: 'Status', width: 100 },
      { prop: 'insertDate', name: 'Date', width: 120, cellTemplate: this.dateTemplate }
    ];
  }

  ngAfterViewInit() {

    this.loadGrids();
  }


  get application(): string {
    return this.configurations.applicationName;
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.invoiceTrackingListNotCompleted = this.invoiceTrackingListNotCompleted.filter(r => Utilities.searchArray(value, false, r.lastOperator, r.salesOrder, r.customerCode,
        r.currentStatus, r.insertDate, r.invoiceNumber, r.customerName));
      this.invoiceTrackingListOnHold = this.invoiceTrackingListOnHold.filter(r => Utilities.searchArray(value, false, r.lastOperator, r.salesOrder, r.customerCode,
        r.currentStatus, r.insertDate, r.invoiceNumber, r.customerName));
      this.invoiceTrackingListNotPrinted = this.invoiceTrackingListNotPrinted.filter(r => Utilities.searchArray(value, false, r.lastOperator, r.salesOrder, r.customerCode,
        r.currentStatus, r.insertDate, r.invoiceNumber, r.customerName));
    }
    else {
      this.invoiceTrackingListNotPrinted = this.rowsCache.filter(r => r.printed != 'A');
      this.invoiceTrackingListOnHold = this.rowsCache.filter(r => r.onHoldUpdate === 'A');
      this.invoiceTrackingListNotCompleted = this.rowsCache.filter(r => r.currentStatus != 'On Hold' && r.completed == 'I');
    }
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


    onSpecificSearchSuccesful(x: SalesOrderList[]): void {

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.searchResults = x.filter(i => i.invoiceNumber != '');

  }


  branchSelected() {

    if (this.selectedBranch != "ALL") {

      this.invoiceTrackingListNotPrinted = this.rowsCache.filter(x => x.printed != 'A' && x.branch == this.selectedBranch);
      this.invoiceTrackingListOnHold = this.rowsCache.filter(x => x.onHoldUpdate === 'A' && x.branch == this.selectedBranch);
      this.invoiceTrackingListNotCompleted = this.rowsCache.filter(x => x.currentStatus != 'On Hold' && x.completed == 'I' && x.branch == this.selectedBranch);
    }
    else {
        this.invoiceTrackingListNotPrinted = this.rowsCache.filter(r => r.printed != 'A');
        this.invoiceTrackingListOnHold = this.rowsCache.filter(r => r.onHoldUpdate === 'A');
        this.invoiceTrackingListNotCompleted = this.rowsCache.filter(r => r.currentStatus != 'On Hold' && r.completed == 'I');
    }
  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });

    this.selectedBranch = this.branchCode;
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  viewInvoiceSelected() {
    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, this.searchResultSelected.invoiceNumber);
    this.router.navigate(['/reports'], { fragment: 'invoicestatus'});
   
  }

  viewITSInvoice(row: InvoiceTrackingStatus) {

    sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
    sessionStorage.setItem(localStorageActions.Customer, row.customerCode);
    sessionStorage.setItem(localStorageActions.InvoiceNumber, row.invoiceNumber);
    this.router.navigate(['../../salesorders'], { fragment: 'invoice', queryParams: { customer: row.customerCode } });
  }


  viewInvoice(row: InvoiceTrackingStatus) {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);

    this.itsDetail = row;
    if (row.id > 0) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModallHide() {
    this.itsDetail = null;
    this.itsModal.hide();
  }



  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;


    this.sysproService.getMissingITS().subscribe(x => this.onMissingSearchSuccesful(x), error => this.onDataLoadFailed(error));

    this.sysproService.getAllInvoiceTrackingStatus().subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

    this.searchType = "itsinvoice";
  }
  

  onSearchSuccesful(x: InvoiceTrackingStatus[]): void {
      console.log(x);
      this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.rowsCache = x;

      if (x.length > 0) {
        this.invoiceTrackingListNotPrinted = x.filter(r => r.printed != 'A');
        this.invoiceTrackingListOnHold = x.filter(r =>  r.onHoldUpdate === 'A');
        this.invoiceTrackingListNotCompleted = x.filter(r => r.currentStatus != 'On Hold' && r.completed == 'I');

        this.branchSelected();
      }
      else {
        this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
          MessageSeverity.warn);
    }

     
  }

  onMissingSearchSuccesful(x: InvoiceTrackingStatus[]): void {
    if (x && x.length > 0) {
      this.rowsCacheMissing = [...x];
      let rowsCacheMissingFiltered = this.rowsCacheMissing.filter(r => r.fromCompany == this.currentCompany && r.shippingInstructions != "Taken By Customer");
      this.rowsMissing = rowsCacheMissingFiltered;
      //Auto update ITS with these records
    }
    console.log(x);
  }

    
    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
  }


  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }
  

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Invoice", "Assigned User", "Status", "Date"]
    };

    var filename = "InvoiceTracking";
    var selectRows = [...this.rowsCache];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.insertDate).toISOString().split('T')[0];
      exportRows.push({
        Invoice: row.invoiceNumber + (row.poCount > 0 ? '*' : ''), AssignedUser: row.lastOperator, Status: row.currentStatus, Date: date
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

}
