import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { MySQLDocument } from '../../../models/mysqldocument.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { Branch } from '../../../models/branch.model';
import { InvoiceBarcode } from '../../../models/invoice-barcode.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { forEach } from '@angular/router/src/utils/collection';



@Component({
  selector: 'pod-file-sync',
  templateUrl: './pod-file-sync.component.html',
  styleUrls: ['./pod-file-sync.component.scss']
})
export class PodFileSyncComponent implements OnInit {

  @ViewChild('searchResultSelector')
  private searchResultSelector;

  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  searchResults: InvoiceBarcode[];
  searchType: string;
  searchText: string;
  searchResultSelected: InvoiceBarcode;


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  checkCreditNotes: boolean;
  columns: any[] = [];
  rows: InvoiceBarcode[] = [];
  rowsCache: InvoiceBarcode[];

  columnsBarcodeReference: any[] = [];
  BarcodeReferenceList: BarcodeReference[] = [];
  BarcodesUpdated: BarcodeReference[];

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  selectedBranch: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;

  loadingIndicator: boolean;

  selected: { startDate: Moment, endDate: Moment };
  show: boolean;

  ranges = {
    //'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  constructor(private router: Router, private alertService: AlertService, private fowkesService: FowkesOnlineService, private sysproService: SysproService,  private route: ActivatedRoute, private accountService: AccountService) {

  }

  ngOnInit() {

    this.selected = {
      startDate: moment().subtract(1, 'day').startOf('day'),
      endDate: moment().subtract(0, 'day').endOf('day')
    }


    //Search by document dates
    this.columnsBarcodeReference = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'prefix', name: 'Prefix', width: 50, cellClass: "center" },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'status', name: 'Status', width: 120 },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      { prop: 'barcode', name: 'Barcode', width: 90 },
      { prop: 'fileName', name: 'File Name', width: 200 },
      { prop: 'numberOfFiles', name: 'Count', width: 50 },
      { prop: 'barcodeDate', name: 'Document Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'exclude', name: 'Excluded', width: 90 }
    ];

    this.loadFileReferences();
  }

  ngAfterViewInit() {


    //Search by document dates
    this.columnsBarcodeReference = [
      { prop: 'company', name: 'C', width: 50 },
      { prop: 'prefix', name: 'Prefix', width: 50, cellClass: "center" },
      { prop: 'branch', name: 'Branch', width: 70, cellClass: "center" },
      { prop: 'status', name: 'Status', width: 120 },
      { prop: 'invoice', name: 'Invoice', width: 90 },
      { prop: 'barcode', name: 'Barcode', width: 90 },
      { prop: 'fileName', name: 'File Name', width: 200 },
      { prop: 'numberOfFiles', name: 'Count', width: 50 },
      { prop: 'barcodeDate', name: 'Document Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'exclude', name: 'Excluded', width: 90 }
    ];
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.company, r.customer, r.branch, r.docType, r.invoice));
    }
    else {
      this.rows = this.rowsCache;
    }
  }

  private loadFileReferences(result?: any) {   

    //this.alertService.startLoadingMessage();
    //this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);
    let status = this.selectUndefinedOptionValue;

        //this.sysproService.getBarcodesAvailableReport(startD.toJSON(), endD.toJSON(),
        //  company, branch, status).subscribe(x => this.onBarcodeSearchSuccesful(x), error => this.onDataLoadFailed(error));
    if (result) {      
      console.log("POST RESPONSE", result.value);
      let test = result.value.actionInput;
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', test +  ' Records were found for syncing, the database has been updated', MessageSeverity.success);
    }
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }



  syncToNextGen() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    let currentDate: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());
    while (currentDate <= endD) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

      this.sysproService.syncFileReferencesByDate(year + '-' + month, day).subscribe(x => this.loadFileReferences(x) , error => this.onDataLoadFailed(error));
    }
    //let currentDate: Date = new Date();
    //let year = currentDate.getFullYear();
    //let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    //let day = ("0" + currentDate.getDate()).slice(-2);
    //currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    //this.sysproService.syncFileReferencesByDate(year + '-' + month, day).subscribe(x => this.loadFileReferences(x), error => this.onReferenceLoadFail(error));
  }

  onBarcodeSearchSuccesful(x: BarcodeReference[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {
      let details = x;
      details.forEach((line, index, details) => {        
        (<any>line).index = index + 1;
      });
      this.BarcodesUpdated = [...details];
      this.BarcodeReferenceList = this.BarcodesUpdated;            
    }
  }

  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.loadFileReferences();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
    this.loadFileReferences();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.loadFileReferences();
  }



  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

}
