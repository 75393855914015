export class CustomerOTPHeader {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(otpHeaderReference?: string, statement?: string, dateFrom?: Date, dateTo?: Date, createDate?: Date, application?: string,
    customerCode?: string, createdBy?: string, sentTo?: string, status?: string, customerName?: string, updatedStatus?: string, updatedCustomerStatus?: string) {

    this.otpHeaderReference = otpHeaderReference;
    this.statement = statement;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.createDate = createDate;
    this.application = application;
    this.customerCode = customerCode;
    this.createdBy = createdBy;
    this.sentTo = sentTo;
    this.status = status;
    this.customerName = customerName;
    this.updatedStatus = updatedStatus;
    this.updatedCustomerStatus = updatedCustomerStatus;
  }


  public otpHeaderReference: string;
  public statement: string;
  public dateFrom: Date;
  public dateTo: Date;
  public createDate: Date;
  public application: string;
  public customerCode: string
  public createdBy: string;
  public sentTo: string;
  public status: string;
  public customerName: string;
  public updatedStatus: string;
  public updatedCustomerStatus: string;
}
