import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, Input } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';

import { Moment } from 'moment';
import * as moment from 'moment';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { Branch } from '../../../models/branch.model';

//import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';
//import { ReportsInvoiceReprintComponent } from './report-components/reports-invoice-reprint-component';
//import { ReportsInvoiceStatusComponent } from './report-components/reports-invoice-status-component';
//import { ReportsSalesComponent } from './report-components/reports-sales-component';
//import { ReportsInvoiceTrackingPurgeComponent } from './report-components/reports-invoice-tracking-purge-component';
//import { MySQLDocument } from '../../../models/mysqldocument.model';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { ValueHelper } from '../../../models/valuehelper.model';
import { validation } from '../../../services/validation.service';
import { ConfigurationService } from '../../../services/configuration.service';



@Component({
  selector: 'pod-search-missing',
  templateUrl: './pod-search-missing.component.html',
  styleUrls: ['./pod-search-missing.component.scss']
})
export class PodSearchMissingComponent implements OnInit, AfterViewInit {

  show: boolean = false;

  private selectUndefinedOptionValue: string = "ALL";

  allBranches: Branch[] = [];

  loadingIndicator: boolean = false;

  selected: { startDate: Moment, endDate: Moment };

  ranges = {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  rows: BarcodeReference[] = [];

  rowsCache: BarcodeReference[] = [];

  columns: any[] = [];

  selectedBranch: string;

  selectedCompany: string;

  selectedStatus: string;

  exclTrans: boolean = false;

  exclCancelled: boolean = false;

  exclCreditNotes: boolean = false;

  exclCashSales: boolean = false;

  showAll: boolean = false;

  excludedTransfers: BarcodeReference[] = [];

  excludedCreditnotes: BarcodeReference[] = [];

  excludedCashSales: BarcodeReference[] = [];

  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;

  @ViewChild('statusList')
  private statusList;

  @ViewChild('statusListSelector')
  private statusListSelector;

  @ViewChild('purchaseOrderList')
  purchaseOrderList: TemplateRef<any>;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('barcodeTemplate')
  barcodeTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
  chkBuyouts: boolean;
    chkPresent: boolean;
    chkTransfers: boolean;
    chkCRN: boolean;
    chkCash: boolean;
    chkAll: boolean;
  chkCnc: boolean;
    searchBatchFrom: string;
  searchBatchTo: string;


  constructor(private router: Router, private alertService: AlertService, private sysproService: SysproService, private route: ActivatedRoute,
    private accountService: AccountService, private fowkesOnline: FowkesOnlineService, private configurations: ConfigurationService) {

  }

  ngOnInit() {

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }

    this.selectedCompany = (this.application == "MaxArcusOnline" ? "M" : "F");
    this.selectedStatus = "Missing POD";
    this.chkCnc = true;
    this.chkTransfers = true;
    this.selectedBranch = "ALL";

    this.columns = [
      { prop: 'company', name: 'C', width: '50', cellClass: "left" },
      { prop: 'documentType', name: 'T', width: '50', cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: '70', cellClass: "left" },
      { prop: 'invoice', name: 'Reference', width: '120', cellClass: "left", cellTemplate: this.barcodeTemplate  },
      { prop: 'orderDate', name: 'Order Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'orderStatus', name: 'Order Status', width: '90', cellClass: "left" },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left"},
       { prop: 'customer', name: 'Customer', width: '100', cellClass: "left" },
      //{ prop: 'invTerms', name: 'Terms', width: '70', cellClass: "left", sortable: false},
      { prop: 'numberOfFiles', name: 'Files', width: '70', cellClass: "left" }
    ];


    this.columns.push({ name: 'Documents', width: 200, cellTemplate: this.selectTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesOnline.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));
    this.Loaddata();
  }

  ngAfterViewInit() {

    this.columns = [
      { prop: 'company', name: 'C', width: '50', cellClass: "left" },
      { prop: 'documentType', name: 'T', width: '50', cellClass: "left" },
      { prop: 'branch', name: 'Branch', width: '70', cellClass: "left" },
      { prop: 'invoice', name: 'Reference', width: '120', cellClass: "left", cellTemplate: this.barcodeTemplate },
      { prop: 'orderDate', name: 'Order Date', width: '120', cellClass: "left", cellTemplate: this.dateTemplate },
      { prop: 'orderStatus', name: 'Order Status', width: '90', cellClass: "left" },
      { prop: 'status', name: 'Status', width: '100', cellClass: "left" },
      //{ prop: 'hasBuyouts', name: 'Buyouts?', width: '70', cellClass: "left" },
      //{ prop: 'documentType', name: 'Type', width: '70', cellClass: "left", sortable: false},
      //{ prop: 'invTerms', name: 'Terms', width: '70', cellClass: "left", sortable: false},
      { prop: 'customer', name: 'Customer', width: '100', cellClass: "left" },
      { prop: 'numberOfFiles', name: 'Files', width: '70', cellClass: "left" }
    ];
    this.columns.push({ name: 'Documents', width: 200, cellTemplate: this.selectTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });


  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  Loaddata() {

    //this.alertService.startLoadingMessage();
    //this.loadingIndicator = true;

    //let startD: Date = new Date(this.selected.startDate.toString());
    //let endD: Date = new Date(this.selected.endDate.toString());

    //let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    //let branch = (this.selectedBranch ? this.selectedBranch : this.branchCode);

    //let batchFrom = (this.searchBatchFrom ? this.searchBatchFrom : this.selectUndefinedOptionValue);
    //let batchTo = (this.searchBatchTo ? this.searchBatchTo : this.selectUndefinedOptionValue);


    //this.sysproService.getBarcodeReferences(startD.toJSON(), endD.toJSON(), company.toUpperCase(), branch.toUpperCase(), batchFrom.toUpperCase(), batchTo.toUpperCase()).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
  }


  syncInvoice(row: any) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getBarcodeForInvoiceSync(row.invoice, row.branch).subscribe(x => this.Loaddata(), error => this.onDataLoadFailed(error));
  }

  statusSelected() {
    this.filterChanged();
  }


  branchSelected() {
  //  this.Loaddata();
  }

  filterBuyouts() {
    this.chkBuyouts = !this.chkBuyouts;
    this.filterChanged();
  }


  filterCancelled() {
    this.chkCnc = !this.chkCnc;
    this.filterChanged();
  }

  filterPresent() {
    this.chkPresent = !this.chkPresent;
    this.filterChanged();
  }

  filterTransfers() {
    this.chkTransfers = !this.chkTransfers;
    this.filterChanged();
  }

  filterCRN() {
    this.chkCRN = !this.chkCRN;
    this.filterChanged();

  }

  filterCash() {
    this.chkCash = !this.chkCash;
    this.filterChanged();

  }

  filterAll() {
    this.chkAll = !this.chkAll;
    this.filterChanged();
  }

    filterChanged() {
      if (this.chkAll) {
        this.chkCash = false;
        this.chkCRN = false;
        this.chkTransfers = false;
        this.chkPresent = false;
        this.chkBuyouts = false;
        this.chkCnc = false;
        //this.searchTerm = "";
        this.rows = this.rowsCache;
        return;
      }

      if (this.chkCRN || this.chkTransfers || this.chkCnc || this.selectedStatus || this.selectedBranch) {
        let filteredRows = this.rowsCache;       
        this.chkCRN ? filteredRows = filteredRows.filter(m => m.documentType != 'C') : '';
        this.chkTransfers ? filteredRows = filteredRows.filter(m => m.documentType != 'O') : '';  
        this.chkCnc ? filteredRows = filteredRows.filter(m => m.orderStatus != '\\') : '';
        this.selectedStatus != 'ALL' ? filteredRows = filteredRows.filter(m => m.status == this.selectedStatus) : '';
        this.selectedBranch != 'ALL' ? filteredRows = filteredRows.filter(m => m.branch == this.selectedBranch) : '';
        this.rows = filteredRows;
      }
      else {
        this.rows = this.rowsCache;
      }

      //if (this.chkCash || this.chkCRN || this.chkTransfers || this.chkPresent || this.chkBuyouts || this.chkCnc || this.selectedStatus || this.selectedBranch) {
      //  let filteredRows = this.rowsCache;
      //  this.chkCash ? filteredRows = filteredRows.filter(m => m.invTerms != '03') : '';
      //  this.chkCRN ? filteredRows = filteredRows.filter(m => m.documentType != 'C') : '';
      //  this.chkTransfers ? filteredRows = filteredRows.filter(m => m.documentType != 'O') : '';
      //  this.chkPresent ? filteredRows = filteredRows.filter(m => m.status != 'Present') : '';
      //  this.chkBuyouts ? filteredRows = filteredRows.filter(x => !x.hasBuyouts) : filteredRows.filter(x => x.hasBuyouts);
      //  this.chkCnc ? filteredRows = filteredRows.filter(m => m.orderStatus != '\\') : '';
      //  this.selectedStatus != 'ALL' ? filteredRows = filteredRows.filter(m => m.status == this.selectedStatus) : '';
      //  this.selectedBranch != 'ALL' ? filteredRows = filteredRows.filter(m => m.branch == this.selectedBranch) : '';
      //  this.rows = filteredRows;
      //}
    }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });

    this.selectedBranch = this.branchCode;
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  specificSearch() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);

    let batchFrom = (this.searchBatchFrom ? this.searchBatchFrom : this.selectUndefinedOptionValue);
    let batchTo = (this.searchBatchTo ? this.searchBatchTo : this.selectUndefinedOptionValue);

    this.sysproService.getBarcodeReferences(startD.toJSON(), endD.toJSON(), company.toUpperCase(), branch.toUpperCase(), batchFrom.toUpperCase(), batchTo.toUpperCase()).subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }

  onSearchSuccesful(x: BarcodeReference[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x[0] && x.length > 0) {
      var updatedList = x.filter(x => x.fileNumber == 0 || x.fileNumber == 1);

      this.rows = [];
      if (updatedList && updatedList[0] && updatedList.length > 0) {
        updatedList.forEach((bReference, index, updatedList) => {

          (<any>bReference).index = index + 1;
          let docList = x.filter(x => x.invoice == bReference.invoice);

          if (bReference.fileNumber >= 1 && bReference.numberOfFiles >= 1) {
            let helper: ValueHelper = new ValueHelper;

            helper.dataValue = 0;

            helper.displayValue = "Please Select";

            bReference.fileNumbers = [];

            bReference.fileNumbers.unshift(helper);

            docList.forEach((item, indexy, y) => {
              let dropDown: ValueHelper = new ValueHelper;

              dropDown.dataValue = item.fileNumber;

              dropDown.displayValue = item.fileName;

              bReference.fileNumbers.push(dropDown);

            });

            bReference.bnumber = bReference.fileNumbers[0];
          }

          this.rows.push(bReference);

        });
      }

      this.rowsCache = [...this.rows];

      this.filterChanged();
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  viewPurchaseOrder(row: BarcodeReference) {

    this.sysproService.getFileStream(row.fileName, row.bnumber.dataValue).subscribe(x => this.onFileSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));

  }

  viewPurchaseOrderDetails(row: BarcodeReference) {

    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, row.invoice);

    sessionStorage.removeItem(localStorageActions.SalesOrderNumber);

    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: row.invoice } });
  }

  onFileSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }

  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    //this.Loaddata();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
   // this.Loaddata();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.Loaddata();
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.invoice, r.orderDate, r.orderStatus, r.fileName, r.prefix, r.branch, r.month, r.status,
        r.barcodeEntryDateString));
    }
    else {
      this.rows = this.rowsCache;
    }
  }


  exportDetails() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Reference", "OrderDate", "DocumentType", "Customer", "CustomerName", "Operator", "ShippingInstructions", "Status", "NumberOfFiles", "OrderStatus"]
    };

    var filename = "Documents";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Invoice: row.invoice, OrderDate: (moment(row.orderDate)).format('DD-MMM-YYYY'), DocumentType: row.documentType,
        Customer: row.customer, CustomerName: row.customerName, Operator: row.operator, ShippingInstructions: row.shippingInstructions,
        Status: row.status, NumberOfFiles: row.numberOfFiles, OrderStatus: row.orderStatus
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  //filterTransfers() {

  //  this.showAll = false;

  //  if (!this.exclTrans) {

  //    this.excludedTransfers = this.rows.filter(x => x.documentType == 'O');
  //    this.rows = this.rows.filter(x => x.documentType != 'O');
  //  }
  //  else {

  //    if (this.excludedTransfers && this.excludedTransfers.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedTransfers);
  //      //this.excludedTransfers = [];
  //    }
  //  }
  //}

  //filterCanceled() {
  //  //todo OrderStatus or Status / ?
  //}

  //filterCRN() {


  //  this.showAll = false;

  //  if (!this.exclCreditNotes) {

  //    this.excludedCreditnotes = this.rows.filter(x => x.documentType == 'C');
  //    this.rows = this.rows.filter(x => x.documentType != 'C');
  //  }
  //  else {
  //    if (this.excludedCreditnotes && this.excludedCreditnotes.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedCreditnotes);
  //    }
  //  }
  //}

  //filterCash() {
  //  this.showAll = false;

  //  if (!this.exclCashSales) {
  //    this.excludedCashSales = this.rows.filter(x => x.invTerms == '03');

  //    this.rows = this.rows.filter(x => x.invTerms != '03');
  //  }
  //  else {

  //    if (this.excludedCashSales && this.excludedCashSales.length >= 1) {
  //      this.rows = this.rows.concat(this.excludedCashSales);
  //    }
  //  }
  //}

  //filterAll() {
  //  if (!this.showAll) {

  //    if (!this.exclTrans) {
  //      this.filterTransfers();
  //    }

  //    if (!this.exclCreditNotes) {
  //      this.filterCRN();
  //    }

  //    if (!this.exclCashSales) {
  //      this.filterCash();
  //    }

  //    this.exclTrans = true;

  //    this.exclCreditNotes = true;

  //    this.exclCashSales = true;

  //  }
  //  else {
  //    if (this.exclTrans) {
  //      this.filterTransfers();
  //    }

  //    if (this.exclCreditNotes) {
  //      this.filterCRN();
  //    }

  //    if (this.exclCashSales) {
  //      this.filterCash();
  //    }

  //    this.exclTrans = false;

  //    this.exclCreditNotes = false;

  //    this.exclCashSales = false;
  //  }
  //}
}
