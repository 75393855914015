
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { localStorageActions } from 'src/app/models/enums';
import { SalesOrderList } from 'src/app/models/salesorder-list.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Branch } from '../../../models/branch.model';
import { InvoiceTrackingStatus } from '../../../models/invoice-tracking-status.model';
import { Outbox } from '../../../models/wapp-inbox.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ReportService } from '../../../services/report.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'reports-invoice-tracking-collections',
  templateUrl: './reports-invoice-tracking-collections.component.html',
  styleUrls: ['./reports-invoice-tracking-collections.component.scss']
})
export class ReportsInvoiceTrackingCollectionsComponent implements OnInit, AfterViewInit {


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];

  loadingIndicator: boolean;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;


  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;


  @ViewChild('dateOnlyTemplate')
  dateOnlyTemplate: TemplateRef<any>;


  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('whatsapptemplate')
  whatsapptemplate: TemplateRef<any>;

  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "ALL";

  @ViewChild('statusListSelector')
  private statusListSelector;
  private allstatuses: string = "All";
  selectedStatus: string = "";


  @ViewChild('#companiesListSelector')
  private companiesListSelector;

  invoiceTrackingPODsReceived: InvoiceTrackingStatus[] = [];
  invoiceTrackingListNotPrinted: InvoiceTrackingStatus[] = [];
  invoiceTrackingListOnHold: InvoiceTrackingStatus[] = [];
  invoiceTrackingListNotCompleted: InvoiceTrackingStatus[] = [];
  rows: InvoiceTrackingStatus[] = [];
  rowsCache: InvoiceTrackingStatus[] = [];

  gridHeight: number = 0;
  selectedItems: any;
  isNavigating: boolean;
  countRecords: number;
  percentageCount: number;
  chkPurged: boolean;
  chkTBC: boolean;
  chkIncomplete: boolean;
  chkShowAll: boolean;
  selectedCompany: string;

  selectedSalesOrder: string;
  selectedInvoice: string;


  @ViewChild('itsModal')
  itsModal: ModalDirective;
  itsDetail: InvoiceTrackingStatus;


  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;
    currentStatus: any;
  invoiceNumber: any;


  @ViewChild('whatsappModal')
  whatsappModal: ModalDirective;
  outboxDetail: Outbox;


  @ViewChild('branches')
  private branches;

  @ViewChild('branchesListSelector')
  private branchesListSelector;
  allBranches: Branch[] = [];

  selectedBranch: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute) {
  }


  ngOnInit() {

    this.setGrid();

    this.selected = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day')
    }

    let defaultBranch = new Branch();
    defaultBranch.branch = this.selectUndefinedOptionValue;
    this.allBranches.push(defaultBranch);
    this.fowkesService.getBranches().subscribe(x => this.onBranchLoadSuccessful(x), error => this.onBranchDataLoadFailed(error));

    // (this.application == "MaxArcusOnline" ? "Max Arcus" : "Fowkes Bros");
    this.selectedCompany = "ALL";
    this.selectedBranch = "ALL";

    this.chkTBC = true;
    this.chkPurged = true;
    this.chkIncomplete = false;
    this.chkShowAll = true;

    this.loadData();

  }



  ngAfterViewInit() {
    this.setGrid();
  }

  setGrid() {
    this.columns = [
      { prop: 'fromCompany', name: 'Company', width: 120 },
      { prop: 'branch', name: 'WH', width: 50 },
      { prop: 'collectionsDateTime', name: 'Pending Collections Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'invoiceNumber', name: 'Invoice', width: 100, cellTemplate: this.invoiceTemplate },
      { prop: 'salesOrder', name: 'SalesOrder', width: 100, cellTemplate: this.salesOrderTemplate },
      { prop: 'customerCode', name: 'Customer', width: 80 },
      { prop: 'customerName', name: 'Name', width: 300 },
      //{ prop: 'insertDate', name: 'Create Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'operatorCode', name: 'Operator', width: 80 },
      //{ prop: 'printedDateTime', name: 'Printed Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'dispatchDateTime', name: 'Dispatched Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'deliveryDateTime', name: 'Delivery Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'returnedDateTime', name: 'Returned Date', width: 120, cellTemplate: this.dateTemplate },
      /*{ prop: 'currentStatus', name: 'Status', width: 120 },*/
      //{ prop: 'lastOperator', name: 'Assigned User', width: 120 },
      //{ prop: 'collectionsDateTime', name: 'Pending Collections Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'podReceivedDate', name: 'POD Received Date', width: 120, cellTemplate: this.dateOnlyTemplate },
      //{ prop: 'invoiceCreditedDate', name: 'Credited Date', width: 120, cellTemplate: this.dateTemplate },
      //{ prop: 'purgeReason', name: 'Purge Reason', width: 120 },
    ];


    this.columns.push({ name: 'Update tatus', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    this.columns.push({ name: '', width: 80, cellTemplate: this.whatsapptemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.lastOperator, r.salesOrder, r.customerCode,
        r.currentStatus, r.insertDate, r.invoiceNumber, r.customerName, r.operatorCode, r.fromCompany));
    }
  }

  specificSearch() {
    this.loadData();
  }


  loadData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;   

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    let company = (this.selectedCompany ? this.selectedCompany : this.selectUndefinedOptionValue);
    let branch = (this.selectedBranch ? this.selectedBranch : this.selectUndefinedOptionValue);

    this.sysproService.getInvoiceTrackingStatusCollectionsControl(startD.toJSON(), endD.toJSON(), company, branch, "ALL").subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }

  refresh() {

    this.loadData();
  }


  branchSelected() {

    if (this.selectedBranch != "ALL") {

      this.rows = this.rowsCache.filter(x => x.branch == this.selectedBranch);
    }
    else {
      this.rows = this.rowsCache;
    }
  }


  onBranchLoadSuccessful(branches: Branch[]) {

    this.allBranches = [...this.allBranches, ...branches];
    setTimeout(() => {
      this.branchesListSelector.refresh();
    });

    this.selectedBranch = this.branchCode;
  }

  onBranchDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve branches from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }



  updateStatus(row: InvoiceTrackingStatus) {

    // Code to handle the status update for this specific row
    if (row && row.currentStatus && row.pendingStatus != "Undefined") {
     
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.invoiceNumber = row.invoiceNumber;
      this.selectedSalesOrder = row.salesOrder;

      this.sysproService.postInvoiceTrackingCollectedUpdate(this.selectedSalesOrder, this.invoiceNumber, this.accountService.currentUser.fullName,
        row.pendingStatus.replace(/\//g, "--").replace(/\+/gi, "___")).
        subscribe(x => this.onUpdateSuccessful(x, true), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty Fields', 'Please enure you have selected an update status',
        MessageSeverity.warn);
    }
  }

    onUpdateSuccessful(x: InvoiceTrackingStatus, arg1: boolean): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.alertService.showMessage('', `Record Updated`, MessageSeverity.success);


    }

  onSearchSuccesful(x: InvoiceTrackingStatus[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.countRecords = 0;
    this.percentageCount = 0;

    if (x && x.length > 0) {
      this.rowsCache = x;
      //this.rowsCache = this.rowsCache.filter(c => c.invoiceCredited == null);
      //this.countRecords = this.invoiceTrackingPODsReceived.length;
      //this.percentageCount = (this.countRecords / this.rowsCache.length) * 100;


      if (this.application == "MaxArcusOnline") {
        this.rowsCache = this.rowsCache.filter(c => c.fromCompany == "Max Arcus");
      }
      if (this.application == "FowkesOnline") {
        this.rowsCache = this.rowsCache.filter(c => c.fromCompany == "Fowkes Bros");
      }
      this.rows = this.rowsCache;
      this.rows.forEach(row => {
        row.pendingStatus = 'Undefined'; // Initial status for each row
      });

      this.branchSelected();

    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }

  }


  showAll() {
    this.chkShowAll = !this.chkShowAll;
    this.chkIncomplete = !this.chkIncomplete;
    this.filterChanged();
  }

  showOnlyIncomplete() {
    this.chkIncomplete = !this.chkIncomplete;
    this.chkShowAll = !this.chkShowAll;
    this.filterChanged();
  }

  //showPurged() {
  //  this.chkPurged = !this.chkPurged;
  //  this.filterChanged();
  //}

  //showTBC() {
  //  this.chkTBC = !this.chkTBC;
  //  this.filterChanged();
  //}

  filterChanged() {

    if (this.chkIncomplete) {
      this.rows = this.rowsCache.filter(x => x.podReceived != "A");
    }

    if (this.chkShowAll) {
      this.rows = this.rowsCache;
    }


  }
  get branchCode(): string {
    return this.configurations.branchCode;
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  onSearchFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;

  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.loadData();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.loadData();
    this.show = false;
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Company", "Invoice", "Status", "CreateDate", "Operator", "CollectionsDate", "Customer", "Name"]
    };

    var filename = "InvoiceTrackingCollectionDetails";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.insertDate).toISOString().split('T')[0];

      exportRows.push({
        Company: row.fromCompany,
        Invoice: row.invoiceNumber, Status: row.currentStatus, CreateDate: date, Operator: row.operatorCode,
        CollectionsDate: this.isValidDate(row.collectionsDateTime) ? new Date(row.collectionsDateTime).toISOString().split('T')[0] : "",
        Customer: row.customerCode, Name: row.customerName
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  isValidDate(d: any): boolean {

    var isValid = (d != null && d != "" && d != "1900-01-01T00:00:00");
    return isValid;
   // return d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime()) && d > new Date(1900, 1, 2);
  }


  viewInvoiceSelected() {
    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, this.searchResultSelected.invoiceNumber);
    this.router.navigate(['/reports'], { fragment: 'invoicestatus' });

  }

  // this.outboxDetail = new Outbox();
  openWhatsAppModal(row: InvoiceTrackingStatus) {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);

    this.outboxDetail = new Outbox();
    this.outboxDetail.toPhoneNumber = "";
    this.outboxDetail.content = "Template";
    this.outboxDetail.headerMediaFileId = row.invoiceNumber;
    this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;

    if (!this.whatsappModal) {
      setTimeout(() => {
        if (this.whatsappModal) {
          this.whatsappModal.show();
        }
      });
    }
    else {
      this.whatsappModal.show();
    }
  }

  onSendReminder() {
    if (this.outboxDetail.toPhoneNumber && this.outboxDetail.toPhoneNumber.length >= 6) {
       this.sysproService.sendTemplateNoFile(this.outboxDetail.toPhoneNumber, this.outboxDetail.nextGenUser,
      this.outboxDetail.headerMediaFileId).subscribe(x => this.onWhatsappSentSuccesful(x), error => this.WhatsappSentFailed(error));
    }
  }

  whatsappModalHide() {
    this.outboxDetail = null;
    this.whatsappModal.hide();
  }

WhatsappSentFailed(error: any): void {
  this.alertService.stopLoadingMessage();
  this.loadingIndicator = false;

  this.alertService.showStickyMessage('Error', `Something went wrong: "${Utilities.getHttpResponseMessages(error)}"`,
    MessageSeverity.error, error);
}

onWhatsappSentSuccesful(x: any): void {
  console.log(x);
  this.alertService.stopLoadingMessage();
  this.loadingIndicator = false;
  this.alertService.showStickyMessage('', `"${x.message}"`, MessageSeverity.info);
  this.whatsappModalHide();
}

  viewInvoice(row: InvoiceTrackingStatus) {

    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoiceNumber);

    this.itsDetail = row;

    if (!this.itsModal) {
      setTimeout(() => {
        if (this.itsModal) {
          this.itsModal.show();
        }
      });
    }
    else {
      this.itsModal.show();
    }
  }

  itsModallHide() {
    this.itsDetail = null;
    this.itsModal.hide();
  }

  previewSalesOrder(row: InvoiceTrackingStatus) {

    if ((this.application == "MaxArcusOnline" && row.fromCompany == "Max Arcus") || (this.application == "FowkesOnline" && row.fromCompany == "Fowkes Bros")) {
      this.currentStatus = row.currentStatus;
      this.invoiceNumber = row.invoiceNumber;

      this.selectedSalesOrder = row.salesOrder;
      if (sessionStorage.getItem(localStorageActions.Customer) != row.customerCode) {
        sessionStorage.setItem(localStorageActions.Customer, row.customerCode);
      }
      if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
        sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
      }

      if (!!this.selectedSalesOrder) {
        if (!this.salesOrderModal) {
          setTimeout(() => {
            if (this.salesOrderModal) {
              this.salesOrderModal.show();
            }
          });
        }
        else {
          this.salesOrderModal.show();
        }
      }
    }
    else {
      this.alertService.showStickyMessage('Warning', `This is a "${row.fromCompany}" invoice and should be viewed from the correct application`,
        MessageSeverity.warn);
    }
  }

  salesOrderModalHide() {
    this.selectedSalesOrder = null;
    this.salesOrderModal.hide();
  }


  viewSalesOrder() {

    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  }



}
