
export class ITSDriverCages  {
  id: number;
  cageName: string;
  cageNumber: string | null;
  cageDescription: string | null;
  createDate: Date;
  createdBy: string;
  modifiedDate: Date | null;
  modifiedBy: string | null;

  cageDetails: ITSDriverCagesDetail[];

}

export class ITSDriverCagesDetail {
  id: number;
  cageId: number;
  areaDescription: string | null;
  areaId: number;
  province: string | null;
  city: string | null;
  suburb: string | null;
  streetCode: string | null;

}

