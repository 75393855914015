import { Component, TemplateRef, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { Brand } from '../../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CMStockCode } from '../../../models/cms-stockcode.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ITSDriverCages, ITSDriverCagesDetail } from '../../../models/invoice-tracking-driver-cages.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { TrackingAreas } from '../../../models/invoice-tracking-areas.model';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'tracking-cages',
  templateUrl: './tracking-cages.component.html',
  styleUrls: ['./tracking-cages.component.scss'],
  animations: [fadeInOut]
})
export class TrackingCagesComponent {

  columns: any[] = [];

  @ViewChild('cageModal')
  cageModal: ModalDirective;

  emptyDetails: boolean;

  rows: ITSDriverCages[] = [];
  rowsCache: ITSDriverCages[];

  selectedArea: TrackingAreas = new TrackingAreas();
  areas: TrackingAreas[] = [];

  @ViewChild('areasSelect')
  private areasSelect;

  @ViewChild('areasSelector')
  private areasSelector;

  driverCageDetail: ITSDriverCages = new ITSDriverCages();

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;


  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
    cardType: string;
    src: string;
    selectedCageDetails: ITSDriverCagesDetail[];
  selectedAreas: TrackingAreas[] = [];
  selectedSavedAreas: TrackingAreas[] = [];
  areasCache: TrackingAreas[];

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;
    existingCages: any;
    nextCageNumber: number;
  generatedCageCode: string;

  isNewCage: boolean;

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private fowkesService: FowkesOnlineService, private configurations: ConfigurationService) {
  }

  SetDataGrid() {

    this.columns = [
      { prop: 'province', name: 'Province', width: 150 },
      { prop: 'city', name: 'City', width: 150 },
      { prop: 'suburb', name: 'Suburb', width: 150 },
      { prop: 'streetCode', name: 'Street Code', width: 150 },
      {
        prop: 'select',
        name: 'Select',
        width: 80,
        sortable: false,
        cellTemplate: this.selectTemplate,
        cellClass: 'center'
      }
    ];
  }

  ngOnInit() {

    this.isNewCage = true;
    this.SetDataGrid();
    this.loadData();
    this.loadAreas();

  }


  ngAfterViewInit() {
    this.SetDataGrid();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getAllDriverCages().subscribe(
      (cages) => {
        this.fowkesService.getAllDriverCagesDetail().subscribe(
          (cageDetails) => {
            this.fowkesService.getAllTrackingAreas().subscribe(
              (areas) => {
                this.onDataLoadSuccessful(cages, cageDetails, areas);
              },
              (error: any) => {
                this.onDataLoadFailed(error);
              }
            );
          },
          (error: any) => {
            this.onDataLoadFailed(error);
          }
        );
      },
      (error: any) => {
        this.onDataLoadFailed(error);
      }
    );
  }

  loadAreas() {
    this.fowkesService.getAllTrackingAreas().subscribe((areas) => {
      let details = areas;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.areas = areas;
      this.areasCache = [...areas];
    });
  }

  onAreaSearch(searchTerm: string) {
    if (searchTerm.trim() === '') {
      this.areas = this.areasCache;
    } else {
      this.areas = this.areas.filter(area =>
        area.province.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area.suburb.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area.streetCode.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  onAreaSelectChange(row: TrackingAreas) {
    if (this.isNewCage) {
      if (row) {
        const selectedArea = this.areas.find((area) => area.id === row.id);
        if (selectedArea) {
          this.selectedAreas.push(selectedArea);
          this.removeItemFromMainList(row.id);
        }
      }
    }
    else {
      if (row) {
        const selectedArea = this.areas.find((area) => area.id === row.id);
        if (selectedArea) {

          //Add to db
          var cageDetails = new ITSDriverCagesDetail();          
          cageDetails.id = 0;
          cageDetails.cageId = this.driverCageDetail.id;
          cageDetails.areaDescription = `${selectedArea.province} ${selectedArea.city} ${selectedArea.suburb} ${selectedArea.streetCode}`;
          cageDetails.areaId = selectedArea.id;
          cageDetails.province = selectedArea.province;
          cageDetails.city = selectedArea.city;
          cageDetails.suburb = selectedArea.suburb;
          cageDetails.streetCode = selectedArea.streetCode;
          
          this.fowkesService.addNewDriverCageDetail(cageDetails).
            subscribe(x => { this.selectedSavedAreas.push(selectedArea); }, error => this.onDataLoadFailed(error));

          this.removeItemFromMainList(row.id);
        }
      }
    }
  }

  removeSelectedArea(area: TrackingAreas) {
    if (this.isNewCage) {
      const selectedArea = this.selectedAreas.find((area) => area.id === area.id);
      if (selectedArea) {
        this.areas.push(selectedArea);
        this.areas = [...this.areas];
        this.removeItemFromDetailList(selectedArea.id);
      }
    }
  }

  removeSelectedSavedArea(area: TrackingAreas) {
    if (!this.isNewCage) {
      const selectedArea = this.selectedAreas.find((area) => area.id === area.id);
      if (selectedArea) {
        this.areas.push(selectedArea);
        this.areas = [...this.areas];
        //Remove straight from db
        var savedArea = this.driverCageDetail.cageDetails.find(x => x.areaId === selectedArea.id)
        this.fowkesService.deleteDriverCageDetail(savedArea.id).
          subscribe(x => { this.removeItemFromSavedlList(savedArea.id) }, error => this.onDataLoadFailed(error));
      }
    }
  }

  isSelectedArea(area: TrackingAreas): boolean {
    return this.selectedAreas.some(a => a.id === area.id);
  }


  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.cageName, r.cageDescription, r.cageNumber));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }




  onDataLoadSuccessful(cages: ITSDriverCages[], cageDetails: ITSDriverCagesDetail[], areas: TrackingAreas[]) {
    // Assuming each cage can have multiple details, use a dictionary to store details by CageId
    const cageDetailsDict: { [key: number]: ITSDriverCagesDetail[] } = {};

    // Group ITSDriverCagesDetail by CageId using the dictionary
    for (const detail of cageDetails) {
      if (!cageDetailsDict[detail.cageId]) {
        cageDetailsDict[detail.cageId] = [];
      }
      cageDetailsDict[detail.cageId].push(detail);
    }

    // Populate the rows array with combined data
    this.rows = cages.map((cage) => {
      return {
        ...cage,
        cageDetails: cageDetailsDict[cage.id] || [], // Add cageDetails array to each cage object
      };
    });

    // Store a cache of rows to use in filtering
    this.rowsCache = this.rows.slice(0);

    // Store areas for use in the template
    this.areas = areas;
    this.areasCache = [...areas];

    this.calculateNextCageNumber();

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  calculateNextCageNumber() {
    this.existingCages = this.rows;
    // Sort the existing cage data based on the cage number in descending order
    this.existingCages.sort((a, b) => {
      const aNumber = parseInt(a.id);
      const bNumber = parseInt(b.id);
      return bNumber - aNumber;
    });

    // Get the highest cage number
    const highestCageNumber = this.existingCages.length > 0 ? parseInt(this.existingCages[0].id) : 0;

    // Calculate the next cage number
    this.nextCageNumber = highestCageNumber + 1;
    this.generatedCageCode = `CAGE${this.nextCageNumber.toString().padStart(5, '0')}`;
  }


  saveDriversCage() {

    if (this.isNewCage) {
      if (this.driverCageDetail && this.driverCageDetail.cageDescription) {
        // Generate cage number based on cage name
        this.driverCageDetail.cageNumber = this.generatedCageCode;
        this.driverCageDetail.cageName = this.driverCageDetail.cageDescription;

        // Check if the cage number already exists in the rowsCache
        if (this.rowsCache.some((x) => x.cageNumber === this.driverCageDetail.cageNumber)) {
          this.alertService.showMessage('Warning', `This Drive Cage already exists`, MessageSeverity.warn);
          return; // Stop execution if cage number exists
        }

        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');

        // Set createdBy and modifiedBy properties with the current user's email
        const currentUserEmail = this.accountService.currentUser.email;
        this.driverCageDetail.createdBy = currentUserEmail;
        this.driverCageDetail.modifiedBy = currentUserEmail;

        // Convert selectedAreas to ITSDriverCagesDetail format
        this.driverCageDetail.cageDetails = this.selectedAreas.map(area => ({
          id: 0,
          cageId: 0,
          areaDescription: `${area.province} ${area.city} ${area.suburb} ${area.streetCode}`,
          areaId: area.id,
          province: area.province,
          city: area.city,
          suburb: area.suburb,
          streetCode: area.streetCode
        }));

        // Create the DTO object to send both the header and details
        const driverCageDto: any = {
          DriverCage: this.driverCageDetail,
          CageDetails: this.driverCageDetail.cageDetails
        };

        // Call the service method to add a new driver cage
        this.fowkesService.addNewDriverCage(driverCageDto).subscribe(
          (result: ITSDriverCages) => {
            this.onSaveSuccessful();
          },
          (error: any) => {
            this.onDataLoadFailed(error);
          }
        );
      } else {
        this.alertService.showMessage('Warning', `Make sure to enter a cage description`, MessageSeverity.warn);
      }
    }
    else {
       this.onSaveSuccessful();
    }
    
  }


  onSaveSuccessful(): void {
    this.cageModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  newDriversCage() {
    this.isNewCage = true;
    this.driverCageDetail = new ITSDriverCages();
    this.calculateNextCageNumber();

    setTimeout(() => {
      if (this.areasSelector) {
        this.areasSelector.refresh();
      }
    });


    if (!this.cageModal) {
      setTimeout(() => {
        if (this.cageModal) {
          this.cageModal.show();
        }
      });
    }
    else {
      this.cageModal.show();
    }

  }

  showCageDetailsModal(cage: ITSDriverCages) {
    this.isNewCage = false;
    this.driverCageDetail = cage;
    this.generatedCageCode = cage.cageNumber;

    for (var cageArea of cage.cageDetails) {
      //Add area to edit list
      const selectedArea = this.areas.find((area) => area.id === cageArea.areaId);
      if (selectedArea) {
        this.selectedSavedAreas.push(selectedArea);
      }
      //Remove area from select list
      this.removeItemFromMainList(selectedArea.id);
    } 

    if (!this.cageModal) {
      setTimeout(() => {
        if (this.cageModal) {
          this.cageModal.show();
        }
      });
    } else {
      this.cageModal.show();
    }
  }

   removeItemFromMainList = (removeId) => {
     this.areas = this.areas.filter(obj => obj.id !== removeId);
  }


  removeItemFromDetailList = (removeId) => {
    this.selectedAreas = this.selectedAreas.filter(obj => obj.id !== removeId);
  }


  removeItemFromSavedlList = (removeId) => {
    this.selectedSavedAreas = this.selectedSavedAreas.filter(obj => obj.id !== removeId);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }


  enableEditMethod(row: ITSDriverCages, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }


  cageModalHide() {
    this.driverCageDetail = new ITSDriverCages();
    this.selectedAreas = [];
    this.cageModal.hide();
  }


  toggleEdit(row: ITSDriverCages, index: number) {
    this.enableEdit = !this.enableEdit;
    this.enableEditIndex = this.enableEdit ? index : null;

    if (!this.enableEdit) {
      // Reset changes and reload the data to cancel editing
      this.loadData();
    }
  }

  saveSegment(row: ITSDriverCages) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    row.createdBy = this.accountService.currentUser.email;
    this.fowkesService.updateDriverCage(row.id, row).subscribe(
      () => {
        this.onUpdateSuccessful();
      },
      (error: any) => {
        this.onDataLoadFailed(error);
      }
    );
  }

  onUpdateSuccessful(): void {
    this.isSaving = false;
    this.enableEdit = false;
    this.enableEditIndex = null;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  onSaveSuccesful(): void {

    this.cageModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  deleteRow(row: ITSDriverCages) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.cageName + ' - ' + row.cageNumber + '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: ITSDriverCages) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.fowkesService.deleteDriverCage(row.id).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.cageName + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  printCard(row: ITSDriverCages) {
    this.cardType = "CageCard";
    this.fowkesService.getPrintCardPDF(this.cardType, row.cageNumber, this.application).subscribe(x => this.onFileSuccessful(x, false, row.cageNumber), error => this.onDataLoadFailed(error));

  }


  onFileSuccessful(x: Blob, preview: boolean, id: string): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = this.cardType + "_" + id + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  
  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

}
