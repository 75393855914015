import { Component, ViewChild, TemplateRef } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CatTemplate } from '../../models/category-template.model';

@Component({
  selector: 'parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss'],
  animations: [fadeInOut]
})
export class ParentComponent {
    loadingIndicator: boolean;

  navItems: CatTemplate[] = [
    {
      displayName: "AngularMix",
      iconName: "close",
      children: [
        {
          displayName: "Speakers",
          iconName: "group",
          children: [
            {
              displayName: "Michael Prentice",
              iconName: "person",
              route: "michael-prentice",
              children: [
                {
                  displayName: "Delight your Organization",
                  iconName: "star_rate",
                  route: "material-design"
                }
              ]
            },
            {
              displayName: "Stephen Fluin",
              iconName: "person",
              route: "stephen-fluin",
              children: [
                {
                  displayName: "What's up with the Web?",
                  iconName: "star_rate",
                  route: "what-up-web"
                }
              ]
            },
            {
              displayName: "Mike Brocchi",
              iconName: "person",
              route: "mike-brocchi",
              children: [
                {
                  displayName: "My ally, the CLI",
                  iconName: "star_rate",
                  route: "my-ally-cli",
                  children: [
                    {
                      displayName: "Delight your Organization",
                      iconName: "star_rate",
                      route: "material-design"
                    }
                  ]
                },
                {
                  displayName: "Become an Angular Tailor",
                  iconName: "star_rate",
                  route: "become-angular-tailer"
                }
              ]
            }
          ]
        },
        {
          displayName: "Sessions",
          iconName: "speaker_notes",
          children: [
            {
              displayName: "Delight your Organization",
              iconName: "star_rate",
              route: "material-design",
              children: [
                {
                  displayName: "Delight your Organization",
                  iconName: "star_rate",
                  route: "material-design"
                }
              ]
            },
            {
              displayName: "What's up with the Web?",
              iconName: "star_rate",
              route: "what-up-web"
            },
            {
              displayName: "My ally, the CLI",
              iconName: "star_rate",
              route: "my-ally-cli"
            },
            {
              displayName: "Become an Angular Tailor",
              iconName: "star_rate",
              route: "become-angular-tailer"
            }
          ]
        },
        {
          displayName: "Feedback",
          iconName: "feedback",
          route: "feedback"
        }
      ]
    }
  ];

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    // this.sysproService.getBrands().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    // this.sysproService.getAllCMCategories().subscribe(x => this.onDataLoadSuccessful(x), error => this.onDataLoadFailed(error));
   // this.sysproService.GetAllCMStockCodes().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(items: any[]) {

  }
 
}
