import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError, from } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class ExternalHTTPService {
  private apiUrl = 'https://fowkes.co.za/api/next-gen/update-category-image.json'; // Target URL


  constructor(private http: HttpClient) {

  }

  updateCategoryImage(data: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.post(this.apiUrl, data, { headers });
  }

  //getTokenEndpoint(): Observable<any> {

  //  var dpdURL = "https://swatws.dawnwing.co.za/dwwebservices/v2/live/api/Token";

  //  const header = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods': 'POST' });
  

  //  const body = {
  //    userName: "FOWKESBROS", password: "FoWK3SBR0S" };


  //  return this.http.post<any>(dpdURL, [body]);

  //}

  //getData(): Observable<any> {
  //  const formData = {
  //    'userName': 'FOWKESBROS',
  //    'password': 'FoWK3SBR0S'
  //  }

  //  return from(
  //    fetch(
  //      'https://swatws.dawnwing.co.za/dwwebservices/v2/live/api/Token', // the url you are trying to access
  //      {
  //        headers: {
  //          'Content-Type': 'application/json',
  //        },
  //        method: 'POST', // GET, POST, PUT, DELETE
  //        mode: 'no-cors', // the most important option
  //        body: 'userName=FOWKESBROS&password=FoWK3SBR0S'
  //      }
  //    ));
  //}

}
