

export class CMSAdditionalImage {
  constructor(id?: number, referenceType?: string, referenceId?: number, stockCode?: string, imageName?: string, display?: boolean,
    modifiedDate?: Date, filePath?: string, imageIndex?: number) {
    this.id = id;
    this.referenceType = referenceType;
    this.referenceId = referenceId;
    this.stockCode = stockCode;
    this.imageName = imageName;
    this.filePath = filePath;
    this.display = display;
    this.modifiedDate = modifiedDate;
    this.imageIndex = imageIndex;
  }

  public id: number;
  public referenceType: string;
  public referenceId: number;
  public stockCode: string;
  public imageName: string;
  public filePath: string;
  public display: boolean;
  public modifiedDate: Date;//null;
  public imageIndex: number;
  public safeURL: any;
}
