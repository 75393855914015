
export class InvoiceHeader {
  constructor(invoiceNumber?: string, salesOrderNumber?: string, customer?: string, branch_code?: string, customerName?: string,
    telephone?: string, addTelephone?: string, fax?: string, email?: string, companyTaxNumber?: string, taxStatus?: string, taxExemptNumber?: string,
    contact?: string, soldToAddr1?: string, soldToAddr2?: string, soldToAddr3?: string, soldToAddr4?: string, soldToAddr5?: string, soldPostalCode?: string,
    shipToAddr1?: string, shipToAddr2?: string, shipToAddr3?: string, shipToAddr4?: string, shipToAddr5?: string, shipPostalCode?: string,
    area?: string, salesperson?: string, shipDate?: Date, orderDate?: Date, createDate?: Date,
    buyersName?: string, buyersEmail?: string, vatPercentage?: number, originalOperator?: string, originalOperatorName?: string) {

    this.invoiceNumber = invoiceNumber;
    this.salesOrderNumber = salesOrderNumber;
   // this.userid = userid;
    this.customer = customer;
    this.branch_code = branch_code;
    this.customerName = customerName;
    this.telephone = telephone;
    this.addTelephone = addTelephone;
    this.email = email;
    this.fax = fax;
    this.companyTaxNumber = companyTaxNumber;
    this.area = area;
    this.salesperson = salesperson;
    this.contact = contact;
    this.taxExemptNumber = taxExemptNumber;
    this.taxStatus = taxStatus;
    this.soldtoaddr1 = soldToAddr1;
    this.soldtoaddr2 = soldToAddr2;
    this.soldtoaddr3 = soldToAddr3;
    this.soldtoaddr4 = soldToAddr4;
    this.soldtoaddr5 = soldToAddr5;
    this.soldpostalcode = soldPostalCode;
    this.shiptoaddr1 = shipToAddr1;
    this.shiptoaddr2 = shipToAddr2;
    this.shiptoaddr3 = shipToAddr3;
    this.shiptoaddr4 = shipToAddr4;
    this.shiptoaddr5 = shipToAddr5;
    this.shippostalcode = shipPostalCode;
    this.shipdate = shipDate;
    this.orderdate = orderDate;
    this.createdate = createDate;
    this.alternateKey = "";
    this.buyersName = buyersName;
    this.buyersEmail = buyersEmail;
    this.vatPercentage = vatPercentage;
    this.originalOperator = originalOperator;
    this.originalOperatorName = originalOperatorName;
  }

  public invoiceNumber: string;
  public salesOrderNumber: string;
  //public userid: string;
  public branch_code: string;
  public customer: string;
  public customerName: string;
  public telephone: string;
  public addTelephone: string;
  public fax: string;
  public email: string;
  public companyTaxNumber: string;
  public taxStatus: string;
  public taxExemptNumber: string;
  public soldtoaddr1: string;
  public soldtoaddr2: string;
  public soldtoaddr3: string;
  public soldtoaddr4: string;
  public soldtoaddr5: string;
  public soldpostalcode: string;
  public shiptoaddr1: string;
  public shiptoaddr2: string;
  public shiptoaddr3: string;
  public shiptoaddr4: string;
  public shiptoaddr5: string;
  public shippostalcode: string;
  public shippingInstrs: string;
  public specialInstrs: string;
  public area: string;
  public salesperson: string;
  public contact: string;
  public telex: string;
  public customerPoNumber: string;
  public alternateKey: string;
  public shipdate: Date;
  public orderdate: Date;
  public ordertype: string;
  public orderdiscountpercentage: number;
  public createdate: Date;
  public totalIncl: number;
  public totalExcl: number;
  public totalDiscount: number;
  public displayDate: string;
  public orderStatus: string;
  public buyersName: string;
  public buyersEmail: string;
  public operator: string;
  public salesPersonName: string;
  public vatPercentage: number;
  public originalOperator: string;
  public originalOperatorName: string;
  public itsInvoice: string;
  public statusDescription: string;

}

