import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { PrinterEndpoint } from './printer-endpoint.service';
import { Printers } from '../models/printers.model';
import { PrinterUserMappings } from '../models/printer-user-mappings.model';
import { CoreAudit } from '../models/core-audit.model';
import { User } from '../models/user.model';

@Injectable()
export class PrinterService {

  constructor(
    private printerEndpoint: PrinterEndpoint) {

  }

  GetPrinters() {
    return this.printerEndpoint.getPrintersEndpoint<Printers[]>();
  }
  
  GetUserPrinterMappings(userId?: string) {
    return this.printerEndpoint.getUserPrinterMappingsEndpoint<PrinterUserMappings[]>(userId);
  }


  PrintInvoice(salesOrder: string, original: boolean, userDetail: User) {
    return this.printerEndpoint.postPrintInvoiceEndpoint<CoreAudit>(salesOrder, original, userDetail);
  }

  postEFTToFile(paymentRunNumber: string, postObject: any) {
    return this.printerEndpoint.postEFTToFileEndpoint<CoreAudit>(paymentRunNumber, postObject);
  }

  getFileStream(fileName?: string) {
    return this.printerEndpoint.getFileStreamEndpoint<Blob>(fileName);
  }


  updateSysproPayRun(paymentRunNumber?: string) {
    return this.printerEndpoint.updateSysproPayRunEndpoint<any>(paymentRunNumber);
  }


  editPrinter(printer: Printers, id: number) {
    return this.printerEndpoint.editPrinterEndpoint<Printers>(printer, id);
  }

  newPrinter(printer: Printers) {
    return this.printerEndpoint.newPrinterEndpoint<Printers>(printer);
  }

  deletePrinter(id: number) {
    return this.printerEndpoint.deletePrinterEndpoint<Printers>(id);
  }

}
