export class InfoBoardNotes {
  constructor(note?: string, status?: string) {
    this.note = note;
    this.status = status;
  }

  public id: number;
  public informationBoardName: string;
  public note: string;
  public heading: string;
  public status: string;
  public createdDate: string;
  public createdBy: string;
  public documentName: string;
  public modifiedDate: string;
  public modifiedBy: string;

}
