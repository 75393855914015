export class ValueHelper {
  
  constructor() {

  }

  public displayValue: string;
  public dataValue: number;
  
}
