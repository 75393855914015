
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { CustomerMovements } from 'src/app/models/customer-movements.model';
import { localStorageActions } from 'src/app/models/enums';
import { CMCategories } from '../../../models/cms-category.model';
import { CoreCustomerPrices } from '../../../models/core-customer-prices.model';
import { CoreCustomerRule } from '../../../models/core-customer-rule.model';
import { CoreCustomer } from '../../../models/core-customer.model';
import { Customer } from '../../../models/customer.model';
import { WHAvailQTY } from '../../../models/inventory-wh.model';
import { Inventory } from '../../../models/inventory.model';
import { Permission } from '../../../models/permission.model';
import { SimpleTree } from '../../../models/simple-tree.model';
import { Supplier } from '../../../models/supplier.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { Calculations } from '../../../services/calculations.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';



@Component({
  selector: 'core-customer-settings',
  templateUrl: './core-customer-settings.component.html',
  styleUrls: ['./core-customer-settings.component.scss']
})
export class CoreCustomerSettingsComponent implements OnInit, AfterViewInit {


  columns: any[] = [];
  priceColumns: any[] = [];

  loadingIndicator: boolean;
  private selectUndefinedOptionValue: string = "Please Select";

  //Customer Search
  searchType: string = "";
  searchText: string = "";
  searchResults: ArCustomer[] = [];
  searchResultSelected: ArCustomer;

  @ViewChild('searchResultSelector')
  private searchResultSelector;

  //Stock Code Search
  searchOption: string = "";
  searchValue: string = "";
  searchResultsStock: Inventory[] = [];
  searchResultSelectedStock: Inventory;

  @ViewChild('searchResultStockSelector')
  private searchResultStockSelector;

  whDetail: WHAvailQTY[] = [];

  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;

  @ViewChild('ruleSupplierModal')
  ruleSupplierModal: ModalDirective;
  @ViewChild('currentSupplier')
  currentSupplier: Supplier;
  @ViewChild('suppliers')
  private suppliers;
  @ViewChild('supplierSelector')
  private supplierSelector;
  suppliersCache: Supplier[] = [];
  selectedSupplier: string = "";


  @ViewChild('ruleCategoryModal')
  ruleCategoryModal: ModalDirective;

  items: TreeviewItem[];
  treeItems: TreeviewItem[] = [];
  treeItem: any;
  treerows: string[];
  item: any;
  data: CMCategories;
  categoryData: CMCategories[];

  breadcrumbs: SimpleTree[] = [];

  seletedItem: TreeviewItem;

  expandIndex: Number = -1;

  config: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 1000,
    hasDivider: false
  };

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  @ViewChild(TreeviewComponent)
  categoryTreeviewComponent: TreeviewComponent;

  @ViewChild('treeModal')
  treeModal: ModalDirective;

  @ViewChild('treeItemTemplate')

  @ViewChild('ruleStockCodeModal')
  ruleStockCodeModal: ModalDirective;


  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;


  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;


  @ViewChild('warehouseTemplate')
  warehouseTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('discountTemplate')
  discountTemplate: TemplateRef<any>;

  @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('sellpriceTemplate')
  sellpriceTemplate: TemplateRef<any>;

  @ViewChild('unitCostTemplate')
  unitCostTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('specialTemplate')
  specialTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;


  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();


  @Input()
  isViewOnly: boolean;

  @Input()
  isManagement: boolean;

  @Input()
  isSearch: boolean;
  id: string;


  newParentName: string;
  specialPriceRow: Inventory;
  supplierDiscountRow: Inventory;
  categoryDiscountRow: Inventory;
  generalPriceRow: Inventory;


  coreCustomer: CoreCustomer = new CoreCustomer();
  rowCustomerRules: CoreCustomerRule[] = [];
  rowCustomerRulesSpecialPrice: CoreCustomerRule[] = [];
  rowCustomerRulesSupplierDiscount: CoreCustomerRule[] = [];
  rowCustomerRulesCategoryDiscount: CoreCustomerRule[] = [];
  rowCustomerRulesCache: CoreCustomerRule[] = [];
  rowCustomerPrices: CoreCustomerPrices[] = [];
  rowCustomerPricesCache: CoreCustomerPrices[] = [];
  coreCustomers: CoreCustomer[];
  customerRule: CoreCustomerRule;
  stockCodedetail: Inventory;
  stockDescription: any;
  stockCode: string;
  rowsCache: Inventory[];
  currentDiscountId: number;
  priceRows: CoreCustomerPrices[];
  priceRowsCache: CoreCustomerPrices[];
  validated: boolean;
  recordsToProcess: number;
  recordsProcessed: number;
    buyerResponsible: string;
    originalPriceRow: CoreCustomerPrices;
    bulkLoad: boolean;
  inventoryList: Inventory[] = [];
    hasWarnings: boolean;


  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.searchOption = "stockcode";
    this.searchType = "customer";
    this.newParentName = "";

    this.setGrid();

    this.loadData();

    this.setGeneralDiscount();
  }
  setGrid() {
    this.columns = [

      { prop: 'code', name: 'Reference', width: 200, cellTemplate: this.stockCodeTemplate, cellClass: "left" },
     /*  { prop: 'ruleName', name: 'Category', width: 150, cellClass: "left" },*/
      { prop: 'addText', name: 'Description', width: 300, cellClass: "left" },
      { prop: 'value', name: 'Less Discount %', width: 100, cellTemplate: this.discountTemplate, cellClass: "right" }
     , { prop: 'addValue', name: 'Cost Plus %', width: 100, cellTemplate: this.priceTemplate, cellClass: "right" }
    ];

    this.columns.push({ name: 'Actions', width: 200, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    this.priceColumns = [

      { prop: 'stockCode', name: 'Stock Code', width: 120, cellTemplate: this.warehouseTemplate, cellClass: "left" },
      { prop: 'description', name: 'Description', width: 280, cellClass: "left" }, 
      { prop: 'sellingPrice', name: 'Selling Price', width: 80, cellTemplate: this.priceTemplate, cellClass: "right" },
      { prop: 'costPrice', name: 'Unit Cost', cellTemplate: this.priceTemplate, width: 80, cellClass: "right" },
      { prop: 'addValue', name: 'Plus %', cellTemplate: this.priceTemplate, width: 80, cellClass: "right" },
      { prop: 'newPrice', name: 'Special Price', width: 80, cellTemplate: this.priceTemplate, cellClass: "right" },
      { prop: 'lastCostChange', name: 'Date Last Cost Change', cellTemplate: this.dateTemplate, width: 90, cellClass: "right" }
    ];

    this.priceColumns.push({ name: 'Actions', width: 180, cellTemplate: this.specialTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
  }

  setGeneralDiscount() {

    this.generalPriceRow = new Inventory();
    this.generalPriceRow.specialName = "General Discount";
    this.generalPriceRow.specialCostAdd = 0.00;
    this.generalPriceRow.specialDiscount = 0.00;
    this.generalPriceRow.specialPriority = 4;
    this.generalPriceRow.categoryId = 0;
    this.generalPriceRow.stockCode = "General";
    this.generalPriceRow.description = "";
  }


  ngAfterViewInit() {

    this.setGrid();
  }


  loadData() {

    this.hasWarnings = false;
    let action = sessionStorage.getItem(localStorageActions.Customer);
    this.searchType = "customer";

    if (action && action.length >= 4) {
      this.id = action;
      this.currentCustomer = this.id;
      this.searchText = this.id;

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getCustomerDetail(this.id).subscribe(
        x => this.onSpecificSearchSuccesful([x]), error => this.onDataLoadFailed(error));
    }

    this.sysproService.getSuppliersForCustomerPricing().subscribe(x => this.onSupplierLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getAllCMCatergoriesTree(this.expandIndex).subscribe(x => this.onCategoriesLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    this.sysproService.getInventoryForBranch(this.branchCode).subscribe(x => this.onAllStockLoadSuccessful([x]), error => this.onDataLoadFailed(error));
  }
   


  onSpecificSearchSuccesful(x: ArCustomer[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {
      this.clearScreen();
      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }
  }

  clearScreen() {
    this.rowCustomerRulesCache = null;
    this.rowCustomerRules = this.rowCustomerRulesCache;
    this.rowCustomerRulesSpecialPrice = null;
    this.rowCustomerRulesSupplierDiscount = null;
    this.rowCustomerRulesCategoryDiscount = null;
    this.rowCustomerPrices = null;
    this.rowCustomerPricesCache = null;

    this.currentDiscountId = 0;
    this.generalPriceRow.specialCostAdd = 0.00;
    this.generalPriceRow.specialDiscount = 0.00;
    this.hasWarnings = false;

    this.searchResultsStock = null;
    this.searchResultSelectedStock = null;

  }

  private loadCustomer() {
    this.id = this.searchResultSelected.customer;
    this.currentCustomer = this.id;

    this.clearScreen();

    sessionStorage.removeItem(localStorageActions.Customer);
    sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);

    this.sysproService.getCustomerDetail(this.id).subscribe(
      x => { this.customerHeading = x;}, error => this.onDataLoadFailed(error));
        

    this.loadDetails();
    this.router.navigate(['../../customers'], { fragment: 'settings', queryParams: { customer: this.currentCustomer } });
  }


  loadDetails() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.coreCustomer = new CoreCustomer();
    this.sysproService.getPricingCustomer(this.id, this.application).subscribe(x => this.onCoreCustomerLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onCoreCustomerLoadSuccessful(x: CoreCustomer[]): void {
    if (x && x.length == 1) {
      if (x[0].customerCode != "") {

        this.coreCustomer = x[0];
        this.sysproService.getAllPricingCustomerRules(this.coreCustomer.id).subscribe(x => this.onPricingRulesLoadSuccessful([x]), error => this.onDataLoadFailed(error));
        this.sysproService.getAllPricingCustomerPricesForCustomerCode(this.id, this.application).subscribe(x => this.onPricesForCustomerLoadSuccessful(x), error => this.onDataLoadFailed(error));

      }
    }
    if (!this.coreCustomer.id || this.coreCustomer.id <= 0) {
      this.coreCustomer.customerCode = this.id;
      this.coreCustomer.company = this.application;
      this.coreCustomer.enabled = true;
      this.coreCustomer.createdBy = this.accountService.currentUser.email;
      this.coreCustomer.staffAccount = false;
      this.sysproService.addNewPricingCustomer(this.coreCustomer).subscribe(x => this.onCoreCustomerCreatedSuccessful(x), error => this.onNewCustomerAddedFailed(error));
    }
  }

  onCoreCustomerCreatedSuccessful(x: CoreCustomer): void {
    this.coreCustomer = x;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }

  onNewCustomerAddedFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to update customer.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  onPricingRulesLoadSuccessful(x: CoreCustomerRule[][]): void {
    this.currentDiscountId = 0;
    if (x && x.length >= 1) {
      var ruleList = x[0];

      this.rowCustomerRulesCache = [...ruleList];
      this.rowCustomerRules = this.rowCustomerRulesCache;
      this.rowCustomerRulesSpecialPrice = this.rowCustomerRulesCache.filter(x => x.priority == 1).filter(s => s.ruleName == "Special Price");
      this.rowCustomerRulesSupplierDiscount = this.rowCustomerRulesCache.filter(x => x.priority == 2);
      this.rowCustomerRulesCategoryDiscount = this.rowCustomerRulesCache.filter(x => x.priority == 3);

      var generalDiscount = this.rowCustomerRules.find(result => result.priority == 4);
      if (generalDiscount) {
        this.currentDiscountId = generalDiscount.id;
        this.generalPriceRow.specialCostAdd = generalDiscount.addValue;
        this.generalPriceRow.specialDiscount = generalDiscount.value;
      }

    }
  }


  onPricesForCustomerLoadSuccessful(x: CoreCustomerPrices[]): void {
    if (x && x.length >= 1) {
      this.rowCustomerPricesCache = x;
      this.rowCustomerPrices = this.rowCustomerPricesCache.filter(r => r.priority == 1).filter(s => s.ruleName == "Special Price");
      this.rowCustomerPrices.forEach((price, index, inventory) => {
        if (new Date(price.lastCostChange).toISOString().split("T")[0] >= new Date(price.modifiedOn).toISOString().split("T")[0]) {
          price.warning = true;
          this.hasWarnings = true;
        }
      });
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  viewCustomerSelected() {
    if (this.searchResultSelected) {
      this.loadCustomer();
    }
  }



  //Stock Codes
  specificSearchStockCode() {
    if (this.searchOption && this.searchValue != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResultsStock = null;
      this.searchResultSelectedStock = null;

      this.sysproService.getStockCodeSearchByCriteria(this.branchCode, this.searchOption, this.searchValue.trim().replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(
        x => this.onStockLoadSuccessful([x]), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  onStockLoadSuccessful(x: any[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      var inventoryList = x[0];
      this.searchResultsStock = [...inventoryList]

      if (this.searchResultsStock.length === 1) {
        this.searchResultSelectedStock = this.searchResultsStock[0];

        this.viewStockCodeSelected();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Stock Code could not be found',
        MessageSeverity.warn);
    }

  }

  showStockDetail(row: any, event) {

    if (row.ruleName == "Special Price") {

      this.stockCode = row.code;

      if (!!this.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  stockCodeModalHide() {
    this.stockCode = null;
    this.stockCodeModal.hide();
  }


  viewStockCodeSelected() {
    if (this.rowCustomerRulesSpecialPrice && this.rowCustomerRulesSpecialPrice.length >= 1 && this.rowCustomerRulesSpecialPrice.find(x => x.code == this.searchResultSelectedStock.stockCode)) {
      this.alertService.showMessage('Duplicate', this.searchResultSelectedStock.stockCode + ' - This stock code has already been added',
        MessageSeverity.warn);
    }
    else {
      this.validated = false;
      this.specialPriceRow = new Inventory();
      this.specialPriceRow = this.searchResultSelectedStock;

      this.specialPriceRow.specialName = "Special Price";
      this.specialPriceRow.specialCostAdd = 0;
      this.specialPriceRow.specialDiscount = 0;
      this.specialPriceRow.specialPriority = 1;
      this.specialPriceRow.specialPrice = 0.00;
    }
  }


  viewSpecialPriceSelected(row: CoreCustomerPrices) {

    this.validated = false;
    this.specialPriceRow = new Inventory();
    this.specialPriceRow.stockCode = row.stockCode;
    this.specialPriceRow.description = row.description;
    this.specialPriceRow.unitCost = row.costPrice;
    this.specialPriceRow.sellingPrice = row.sellingPrice;
    this.specialPriceRow.priceConvFac = row.conversionFactor;
    this.specialPriceRow.uom = row.uom;

    this.specialPriceRow.specialName = "Special Price";
    this.specialPriceRow.specialCostAdd = row.addValue;
    this.specialPriceRow.specialDiscount = row.value;
    this.specialPriceRow.specialPriority = 1;
    this.specialPriceRow.specialPrice = row.newPrice;

    this.originalPriceRow = row;

    if (!!row.stockCode) {
      if (!this.ruleStockCodeModal) {
        setTimeout(() => {
          if (this.ruleStockCodeModal) {
            this.ruleStockCodeModal.show();
          }
        });
      }
      else {
        this.ruleStockCodeModal.show();
      }
    }
  }

  ruleStockCodeModalHide() {
    this.specialPriceRow = null;
    this.originalPriceRow = null;
    this.ruleStockCodeModal.hide();

  }

  calcSpecialPrice(row: Inventory): any {
    let total = 0;
    if (row.specialPrice > 0) {
      row.specialCostAdd = Calculations.getSpecialPriceCostPlus(row.unitCost, row.specialPrice, row.priceConvFac, 0);
      row.specialDiscount = 0;
      var specialUnitPrice = (row.specialPrice / row.priceConvFac);
      row.specialGP = Calculations.calculateGPLineTotal(row.unitCost, specialUnitPrice, row.priceConvFac, row.specialGP);

      this.saveStockCodeRule(row);

    }
    else {
      this.alertService.showDialog('Please enter a special price. ', DialogType.confirm, () => this.clearRowHelper(row));
    }

  }

  editSpecialPrice(row: Inventory): any {
    let total = 0;
    if (row.specialPrice > 0) {
      row.specialCostAdd = Calculations.getSpecialPriceCostPlus(row.unitCost, row.specialPrice, row.priceConvFac, 0);
      row.specialDiscount = 0;
      var specialUnitPrice = (row.specialPrice / row.priceConvFac);
      row.specialGP = Calculations.calculateGPLineTotal(row.unitCost, specialUnitPrice, row.priceConvFac, row.specialGP);

      
     // this.saveStockCodeRule(row);

    }
    else {
      this.alertService.showDialog('Please enter a special price. ', DialogType.confirm, () => this.clearRowHelper(row));
    }

  }
  editSpecialCost(row: Inventory): any {
    let total = 0;
    if (row.specialCostAdd > 0) {

      total = Calculations.getSpecialPriceTotal(row.unitCost, 0, row.specialCostAdd, 0, row.priceConvFac, 0);
      row.specialPrice = total;
      row.specialDiscount = 0;
      var specialUnitPrice = (row.specialPrice / row.priceConvFac);
      row.specialGP = Calculations.calculateGPLineTotal(row.unitCost, specialUnitPrice, row.priceConvFac, row.specialGP);


      // this.saveStockCodeRule(row);

    }
    else {
      this.alertService.showDialog('Please enter a special price. ', DialogType.confirm, () => this.clearRowHelper(row));
    }

  }

  saveNewSpecialPrice(row: Inventory): any {
    
    if (row.specialPrice > 0) {
      this.saveStockCodeRule(row);
     // this.sysproService.updatePricingCustomerRule()
      //this.sysproService.deletePricingCustomerRule(this.originalPriceRow.ruleId).subscribe(results =>  this.saveStockCodeRule(row)
      //  , error => this.onNewRuleAddedFailed(error)); 

    }
    else {
      this.alertService.showDialog('Please enter a special price. ', DialogType.confirm, () => this.clearRowHelper(row));
    }

  }

  applyPriceHelper(reccommendedPrice: number, row: Inventory): any {
    this.specialPriceRow.specialCostAdd = 25;
    this.specialPriceRow.specialPrice = reccommendedPrice;
    this.specialPriceRow.specialGP = Calculations.calculateGPLineTotal(row.unitCost, row.specialPrice, row.priceConvFac, row.specialGP);


    this.saveStockCodeRule(row);
  }

  clearRowHelper(row: Inventory): any {
    if (row.specialName == "General Discount") {
      this.generalPriceRow.specialDiscount = 0;
      this.generalPriceRow.specialCostAdd = 0;
      this.generalPriceRow.specialPrice = 0;
    }
    if (row.specialName == "Category") {
      this.categoryDiscountRow.specialDiscount = 0;
      this.categoryDiscountRow.specialCostAdd = 0;
      this.categoryDiscountRow.specialPrice = 0;
    }
    if (row.specialName == "Supplier") {
      this.supplierDiscountRow.specialDiscount = 0;
      this.supplierDiscountRow.specialCostAdd = 0;
      this.supplierDiscountRow.specialPrice = 0;
    }
  }

  saveStockCodeRule(row: Inventory) {
    var GPValid = (row.specialGP < 20) ? false : true;
    if (!GPValid && !this.validated) {
      this.validated = true;
      var reccommendedPrice = (Calculations.getSpecialPriceTotal(row.unitCost, 0, 25, row.specialDiscount, row.priceConvFac, 0) * row.priceConvFac);
      this.alertService.showDialog('The GP% is less than the minimum recommended 20%, do you want to apply the automatic price of \"R' + reccommendedPrice.toFixed(2) + '\"?',
        DialogType.confirm, () => this.overridePriceHelper(row), () => this.applyPriceHelper(reccommendedPrice, row),  "Apply Special Price", "Use Recommended Price");
    }
    else {
      if (this.currentCustomer && this.currentCustomer != "") {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        if (this.coreCustomer && this.coreCustomer.id > 0) {
          //Edit
          this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
          this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
            this.alertService.showStickyMessage('Success', 'Customer Updated',
              MessageSeverity.info)
          }, error => this.onNewCustomerAddedFailed(error));
        }

        let customerRule = this.setNewRule(row);
        if (this.originalPriceRow && this.originalPriceRow.ruleId && this.originalPriceRow.ruleId > 1) {
          //let id = this.originalPriceRow.ruleId;
          let ruleToEdit = this.rowCustomerRulesCache.find(r => r.id == this.originalPriceRow.ruleId);
          ruleToEdit.addText = customerRule.addText;
          ruleToEdit.addValue = customerRule.addValue;
          ruleToEdit.ruleDescription = customerRule.ruleDescription;
          ruleToEdit.ruleName = customerRule.ruleName;
          ruleToEdit.value = customerRule.value;

          this.sysproService.updatePricingCustomerRule(ruleToEdit, ruleToEdit.id, this.application, this.branchCode).subscribe(x =>
              this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));

        }
        else {

          this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));

        }
        this.specialPriceRow = null;
        //this.ruleStockCodeModalHide();

      }
      else {
        this.alertService.showStickyMessage('Warning', 'Please remember to first search for a customer',
          MessageSeverity.warn);
      }
    }
  }

  saveSupplierRule(row: Inventory) {

    if (this.currentCustomer && this.currentCustomer != "") {
      if (row.specialCostAdd > 0 && row.specialDiscount > 0) {
        this.alertService.showDialog('Please enter either a discount percentage or cost plus value. ', DialogType.confirm, () => this.clearRowHelper(row));
      }
      else {

        //Check supplier hasnt already been added


        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        if (this.coreCustomer && this.coreCustomer.id > 0) {
          //Edit
          this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
          this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
            this.alertService.showStickyMessage('Success', 'Customer Updated',
              MessageSeverity.info)
          }, error => this.onNewCustomerAddedFailed(error));
        }

        let customerRule = this.setNewRule(row);

        if (row.id >= 1) {
          this.sysproService.deletePricingCustomerRule(row.id).subscribe(x => { this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error)); }, error => this.onNewRuleAddedFailed(error));
        }
        else {

          this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));
        }

        this.ruleSupplierModalHide();
      }

    }
    else {
      this.alertService.showStickyMessage('Warning', 'Please remember to first search for a customer',
        MessageSeverity.warn);
    }


  }

  //async recalculateCategories() {

  //  if (this.rowCustomerRulesCategoryDiscount && this.rowCustomerRulesCategoryDiscount.length >= 1) {     
  //    this.bulkLoad = true;
  //    for (var rule of this.rowCustomerRulesCategoryDiscount) {
  //      let id = rule.id;
       
  //      if (id >= 1) {
  //        this.alertService.startLoadingMessage();
  //        this.loadingIndicator = true;
  //        this.alertService.showMessage('Updating', `Recalculating ` + rule.code, MessageSeverity.info);

  //        await this.sysproService.updatePricingCustomerRule(rule, id).subscribe(x => {

  //          console.log(id);
  //          this.sysproService.deleteAllPricingCustomerPricesForRule(id).subscribe(x => {
              
  //            this.onAutoCustomerRuleAdded(rule);
  //          }, error => this.onNewRuleAddedFailed(error));

  //        }, error => this.onNewRuleAddedFailed(error));

  //      }
  //    }
  //    //this.alertService.showMessage('Success', `Updated All ` + this.rowCustomerRulesCategoryDiscount.length, MessageSeverity.success);
  //    //this.bulkLoad = false;
  //    //this.loadData();

  //  }
  //}


  //onAutoCustomerRuleAdded(customerRule: CoreCustomerRule): void {
  //  console.log(customerRule);
  //    //Get all stock codes for category tree and apply rule to each stock code
  //  this.sysproService.getStockCodePriceDetailForCategory(customerRule.categoryId)
  //      .subscribe(x => this.stockCodesSelected(x, customerRule), error => this.onDataLoadFailed(error));
  //  }

  saveCategoryRule(row: Inventory) {
    if (this.currentCustomer && this.currentCustomer != "") {
      if (row.specialCostAdd > 0 && row.specialDiscount > 0) {
        this.alertService.showDialog('Please enter either a discount percentage or cost plus value. ', DialogType.confirm, () => this.clearRowHelper(row));
      }
      else {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        if (this.coreCustomer && this.coreCustomer.id > 0) {
          //Edit
          this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
          this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
            this.alertService.showStickyMessage('Success', 'Customer Updated',
              MessageSeverity.info)
          }, error => this.onNewCustomerAddedFailed(error));
        }

        let customerRule = this.setNewRule(row);
        if (row.id >= 1) {
          this.sysproService.deletePricingCustomerRule(row.id).subscribe(x => { this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error)); }, error => this.onNewRuleAddedFailed(error));
        }
        else {

          this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));
        }

        this.ruleCategoryModalHide();
      }

    }
    else {
      this.alertService.showStickyMessage('Warning', 'Please remember to first search for a customer',
        MessageSeverity.warn);
    }


  }

  saveGeneralRule(row: Inventory) {
    if (this.currentCustomer && this.currentCustomer != "") {
      if (row.specialCostAdd > 0 && row.specialDiscount > 0) {
        this.alertService.showDialog('Please enter either a discount percentage or cost plus value. ', DialogType.confirm, () => this.clearRowHelper(row));
      }
      else {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        if (this.coreCustomer && this.coreCustomer.id > 0) {
          //Edit
          this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
          this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
            this.alertService.showStickyMessage('Success', 'Customer Updated',
              MessageSeverity.info)
          }, error => this.onNewCustomerAddedFailed(error));
        }

        var customerRule = this.setNewRule(row);
        if (this.currentDiscountId && this.currentDiscountId > 0) {
          customerRule.id = this.currentDiscountId;
          customerRule.modifiedBy = this.accountService.currentUser.email;
          this.sysproService.updatePricingCustomerRule(customerRule, this.currentDiscountId, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));
        }
        else {

          this.sysproService.addNewPricingCustomerRule(customerRule, this.application, this.branchCode).subscribe(x => this.onNewCustomerRuleAdded(x, row), error => this.onNewRuleAddedFailed(error));
        }
      }


      // this.generalPriceRow = null;

    }
    else {
      this.alertService.showStickyMessage('Warning', 'Please remember to first search for a customer',
        MessageSeverity.warn);
    }
  }

  deleteGeneralRule(row: CoreCustomerRule) {

    if (this.currentDiscountId && this.currentDiscountId > 0) {
      this.alertService.startLoadingMessage('Deleting...');
      this.loadingIndicator = true;

      row.id = this.currentDiscountId;
      this.sysproService.deletePricingCustomerRule(row.id).subscribe(results => {

        this.loadData();

        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showMessage('Success', ` Successfully deleted`, MessageSeverity.success);
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }

  onNewCustomerRuleAdded(x: CoreCustomerRule, row: Inventory): void {

    let customerRule = x;
    if (customerRule && customerRule.id) {
      this.alertService.showStickyMessage('Success', 'Pricing Rule Updated',
        MessageSeverity.info);

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.originalPriceRow = null;
      this.loadData();
      //Apply Rule
     // this.handleRuleApplication(customerRule, row);
    }
  }

  //updateRule(row: CoreCustomerRule) {

  //  //Find and remove any existing prices for that rule
  //  this.sysproService.deleteAllPricingCustomerPricesForRule(row.id).subscribe(x => this.onRuleCleared(row), error => this.onRuleClearedFailed(error));

  //}

  //onRuleClearedFailed(error: any): void {
  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;

  //  this.alertService.showStickyMessage('Load Error', `Unable to update pricing rule.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
  //    MessageSeverity.error, error);
  //}

  //onRuleCleared(row: CoreCustomerRule): void {

  //  // Get data to work with
  //  if (row.priority == 1) {
  //    //Save single stock code with rule
  //    var baseItem = this.setNewPriceItem();
  //    baseItem.ruleId = row.id;
  //    baseItem.priority = row.priority;

  //    baseItem.value = row.value;
  //    baseItem.addValue = row.addValue;
  //    baseItem.addText = row.addText;
  //    baseItem.ruleName = row.ruleName;
  //    baseItem.ruleDescription = row.ruleDescription;

  //    this.sysproService.getStockCodePriceDetail(row.code.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"))
  //      .subscribe(x => {
  //        var total = 0;
  //        var stockCode = new Inventory();
  //        stockCode = x[0];
  //        baseItem.stockCode = stockCode.stockCode;
  //        baseItem.costPrice = stockCode.unitCost;
  //        baseItem.sellingPrice = stockCode.sellingPrice;
  //        baseItem.uom = stockCode.uom;
  //        baseItem.description = stockCode.description;
  //        baseItem.conversionFactor = stockCode.priceConvFac;

  //        if (row.addValue > 0) {
  //          total = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, row.addValue, row.value, stockCode.priceConvFac, 0);
  //        }
  //        else {
  //          total = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, row.value, stockCode.priceConvFac, 0);
  //        }
  //        baseItem.newPrice = total;
  //        baseItem.gpValue = Calculations.calculateGPLineTotal(stockCode.unitCost, total, stockCode.priceConvFac, 0);

  //        this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(baseItem), error => this.onNewRuleAddedFailed(error));

  //      }, error => this.onDataLoadFailed(error));



  //  }
  //}

  //handleRuleApplication(customerRule: CoreCustomerRule, row: Inventory) {
  //  this.alertService.startLoadingMessage("Starting Price Updates");
  //  this.loadingIndicator = true;

  //  var baseItem = this.setNewPriceItem();
  //  baseItem.ruleId = customerRule.id;
  //  baseItem.priority = customerRule.priority;
  //  baseItem.value = customerRule.value;
  //  baseItem.addValue = customerRule.addValue;
  //  baseItem.addText = customerRule.addText;
  //  baseItem.ruleName = customerRule.ruleName;
  //  baseItem.ruleDescription = customerRule.ruleDescription;

  //  if (customerRule.priority == 1) {
  //    //Save single stock code with rule
  //    baseItem.stockCode = row.stockCode;
  //    baseItem.costPrice = row.unitCost;
  //    baseItem.sellingPrice = row.sellingPrice;

  //    baseItem.newPrice = (row.specialPrice / row.priceConvFac);
  //    baseItem.gpValue = row.specialGP;
  //    baseItem.uom = row.uom;
  //    baseItem.description = row.description;
  //    baseItem.conversionFactor = row.priceConvFac;
  //    baseItem.priority = row.specialPriority;

  //    this.recordsToProcess = 1;
  //    this.recordsProcessed = 0;
  //    this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(baseItem), error => this.onNewRuleAddedFailed(error));

  //  }
  //  if (customerRule.priority == 2) {
  //    //Get all stock codes for supplier and apply rule to each stock code
  //    this.sysproService.getStockCodePriceDetailForSupplier(customerRule.code.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---"))
  //      .subscribe(x => this.stockCodesSelected(x, customerRule), error => this.onDataLoadFailed(error));

  //  }
  //  if (customerRule.priority == 3) {
  //    //Get all stock codes for category tree and apply rule to each stock code
  //    this.sysproService.getStockCodePriceDetailForCategory(customerRule.categoryId)
  //      .subscribe(x => this.stockCodesSelected(x, customerRule), error => this.onDataLoadFailed(error));
  //  }
  //  if (customerRule.priority == 4) {
  //    this.alertService.showMessage('Success', ` Successfully updated`, MessageSeverity.success);
  //    this.loadDetails();

  //  }

  //}

  //stockCodesSelected(x: Inventory[], customerRule: CoreCustomerRule): void {

  //  this.recordsToProcess = 0;

  //  if (x && x[0]) {
  //    this.rowsCache = x;
  //    this.recordsToProcess = this.rowsCache.length;
  //    this.recordsProcessed = 0;
  //    var baseItem = this.setNewPriceItem();

  //    baseItem.ruleId = customerRule.id;
  //    baseItem.priority = customerRule.priority;
  //    baseItem.value = customerRule.value;
  //    baseItem.addValue = customerRule.addValue;
  //    baseItem.addText = customerRule.addText;
  //    baseItem.ruleName = customerRule.ruleName;
  //    baseItem.ruleDescription = customerRule.ruleDescription;

  //    this.processBulk(customerRule, baseItem);
     
  //  }
  //  else {
  //    this.alertService.stopLoadingMessage();
  //    this.loadingIndicator = false;
  //    //No Stock Codes Found

  //    this.alertService.showMessage('No results', 'No Stock Codes found for this selection',
  //      MessageSeverity.warn);

  //    //Remove Rule
  //    this.deleteRowHelper(customerRule);

  //  }

  //}

  //sleep(ms) {
  //  return new Promise(resolve => setTimeout(resolve, ms));
  //}


  //async processAsync(customerRule: CoreCustomerRule, baseItem: CoreCustomerPrices) {

  //  let index = 0;

  //  //Split Batches
  //  if (this.recordsToProcess > 1000) {

  //    var batch1 = this.rowsCache.slice(0, 1000);
  //    index = await this.newMethod(batch1, index, baseItem, customerRule);
      
  //    await this.sleep(2000);

  //    if (this.recordsToProcess > 2000) {

  //      var batch2 = this.rowsCache.slice(1000, 2000);
  //      index = await this.newMethod(batch2, index, baseItem, customerRule);

  //      await this.sleep(2000);

  //      var batch3 = this.rowsCache.slice(2000, this.recordsToProcess);
  //      index = await this.newMethod(batch3, index, baseItem, customerRule);
  //    }
  //    else {

  //      var batch2 = this.rowsCache.slice(1000, this.recordsToProcess);
  //      index = await this.newMethod(batch2, index, baseItem, customerRule);
  //    }
  //  }
  //  else {
  //    index = await this.newMethod(this.rowsCache, index, baseItem, customerRule);
  //  }

  //  console.log(index);
    
  //}

  //private async processBulk(customerRule: CoreCustomerRule, baseItem: CoreCustomerPrices) {

  // // const stockCodes = this.rowsCache;

  //  for (const [idx, stockCode] of this.rowsCache.entries()) {
  // /* await Promise.all(stockCodes.map(async (stockCode) => {*/
  //    var total = 0;
  //    baseItem.stockCode = stockCode.stockCode;
  //    baseItem.costPrice = stockCode.unitCost;
  //    baseItem.sellingPrice = stockCode.sellingPrice;
  //    baseItem.uom = stockCode.uom;
  //    baseItem.description = stockCode.description;
  //    baseItem.conversionFactor = stockCode.priceConvFac;

  //    if (customerRule.addValue > 0) {
  //      total = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, customerRule.addValue, customerRule.value, stockCode.priceConvFac, 0);
  //    }
  //    else {
  //      total = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, customerRule.value, stockCode.priceConvFac, 0);
  //    }
  //    baseItem.newPrice = total;

  //    baseItem.gpValue = Calculations.calculateGPLineTotal(stockCode.unitCost, total, stockCode.priceConvFac, 0);

  //    //console.log(index, stockCode);

  //    if (idx % 100 == 0 && idx != 0) {
  //      //DoStuff

  //      //console.log(`SLEEPTING ${idx + 1}:`, stockCode);
  //      await this.sleep(2000);
  //      //console.log(`Received Todo ${idx + 1}:`, stockCode);
  //      await this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
  //        error => this.onSpecialPriceAddedFailed(error, baseItem.stockCode));
  //    }
  //    else {

  //      //console.log(`Received Todo ${idx + 1}:`, stockCode);
  //      await this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
  //        error => this.onNewPriceAddedFailed(error, baseItem));
  //    }

  //  };

  //  console.log('Finished!');
  //  //batch1.forEach(async (stockCode) => {
  //  //  //Save single stock code with rule
  //  //  index++;

  //  //  var total = 0;
  //  //  baseItem.stockCode = stockCode.stockCode;
  //  //  baseItem.costPrice = stockCode.unitCost;
  //  //  baseItem.sellingPrice = stockCode.sellingPrice;
  //  //  baseItem.uom = stockCode.uom;
  //  //  baseItem.description = stockCode.description;
  //  //  baseItem.conversionFactor = stockCode.priceConvFac;

  //  //  if (customerRule.addValue > 0) {
  //  //    total = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, customerRule.addValue, customerRule.value, stockCode.priceConvFac, 0);
  //  //  }
  //  //  else {
  //  //    total = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, customerRule.value, stockCode.priceConvFac, 0);
  //  //  }
  //  //  baseItem.newPrice = total;

  //  //  baseItem.gpValue = Calculations.calculateGPLineTotal(stockCode.unitCost, total, stockCode.priceConvFac, 0);

  //  //  console.log(index, stockCode);

  //  //  await this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
  //  //    error => this.onSpecialPriceAddedFailed(error, baseItem.stockCode));

  //  //});
  //  //return index;
  //}
  //onNewPriceAddedFailed(error: any, baseItem: CoreCustomerPrices): void {
  //  console.log(`Received ERROR`, error);
  //  //if (this.accountService.currentUser) {
  //  //  this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
  //  //    error => this.onNewPriceAddedFailed(error, baseItem));
  //  //}
  //}

    //private async newMethod(batch1: Inventory[], index: number, baseItem: CoreCustomerPrices, customerRule: CoreCustomerRule) {
    //    batch1.forEach(async (stockCode) => {
    //        //Save single stock code with rule
    //        index++;

    //        var total = 0;
    //        baseItem.stockCode = stockCode.stockCode;
    //        baseItem.costPrice = stockCode.unitCost;
    //        baseItem.sellingPrice = stockCode.sellingPrice;
    //        baseItem.uom = stockCode.uom;
    //        baseItem.description = stockCode.description;
    //      baseItem.conversionFactor = stockCode.priceConvFac;

    //        if (customerRule.addValue > 0) {
    //            total = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, customerRule.addValue, customerRule.value, stockCode.priceConvFac, 0);
    //        }
    //        else {
    //            total = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, customerRule.value, stockCode.priceConvFac, 0);
    //        }
    //        baseItem.newPrice = total;

    //        baseItem.gpValue = Calculations.calculateGPLineTotal(stockCode.unitCost, total, stockCode.priceConvFac, 0);

    //        console.log(index, stockCode);

    //       await  this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
    //            error => this.onSpecialPriceAddedFailed(error, baseItem.stockCode));

    //    });
    //    return index;
    //}

  //process(customerRule: CoreCustomerRule, baseItem: CoreCustomerPrices) {

  //  let index = 0;

  //  console.log(this.recordsToProcess);


  //  this.rowsCache.forEach(async stockCode => {
  //    //Save single stock code with rule
  //    index++;
  //    var total = 0;
  //    baseItem.stockCode = stockCode.stockCode;
  //    baseItem.costPrice = stockCode.unitCost;
  //    baseItem.sellingPrice = stockCode.sellingPrice;
  //    baseItem.uom = stockCode.uom;
  //    baseItem.description = stockCode.description;
  //    baseItem.conversionFactor = stockCode.priceConvFac;

  //    if (customerRule.addValue > 0) {
  //      total = Calculations.getSpecialPriceTotal(stockCode.unitCost, 0, customerRule.addValue, customerRule.value, stockCode.priceConvFac, 0);
  //    }
  //    else {
  //      total = Calculations.getSpecialPriceTotal(0, stockCode.sellingPrice, 0, customerRule.value, stockCode.priceConvFac, 0);
  //    }
  //    baseItem.newPrice = total;

  //    baseItem.gpValue = Calculations.calculateGPLineTotal(stockCode.unitCost, total, stockCode.priceConvFac, 0);

  //    this.sysproService.addNewPricingCustomerPrice(baseItem).subscribe(x => this.onNewSpecialPriceAdded(x),
  //      error => this.onSpecialPriceAddedFailed(error, baseItem.stockCode));

  //  })
  //}

  //onSpecialPriceAddedFailed(error: any, stockCode: string ): void {     
  //  this.recordsProcessed++;
  //  //this.alertService.showStickyMessage('Something', stockCode + ' (Line ' + this.recordsProcessed + `) Unable to update pricing rule.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
  //  //    MessageSeverity.error, error);

  //  }

  //onNewSpecialPriceAdded(row: CoreCustomerPrices): void {
  //  this.recordsProcessed++;

  //  if (this.recordsToProcess == this.recordsProcessed) {
  //    this.alertService.stopLoadingMessage();
  //    this.loadingIndicator = false;

  //    this.alertService.showMessage('Success', this.recordsToProcess + ` Stock Codes Successfully added`, MessageSeverity.success);
  //    if (!this.bulkLoad) {

  //      this.loadData();
  //    }
  //  }
  //}

  setNewRule(row: Inventory): CoreCustomerRule {
    var rule = new CoreCustomerRule();

    rule.ruleName = row.specialName;

    rule.customerId = this.coreCustomer.id;
    rule.customerCode = this.coreCustomer.customerCode;

    rule.addText = row.description;
    rule.ruleDescription = (row.specialDiscount > 0) ? "Less Discount" : "Cost Plus";
    if (row.specialDiscount == 0 && row.specialCostAdd == 0 && row.specialPriority != 1) {
      rule.ruleDescription = "Less Discount";
    }

    rule.code = row.stockCode;
    rule.addValue = row.specialCostAdd;
    rule.value = row.specialDiscount;

    rule.addCheck = true;
    rule.addFlag = "Y";
    rule.priority = row.specialPriority;
    rule.categoryId = row.categoryId;
    rule.createdBy = this.accountService.currentUser.email;

    return rule;
  }

  //setNewPriceItem(): CoreCustomerPrices {
  //  var newPrice = new CoreCustomerPrices();
  //  newPrice.company = this.coreCustomer.company;
  //  newPrice.createdBy = this.accountService.currentUser.email;
  //  newPrice.customerCode = this.coreCustomer.customerCode;
  //  newPrice.customerId = this.coreCustomer.id;
  //  newPrice.supplierCode = "";
  //  newPrice.category = "";
  //  newPrice.categoryId = 0;
  //  newPrice.enabled = true;
  //  return newPrice;
  //}

  

  onNewRuleAddedFailed(error: any, item?: string): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Something', item + ` Unable to update pricing rule.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

   // this.loadData();
   
  }

  

  deleteRule(row: CoreCustomerRule) {
   
    this.alertService.showDialog('Are you sure you want to delete this rule\"\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: CoreCustomerRule) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.sysproService.deletePricingCustomerRule(row.id).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.ruleName + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  deleteSpecialRule(row: CoreCustomerPrices) {
   
    this.alertService.showDialog('Are you sure you want to delete this rule?', DialogType.confirm, () => this.deleteSpecialRowHelper(row));
  }

  deleteSpecialRowHelper(row: CoreCustomerPrices): any {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.sysproService.deletePricingCustomerRule(row.ruleId).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.ruleName + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  get application(): string {
    return this.configurations.applicationName;
  }

  overridePriceHelper(row: Inventory): any {
    this.validated = true;

    this.saveStockCodeRule(row);
    //if (row.specialGP < 15) {
    //  this.alertService.showDialog('GP% can\'t be below 15%.', DialogType.confirm, () => this.clearRowHelper(row));
    //}
    //AddRule

  }


  onSupplierLoadSuccessful(suppliers: Supplier[]) {
    this.currentSupplier = new Supplier();

    this.suppliersCache = suppliers;
    this.setSupplier(suppliers);

    //filter out suppliers that has already been added

  }

  setSupplier(suppliers: Supplier[]) {


    if (suppliers == null || this.suppliers.length != suppliers.length) {
      setTimeout(() => {
        if (this.supplierSelector) {
          this.supplierSelector.refresh();
        }
      });
    }
   
  }


  viewSupplierSelected() {

    if (this.rowCustomerRulesSupplierDiscount && this.rowCustomerRulesSupplierDiscount.length >= 1 && this.rowCustomerRulesSupplierDiscount.find(x => x.code == this.selectedSupplier)) {
      this.alertService.showStickyMessage('Duplicate', this.selectedSupplier + ' This supplier has already been added',
        MessageSeverity.error);
    }
    else {
      var supplierDetail = this.getSupplierByID(this.selectedSupplier);
      this.supplierDiscountRow = new Inventory();
      this.supplierDiscountRow.specialName = "Supplier";
      this.supplierDiscountRow.stockCode = supplierDetail.supplier;
      this.supplierDiscountRow.description = supplierDetail.supplierName;
      this.supplierDiscountRow.specialCostAdd = 0;
      this.supplierDiscountRow.specialDiscount = 0;
      this.supplierDiscountRow.specialPriority = 2;

      if (!this.ruleSupplierModal) {
        setTimeout(() => {
          if (this.ruleSupplierModal) {
            this.ruleSupplierModal.show();
          }
        });
      }
      else {
        this.ruleSupplierModal.show();
      }
    }  
    
  }


  private getSupplierByID(name: string) {
    return this.suppliersCache.find((r) => r.supplier == name);
  }


 ruleSupplierModalHide() {

   this.supplierDiscountRow = null;
   this.ruleSupplierModal.hide();
  }

  selectTreeItem(row: TreeviewItem) {

    if (this.rowCustomerRulesCategoryDiscount && this.rowCustomerRulesCategoryDiscount.length >= 1 && this.rowCustomerRulesCategoryDiscount.find(x => x.code == row.text)) {
      this.alertService.showMessage('Duplicate', row.text + ' - This category has already been added',
        MessageSeverity.warn);
    }
    else {
      this.newParentName = row.text;

      this.treeModal.hide();
      this.categoryDiscountRow = new Inventory();
      this.categoryDiscountRow.specialName = "Category";
      this.categoryDiscountRow.specialCostAdd = 0;
      this.categoryDiscountRow.specialDiscount = 0;
      this.categoryDiscountRow.specialPriority = 3;
      this.categoryDiscountRow.categoryId = row.value.category.Id;
      this.categoryDiscountRow.stockCode = row.text;
      this.categoryDiscountRow.description = "";

      if (!this.ruleCategoryModal) {
        setTimeout(() => {
          if (this.ruleCategoryModal) {
            this.ruleCategoryModal.show();
          }
        });
      }
      else {
        this.ruleCategoryModal.show();
      }
    }
  }

  ruleCategoryModalHide() {

    this.categoryDiscountRow = null;
    this.ruleCategoryModal.hide();
  }

  onCategoriesLoadSuccessful(treeview: any[]) {
    if (treeview[0]) {
      let treeviewItems = new TreeviewItem(JSON.parse(treeview[0]));

      this.items = this.getItems([treeviewItems]);
      this.treeItems = this.getItems([treeviewItems]);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }

  list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentCategory !== "Root") {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parentCategory]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }


  loadCategoryModal() {
    if (!this.treeModal) {
      setTimeout(() => {
        if (this.treeModal) {
          this.treeModal.show();
        }
      });
    }
    else {
      this.treeModal.show();
    }
  }



  loadModalEdit(row: CoreCustomerRule) {

    if (row.ruleName == "Category") {
      this.newParentName = row.code;
      this.categoryDiscountRow = new Inventory();
      this.categoryDiscountRow.specialName = "Category";
      this.categoryDiscountRow.specialCostAdd = row.addValue;
      this.categoryDiscountRow.specialDiscount = row.value;
      this.categoryDiscountRow.specialPriority = 3;
      this.categoryDiscountRow.categoryId = row.categoryId;
      this.categoryDiscountRow.stockCode = row.code;
      this.categoryDiscountRow.description = row.addText;
      this.categoryDiscountRow.id = row.id;

      if (!this.ruleCategoryModal) {
        setTimeout(() => {
          if (this.ruleCategoryModal) {
            this.ruleCategoryModal.show();
          }
        });
      }
      else {
        this.ruleCategoryModal.show();
      }
    }

    if (row.ruleName == "Supplier") {
     
      this.supplierDiscountRow = new Inventory();
      this.supplierDiscountRow.specialName = "Supplier";
      this.supplierDiscountRow.specialCostAdd = row.addValue;
      this.supplierDiscountRow.specialDiscount = row.value;
      this.supplierDiscountRow.specialPriority = 2;
      this.supplierDiscountRow.categoryId = row.categoryId;
      this.supplierDiscountRow.stockCode = row.code;
      this.supplierDiscountRow.description = row.addText;
      this.supplierDiscountRow.id = row.id;

      if (!this.ruleSupplierModal) {
        setTimeout(() => {
          if (this.ruleSupplierModal) {
            this.ruleSupplierModal.show();
          }
        });
      }
      else {
        this.ruleSupplierModal.show();
      }
    }
   
  }

  treeItemBeforeExpand(row: TreeviewItem) {
    console.log(row);
    if (row) {
      if (row.collapsed) {

      }
      else {
      }
    }
  }



  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }



  get branchCode(): string {
    return this.configurations.branchCode;
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }



  showWHDetail(row: Inventory, event) {
    console.log(row);
    if (row) {
      this.stockDescription = row.stockCode + ": " + row.description;
      this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }
  

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());

    console.log(whDetail);
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  //downloadPriceList() {

  //  this.alertService.startLoadingMessage('Loading Details...');
  //  this.loadingIndicator = true;

  //  this.sysproService.getAllPricingCustomerPrices(this.coreCustomer.id).subscribe(x => this.onPricesLoadSuccessful(x), error => this.onDataLoadFailed(error));    

  //}

  downloadCustomerPriceList() {
    this.alertService.startLoadingMessage('Loading Details...');
    this.loadingIndicator = true;

    this.sysproService.getAllPricingCustomerPrices(this.coreCustomer.id).subscribe(x => this.onPricesLoadSuccessful(x, false), error => this.onDataLoadFailed(error));    

  }


  downloadInternalList() {
    this.alertService.startLoadingMessage('Loading Details...');
    this.loadingIndicator = true;

    this.sysproService.getAllPricingCustomerPrices(this.coreCustomer.id).subscribe(x => this.onPricesLoadSuccessful(x, true), error => this.onDataLoadFailed(error));

  }


  disableCustomerPricing() {

    if (this.coreCustomer.enabled) {
      this.coreCustomer.enabled = false;
      this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
      this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
        this.alertService.showStickyMessage('Success', 'Customer Updated',
          MessageSeverity.info);

        this.loadingIndicator = false;
      }, error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Note', 'Customer Discounts Already Disabled',
        MessageSeverity.info);
    }

  }

  enableCustomerPricing() {

    if (!this.coreCustomer.enabled) {
      this.coreCustomer.enabled = true;
      this.coreCustomer.modifiedBy = this.accountService.currentUser.email;
      this.sysproService.updatePricingCustomer(this.coreCustomer, this.coreCustomer.id).subscribe(x => {
        this.alertService.showStickyMessage('Success', 'Customer Updated',
          MessageSeverity.info);
        this.loadingIndicator = false;

      }, error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Note', 'Customer Discounts Already Enabled',
        MessageSeverity.info);
    }

  }


  onAllStockLoadSuccessful(inventory: any[]): void {
    this.inventoryList = inventory[0];

  }

  onPricesLoadSuccessful(prices: CoreCustomerPrices[], internal: boolean): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (prices && prices.length > 0) {
      this.priceRows = prices;
      this.priceRowsCache = [...this.priceRows];
         
      if (internal) {

        this.metaList();
      }
      else {
        this.customerList();
      }
    }

  }


  metaList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description", "Unit Cost", "Cost Plus", "Selling Price", "Discount", "Nett Price", "ConversionFactor", "GP", "Last Cost Changed",
        "Price Updated On", "Supplier", "BrandName", "Status"]
    };

    var filename = this.currentCustomer + "_InternalPriceList";
    var selectRows = [...this.priceRowsCache.filter(r => r.status != "D")];

    var exportRows = [];
    for (var row of selectRows) {
      let date1 = new Date(row.lastCostChange).toISOString().split('T')[0];
      let date2 = new Date((row.modifiedOn ? row.modifiedOn : row.createdOn)).toISOString().split('T')[0];
      exportRows.push({
        StockCode: row.stockCode.replace(/\,/g, ' ').replace(/\;/g, ' '), Description: row.description.replace(/\,/g, ' ').replace(/\;/g, ' '),  
        UnitCost: row.costPrice, CostPlus: row.addValue,
        SellingPrice: row.sellingPrice, DiscountPerc: row.value,
        SpecialPrice: row.newPrice,
        ConversionFactor: row.conversionFactor, GP: row.gpValue, CostChanged: date1, PriceUpdated: date2,
        Supplier: row.supplierCode, BrandName: row.brandName, Status: row.status
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  customerList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["StockCode", "Description",  "Nett Price"]
    };

    var filename = this.currentCustomer + " PriceList";
    var selectRows = [...this.priceRowsCache.filter(r => r.status != "D")];
    var exportRows = [];
    for (var row of selectRows) {
     
      exportRows.push({
        StockCode: row.stockCode.replace(/\,/g, ' ').replace(/\;/g, ' '), Description: row.description.replace(/\,/g, ' ').replace(/\;/g, ' '),       
        SpecialPrice: row.newPrice
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


  get canMaintainPrices() {
    return this.accountService.userHasPermission(Permission.maintainCustomerPricingPermission);
  }
}
