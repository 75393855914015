import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
//import { AlertService, MessageSeverity } from '../../services/alert.service';
//import { AccountService } from '../../services/account.service';
//import { Utilities } from '../../services/utilities';
//import { FowkesOnlineService } from '../../services/fowkes-online.service';
//import { User } from '../../models/user.model';
//import { UserEdit } from '../../models/user-edit.model';
//import { Role } from '../../models/role.model';
//import { Branch } from '../../models/branch.model';
//import { ArCustomer } from '../../models/arcustomer.model';
//import { CartHeader } from '../../models/cart-header.model';
//import { ArCustomerEdit } from '../../models/arcustomer-edit.model';
//import { SalesPerson } from '../../models/salesperson.model';
//import { AspnetApplications } from '../../models/applications.model';
//import { Permission } from '../../models/permission.model';
//import { SysproService } from '../../services/syspro.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { ArNarration } from 'src/app/models/arnarration.model';
//import { localStorageActions } from 'src/app/models/enums';
//import { ConfigurationService } from 'src/app/services/configuration.service';


@Component({
  selector: 'reports-invoice-tracking-purge',
  templateUrl: './reports-invoice-tracking-purge-component.html',
  styleUrls: ['./reports-invoice-tracking-purge-component.scss']
})
export class ReportsInvoiceTrackingPurgeComponent
{// implements OnInit {

  private isEditMode = false;
  //private isSaving = false;
  //private isNavigating = false;
  //private showValidationErrors = false;
  //private editingUserName: string;
  //uniqueId: string = Utilities.uniqueId();
  //private user: User = new User();
  //customer: ArCustomer = new ArCustomer();
  //private customerEdit: ArCustomerEdit;
  //private cartEdit: CartHeader;
  //columns: any[] = [];
  //rows: ArNarration[] = [];
  //rowsCache: ArNarration[] = [];
  //private allSalesPersons: SalesPerson[] = [];
  //
  //public formResetToggle = true;
  //
  //public changesSavedCallback: () => void;
  //public changesFailedCallback: () => void;
  //public changesCancelledCallback: () => void;

  //@Input()
  //isViewOnly: boolean;
  //
  //@Input()
  //isGeneralEditor = false;
  //
  //
  //@ViewChild('f')
  //private form;

  // ViewChilds Required because ngIf hides template variables from global scope soldPostalCode
  //@ViewChild('customerName')
  //private customerName;
  //
  //@ViewChild('telex')
  //private telex;
  //
  //@ViewChild('soldPostalCode')
  //private soldPostalCode;
  //
  //@ViewChild('shipPostalCode')
  //private shipPostalCode;
  //
  //@ViewChild('email')
  //private email;
  //
  //@ViewChild('salespersons')
  //private salespersons;
  //
  //@ViewChild('salespersonSelector')
  //private salespersonSelector;
  //
  //@ViewChild('sysproOperator')
  //private sysproOperator;
  //
  //@ViewChild('applications')
  //private applications;
  //
  //@ViewChild('applicationsSelector')
  //private applicationsSelector;
  //

  //@ViewChild('notesModal')
  //notesModal: ModalDirective;
  //
  //@ViewChild('debtorsModal')
  //debtorsModal: ModalDirective;
  //
  //@ViewChild('indexTemplate')
  //indexTemplate: TemplateRef<any>;
  //
  //private customerAccountStatus: string = ""
  //private sub: any;
  //private id: any = "";
  //  customerNotes: ArNarration[];
  //  customerIsOnHoldText: string;
  //  totalInvoiceCount: number;
  //cartSaved: boolean;
  //
  //cartId: string = "";
  //cart: CartHeader = new CartHeader();
  //  isNavigatingTo: string;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
  
    //if (!this.isGeneralEditor) {
    //  this.loadCurrentUserData();
    //}

  }
  /*
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    console.log(this.accountService);

    this.columns = [
      { prop: 'line', name: 'Line', width: 50, canAutoResize: false },
      { prop: 'notation', name: 'Notation', width: 300 }
    ];

    let action = sessionStorage.getItem(localStorageActions.Customer);
    let cartId = sessionStorage.getItem(localStorageActions.CartId);

    if (action) {
      this.id = action;
    }
    else {
      this.router.navigate(['../../customers']);
    }

    if (!!cartId) {
      this.cartId = cartId;
      console.log("I have loaded with this cartId", this.cartId);

    }

    //Header should populate from the syspro customer
    this.sysproService.getCustomerDetail(this.id).subscribe(x => this.onDataLoadSuccessful(x), error => this.onCurrentUserDataLoadFailed(error));

    this.sysproService.getCustomerNotes(this.id).subscribe(x => this.onNotesLoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));
    this.sysproService.GetAllSalesPersons(this.branchCode).subscribe(x => this.onSalesPersonoadSuccessful([x]), error => this.onCurrentUserDataLoadFailed(error));

  }

  get branchCode(): string {
    return this.configurations.branchCode;
  }

  private onSalesPersonoadSuccessful(salesPersons: any[]) {
    this.allSalesPersons = salesPersons[0];
    console.log(this.allSalesPersons);
  }
  
  private onDataLoadSuccessful(customer: ArCustomer) {
    this.alertService.stopLoadingMessage();
    this.customer = customer;
    console.log("THIS IS THE CUSTOMER", this.customer);
    if (!!this.cartId) {
      //Header should populate from the syspro customer and the cart
      this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }

    let overLimit = parseFloat(customer.creditLimit) - parseFloat(customer.currentBalance1);

    if (customer.customerOnHold === "Y") {
      this.customerIsOnHoldText = "Customer is on hold";
      this.customerAccountStatus = (overLimit < 0 ? "On hold and exceeded credit limit" : "Customer on hold");
    }
    else {
      this.customerAccountStatus = (overLimit < 0 ? "Customer exceeded credit limit" : "In Terms");
    }

    this.getTotalInvoices();

    console.log("THIS IS THE NEW CUSTOMER", this.customer);

    this.alertService.showMessage('Success', `Loaded Syspro Customer`, MessageSeverity.success);
  }


  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    console.log("THIS IS THE CART", this.cart);
    Object.assign(this.customer, this.cart);
    console.log("THIS IS THE CUSTOMER AND CART", this.customer);

    this.alertService.showMessage('Success', `Loaded Cart Header`, MessageSeverity.success);
  }

  private getTotalInvoices() {
    this.totalInvoiceCount = parseFloat(this.customer.numCurrentInv) + parseFloat(this.customer.num30daysInv) + parseFloat(this.customer.num60daysInv)
      + parseFloat(this.customer.num90daysInv) + parseFloat(this.customer.num120daysInv);
  }

  private onNotesLoadSuccessful(notes: any[]) {
    this.alertService.stopLoadingMessage();
    this.customerNotes = notes;
    if (notes[0].length > 0) {
      this.notesModal.show();
    }
  }

  goAddItems() {
    this.isNavigating = true;
    this.isNavigatingTo = "inventory";
    this.showValidationErrors = false;
    this.setCartHeader();
    this.save();
  }

  goAddNSItems() {
    this.isNavigating = true;
    this.isNavigatingTo = "nonstock";
    this.showValidationErrors = false;
    this.setCartHeader();
    this.save();
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }


  onDataLoadFailed(error: any) {

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  private getSalesPersonByName(name: string) {
    return this.allSalesPersons.find((r) => r.salesperson == name);
  }
  

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  
  private edit() {

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.setCartHeader();
  }
  
  private setCartHeader() {

    this.cartEdit = new CartHeader();
    Object.assign(this.cartEdit, this.customer);
    this.cartEdit.userid = this.accountService.currentUser.id;

    console.log("THIS IS MY EDITING CART POPULATED WITH CUSTOMER", this.cartEdit);

    if (!!this.cartId) {
      if (this.cart) {
        Object.assign(this.cartEdit, this.cart);
        console.log("THIS IS MY EDITING CART POPULATED WITH EXISTING CART", this.cartEdit);
        console.log("THIS IS THE CART THAT WAS ADDED", this.cart);
      }
    }
    else {
      let uniqueId = this.id + Math.random().toString(36).substring(2) + Date.now().toString(36);
      this.cartEdit.cartNumber = uniqueId;
      this.cartEdit.branch_code = this.configurations.branchCode;
      this.cartEdit.customerPoNumber = "";
      this.cartEdit.shippingInstrs = "";
      this.cartEdit.specialInstrs = "";
      this.cartEdit.ordertype = "";
      this.cartEdit.orderdiscountpercentage = 0;
      this.cartEdit.quotedFrom = "";
      this.cartEdit.hasNettPrices = false;
      console.log("CartNumber2", this.cartEdit.cartNumber);
    }

    console.log("THIS IS WHAT I HAVE AFTER setCartHeader", this.cartEdit);
  }

  setLocalCartId(cartId: string) {
    if (sessionStorage.getItem(localStorageActions.CartId) != cartId) {
      sessionStorage.setItem(localStorageActions.CartId, cartId);
      this.cartId = cartId;
    }
  }

  private save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    console.log("CartNumberSave", this.cartEdit.cartNumber);
    this.fowkesService.newCart(this.cartEdit).subscribe(_response => this.saveSuccessHelper(this.cartEdit.cartNumber), error => this.saveFailedHelper(error));
    
  }


  private saveSuccessHelper(cartId: string) {

    this.setLocalCartId(cartId);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;
    this.isEditMode = false;

    this.alertService.showMessage('Success', `Updated successfully ` + cartId, MessageSeverity.success);

    if (!this.isNavigating) {
      this.fowkesService.getCartHeader(this.cartId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      if (this.isNavigatingTo == "nonstock") {
        this.router.navigate(['../orders'], { fragment: 'nonstock', queryParams: { customer: this.customer.customer, cartId: cartId } });
      }
      if (this.isNavigatingTo == "inventory") {
        this.router.navigate(['../orders'], { fragment: 'inventory', queryParams: { customer: this.customer.customer, cartId: cartId } });
      }
    }
  }
  
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }
   
   
  private cancel() {
    //if (this.isGeneralEditor) {
    //  //this.userEdit = this.user = new UserEdit();
    //  this.customerEdit = this.customer = new ArCustomerEdit();
    //} else {
    //  //this.userEdit = new UserEdit();
    //  this.customerEdit = new ArCustomerEdit();
    // // Object.assign(this.cartEdit, this.customer);
    //}
    this.setCartHeader();
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }


  private close() {
   // this.userEdit = this.user = new UserEdit();
    this.customerEdit = this.customer = new ArCustomerEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  copySoldToShip() {
    this.cartEdit.shipToAddr1 = this.cartEdit.soldToAddr1;
    this.cartEdit.shipToAddr2 = this.cartEdit.soldToAddr2;
    this.cartEdit.shipToAddr3 = this.cartEdit.soldToAddr3;
    this.cartEdit.shipToAddr4 = this.cartEdit.soldToAddr4;
    this.cartEdit.shipToAddr5 = this.cartEdit.soldToAddr5;
    this.cartEdit.shipPostalCode = this.cartEdit.soldPostalCode;
  }

  copyShipToSold() {
    this.cartEdit.soldToAddr1 = this.cartEdit.shipToAddr1;
    this.cartEdit.soldToAddr2 = this.cartEdit.shipToAddr2;
    this.cartEdit.soldToAddr3 = this.cartEdit.shipToAddr3;
    this.cartEdit.soldToAddr4 = this.cartEdit.shipToAddr4;
    this.cartEdit.soldToAddr5 = this.cartEdit.shipToAddr5;
    this.cartEdit.soldPostalCode = this.cartEdit.shipPostalCode;
  }
  
  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  search() {
    if (this.cartId) {

      this.router.navigate(['../../orders'], { fragment: 'customers', queryParams: { customer: this.customer, cartId: this.cartId } });
    }
    else {
      this.router.navigate(['../../customers'], { fragment: 'customers', queryParams: { customer: this.customer } });
    }
  }

  //editCustomer(customer: ArCustomer, allSalesPersons: SalesPerson[]) {
  //  if (customer) {
  //    this.isGeneralEditor = true;

  //    this.setSalesPerson(customer, allSalesPersons);
  //    this.customer = new ArCustomer();
  //    this.customerEdit = new ArCustomerEdit();
  //    Object.assign(this.customer, customer);
  //    Object.assign(this.customerEdit, customer);
  //    this.edit();

  //    console.log(this.customerEdit);
  //    console.log(this.customer);
  //    return this.customerEdit;
  //  } 
  //}


  //displayCustomer(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

  //  this.customer = new ArCustomer();
  //  Object.assign(this.customer, customer);
  //  this.setSalesPerson(customer, allSalesPersons);
  //  this.isEditMode = false;
  //}


  private setSalesPerson(customer: ArCustomer, allSalesPersons?: SalesPerson[]) {

    this.allSalesPersons = allSalesPersons ? [...allSalesPersons] : [];

    if (customer.salesperson) {
      if (!this.allSalesPersons.some(r => r.salesperson == customer.salesperson)) {
        this.allSalesPersons.unshift(new SalesPerson(customer.salesperson));
      }
    }

    if (allSalesPersons == null || this.allSalesPersons.length != allSalesPersons.length) {
      setTimeout(() => {
        if (this.salespersonSelector) {
          this.salespersonSelector.refresh();
        }
      });
    }
  }


  get canViewAllSalesPersons(){
    return true;
  }

  get canAssignSalesPersons() {
    return true;
  }

  get canViewAllBranches() {
    return true;
  }
  get canAssignBranches() {
    return true;
  }

  get canAssignApplications() {
    return true;
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }

  get canCreateCart() {
    return this.accountService.userHasPermission(Permission.placeOrderPermission);
  }
  */
}
