
export class CoreAudit {
  constructor(aspnetApplication?: string, sysproOperator?: string, branch?: string, referenceType?: string, referenceNumber?: string, actionType?: string, actionInput?: string,
    actionOutput?: string, actionedBy?: string, applicationUser?: string, groupKey?: string) {

    this.aspnetApplication = aspnetApplication;
    this.sysproOperator = sysproOperator;
    this.branch = branch;
    this.referenceType = referenceType;
    this.referenceNumber = referenceNumber;
    this.actionType = actionType;
    this.actionInput = actionInput;
    this.actionOutput = actionOutput;
    this.actionedBy = actionedBy;
    this.applicationUser = applicationUser;
    this.groupKey = groupKey;
  }

  public aspnetApplication: string;
  public sysproOperator: string;
  public branch: string;
  public referenceType: string;
  public referenceNumber: string;
  public actionType: string;
  public actionInput: string;
  public actionOutput: string;
  public actionedBy: string;
  public applicationUser: string;
  public groupKey: string;
}

