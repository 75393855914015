
export class ArCustomer {
  constructor(customer?: string, branch?: string, customerName?: string, customerOnHold?: string, telephone?: string,
    addTelephone?: string, fax?: string, email?: string, companyTaxNumber?: string, taxStatus?: string, taxExemptNumber?: string,
    soldToAddr1?: string, soldToAddr2?: string, soldToAddr3?: string, soldToAddr4?: string, soldToAddr5?: string, soldPostalCode?: string,
    shipToAddr1?: string, shipToAddr2?: string, shipToAddr3?: string, shipToAddr4?: string, shipToAddr5?: string, shipPostalCode?: string,
    area?: string, salesperson?: string, creditStatus?: string, termsDescription?: string, currency?: string, customerClass?: string,
    contact?: string, telex?: string,
    creditLimit?: string, currentBalance1?: string, valCurrentInv?: string, val30daysInv?: string, val60daysInv?: string, val90daysInv?: string,
    val120daysInv?: string, mtdCrMemoVal1?: string, valFutureInv?: string, mtdNumInvoices1?: string, numcurrentinv?: string, num30daysInv?: string,
    num60daysInv?: string, num90daysInv?: string, num120daysInv?: string, mtdNumCrMemos1?: string, numFutureInv?: string, mtdInvoiceVal1?: string,
    buyersName?: string, buyersEmail?: string, salespersonName?: string, debtorsClerk?: string) {

    this.customer = customer;
    this.branch = branch;
    this.customerName = customerName;
    this.customerOnHold = customerOnHold;
    this.telephone = telephone;
    this.addTelephone = addTelephone;
    this.email = email;
    this.fax = fax;
    this.companyTaxNumber = companyTaxNumber;
    this.area = area;
    this.salesperson = salesperson;
    this.creditStatus = creditStatus;
    this.termsDescription = termsDescription;
    this.currency = currency;
    this.customerClass = customerClass;
    this.taxExemptNumber = taxExemptNumber;
    this.taxStatus = taxStatus;
    this.contact = contact;
    this.soldToAddr1 = soldToAddr1;
    this.soldToAddr2 = soldToAddr2;
    this.soldToAddr3 = soldToAddr3;
    this.soldToAddr4 = soldToAddr4;
    this.soldToAddr5 = soldToAddr5;
    this.soldPostalCode = soldPostalCode;
    this.shipToAddr1 = shipToAddr1;
    this.shipToAddr2 = shipToAddr2;
    this.shipToAddr3 = shipToAddr3;
    this.shipToAddr4 = shipToAddr4;
    this.shipToAddr5 = shipToAddr5;
    this.shipPostalCode = shipPostalCode;
    this.creditLimit = creditLimit;
    this.currentBalance1 = currentBalance1;
    this.valCurrentInv = valCurrentInv;
    this.val30daysInv = val30daysInv;
    this.val60daysInv = val60daysInv;
    this.val90daysInv = val90daysInv;
    this.val120daysInv = val120daysInv;
    this.mtdCrMemoVal1 = mtdCrMemoVal1;
    this.numCurrentInv = numcurrentinv;
    this.valFutureInv = valFutureInv;
    this.numFutureInv = numFutureInv;
    this.num30daysInv = num30daysInv;
    this.num60daysInv = num60daysInv;
    this.num90daysInv = num90daysInv;
    this.num120daysInv = num120daysInv;
    this.mtdNumCrMemos1 = mtdNumCrMemos1;
    this.mtdInvoiceVal1 = mtdInvoiceVal1;
    this.mtdNumInvoices1 = mtdNumInvoices1;
    this.buyersName = buyersName;
    this.buyersEmail = buyersEmail;
    this.salespersonName = salespersonName;
    this.debtorsClerk = debtorsClerk;
  }
  /*[Key]*/
  public customer: string;
  public branch: string;
  public customerName: string;
  public customerOnHold: string;
  public telephone: string;
  public addTelephone: string;
  public fax: string;
  public email: string;
  public companyTaxNumber: string;
  public TaxStatus: string;
  public taxExemptNumber: string;
  public soldToAddr1: string;
  public soldToAddr2: string;
  public soldToAddr3: string;
  public soldToAddr4: string;
  public soldToAddr5: string;
  public soldPostalCode: string;
  public area: string;
  public salesperson: string;
  public salespersonName: string;
  public termsCode: string;
  public termsDescription: string;
  public currency: string;
  public customerClass: string;
  public contact: string;
  public telex: string;
  public shipToAddr1: string;
  public shipToAddr2: string;
  public shipToAddr3: string;
  public shipToAddr4: string;
  public shipToAddr5: string;
  public shipPostalCode: string;
  public creditStatus: string;
  public taxStatus: string;


  public creditLimit: string;
  public currentBalance1: string;
  public valCurrentInv: string;
  public val30daysInv: string;
  public val60daysInv: string;
  public val90daysInv: string;
  public val120daysInv: string;
  public mtdCrMemoVal1: string;
  public valFutureInv: string;
  public mtdNumInvoices1: string;
  public numCurrentInv: string;
  public num30daysInv: string;
  public num60daysInv: string;
  public num90daysInv: string;
  public num120daysInv: string;
  public mtdNumCrMemos1: string;
  public numFutureInv: string;
  public mtdInvoiceVal1: string;

  public buyersName: string;
  public buyersEmail: string;
  public debtorsClerk: string;

  public overLimit: boolean;
}

