

import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { CustomerOTPReference } from '../../models/customerOTPReference.model';
import { Utilities } from '../../services/utilities';
import { ReportService } from '../../services/report.service';
import { CustomerPayments } from '../../models/customer-payments.model';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ArCustomer } from '../../models/arcustomer.model';


@Component({
  selector: 'customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.scss'],
    animations: [fadeInOut]
})
export class CustomerPortalComponent {

  authorized: boolean;
  stringToCompare: string;

  loadingIndicator: boolean;

  @Input()
  OTP: string;

  @Input()
  Reference: string;
  showResults: boolean;

  columns: any[] = [];
  rows: CustomerPayments[] = [];
  rowsCache: CustomerPayments[] = [];
  customerPaymentsList: CustomerPayments[];


  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;


  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

    customerId: string;

  src: string;
  documentReference: string;
    expired: boolean;
    applicationId: string;
    custDetails: ArCustomer[];
    welcomeMessage: string;


  constructor(public configurations: ConfigurationService, private alertService: AlertService, private reportService: ReportService,
    private fowkesService: FowkesOnlineService) {
  }

  ngOnInit() {

    this.authorized = false;
    this.customerId = "";

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('OTP');
    this.documentReference = myParam;

    //if (this.documentReference) {
    //  this.downloadPDF(this.documentReference);
    //}
  }


  downloadPDF(documentReference: string) {
    this.alertService.stopLoadingMessage();
    this.reportService.getFileStreamStatement(this.documentReference).subscribe(x => this.onFileSuccessful(x, this.documentReference), error => this.onDataLoadFailed(error));
  }

  downloadDocument(row: any) {
    var docRef = "Invoice_";

    if (row.transaction == "Invoice") {
      docRef = "Invoice_" + row.invoice + ".pdf";


      this.reportService.getStatementInvoicePDF(this.customerId, this.applicationId, row.salesOrder, row.invoice, this.OTP).subscribe(x => {
        this.reportService.getFileStreamStatement(docRef).subscribe(x => this.onFileSuccessful(x, docRef), error => this.onDataLoadFailed(error))
      }, error => this.onDataLoadFailed(error));

    }
    if (row.transaction == "Credit") {
      var docRef = "InvoiceCredit_" + row.invoice + ".pdf";


      this.reportService.getStatementCreditPDF(this.customerId, this.applicationId, row.salesOrder, row.invoice, this.OTP).subscribe(x => {
        this.reportService.getFileStreamStatement(docRef).subscribe(x => this.onFileSuccessful(x, docRef), error => this.onDataLoadFailed(error))
      }, error => this.onDataLoadFailed(error));

    }

  }

  ngAfterViewInit() {
  }

  verifyAuth() {
    this.alertService.startLoadingMessage("Verfiying...");
    this.loadingIndicator = true;
    this.Reference = this.stringToCompare;
    this.OTP = this.Reference;

    this.fowkesService.getOTPDetails(this.OTP).subscribe(x => this.onDataLoadSuccesful(x), error => this.onDataLoadFailed(error));
  }
   
  onDataLoadSuccesful(x: CustomerOTPReference[]): void {

    if (x && x.length >= 1) {

      var statementDetail = x[0];

      if (statementDetail) {

        this.onVerificationSuccess();
        this.customerId = statementDetail.customerCode;
        this.applicationId = statementDetail.application;

        //Changing to 10 days from 5
        var d = new Date();
        d.setDate(d.getDate() - 10);
        var c = new Date(statementDetail.createDate);
        //check date
        if (c < d) {
          this.expired = true;
          this.authorized = false;
        }
        else {

          var start = new Date(statementDetail.dateFrom.toString());
          var end = new Date(statementDetail.dateTo.toString());

          this.fowkesService.getCustomerPayments(start.toJSON(), end.toJSON(), statementDetail.customerCode).subscribe(x => this.onStatementDetailLoadSuccessful([x]), error => this.onDataLoadFailed(error));

          this.fowkesService.getCustomerDetail(this.customerId).subscribe(
            x => this.onSpecificSearchSuccesful([x]), error => this.onDataLoadFailed(error));
        }

      }
    }
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

  }

  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (x && x.length >= 1) {

      this.custDetails = x;
      this.welcomeMessage = this.custDetails[0].customerName;

    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }

  }

  onStatementDetailLoadSuccessful(customers: any[]): void {

      this.customerPaymentsList = customers[0];

      if (this.customerPaymentsList && this.customerPaymentsList.length >= 1) {

        this.customerPaymentsList.forEach((customer, index, customers) => {
          (<any>customer).index = index + 1;
          (<any>customer).filterDate = customer.date.toString();
        });

        //Remove 0 value lines
        this.customerPaymentsList = this.customerPaymentsList.filter(x => x.customerPoNumber != 'Unapplied cash')

        this.rowsCache = [...this.customerPaymentsList];
        this.rows = [...this.rowsCache]

      }
      else {
        this.alertService.showMessage('No results', 'No payment details for the search criteria',
          MessageSeverity.warn);
      }

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    }

  viewDocument(row: CustomerOTPReference) {
    if (row.documentNumber != "") {

      this.reportService.getFileStreamStatement(row.documentNumber).subscribe(x => this.onFileSuccessful(x, row.documentNumber), error => this.onDataLoadFailed(error));

    }
  }



  onFileSuccessful(x: Blob, value: string): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;
    var preview = false;
    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = value;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  loadCustomerDetails() {
   //Get Customer Info?

  }

  onVerificationSuccess(): void {
    this.authorized = true;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
   
  }

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve customers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }



  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Customer", "Invoice", "Date", "Transaction", "Customer PO", "Payment Ref",
        "Operator",
        "Total (Incl VAT)"]
    };

    var filename = this.customerId + "-Payments";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {

      exportRows.push({
        Customer: row.customer, Invoice: row.invoice, Date: (moment(row.filterDate)).format('DD-MMM-YYYY'), Transaction: row.transaction,
        CustomerPO: row.customerPoNumber, PaymentRef: row.reference,
        Operator: row.operator,
        Total: row.amount
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
