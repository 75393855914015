
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, ElementRef, OnChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteHeader } from 'src/app/models/quote-header.model';
import { Email } from 'src/app/models/email.model';
import { User } from 'src/app/models/user.model';
import { Role } from 'src/app/models/role.model';
import { SalesOrderHeader } from '../../models/salesorder-header.model';
import { ShippingInstr } from '../../models/shipping-instructions.model';
import * as moment from 'moment';
import { WHAvailQTY } from '../../models/inventory-wh.model';
import { QuoteDetail } from '../../models/quote-detail.model';
import { QuoteList } from '../../models/quote-list.model';
import { CoreAudit } from '../../models/core-audit.model';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { WAPP } from '../../models/whatsapp.model';
import { OptIns, Outbox } from '../../models/wapp-inbox.model';


@Component({
  selector: 'syspro-quote-view',
  templateUrl: './syspro-quote-view.component.html',
  styleUrls: ['./syspro-quote-view.component.scss']
})
export class SysproQuoteViewComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  uniqueId: string = Utilities.uniqueId();
  columns: any[] = [];
    rows: QuoteDetail[] = [];
  rowsCache: QuoteDetail[] = [];
  cartRows: CartDetail[] = [];
  commentLine: QuoteDetail = new QuoteDetail();
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

  emailObject: Email = new Email();
  wappObject: WAPP = new WAPP();

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
    priceTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
    actionsTemplate: TemplateRef<any>;

  

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
  inventoryList: Inventory[];

  @ViewChild('shipDate')
  private shipDate;

  @ViewChild('shippingInstr')
  private shippingInstr;

  @ViewChild('shippingInstrSelector')
  private shippingInstrSelector;
  shippingInstructionsList: ShippingInstr;

  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;


  @ViewChild('commentsModal')
  commentsModal: ModalDirective;

  @ViewChild('confirmModal')
  confirmModal: ModalDirective;


  @ViewChild('changeCustomerModal')
  changeCustomerModal: ModalDirective;  

  @ViewChild('customerSearchModal')
  customerSearchModal: ModalDirective;

  @ViewChild('confirmNewCustomerModal')
  confirmNewCustomerModal: ModalDirective;

  @ViewChild('viewNewCustomerModal')
  viewNewCustomerModal: ModalDirective;

  @ViewChild('viewNewCustomerCartModal')
  viewNewCustomerCartModal: ModalDirective;

  @ViewChild('changeBranchModal')
  changeBranchModal: ModalDirective;

  @ViewChild('commentsSelector')
  private commentsSelector;
  comments: string[];
  newComment: string;

  @ViewChild('printOptionsSelector')
  private printOptionsSelector;

  @ViewChild('printOptions')
  private printOptions;


  @Input()
  isQuote: boolean;

  @Input()
  selectedQuote: string;

  @Input()
  isViewOnly: boolean;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @Input()
  selectedCustomerCode: string;

  @Input()
  selectedCartNumber: string;


    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    quoteId: string;
    quote: QuoteHeader;
    pdfName: string;
    isUpdating: any;
  quoteHeader: QuoteHeader = new QuoteHeader();
    user: any;
    allRoles: any[];
  src: string;
  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  stockCodedetail: Inventory;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;

  startDate = new Date().toISOString().slice(0, 16);
  customer: ArCustomer;

  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;
    stockCode: string;
    inventoryView: boolean;
    fragment: string;

  auditObj = new CoreAudit();

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  searchResults: QuoteList[] = [];
  searchType: string;
  searchText: string;
  searchResultSelected: QuoteList;

  hasDataSheets: boolean;
  attachDataSheets: boolean;
  attachSpecials: boolean;
  hasAddDataSheets: boolean;
    quoteAge: number;
    enableInput: any;
    buyerResponsible: string;
    branchToUse: string;
    outboxDetail: Outbox;
    WhatsappEnabled: boolean;
    WhatsappNumber: string;
    changeCustomer: boolean;
    newCustomer: ArCustomerEdit = new ArCustomerEdit();
    newCartNumber: any;


  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {

    let action = sessionStorage.getItem(localStorageActions.Customer);
    let quoteId = sessionStorage.getItem(localStorageActions.QuoteNumber);

    this.id = (action ? action.trim() : "");
    this.quoteId = quoteId;

    this.auditObj.referenceType = "QUOTE";
    this.auditObj.referenceNumber = quoteId;
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.accountService.currentUser.email;
    this.auditObj.actionedBy = this.accountService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;
    this.postAudit();   

    if (this.id != "") {
      this.searchType = "customer";
      this.searchText = this.id;
      this.specificSearch();
    }

      this.columns = [
        { prop: 'stockCode', name: 'StockCode', width: 120, cellTemplate: this.stockCodeTemplate },
        { prop: 'stockDescription', name: 'Description', width: 400, cellTemplate: this.descriptionTemplate },
        { prop: 'availQty', name: 'Avail Qty', width: 80, cellClass: "right" },
        { prop: 'orderQty', name: 'Order Qty', width: 100, cellTemplate: this.orderQtyTemplate },
        { prop: 'orderUom', name: 'UOM', width: 80, cellClass: "right" },
        { prop: 'sellingPrice', name: 'Price(excl)', width: 120, cellTemplate: this.priceTemplate, cellClass: "right" },
        { prop: 'discountPercentage1', name: 'Disc(%)', width: 80, cellTemplate: this.discountTemplate, cellClass: "center" },
        { prop: 'lineTotal', name: 'Total(excl)', width: 100, cellClass: "right" }
    ];

    this.loadData();

   // this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));

    }
  ngOnChanges() {

    if (this.selectedQuote) {
      let action = sessionStorage.getItem(localStorageActions.Customer);
      let quoteId = sessionStorage.getItem(localStorageActions.QuoteNumber);

      this.id = (action ? action.trim() : "");
      this.quoteId = quoteId;

      this.searchType = "customer";
      this.searchText = this.id;
      this.specificSearch();

      this.columns = [
        { prop: 'stockCode', name: 'StockCode', width: 120, cellTemplate: this.stockCodeTemplate },
        { prop: 'stockDescription', name: 'Description', width: 400, cellTemplate: this.descriptionTemplate },
        { prop: 'availQty', name: 'Avail Qty', width: 80, cellClass: "right" },
        { prop: 'orderQty', name: 'Order Qty', width: 100, cellTemplate: this.orderQtyTemplate },
        { prop: 'orderUom', name: 'UOM', width: 80, cellClass: "right" },
        { prop: 'sellingPrice', name: 'Price(excl)', width: 120, cellTemplate: this.priceTemplate, cellClass: "right" },
        { prop: 'discountPercentage1', name: 'Disc(%)', width: 80, cellTemplate: this.discountTemplate, cellClass: "center" },
        { prop: 'lineTotal', name: 'Total(excl)', width: 100, cellClass: "right" }
      ];

      this.loadData();
    }
    else {
      this.quoteHeader = new QuoteHeader();
      this.rows = null;
    }
    
  }

  ngAfterViewInit() {
    //this.confirmInput.nativeElement.focus();
  }
  

  loadData() {
    if (this.quoteId) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
     
      this.sysproService.getQuoteHeader(this.quoteId).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));
      this.sysproService.GetSysproComments().subscribe(x => this.onCommentsLoadSuccessful(x), error => this.onDataLoadFailed(error));

      //Check for wapp settings
      this.sysproService.GetUserTel(this.accountService.currentUser.userName).subscribe(x => this.onTelReceived(x), error => this.onOptinLoadFailed(error));

      //this.sysproService.getWappOptInDetails(this.accountService.currentUser.userName).subscribe(x => this.onUserWappLoadSuccesful(x), error => this.onOptinLoadFailed(error));
    }

  }

  onTelReceived(x: string): void {
    console.log(x);
    this.WhatsappEnabled = true;
    this.WhatsappNumber = x;

  }
    onOptinLoadFailed(error: any): void {
      this.WhatsappEnabled = false;
    }
    onUserWappLoadSuccesful(x: OptIns): void {
      if (x) {
        //this.WhatsappEnabled = x.optedIn;
        this.WhatsappEnabled = true;
        this.WhatsappNumber = x.phoneNumber;
      }
    }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      this.sysproService.getQuoteNumberSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onDataLoadFailed(error));

    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: QuoteList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;


    //Filter out web orders but show web quotes
    this.searchResults = x; // x.filter(x => x.customer !== "FBWEB" || x.webQuote === true);


    if (x.length === 1) {
      this.searchResultSelected = x[0];
      if (this.searchType.toUpperCase() == "QUOTENUMBER") {

        this.viewQuoteSelected();
      }
    }

  }
    viewQuoteSelected() {
      if (this.searchResultSelected) {
        sessionStorage.removeItem(localStorageActions.Customer);
        sessionStorage.setItem(localStorageActions.Customer, this.searchResultSelected.customer);
        sessionStorage.removeItem(localStorageActions.QuoteNumber);
        sessionStorage.setItem(localStorageActions.QuoteNumber, this.searchResultSelected.quoteNumber);
       
        this.id = this.searchResultSelected.customer;
        this.quoteId = this.searchResultSelected.quoteNumber;       
        this.loadData();
        this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: this.searchResultSelected.customer } });

      }
    }

  viewSalesOrder() {
    if (sessionStorage.getItem(localStorageActions.Customer) != this.id) {
      sessionStorage.setItem(localStorageActions.Customer, this.id);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.quoteHeader.convertedRef) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.quoteHeader.convertedRef);
    }
    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: this.id } });
  }

    onCommentsLoadSuccessful(x: string[]): void {
      this.comments = x;
      setTimeout(() => {
        if (this.commentsSelector) {
          this.commentsSelector.refresh();
        }
      });

      console.log("COMMENTS", this.comments);
  }

  openCommentsModal() {
    this.commentsModal.show();
    this.commentLine = new QuoteDetail();
    this.commentLine.commentType = "I";
    console.log("COMMENT LINE", this.commentLine);
  }


  addTopComment() {
    const lineNumber = 0;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___");
    this.sysproService.updateQuoteComments(this.quoteId, lineNumber, 0, comment, "TOP")
      .subscribe(x => this.loadData(), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }

  addComment() {
    const lineNumber = this.rows[this.rows.length - 1].quoteLineNumber + 1;
    let comment = this.commentLine.comment ? this.commentLine.comment : this.newComment.replace(/\//g, "--").replace(/\+/gi, "___");
    let isPreviousLineAComment = (this.rows[this.rows.length - 1].lineType == "6") ? "Y" : "N";
    this.sysproService.updateQuoteComments(this.quoteId, lineNumber, 0, comment, isPreviousLineAComment)
      .subscribe(x => this.loadData(), error => this.onDataLoadFailed(error));
    this.commentsModal.hide();
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get application(): string {
    return this.configurations.applicationName;
  }
    
  onCartHeaderLoadSuccessful(quote: QuoteHeader) {
    let res = quote;

    this.sysproService.getShippingInstructions().subscribe(x => this.onShippingInstructionsLoadSuccessful(x), error => this.onShippingInstructionsFailed(error));

    this.quoteHeader = res[0];
    this.startDate = moment().format('YYYY-MM-DD');
    this.quoteHeader.shipdate = new Date(this.startDate);

    this.branchToUse = this.quoteHeader.branch_code;

    console.log(this.quoteHeader);

    this.sysproService.getQuoteDetail(this.quoteId).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onShippingInstructionsFailed(error: any): void {
    throw new Error("Method not implemented.");
  }
  onShippingInstructionsLoadSuccessful(x: ShippingInstr): void {
    this.shippingInstructionsList = x;
    if (this.quoteHeader.shippingInstrs == "") {
      this.quoteHeader.shippingInstrs = "01 - DELIVER VIA FOWKES CT BAKKIE";
    }
    setTimeout(() => {
      if (this.shippingInstrSelector) {
        this.shippingInstrSelector.refresh();
      }
    });
    console.log("THIS IS THE SHIPPING INSTRUCTIONS", this.shippingInstructionsList);
  }


  onCartDetailLoadSuccessful(quoteDetail: any[]) {

    this.rowsCache = [...quoteDetail];
    const updateRows = this.rowsCache;
    const vat = this.quoteHeader.vatPercentage;

    this.TotalLines = updateRows.filter(x => x.lineType !== "6").length;

    this.TotalItems = 0;
    this.TotalExcl = 0;
    this.TotalDiscount = 0;
    this.TotalExclAfterDiscount = 0;
    this.TotalVat = 0;
    this.TotalIncl = 0;

    for (var product of updateRows) {
      var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.sellingPrice, product.priceConvFac, product.lineTotal);
      var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.sellingPrice, product.discountPercentage1, product.priceConvFac, 0);

      this.TotalItems += product.orderQty;
      this.TotalExcl += lineTotalExclVat;
      this.TotalDiscount += lineDiscount;

      var lineVat = 0;
      if (vat && product.vatExempt == false) {
        lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);
      }
      //var lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);

      this.TotalVat += lineVat;

      this.calcTotal(product);
    }

    this.TotalExclAfterDiscount = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero(this.TotalDiscount, 2));
    this.TotalIncl = Calculations.RoundAwayFromZero((this.TotalExclAfterDiscount + this.TotalVat), 2);

    this.rows = quoteDetail;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  
  viewCustomer() {
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goAddItems() {

    this.isNavigating = true;
    this.fragment = 'inventory';
    this.CopyQuote();
    //this.router.navigate(['../../quotes'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }
  
  
  goAddNSItems() {
    this.isNavigating = true;
    this.fragment = 'nonstock';
    this.CopyQuote();
   // this.router.navigate(['../../quotes'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goCreateCart() {
    this.isNavigating = true;
    this.fragment = 'cart';
    if (this.branchCode != this.quoteHeader.branch_code) {
      this.changeBranchModal.show();
      this.quoteAge = new Date(Date.now() - +(new Date(this.quoteHeader.createDate))).getDate() - 1;
    }
    else {
      this.CopyQuote();
    }
  }

  newCopyQuoteButton() {

    this.quoteAge = new Date(Date.now() - +(new Date(this.quoteHeader.createDate))).getDate() - 1;
    //Check if logged in branch different to quote branch
    if (this.branchCode != this.quoteHeader.branch_code) {
      this.changeBranchModal.show();
    }
    else {
      this.confirmModal.show();
    }
  }

  
  changeCustomerCopyQuoteButton() {

    this.changeCustomer = true;
    this.changeCustomerModal.show();
    this.newCustomer = new ArCustomerEdit();
  }

  changeCustomerModalHide() {

    this.changeCustomer = false;
    this.changeCustomerModal.hide();
    this.newCustomer = new ArCustomerEdit();
  }

  openSearchForCustomerModal() {
    this.changeCustomer = true;
    this.changeCustomerModal.hide();
    this.newCustomer = new ArCustomerEdit();

    if (!this.customerSearchModal) {
      setTimeout(() => {
        if (this.customerSearchModal) {
          this.customerSearchModal.show();
        }
      });
    }
    else {
      this.customerSearchModal.show();
    }
  }

  onCustomerSelected(customer: any) {
    console.log('Selected customer:', customer);

    if (customer) {
      this.newCustomer = customer;

      if (!this.confirmNewCustomerModal) {
        setTimeout(() => {
          if (this.confirmNewCustomerModal) {
            this.confirmNewCustomerModal.show();
          }
        });
      }
      else {
        this.confirmNewCustomerModal.show();
      }

      this.customerSearchModal.hide();
    }
  }

  confirmNewCustomerForQuote(customerCode: string) {

    this.selectedCustomerCode = customerCode;
    //Create Cart//Set id
    var uniqueId = this.id + Math.random().toString(36).substring(2) + Date.now().toString(36);
    this.cartId = uniqueId;
    sessionStorage.removeItem(localStorageActions.CartId);
    sessionStorage.setItem(localStorageActions.CartId, this.cartId);

    //Set new customer Id
    if (sessionStorage.getItem(localStorageActions.Customer) != customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, customerCode);
    }

    this.sysproService.getCartFromQuote(this.quoteId, this.cartId, this.branchToUse).subscribe(_response => this.newCustomerCartCreatedSuccessHelper(_response), error => this.saveFailedHelper(error));

  }

  private newCustomerCartCreatedSuccessHelper(res?: any) {

    this.cartSaved = true;
    this.alertService.stopLoadingMessage();
    this.confirmNewCustomerModal.hide();

    //Load Customer View
    if (!this.viewNewCustomerModal) {
      setTimeout(() => {
        if (this.viewNewCustomerModal) {
          this.viewNewCustomerModal.show();
        }
      });
    }
    else {
      this.viewNewCustomerModal.show();
    }
    
  }


  onCartNumberSelected(cartNumber: any) {
    console.log('Selected cart:', cartNumber);

    if (cartNumber) {
      this.newCartNumber = cartNumber;
      this.selectedCartNumber = cartNumber;

      if (!this.viewNewCustomerCartModal) {
        setTimeout(() => {
          if (this.viewNewCustomerCartModal) {
            this.viewNewCustomerCartModal.show();
          }
        });
      }
      else {
        this.viewNewCustomerCartModal.show();
      }

      this.viewNewCustomerModal.hide();
    }
  }

  viewNewCustomerCartModalHide() {

    this.changeCustomer = false;
    this.viewNewCustomerCartModal.hide();
    this.newCustomer = new ArCustomerEdit();
    this.selectedCartNumber = "";
    this.newCartNumber = "";

  }
  customerSearchModalHide() {
    this.changeCustomer = false;
    this.customerSearchModal.hide();
  }
  viewNewCustomerModalHide() {
    this.changeCustomer = false;
    this.viewNewCustomerModal.hide();
    this.newCustomer = new ArCustomerEdit();
  }


  changeBranchModalHide() {
    this.changeBranchModal.hide();
  }

  keepBranchHelper(): any {
    this.branchToUse = this.quoteHeader.branch_code;
    this.changeBranchModal.hide();
    this.confirmModal.show();
  }


  newBranchHelper(): any {
    this.changeBranchModal.hide();
    //Change Branch

    this.branchToUse = this.branchCode;
    this.confirmModal.show();
  }

  confirmModalHide() {
    this.confirmModal.hide();
  }


  CopyQuote() {
    var uniqueId = this.id + Math.random().toString(36).substring(2) + Date.now().toString(36);
    this.cartId = uniqueId;
    sessionStorage.removeItem(localStorageActions.CartId);
    sessionStorage.setItem(localStorageActions.CartId, this.cartId);
    this.sysproService.getCartFromQuote(this.quoteId, this.cartId, this.branchToUse).subscribe(_response => this.saveSuccessHelper(_response), error => this.saveFailedHelper(error));  

  }

  reloadPricingHelper(): any {
    //Reload Prices 
    sessionStorage.setItem(localStorageActions.Reset, "true");
    this.isNavigating = true;
    this.fragment = 'cart';
    this.CopyQuote();
  }


  keepQuotePricingHelper(): any {
    // Dont reload prices
    sessionStorage.setItem(localStorageActions.Reset, "false");
    this.isNavigating = true;
    this.fragment = 'cart';
    this.CopyQuote();
  }


  private saveSuccessHelper(res?: any) {

    this.cartSaved = true;

    this.alertService.stopLoadingMessage();

    if (res) {
      console.log(res);
    }

    if (this.isNavigating) {
      this.router.navigate(['../orders'], { fragment: this.fragment, queryParams: { customer: this.id, cartId: this.cartId } });
    }

    if (this.isUpdating) {

      this.isUpdating = false;
      this.alertService.showMessage('Success', this.quoteId + ` Updated successfully`, MessageSeverity.success);
      this.loadData();
    }
    else {

      this.isSaving = false;
      this.alertService.showMessage('Success', this.cartId + ` Created successfully`, MessageSeverity.success);
    }

  }


  //goPlaceOrder() {
  //  //this.alertService.startLoadingMessage('Loggin into Syspro...');
  //  ////Check SYSPRO User
  //  //this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onsessionCreatedSuccesful(x), error => this.onSessionFailed(error));


  //}

  //onsessionCreatedSuccesful(x: User): void {
  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;
  //  this.alertService.showMessage('Success', `Logged into syspro successfully`, MessageSeverity.success);

  //  this.alertService.startLoadingMessage('Creating Sales Order...');
  //  var user = x;
  //  var header = this.quoteHeader;
  //  var detail = this.rows;
  //  var postObject = {
  //    "user": user, "header": header, "detail": detail
  //  };
  //  this.sysproService.postSalesOrderSyspro(postObject).subscribe(x => this.onSoirtoiPOSTSuccesful(x), error => this.onSoirtoiPOSTFailed(error));
  //}
  //onSoirtoiPOSTSuccesful(x: any): void {

  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;
  //  var salesOrderHeader = x[0];
  //  if (salesOrderHeader) {
  //    this.alertService.showMessage('Success', `Created Sales Order`, MessageSeverity.success);
  //    this.isNavigating = true;

  //    sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
  //    sessionStorage.setItem(localStorageActions.SalesOrderNumber, salesOrderHeader.salesOrderNumber);
  //    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: this.id, salesorder: salesOrderHeader.salesOrderNumber } });
  //  }
  //  else {

  //    this.alertService.showMessage('Failed', `Creating Sales Order Failed`, MessageSeverity.error);
  //  }

  //}
  goCancelQuote() {

    this.isNavigating = false;
    this.isUpdating = true;
    this.sysproService.getUpdateQuoteStatus(this.quoteId, "C").subscribe(_response => this.saveSuccessHelper(_response), error => this.saveFailedHelper(error));

  }


  onStockLoadSuccessful(inventory: any[]) {

    this.inventoryList = inventory[0];
  }

  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.stockDescription;
      this.stockCode = this.stockCodedetail.stockCode;

      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.addSheetImage = row.addSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.onSpecialFowkes = row.onSpecialFowkes;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  onStockCodeLoadSuccessful(row: Inventory): void {

    this.stockCodedetail = new Inventory();
    this.stockCodedetail = row[0];
    this.hasDataSheet = false;
    this.hasSpecials = false;
    this.hasAddDataSheets = false;
    if (this.stockCodedetail.dataSheetImage) {
      this.hasDataSheet = true;
      this.stockCodedetail.dataSheetImage = encodeURIComponent(this.stockCodedetail.dataSheetImage);
    }
    if (this.stockCodedetail.addSheetImage) {
      this.hasAddDataSheets = true;
      this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
    }
    if (this.stockCodedetail.specialsImage) {
      this.hasSpecials = true;
    }
    if (!!this.stockCodedetail) {
      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }
    }
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }

  openSpecials(row: Inventory) {
    if (this.application == "MaxArcusOnline") {
      window.open("https://www.maxarcus.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    else {
      //Updated 
      window.open("https://www.fowkes.co.za/products?keyword=" + encodeURIComponent(row.stockCode), "_blank");
    }
    //if (!!row.specialsImage && row.specialsImage != "") {
    //  window.open(row.filePath + "/SpecialImages/" + row.specialsImage, "_blank");
    //}
  }

  showWHDetail(row: any, event) {
    if (row) {
      if (row.lineType != "7") {
        this.stockDescription = row.stockCode + ": " + row.stockDescription;
        this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      }
    }
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }

  onEmailLoadSuccessful(type?: string) {
       
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasSpecials = false;
    this.hasAddDataSheets = false;

    if (type == "stockcode") {
      this.inventoryView = true;
      this.stockCodeModal.hide();

      var stockCode = this.stockCodedetail.stockCode;
      var description = this.stockDescription;

      this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);

      this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);

      this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.specialsImage.length >= 1 && (this.stockCodedetail.onSpecial || this.stockCodedetail.onSpecialFowkes));

      this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___"), this.branchCode, this.application).subscribe(x => {
        this.emailModal.show(),
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }, error => this.onDataLoadFailed(error));

      this.emailObject.RecepientName = this.quoteHeader.contact;
      this.emailObject.RecepientEmail = this.quoteHeader.email;
      this.emailObject.CC = "";
      this.emailObject.BCC = this.accountService.currentUser.email;
      this.emailObject.Subject = "Inventory Item: " + description;
      this.emailObject.SenderName = this.accountService.currentUser.fullName;
      this.emailObject.SenderEmail = this.accountService.currentUser.email;
      this.emailObject.SenderBranch = this.branchCode;
      this.emailObject.SenderCompany = this.application;
      this.emailObject.Attachment = stockCode;
      this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
      this.emailObject.EmailType = "Inventory";

      if (this.hasDataSheets) {
        this.attachDataSheets = true;
        this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();
      }

      if (this.hasAddDataSheets) {
        this.attachDataSheets = true;
        this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
      }

      if (this.hasSpecials) {
        this.attachSpecials = true;
        this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
      }
    }
    else {

      this.inventoryView = false;
      this.emailObject.RecepientName = "";
      this.emailObject.RecepientEmail = "";
      this.emailObject.CC = "";
      this.emailObject.BCC = this.accountService.currentUser.email;
      this.emailObject.Subject = "Quote: " + this.quoteId;
      this.emailObject.SenderName = this.accountService.currentUser.fullName;
      this.emailObject.SenderEmail = this.accountService.currentUser.email;
      this.emailObject.SenderBranch = this.branchCode;
      this.emailObject.SenderCompany = this.application;
      this.emailObject.Attachment = this.quoteId;
      this.emailObject.Bod = "Thank you for allowing us to submit our quotation. Please find attached PDF that can be viewed with any standard PDF reader. If you decide to accept please reference this quote number.";

      this.reportService.getQuotePDF(this.quoteId, this.application).subscribe(x => {
        this.emailModal.show(),
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }, error => this.onDataLoadFailed(error));
     
    }

  }

  titleCase(str) {
    str = str.toLowerCase();
  return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
}

  viewPDF() {
    if (this.inventoryView) {
      var stockCode = this.emailObject.Attachment;
      this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));

    }
    else {
      //this.reportService.getQuotePDF(this.quoteId).subscribe(x => this.getPDF(x, this.quoteId), error => this.onDataLoadFailed(error));
      this.reportService.getFileStream(this.quoteId).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
    }
  }

  sendWappMessage() {
    this.outboxDetail = new Outbox();

    this.outboxDetail.toPhoneNumber = this.WhatsappNumber;
    this.outboxDetail.content = "Template";
    this.outboxDetail.headerMediaFileId = this.quoteId;
    this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;

    //this.sysproService.sendwappWithQuote(this.outboxDetail.toPhoneNumber, 
    //  this.outboxDetail.headerMediaFileId, this.outboxDetail.nextGenUser).subscribe(x => this.onMessageSentSuccesful(), error => this.onDataLoadFailed(error));

    this.sysproService.sendTemplateWithFile(this.outboxDetail.headerMediaFileId, "QUOTE", this.outboxDetail.toPhoneNumber, this.outboxDetail.nextGenUser,
      this.outboxDetail.headerMediaFileId).subscribe(x => this.onWhatsappSentSuccesful(x), error => this.WhatsappSentFailed(error));
  }

    WhatsappSentFailed(error: any): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.alertService.showStickyMessage('Error', `Something went wrong: "${Utilities.getHttpResponseMessages(error)}"`,
        MessageSeverity.error, error);
    }

  onWhatsappSentSuccesful(x: any): void {
    console.log(x);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
    this.alertService.showStickyMessage('', `"${x.message}"`, MessageSeverity.info);
      this.emailModal.hide();
    }

    onMessageSentSuccesful(): void {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', `Message sent successfully`, MessageSeverity.success);
      this.emailModal.hide();
    }

  previewPDF() {
    if (this.inventoryView) {
      var stockCode = this.emailObject.Attachment;
      this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));

    }
    else {
      this.reportService.getFileStream(this.quoteId).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
    }
  }

  emailPDF() {

   
    if (this.emailObject.RecepientEmail && this.emailObject.RecepientEmail.trim().length >= 1) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.emailObject.AddDoc1 = (this.attachDataSheets ? this.emailObject.AddDoc1 : "");
      this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");


      this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showMessage('Warning', `Remeber to add email address for recepient`, MessageSeverity.warn);
    }

  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }
  
  getPDF(x: Blob, quoteNumber: string): void {
    if (this.inventoryView) {
      var stockCode = this.emailObject.Attachment;
      this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));

    }
    else {
      this.reportService.getFileStream(quoteNumber).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
    }
  }

  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      if (this.inventoryView) {
        link.download = this.emailObject.Attachment.replace('/', '_').replace('+', '_') + ".pdf";
      }
      else {

        link.download = this.quoteId + ".pdf";
      }
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  exportCSV() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["QuoteNumber", "Line", "StockCode", "Description", "QTY", "UOM", "Price", "Discount", "Nett Price", "Total Ex Vat"]
    };

    var filename = this.quoteId;
    var selectRows = [...this.rowsCache];
    var exportRows = [];
    for (var row of selectRows) {
      exportRows.push({
        QuoteNumber: row.quoteNumber, Line: row.quoteLineNumber, StockCode: row.stockCode.replace(/\,/g, ' ').replace(/\;/g, ' '), Description: row.stockDescription.replace(/\,/g, ' ').replace(/\;/g, ' '),
        QTY: row.orderQty,
        UOM: row.orderUom, Price: row.sellingPrice, Discount: row.discountPercentage1, Nett: row.nettPrice, Total: row.lineTotal
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  //onSessionFailed(error: any): void {
  //  this.alertService.stopLoadingMessage();
  //  this.alertService.showStickyMessage('Error', 'Could not log you into SYSPRO', MessageSeverity.error, error);
  //  this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  //}

  //onSoirtoiPOSTFailed(error: any): void {
  //  this.alertService.stopLoadingMessage();
  //  this.alertService.showStickyMessage('Error', 'The below errors occured whilst placing Sales Order:', MessageSeverity.error, error);
  //  this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  //}

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback) {
    //  this.changesFailedCallback();
    //}
  }

  private save(location?) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    
    for (var product of this.rowsCache) {
    }


  }

  viewInventory() {

  }

  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
  }


  calcDisc(row: any, event) {
    var val = event.target.value;
    row.discountPercentage1 = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
  }


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
  }


  calcTotal(row: any) {
    let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
    row.lineTotal = total;
      //Calculations.precise_round(total, 2);
  }

  setDelete(row: any) {
    if (row.selected) {
      this.selectedItems.push(row.lineNumber);
    }
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }


  private postAudit() {
    this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  }
}
