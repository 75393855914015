
export class Inventory {
  constructor(stockCode?: string, warehouse?: string, description?: string, availQty?: number, uom?: string,
    priceUom?: string, backOrderIndicator?: string, binLoc?: string, decimals?: number, qtyOnHand?: number, qtyAllocated?: number,
    qtyInTransit?: number, qtyOnOrder?: number, sellingPrice?: number, unitCost?: number, priceConvFac?: number, showAlternate?: boolean,
    newArrival?: boolean, onSpecial?: boolean, onSpecialFowkes?: boolean, alternateKey?: string, specialsImage?: string, specialPrice?: number,   
    orderqty?: number, discountpercentage1?: number, lineTotal?: number,
    extraDescription1?: string, extraDescription2?: string,
    categoryName?: string, brandName?: string, stockCodeImage?: string,
    dataSheetImage?: string, addSheetImage?: string,  status?: string, drawOfficeNum?: string, 
    mapped?: boolean, shortDescription?: string, longDescription?: string, impaCodes?: string, categoryId?: number, id?: number,
    vatExempt?: boolean, extraProductInform?: string, sysproNettPrice?: boolean, newPrice?: number, stockCodeImageChanged?: Date, dataSheetImageChanged?: Date,
    addSheetImageChanged?: Date, specialsImageChanged?: Date, topSellerText?: string, qtyPerCase?: number ) {

    this.stockCode = stockCode;
    this.warehouse = warehouse;
    this.description = description;
    this.availQty = availQty;
    this.uom = uom;
    this.priceUom = priceUom;
    this.backOrderIndicator = backOrderIndicator;
    this.binLoc = binLoc;
    this.decimals = decimals;
    this.qtyOnHand = qtyOnHand;
    this.qtyAllocated = qtyAllocated;
    this.qtyInTransit = qtyInTransit;
    this.qtyOnOrder = qtyOnOrder;
    this.sellingPrice = sellingPrice;
    this.unitCost = unitCost;
    this.priceConvFac = priceConvFac;
    this.showAlternate = showAlternate;
    this.newArrival = newArrival;
    this.onSpecial = onSpecial;
    this.onSpecialFowkes = onSpecialFowkes;
    this.alternateKey = alternateKey;
    this.specialsImage = specialsImage;
    this.specialPrice = specialPrice;
    this.extraDescription1 = extraDescription1;
    this.extraDescription2 = extraDescription2;
    this.categoryName = categoryName;
    this.brandName = brandName;
    this.stockCodeImage = stockCodeImage;
    this.dataSheetImage = dataSheetImage;
    this.addSheetImage = addSheetImage;
    this.status = status;
    this.drawOfficeNum = drawOfficeNum;
    this.mapped = mapped;
    this.shortDescription = shortDescription;
    this.longDescription = longDescription;
    this.impaCodes = impaCodes;
    this.categoryId = categoryId;
    this.id = id;
    this.vatExempt = vatExempt;
    this.extraProductInform = extraProductInform;
    this.topSellerText = topSellerText;
    this.sysproNettPrice = sysproNettPrice;
    this.newPrice = newPrice;
    this.stockCodeImageChanged = stockCodeImageChanged;
    this.dataSheetImageChanged = dataSheetImageChanged;
    this.addSheetImageChanged = addSheetImageChanged;
    this.specialsImageChanged = specialsImageChanged;
    this.qtyPerCase = qtyPerCase;
  }

  public id: number;
  public stockCode: string;
  public warehouse: string;
  public description: string;
  public availQty: number;
  public uom: string;
  public priceUom: string;
  public backOrderIndicator: string;
  public binLoc: string;
  public decimals: number;
  public qtyOnHand: number;
  public qtyAllocated: number;
  public qtyInTransit: number;
  public qtyOnOrder: number;
  public sellingPrice: number;
  public unitCost: number;
  public priceConvFac: number;
  public showAlternate: boolean;
  public newArrival: boolean;
  public onSpecial: boolean;
  public onSpecialFowkes: boolean;
  public alternateKey: string;
  public specialsImage: string;
  public specialPrice: number;
  public orderqty: number;
  public orderQty: number;
  public discountpercentage1: number;
  public lineTotal: number;

  public extraDescription1: string;
  public extraDescription2: string;
  public categoryName: string;
  public brandName: string;
  public stockCodeImage: string;
  public dataSheetImage: string;
  public addSheetImage: string;
  public status: string;
  public drawOfficeNum: string;
  public mapped: boolean;
  public shortDescription: string;
  public longDescription: string;
  public impaCodes: string;
  public categoryId: number;
  public categoryTree: string;
  public filePath: string;
  public qtyCT: number;
  public qtyCombined: number;
  public binLocCT: string;
  public createDate: Date;
  public specialDiscount: number;
  public specialCostAdd: number;
  public specialGP: number;
  public specialName: string;
  public specialPriority: number;

  public vatExempt: boolean;
  public extraProductInform: string;
  public topSellerText: string;
  public sysproNettPrice: boolean;
  public newPrice: number;
  public stockCodeImageChanged: Date;//null;
  public dataSheetImageChanged: Date;//null;
  public addSheetImageChanged: Date;//null;
  public specialsImageChanged: Date;//null;
  public modifiedDate: Date;
  public qtyPerCase: number;
}

