
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { PrinterService } from '../../services/printer.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteHeader } from 'src/app/models/quote-header.model';
import { Email } from 'src/app/models/email.model';
import { User } from 'src/app/models/user.model';
import { Role } from 'src/app/models/role.model';
import { SalesOrderStatus } from 'src/app/models/salesorder-status.model';
import { SalesOrderCancelReasonCode } from 'src/app/models/salesorder-cancelreasons.model';
import { InvoiceDetail } from 'src/app/models/invoice-detail.model';
import { InvoiceHeader } from 'src/app/models/invoice-header.model';
import { CoreAudit } from '../../models/core-audit.model';
import { Printers } from '../../models/printers.model';
import { PrinterUserMappings } from '../../models/printer-user-mappings.model';
import { WHAvailQTY } from '../../models/inventory-wh.model';
import { SalesOrderList } from '../../models/salesorder-list.model';
import { InvoiceReprint } from '../../models/invoice-reprint.model';
import { InvoiceTrackingStatus } from '../../models/invoice-tracking-status.model';
import { WebOrder } from '../../models/web-order.model';
import { BarcodeReference } from '../../models/barcode-reference.model';
import { OptIns, Outbox } from '../../models/wapp-inbox.model';


@Component({
  selector: 'syspro-invoice-view',
  templateUrl: './syspro-invoice-view.component.html',
  styleUrls: ['./syspro-invoice-view.component.scss']
})
export class SysproInvoiceViewComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  uniqueId: string = Utilities.uniqueId();
  columns: any[] = [];
    rows: InvoiceDetail[] = [];
  rowsCache: InvoiceDetail[] = [];
    //cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
    loadingIndicator: boolean;

    emailObject: Email = new Email();

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;


  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
    priceTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('cancelTemplate')
  cancelTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
  inventoryList: Inventory[];


  @ViewChild('warehouseModal')
  warehouseModal: ModalDirective;

  @ViewChild('stockCodeModal')
  stockCodeModal: ModalDirective;

  @ViewChild('podModal')
  podModal: ModalDirective;

  @ViewChild('emailModal')
  emailModal: ModalDirective;
  @ViewChild('pdfModal')
  pdfModal: ModalDirective;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
    quoteId: string;
  quote: QuoteHeader;
  salesOrderNumber: string;
  invoiceNumber: string;
 // salesOrder: SalesOrderHeader;
  invoiceHeader: InvoiceHeader = new InvoiceHeader();
    pdfName: string;
    isUpdating: any;
  quoteHeader: QuoteHeader = new QuoteHeader();
    user: any;
    allRoles: any[];
    src: string;
    OrderStatuses: SalesOrderStatus;
  CancelCodes: SalesOrderCancelReasonCode;
  CancelReason: string;

  @ViewChild('cancelreasons')
  private cancelreasons;

  @ViewChild('cancelreasonsSelector')
  private cancelreasonsSelector;

  selectedPrinter: string;

  @ViewChild('printModal')
  printModal: ModalDirective;
  @ViewChild('printerSelector')
  private printerSelector;
  Printers: Printers[] = [];
  printerMappings: PrinterUserMappings[] = [];

  @ViewChild('authModal')
  authModal: ModalDirective;

  authorized: boolean;
  stringToCompare: string;

  auditObj = new CoreAudit();

  whDetail: WHAvailQTY[] = [];
  stockDescription: string;
  stockCodedetail: Inventory;
  hasDataSheet: boolean;
  hasSpecials: boolean;
  alternateKey: string;


  hasDataSheets: boolean;
  attachDataSheets: boolean;
  attachSpecials: boolean;

  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @Input()
  stockCode: string;
  searchResults: SalesOrderList[];
  searchType: string;
  searchText: string;
  searchResultSelected: SalesOrderList;
    checkCreditNotes: boolean;
    hasTracking: boolean;
    hasAddDataSheet: boolean;
    hasAddDataSheets: boolean;
    buyerResponsible: string;
    invoiceReprintList: InvoiceReprint[] = [];
    originalPrintLine: InvoiceReprint;
    isWebOrder: boolean;
  result: InvoiceTrackingStatus;
  ITSObject: InvoiceTrackingStatus = new InvoiceTrackingStatus();

  hasPODs: boolean;
  referenceList: BarcodeReference[];

  outboxDetail: Outbox;
  WhatsappEnabled: boolean;
  WhatsappNumber: string;

  weborderCompleted: boolean;

  @Input()
  isViewOnly: boolean;

  @Input()
  selectedInvoice: string;

  @Input()
  selectedSalesOrder: string;

  @Input()
  isCreditNote: boolean;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService,
    private router: Router, private route: ActivatedRoute, private printerService: PrinterService) {
    }


  ngOnInit() {

    const action = sessionStorage.getItem(localStorageActions.Customer);
    const salesOrderNumber = sessionStorage.getItem(localStorageActions.SalesOrderNumber);
    const invoiceNumber = sessionStorage.getItem(localStorageActions.InvoiceNumber);

    this.id = (action ? action.trim() : "");

    this.auditObj.referenceType = "INVOICE";
    this.auditObj.referenceNumber = invoiceNumber;
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.accountService.currentUser.email;
    this.auditObj.actionedBy = this.accountService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;
    this.postAudit();

    this.searchType = "invoice";
    this.checkCreditNotes = false;

    if (invoiceNumber && invoiceNumber != "") {

      this.salesOrderNumber = salesOrderNumber;
      this.invoiceNumber = invoiceNumber;

      this.loadData();

    }
    else {
      if (salesOrderNumber && salesOrderNumber != "") {
        this.checkCreditNotes = true;
        this.searchText = salesOrderNumber;
        this.specificSearch();
      }
    }
  }


    ngAfterViewInit() {

  }


  ngOnChanges() {

    this.isViewOnly = this.isViewOnly == true ? this.isViewOnly : false;

    if (this.selectedSalesOrder && this.selectedInvoice) {

      this.salesOrderNumber = this.selectedSalesOrder;
      this.invoiceNumber = this.selectedInvoice;
      this.checkCreditNotes = this.isCreditNote;

      this.loadData();

    }

  }
  

  loadData() {

    if (this.salesOrderNumber && this.invoiceNumber) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getInvoiceHeader(this.salesOrderNumber, this.invoiceNumber).subscribe(x => this.onCartHeaderLoadSuccessful(x), error => this.onDataLoadFailed(error));

    }

  }



  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchResults = null;
      if (this.checkCreditNotes) {
        this.sysproService.getCreditNoteSearchByCriteria(this.searchType, this.searchText.trim()).subscribe(
          x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
      }
      else {
        this.sysproService.getSalesOrderSearchByCriteria(this.searchType, this.searchText.trim()).subscribe(
          x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
      }
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }
  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }


  onSpecificSearchSuccesful(x: SalesOrderList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x.filter(i => i.invoiceNumber != '');

    if (x.length === 1) {
      this.searchResultSelected = x[0];
      this.viewInvoiceSelected();
    }

  }

  viewInvoiceSelected() {
    if (this.searchResultSelected) {
      sessionStorage.removeItem(localStorageActions.Customer);
      sessionStorage.setItem(localStorageActions.Customer, this.searchResultSelected.customer);
      sessionStorage.removeItem(localStorageActions.SalesOrderNumber);
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.searchResultSelected.salesOrderNumber);
      sessionStorage.removeItem(localStorageActions.InvoiceNumber);
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.searchResultSelected.invoiceNumber);
      this.id = this.searchResultSelected.customer;
      this.salesOrderNumber = this.searchResultSelected.salesOrderNumber;
      this.invoiceNumber = this.searchResultSelected.invoiceNumber;      
      this.loadData();
      this.router.navigate(['../../salesorders'], { fragment: 'invoice' });

    }
  }

  reprint() {
    this.authModal.show();  
  }

  verifyAuth() {
    this.alertService.startLoadingMessage("Verfiying...");
    this.loadingIndicator = true;
    this.fowkesService.VerifyPassword(this.branchCode, "REPRINT", encodeURIComponent(this.stringToCompare)).subscribe(x => this.onVerificationSuccess(), error => this.onVerificationFailed(error));

  }
  onVerificationSuccess(): void {
    this.authorized = true;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', 'Authorized', MessageSeverity.success);
    this.authModal.hide();
    //if (!!this.selectedPrinter == false) {
    //  this.selectedPrinter = "CTFB Invoice FB Reps Store";
    //}
    this.printModal.show();    
  }

  onVerificationFailed(error: any): void {
    this.authorized = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'Authorization Failed ', MessageSeverity.error);
  }

  print() {
    if (this.selectedPrinter) {

      console.log(this.selectedPrinter);
      this.user = this.accountService.currentUser;
      this.user.defaultPrinter = this.selectedPrinter;
      console.log("PRINT");
      this.printerService.PrintInvoice(this.salesOrderNumber, false, this.user).subscribe(x => this.onPrintSuccesful(x), error => this.onDataLoadFailed(error));
     this.printModal.hide();
    }
    else {
      this.alertService.showStickyMessage('Error', 'Please select a printer first.', MessageSeverity.error, "");

    }

  }

  get defaultPrinter(): string {
    return this.configurations.defaultPrinter;
  }

  onPrintersLoadSuccesful(x: Printers[]): void {
    this.Printers = x;

    setTimeout(() => {
      if (this.printerSelector) {
        this.printerSelector.refresh();
      }
    });
    if (this.defaultPrinter) {
      this.selectedPrinter = this.defaultPrinter;
    }
    else {
      this.printerService.GetUserPrinterMappings(this.accountService.currentUser.id).subscribe(x => this.onUserPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));
    }

    console.log("Printers", x);
  }
  onUserPrintersLoadSuccesful(x: PrinterUserMappings[]): void {

    this.printerMappings = x;

    if (this.printerMappings.length > 0) {
      const printerForInvoice = this.printerMappings.filter(p => p.reportName === "Invoice");
      this.selectedPrinter = (printerForInvoice.length ? printerForInvoice[0].printerName : this.selectedPrinter);
    }

    console.log("Printer Mappings", x);
  }

    onStatusesLoaded(x: SalesOrderStatus): void {
      this.OrderStatuses = x;
      console.log("STATUSES", this.OrderStatuses);
    }
    onCancelCodesLoaded(x: SalesOrderCancelReasonCode): void {
      this.CancelCodes = x;
      //if (this.CancelCodes == null) {
      //  setTimeout(() => {
      //    if (this.cancelreasonsSelector) {
      //      this.cancelreasonsSelector.refresh();
      //    }
      //  });
      //}
      console.log("CANCEL CODES", this.CancelCodes);
    }


  onPrintSuccesful(x: CoreAudit): void {

    this.alertService.showMessage('Success', `Printed Invoice`, MessageSeverity.success);
    this.loadData();
    console.log("PRINTED", x);
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Load Error', `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }
    
  onCartHeaderLoadSuccessful(order: InvoiceHeader) {
    let res = order;

    this.invoiceHeader = res[0];

    this.sysproService.getInvoiceDetail(this.salesOrderNumber, this.invoiceNumber).subscribe(x => this.onCartDetailLoadSuccessful(x), error => this.onDataLoadFailed(error));
    this.hasTracking = (!!this.invoiceHeader.itsInvoice && this.invoiceHeader.itsInvoice.length > 1);

    this.isWebOrder = (this.invoiceHeader.customer && this.invoiceHeader.customer == "FBWEB") ? true : false;
    this.weborderCompleted = false;
    if (this.hasTracking && this.isWebOrder) {

      //Get WebOrder Status
      this.sysproService.getWebOrderStatus(this.invoiceHeader.customerPoNumber).
        subscribe(x => this.onWebOrderLoadSuccessful(x), error => this.onDataLoadFailed(error));

    }

    this.printerService.GetPrinters().subscribe(x => this.onPrintersLoadSuccesful(x), error => this.onDataLoadFailed(error));
    this.sysproService.getInvoiceReprintDetails(this.invoiceNumber).subscribe(x => this.onPrintingDetailsLoadSuccesful(x), error => this.onDataLoadFailed(error));

    this.sysproService.getPODByInvoice(this.invoiceNumber).subscribe(x => this.onBarcodesLoadSuccesful(x), error => { this.hasPODs = false; });

    //Check for wapp settings
     // this.sysproService.getWappOptInDetails(this.accountService.currentUser.userName).subscribe(x => this.onUserWappLoadSuccesful(x), error => this.onOptinLoadFailed(error));


    this.sysproService.GetUserTel(this.accountService.currentUser.userName).subscribe(x => this.onTelReceived(x), error => this.onOptinLoadFailed(error));

    console.log(this.invoiceHeader);
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  onTelReceived(x: string): void {
    console.log(x);
    this.WhatsappEnabled = true;
    this.WhatsappNumber = x;

  }

  onOptinLoadFailed(error: any): void {
    this.WhatsappEnabled = false;
  }
  onUserWappLoadSuccesful(x: OptIns): void {
    if (x) {
      this.WhatsappEnabled = x.optedIn;
      this.WhatsappNumber = x.phoneNumber;
    }
  }


  podModalShow() {
    if (!this.podModal) {
      setTimeout(() => {
        if (this.podModal) {
          this.podModal.show();
        }
      });
    }
    else {
      this.podModal.show();
    }
  }

  onBarcodesLoadSuccesful(x: any) {
    var scannedDocs = x;
    if (scannedDocs && scannedDocs[0]) {
      this.referenceList = scannedDocs;
      this.hasPODs = true;
     
    }
    else {
      this.hasPODs = false;
      this.referenceList = null;
    }
  }


  podModalHide() {
    this.referenceList = null;
    this.podModal.hide();
  }


  viewPOD(row: BarcodeReference) {
    this.sysproService.getFileStream(row.fileName, row.fileNumber).subscribe(x => this.onViewPODSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));
  }

  sendWappPOD(row: BarcodeReference) {
    if (row && row.fileName != '') {

      this.sysproService.getFileStream(row.fileName, row.fileNumber).subscribe(x => {
        this.outboxDetail = new Outbox();
        this.outboxDetail.toPhoneNumber = this.WhatsappNumber;
        this.outboxDetail.content = "view_pod";
        this.outboxDetail.headerMediaFileId = row.fileName;
        this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;
        //this.sysproService.sendwappWithPDF(this.outboxDetail.toPhoneNumber,
        //  this.outboxDetail.headerMediaFileId, this.outboxDetail.nextGenUser, "POD", row.invoice).subscribe(x => this.onMessageSentSuccesful(), error => this.onDataLoadFailed(error));
        this.sysproService.sendTemplateWithFile(this.outboxDetail.headerMediaFileId, "POD", this.outboxDetail.toPhoneNumber, this.outboxDetail.nextGenUser,
          this.outboxDetail.headerMediaFileId).subscribe(x => this.onWhatsappSentSuccesful(x), error => this.WhatsappSentFailed(error));

      }, error => this.onDataLoadFailed(error));      
    }
  }
  onWhatsappSentSuccesful(x: any): void {
    console.log(x);
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('', `"${x.message}"`, MessageSeverity.info);
    this.emailModal.hide();
  }

  WhatsappSentFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Error', `Something went wrong: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  onMessageSentSuccesful(): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Message sent successfully`, MessageSeverity.success);
    this.podModalHide();
  }

  onViewPODSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }

  onWebOrderLoadSuccessful(x: WebOrder): void {

    if (x && x[0] && (x[0].internalStatus != 'COMPLETED' && x[0].internalStatus != 'CANCELLED' )) {

      //Get Tracking Status
      this.sysproService.getInvoiceTrackingStatus(this.salesOrderNumber, this.invoiceNumber).
        subscribe(x => this.onITSSuccessful(x), error => this.onDataLoadFailed(error));
    }
  }

  onITSSuccessful(result: InvoiceTrackingStatus): void {
    if (result[0]) {
      this.ITSObject = new InvoiceTrackingStatus();
      this.ITSObject = result[0];

      this.weborderCompleted = (this.ITSObject && this.ITSObject.completed == 'A') ? true : false;     

    }

  }

  updateWebOrder() {

    this.sysproService.updateWebOrderStatus(this.invoiceNumber, "COMPLETED").
      subscribe(x => this.onUpdateSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onUpdateSuccessful(x: any): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Order Updated`, MessageSeverity.success);
  }

    onPrintingDetailsLoadSuccesful(x: InvoiceReprint[]): void {

      this.invoiceReprintList = x;
      if (this.invoiceReprintList.length > 0) {
        var original = this.invoiceReprintList.filter(x => x.originalDocument == true);
        if (original && original.length > 0) {
          this.originalPrintLine = original[0];
        }
      }
    }


  onCartDetailLoadSuccessful(invoiceDetail: InvoiceDetail[]) {

    this.rowsCache = invoiceDetail;
    const vat = this.invoiceHeader.vatPercentage;
    this.TotalItems = 0;
    this.TotalExcl = 0;
    this.TotalDiscount = 0;
    this.TotalExclAfterDiscount = 0;
    this.TotalVat = 0;
    this.TotalIncl = 0;

    const updateRows = this.rowsCache;
    this.TotalLines = updateRows.filter(x => x.lineType !== "6").length;

    for (var product of this.rowsCache) {
      var lineTotalExclVat = Calculations.calculateBaseLineTotal(product.orderQty, product.sellingPrice, product.priceConvFac, product.lineTotal);
      var lineDiscount = Calculations.calculateLineDiscountTotal(product.orderQty, product.sellingPrice, product.discountPercentage1, product.priceConvFac, 0);

      product.nettPrice = Calculations.getGridNettLinePrice(product.orderQty, product.sellingPrice, product.priceConvFac, product.discountPercentage1, 0);
      this.TotalItems += product.orderQty;
      this.TotalExcl += lineTotalExclVat;
      this.TotalDiscount += lineDiscount;

      var lineVat = 0;
      if (vat && product.vatExempt == false) {
        lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);
      }
      //var lineVat = Calculations.calculateLineVat(lineTotalExclVat, lineDiscount, 0, vat);

      this.TotalVat += lineVat;

      this.calcTotal(product);
    }

    this.TotalExclAfterDiscount = (Calculations.RoundAwayFromZero(this.TotalExcl, 2) - Calculations.RoundAwayFromZero(this.TotalDiscount, 2));
    this.TotalIncl = Calculations.RoundAwayFromZero((this.TotalExclAfterDiscount + this.TotalVat), 2);

    this.sysproService.updateInvoiceTotals(this.application, this.salesOrderNumber, this.invoiceNumber, vat, this.TotalIncl, this.TotalExclAfterDiscount, this.TotalDiscount).
      subscribe(x => { }, error => this.onDataLoadFailed(error));

    this.rows = this.rowsCache;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

  onStockLoadSuccessful(inventory: any[]) {

    this.inventoryList = inventory[0];
  }

  stockCodeModalHide() {
    this.stockCodedetail = null;
    this.stockCodeModal.hide();
  }

  showStockDetail(row: any, event) {
    if (row.lineType != "7" && row.mapped) {
      this.stockCodedetail = new Inventory();
      this.stockCodedetail.stockCode = row.stockCode;
      this.stockDescription = row.stockDescription;
      this.stockCode = this.stockCodedetail.stockCode;
      this.stockCodedetail.dataSheetImage = row.dataSheetImage;
      this.stockCodedetail.specialsImage = row.specialsImage;
      this.stockCodedetail.onSpecial = row.onSpecial;
      this.stockCodedetail.filePath = row.filePath;

      if (!!this.stockCodedetail.stockCode) {
        if (!this.stockCodeModal) {
          setTimeout(() => {
            if (this.stockCodeModal) {
              this.stockCodeModal.show();
            }
          });
        }
        else {
          this.stockCodeModal.show();
        }
      }
    }
  }

  onStockCodeLoadSuccessful(row: Inventory): void {

    this.stockCodedetail = new Inventory();
    this.stockCodedetail = row[0];
    this.hasDataSheet = false;
    this.hasSpecials = false;

    if (this.stockCodedetail.dataSheetImage) {
      this.hasDataSheet = true;
    }
    if (this.stockCodedetail.addSheetImage) {
      this.hasAddDataSheet = true;
      this.stockCodedetail.addSheetImage = encodeURIComponent(this.stockCodedetail.addSheetImage);
    }
    if (this.stockCodedetail.specialsImage) {
      this.hasSpecials = true;
    }
    if (!!this.stockCodedetail) {
      if (!this.stockCodeModal) {
        setTimeout(() => {
          if (this.stockCodeModal) {
            this.stockCodeModal.show();
          }
        });
      }
      else {
        this.stockCodeModal.show();
      }
    }
  }

  openAlternate(row: Inventory, event) {
    if (row && row.alternateKey) {
      this.stockDescription = "Alternate StockCode: " + row.alternateKey;
      this.sysproService.getStockCodeAvailableQuantities(row.alternateKey.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));

    }
  }

  openDataSheet(dataSheet: string) {
    window.open(this.stockCodedetail.filePath + "/DataSheetImages/" + dataSheet, "_blank");
  }

  openSpecials(specialsImg: string) {
    if (!!specialsImg && specialsImg != "") {
      window.open(this.stockCodedetail.filePath + "/SpecialImages/" + specialsImg, "_blank");
    }
  }

  showWHDetail(row: any, event) {
    if (row) {
      if (row.lineType != "7") {
        this.stockDescription = row.stockCode + ": " + row.stockDescription;
        this.sysproService.getStockCodeAvailableQuantities(row.stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onWHLoadSuccessful([x]), error => this.onDataLoadFailed(error));
      }
    }
  }

  onWHLoadSuccessful(whDetail: any) {
    this.whDetail.push(new WHAvailQTY());
    if (whDetail) {
      this.whDetail = whDetail[0];
      this.buyerResponsible = this.whDetail[0].buyerResponsible;
      this.warehouseModal.show();
    }
  }


  onEmailLoadSuccessful() {
    var stockCode = this.stockCodedetail.stockCode;
    var description = this.stockDescription;

    this.attachDataSheets = false;
    this.attachSpecials = false;
    this.hasDataSheets = false;
    this.hasSpecials = false;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.reportService.getInventoryPDF(stockCode.replace(/\//g, "--").replace(/\+/gi, "___"), this.branchCode, this.application).subscribe(x => this.loadInventoryEmailModal(description, stockCode));
  }

  private loadInventoryEmailModal(description: string, stockCode: string) {

    this.hasDataSheets = (this.stockCodedetail.dataSheetImage && this.stockCodedetail.dataSheetImage.length >= 1);

    this.hasAddDataSheets = (this.stockCodedetail.addSheetImage && this.stockCodedetail.addSheetImage.length >= 1);
    this.hasSpecials = (this.stockCodedetail.specialsImage && this.stockCodedetail.onSpecial && this.stockCodedetail.specialsImage.length >= 1);

    this.emailModal.show();
    this.emailObject.RecepientName = this.invoiceHeader.contact;
    this.emailObject.RecepientEmail = this.invoiceHeader.email;
    this.emailObject.CC = "";
    this.emailObject.BCC = "";
    this.emailObject.Subject = "Inventory Item: " + description;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.application;
    this.emailObject.Attachment = stockCode;
    this.emailObject.Bod = "Please find attached PDF that can be viewed with any standard PDF reader.";
    this.emailObject.EmailType = "Inventory";

    if (this.hasDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc1 = this.stockCodedetail.dataSheetImage.trim();
    }

    if (this.hasAddDataSheets) {
      this.attachDataSheets = true;
      this.emailObject.AddDoc3 = this.stockCodedetail.addSheetImage.trim();
    }

    if (this.hasSpecials) {
      this.attachSpecials = true;
      this.emailObject.AddDoc2 = this.stockCodedetail.specialsImage.trim();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  titleCase(str) {
    str = str.toLowerCase();
    return str.replace(/\w\S/g, function (t) { return t.toUpperCase() });
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  viewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  previewPDF() {
    var stockCode = this.emailObject.Attachment;
    this.reportService.getFileStreamInventory(stockCode.replace(/\//g, "--").replace(/\+/gi, "___")).subscribe(x => this.onFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailPDF() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.emailObject.AddDoc1 = (this.attachDataSheets ? this.emailObject.AddDoc1 : "");
    this.emailObject.AddDoc2 = (this.attachSpecials ? this.emailObject.AddDoc2 : "");

    this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);
    this.emailModal.hide();
  }


  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    var stockCode = this.emailObject.Attachment.replace('/', '_').replace('+', '_');

    this.src = url;
    // window.open(this.src).print();
    // const blobUrl = window.URL.createObjectURL((test));
    //const iframeDoc = document.getElementsByTagName("iframe")[0].contentWindow;
    // iframeDoc.src = url;



    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = stockCode + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }



  private saveSuccessHelper(res?: any) {

    this.cartSaved = true;

    this.alertService.stopLoadingMessage();

    if (res) {
      console.log(res);
    }

    if (this.isNavigating) {
      this.router.navigate(['../orders'], { fragment: 'cart', queryParams: { customer: this.id, cartId: this.cartId } });
    }

    if (this.isUpdating) {

      this.isUpdating = false;
      this.alertService.showMessage('Success', this.quoteId + ` Updated successfully`, MessageSeverity.success);
    }
    else {

      this.isSaving = false;
      this.alertService.showMessage('Success', this.cartId + ` Created successfully`, MessageSeverity.success);
    }

  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback) {
    //  this.changesFailedCallback();
    //}
  }

  private save(location?) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    
    for (var product of this.rowsCache) {
    }


  }

  viewInventory() {

  }

  calcOrderQty(row: any, event) {

    if (row.lineType != "6") {
      var val = event.target.value;
      row.orderQty = val;

      let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
      row.lineTotal = total;
    }
  }


  //calcDisc(row: any, event) {
  //  var val = event.target.value;
  //  row.discountPercentage1 = val;

  //  let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
  //  row.lineTotal = Calculations.precise_round(total, 2);
  //}


  //calcPrice(row: any, event) {
  //  var val = event.target.value;
  //  row.sellingPrice = val;

  //  let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
  //  row.lineTotal = Calculations.precise_round(total, 2);
  //}


  calcTotal(row: any) {
    if (row.lineType != "6") {
      let total = Calculations.getGridLineTotal(row.orderQty, row.sellingPrice, row.priceConvFac, row.discountPercentage1, 0);
      row.lineTotal = total;
    }
  }

  setDelete(row: any) {
    if (row.selected) {
      this.selectedItems.push(row.lineNumber);
    }
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }


  viewTracking() {
    sessionStorage.removeItem(localStorageActions.ITSInvoiceNumber);
    sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, this.invoiceHeader.itsInvoice);
    this.router.navigate(['../../reports'], { fragment: 'invoicestatus' });

  }

  viewPODs() {
    sessionStorage.removeItem(localStorageActions.PODReference);
    sessionStorage.setItem(localStorageActions.PODReference, this.invoiceHeader.invoiceNumber);
    sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.invoiceHeader.salesOrderNumber);
    this.router.navigate(['../pods'], { fragment: 'view', queryParams: { invoice: this.invoiceHeader.salesOrderNumber } });

  }


  get canViewWebQuotes() {
    return this.accountService.userHasPermission(Permission.webQuotesPermission);
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }


  get canReprintInvoices() {
    return this.accountService.userHasPermission(Permission.reprintInvoicesPermission);
  }


  private postAudit() {
    this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  }
}
