import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { Brand } from '../../../models/brand.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CMStockCode } from '../../../models/cms-stockcode.model';
import { ITSVehicles } from '../../../models/invoice-tracking-vehicles.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'tracking-fingerprint',
  templateUrl: './tracking-fingerprint.component.html',
  styleUrls: ['./tracking-fingerprint.component.scss'],
  animations: [fadeInOut]
})
export class TrackingFingerPrintComponent {

  @ViewChild('vehicleModal')
  vehicleModal: ModalDirective;

  emptyDetails: boolean;

  rows: ITSVehicles[] = [];
  rowsCache: ITSVehicles[];

  vehicleDetail: ITSVehicles = new ITSVehicles();

  isEditMode = false;
  enableEdit = false;
  enableEditIndex = null;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  newformData = new FormData();
  isSaving: boolean;
  loadingIndicator: boolean;

  searchTerm: string = "";
    cardType: string;
    src: string;
    result: any;

  constructor(private http: HttpClient, private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute,
    private accountService: AccountService, private fowkesService: FowkesOnlineService, private configurations: ConfigurationService) {
  }

  ngOnInit() {

    this.loadData();

  }

  ngAfterViewInit() {


  }

  captureFingerprint(): void {
    const fingerprintData = this.getFingerprintData();

    this.fowkesService.verify(fingerprintData).subscribe(response => {
      if (response) {
        // Handle successful verification
      } else {
        // Handle failed verification
      }
    });
  }

  getFingerprintData(): any {
    // Implement logic to get fingerprint data from the scanner
    return {};
  }

  scanFingerprint() {
    this.fowkesService.scanFingerprint().subscribe(
      (data) => {
        this.result = data;
      },
      (error) => {
        this.result = { message: 'An error occurred' };
      }
    );
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.fowkesService.getAllVehicles().subscribe(x => this.onDataLoadSuccessful([x]), error => this.onDataLoadFailed(error));

  }

  onDataLoadSuccessful(vehicles: any[]) {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (vehicles[0]) {

      this.rowsCache = [...vehicles[0]];
      this.rows = [...this.rowsCache];


      if (this.searchTerm.trim() != "") {
        this.onSearchChanged(this.searchTerm);
      }

    }
    else {
      this.emptyDetails = true;
    }
  }
  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  onSearchChanged(value: string) {
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.vehicleName, r.vehicleDescription, r.vehicleNumber));
      this.searchTerm = value;
    }
    else {
      this.searchTerm = "";
      this.rows = [...this.rowsCache];
    }
  }

  //onValidationSuccesful(x: CMStockCode[], row: Brand): void {
  //  console.log(x);
  //  if (x && x.length >= 1) {
  //    this.alertService.showMessage('Warning', `You cannot delete a brand with stock codes associated. There are ` + x.length + ' items linked to this brand ', MessageSeverity.warn);
  //  }
  //  else {
  //    this.isSaving = true;
  //    this.alertService.startLoadingMessage('Saving changes...');
  //    this.sysproService.deleteBrand(row.id).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  //  }
  //}

  enableEditMethod(row: ITSVehicles, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    console.log(i, row);
  }

  saveSegment(row: ITSVehicles) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');
    row.createdBy = this.accountService.currentUser.email;
    this.fowkesService.updateVehicle(row.id, row).subscribe(x => this.onUpdateSuccesful(), error => this.onPostDataLoadFailed(error));
  }

  onUpdateSuccesful(): void {

    this.isSaving = false;
    this.enableEdit = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }


  vehicleModalHide() {
    this.vehicleDetail = new ITSVehicles();
    this.vehicleModal.hide();
  }

  newVehicle() {

    if (!this.vehicleModal) {
      setTimeout(() => {
        if (this.vehicleModal) {
          this.vehicleModal.show();
        }
      });
    }
    else {
      this.vehicleModal.show();
    }

  }

  saveVehicle() {

    //Validate save
    if (this.vehicleDetail) {
      if (this.vehicleDetail.vehicleNumber) {
        if (this.rowsCache.filter(x => x.vehicleNumber == this.vehicleDetail.vehicleNumber).length >= 1) {
          this.alertService.showMessage('Warning', `This Vehicle already exists`, MessageSeverity.warn);
        }
        else {
          this.isSaving = true;
          this.alertService.startLoadingMessage('Saving changes...');
          this.vehicleDetail.createdBy = this.accountService.currentUser.email;
          this.vehicleDetail.vehicleName = this.vehicleDetail.vehicleDescription;
          this.fowkesService.addNewVehicle(this.vehicleDetail).subscribe(x => this.onSaveSuccesful(), error => this.onPostDataLoadFailed(error));
        }
        //else {
        //  if (this.newformData.has('file')) {
        //    this.sysproService.postFileData(this.newformData, "BRAND", this.brandDetail.brandName.replace(/\//g, "--").replace(/\+/gi, "___").replace(/\%/gi, "---")).subscribe(x => this.newFilePostedSuccess(x), error => this.onPostDataLoadFailed(error));
        //  }
        //  else {
        //    this.alertService.showMessage('Warning', `Remeber to add a file first`, MessageSeverity.warn);
        //  }
        //}
      }
      else {
        this.alertService.showMessage('Warning', `Make sure to enter a driver name`, MessageSeverity.warn);
      }
    }
  }

  onSaveSuccesful(): void {

    this.vehicleModalHide();
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Updated successfully`, MessageSeverity.success);
    this.loadData();
  }

  deleteRow(row: ITSVehicles) {
    this.alertService.showDialog('Are you sure you want to delete \"' + row.vehicleName + ' - ' + row.vehicleNumber + '\"?', DialogType.confirm, () => this.deleteRowHelper(row));
  }

  deleteRowHelper(row: ITSVehicles) {

    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.fowkesService.deleteVehicle(row.id).subscribe(results => {

      this.loadData();

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.alertService.showMessage('Success', row.vehicleNumber + ` Successfully deleted`, MessageSeverity.success);
    },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the line.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  printCard(row: ITSVehicles) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.cardType = "VehicleCard";
    this.fowkesService.getPrintCardPDF(this.cardType, row.vehicleNumber, this.application).subscribe(x => this.onFileSuccessful(x, false, row.vehicleNumber), error => this.onDataLoadFailed(error));

  }

  onFileSuccessful(x: Blob, preview: boolean, id: string): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = this.cardType + "_" + id + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  onPostDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
  }

  //onPostDataSuccessful(arg0: any[]) {
  //  throw new Error("Method not implemented.");
  //}
}
